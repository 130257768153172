import React, { ReactElement } from 'react';

export const FlaggedForReview = (): ReactElement => {
    return (
        <svg className="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.66699 9.99998C2.66699 9.99998 3.33366 9.33331 5.33366 9.33331C7.33366 9.33331 8.66699 10.6666 10.667 10.6666C12.667 10.6666 13.3337 9.99998 13.3337 9.99998V1.99998C13.3337 1.99998 12.667 2.66665 10.667 2.66665C8.66699 2.66665 7.33366 1.33331 5.33366 1.33331C3.33366 1.33331 2.66699 1.99998 2.66699 1.99998V9.99998Z"
                stroke="#FFAB00"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M2.66699 14.6667V10"
                stroke="#FFAB00"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
