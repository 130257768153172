import { Saving } from 'assets/SavingSpinner.svg';
import React, { ReactElement } from 'react';

export const LoadingSpinner = ({
    className = '',
    strokeColor = '#2F64BB',
}: {
    className?: string;
    strokeColor?: string;
}): ReactElement => {
    return (
        <div className={className}>
            <div className={`mx-auto h-24 w-24 animate-spin`}>
                <Saving strokeColor={strokeColor} />
            </div>
        </div>
    );
};
