import React, { ReactElement } from 'react';

interface PillProps {
    title: string;
    alt?: string;
    colour: string;
    dataCy?: string;
}

export const LIGHTBLUE = 'LIGHT-BLUE-PILL';
export const BLUE = 'BLUE-PILL';
export const BRIGHTBLUE = 'BRIGHT-BLUE-PILL';
export const LIGHTGRAY = 'LIGHTGRAY-PILL';
export const YELLOW = 'YELLOW-PILL';
export const LIGHTYELLOW = 'LIGHT-YELLOW-PILL';
export const GRAY = 'GRAY-PILL';
export const GREEN = 'GREEN-PILL';
export const RED = 'RED-PILL';

const colours = {
    [BRIGHTBLUE]: 'text-white bg-primary-blue-100',
    [LIGHTBLUE]: 'text-primary-blue-80 bg-primary-blue-100-opace-1',
    [BLUE]: 'text-primary-blue-80 bg-primary-blue-100-opace-1',
    [YELLOW]: 'text-white bg-dark-golden',
    [LIGHTYELLOW]: 'bg-warning-light/30 text-warning-dark',
    [GREEN]: 'text-white bg-success-light',
    [LIGHTGRAY]: 'text-gray-500 bg-gray-200',
    [GRAY]: 'text-white bg-black-80',
    [RED]: 'text-white bg-error-light',
};

export const Pill = ({ title, alt, colour, dataCy }: PillProps): ReactElement => {
    return (
        <span
            data-cy={dataCy}
            className={`small-1-semibold mx-4 my-2 truncate rounded-full px-8 py-2 leading-22 ${colours[colour]}`}
            title={alt || title}
        >
            {title}
        </span>
    );
};
