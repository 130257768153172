import React, { ReactElement } from 'react';

interface EmptyStateProps {
    placeholderImage?: ReactElement;
    title?: string;
    subTitle?: string;
    button?: ReactElement;
}

export const EmptyState = ({ placeholderImage, title, subTitle, button }: EmptyStateProps): ReactElement => {
    return (
        <div className="flex select-none flex-col items-center">
            <div>{placeholderImage}</div>
            <p className="h6-medium tracking-0.2 mt-32 select-none text-center text-black">{title}</p>
            <p className="body-2-reg tracking-0.1 m-auto mt-8 select-none text-center text-black-80">{subTitle}</p>
            <div className="pt-38">{button}</div>
        </div>
    );
};
