import { validate as uuidValidate, version as uuidVersion } from 'uuid';

export function isValidUUID(uuid: string): boolean {
    return uuidValidate(uuid) && uuidVersion(uuid) === 4;
}

export const isValidNumber = (numberText: string): boolean => {
    if (!numberText) {
        return false;
    }

    return !isNaN(parseFloat(numberText));
};

export const isValidString = (str: string): boolean => {
    if (!str) {
        return false;
    }

    if (str.trim && str.trim()) {
        return true;
    }

    return false;
};

export const isValidStringOfLength = (str: string, minLength: number): boolean => {
    if (!str) {
        return false;
    }

    if (str.trim && str.trim()) {
        if (str.length >= minLength) {
            return true;
        }
    }

    return false;
};

export const isValidEmail = (emailAddress: string): boolean => {
    if (isValidString(emailAddress)) {
        if (emailAddress.indexOf('@') !== -1) {
            if (emailAddress.indexOf('.') !== -1) {
                if (emailAddress.length > 5) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const getEmailDomain = (emailAddress: string): string => {
    if (isValidEmail(emailAddress)) {
        const components = emailAddress.split('@');
        return components[1].split('.')[0];
    }
};

export const convertToUTF8 = (originalString: string): string => {
    const characters: string[] = [];
    for (let i = 0; i < originalString.length; i++) {
        if (originalString.charCodeAt(i) <= 127) {
            characters.push(originalString.charAt(i));
        }
    }
    return characters.join('');
};
