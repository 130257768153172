import { ChevronRight } from 'assets/chevron-right.svg';
import { ReactComponent as OpenProjects } from 'assets/openProjects.svg';
import { Avatar } from 'components/duex/Avatar';
import { EmptyState } from 'components/empty-states.component';
import { Col, ColHeader, Row, StyledTable } from 'components/table';
import { useUserContext } from 'hooks/useUserContext';
import _truncate from 'lodash/truncate';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project, ProjectDashboard } from 'types';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';

export const Commitments = ({ dashboardData }: { dashboardData: ProjectDashboard; project: Project }): ReactElement => {
    const { getUser } = useUserContext();
    const navigate = useNavigate();

    return (
        <StyledTable>
            <>
                <Row className="border-b border-gray-light-1 bg-white">
                    <>
                        <ColHeader text="Assignee" className={`w-5-of-24 pl-16`} />
                        <ColHeader text="Question" className={`w-6-of-24`} />
                        <ColHeader text="Answer" className={`w-6-of-24`} />
                        <ColHeader text="Commitment" className={`w-6-of-24`} />
                        <ColHeader text="" className={`w-1-of-24`} />
                    </>
                </Row>
                {!dashboardData.commitments.length && (
                    <li className="mt-80">
                        <EmptyState placeholderImage={<OpenProjects />} title="There are no commitments currently" />
                    </li>
                )}
                {dashboardData.commitments.map((question) => {
                    const assignee = getUser(question.assignedToUserId);

                    return (
                        <Row
                            className="border-b border-black-10 hover:bg-gray-50 cursor-pointer"
                            spacing="py-16"
                            onClickHandler={() => {
                                navigate(
                                    `${createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                                        projectId: question.projectId,
                                        questionId: question.questionId,
                                    })}workflow`,
                                );
                            }}
                        >
                            <Col className={`w-5-of-24 pl-16`}>
                                {question.assignedToUserId && (
                                    <div className="flex gap-16 flex-row items-center ">
                                        <Avatar size={32} userId={question.assignedToUserId} />
                                        {_truncate(assignee.fullName, { length: 42 })}
                                    </div>
                                )}
                                {!question.assignedToUserId && <span className="italic text-gray-500">Unassigned</span>}
                            </Col>
                            <Col className={`w-6-of-24 whitespace-pre-line break-words clamp-3`}>
                                {question.question}
                            </Col>
                            <Col className={`w-6-of-24 whitespace-pre-line break-words clamp-3`}>{question.answer}</Col>
                            <Col className={`w-6-of-24`}>{question.commitments}</Col>
                            <Col className={`w-1-of-24`}>
                                <ChevronRight />
                            </Col>
                        </Row>
                    );
                })}
            </>
        </StyledTable>
    );
};
