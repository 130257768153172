import { LeftArrow } from 'assets/left-arrow.svg';
import { RightArrow } from 'assets/right-arrow.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { CircleButton } from 'components/duex/CircleButton';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import React, { ReactElement, useState } from 'react';
import { Project, Question } from 'types';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { CombinedComponent } from './combined-component';

interface Candidate extends Question {
    project: Project;
}

export const CandidateReviewModal = ({
    candidate,
    closeModal,
    nextCandidate,
    previousCandidate,
    hasNextCandidate,
    hasPreviousCandidate,
}: {
    candidate: Candidate;
    closeModal: () => void;
    hasNextCandidate?: boolean;
    hasPreviousCandidate?: boolean;
    nextCandidate?: () => void;
    previousCandidate?: () => void;
}): ReactElement => {
    const [saving, setSaving] = useState(null);

    const updateCandidate = async (newStatus: string) => {
        try {
            setSaving(newStatus);

            const url = ENDPOINTS.getUrl(CONSTANTS.ANSWER_LIBRARY_BULK_CANDIDATE_CHANGE);

            const results = await API.post(url, {
                questionIds: [candidate.questionId],
                newStatus,
            });
            if (results && results.data) {
                setSaving(null);
                closeModal();
            }
        } catch (err) {
            showError('There was an error making those changes', err);
            setSaving(null);
        }
    };

    const previousButton = (
        <CircleButton
            className="mt-20"
            onClick={previousCandidate}
            title="Previous Candidate"
            disabled={!hasPreviousCandidate}
        >
            <LeftArrow strokeColor="currentColor" />
        </CircleButton>
    );
    const nextButton = (
        <CircleButton className="mt-20" onClick={nextCandidate} title="Next Candidate" disabled={!hasNextCandidate}>
            <RightArrow strokeColor="currentColor" />
        </CircleButton>
    );

    return (
        <Modal
            closeModal={closeModal}
            title="Answer Review"
            width="w-1000"
            leftControl={previousButton}
            rightControl={nextButton}
        >
            <div className="overflow-y-auto">
                <CombinedComponent question={candidate} />
            </div>

            <ButtonBar className="mt-16">
                <Button label="Cancel" buttonType="SECONDARY" onClick={closeModal} disabled={saving} />
                <Button
                    label="Discard Answer"
                    buttonType="SECONDARY"
                    onClick={() => {
                        updateCandidate('DISCARD');
                    }}
                    disabled={saving}
                    loading={saving === 'DISCARD'}
                />
                <Button
                    label="Add to Library"
                    onClick={() => {
                        updateCandidate('CREATE');
                    }}
                    disabled={saving}
                    loading={saving === 'CREATE'}
                />
            </ButtonBar>
        </Modal>
    );
};
