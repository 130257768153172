import _find from 'lodash/find';
import _map from 'lodash/map';
import { ReactElement, useState } from 'react';

interface Tab {
    label: string;
    isActive: boolean;
    filters?: {
        [key: string]: string | boolean;
    };
    description?: string;
    key?: string;
    component?: ReactElement;
}

interface TabsFunction {
    selectedTab: Tab;
    tabs: Tab[];
    changeTab: (selectedTab: Tab) => void;
    setTabs: (newTabs: Tab[]) => void;
}

function compareTabs(tabA: Tab, tabB: Tab) {
    if (tabA.key) {
        return tabA.key === tabB.key;
    } else {
        return tabA.label === tabB.label;
    }
}

export function useTabs(initialTabs: Tab[]): TabsFunction {
    const [tabs, setTabs] = useState(initialTabs);

    const selectedTab = _find(tabs, {
        isActive: true,
    });

    const changeTab = (selectedTab: Tab) => {
        setTabs(
            _map(tabs, (tab: Tab) => {
                return {
                    ...tab,
                    isActive: compareTabs(selectedTab, tab),
                };
            }),
        );
    };

    return {
        selectedTab,
        changeTab,
        tabs,
        setTabs,
    };
}
