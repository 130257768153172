import React, { ComponentType, ReactElement, useState } from 'react';
import { IconProps } from 'types/icon.type';
import Tooltip, { getTooltipId } from './Tooltip';

export const IconButton = ({
    className = '',
    Icon,
    strokeColor = '#A0A4A8',
    hoverStrokeColor = '#2563EB',
    title,
    onClick,
    bg = '',
    bgHover = 'hover:bg-gray-100',
}: {
    className?: string;
    hoverStrokeColor?: string;
    strokeColor?: string;
    Icon: ComponentType<IconProps>;
    title: string;
    onClick?: () => void;
    bg?: string;
    bgHover?: string;
}): ReactElement => {
    const [isHovered, setIsHovered] = useState(false);
    const stroke = isHovered ? hoverStrokeColor : strokeColor;

    const tooltipId = getTooltipId(title);

    return (
        <span
            className={`${className} flex h-32 w-32 cursor-pointer items-center justify-center rounded-sm ${bg} ${bgHover}`}
            data-tooltip-content={title}
            data-tooltip-id={tooltipId}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
            }}
        >
            <Icon strokeColor={stroke} />
            <Tooltip id={tooltipId} />
        </span>
    );
};
