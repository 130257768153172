import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { showError } from 'components/error-toast.component';
import { convertToUTF8 } from 'components/validation/string';
import { useTeamContext } from 'hooks/useTeamContext';
import _last from 'lodash/last';
import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const LibraryImportFileUpload = ({
    onComplete,
    closeModal,
}: {
    onComplete: (answerSourceId: string) => void;
    closeModal: () => void;
}): ReactElement => {
    const allowedFileTypes = ['.xlsx', '.csv', '.docx', '.pdf'];
    const [uploading, setUploading] = useState(false);
    const inputButton = useRef<HTMLInputElement>();
    const [enableSaveButton, setEnableSaveButton] = useState(false);
    const { settings } = useTeamContext();

    const fileSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            const maxFileSize = 25;

            if (fileSize > maxFileSize) {
                alert('That file is too large. We allow up to 25MB.');
            }

            const extension = _last(file.name.split('.'));

            if (allowedFileTypes.indexOf(`.${extension.toLowerCase()}`) === -1) {
                inputButton.current.value = '';
                alert('Unfortunately we dont support that file type');
            }
        }

        calculateSave();
    };

    const calculateSave = () => {
        const valid = inputButton.current && inputButton.current.files && inputButton.current.files.length > 0;

        setEnableSaveButton(valid);
    };

    const uploadFile = async () => {
        const stepsCompleted = [];
        try {
            const started = new Date();
            setUploading(true);
            const fileName = inputButton.current.files[0].name;
            const contentType = inputButton.current.files[0].type;
            const file = inputButton.current.files[0];

            const body = {
                fileName: convertToUTF8(fileName),
                contentType,
            };

            stepsCompleted.push(`Starting: ${JSON.stringify(body)}`);

            const uploadResource = await API.post(ENDPOINTS.getUrl(CONSTANTS.UPLOAD_ANSWER_LIBRARY_START), body);
            const { uploadUrl, answerSourceId } = uploadResource.data;

            stepsCompleted.push('Create_Upload');

            await API.putWithoutAuth(uploadUrl, file, {
                'Content-Type': contentType,
            });

            stepsCompleted.push('Upload_To_S3');

            const result = await API.post(
                ENDPOINTS.getUrl(CONSTANTS.UPLOAD_ANSWER_LIBRARY_COMPLETE, {
                    answerSourceId,
                }),
            );

            stepsCompleted.push('Finalize_Document_Creation');

            logEvent(`ANSWER_SOURCE_FILE_UPLOADED`, started);

            setUploading(false);

            if (result && result.data) {
                onComplete(result.data);
            }
        } catch (err) {
            setUploading(false);
            showError(
                'There was an error uploading your file. Please check the file is a supported format and can be opened on your computer.',
                {
                    context: 'LibraryFileImporter',
                    stepsCompleted,
                    ...err,
                },
            );
        }
    };

    useEffect(() => {
        calculateSave();
    }, [inputButton]);

    return (
        <div>
            <p className="mb-16 text-14 text-gray-600">
                Answers you import will be added
                {settings.defaultAnswersToFlowToLibrary
                    ? ' into your Available Answers collection. They will be immediately available for your team.'
                    : ' into your Review collection.'}
            </p>
            <div>
                <label htmlFor="file-selector" className="text-sm text-gray-500">
                    File
                </label>
                <input
                    id="file-selector"
                    type="file"
                    className="btn-secondary mb-8 w-full"
                    accept={allowedFileTypes.join(', ')}
                    onChange={fileSelectHandler}
                    ref={inputButton}
                />
                <label className="text-sm text-gray-500">We accept .xlsx, .csv, .docx, .pdf files only.</label>
            </div>

            <ButtonBar className="mt-24">
                <Button buttonType="SECONDARY" label="Cancel" onClick={closeModal} />
                <Button
                    label="Upload File"
                    disabled={!enableSaveButton || uploading}
                    onClick={uploadFile}
                    loading={uploading}
                />
            </ButtonBar>
        </div>
    );
};
