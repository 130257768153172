import React from 'react';

export const Mail = (): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.33329 3.33325H16.6666C17.5833 3.33325 18.3333 4.08325 18.3333 4.99992V14.9999C18.3333 15.9166 17.5833 16.6666 16.6666 16.6666H3.33329C2.41663 16.6666 1.66663 15.9166 1.66663 14.9999V4.99992C1.66663 4.08325 2.41663 3.33325 3.33329 3.33325Z"
                stroke="#CACCCF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.3333 5L9.99996 10.8333L1.66663 5"
                stroke="#CACCCF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
