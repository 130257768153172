import { LibraryCross } from 'assets/answer-library-cross.svg';
import { useDebounce } from 'hooks/useDebounce';
import React, { ReactElement, useEffect, useState } from 'react';
import { Search } from '../../assets/magnify.svg';

export default function SearchTextField({
    applySearch,
    placeholder,
    currentValue = '',
    activeBackground = false,
    testId,
    debounceDuration = 500,
    dataCy = '',
}: {
    applySearch: (searchText: string) => void;
    placeholder?: string;
    currentValue?: string;
    activeBackground?: boolean;
    testId?: string;
    debounceDuration?: number;
    dataCy?: string;
}): ReactElement {
    const [searchValue, setSearchValue] = useState(currentValue);
    const debouncedSearchTerm = useDebounce(searchValue, debounceDuration);

    useEffect(() => {
        applySearch(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    useEffect(() => {
        setSearchValue(currentValue);
    }, [currentValue]);

    const background = activeBackground && searchValue ? 'bg-faded-yellow' : 'bg-faded-gray';

    return (
        <>
            <div data-cy={testId} className={`relative right-0 flex items-center`}>
                <div className="absolute left-0 flex items-center stroke-current px-10 py-10 text-primary-blue-60">
                    <Search strokeColor={'#CDDBF4'} />
                </div>
                <input
                    autoComplete="off"
                    className={`body-1-reg h-48 w-full appearance-none rounded-md border-none px-32 text-14 leading-22 text-primary-blue-60 placeholder-primary-blue-60 transition 
                    duration-150 ease-in-out hover:border-light-faded-blue hover:outline-none focus:text-field-outline-blue focus:outline-none focus:outline-field-outline-blue focus:ring ${background} `}
                    placeholder={placeholder || 'Search'}
                    value={searchValue}
                    maxLength={100}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSearchValue(event.target.value);
                    }}
                    data-cy={dataCy}
                />
                {searchValue && (
                    <div
                        onClick={() => {
                            setSearchValue('');
                        }}
                        className={`opacity-1 absolute right-0 ml-8 cursor-pointer select-none px-10 py-10 text-primary-blue-60`}
                    >
                        <LibraryCross
                            width="30"
                            height="30"
                            strokeColorCross={'#FFFFFF'}
                            strokeColorCircle={'#CACCCF'}
                        />
                    </div>
                )}
            </div>
        </>
    );
}
