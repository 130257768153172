import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { H2Heading } from 'components/duex/Heading';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { isValidEmail } from 'components/validation/string';
import React, { FormEvent, ReactElement, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const InviteModal = ({ closeModal, projectId }: { closeModal: () => void; projectId: string }): ReactElement => {
    const [recipient, setRecipient] = useState('');
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const submitForm = async (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        try {
            const started = new Date();
            setFormSubmitting(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_INVITE_ASSESSMENT, {
                projectId,
            });
            const result = await API.post(url, {
                emailAddress: recipient,
            });

            if (result.data) {
                setFormSubmitted(true);

                logEvent(`PROJECT_INVITE_SENT`, started, {
                    projectId,
                    emailAddress: recipient,
                });
            }

            setFormSubmitting(false);
        } catch (err) {
            showError('There was an error sending your Invitation', err);
            setFormSubmitting(false);
        }

        return false;
    };

    const resetForm = () => {
        setFormSubmitting(false);
        setFormSubmitted(false);
        setRecipient('');
    };

    const formValid = isValidEmail(recipient);

    return (
        <Modal width="w-600" title="Send Invitation" closeModal={closeModal}>
            <p className="mb-16 mt-8 text-gray-700">You&apos;re sending out invitations to complete an assessment.</p>
            <div className="">
                {formSubmitting && <LoadingSpinner className="" />}
                {formSubmitted && (
                    <>
                        <div className="flex flex-col gap-16">
                            <H2Heading label="Invitation Sent" />
                            <p>Email sent to {recipient}</p>
                            <div className="flex justify-center">
                                <Button buttonType="SECONDARY" label="Send another invitation" onClick={resetForm} />
                            </div>
                        </div>
                    </>
                )}
                {!formSubmitted && (
                    <>
                        <form className="flex flex-col gap-16 w-full" onSubmit={submitForm}>
                            <FormTextField
                                label="Recipient Email Address"
                                placeholder="john.smith@acme.com"
                                defaultValue={recipient}
                                valueChanged={(newValue: string) => {
                                    setRecipient(newValue);
                                }}
                            />
                            <ButtonBar>
                                <Button
                                    onClick={() => {
                                        submitForm();
                                    }}
                                    label="Send"
                                    disabled={!formValid || formSubmitting}
                                    loading={formSubmitting}
                                />
                                {recipient && (
                                    <Button
                                        onClick={resetForm}
                                        buttonType="SECONDARY"
                                        disabled={formSubmitting}
                                        label="Undo Changes"
                                    />
                                )}
                            </ButtonBar>
                        </form>
                    </>
                )}
            </div>
        </Modal>
    );
};
