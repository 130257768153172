import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Module } from 'components/duex/Module';
import { Tabs } from 'components/duex/Tabs';
import Tooltip, { getTooltipId } from 'components/duex/Tooltip';
import { showError } from 'components/error-toast.component';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { NotificationRow } from './notification-row';

export const Notifications = (): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [filteredNotifications, setFilteredNotifications] = useState([]);

    const StartingTabs = [
        {
            label: 'Unread',
            isActive: true,
        },
        {
            label: 'All',
            isActive: false,
        },
    ];

    const [tabs, setTabs] = useState(StartingTabs);

    const fetchData = async (showLoading = true) => {
        try {
            setLoading(showLoading);
            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_NOTIFICATIONS);
            const result = await API.get(url);

            if (result && result.data) {
                setNotifications(result.data);
            }

            setLoading(false);
        } catch (err) {
            showError('There was an error retrieving your notifications', err);
            setLoading(false);
        }
    };

    const markNotificationAsRead = async (notificationId: string) => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.READ_NOTIFICATIONS, {
                notificationId,
            });
            await API.post(url, {});

            logEvent('NOTIFICATIONS_MARK_READ', started, {
                notificationId,
            });

            fetchData(false);
        } catch (err) {
            showError('There was an error marking your notification as read', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        const selectedTab = _find(tabs, { isActive: true });

        if (selectedTab.label === 'Unread') {
            setFilteredNotifications(
                _filter(notifications, {
                    readAt: null,
                }),
            );
        } else {
            setFilteredNotifications(notifications);
        }
    }, [notifications, tabs]);

    useEffect(() => {
        fetchData();
    }, []);

    const selectedTab = _find(tabs, { isActive: true });
    const onUnreadTab = selectedTab.label === 'Unread';
    const tooltipId = getTooltipId('notifications');

    return (
        <Module moduleName="Notifications">
            <div className="mx-16">
                <Tabs
                    tabItems={tabs}
                    onChange={(selectedTab) => {
                        setTabs(
                            tabs.map((tab) => {
                                return {
                                    ...tab,
                                    isActive: tab.label === selectedTab.label,
                                };
                            }),
                        );
                    }}
                />
                <div className="pt-16">
                    {loading && <LoadingSpinner className="pt-32" />}
                    {!loading && filteredNotifications.length === 0 && (
                        <EmptyState
                            title={`${onUnreadTab ? 'No Unread Notifications' : 'No Notifications'}`}
                            subtitle={`${
                                onUnreadTab
                                    ? 'You might have read them all, or you might not have received any yet.'
                                    : "You haven't received any notifications yet."
                            }`}
                            width="w-600"
                        />
                    )}
                    {!loading &&
                        filteredNotifications.length > 0 &&
                        filteredNotifications.map((notification) => {
                            return (
                                <NotificationRow
                                    notification={notification}
                                    markNotificationAsRead={markNotificationAsRead}
                                    tooltipId={tooltipId}
                                />
                            );
                        })}
                </div>
            </div>
            <Tooltip id={tooltipId} />
        </Module>
    );
};
