import { ChevronRight } from 'assets/chevron-right.svg';
import { Contributors } from 'components/duex/Contributors';
import { ProgressMeter, ProgressSegment } from 'components/duex/ProgressMeter';
import { BLUE, LIGHTYELLOW, Pill } from 'components/pill.component';
import { Col, Row } from 'components/table';
import _sortBy from 'lodash/sortBy';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import Project, { ProgressCount } from 'types/project.type';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { DueDate, PriorityStatus } from './common-project-list.component';
const colours = {
    INCOMPLETE: '',
    'IN-PROGRESS': '',
    COMPLETE: 'bg-success-dark',
};

const order = {
    INCOMPLETE: 3,
    'IN-PROGRESS': 2,
    COMPLETE: 1,
};

const formatProgress = (progessCounts: ProgressCount[]): [string, ProgressSegment[]] => {
    let totalCount = 0;
    let completed = 0;

    progessCounts.forEach(({ count, responseStatus }) => {
        totalCount = totalCount + parseInt(count, 10);

        if (responseStatus === 'COMPLETE') {
            completed = completed + parseInt(count, 10);
        }
    });

    const segments = _sortBy(
        progessCounts.map(({ count, responseStatus }) => {
            return {
                percentage: parseInt(count, 10) / totalCount,
                colour: colours[responseStatus],
                order: order[responseStatus],
            };
        }),
        'order',
    );

    const description = totalCount > 0 ? `${completed} of ${totalCount} Completed` : '';

    return [description, segments];
};

const ProjectListItem = ({ project }: { project: Project }): ReactElement => {
    const navigate = useNavigate();

    const [description, segments] = formatProgress(project.progressCount);

    return (
        <Row
            className={`cursor-pointer border-b border-gray-light-1 hover:bg-faded-gray`}
            dataCy={`projectRow`}
            key={project.projectId}
            onClickHandler={() =>
                navigate(
                    createUrl(ROUTES.PROJECT_DASHBOARD, {
                        projectId: project.projectId,
                    }),
                )
            }
        >
            <>
                <Col className={`w-1-of-12`}>
                    {project.projectType === 'SUPPLIER' && <Pill colour={BLUE} title="SUPPLIER" />}
                    {project.projectType === 'BUYER' && <Pill colour={LIGHTYELLOW} title="BUYER" />}
                </Col>
                <Col className={`w-1-of-12`}>
                    <PriorityStatus
                        projectPriority={project.projectPriority}
                        projectDueDate={project.dueOn}
                        projectStatus={project.projectStatus}
                    />
                </Col>
                <Col className={`w-3-of-12 truncate`}>{project.name}</Col>
                <Col className={`w-1-of-12 truncate`}>
                    <ProgressMeter segments={segments} width={75} description={description} />
                </Col>
                <Col className={`w-2-of-12`}>
                    <DueDate
                        projectPriority={project.projectPriority}
                        projectDueDate={project.dueOn}
                        projectStatus={project.projectStatus}
                    />
                </Col>
                <Col className={`w-2-of-12`}>{moment(project.dueOn).format('Do MMMM YYYY')}</Col>
                {/* <Col className={`w-2-of-12`}>{moment(project.updatedAt).fromNow()}</Col> */}
                <Col className={`flex w-1-of-12`}>
                    <Contributors team={project.projectContributors} />
                </Col>
                <Col className={`w-1-of-12`}>
                    <ChevronRight />
                </Col>
            </>
        </Row>
    );
};

export const ProjectListRow = React.memo(ProjectListItem);
