export const ERROR_MESSAGES = {
    GENERIC_MESSAGE: 'Hmm, something went wrong. Please try again.',
    REQUIRED_FIELDS_FAIL: 'Please fill in required fields',
    CREATE_PROJECT_FAIL: "Hmm, we weren't able to create your project. Please try again.",
    FETCH_QUESTION_FAIL: "Hmm, we weren't able to fetch that question. You can try refreshing the page.",
    FETCH_SUGGESTIONS_FAIL: "Hmm, we weren't able to fetch any suggestions. You can try refreshing the page.",
    FLAG_ANSWER_FAIL: "Hmm, we weren't able to flag this answer. Please try again.",
    HIDE_ANSWER_FAIL: "Hmm, we weren't able to hide this answer. Please try again.",
    SAVE_ANSWER_FAIL: "Hmm, we weren't able to save your answers. Please try again.",
    SEARCH_SUGGESTED_FAIL: "Hmm, we weren't able to search your answers. Please try again.",
    FETCH_LIB_DEF_FAIL:
        "Hmm, we weren't able to fetch any definitions for this question. You can try refreshing the page",
    CHANGE_ASIGNEE_FAIL: "Hmm, we weren't able to assign this question. Please try again.",
    FETCH_QUESTIONS_FAIL: "Hmm, we weren't able to fetch any questions for this project. Please try again.",
    CHANGE_PROJECT_ASSIGNEE_FAIL: "Hmm, we weren't able to assign this project. Please try again.",
    EXPORT_PROJECT_FAIL:
        "Hmm, we weren't able to fetch questions for this project to export. You can try refreshing the page.",
    AUTHENTICATION_REQUEST_FAIL: 'There was some issue in authentication of the token. Please refresh the page.',
    CLOSE_PROJECT_FAIL: 'There was some error in closing the project. Please try again.',
    UPDATE_PROFILE_FAIL: "Hmm, we weren't able to save your details. Please try again.",
    FETCH_USER_FAIL: 'Something went wrong. Please refresh',
    FETCH_TEAM_MEMBER_FAIL: 'Something went wrong. Please refresh once.',
    PHOTO_UPLOAD_FAIL: "Hmm, we weren't able to upload the photo. Please try again.",
    READ_NOTIFICATIONS_FAIL: "Hmm, the notification couldn't be read. Please try again.",
    FETCH_NOTIFICATIONS_FAIL: "Hmm, the notification couldn't be fetched. Please try again.",
    FETCH_DASHBOARD_PROJECT_FAIL: 'Hmm, we were not able the fetch the projects. Please try again.',
    FECTH_ROLES_FAIL: 'Hmm, we were not able to fetch roles of team members. Please try again.',
    FECTH_GROUPS_FAIL: 'Hmm, we were not able to fetch groups of team members. Please try again.',
    FECTH_TEAM_MEMBER_FAIL: 'Hmm, we were not able to fetch your team members information. Please try again.',
    INVITE_TEAM_MEMBER_FAIL: 'Hmm, we were not able to invite the team member. Please try again.',
    COMPLETE_REGISTRATION_FAIL: 'Hmm, we were not able to complete your registration. Please try again.',
    FETCH_INVITATIONS_FAIL: 'Hmm, something went wrong. You can try refreshing the page.',
    CREATE_TEAM_FAIL: 'Hmm, we were not able to create the team. Please try again.',
    UPDATE_MEMBERSHIP_FAIL: 'Hmm, we were not able to update the changes made to the member. Please try again.',
    UPDATE_ACCEPT_INVITATION_FAIL: 'Hmm, we were not able to accept the invitation. Please try again.',
    UPDATE_DECLINE_INVITATION_FAIL: 'Hmm, we were not able to decline the invitation. Please try again.',
    IMPORT_ANSWERS_FAIL: 'Hmm, we were not able to import answers. Please try again.',
    IMPORT_ANSWERS_FORMAT_ISSUES:
        'Hmm, Looks like there is an error in the format. Please upload questions and answers in the correct format.',
    ADD_QUESTION_FAIL: 'Hmm, we were not able to add the question. Please try again.',
    FAILED_DELETE_QUESTION: 'Hmm, we were not able to delete the question. Please try again.',
    FAILED_COMPLETE_QUESTION: 'Hmm, we were not able to mark the question as complete. Please try again.',
    FAILED_NOT_A_QUESTION: 'Hmm, we were not able to mark it as not a question. Please try again.',
    EMPTY_USERNAME_PASSWORD: 'Email/Password cannot be empty',
    PROJECT_DASHBOARD_FAIL:
        'Hmm, there was some issue in fetching the project dashboard. Please try refreshing the page.',
    FETCH_ANSWERS_FAIL: 'We were unable to make that request. Please try again.',
    FETCH_USER_SETTINGS_FAIL: 'We were unable to receive your settings. Please try again.',
    UPDATE_USER_SETTINGS_FAIL: 'We were unable to update your settings. Please try again.',
    GET_PROJECT_FAIL: 'Hmm, something went wrong. Please try again.',
    CREATE_PROJECT_IMPORT_ISSUES:
        'Hmm, Looks like there is an error in the format. Please paste the excel rows in the correct format.',
    EMPTY_RESET_EMAIL: 'Email cannot be empty',
    CHANGE_PROJECT_COMPLIANCE_FAIL: 'Custom Compliance was unable to be sent',
    FETCH_PROJECT_COMPLIANCE_FAIL: 'Custom Compliance was unable to be fetched',
    FETCH_FAVOURITE_ANSWERS: `There was a problem when we tried to get your favourite answers`,
    FETCH_PROJECT_TEAM: `There was a problem when we tried to fetch the project team`,
};
