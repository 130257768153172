import { ReactComponent as PersonSearching } from 'assets/person-searching.svg';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { EmptyState } from 'components/empty-states.component';
import { showError } from 'components/error-toast.component';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import { Answer } from 'types';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { SuggestAnswersProps } from '../answering.view';
import { SuggestedAnswersListItem } from './suggested-answers-list-item.component';

export const suggestionDropownOptions = [
    {
        value: CONSTANTS.USE_THIS_ANSWER,
        label: 'Use this Answer',
    },
    {
        value: CONSTANTS.FLAG_ANSWER,
        label: 'Flag for Review',
    },
    {
        value: CONSTANTS.HIDE_ANSWER,
        label: "Don't show this Again",
    },
];

export const SuggestedAnswers = ({
    project,
    projectId,
    questionId,
    applyAnswer,
    hideAnswer,
    flagAnswer,
    undoHideAnswer,
    hiddenAnswers,
}: SuggestAnswersProps): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([]);

    const fetchSuggestions = async () => {
        try {
            setLoading(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.PROJECT_SUGGESTION_SEARCH, {
                questionId,
                projectId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                setSuggestions(response.data);
            }
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showError('There was a problem fetching suggestions', err);
        }
    };

    const updateQuestion = async (updatedSuggestion: Answer) => {
        setSuggestions(
            _map(suggestions, (suggestion) => {
                if (suggestion.answerId === updatedSuggestion.answerId) {
                    return updatedSuggestion;
                }

                return suggestion;
            }),
        );
    };

    useEffect(() => {
        if (projectId && questionId) {
            fetchSuggestions();
        }
    }, [projectId, questionId]);

    if (loading) {
        return <LoadingSpinner className="pt-64" />;
    }

    if (suggestions.length > 0) {
        return (
            <ul className="pt-32">
                {suggestions.map((answer) => (
                    <SuggestedAnswersListItem
                        answer={answer}
                        key={answer.answerId}
                        suggestionDropownOptions={suggestionDropownOptions}
                        applyAnswer={applyAnswer}
                        hideAnswer={hideAnswer}
                        flagAnswer={(answer: Answer, flaggedReason: string) => {
                            flagAnswer(answer, flaggedReason);
                            updateQuestion({
                                ...answer,
                                answerStatus: 'FLAGGED',
                                flaggedReason,
                            });
                        }}
                        undoHideAnswer={undoHideAnswer}
                        projectStatus={project.projectStatus}
                        hiddenAnswers={hiddenAnswers}
                    />
                ))}
            </ul>
        );
    }

    return (
        <div className="flex h-full items-center justify-center pb-32 pt-64 text-center">
            <EmptyState
                placeholderImage={<PersonSearching />}
                title="Suggested Answers"
                subTitle="There's not much here, try importing some answers to your Answer Library"
            />
        </div>
    );
};
