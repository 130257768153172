export function makeCancelable<T>(promise: Promise<T>): {
    promise: Promise<T>;
    cancel: () => void;
} {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise<T>((resolve, reject) => {
        promise
            .then((result) => {
                if (!hasCanceled_) {
                    resolve(result);
                }
            })
            .catch(reject);
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
}
