import { ComplianceSelect, options } from 'components/compliance-select.component';
import { DatePicker } from 'components/date-picker.component';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Module } from 'components/duex/Module';
import { showError } from 'components/error-toast.component';
import { GRAY, GREEN, Pill, RED, YELLOW } from 'components/pill.component';
import { Select } from 'components/select.component';
import { Col, ColHeader, Row } from 'components/table';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { expectedOutcomeOptions, outcomeOptions } from 'modules/project-dashboard/project-details/project-details.view';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

const complianceStyles = {
    [CONSTANTS.COMPLIANT]: GREEN,
    [CONSTANTS.PARTIALLYCOMPLIANT]: YELLOW,
    [CONSTANTS.NONCOMPLIANT]: RED,
    [CONSTANTS.NOTAPPLICABLE]: GRAY,
};

const reports = [
    {
        reportType: 'Projects',
        presets: [
            {
                name: 'Last 30 days',
                configuration: {
                    fromDate: moment().subtract(30, 'days'),
                },
            },
            {
                name: 'Last 6 Months',
                configuration: {
                    fromDate: moment().subtract(6, 'months'),
                },
            },
            {
                name: 'Recently Won',
                configuration: {
                    fromDate: moment().subtract(30, 'days'),
                    outcome: 'Won',
                },
            },
            {
                name: 'Recently Lost',
                configuration: {
                    fromDate: moment().subtract(30, 'days'),
                    outcome: 'Lost',
                },
            },
            {
                name: 'Recent Unexpected Wins',
                configuration: {
                    fromDate: moment().subtract(30, 'days'),
                    outcome: 'Won',
                    expectedOutcome: '1',
                },
            },
            {
                name: 'Recent Unexpected Losses',
                configuration: {
                    fromDate: moment().subtract(30, 'days'),
                    outcome: 'Lost',
                    expectedOutcome: '5',
                },
            },
            {
                name: 'Custom',
            },
        ],
    },
    {
        reportType: 'Questions',
        presets: [
            {
                name: 'Non Compliant last 30 days',
                configuration: {
                    fromDate: moment().subtract(30, 'days'),
                    complianceSelections: [CONSTANTS.PARTIALLYCOMPLIANT, CONSTANTS.NONCOMPLIANT],
                },
            },
            {
                name: 'Non Compliant last 6 Months',
                configuration: {
                    fromDate: moment().subtract(6, 'months'),
                    complianceSelections: [CONSTANTS.PARTIALLYCOMPLIANT, CONSTANTS.NONCOMPLIANT],
                },
            },
            {
                name: 'Custom',
            },
        ],
    },
];

function getPresetByKey(key: string) {
    const parts = key.split('_');

    const reportGroup = _find(reports, {
        reportType: parts[0],
    });

    const presetName = parts.slice(1).join(' ');

    return _find(reportGroup.presets, {
        name: presetName,
    });
}

function createPillForCompliance(complianceStatus: string): ReactElement {
    const option = _find(options, {
        key: complianceStatus,
    });
    if (!option) {
        return null;
    }
    return <Pill title={option.label} alt={option.label} colour={complianceStyles[complianceStatus]} />;
}

function getIdentifier(reportType: string, name: string): string {
    return `${reportType}_${name}`.replaceAll(' ', '_');
}

export const Insights = (): ReactElement => {
    const [searchResults, setSearchResults] = useState([]);
    const [selectedReport, selectReport] = useState(getIdentifier('Projects', 'Last 30 days'));
    const [fromDateFocus, setFromDateFocus] = useState(false);
    const [fromDate, setFromDate] = useState(null);
    const [toDateFocus, setToDateFocus] = useState(false);
    const [toDate, setToDate] = useState(null);
    const [isSearching, setIsSearching] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [showControls, setShowControls] = useState(false);
    const [complianceSelections, setComplianceSelections] = useState(_map(options, 'key'));
    const searchingQuestions = selectedReport.startsWith('Question');

    const defaultIndustryLabel = 'All Industries';
    const defaultProjectLabel = 'All Projects';
    const defaultOutcomeLabel = 'All Outcomes';

    const [projectList, setProjectList] = useState([
        {
            name: defaultProjectLabel,
            projectId: 0,
        },
    ]);
    const [industryList, setIndustryList] = useState([
        {
            industryVertical: defaultIndustryLabel,
        },
    ]);

    const [outcomeList] = useState([
        {
            value: defaultOutcomeLabel,
            label: defaultOutcomeLabel,
        },
        ...outcomeOptions,
    ]);

    const [expectedOutcomeList] = useState([
        {
            value: defaultOutcomeLabel,
            label: defaultOutcomeLabel,
        },
        ...expectedOutcomeOptions,
    ]);

    const [selectedProject, setProject] = useState(defaultProjectLabel);
    const [selectedProjectId, setProjectId] = useState(null);
    const [selectedIndustry, setIndustry] = useState(defaultIndustryLabel);
    const [selectedOutcome, setOutcome] = useState(defaultOutcomeLabel);
    const [selectedExpectedOutcome, setExpectedOutcome] = useState(defaultOutcomeLabel);
    const [industryFocus, toggleIndustryFocus] = useState(false);
    const [outcomeFocus, toggleOutcomeFocus] = useState(false);
    const [expectedOutcomeFocus, toggleExpectedOutcomeFocus] = useState(false);
    const [projectFocus, toggleProjectFocus] = useState(false);

    const fetchListData = async () => {
        try {
            const projectListPromise = API.get(ENDPOINTS.getUrl(CONSTANTS.GET_PROJECT_LIST));
            const industryListPromise = API.get(ENDPOINTS.getUrl(CONSTANTS.GET_INDUSTRY_LIST));

            const [projectListData, industryListData] = await Promise.all([projectListPromise, industryListPromise]);

            setProjectList([
                {
                    name: defaultProjectLabel,
                    projectId: 0,
                },
                ...projectListData.data,
            ]);
            setIndustryList([
                {
                    industryVertical: defaultIndustryLabel,
                },
                ...industryListData.data,
            ]);
        } catch (err) {
            showError('There was an error retrieving the project and industry lists', err);
        }
    };

    const exportData = async () => {
        try {
            const started = new Date();
            setIsExporting(true);
            const helper = API;

            let url = '';
            let headers = [];

            if (searchingQuestions) {
                url = ENDPOINTS.getUrl(CONSTANTS.EXPORT_ALL_QUESTIONS);
                headers = [
                    'Project Name',
                    'Industry Vertical',
                    'Response Classification',
                    'Question',
                    'Answer',
                    'Last Answered At',
                    'Answered By',
                    'Classified By',
                    'Tags',
                ];
            } else {
                url = ENDPOINTS.getUrl(CONSTANTS.EXPORT_ALL_PROJECTS);
                headers = [
                    'Project Name',
                    'Industry Vertical',
                    'Updated At',
                    'Created By',
                    'Expected Outcome',
                    'Actual Outcome',
                    'Project Value',
                    'Outcome Reason',
                    'Tags',
                ];
            }

            const result = await helper.post(url, getParamsForSearch());

            logEvent(`INSIGHTS_EXPORT_DATA`, started, getParamsForSearch());

            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                filename: 'pearler-export',
                useTextFile: false,
                useBom: true,
                headers,
            };

            let data;

            if (searchingQuestions) {
                data = _map(
                    result.data,
                    ({
                        name,
                        industryVertical,
                        responseClassification,
                        question,
                        answer,
                        updatedAt,
                        answeredByUser,
                        classifiedByUser,
                        tags,
                    }) => {
                        return {
                            name: name || '',
                            industryVertical: industryVertical || '',
                            responseClassification: responseClassification || '',
                            question: question || '',
                            answer: answer || '',
                            updatedAt: moment(updatedAt).format('YYYY-M-D'),
                            answeredByUser: answeredByUser || '',
                            classifiedByUser: classifiedByUser || '',
                            tags: tags || '',
                        };
                    },
                );
            } else {
                data = _map(
                    result.data,
                    ({
                        name,
                        industryVertical,
                        createdBy,
                        projectExpectedOutcome,
                        projectOutcome,
                        updatedAt,
                        projectValue,
                        projectOutcomeReason,
                        tags,
                    }) => {
                        return {
                            name: name || '',
                            industryVertical: industryVertical || '',
                            updatedAt: moment(updatedAt).format('YYYY-M-D'),
                            createdBy: createdBy?.fullName || '',
                            projectExpectedOutcome: projectExpectedOutcome || '',
                            projectOutcome: projectOutcome || '',
                            projectValue: projectValue || '',
                            projectOutcomeReason: projectOutcomeReason || '',
                            tags: tags || '',
                        };
                    },
                );
            }

            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(data);
            setIsExporting(false);
        } catch (err) {
            setIsExporting(false);
            showError('There was an error exporting your insights data', err);
        }
    };

    function getParamsForSearch() {
        const parameters: { [key: string]: string } = {};

        if (fromDate) {
            parameters.after = fromDate.format('YYYY-MM-DD');
        }
        if (toDate) {
            parameters.before = toDate.format('YYYY-MM-DD');
        }

        if (searchingQuestions && complianceSelections.length > 0) {
            parameters.complianceStatusList = complianceSelections.join(',');
        }

        if (selectedProjectId && selectedProjectId !== 0) {
            parameters.projectId = selectedProjectId;
        }

        if (selectedIndustry && selectedIndustry != defaultIndustryLabel) {
            parameters.industryVertical = selectedIndustry;
        }

        if (selectedOutcome && selectedOutcome != defaultOutcomeLabel) {
            parameters.projectOutcome = selectedOutcome;
        }

        if (selectedExpectedOutcome && selectedExpectedOutcome != defaultOutcomeLabel) {
            parameters.projectExpectedOutcome = selectedExpectedOutcome;
        }

        return parameters;
    }

    const search = async () => {
        try {
            const started = new Date();
            setIsSearching(true);

            const helper = API;

            let url = '';

            if (searchingQuestions) {
                url = ENDPOINTS.getUrl(CONSTANTS.SEARCH_ALL_QUESTIONS);
            } else {
                url = ENDPOINTS.getUrl(CONSTANTS.SEARCH_ALL_PROJECTS);
            }

            const result = await helper.post(url, getParamsForSearch());

            if (result && result.data) {
                setSearchResults(result.data);
            }

            logEvent(`INSIGHTS_SEARCH_DATA`, started, getParamsForSearch());

            setIsSearching(false);
        } catch (err) {
            showError('There was an error search for your insights', err);
            setIsSearching(false);
        }
    };

    const reset = () => {
        setFromDate(null);
        setToDate(null);
        setComplianceSelections(_map(options, 'key'));
        setIndustry(defaultIndustryLabel);
        setProject(defaultProjectLabel);
        setProjectId(0);
        setOutcome(defaultOutcomeLabel);
        setExpectedOutcome(defaultOutcomeLabel);
    };

    useEffect(() => {
        fetchListData();
    }, []);

    useEffect(() => {
        search();
    }, [
        fromDate,
        toDate,
        complianceSelections,
        selectedIndustry,
        selectedProject,
        selectedProjectId,
        selectedOutcome,
        selectedExpectedOutcome,
    ]);

    useEffect(() => {
        reset();
        const isCustom = selectedReport.indexOf('Custom') !== -1;
        setShowControls(isCustom);

        const preset = getPresetByKey(selectedReport);

        if (preset?.configuration) {
            setFromDate(preset.configuration.fromDate || null);
            setToDate(preset.configuration.toDate || null);
            setComplianceSelections(preset.configuration.complianceSelections || []);
            setOutcome(preset.configuration.outcome || defaultOutcomeLabel);
            setExpectedOutcome(preset.configuration.expectedOutcome || defaultOutcomeLabel);
        }
    }, [selectedReport]);

    return (
        <Module
            moduleName="Insights"
            width="w-full"
            rightItem={
                <ButtonBar>
                    <Button
                        buttonType="SECONDARY"
                        label="Export to CSV"
                        onClick={exportData}
                        className="mr-16"
                        loading={isExporting}
                        disabled={isExporting || searchResults.length === 0}
                    />
                    <Button
                        buttonType={showControls ? 'SECONDARY' : 'PRIMARY'}
                        label={showControls ? 'Hide Settings' : 'Report Settings'}
                        onClick={() => {
                            setShowControls(!showControls);
                        }}
                    />
                </ButtonBar>
            }
        >
            <div
                className="grid h-full max-h-full gap-16"
                style={{
                    gridTemplateColumns: '300px auto',
                }}
            >
                <div className="h-full rounded-r-lg bg-faded-gray py-16">
                    <div className="mx-24 mb-16">
                        <h3 className="select-none text-14 uppercase leading-32 text-black-80">Reports</h3>
                    </div>
                    {reports.map(({ reportType, presets }, index) => {
                        return (
                            <div key={index} className="mx-8 mb-32">
                                <div className="mb-16 border-b pb-8 pl-16 text-14 text-black-80">{reportType}</div>
                                {presets.map((preset, index) => {
                                    const identifier = getIdentifier(reportType, preset.name);
                                    const isSelected = selectedReport === identifier;
                                    return (
                                        <div
                                            key={index}
                                            className={`mx-8 my-2 grid cursor-pointer select-none gap-8 truncate rounded-lg p-8 text-black hover:bg-blue-100 ${
                                                isSelected ? 'bg-blue-600 text-white hover:bg-blue-600' : ''
                                            }`}
                                            onClick={() => {
                                                selectReport(identifier);
                                            }}
                                        >
                                            {preset.name}
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
                <div
                    className="relative grid h-full max-h-full overflow-y-auto pr-16"
                    style={{ gridTemplateRows: 'calc(100vh - 104px)' }}
                >
                    <div className="">
                        {showControls && (
                            <div className="mb-16 rounded-md bg-gray-50 p-16">
                                <div>
                                    <div className="my-16 grid gap-16">
                                        <div className="grid w-full grid-cols-4 gap-16">
                                            <div>
                                                <label
                                                    htmlFor="from-date-picker"
                                                    className={`small-1-med mb-5 ${
                                                        fromDateFocus ? 'text-primary-blue-100' : 'text-black-60'
                                                    }`}
                                                >
                                                    {searchingQuestions ? 'Answered' : 'Created'} After
                                                </label>
                                                <DatePicker
                                                    placeholder={`${searchingQuestions ? 'Answered' : 'Created'} after`}
                                                    id="from-date-picker"
                                                    toggleDateFocus={setFromDateFocus}
                                                    allowPastDates={true}
                                                    dueDate={fromDate}
                                                    setDueDate={(date) => {
                                                        setFromDate(date);
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    htmlFor="to-date-picker"
                                                    className={`small-1-med mb-5 ${
                                                        toDateFocus ? 'text-primary-blue-100' : 'text-black-60'
                                                    }`}
                                                >
                                                    {searchingQuestions ? 'Answered' : 'Created'} Before
                                                </label>
                                                <DatePicker
                                                    placeholder={`${
                                                        searchingQuestions ? 'Answered' : 'Created'
                                                    } before`}
                                                    id="to-date-picker"
                                                    toggleDateFocus={setToDateFocus}
                                                    allowPastDates={true}
                                                    dueDate={toDate}
                                                    setDueDate={(date) => {
                                                        setToDate(date);
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <label
                                                    className={`small-1-med mb-5 ${
                                                        industryFocus ? 'text-primary-blue-100' : 'text-black-60'
                                                    }`}
                                                >
                                                    Industry
                                                </label>
                                                <Select
                                                    classesForInput="form-input mt-1 block w-full body-1-reg py-12 px-16 text-black-100 focus:outline-none focus:border-primary-blue-100 focus:shadow-none"
                                                    onFocusChange={toggleIndustryFocus}
                                                    value={selectedIndustry}
                                                    showArrow={false}
                                                >
                                                    <ul className="max-h-304 w-full overflow-y-scroll rounded-lg bg-white py-8 shadow-md">
                                                        {industryList.map((industry, index: number) => (
                                                            <li
                                                                key={index}
                                                                onClick={() => {
                                                                    setIndustry(industry.industryVertical);
                                                                }}
                                                                className="cursor-pointer bg-white px-16 py-10 hover:bg-primary-blue-100-opace-4 hover:text-primary-blue-100"
                                                            >
                                                                {industry.industryVertical}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Select>
                                            </div>
                                            <div>
                                                <label
                                                    className={`small-1-med mb-5 ${
                                                        projectFocus ? 'text-primary-blue-100' : 'text-black-60'
                                                    }`}
                                                >
                                                    Project
                                                </label>
                                                <Select
                                                    classesForInput="form-input mt-1 block w-full body-1-reg py-12 px-16 text-black-100 focus:outline-none focus:border-primary-blue-100 focus:shadow-none"
                                                    onFocusChange={toggleProjectFocus}
                                                    value={selectedProject}
                                                    showArrow={false}
                                                >
                                                    <ul className="max-h-304 w-full overflow-y-scroll rounded-lg bg-white py-8 shadow-md">
                                                        {projectList.map((project) => (
                                                            <li
                                                                key={project.projectId}
                                                                onClick={() => {
                                                                    const projectId = project.projectId;

                                                                    const matchingProject = _find(projectList, {
                                                                        projectId,
                                                                    });

                                                                    setProject(
                                                                        matchingProject?.name || defaultProjectLabel,
                                                                    );
                                                                    setProjectId(projectId);
                                                                }}
                                                                className="cursor-pointer bg-white px-16 py-10 hover:bg-primary-blue-100-opace-4 hover:text-primary-blue-100"
                                                            >
                                                                {project.name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Select>
                                            </div>
                                            <div>
                                                <label
                                                    className={`small-1-med mb-5 ${
                                                        outcomeFocus ? 'text-primary-blue-100' : 'text-black-60'
                                                    }`}
                                                >
                                                    Actual Outcome
                                                </label>
                                                <Select
                                                    classesForInput="form-input mt-1 block w-full body-1-reg py-12 px-16 text-black-100 focus:outline-none focus:border-primary-blue-100 focus:shadow-none"
                                                    onFocusChange={toggleOutcomeFocus}
                                                    value={selectedOutcome}
                                                    showArrow={false}
                                                >
                                                    <ul className="max-h-304 w-full overflow-y-scroll rounded-lg bg-white py-8 shadow-md">
                                                        {outcomeList.map((outcome, index: number) => (
                                                            <li
                                                                key={index}
                                                                onClick={() => {
                                                                    setOutcome(outcome.value);
                                                                }}
                                                                className="cursor-pointer bg-white px-16 py-10 hover:bg-primary-blue-100-opace-4 hover:text-primary-blue-100"
                                                            >
                                                                {outcome.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Select>
                                            </div>
                                            <div>
                                                <label
                                                    className={`small-1-med mb-5 ${
                                                        expectedOutcomeFocus ? 'text-primary-blue-100' : 'text-black-60'
                                                    }`}
                                                >
                                                    Expected Outcome
                                                </label>
                                                <Select
                                                    classesForInput="form-input mt-1 block w-full body-1-reg py-12 px-16 text-black-100 focus:outline-none focus:border-primary-blue-100 focus:shadow-none"
                                                    onFocusChange={toggleExpectedOutcomeFocus}
                                                    value={
                                                        _find(expectedOutcomeList, {
                                                            value: selectedExpectedOutcome,
                                                        })?.label || selectedExpectedOutcome
                                                    }
                                                    showArrow={false}
                                                >
                                                    <ul className="max-h-304 w-full overflow-y-scroll rounded-lg bg-white py-8 shadow-md">
                                                        {expectedOutcomeList.map((outcome, index: number) => (
                                                            <li
                                                                key={index}
                                                                onClick={() => {
                                                                    setExpectedOutcome(outcome.value);
                                                                }}
                                                                className="cursor-pointer bg-white px-16 py-10 hover:bg-primary-blue-100-opace-4 hover:text-primary-blue-100"
                                                            >
                                                                {outcome.label}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </Select>
                                            </div>
                                        </div>
                                        {searchingQuestions && (
                                            <div className="w-600">
                                                <label className={`small-1-med text-black-60`}>Compliance Status</label>
                                                <div className="pt-8">
                                                    <ComplianceSelect
                                                        selections={complianceSelections}
                                                        onUpdate={setComplianceSelections}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            <Row className="mb-8 border-b border-gray-light-1 px-8">
                                <ColHeader className={`w-3-of-24`} text="Project" />
                                {searchingQuestions && (
                                    <>
                                        <ColHeader className={`w-5-of-24`} text="Question" />
                                        <ColHeader className={`w-5-of-24`} text="Answer" />
                                        <ColHeader className={`w-2-of-24`} text="Answered On" />
                                        <ColHeader className={`w-3-of-24`} text="Answered By" />
                                        <ColHeader className={`w-3-of-24`} text="Classified By" />
                                        <ColHeader className={`w-3-of-24`} text="Classification" />
                                    </>
                                )}
                                {!searchingQuestions && (
                                    <>
                                        <ColHeader className={`w-2-of-24`} text="Updated At" />
                                        <ColHeader className={`w-3-of-24`} text="Expected Outcome" />
                                        <ColHeader className={`w-2-of-24`} text="Outcome" />
                                        <ColHeader className={`w-2-of-24`} text="Value" />
                                        <ColHeader className={`w-6-of-24`} text="Reason" />
                                        <ColHeader className={`w-3-of-24`} text="Vertical" />
                                        <ColHeader className={`w-3-of-24`} text="Created By" />
                                    </>
                                )}
                            </Row>
                            {!isSearching && searchResults.length === 0 && (
                                <EmptyState
                                    width="w-full"
                                    className="mt-16"
                                    title="No results found"
                                    subtitle="Try altering changing report or adjusting your search settings"
                                />
                            )}
                            {searchResults.map((result, index) => {
                                return (
                                    <Row
                                        dataCy="Result_Row"
                                        key={index}
                                        spacing="py-16"
                                        className={`rounded-sm border-black-10 px-8 hover:bg-gray-50`}
                                    >
                                        <Col className={`w-3-of-24`}>
                                            <Link
                                                className="text-primary-blue-100 underline"
                                                to={createUrl(ROUTES.PROJECT_DASHBOARD, {
                                                    projectId: result.projectId,
                                                })}
                                            >
                                                {result.name}
                                            </Link>
                                        </Col>

                                        {searchingQuestions && (
                                            <>
                                                <Col className={`w-5-of-24`}>{result.question}</Col>
                                                <Col className={`w-5-of-24`}>{result.answer}</Col>
                                            </>
                                        )}

                                        <Col className={`w-2-of-24`}>
                                            {moment(result.updatedAt).format('Do MMM YYYY')}
                                        </Col>

                                        {searchingQuestions && (
                                            <>
                                                <Col className={`w-3-of-24`}>{result.answeredByUser}</Col>
                                                <Col className={`w-3-of-24`}>{result.classifiedByUser}</Col>
                                                <Col className={`w-3-of-24`}>
                                                    {createPillForCompliance(result.responseClassification)}
                                                </Col>
                                            </>
                                        )}
                                        {!searchingQuestions && (
                                            <>
                                                <Col className={`w-3-of-24`}>
                                                    {
                                                        _find(expectedOutcomeOptions, {
                                                            value: result.projectExpectedOutcome,
                                                        })?.label
                                                    }
                                                </Col>
                                                <Col className={`w-2-of-24`}>{result.projectOutcome}</Col>
                                                <Col className={`w-2-of-24`}>{result.projectValue}</Col>
                                                <Col className={`w-6-of-24`}>{result.projectOutcomeReason}</Col>
                                                <Col className={`w-3-of-24`}>{result.industryVertical}</Col>
                                                <Col className={`w-3-of-24`}>{result.createdBy?.fullName}</Col>
                                            </>
                                        )}
                                    </Row>
                                );
                            })}
                            {isSearching && <LoadingSpinner />}
                        </div>
                    </div>
                </div>
            </div>
        </Module>
    );

    //             </div>
    //             {searchSubmitted && isSearching && <Loader />}
    //             {searchSubmitted && !isSearching && searchResults.length === 0 && (
    //                 <div className="py-64">
    //                     <EmptyState
    //                         placeholderImage={<PersonSearching />}
    //                         title="No questions were found"
    //                         subTitle="Please check your search filters"
    //                     />
    //                 </div>
    //             )}
    //             {searchResults.length > 0 && !isSearching && (
    //                 <>
    //                     <h3 className="h6-medium mt-32">Search Results</h3>
    //                     {searchResults.length === 100 && (
    //                         <p className="subtitle-2-reg mb-8 text-black-80">
    //                             Search is limited to 100 questions. Click export to download all questions as a CSV.
    //                         </p>
    //                     )}
    //                     {searchResults.length < 100 && (
    //                         <p className="subtitle-2-reg mb-8 text-black-80">{searchResults.length} questions found.</p>
    //                     )}
    //                 </>
    //             )}
};
