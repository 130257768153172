import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const ThirdPartyLinkModal = ({
    projectId,
    closeModal,
    existingUrl,
    existingNotes,
}: {
    projectId: string;
    existingUrl: string;
    existingNotes: string;
    closeModal: () => void;
}): ReactElement => {
    const [linkUrl, setLinkUrl] = useState(existingUrl || '');
    const [notes, setNotes] = useState(existingNotes || '');
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [saving, setSaving] = useState(false);

    const validate = (linkUrl = ''): boolean => {
        const cleaned = linkUrl.trim();
        return cleaned && cleaned.length > 2;
    };

    const save = async () => {
        try {
            const started = new Date();
            setSaving(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_PROJECT, {
                projectId,
            });

            await API.post(url, {
                thirdPartyProjectUrl: linkUrl,
                thirdPartyProjectNotes: notes,
            });

            logEvent(`UPDATE_PORTAL_DETAILS`, started);
            setSaving(false);

            closeModal();
        } catch (err) {
            setSaving(false);
            showError('There was an error updating your project', err);
        }
    };

    useEffect(() => {
        setSaveEnabled(validate(linkUrl));
    }, [linkUrl]);

    return (
        <Modal width="w-420" closeModal={closeModal} title="Update Portal Details">
            <div>
                <FormTextField
                    placeholder="Portal URL"
                    label="Portal URL"
                    errorMessage="Provide a link to the Portal"
                    validator={validate}
                    valueChanged={setLinkUrl}
                    required={true}
                    containerClasses="mb-16"
                    defaultValue={linkUrl}
                    maxLength={256}
                />
                <FormTextField
                    inputType="textarea"
                    placeholder="Notes"
                    label="Notes"
                    errorMessage="Add notes, e.g. pass code, etc"
                    valueChanged={setNotes}
                    defaultValue={notes}
                    spellCheck={true}
                    maxLength={256}
                />
            </div>

            <ButtonBar className="mt-32">
                <Button buttonType="SECONDARY" label="Cancel" onClick={closeModal} />
                <Button label="Save" disabled={!saveEnabled || saving} onClick={save} loading={saving} />
            </ButtonBar>
        </Modal>
    );
};
