import { Avatar } from 'components/duex/Avatar';
import React, { CSSProperties, ReactElement, useEffect, useRef } from 'react';
import type { Question } from 'types/';
import { QuestionStatus } from './question-status.component';

export const QuestionRow = ({
    index,
    style,
    data: { onClick, onHover, onBlur, questionList, setRowHeight, activeQuestion },
}: {
    index: number;
    style: CSSProperties;
    data: {
        onClick: (question: Question) => void;
        onHover: ({
            question,
            position,
        }: {
            question: Question;
            position: {
                top: number;
                height: number;
            };
        }) => void;
        onBlur: () => void;
        setRowHeight: (index: number, height: number) => void;
        activeQuestion: string;
        questionList: Question[];
    };
}): ReactElement => {
    const question = questionList[index];

    const rowRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (rowRef.current) {
            setRowHeight(index, rowRef.current.clientHeight);
        }
    }, [rowRef]);

    const isSelected = question.questionId === activeQuestion;

    return (
        <a
            style={style}
            onClick={() => {
                onClick(question);
            }}
            onMouseEnter={() => {
                onHover({
                    question: question,
                    position: {
                        top: rowRef.current.getBoundingClientRect().top,
                        height: rowRef.current.clientHeight,
                    },
                });
            }}
            onMouseLeave={() => {
                onBlur();
            }}
        >
            <div
                className={`${
                    isSelected ? 'text-app-blue' : 'bg-white text-light-black hover:bg-faded-gray'
                } flex h-64 cursor-pointer select-none items-center justify-between pl-16`}
                ref={rowRef}
                data-cy={`questionRow`}
                style={{
                    backgroundColor: isSelected ? '#E2EBFF' : '',
                }}
            >
                <div
                    className={`flex h-24 w-24 items-center justify-center rounded-full bg-faded-blue text-11 leading-16 text-app-blue`}
                >
                    <Avatar size={24} userId={question.assignedToUserId} />
                </div>
                <div
                    className="h-32 w-75-percent overflow-hidden text-12 leading-16 "
                    style={{ display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
                >
                    {question.referenceNumber && (
                        <span
                            data-cy="Reference_Number"
                            className={`pr-8  ${isSelected ? 'text-app-blue' : 'text-primary-blue-80'}`}
                        >
                            {question.referenceNumber}
                        </span>
                    )}
                    {question.question}
                </div>

                <QuestionStatus question={question} />

                <div className={`${isSelected ? 'bg-primary-blue-80' : ''} h-full w-4 self-end rounded-r-sm`}></div>
            </div>
        </a>
    );
};
