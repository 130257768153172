import React, { ReactElement, useEffect, useState } from 'react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { testAutomation } from './TestAutomationAttribute';

export const FormTextField = ({
    valueChanged,
    validator,
    placeholder = '',
    label,
    errorMessage = '',
    required,
    inputType = 'text',
    containerClasses = '',
    defaultValue = '',
    rows = 10,
    maxLength = 255,
    spellCheck = false,
    disabled = false,
    includeLabel = true,
    onBlur = null,
    image = null,
    autoComplete = 'off',
    testId,
    className = '',
    minRows = 5,
    maxRows = 25,
}: {
    validator?: (value: string) => boolean;
    valueChanged?: (value: string) => void;
    placeholder?: string;
    label: string;
    required?: boolean;
    errorMessage?: string;
    inputType?: 'text' | 'textarea' | 'password' | 'resizing-textarea';
    containerClasses?: string;
    defaultValue?: string;
    rows?: number;
    maxLength?: number;
    minRows?: number;
    maxRows?: number;
    spellCheck?: boolean;
    disabled?: boolean;
    includeLabel?: boolean;
    autoComplete?: string;
    onBlur?: (newValue?: string) => void;
    image?: ReactElement;
    testId?: string;
    className?: string;
}): ReactElement => {
    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;
    const [fieldValue, setFieldValue] = useState('');
    const [fieldFocussed, setFieldFocussed] = useState(false);
    const [showingError, setShowingError] = useState(false);
    const [hasFocussed, setHasFocussed] = useState(false);

    let labelClasses = '';
    let inputClasses = '';

    if (showingError) {
        labelClasses = 'showing-error text-red';
        inputClasses = 'showing-error text-red border-red bg-white';
    } else if (fieldFocussed) {
        labelClasses = 'text-primary-blue-100';
        inputClasses = 'text-primary-blue-100 border-primary-blue-100 bg-white';
    } else if (disabled) {
        labelClasses = 'text-black-60';
        inputClasses = 'text-gray-600 bg-gray-100';
    } else {
        labelClasses = 'text-black-60';
        inputClasses = 'bg-white';
    }

    useEffect(() => {
        setFieldValue(defaultValue);
        if (validator) {
            setShowingError(!validator(defaultValue) && hasFocussed && !fieldFocussed);
        }
    }, [defaultValue]);

    const inputProps = {
        maxLength,
        id: fieldIdentifier,
        className: `form-input mt-1 block w-full body-1-reg py-12 focus:outline-none focus:shadow-none ${className} ${inputClasses} ${
            image ? 'pl-44 pr-16' : 'px-16 '
        }`,
        value: fieldValue,
        rows,
        spellCheck,
        disabled,
        autoComplete,
        onChange: (e) => {
            setFieldValue(e.target.value);
            if (valueChanged) {
                valueChanged(e.target.value);
            }
        },
        onFocus: () => {
            setFieldFocussed(true);
            setShowingError(false);
            setHasFocussed(true);
        },
        onBlur: () => {
            setFieldFocussed(false);

            if (validator) {
                setShowingError(!validator(fieldValue));
            }

            if (onBlur) {
                onBlur(fieldValue);
            }
        },
        placeholder,
        'data-cy': testAutomation(testId || label || placeholder),
    };

    const optionalParameters = { maxRows, minRows };

    return (
        <div className={containerClasses}>
            {includeLabel && (
                <label
                    htmlFor={fieldIdentifier}
                    className={`small-1-med mb-4 flex flex-row justify-between ${labelClasses}`}
                >
                    <span>{label}</span>
                    {required && <span className="float-right text-black-60">Required</span>}
                </label>
            )}
            <div className={`${image ? 'relative' : ''}`}>
                {image && <span className="absolute left-16 top-16">{image}</span>}
                {inputType === 'resizing-textarea' && <ReactTextareaAutosize {...inputProps} {...optionalParameters} />}
                {inputType === 'textarea' && <textarea {...inputProps}>{fieldValue}</textarea>}
                {(inputType === 'text' || inputType === 'password') && <input type={inputType} {...inputProps} />}
                {showingError && <span className="text-12 text-black-60">{errorMessage}</span>}
            </div>
        </div>
    );
};
