import { ClosedEye, Eye } from 'assets/eye.svg';
import { Key } from 'assets/key.svg';
// import { Google } from 'assets/google.svg';
import { Mail } from 'assets/mail.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { isValidEmail, isValidStringOfLength } from 'components/validation/string';
import { useUserContext } from 'hooks/useUserContext';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Microsoft } from 'assets/microsoft.svg';
import { ROUTES } from 'utility/ApplicationRoutes';
import { extractDetailsFromURL } from 'utility/Common';
import SSOHelper from 'utility/SSOHelper';

export const CreateAccount = (): ReactElement => {
    const navigate = useNavigate();
    const auth = useUserContext();
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showSpinner, toggleSpinner] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, toggleShowPassword] = useState(false);

    useEffect(() => {
        const urlEmail = extractDetailsFromURL('email');
        if (urlEmail) {
            setEmail(urlEmail);
        }
    }, []);

    const register = async () => {
        try {
            toggleSpinner(true);
            const cleanedEmail = email.trim().toLowerCase();

            const ssoResult = await SSOHelper.checkDomain(cleanedEmail);

            if (!ssoResult) {
                const { success } = await auth.signUp(cleanedEmail, password);

                if (success) {
                    navigate({
                        pathname: ROUTES.CONFIRM_REGISTRATION,
                        search: `?email=${encodeURIComponent(cleanedEmail)}`,
                    });
                }
            }

            toggleSpinner(false);
        } catch (error) {
            setErrorMsg(error.message);
            console.error({ error });
            toggleSpinner(false);
        }
    };

    function annotateErrorMessage(errorMsg: string): string {
        switch (errorMsg) {
            case 'User already exists':
                return 'An account already exists, click Sign In below.';
            case `1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$`:
            case 'Password did not conform with policy: Password not long enough':
                return 'Password is not long enough, it must include a symbol, number, uppercase letter, and be 8 characters long';
            default:
                return errorMsg;
        }
    }

    useEffect(() => {
        document.title = 'Pearler | Create Account';
    }, []);

    const formIsValid = isValidEmail(email) && isValidStringOfLength(password, 8);

    return (
        <div className="w-full">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    await register();
                }}
                className="grid gap-16"
            >
                <h1 className="h3-bold select-none text-center text-primary-blue-80">Create an account</h1>

                {errorMsg && (
                    <p className={`subtitle-2-reg min-h-20 text-left text-red`}>{annotateErrorMessage(errorMsg)}</p>
                )}

                <fieldset className="grid gap-16">
                    <FormTextField
                        defaultValue={email}
                        label="Work Email"
                        validator={(value: string) => isValidEmail(value)}
                        valueChanged={(val: string) => {
                            setEmail(val);
                        }}
                        onBlur={() => {
                            SSOHelper.checkDomain(email);
                            setErrorMsg('');
                        }}
                        placeholder="Work Email"
                        errorMessage="Please enter your work email address"
                        image={<Mail />}
                        autoComplete="email"
                        maxLength={250}
                    />
                    <div className="relative">
                        <FormTextField
                            defaultValue={password}
                            inputType={showPassword ? 'text' : 'password'}
                            label="Password"
                            validator={(value: string) => isValidStringOfLength(value, 8)}
                            valueChanged={(val: string) => {
                                setPassword(val);
                            }}
                            onBlur={() => {
                                setErrorMsg('');
                            }}
                            placeholder="Password"
                            errorMessage="Please enter a password"
                            autoComplete="new-password"
                            image={<Key />}
                            maxLength={250}
                        />
                        <div
                            className="absolute right-20 top-36 cursor-pointer"
                            onClick={() => toggleShowPassword(!showPassword)}
                            title={showPassword ? 'Hide Password' : 'Reveal Password'}
                        >
                            {showPassword ? <Eye /> : <ClosedEye />}
                        </div>
                    </div>

                    <div className="rounded-sm border border-gray-100 bg-gray-50 p-8 text-11 text-gray-500">
                        <span className="text-12 font-medium">Please use a password that has:</span>
                        <ul className="px-4 py-4">
                            <li>At least 8 characters</li>
                            <li>1 lower case character</li>
                            <li>1 upper case character</li>
                            <li>1 special character</li>
                        </ul>
                    </div>
                </fieldset>
                <div className="grid w-full gap-16">
                    <ButtonBar>
                        <Button
                            buttonType="LARGE_PRIMARY"
                            action="submit"
                            label="Create Account"
                            loading={showSpinner}
                            disabled={!formIsValid || showSpinner}
                            className="w-full"
                        />
                    </ButtonBar>
                    <ButtonBar className="grid w-full grid-cols-2">
                        <Button
                            buttonType="SECONDARY"
                            label="Back to Sign In"
                            onClick={() => {
                                navigate(ROUTES.LOGIN);
                            }}
                        />
                        <Button
                            buttonType="SECONDARY"
                            label="Forgot Password"
                            onClick={() => {
                                navigate(ROUTES.RESET_PASSWORD);
                            }}
                        />
                    </ButtonBar>
                    <p className="small-2-reg select-none text-black-60">
                        By creating an account, you&apos;re agreeing to our{' '}
                        <a
                            href="https://www.pearler.ai/terms"
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-blue-100 underline"
                        >
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a
                            href="https://www.pearler.ai/privacy"
                            target="_blank"
                            rel="noreferrer"
                            className="text-primary-blue-100 underline"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>
            </form>
        </div>
    );
};
