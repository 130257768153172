import { Logo } from 'assets/logo.svg';
import React from 'react';

interface Layout {
    component: React.ReactElement;
}

export const AuthLayout = ({ component }: Layout): React.ReactElement => {
    return (
        <div
            className="flex h-screen w-full flex-col justify-center"
            style={{
                backgroundColor: '#2F80ED',
                background: 'linear-gradient(113.01deg, #2F80ED 0.36%, #103668 50.18%, #2F80ED 100%)',
            }}
        >
            <div className="flex flex-col items-center justify-items-center">
                <div data-cy="Logo_Wrapper" className="my-32">
                    <Logo />
                </div>
                <div className="mx-auto w-500 max-w-full rounded-lg bg-white p-32 shadow-xl">
                    <div className="flex w-full flex-col items-center gap-32">
                        <div className="w-full">{component}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
