import _lowerFirst from 'lodash/lowerFirst';

const COMPLIANT = 'Compliant';
const PARTIALLY_COMPLIANT = 'Partially Compliant';
const NON_COMPLIANT = 'Non Compliant';
const NOT_APPLICABLE = 'Not Applicable';
const INCOMPLETE = 'Incomplete';

const map = {
    COMPLIANT: COMPLIANT,
    'NON-COMPLIANT': NON_COMPLIANT,
    'PARTIALLY-COMPLIANT': PARTIALLY_COMPLIANT,
    'NOT-APPLICABLE': NOT_APPLICABLE,
    INCOMPLETE: INCOMPLETE,
};

const sortMap = {
    COMPLIANT: 0,
    'NON-COMPLIANT': 1,
    'PARTIALLY-COMPLIANT': 2,
    'NOT-APPLICABLE': 3,
    INCOMPLETE: 4,
};

export const complianceLabel = (complianceStatus: string): string => {
    if (map[complianceStatus]) {
        return map[complianceStatus];
    }

    return '';
};

export const getComplianceStorageKey = (complianceStatus: string): string => {
    const label = complianceLabel(complianceStatus);

    return _lowerFirst(label.replaceAll(' ', ''));
};

export const getComplianceSortKey = (complianceStatus: string): number => {
    return sortMap[complianceStatus];
};
