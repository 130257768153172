import React, { ReactElement, ReactNode } from 'react';

export const EmptyState = ({
    title,
    subtitle,
    className = '',
    width = 'w-full max-w-600',
    transparent = false,
    children,
}: {
    title: string;
    subtitle?: string;
    className?: string;
    width?: string;
    transparent?: boolean;
    children?: ReactNode;
}): ReactElement => {
    return (
        <div
            className={`${className} ${width} ${
                !transparent ? 'bg-blue-50' : ''
            }  mx-auto rounded-lg border-gray-300 p-32 `}
        >
            <h3 className="text-18 font-medium">{title}</h3>
            {subtitle && <p className="pt-8 text-14 font-medium text-gray-800">{subtitle}</p>}

            {children}
        </div>
    );
};
