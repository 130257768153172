import { Tabs } from 'components/duex/Tabs';
import { useTabs } from 'hooks/useTabs';
import React, { ReactElement, useEffect, useState } from 'react';
import { Project, ProjectDashboard } from 'types';
import { CONFIG } from 'utility/Config';
import { Assumptions } from './assumptions.component';
import { CardsWrapper } from './cards-wrapper.view';
import { Charts } from './charts.view';
import { Commitments } from './commitments.component';
import { ProjectAssigneeTable } from './project-assignee-table.view';

const defaultTabs = [
    {
        label: 'Assignments',
        isActive: true,
        key: 'ASSIGNMENTS',
    },
    {
        label: 'Assumptions',
        isActive: false,
        key: 'ASSUMPTIONS',
    },
    {
        label: 'Commitments',
        isActive: false,
        key: 'COMMITMENTS',
    },
];

export const ProjectOversight = ({
    project,
    dashboardData,
}: {
    project: Project;
    dashboardData: ProjectDashboard;
}): ReactElement => {
    const [screensize, changeScreenSize] = useState(window.innerWidth);
    const { tabs, changeTab, selectedTab } = useTabs(defaultTabs);

    useEffect(() => {
        window.removeEventListener('resize', () => changeScreenSize(window.innerWidth));
        window.addEventListener('resize', () => changeScreenSize(window.innerWidth));
        return window.removeEventListener('resize', () => changeScreenSize(window.innerWidth));
    }, []);

    return (
        <div
            className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'px-24' : 'pl-24'} py-24`}
            style={{
                gridArea: 'oversight-area',
            }}
        >
            <CardsWrapper project={project} dashboardData={dashboardData} />
            <Charts project={project} dashboardData={dashboardData} />

            <Tabs tabItems={tabs} onChange={changeTab} />
            <div className="pt-16 min-h-200">
                {selectedTab.key === 'ASSIGNMENTS' && (
                    <ProjectAssigneeTable project={project} dashboardData={dashboardData} />
                )}
                {selectedTab.key === 'COMMITMENTS' && <Commitments project={project} dashboardData={dashboardData} />}
                {selectedTab.key === 'ASSUMPTIONS' && <Assumptions project={project} dashboardData={dashboardData} />}
            </div>
        </div>
    );
};
