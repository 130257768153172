import { ChevronRight } from 'assets/chevron-right.svg';
import { Tags } from 'components/duex/Tags';
import { testAutomation } from 'components/duex/TestAutomationAttribute';
import { LIGHTBLUE, Pill } from 'components/pill.component';
import _find from 'lodash/find';
import _truncate from 'lodash/truncate';
import { QuestionListFilter } from 'modules/project/project.view';
import React from 'react';
import { Project, Question } from 'types';
import { CONSTANTS } from 'utility/Constants';

interface Breadcrumb {
    label: string;
    description: string;
    filter?: string;
    filterData?: string;
}

export const QuestionContext = ({
    question,
    project,
    setFilters,
}: {
    question: Question;
    project: Project;
    setFilters: (updatedFilters: QuestionListFilter[]) => void;
}) => {
    const breadcrumbs: Breadcrumb[] = [];

    if (question.projectSourceId) {
        const document = _find(project.sources, {
            projectSourceId: question.projectSourceId,
        });

        if (document) {
            breadcrumbs.push({
                label: document.fileName,
                description: `Document: ${document.fileName}`,
                filter: CONSTANTS.FILTER_DOCUMENT,
                filterData: question.projectSourceId,
            });
        }
    }

    if (question.sheetId) {
        const sheet = _find(project.sheets, {
            sheetId: question.sheetId,
        });

        if (sheet) {
            breadcrumbs.push({
                label: sheet.sheetName,
                description: `Sheet: ${sheet.sheetName}`,
                filter: CONSTANTS.FILTER_SHEET,
                filterData: question.sheetId,
            });
        }
    }

    if (question.sectionDescription) {
        breadcrumbs.push({
            label: question.sectionDescription,
            description: `Section: ${question.sectionDescription}`,
            filter: CONSTANTS.FILTER_SECTION,
            filterData: question.sectionDescription,
        });
    }

    if (question.referenceNumber) {
        breadcrumbs.push({
            label: question.referenceNumber,
            description: `Reference Number: ${question.referenceNumber}`,
        });
    }
    return (
        <div className="mb-8">
            <div className="flex gap-8 text-14 flex-row  items-center -mx-8">
                {breadcrumbs.map((breadcrumb, index) => {
                    const last = index === breadcrumbs.length - 1;

                    const label = (
                        <span
                            data-cy={testAutomation(breadcrumb.description.split(':')[0])}
                            className={`${breadcrumb.filter ? 'hover:underline cursor-pointer hover:bg-gray-50 text-blue-500' : 'text-gray-600'} p-8 rounded-md`}
                            title={breadcrumb.description}
                            onClick={() => {
                                if (breadcrumb.filter) {
                                    setFilters([
                                        {
                                            key: breadcrumb.filter,
                                            data: [breadcrumb.filterData],
                                        },
                                    ]);
                                }
                            }}
                        >
                            {_truncate(breadcrumb.label, {
                                length: 30,
                                omission: '...',
                            })}
                        </span>
                    );

                    if (last) {
                        return label;
                    }

                    return (
                        <>
                            {label}
                            <ChevronRight />
                        </>
                    );
                })}
            </div>
            {question.importance && (
                <div className="mb-8 -mx-8">
                    <Pill
                        dataCy="Importance"
                        title={_truncate(question.importance, {
                            length: 100,
                            omission: '...',
                        })}
                        alt={`Importance ${question.importance}`}
                        colour={LIGHTBLUE}
                    />
                </div>
            )}
            <Tags scopeIdentifier={question.questionId} scope="QUESTION" />
        </div>
    );
};
