import { Information } from 'assets/information.svg';
import React from 'react';

export const InformationNotice = ({
    title,
    description,
    className = '',
}: {
    title: string;
    description: string;
    className?: string;
}) => {
    return (
        <div className={`bg-blue-100 rounded-md p-16 text-blue-500 flex flex-row gap-16 items-center ${className}`}>
            <Information strokeColor="currentColor" />
            <div>
                <p className="font-semibold text-16 text-blue-600">{title}</p>
                <p className="font-medium text-14 text-blue-600">{description}</p>
            </div>
        </div>
    );
};
