import _take from 'lodash/take';
import React, { ReactElement } from 'react';
import { Avatar } from './Avatar';
export const Contributors = ({ team = [] }: { team: string[] }): ReactElement => {
    const limit = 5;
    const trimmedTeam = _take(team, limit);

    const classes = ['z-5', 'z-4', 'z-3', 'z-2', 'z-1'];

    return (
        <div className="relative flex h-32">
            {trimmedTeam.map((userId, index) => {
                return (
                    <span
                        key={userId}
                        className={`absolute inline h-32 w-32 hover:z-12 ${classes[index]}`}
                        style={{
                            left: index * 16,
                        }}
                    >
                        <Avatar userId={userId} size={32} extraClasses="border border-white" />
                    </span>
                );
            })}
        </div>
    );
};
