import { ThinSingleChevron } from 'assets/thin-single-chevron.svg';
import { Button } from 'components/duex/Button';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { showError } from 'components/error-toast.component';
import { useTeamContext } from 'hooks/useTeamContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import Division from 'types/division.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { AddDivisionModal } from './add-division-modal';
import { EditDivisionModal } from './edit-division-modal';

// The code of this does not support infinite nesting, it's limited to three by design.

interface OrderedDivision extends Division {
    children?: OrderedDivision[];
}

function getChildren(divisionId: string, divisions: Division[]): Division[] {
    return _filter(divisions, {
        parentDivisionId: divisionId,
    });
}

function organiseDivisions(divisions: Division[]): OrderedDivision[] {
    const topLevel = getChildren(null, divisions);

    return _map(topLevel, (division: Division) => {
        const children = getChildren(division.divisionId, divisions);

        return {
            ...division,
            depth: 0,
            children: _map(children, (child: Division) => {
                return {
                    ...child,
                    depth: 1,
                    children: _map(getChildren(child.divisionId, divisions), (grandchild) => {
                        return {
                            ...grandchild,
                            depth: 2,
                            children: [],
                        };
                    }),
                };
            }),
        };
    });
}

function getDivisionOptions(divisions: Division[]): Division[] {
    const topLevel = getChildren(null, divisions);

    return _flatten(
        _map(topLevel, (division) => {
            return [division, ...getChildren(division.divisionId, divisions)];
        }),
    );
}

export const Divisions = (): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [showDivisionModal, setShowDivisionModal] = useState(false);
    const [editDivisionId, setEditDivisionId] = useState(null);
    const [divisions, setDivisions] = useState([]);
    const [sortedDivisions, setSortedDivisions] = useState([]);
    const { refresh } = useTeamContext();

    const fetchDivisions = async () => {
        try {
            setLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.GET_DIVISIONS);
            const result = await API.get(url);

            if (result) {
                setDivisions(result.data);
                setSortedDivisions(organiseDivisions(result.data));
            }

            setLoading(false);
        } catch (err) {
            showError('There was an error retrieving your divisions', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDivisions();
    }, []);

    useEffect(() => {
        document.title = 'Pearler | Divisions';
    }, []);

    const renderDivision = (division: OrderedDivision) => {
        const indent = ['pl-16', 'pl-16', 'pl-32'];

        return (
            <div key={division.divisionId}>
                <div
                    onClick={() => {
                        setEditDivisionId(division.divisionId);
                    }}
                    className={`my-4 rounded-md bg-gray-50 p-16 hover:bg-blue-50 cursor-pointer ${
                        indent[division.depth]
                    } flex flex-row `}
                >
                    {division.depth !== 0 && <ThinSingleChevron className="mr-4 mt-2" />}
                    {division.divisionName}
                </div>
                {division.children && division.children.map(renderDivision)}
            </div>
        );
    };

    return (
        <div>
            <div className="grid grid-cols-2">
                <div className="my-16 select-none text-26 font-medium text-black-100">Divisions</div>
                <div className="flex flex-row-reverse items-center">
                    <Button
                        label="Create Division"
                        onClick={() => {
                            setShowDivisionModal(true);
                        }}
                    />
                </div>
            </div>

            {loading && <LoadingSpinner className="pt-32" />}

            {!loading && <div className="">{sortedDivisions.map(renderDivision)}</div>}
            {showDivisionModal && (
                <AddDivisionModal
                    divisions={getDivisionOptions(divisions)}
                    closeModal={() => {
                        setShowDivisionModal(false);
                        fetchDivisions();
                        refresh();
                    }}
                />
            )}
            {editDivisionId && (
                <EditDivisionModal
                    divisions={getDivisionOptions(divisions)}
                    division={_find(divisions, {
                        divisionId: editDivisionId,
                    })}
                    closeModal={() => {
                        setEditDivisionId(null);
                        fetchDivisions();
                        refresh();
                    }}
                />
            )}
        </div>
    );
};
