import React, { ReactElement } from 'react';

const StyledTable = ({
    resetDefaultStyling,
    customStyling,
    uniqueStyling,
    children,
}: {
    resetDefaultStyling?: boolean;
    customStyling?: string;
    uniqueStyling?;
    children: ReactElement;
}): ReactElement => {
    return (
        <ul
            className={`${resetDefaultStyling ? customStyling : 'select-none border-none shadow-none'}`}
            style={uniqueStyling}
        >
            {children}
        </ul>
    );
};

export default StyledTable;
