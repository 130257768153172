import React, { ChangeEvent, Ref, forwardRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const FormFileUpload = forwardRef(function FormFileUpload(
    {
        valueChanged,
        label,
        required,
        containerClasses = '',
        disabled = false,
        includeLabel = true,
        dataCy,
        accept = '*',
    }: {
        valueChanged?: (value: ChangeEvent<HTMLInputElement>) => void;
        label: string;
        required?: boolean;
        containerClasses?: string;
        disabled?: boolean;
        includeLabel?: boolean;
        dataCy?: string;
        accept?: string;
    },
    ref: Ref<HTMLInputElement>,
) {
    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;
    const [fieldFocussed, setFieldFocussed] = useState(false);

    let labelClasses = '';
    let inputClasses = '';

    if (fieldFocussed) {
        labelClasses = 'text-primary-blue-100';
        inputClasses = 'text-primary-blue-100 border-primary-blue-100';
    } else if (disabled) {
        labelClasses = 'text-black-60';
        inputClasses = 'text-gray-600 bg-gray-100';
    } else {
        labelClasses = 'text-black-60';
        inputClasses = '';
    }

    return (
        <div className={containerClasses}>
            {includeLabel && (
                <label
                    htmlFor={fieldIdentifier}
                    className={`small-1-med mb-4 flex flex-row justify-between ${labelClasses}`}
                >
                    <span>{label}</span>
                    {required && <span className="float-right text-black-60">Required</span>}
                </label>
            )}
            <input
                data-cy={dataCy}
                id={fieldIdentifier}
                type="file"
                className={`btn-secondary w-full ${inputClasses}`}
                accept={accept}
                onChange={valueChanged}
                ref={ref}
                onFocus={() => {
                    setFieldFocussed(true);
                }}
                onBlur={() => {
                    setFieldFocussed(false);
                }}
            />
            {/* {showingError && <span className="text-12 text-black-60">{errorMessage}</span>} */}
        </div>
    );
});
