import { DropDownArrow } from 'assets/drop-close-arrow.svg';
import _find from 'lodash/find';
import React, { useState } from 'react';
import FloatingElement from './FloatingElement';
import { Menu } from './Menu';

interface Item {
    label?: string;
    testId?: string;
    value?: string;
}

export const Select = ({
    items,
    value,
    onChange,
    placeholder,
    width = 'w-full',
}: {
    items: Item[];
    value: string;
    onChange: (newValue: string) => void;
    placeholder?: string;
    width?: string;
}) => {
    const [menuOpen, setOpen] = useState(false);

    const selectItems = items.map((item) => {
        return {
            ...item,
            isSelected: value === item.value,
            onClick: () => {
                onChange(item.value);
                setOpen(false);
            },
        };
    });

    const selectedValue = _find(selectItems, {
        isSelected: true,
    });

    return (
        <div className={`bg-white rounded-md text-14 py-8 overflow-hidden border border-gray-200 ${width}`}>
            <button
                className={`px-16 text-left ${width} block relative ${
                    selectedValue ? 'text-black-80' : 'text-black-60'
                }`}
                onClick={() => {
                    if (!menuOpen) {
                        setOpen(true);
                    }
                }}
            >
                {selectedValue?.label || placeholder}
                <span className={`absolute right-8 top-2 duration-200 ease-linear ${menuOpen ? 'rotate-180' : ''}`}>
                    <DropDownArrow color="#2068E3" />
                </span>
            </button>
            {menuOpen && (
                <FloatingElement
                    showStyling={false}
                    close={() => {
                        setOpen(false);
                    }}
                    className={`${width} mt-16 max-h-304`}
                >
                    <Menu items={selectItems} />
                </FloatingElement>
            )}
        </div>
    );
};
