import { FilterIcon } from 'assets/filter.svg';
import { Folder } from 'assets/folder.svg';
import { Globe } from 'assets/globe.svg';
import { Button } from 'components/duex/Button';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Menu } from 'components/duex/Menu';
import { MenuButton } from 'components/duex/MenuButton';
import { Module } from 'components/duex/Module';
import SearchTextField from 'components/duex/SearchTextField';
import { showError } from 'components/error-toast.component';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { PERMISSION } from 'utility/Permission';
import { DashboardNotifications } from './notifications.view';
import { DashboardProjects } from './open-projects.view';
import { SearchResults } from './search-results.view';
import { DashboardActivity } from './your-activity.view';
import { DashboardInvitations } from './your-invitations.view';

// import { DashboardInvitations } from './your-invitations.view';

export function Dashboard(): ReactElement {
    const { hasPermission } = useUserContext();

    const [isSearching, setIsSearching] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const [inviteList, setInviteList] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [collections, setCollections] = useState([]);
    const [collectionFilters, setCollectionFilters] = useState({});

    const [searchValue, setSearchValue] = useState('');

    const navigate = useNavigate();
    const hasCreatePermission = hasPermission(PERMISSION.CREATE_PROJECT);

    const applySearch = (searchText: string) => {
        setIsSearching(Boolean(searchText));
        setSearchValue(searchText);
    };

    const fetchInvitations = async () => {
        try {
            setFetchingData(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_GET_INBOX);
            const result = await API.get(url);

            setFetchingData(false);

            if (result) {
                setInviteList(result.data);
            }
        } catch (err) {
            showError('There was an error retrieving your Assessment Inbox', err);
            setFetchingData(false);
        }
    };

    const fetchCollections = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_ANSWER_COLLECTION);

            const results = await API.get(url);
            if (results && results.data) {
                setCollections(results.data);
            }
        } catch (err) {
            showError('There was an error fetching the list of collections', err);
        }
    };

    const getCollectionFilters = () => {
        if (Object.keys(collectionFilters).length === 0) {
            return {};
        }

        return {
            collectionFilters: {
                includeFrom: _map(
                    _filter(collections, (collection) => {
                        return collectionFilters[collection.collectionId] === true;
                    }),
                    'collectionId',
                ),
                excludeFrom: _map(
                    _filter(collections, (collection) => {
                        return !collectionFilters[collection.collectionId];
                    }),
                    'collectionId',
                ),
                includeUnorganized: false,
            },
        };
    };

    const performSearch = async () => {
        try {
            const started = new Date();
            setLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.COMBINED_SEARCH_ANSWERS);

            const response = await API.post(url, {
                searchQuery: searchValue,
                ...getCollectionFilters(),
            });

            logEvent('DASHBOARD_SEARCH_ANSWERS', started);

            if (response && response.data) {
                setSearchResults(response.data);
            }
            setLoading(false);
        } catch (err) {
            showError('There was a problem searching for answers', err);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCollections();
    }, []);

    const menuOptions = [
        {
            label: 'Everywhere',
            icon: <Globe strokeColor="currentColor" />,
            onClick: () => {
                setCollectionFilters({});
            },
            isSelected: Object.keys(collectionFilters).length === 0,
        },
        {
            isDivider: true,
        },
        ..._map(collections, (collection) => {
            return {
                label: collection.collectionName,
                icon: <Folder strokeColor="currentColor" />,
                isSelected: collectionFilters[collection.collectionId] === true,
                onClick: () => {
                    const update = {
                        ...collectionFilters,
                    };

                    if (collectionFilters[collection.collectionId]) {
                        delete update[collection.collectionId];
                    } else {
                        update[collection.collectionId] = true;
                    }
                    setCollectionFilters(update);
                },
            };
        }),
    ];

    useEffect(() => {
        if (searchValue) {
            performSearch();
        }
    }, [searchValue, collectionFilters]);

    return (
        <div>
            <Module
                moduleName="Dashboard"
                width="w-full"
                middleItem={
                    <div
                        className="mx-auto w-600 grid gap-16 relative"
                        style={{
                            gridTemplateColumns: '550px auto',
                        }}
                    >
                        <SearchTextField applySearch={applySearch} placeholder="Search knowledgebase" />
                        <div className="mt-6">
                            <MenuButton
                                Icon={FilterIcon}
                                width="w-400"
                                forceHighlight={Object.keys(collectionFilters).length > 0}
                                menu={<Menu items={menuOptions} />}
                            />
                        </div>
                    </div>
                }
                rightItem={
                    hasCreatePermission ? (
                        <Button
                            onClick={() => {
                                navigate(ROUTES.PROJECT_CREATE);
                            }}
                            className="mr-16"
                            label="New Project"
                        />
                    ) : null
                }
            >
                {isSearching && (
                    <div className="mx-auto my-16 w-800">
                        {loading && <LoadingSpinner />}
                        {!loading && <SearchResults results={searchResults} />}
                    </div>
                )}
                {/* <div className="px-32">
                    <Select
                        items={[
                            {
                                value: '1234',
                                label: 'Adelaide',
                            },
                            {
                                value: '5678',
                                label: 'Sydney',
                            },
                        ]}
                        placeholder="Select a city"
                        value={selectedValue}
                        onChange={setSelectedValue}
                        width="w-400"
                    />
                </div>
                <div className="w-400 p-32">
                    <MenuButton
                        menu={
                            <Menu
                                items={[
                                    {
                                        label: 'Invite Team',
                                        icon: <People strokeColor="currentColor" />,
                                    },
                                    {
                                        label: 'Settings',
                                        icon: <Settings strokeColor="currentColor" />,
                                    },
                                    {
                                        label: 'Administration',
                                        icon: <Administration strokeColor="currentColor" />,
                                    },
                                    {
                                        isDivider: true,
                                    },
                                    {
                                        label: 'Documents',
                                        icon: <Documents strokeColor="currentColor" />,
                                    },
                                ]}
                            />
                        }
                    />
                </div> */}

                {!isSearching && (
                    <div className={`mt-16 grid grid-cols-2 gap-16 px-24 relative`}>
                        <div className="absolute top-0 right-16 z-2"></div>
                        <DashboardProjects />
                        <DashboardNotifications />
                        <DashboardActivity />
                        <DashboardInvitations
                            refreshData={fetchInvitations}
                            showSpinner={fetchingData}
                            invitations={inviteList}
                        />
                    </div>
                )}
            </Module>
        </div>
    );
}
