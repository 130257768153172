import { Button } from 'components/duex/Button';
import { EmptyState } from 'components/duex/EmptyState';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { Loader } from 'components/loader.component';
import { useSocket } from 'hooks/useSocket';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import React, { ReactElement, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { DocumentView } from './document-view';
import { SourceFileUpload } from './import-file';
import { SheetView } from './sheet-view';

export const Importer = ({
    projectId,
    setUnsavedChanges,
}: {
    projectId: string;
    setUnsavedChanges: (newValue: boolean) => void;
}): ReactElement => {
    const [files, setFiles] = useState([]);
    const [showUploader, setShowUploader] = useState(false);
    const [selectedFileIndex, setSelectedFileIndex] = useState(null);
    const [selectedSheetIndex, setSelectedSheetIndex] = useState(null);
    const [includedRowCounts, setIncludedRowCounts] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { latestMessage } = useSocket();

    const socketMessageReceived = () => {
        if (latestMessage && latestMessage.eventName) {
            if (latestMessage.eventName === 'SOURCE_DOUMENT_UPDATED') {
                const sameProject = latestMessage?.data?.projectId === projectId;

                if (sameProject) {
                    fetchData();
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.GET_SOURCE_DOCS, {
                projectId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                setFiles(results.data);
                setIsLoading(false);

                if (results.data.length === 0) {
                    setShowUploader(true);
                }
            }
        } catch (err) {
            showError('There was an error fetching the list of source documents', err);
            setIsLoading(false);
        }
    };

    const processCounts = () => {
        const counts = {};

        files.forEach((file) => {
            file.sheets.forEach((sheet) => {
                let count = 0;

                if (sheet.isLinked) {
                    const questionColumn = _find(sheet.columnClassifications, {
                        columnType: 'Question',
                    });

                    if (questionColumn) {
                        const linkedRows = _filter(sheet.rowInclusion, {
                            isLinked: true,
                        });

                        const validQuestions = _filter(linkedRows, ({ rowNumber }) => {
                            const matchingRow = _find(sheet.sheetContent, {
                                rowNumber,
                            });

                            if (!matchingRow) {
                                return false;
                            }

                            const answerColumn = _find(matchingRow.columns, {
                                columnNumber: questionColumn.columnNumber,
                            });

                            if (answerColumn && answerColumn.content !== '') {
                                return true;
                            }
                        });

                        count = validQuestions.length;
                    }
                }

                counts[sheet.sheetId] = count;
            });
        });

        setIncludedRowCounts(counts);
    };
    const undoChanges = () => {
        selectSheet(selectedFileIndex, selectedSheetIndex);
    };

    const selectSheet = (fileIndex, sheetIndex) => {
        setSelectedFileIndex(fileIndex);
        setSelectedSheetIndex(sheetIndex);
    };

    const selectFile = (fileIndex) => {
        setSelectedFileIndex(fileIndex);
        setSelectedSheetIndex(null);
    };

    useEffect(() => {
        processCounts();
    }, [files]);

    useEffect(() => {
        fetchData();
    }, [projectId]);

    useEffect(() => {
        socketMessageReceived();
    }, [latestMessage]);

    let selectedFile = null;
    let selectedSheet = null;

    if (selectedFileIndex !== null) {
        selectedFile = files[selectedFileIndex];

        if (selectedSheetIndex !== null && selectedFile) {
            selectedSheet = files[selectedFileIndex].sheets[selectedSheetIndex];
        }
    }

    return (
        <>
            {isLoading && (
                <div className="mx-auto mt-64">
                    <Loader />
                </div>
            )}
            {!isLoading && files.length === 0 && (
                <div className="mx-auto mt-32 max-w-400">
                    <EmptyState
                        title="Import Questions"
                        subtitle="You haven't uploaded any files yet, click the button below to start uploading."
                    >
                        <div className="flex flex-row justify-center">
                            <Button
                                onClick={() => {
                                    setShowUploader(true);
                                }}
                                label="Upload File"
                                className="mt-16"
                            />
                        </div>
                    </EmptyState>
                </div>
            )}
            {!isLoading && files.length > 0 && (
                <div className="flex max-h-full w-full flex-row gap-16">
                    <div className="w-250">
                        <div className="mx-8 my-4 flex flex-row justify-between text-12 font-bold uppercase">
                            <span>Files</span>
                            <button
                                className="hover:text-blue-700 hover:underline"
                                onClick={() => {
                                    setShowUploader(true);
                                }}
                            >
                                Add File
                            </button>
                        </div>
                        <div
                            data-cy="Sheet_List"
                            className="overflow-y-auto"
                            style={{ maxHeight: 'calc(100% - 24px)' }}
                        >
                            {files.map((file, fileIndex) => {
                                let questionCount = 0;

                                if (file && file.document) {
                                    questionCount = _filter(file.document.annotations, {
                                        annotationType: 'QUESTION',
                                    }).length;
                                }

                                return (
                                    <div
                                        key={fileIndex}
                                        className={`mb-16 overflow-hidden rounded-lg`}
                                        onClick={() => {
                                            if (file.document) {
                                                selectFile(fileIndex);
                                            }
                                        }}
                                    >
                                        <div className="select-none bg-blue-50 px-8 py-16 text-12 font-medium text-blue-800">
                                            {file.fileName || 'Copied into Pearler'}
                                        </div>
                                        {file.sheets && (
                                            <div>
                                                {file.sheets.map((sheet, sheetIndex) => {
                                                    return (
                                                        <div
                                                            data-cy="Single_Sheet"
                                                            className={`cursor-pointer bg-gray-50 px-8 py-8 text-12 text-gray-600 ${
                                                                sheet?.sheetId === selectedSheet?.sheetId
                                                                    ? 'bg-blue-100 text-gray-800'
                                                                    : 'hover:bg-blue-100 hover:text-gray-800'
                                                            }  flex flex-row justify-between`}
                                                            key={sheet.sheetId}
                                                            onClick={() => {
                                                                selectSheet(fileIndex, sheetIndex);
                                                            }}
                                                        >
                                                            <span className="leading-24">{sheet.sheetName}</span>
                                                            <span className="h-24 w-24 rounded-full bg-white text-center text-10 font-medium leading-24 text-gray-400">
                                                                {includedRowCounts[sheet.sheetId] || 0}
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                        {file.document && (
                                            <div
                                                className={`cursor-pointer bg-gray-50 px-8 py-8 text-12 text-gray-600 ${
                                                    fileIndex === selectedFileIndex
                                                        ? 'bg-blue-100 text-gray-800'
                                                        : 'hover:bg-blue-100 hover:text-gray-800'
                                                }  flex flex-row justify-between`}
                                                onClick={() => {
                                                    selectFile(fileIndex);
                                                }}
                                            >
                                                <span className="leading-24">Entire Document</span>
                                                <span className="h-24 w-24 rounded-full bg-white text-center text-10 font-medium leading-24 text-gray-400">
                                                    {questionCount}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="max-h-full w-80-percent">
                        {selectedSheet && (
                            <SheetView
                                projectId={projectId}
                                fetchData={fetchData}
                                undoChanges={undoChanges}
                                setUnsavedChanges={setUnsavedChanges}
                                selectedFile={selectedFile}
                                selectedSheet={selectedSheet}
                                selectedSheetIndex={selectedSheetIndex}
                            />
                        )}
                        {!selectedSheet && selectedFile && selectedFile.document && (
                            <DocumentView
                                projectId={projectId}
                                fetchData={fetchData}
                                undoChanges={undoChanges}
                                setUnsavedChanges={setUnsavedChanges}
                                selectedFile={selectedFile}
                            />
                        )}
                    </div>
                </div>
            )}

            {showUploader && (
                <Modal
                    width="w-478"
                    closeModal={() => {
                        setShowUploader(false);
                    }}
                    title="Add File"
                >
                    <SourceFileUpload
                        projectId={projectId}
                        onCancel={() => {
                            setShowUploader(false);
                        }}
                        onComplete={() => {
                            setShowUploader(false);
                            fetchData();
                        }}
                    />
                </Modal>
            )}
        </>
    );
};
