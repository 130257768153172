import React, { ReactElement } from 'react';

export const LoaderFile = ({ strokeColor = '#2068e3' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg
            style={{ margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto' }}
            width="61px"
            height="61px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <circle
                cx="50"
                cy="50"
                fill="none"
                stroke={strokeColor}
                strokeWidth="7"
                r="32"
                strokeDasharray="150.79644737231007 52.26548245743669"
            >
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                ></animateTransform>
            </circle>
        </svg>
    );
};
