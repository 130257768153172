import { useEffect, useState } from 'react';
import { singletonHook } from 'react-singleton-hook';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { API } from 'utility/Api';
import { log } from 'utility/Logging';
import Queue from './queueProcessor';

interface Message {
    eventName: string;
    // eslint-disable-next-line
    data: any | any[];
    scope: {
        [key: string]: string;
    };
}

interface SocketFunction {
    latestMessage: Message;
    disconnect: () => void;
    connect: () => void;
    isConnected: boolean;
}

const dummyMessage: Message = {
    eventName: '',
    data: {},
    scope: {},
};

const dummyObject: SocketFunction = {
    latestMessage: dummyMessage,
    connect: () => {
        // Do nothing
        log('WS - Connect - Do nothing');
    },
    disconnect: () => {
        // Do nothing
        log('WS - Disconnect - Do nothing');
    },
    isConnected: false,
};

function useSocketFn(): SocketFunction {
    const [latestMessage, setLatestMessage] = useState(dummyMessage);
    const [authenticated, setAuthenticated] = useState(false);

    const { sendMessage, lastMessage, readyState } = useWebSocket(process.env.REACT_APP_API_WEBSOCKET_ADDRESS, {
        shouldReconnect: () => {
            return true;
        },
        reconnectAttempts: 1000,
        reconnectInterval: 3000,
    });

    const checkConnection = async () => {
        if (readyState !== ReadyState.OPEN && authenticated) {
            setAuthenticated(false);
        }
    };

    Queue.setCallback((data: Message) => {
        setLatestMessage(data);
    });

    const processMessage = async () => {
        try {
            if (lastMessage && lastMessage.data) {
                const { eventName, data, scope } = JSON.parse(lastMessage.data);

                if (eventName == 'WS-CONNECTED' && readyState === ReadyState.OPEN) {
                    connect();
                } else {
                    if (scope.sessionIdentifier !== API.getSessionIdentifier()) {
                        Queue.add({
                            eventName: eventName.replace('SYSTEM_EVENT_', ''),
                            data,
                            scope,
                        });
                    }
                }
            }
        } catch (err) {
            console.log('Websocket error parsing message', err);
        }
    };

    const connect = async () => {
        try {
            if (!authenticated) {
                log('WS - Connecting');
                const authorization = await API.requestAuthToken();
                sendMessage(
                    JSON.stringify({
                        authorization,
                    }),
                );
                setAuthenticated(true);
            }
        } catch (err) {
            log('WS Connect Error', err);
        }
    };

    const disconnect = async () => {
        try {
            log('WS - Disconnecting');
            sendMessage(
                JSON.stringify({
                    disconnect: true,
                }),
            );
            setAuthenticated(false);
        } catch (err) {
            log('WS Disconnect Error', err);
        }
    };

    useEffect(() => {
        processMessage();
    }, [lastMessage]);

    useEffect(() => {
        checkConnection();
    }, [readyState]);

    return {
        latestMessage,
        connect,
        disconnect,
        isConnected: authenticated,
    };
}

export const useSocket = singletonHook(dummyObject, useSocketFn);
