import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormSelectField } from 'components/duex/FormSelectField';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { InformationNotice } from 'components/duex/Notice';
import { Tabs } from 'components/duex/Tabs';
import { showError } from 'components/error-toast.component';
import { GRAY, GREEN, Pill } from 'components/pill.component';
import { isValidStringOfLength } from 'components/validation/string';
import { isValidUrl } from 'components/validation/url';
import { useTabs } from 'hooks/useTabs';
import { useUserContext } from 'hooks/useUserContext';
import _isEqual from 'lodash/isEqual';
import _last from 'lodash/last';
import _pick from 'lodash/pick';
import { Label, SectionTitle, Value } from 'modules/answer-library/analytics/typography';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import Document from 'types/document.type';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { PERMISSION } from 'utility/Permission';

const editableProperties = [
    'availableToAttachToProjects',
    'documentName',
    'documentTypeId',
    'externalUrl',
    'storeFile',
];

const PREVIEW_TAB = 'PREVIEW_TAB';
const EDIT_TAB = 'EDIT_TAB';

const defaultTabs = [
    {
        label: 'Preview',
        isActive: true,
        key: PREVIEW_TAB,
    },
    {
        label: 'Edit',
        isActive: false,
        key: EDIT_TAB,
    },
];

function getDisplayDocument({ accessUrl, externalUrl }: Document) {
    // Commented out formats are unsupported for preview

    const formats = {
        bmp: 'image/bmp',
        // doc: 'application/msword',
        // docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        htm: 'text/htm',
        html: 'text/html',
        jpg: 'image/jpg',
        jpeg: 'image/jpeg',
        pdf: 'application/pdf',
        png: 'image/png',
        // ppt: 'application/vnd.ms-powerpoint',
        // pptx: 'applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation',
        tiff: 'image/tiff',
        txt: 'text/plain',
        // xls: 'application/vnd.ms-excel',
        // xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    let uri = '';

    if (accessUrl) {
        uri = accessUrl;
    } else {
        uri = externalUrl;
    }

    const path = uri.split('?')[0];
    const components = path.split('.');
    const extension = _last(components).toLowerCase();
    const fileType = formats[extension];

    if (!fileType) {
        return false;
    }
    return [
        {
            uri,
            fileType,
        },
    ];
}

export const EditDocumentModal = ({
    closeModal,
    document,
    documentTypes,
    refresh,
}: {
    closeModal: () => void;
    document: Document;
    documentTypes: DocumentType[];
    refresh: () => void;
}): ReactElement => {
    const { documentId } = document;
    const [isSaving, setIsSaving] = useState(null);
    const [documentSettings, setDocumentSettings] = useState<Document>(document);
    const { tabs, changeTab, selectedTab } = useTabs(defaultTabs);

    const getPayload = () => {
        return _pick(documentSettings, editableProperties);
    };

    const archiveDocument = async () => {
        try {
            const started = new Date();
            setIsSaving('ARCHIVE');
            const url = ENDPOINTS.getUrl(CONSTANTS.ARCHIVE_DOCUMENT, {
                documentId,
            });

            const results = await API.post(url, {});

            logEvent(`DOCUMENTS_UPDATE_STATUS_ARCHIVE`, started, {
                documentId,
            });

            if (results && results.data) {
                setIsSaving(null);
                refresh();
            }
        } catch (err) {
            showError('There was an error archiving that document', err);
        }
    };

    const reinstateDocument = async () => {
        try {
            const started = new Date();
            setIsSaving('REINSTATE');
            const url = ENDPOINTS.getUrl(CONSTANTS.REINSTATE_DOCUMENT, {
                documentId,
            });

            const results = await API.post(url, {});

            logEvent(`DOCUMENTS_UPDATE_STATUS_REINSTATE`, started, {
                documentId,
            });

            if (results && results.data) {
                setIsSaving(null);
                refresh();
            }
        } catch (err) {
            showError('There was an error reinstating that document', err);
        }
    };
    const save = async () => {
        try {
            if (getSaveReadiness()) {
                const started = new Date();
                setIsSaving('SAVING');

                const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_DOCUMENT, { documentId });
                const result = await API.post(url, getPayload());

                logEvent(`UPDATE_DOCUMENT`, started);

                if (result.data) {
                    setIsSaving(null);
                    closeModal();
                }
            }
        } catch (err) {
            setIsSaving(false);
            showError('There was an error updating your document', err);
        }
    };

    const getSaveReadiness = () => {
        return isValidStringOfLength(documentSettings?.documentName, 3);
    };

    const getAvailability = (documentStatus: string) => {
        if (documentStatus === 'DEACTIVE') {
            return ['Archived', GRAY];
        }
        if (documentStatus === 'UPLOADED') {
            return ['Available', GREEN];
        }

        return [];
    };

    const updateSetting = (propertyName: string, newValue: string | boolean) => {
        setDocumentSettings({
            ...documentSettings,
            [propertyName]: newValue,
        });
    };

    const { getUser, hasPermission } = useUserContext();
    const author = getUser(document.createdByUserId);

    const canEdit = hasPermission(PERMISSION.UPDATE_DOCUMENT);

    const [availabilityText, pillColour] = getAvailability(document.documentStatus);

    const hasChanges = !_isEqual(_pick(document, editableProperties), _pick(getPayload(), editableProperties));

    const displayDocument = getDisplayDocument(documentSettings);
    return (
        <Modal width="w-1000" closeModal={closeModal} title={document.documentName}>
            <div className="rounded-md bg-gray-50 p-16 mb-32">
                <div className="grid" style={{ gridTemplateColumns: 'auto auto' }}>
                    <div className="grid grid-cols-3">
                        <div>
                            <Label title="Author" />
                            <div
                                className="grid items-center gap-8"
                                style={{
                                    gridTemplateColumns: '32px auto',
                                }}
                            >
                                <Avatar userId={document.createdByUserId} size={32} />
                                <Value title={author.fullName || 'Unknown'} preventWrapping={true} />
                            </div>
                        </div>
                        <div>
                            <Label title="Created" />
                            <Value title={moment(document.createdAt).format('Do MMM YYYY')} />
                        </div>
                        <div className="text-center">
                            <Label title="Status" />
                            <Pill title={availabilityText} colour={pillColour} />
                        </div>
                    </div>
                    {canEdit && (
                        <ButtonBar className="items-center">
                            {document.documentStatus === 'DEACTIVE' && (
                                <Button
                                    label="Reinstate Document"
                                    buttonType="SECONDARY"
                                    onClick={reinstateDocument}
                                    disabled={isSaving}
                                    loading={isSaving === 'REINSTATE'}
                                />
                            )}
                            {document.documentStatus === 'UPLOADED' && (
                                <Button
                                    label="Archive Document"
                                    buttonType="SECONDARY"
                                    onClick={archiveDocument}
                                    disabled={isSaving}
                                    loading={isSaving === 'ARCHIVE'}
                                />
                            )}
                        </ButtonBar>
                    )}
                </div>
            </div>
            {displayDocument && <Tabs tabItems={tabs} onChange={changeTab} />}

            <div className="pt-24">
                {selectedTab.key === PREVIEW_TAB && displayDocument && (
                    <DocViewer
                        documents={displayDocument}
                        pluginRenderers={DocViewerRenderers}
                        config={{
                            header: {
                                disableFileName: true,
                            },
                        }}
                    />
                )}
                {(selectedTab.key === EDIT_TAB || !displayDocument) && (
                    <>
                        <fieldset className="flex flex-col gap-16">
                            <FormTextField
                                placeholder="Enter your document name here."
                                label="Document Name"
                                errorMessage="Please provide a document Name"
                                validator={(currentValue) => {
                                    return isValidStringOfLength(currentValue, 3);
                                }}
                                defaultValue={documentSettings?.documentName}
                                valueChanged={(newValue: string) => {
                                    updateSetting('documentName', newValue);
                                }}
                                required={true}
                                spellCheck={true}
                                maxLength={256}
                                disabled={!canEdit}
                            />
                            <FormSelectField
                                label="Document Type"
                                placeholder="Select the type of document"
                                errorMessage="Select the type of document"
                                defaultValue={documentSettings?.documentTypeId}
                                required={true}
                                items={documentTypes}
                                optionLabel="documentName"
                                optionValue="documentTypeId"
                                valueChanged={(newValue: string) => {
                                    updateSetting('documentTypeId', newValue);
                                }}
                                validator={(newValue: string) => {
                                    return isValidStringOfLength(newValue, 3);
                                }}
                                disabled={!canEdit}
                            />
                            <div>
                                {/* <SettingsItem>
                        <SettingsCheckbox
                            description="Allow this document to be attached to Projects"
                            currentValue={documentSettings?.availableToAttachToProjects || false}
                            onChange={(newValue) => {
                                updateSetting('availableToAttachToProjects', newValue);
                            }}
                            disabled={!canEdit}
                        />
                    </SettingsItem> */}
                                {/* <SettingsItem>
                        <SettingsCheckbox
                            description="Store and distribute this document for me"
                            currentValue={documentSettings?.storeFile || false}
                            onChange={(newValue) => {
                                updateSetting('storeFile', newValue);
                            }}
                            disabled={true}
                        />
                    </SettingsItem> */}
                            </div>
                            {documentSettings?.storeFile && (
                                <div className="flex flex-col gap-16">
                                    <SectionTitle title="Stored File" />
                                    <ButtonBar alignment="LEFT">
                                        <Button
                                            label="Download File"
                                            onClick={() => {
                                                window.open(documentSettings?.accessUrl);
                                            }}
                                        />
                                        {/* {canEdit && <Button label="Replace File" buttonType="TERTIARY" />} */}
                                    </ButtonBar>
                                </div>
                            )}
                            {!documentSettings?.storeFile && (
                                <div className="flex flex-col gap-16">
                                    <SectionTitle title="Linked File" />
                                    <InformationNotice
                                        title="Trust Centre"
                                        description="Documents that are linked are not available for download in the Trust Centre."
                                    />
                                    <FormTextField
                                        placeholder="Enter your URL that links to the document here."
                                        label="Access URL"
                                        errorMessage="Please provide a URL"
                                        validator={(currentValue) => {
                                            return isValidUrl(currentValue);
                                        }}
                                        defaultValue={documentSettings?.externalUrl}
                                        valueChanged={(newValue: string) => {
                                            updateSetting('externalUrl', newValue);
                                        }}
                                        required={true}
                                        maxLength={1024}
                                        disabled={!canEdit}
                                    />
                                    <div>
                                        <Button
                                            label="Open Link"
                                            onClick={() => {
                                                window.open(documentSettings?.externalUrl);
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </fieldset>
                        {canEdit && (
                            <ButtonBar className="mt-24">
                                <Button
                                    label="Cancel"
                                    onClick={closeModal}
                                    buttonType="SECONDARY"
                                    disabled={isSaving}
                                />
                                <Button
                                    label="Save Changes"
                                    onClick={save}
                                    disabled={!getSaveReadiness() || isSaving || !hasChanges}
                                    loading={isSaving === 'SAVING'}
                                />
                            </ButtonBar>
                        )}
                    </>
                )}
            </div>
        </Modal>
    );
};
