import { Chart, registerables } from 'chart.js';
import { Heatmap } from 'components/duex/Heatmap';
import _map from 'lodash/map';
import _union from 'lodash/union';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { Line } from 'react-chartjs-2';
import { Project, ProjectDashboard } from 'types';

Chart.register(...registerables);

export const Charts = ({
    project,
    dashboardData,
}: {
    project: Project;
    dashboardData: ProjectDashboard;
}): ReactElement => {
    const createLabel = (value: string) => {
        const thisDate = moment(value).endOf('day');
        const createdDate = moment(project.createdAt).endOf('day');

        if (thisDate.isBefore(createdDate)) {
            return 'Created';
        }

        const dueDate = moment(project.dueOn).endOf('day');

        if (dueDate.isSame(thisDate)) {
            return 'Due Date';
        }

        const todayDate = moment().endOf('day');
        if (todayDate.isSame(thisDate)) {
            return 'Today';
        }

        return moment(value).format('MMM D');
    };

    const burnDownChartData = dashboardData.completionProgress.map((item) => {
        return {
            x: createLabel(item.day),
            y: item.remainingQuestions,
        };
    });
    const burnDownChartLabels = dashboardData.completionProgress.map((item) => item.day);

    const idealBurnDownChartData = dashboardData.minimumProgress.map((item) => {
        return {
            x: createLabel(item.day),
            y: item.remainingQuestions,
        };
    });
    const idealBurnDownChartLabels = dashboardData.minimumProgress.map((item) => item.day);

    const combinedLabels = _union(burnDownChartLabels, idealBurnDownChartLabels);

    const labels = _map(combinedLabels, createLabel);

    const data = {
        datasets: [
            {
                label: 'Target Remaining Questions',
                data: idealBurnDownChartData,
                fill: false,
                borderColor: 'rgba(29, 67, 131, 0.3)',
                borderDash: [6, 6],
                borderWidth: 2,
                borderCapStyle: 'round',
                pointRadius: 0,
                type: 'line',
                order: 1,
            },
            {
                label: 'Remaining Questions',
                data: burnDownChartData,
                fill: true,
                backgroundColor: 'rgba(32, 104, 227, 0.3)',
                borderColor: '#2C66C9',
                pointRadius: 0,
                pointBackgroundColor: '#2068E3',
                borderWidth: 2,
                type: 'line',
                order: 2,
            },
        ],
        labels,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        spanGaps: false,
        scales: {
            y: {
                ticks: {
                    precision: 0,
                },
                beginAtZero: true,
                suggestedMin: 0,
                min: 0,
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };

    const heatMapData = _map(dashboardData.projectActivity, (item: { day: string; totalActionsCount: number }) => {
        return {
            date: item.day,
            count: item.totalActionsCount,
        };
    });

    let endDate: moment.Moment;

    const dueDate = moment(dashboardData.project.dueOn);
    const today = moment();

    if (today.isAfter(dueDate)) {
        endDate = today.add(1, 'month');
    } else {
        if (heatMapData.length) {
            const lastActivity = moment(dashboardData.projectActivity[heatMapData.length - 1].day);
            endDate = lastActivity.add(1, 'month');
        } else {
            endDate = dueDate.add(1, 'month');
        }
    }

    return (
        <div className="mb-28 flex justify-between ">
            <div className="burndown-chart flex w-48-percent flex-col rounded-lg border border-black-10 p-24">
                <p className="subtitle-1-med text-black-100">Questions Remaining </p>
                <p className="body-2-reg mb-20 text-black-80">
                    Track the amount of questions remaining for this assessment.
                </p>
                <div className="flex-grow-2">
                    {/* 
// @ts-ignore */}
                    <Line data={data} options={options} type="line" />
                </div>
            </div>
            <div className="w-48-percent rounded-lg border border-black-10 p-24 ">
                <p className="subtitle-1-med text-black-100">Daily Contributions </p>
                <p className="body-2-reg text-black-80">Track the amount of daily contributions on this assessment.</p>
                <Heatmap
                    height={'h-320'}
                    activity={heatMapData}
                    startDate={moment(project.createdAt).subtract(1, 'day')}
                    endDate={endDate}
                />
            </div>
        </div>
    );
};
