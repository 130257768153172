import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { EmptyState } from 'components/empty-states.component';
import { useUserContext } from 'hooks/useUserContext';
import _find from 'lodash/find';
import moment from 'moment';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ReactComponent as ActivityImage } from '../../../assets/activity-image.svg';
import { StatusSymbol } from '../../../assets/status-symbol.svg';
import { ProjectSharedProps } from '../project.view';
import { ActivityModal } from './activity-detail.component';
import { prepareActivityListElements } from './collaboration.controller';
export const Activity = ({ dataCy = '', ...props }: ProjectSharedProps): ReactElement => {
    const { teamList } = useUserContext();
    const { questionActivity, fetchingHistory } = props;
    const [showActivityDetail, setShowActivityDetail] = useState(null);

    const el = useRef(null);

    useEffect(() => {
        if (el.current) {
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
    });

    const activities = prepareActivityListElements(questionActivity, teamList);

    if (fetchingHistory) {
        return (
            <div className="flex h-full items-center justify-center">
                <LoadingSpinner />
            </div>
        );
    }

    if (activities.length === 0) {
        return (
            <div className="flex h-full items-center justify-center">
                <EmptyState
                    placeholderImage={<ActivityImage />}
                    title="There is no progress, yet"
                    subTitle="This is where you can follow the changes of changes to this question."
                />
            </div>
        );
    }

    const activity = _find(activities, {
        activityId: showActivityDetail,
    });

    return (
        <div className="h-full max-h-full overflow-auto px-24 pt-32">
            <ul className="pl-4">
                {activities.length &&
                    activities.map((activity, index) => {
                        const firstItem = index === 0;
                        const lastItem = index === activities.length - 1;
                        const allButFirstConditionalClasses = !firstItem ? 'border-l-2 border-faded-blue' : '';
                        const allButLastConditionalClasses = !lastItem ? 'border-l-2 border-faded-blue' : '';

                        return (
                            <li
                                key={activity.activityId}
                                className={`relative cursor-pointer rounded-r-md hover:bg-blue-50 ${
                                    firstItem ? 'rounded-t-md' : ''
                                } ${lastItem ? 'rounded-b-md' : ''}`}
                                onClick={() => {
                                    setShowActivityDetail(activity.activityId);
                                }}
                            >
                                <div className="absolute z-1" style={{ left: '-3px', top: '12px' }}>
                                    <StatusSymbol fillColor="#8AABE5" />
                                </div>
                                <p
                                    className={`${allButFirstConditionalClasses} small-3-reg tracking-0.2 select-none pl-16 pt-4 text-light-gray`}
                                    data-cy="ActivityDate"
                                >
                                    {moment(activity.updatedAt).format('Do MMMM YYYY @ h:mm a')}
                                </p>
                                <div className={`${allButLastConditionalClasses} relative flex flex-row`}>
                                    <div className="body-2-reg mb-24 ml-16 mt-4" data-cy={dataCy}>
                                        {activity.segments.map((segment, index) => {
                                            if (segment.type === 'MEMBER') {
                                                return (
                                                    <span key={index} className="text-heading-blue">
                                                        {segment.content}
                                                    </span>
                                                );
                                            }
                                            return segment.content;
                                        })}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                <li id={'el'} ref={el}></li>
            </ul>
            {showActivityDetail && (
                <ActivityModal
                    activity={activity}
                    closeModal={() => {
                        setShowActivityDetail(null);
                    }}
                />
            )}
        </div>
    );
};
