import { Inbox } from 'assets/inbox.svg';
import React, { ReactElement } from 'react';

export const QuestionNoResults = ({ clearFilter }: { clearFilter: () => void }): ReactElement => {
    return (
        <div className="ml-40 mr-40 mt-70 flex flex-col items-center">
            <Inbox />
            <p className="tracking-0.1 mb-8 mt-30 select-none text-center text-16 font-medium leading-24">No Results</p>
            <p className="mb-16 select-none text-center text-14 font-normal leading-22 text-black-60">
                We couldn&apos;t find any questions that match your selections.
            </p>
            <button
                className="leading-17 tracking-0.2 select-none rounded-lg border border-gray-400 bg-field-outline-blue px-24 py-11 text-center text-14 text-white"
                onClick={clearFilter}
            >
                Reset Filters
            </button>
        </div>
    );
};
