import React, { ReactElement } from 'react';

export const ChevronRight = ({
    strokeColour = '#A0A4A8',
    className = '',
}: {
    strokeColour?: string;
    className?: string;
}): ReactElement => {
    return (
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.5 11L6.5 6L1.5 1"
                stroke={strokeColour}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
