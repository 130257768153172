import React, { ReactElement } from 'react';

export const ErrorExclamation = ({ strokeColor }: { strokeColor: string }): ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.1191 9.804C13.1191 10.56 13.0911 11.2647 13.0351 11.918C12.9791 12.5713 12.9044 13.234 12.8111 13.906H11.2151C11.1217 13.234 11.0424 12.5713 10.9771 11.918C10.9211 11.2647 10.8931 10.56 10.8931 9.804V7.298H13.1191V9.804ZM13.3011 15.894C13.3011 16.3047 13.1657 16.622 12.8951 16.846C12.6337 17.07 12.3351 17.182 11.9991 17.182C11.6631 17.182 11.3597 17.07 11.0891 16.846C10.8277 16.622 10.6971 16.3047 10.6971 15.894C10.6971 15.4833 10.8277 15.166 11.0891 14.942C11.3597 14.718 11.6631 14.606 11.9991 14.606C12.3351 14.606 12.6337 14.718 12.8951 14.942C13.1657 15.166 13.3011 15.4833 13.3011 15.894Z"
                fill={strokeColor || '#FF5640'}
            />
            <rect x="1" y="1" width="22" height="22" rx="11" stroke={strokeColor || '#FF5640'} strokeWidth="2" />
        </svg>
    );
};
