import React, { ReactElement } from 'react';

export const TeamCreationImage = (): ReactElement => {
    return (
        <svg width="432" height="309" viewBox="0 0 432 309" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M357.648 194.975C358.025 194.193 358.225 193.339 358.235 192.472C358.245 191.604 358.064 190.746 357.706 189.956C357.347 189.166 356.82 188.464 356.161 187.9C355.501 187.336 354.726 186.923 353.89 186.69L352.576 165.652L343.813 172.24L346.404 191.208C346.106 192.637 346.343 194.126 347.07 195.392C347.798 196.659 348.965 197.615 350.351 198.079C351.737 198.543 353.246 198.484 354.59 197.912C355.935 197.339 357.023 196.294 357.648 194.975Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M395.847 277.671L402.807 279.664L413.818 253.799L403.545 250.858L395.847 277.671Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M414.378 289.963L392.502 283.698L394.925 275.257L408.349 279.101C410.591 279.743 412.485 281.248 413.616 283.285C414.747 285.322 415.021 287.724 414.378 289.963Z"
                    fill="#2F2E41"
                />
                <path
                    d="M384.694 301.432L377.453 301.431L374.007 273.538L384.695 273.538L384.694 301.432Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M372.28 299.365H386.245V308.146H363.487C363.487 306.993 363.715 305.851 364.157 304.786C364.599 303.72 365.246 302.752 366.063 301.937C366.879 301.122 367.848 300.475 368.915 300.033C369.982 299.592 371.125 299.365 372.28 299.365Z"
                    fill="#2F2E41"
                />
                <path
                    d="M364.667 187.706C364.667 187.706 360.533 204.223 367.621 237.845L372.936 295.652L386.521 296.242L384.749 233.716L389.474 207.762L401.287 243.744L397.153 269.108L407.193 273.827L418.415 239.025C418.415 239.025 417.234 190.656 412.509 184.757L364.667 187.706Z"
                    fill="#2F2E41"
                />
                <path
                    d="M381.822 93.6241C389.833 93.6241 396.328 87.1377 396.328 79.1364C396.328 71.1351 389.833 64.6487 381.822 64.6487C373.81 64.6487 367.315 71.1351 367.315 79.1364C367.315 87.1377 373.81 93.6241 381.822 93.6241Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M374.118 101.137C374.118 101.137 373.232 103.083 383.863 101.881C394.495 100.679 396.857 97.1621 396.857 97.1621L415.462 178.669L414.281 185.881L363.486 188.886V178.669L372.346 124.577L374.118 101.137Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M378.466 186.826L362.364 186.522L362.312 186.298C360.233 175.122 360.434 163.641 362.902 152.544C366.154 138.162 358.765 118.658 355.437 110.939C355.045 110.021 354.999 108.993 355.306 108.043C355.613 107.093 356.253 106.287 357.109 105.771L368.507 98.9415C369.283 98.4767 370.156 98.1994 371.058 98.1317C371.96 98.0639 372.865 98.2077 373.702 98.5515C374.538 98.8952 375.283 99.4295 375.876 100.112C376.469 100.794 376.894 101.605 377.117 102.48L379.11 110.288C388.288 120.144 387.645 140.361 385.484 155.6C383.939 166.097 381.616 176.464 378.533 186.616L378.466 186.826Z"
                    fill="#2F2E41"
                />
                <path
                    d="M360.238 104.241L358.845 105.192C355.699 107.328 353.336 110.431 352.114 114.03C351.405 116.128 350.863 118.279 350.492 120.462C349.311 127.54 343.995 157.623 343.995 157.623L341.633 187.117L354.627 184.167L360.533 149.955L366.44 128.72L360.238 104.241Z"
                    fill="#2F2E41"
                />
                <path
                    d="M406.447 186.966L406.325 186.627C405.48 184.289 385.768 129.212 395.078 109.791L394.166 100.683C394.068 99.7072 394.215 98.7225 394.592 97.8173C394.97 96.9122 395.567 96.1148 396.329 95.4969C397.091 94.879 397.995 94.46 398.96 94.2774C399.925 94.0948 400.92 94.1543 401.856 94.4507L416.101 98.9611C416.689 99.1443 417.202 99.5125 417.563 100.011C417.925 100.509 418.115 101.11 418.106 101.726C418.047 106.961 417.757 120.305 416.345 131.117C414.599 144.484 420.419 177.882 420.478 178.218L420.513 178.416L406.447 186.966Z"
                    fill="#2F2E41"
                />
                <path
                    d="M400.169 172.322C400.186 171.455 400.393 170.602 400.776 169.824C401.158 169.045 401.707 168.36 402.383 167.816C403.06 167.273 403.847 166.884 404.691 166.677C405.534 166.47 406.412 166.45 407.264 166.619L417.932 148.431L422.781 158.256L411.907 174.021C411.528 175.431 410.644 176.654 409.423 177.457C408.202 178.26 406.729 178.588 405.282 178.378C403.836 178.169 402.516 177.437 401.574 176.32C400.631 175.204 400.132 173.782 400.169 172.322Z"
                    fill="#FFB8B8"
                />
                <path
                    d="M413.986 98.9317L414.75 99.1078C415.694 99.3255 416.57 99.7746 417.298 100.414C418.025 101.054 418.582 101.865 418.918 102.773L432 138.158L416.643 171.78L411.032 157.918L418.12 142.582L405.421 126.36L413.986 98.9317Z"
                    fill="#2F2E41"
                />
                <path
                    d="M396.525 81.1391C397.358 78.9912 397.982 76.7681 398.388 74.5005C398.79 72.22 398.521 69.8718 397.614 67.7408C396.639 65.8771 395.275 64.244 393.614 62.9518C392.476 61.9848 391.281 61.0865 390.035 60.2616C386.525 57.9369 385.761 60.0522 381.551 59.5764C377.342 59.1006 372.624 57.8914 369.25 60.4009C367.067 62.0563 365.429 64.3243 364.544 66.9146C363.66 69.505 363.569 72.3001 364.283 74.9424C365.224 78.1393 364.475 79.1929 367.379 80.9207C367.378 78.0437 368.474 75.2741 370.444 73.1754C372.457 71.0693 375.179 69.783 378.086 69.5653C380.325 69.4032 382.555 69.8803 384.747 70.3544C386.873 70.8144 389.057 71.2979 390.86 72.4942C393.848 74.4767 395.249 78.0171 396.48 81.3382"
                    fill="#2F2E41"
                />
                <path d="M257.518 137.439H0V138.618H257.518V137.439Z" fill="#E6E6E6" />
                <path d="M257.518 91.4291H0V92.6088H257.518V91.4291Z" fill="#E6E6E6" />
                <path d="M257.518 45.4196H0V46.5993H257.518V45.4196Z" fill="#E6E6E6" />
                <path d="M206.605 0H205.424V184.038H206.605V0Z" fill="#E6E6E6" />
                <path d="M155.101 0H153.92V184.038H155.101V0Z" fill="#E6E6E6" />
                <path d="M103.598 0H102.417V184.038H103.598V0Z" fill="#E6E6E6" />
                <path d="M52.0944 0H50.9131V184.038H52.0944V0Z" fill="#E6E6E6" />
                <path d="M154.747 46.0095H51.3853V92.0189H154.747V46.0095Z" fill="#2F64BB" />
                <path d="M51.3853 88.4798V138.028L105.133 90.2494L51.3853 88.4798Z" fill="#2F64BB" />
                <path
                    d="M154.747 90.2494V92.019L154.074 92.6148L152.715 93.8181C126.296 94.6144 104.956 113.514 103.993 136.949L102.783 138.023L102.771 138.028V88.4798L154.747 90.2494Z"
                    fill="#2F64BB"
                />
                <path
                    d="M154.452 92.6089C154.328 92.6089 154.198 92.6088 154.074 92.6147C125.971 92.7917 103.131 113.036 102.783 138.023C102.771 138.223 102.771 138.418 102.771 138.618C102.771 163.988 125.954 184.628 154.452 184.628C182.95 184.628 206.133 163.988 206.133 138.618C206.133 113.248 182.95 92.6089 154.452 92.6089ZM154.452 183.448C126.609 183.448 103.952 163.34 103.952 138.618C103.952 138.058 103.964 137.503 103.993 136.949C104.956 113.514 126.296 94.6144 152.715 93.8181C153.288 93.7945 153.867 93.7886 154.452 93.7886C182.294 93.7886 204.951 113.897 204.951 138.618C204.951 163.34 182.294 183.448 154.452 183.448Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M126.101 138.038L123.148 138.022C123.149 137.759 123.622 111.784 153.832 109.982L154.008 112.927C126.61 114.561 126.109 137.08 126.101 138.038Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M184.267 137.439C184.153 134.077 183.364 130.772 181.945 127.72C180.527 124.669 178.508 121.934 176.01 119.678C170.137 114.329 162.459 111.391 154.511 111.449H154.452C138.227 111.449 124.985 123.052 124.636 137.439C124.625 137.633 124.625 137.834 124.625 138.028C124.625 145.555 128.151 152.356 133.809 157.193C139.586 162.041 146.906 164.67 154.452 164.608H154.511C170.93 164.578 184.279 152.669 184.279 138.028C184.279 137.834 184.279 137.633 184.267 137.439Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M176.01 119.678L175.124 120.462L134.695 156.409L133.809 157.193C128.151 152.356 124.625 145.555 124.625 138.028C124.625 137.834 124.625 137.633 124.636 137.439C124.985 123.052 138.227 111.449 154.452 111.449H154.511C162.459 111.391 170.137 114.329 176.01 119.678Z"
                    fill="#2F64BB"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="432" height="308.147" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
