import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { Modal } from 'components/duex/Modal';
import { complianceLabel } from 'data/compliance-status-options';
import { useUserContext } from 'hooks/useUserContext';
import React, { ReactElement } from 'react';
import { Question } from 'types';

export const QuestionChangedModal = ({
    question,
    incomingChanges,
    applyIncomingChanges,
    discardIncomingChanges,
}: {
    question: Question;
    incomingChanges: {
        userId;
        question: Question;
    };
    applyIncomingChanges: () => void;
    discardIncomingChanges: () => void;
}): ReactElement => {
    const headerClasses = `p-16 text-left text-12 sticky top-0 z-1 bg-white border-b border-gray-100 text-gray-500`;
    const cellClasses = `p-8 whitespace-pre-line break-words overflow-hidden font-body`;
    const rowClasses = `rounded-lg text-12 overflow-hidden hover:bg-gray-50`;

    const { getUser } = useUserContext();
    const author = getUser(incomingChanges.userId);

    return (
        <Modal title="Question Updated" showClose={false} width="w-720">
            <div className="flex flex-row gap-16">
                <Avatar size={40} userId={incomingChanges.userId} />
                <p className="text-14 leading-40 text-gray-500">Changes have been saved by {author.fullName}</p>
            </div>

            <table>
                <thead>
                    <tr>
                        <td className={headerClasses}>Field</td>
                        <td className={headerClasses}>Original</td>
                        <td className={headerClasses}>Updated</td>
                    </tr>
                </thead>
                <tbody>
                    {question.answer !== incomingChanges.question.answer && (
                        <tr className={rowClasses}>
                            <td className={cellClasses}>Answer</td>
                            <td className={cellClasses}>{question.answer}</td>
                            <td className={cellClasses}>{incomingChanges.question.answer}</td>
                        </tr>
                    )}

                    {question.responseClassification !== incomingChanges.question.responseClassification && (
                        <tr className={rowClasses}>
                            <td className={cellClasses}>Classification</td>
                            <td className={cellClasses}>{complianceLabel(question.responseClassification)}</td>
                            <td className={cellClasses}>
                                {complianceLabel(incomingChanges.question.responseClassification)}
                            </td>
                        </tr>
                    )}

                    {question.includeInLibrary !== incomingChanges.question.includeInLibrary && (
                        <tr className={rowClasses}>
                            <td className={cellClasses}>Include in Library</td>
                            <td className={cellClasses}>{question.includeInLibrary ? 'Yes' : 'No'}</td>
                            <td className={cellClasses}>{incomingChanges.question.includeInLibrary ? 'Yes' : 'No'}</td>
                        </tr>
                    )}
                </tbody>
            </table>

            <ButtonBar className="mt-16">
                <Button label="Accept Changes" onClick={applyIncomingChanges} buttonType="PRIMARY" />
                <Button label="Ignore" onClick={discardIncomingChanges} buttonType="TERTIARY" />
            </ButtonBar>
        </Modal>
    );
};
