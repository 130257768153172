import { Modal } from 'components/duex/Modal';
import React, { ReactElement } from 'react';
import { Project } from 'types';
import { Exporter } from './exporter.view';

export const ExporterModal = ({
    closeModal,
    projectId,
    project,
}: {
    closeModal: () => void;
    projectId: string;
    project: Project;
}): ReactElement => {
    return (
        <Modal width="w-95-percent" closeModal={closeModal} title="Export Project">
            <Exporter projectId={projectId} project={project} />
        </Modal>
    );
};
