import { AcceptTick } from 'assets/accept.svg';
import { RejectCross } from 'assets/reject.cross.svg';
import { ConfirmationDialog } from 'components/duex/ConfirmationDialog';
import { EmptyState } from 'components/duex/EmptyState';
import { IconButton } from 'components/duex/IconButton';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { showError } from 'components/error-toast.component';
import { BLUE, Pill, RED } from 'components/pill.component';
import { Col, ColHeader, Row } from 'components/table';
import { ProjectInvite } from 'modules/assessments/view-submission.modal';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { DashboardWidget } from './dashboard-widget';

export const DashboardInvitations = ({
    refreshData,
    showSpinner,
    invitations,
}: {
    refreshData: () => void;
    showSpinner: boolean;
    invitations: ProjectInvite[];
}): ReactElement => {
    const [showAcceptModal, setShowingAcceptModal] = useState(null);
    const [showDeclineModal, setShowingDeclineModal] = useState(null);

    const navigate = useNavigate();

    const acceptInvitation = async (projectInviteId: string) => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_ACCEPT_ASSESSMENT, {
                projectInviteId,
            });
            const result = await API.post(url, {});

            logEvent(`PROJECT_INVITE_ACCEPTED`, started, {
                projectInviteId,
            });

            if (result) {
                const projectId = result.data.projectId;

                logEvent(`PROJECT_CREATED`, started, {
                    projectInviteId,
                    projectId,
                });

                const url = `/Projects/${projectId}`;
                navigate(url);
            }
        } catch (err) {
            showError('There was an error accepting that Invitation', err);
        }
    };

    const declineInvitation = async (projectInviteId: string) => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_DECLINE_ASSESSMENT, {
                projectInviteId,
            });
            const result = await API.post(url, {});

            logEvent(`PROJECT_INVITE_DECLINED`, started, {
                projectInviteId,
            });

            if (result) {
                refreshData();
            }
        } catch (err) {
            showError('There was an error declining that Invitation', err);
        }
    };

    useEffect(() => {
        refreshData();
    }, []);

    if (invitations.length === 0) {
        return null;
    }
    return (
        <DashboardWidget isLoading={showSpinner} title="Invitations" drawBorder={true}>
            {showSpinner && <LoadingSpinner className="mt-32" />}
            {!showSpinner && invitations.length === 0 && (
                <div className="flex h-full flex-col items-center justify-center">
                    <EmptyState
                        width="w-360"
                        title="No invitations found"
                        subtitle="Invitations accepted by your team, and those sent to you appear here."
                    />
                </div>
            )}
            {!showSpinner && (
                <div className="py-8">
                    <Row className="border-b border-gray-light-1 bg-white">
                        <>
                            <ColHeader className={`w-2-of-12 ml-8`} text="Status" />
                            <ColHeader className={`w-3-of-12`} text="Sender" />
                            <ColHeader className={`w-3-of-12`} text="Company" />
                            <ColHeader className={`w-2-of-12`} text="Sent" />
                            <ColHeader className={`w-2-of-12`} text="" />
                        </>
                    </Row>

                    {!showSpinner &&
                        invitations.map((invite) => {
                            const title = invite.inviteStatus;
                            const alt = invite.inviteStatus;
                            const colour = invite.inviteStatus === 'UNCLAIMED' ? RED : BLUE;
                            const showInviteButtons = invite.claimable;

                            return (
                                <Row
                                    key={invite.projectInviteId}
                                    className="border-b border-black-10 hover:bg-blue-50"
                                    spacing="py-16"
                                >
                                    <>
                                        <Col className={`w-2-of-12 ml-8`}>
                                            <Pill title={title} alt={alt} colour={colour} />
                                        </Col>
                                        <Col className={`w-3-of-12`}>{invite.sentByUser?.fullName}</Col>
                                        <Col className={`w-3-of-12`}>{invite.sentByTeam?.name}</Col>
                                        <Col className={`w-2-of-12`}>{moment(invite.createdAt).fromNow()}</Col>
                                        <Col className={`flex w-2-of-12 flex-row-reverse gap-0 pr-8`}>
                                            {showInviteButtons && (
                                                <>
                                                    <div
                                                        onClick={() => {
                                                            setShowingDeclineModal(invite.projectInviteId);
                                                        }}
                                                    >
                                                        <IconButton title="Decline Invitation" Icon={RejectCross} />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            setShowingAcceptModal(invite.projectInviteId);
                                                        }}
                                                    >
                                                        <IconButton title="Accept Invitation" Icon={AcceptTick} />
                                                    </div>
                                                </>
                                            )}
                                        </Col>
                                    </>
                                </Row>
                            );
                        })}
                </div>
            )}
            {showAcceptModal && (
                <ConfirmationDialog
                    title="Accept Invitation"
                    descriptionText={[
                        'Accepting this invitation will create a Project in Pearler.',
                        'The sender will be notified of your acceptance.',
                        'Everyone in your team will be able to see the project.',
                    ]}
                    primaryLabel="Accept Invitation"
                    primaryAction={() => {
                        acceptInvitation(showAcceptModal);
                        setShowingAcceptModal(false);
                    }}
                    cancelAction={() => {
                        setShowingAcceptModal(false);
                    }}
                />
            )}

            {showDeclineModal && (
                <ConfirmationDialog
                    title="Decline Invitation"
                    descriptionText={[
                        'Declining invitations is not reversible.',
                        'The sender will need to send a new invitation once this is declined.',
                        'The sender will be notified of your decline.',
                    ]}
                    primaryLabel="Decline Invitation"
                    primaryAction={() => {
                        declineInvitation(showDeclineModal);
                        setShowingDeclineModal(false);
                    }}
                    cancelAction={() => {
                        setShowingDeclineModal(false);
                    }}
                />
            )}
        </DashboardWidget>
    );
};
