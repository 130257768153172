import { ClosedEye, Eye } from 'assets/eye.svg';
import { Mail } from 'assets/mail.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { isValidEmail, isValidStringOfLength } from 'components/validation/string';
import { useUserContext } from 'hooks/useUserContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import SSOHelper from 'utility/SSOHelper';

type STAGE = 'SEND_EMAIL' | 'RESET_PASSWORD';

export const ResetPassword = (): React.ReactElement => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [stage, setStage] = useState<STAGE>('SEND_EMAIL');
    const [sendingEmailCode, toggleSendingEmailCode] = useState(false);
    const [verifyingReset, toggleVerifyingReset] = useState(false);
    const [errorMsg, setErrorMessage] = useState('');
    const [showPassword, toggleShowPassword] = useState(false);
    const navigate = useNavigate();
    const auth = useUserContext();

    const annotateErrorMessage = (errorMessage: string): string => {
        if (errorMessage === 'Confirmation code cannot be empty') {
            return 'Please check your verification code and try again.';
        }
        if (errorMessage === 'Attempt limit exceeded, please try after some time.') {
            return 'Whoa, hold on there. Please check your verification code and email are correct';
        }

        return errorMessage;
    };

    const sendReset = async () => {
        try {
            setErrorMessage('');
            const cleanedEmail = email.trim().toLowerCase();
            const ssoResult = await SSOHelper.checkDomain(cleanedEmail);

            if (!ssoResult) {
                toggleSendingEmailCode(true);
                const result = await auth.forgotPasswordRequest(cleanedEmail);

                const {
                    nextStep: { codeDeliveryDetails },
                } = result;

                if (codeDeliveryDetails) {
                    setStage('RESET_PASSWORD');
                }
                toggleSendingEmailCode(false);
            }
        } catch (error) {
            toggleSendingEmailCode(false);
            setErrorMessage(annotateErrorMessage(error.message));
            console.error({ error });
        }
    };

    const verifyReset = async () => {
        try {
            setErrorMessage('');
            const cleanedEmail = email.trim().toLowerCase();
            const cleanedVerificationCode = verificationCode.replace(/\D/g, '');
            toggleVerifyingReset(true);

            const { success, error, errorMessage } = await auth.forgotPasswordSubmit(
                cleanedEmail,
                cleanedVerificationCode,
                newPassword,
            );
            if (success) {
                const { error, errorMessage } = await auth.signIn(cleanedEmail, newPassword);

                if (error && errorMessage.code === CONSTANTS.USER_NOT_CONFIRMED_EXPECTION) {
                    navigate(`${ROUTES.CONFIRM_REGISTRATION}?email=${encodeURIComponent(cleanedEmail)}`);
                    await auth.resendConfirmationCode(cleanedEmail);
                }
            } else if (error) {
                setErrorMessage(annotateErrorMessage(errorMessage.message));
            }
            toggleVerifyingReset(false);
        } catch (error) {
            toggleVerifyingReset(false);
            console.error({ error });
        }
    };

    useEffect(() => {
        document.title = 'Pearler | Reset Password';
    }, []);

    const formIsValid = isValidEmail(email);
    const finishFormIsValid = isValidStringOfLength(verificationCode, 6) && isValidStringOfLength(newPassword, 8);

    const sendEmailForm = (
        <div className="">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    await sendReset();
                }}
                className="grid gap-16"
            >
                <fieldset>
                    <FormTextField
                        defaultValue={email}
                        label="Work Email"
                        validator={(value: string) => isValidEmail(value)}
                        valueChanged={(val: string) => {
                            setEmail(val);
                        }}
                        onBlur={() => {
                            SSOHelper.checkDomain(email);
                            setErrorMessage('');
                        }}
                        placeholder="Work Email"
                        errorMessage="Please enter your work email address"
                        image={<Mail />}
                        autoComplete="email"
                        maxLength={250}
                    />
                </fieldset>
                <div className="grid w-full gap-16">
                    <ButtonBar>
                        <Button
                            buttonType="LARGE_PRIMARY"
                            action="submit"
                            label="Send Password Reset Email"
                            loading={sendingEmailCode}
                            disabled={!formIsValid || sendingEmailCode}
                            className="w-full"
                        />
                    </ButtonBar>
                    <ButtonBar className="grid w-full grid-cols-2">
                        <Button
                            buttonType="SECONDARY"
                            label="Back to Sign In"
                            onClick={() => {
                                navigate(ROUTES.LOGIN);
                            }}
                        />
                        <Button
                            buttonType="SECONDARY"
                            label="Create Account"
                            onClick={() => {
                                navigate(ROUTES.REGISTER);
                            }}
                        />
                    </ButtonBar>
                </div>
            </form>
        </div>
    );

    const confirmResetForm = (
        <React.Fragment>
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    await verifyReset();
                }}
                className="grid gap-16"
            >
                <FormTextField
                    defaultValue={verificationCode}
                    label="Verification Code"
                    validator={(value: string) => isValidStringOfLength(value, 6)}
                    valueChanged={(val: string) => {
                        setVerificationCode(val);
                    }}
                    onBlur={() => {
                        setErrorMessage('');
                    }}
                    placeholder="Verification Code"
                    errorMessage="Please enter the verification code"
                    autoComplete="one-time-code"
                    maxLength={10}
                />

                <div className="relative">
                    <FormTextField
                        defaultValue={newPassword}
                        inputType={showPassword ? 'text' : 'password'}
                        label="Password"
                        validator={(value: string) => isValidStringOfLength(value, 8)}
                        valueChanged={(val: string) => {
                            setNewPassword(val);
                        }}
                        onBlur={() => {
                            setErrorMessage('');
                        }}
                        placeholder="Password"
                        errorMessage="Please enter a new password"
                        autoComplete="new-password"
                        maxLength={250}
                    />
                    <div
                        className="absolute right-20 top-36 cursor-pointer"
                        onClick={() => toggleShowPassword(!showPassword)}
                        title={showPassword ? 'Hide Password' : 'Reveal Password'}
                    >
                        {showPassword ? <Eye /> : <ClosedEye />}
                    </div>
                </div>
                <div className="rounded-sm border border-gray-100 bg-gray-50 p-8 text-11 text-gray-500">
                    <span className="text-12 font-medium">Please use a password that has:</span>
                    <ul className="px-4 py-4">
                        <li>At least 8 characters</li>
                        <li>1 lower case character</li>
                        <li>1 upper case character</li>
                        <li>1 special character</li>
                    </ul>
                </div>
                <ButtonBar>
                    <Button
                        buttonType="LARGE_PRIMARY"
                        action="submit"
                        label="Update Password"
                        loading={verifyingReset}
                        disabled={!finishFormIsValid || verifyingReset}
                        className="w-full"
                    />
                </ButtonBar>
            </form>
        </React.Fragment>
    );

    return (
        <div className="grid w-full gap-16">
            <h1 className="h3-bold select-none text-center text-primary-blue-80">Reset Password</h1>
            <p className="h6-medium text-center text-primary-blue-60">
                Enter your work email and we&apos;ll send you a verification code to reset your password.
            </p>
            {errorMsg && <p className={`subtitle-2-reg min-h-20 text-left text-red`}>{errorMsg}</p>}

            {stage === 'SEND_EMAIL' ? sendEmailForm : confirmResetForm}
        </div>
    );
};
