import { GRAY, LIGHTBLUE, Pill, YELLOW } from 'components/pill.component';
import React, { ReactElement } from 'react';
import { CONSTANTS } from 'utility/Constants';

export const Priority = ({ projectPriority }: { projectPriority: string }): ReactElement => {
    switch (projectPriority) {
        case CONSTANTS.HIGH:
            return <Pill title="High Priority" alt="Priority" colour={YELLOW} />;
        case CONSTANTS.LOW:
            return <Pill title="Low Priority" alt="Priority" colour={GRAY} />;
        case CONSTANTS.NORMAL:
            return <Pill title="Normal Priority" alt="Priority" colour={LIGHTBLUE} />;
    }

    return null;
};
