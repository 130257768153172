import { Modal } from 'components/duex/Modal';
import React, { ReactElement, useState } from 'react';
import { Importer } from './importer.view';

export const ImporterModal = ({
    closeModal,
    projectId,
}: {
    closeModal: () => void;
    projectId: string;
}): ReactElement => {
    const [unsavedChanges, setUnsavedChanges] = useState(false);

    return (
        <Modal
            width="w-95-percent"
            height="full"
            layout="flex-row"
            closeSafely={() => {
                return !unsavedChanges;
            }}
            closeModal={closeModal}
            title="Question Sources"
        >
            <Importer projectId={projectId} setUnsavedChanges={setUnsavedChanges} />
        </Modal>
    );
};
