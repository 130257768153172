import { FullStory } from '@fullstory/browser';
import { User } from 'types';
import { GenericDictionary } from 'types/generic-dictionary';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import harness from './Automation';

let loggedInUserId = null;

export const logEvent = (eventName: string, startDate: Date, eventData?: GenericDictionary): void => {
    FullStory('trackEvent', {
        name: eventName,
        properties: eventData,
    });

    const duration = new Date().getTime() - startDate.getTime();

    API.post(ENDPOINTS.getUrl(CONSTANTS.USER_LOG_ACTIVITY), {
        eventName,
        eventData,
        userId: loggedInUserId,
        duration,
    });
};

// eslint-disable-next-line
export const logError = (userErrorText: string, err: Error | any): void => {
    if (!harness.isUnderTest()) {
        API.post(ENDPOINTS.getUrl(CONSTANTS.USER_LOG_ACTIVITY), {
            eventName: 'APP_ERROR',
            eventData: {
                userErrorText,
                stackTrace: err?.stack,
                message: err?.message,
            },
            ...{
                userId: loggedInUserId ? loggedInUserId : undefined,
            },
        });
    }
};

export const trackLogin = (userId: string, userModel: User): void => {
    FullStory('setIdentity', {
        uid: userId,
        properties: userModel,
    });
    loggedInUserId = userId;
};

export const trackLogout = (): void => {
    FullStory('setIdentity', {
        anonymous: true,
    });
    loggedInUserId = null;
};
