import { AccountSetupImage } from 'assets/onboarding-account-setup.svg';
import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { showError } from 'components/error-toast.component';
import { useUserContext } from 'hooks/useUserContext';
import _get from 'lodash/get';
import React, { ReactElement, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const AccountSetup = (): ReactElement => {
    const [saving, setSaving] = useState({});
    const {
        userStatus: { invitations },
        restartApplication,
        getAccountStatus,
    } = useUserContext();

    const acceptInvitation = async (invitationId: string) => {
        try {
            const started = new Date();
            setSaving({
                ...saving,
                [invitationId]: 'ACCEPTING',
            });

            const data = {
                membershipId: invitationId,
            };
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_INVITATION, data);
            const result = await API.post(url, {
                invitationStatus: CONSTANTS.ACCEPT_INVITATION,
            });

            if (result && result.data) {
                logEvent('MEMBERSHIP_INVITE_ACCEPTED', started, {
                    membershipId: invitationId,
                });

                restartApplication();
            }

            setSaving({
                ...saving,
                [invitationId]: undefined,
            });
        } catch (err) {
            showError('There was an error accepting that invitation', err);
            setSaving({
                ...saving,
                [invitationId]: undefined,
            });
        }
    };

    const declineInvitation = async (invitationId: string) => {
        try {
            const started = new Date();
            setSaving({
                ...saving,
                [invitationId]: 'DECLINING',
            });

            const data = {
                membershipId: invitationId,
            };
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_INVITATION, data);
            const result = await API.post(url, {
                invitationStatus: CONSTANTS.DECLINE_INVITATION,
            });

            if (result && result.data) {
                logEvent('MEMBERSHIP_INVITE_DECLINED', started, {
                    membershipId: invitationId,
                });

                if (invitations.length <= 1) {
                    restartApplication();
                } else {
                    await getAccountStatus();
                }
            }

            setSaving({
                ...saving,
                [invitationId]: undefined,
            });
        } catch (err) {
            showError('There was an error declining that invitation', err);
            setSaving({
                ...saving,
                [invitationId]: undefined,
            });
        }
    };

    return (
        <div className="flex h-screen w-full items-center">
            <div className="mx-auto flex w-700 flex-col items-center">
                <div className="mb-8">
                    <AccountSetupImage />
                </div>
                <p className="h4-medium tracking-0.2 my-24 text-black-100">You&apos;re invited!</p>
                <>
                    {invitations &&
                        invitations.map((invitation, index) => {
                            const sender = _get(invitation, 'invitationData.sender');
                            const team = _get(invitation, 'invitationData.team');
                            const invitationId = _get(invitation, 'membershipId');
                            return (
                                <div
                                    key={index}
                                    className="bg-gray-25 mb-16 flex w-full flex-row items-center gap-16 rounded-lg p-16 hover:bg-very-light-blue"
                                >
                                    <Avatar user={invitation.invitationData.sender} size={32} />

                                    <p className="body-1-reg w-400 text-black-80">
                                        {sender.fullName} invited you to join {team.name}.
                                    </p>
                                    <ButtonBar>
                                        <Button
                                            onClick={() => {
                                                declineInvitation(invitationId);
                                            }}
                                            label={`Decline`}
                                            loading={saving[invitationId] === 'DECLINING'}
                                            disabled={saving[invitationId]}
                                            buttonType="SECONDARY"
                                        />
                                        <Button
                                            onClick={() => {
                                                acceptInvitation(invitationId);
                                            }}
                                            label={`Accept`}
                                            loading={saving[invitationId] === 'ACCEPTING'}
                                            disabled={saving[invitationId]}
                                        />
                                    </ButtonBar>
                                </div>
                            );
                        })}
                </>
            </div>
        </div>
    );
};
