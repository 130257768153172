import { Avatar } from 'components/duex/Avatar';
import { useUserContext } from 'hooks/useUserContext';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Question } from 'types';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { AnalyticsProps } from '../answer-analytics.modal';
import { highlightString } from '../utilities/highlight-string';
import { FormattedValue, Label, SectionTitle, Value } from './typography';

interface UsageCardProps extends AnalyticsProps {
    question: Question;
    lastItem: boolean;
}

export const UsageCard = ({ question, answer, lastItem }: UsageCardProps): ReactElement => {
    const { getUser } = useUserContext();
    const author = getUser(question.answeredByUserId);

    return (
        <div className={`${!lastItem ? 'mb-16' : ''}`}>
            <div className="grid grid-cols-3 rounded-t-md bg-very-light-blue p-16">
                <div>
                    <Label title="Answered By" />
                    <div
                        className="grid items-center gap-8"
                        style={{
                            gridTemplateColumns: '32px auto',
                        }}
                    >
                        <Avatar userId={question.answeredByUserId} size={32} />
                        <Value title={author.fullName || 'Unknown'} preventWrapping={true} />
                    </div>
                </div>
                <div>
                    <Label title="Used" />
                    <Value title={moment(question.updatedAt).format('Do MMM YYYY')} />
                </div>
                <div>
                    <Label title="Project Name" />
                    <Link
                        to={createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                            questionId: question.questionId,
                            projectId: question.projectId,
                        })}
                    >
                        <span className="h-40 text-14 leading-40 text-primary-blue-100 hover:underline">
                            {question.project.name}
                        </span>
                    </Link>
                </div>
            </div>
            <div className="rounded-b-md border border-gray-50 p-16">
                <div>
                    {question.information && (
                        <>
                            <Label title="Information" />
                            <FormattedValue title={question.information} />
                        </>
                    )}
                </div>

                <SectionTitle title="Question" />
                <FormattedValue title={question.question} />

                <SectionTitle title="Answer" />
                <FormattedValue
                    title={
                        answer.answer !== question.answer
                            ? highlightString(answer.answer, question.answer)
                            : answer.answer
                    }
                />
            </div>
        </div>
    );
};
