import { SettingsCog } from 'assets/SettingsCog.svg';
import { Add } from 'assets/add.svg';
import { FilterIcon } from 'assets/filter.svg';
import { ModalCross } from 'assets/modal-cross.svg';
import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import FloatingElement from 'components/duex/FloatingElement';
import { IconButton } from 'components/duex/IconButton';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Module } from 'components/duex/Module';
import SearchTextField from 'components/duex/SearchTextField';
import { TagList } from 'components/duex/TagList';
import { testAutomation } from 'components/duex/TestAutomationAttribute';
import Tooltip, { getTooltipId } from 'components/duex/Tooltip';
import { showError } from 'components/error-toast.component';
import { BRIGHTBLUE, GRAY, LIGHTGRAY, LIGHTYELLOW, Pill, YELLOW } from 'components/pill.component';
import { showSuccess } from 'components/success-toast.component';
import { Col, ColHeader, Row } from 'components/table';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import { useTagContext } from 'hooks/useTagsContext';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _identity from 'lodash/identity';
import _includes from 'lodash/includes';
import _isEqual from 'lodash/isEqual';
import _keys from 'lodash/keys';
import _map from 'lodash/map';
import _pickBy from 'lodash/pickBy';
import _uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { LibraryImporterModal } from '../library-importer/library-importer.modal';
import { AddAnswerModal } from './add-answer.modal';
import { AddCollectionModal } from './add-collection.modal';
import { AnswerAnalyticsModal } from './answer-analytics.modal';
import { CandidateReviewModal } from './candidate-review.modal';
import { EditCollectionModal } from './edit-collection.modal';
import { highlightString } from './utilities/highlight-string';
const FAVOURITES_ID = '__FAVOURITES';
const NEEDS_REVIEW_ID = '__NEEDS_REVIEW';
const LIVE_ANSWERS_ID = '__LIVE_ANSWERS';
const ARCHIVE_ID = '__ARCHIVE';
const CANDIDATES_ID = '__CANDIDATES_ID';

const defaultIds = [FAVOURITES_ID, NEEDS_REVIEW_ID, LIVE_ANSWERS_ID, ARCHIVE_ID, CANDIDATES_ID];

const defaultCollections = [
    {
        collectionId: LIVE_ANSWERS_ID,
        collectionName: 'Available Answers',
        countKey: 'IN-USE',
    },
    {
        collectionId: NEEDS_REVIEW_ID,
        collectionName: 'Review',
        countKey: 'FLAGGED',
    },
    {
        collectionId: CANDIDATES_ID,
        collectionName: 'Candidates',
        countKey: 'CANDIDATE',
    },
    {
        collectionId: FAVOURITES_ID,
        collectionName: 'Favourites',
        countKey: 'FAVOURITE',
    },
    {
        collectionId: ARCHIVE_ID,
        collectionName: 'Archive',
        countKey: 'ARCHIVED',
    },
];

const requestLimit = 30;

export const AnswerLibraryHome = (): ReactElement => {
    const [selectedCollection, setSelectedCollection] = useState(LIVE_ANSWERS_ID);
    const [answers, setAnswers] = useState([]);
    const [collections, setCollections] = useState([]);
    const { answerTags } = useTagContext();
    const { getUser } = useUserContext();

    const [moveToCollection, setMoveToCollection] = useState(null);

    const [makingBulkChange, setMakingBulkChange] = useState(null);
    const [fetchingCollections, setFetchingCollections] = useState(false);
    const [, setFetchingCollectionCounts] = useState(false);
    const [fetchingAnswers, setFetchingAnswers] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [showAddAnswer, setShowAddAnswer] = useState(false);
    const [showAnswerAnalyticsforId, setShowAnswerAnalyticsforId] = useState(null);
    const [showAddCollection, setShowAddCollection] = useState(false);
    const [showCandidateforId, setShowCandidateforId] = useState(null);
    const [showAnswerImporter, setShowAnswerImporter] = useState(false);

    const [allAnswersSelected, setAllAnswersSelected] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [collectionCounts, setCollectionCounts] = useState({});

    const [hoveringCollection, setHoveringCollection] = useState(null);
    const [editCollection, setEditCollection] = useState(null);

    const [selectedTags, setSelectedTags] = useState({});
    const [showTagSelector, setShowTagSelector] = useState(false);
    const description = `Selects all the currently shown Answers`;
    const tooltipId = getTooltipId(description);

    const fetchCollections = async () => {
        try {
            setFetchingCollections(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_ANSWER_COLLECTION);

            const results = await API.get(url);
            if (results && results.data) {
                setCollections(results.data);
            }
            setFetchingCollections(false);
        } catch (err) {
            showError('There was an error fetching the list of collections', err);
            setFetchingCollections(false);
        }
    };

    const fetchCollectionCounts = async () => {
        try {
            setFetchingCollectionCounts(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_ANSWER_COLLECTION_COUNTS);

            const results = await API.get(url);
            if (results && results.data) {
                setCollectionCounts(results.data);
            }
            setFetchingCollectionCounts(false);
        } catch (err) {
            showError('There was an error fetching the counts of your collections', err);
            setFetchingCollectionCounts(false);
        }
    };

    const getSelectedAnswers = () => {
        const candidateIds = [];

        for (const candidateId in selectedAnswers) {
            if (selectedAnswers[candidateId]) {
                candidateIds.push(candidateId);
            }
        }
        return candidateIds;
    };

    const bulkChange = async (newStatus: string) => {
        try {
            setMakingBulkChange(newStatus);

            const url = ENDPOINTS.getUrl(CONSTANTS.ANSWER_LIBRARY_BULK_CHANGE);

            const results = await API.post(url, {
                answerIds: getSelectedAnswers(),
                answerStatus: newStatus,
            });
            if (results && results.data) {
                setMakingBulkChange(null);
                setSelectedAnswers({});
                setAllAnswersSelected(false);
                fetchCollectionCounts();
            }
            fetchAnswers(true);
        } catch (err) {
            showError('There was an error making those changes', err);
            setMakingBulkChange(null);
        }
    };

    const bulkCandidateChange = async (newStatus: string, addToCollection = false) => {
        try {
            setMakingBulkChange(newStatus);

            const url = ENDPOINTS.getUrl(CONSTANTS.ANSWER_LIBRARY_BULK_CANDIDATE_CHANGE);

            const optionalParams = {
                collectionId: addToCollection ? moveToCollection : null,
            };

            const results = await API.post(url, {
                questionIds: getSelectedAnswers(),
                newStatus,
                ...optionalParams,
            });
            if (results && results.data) {
                setMakingBulkChange(null);
                setSelectedAnswers({});
                setAllAnswersSelected(false);
                fetchCollectionCounts();
            }
            fetchAnswers(true);
        } catch (err) {
            showError('There was an error making those changes', err);
            setMakingBulkChange(null);
        }
    };

    const bulkAddToCollection = async (newStatus: string) => {
        try {
            setMakingBulkChange(newStatus);

            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.ADD_TO_COLLECTION, {
                collectionId: moveToCollection,
            });

            const count = getSelectedAnswers().length;

            const results = await API.post(url, {
                answerIds: getSelectedAnswers(),
            });

            logEvent('ANSWER_LIBRARY_ADD_TO_COLLECTION', started);

            if (results && results.data) {
                setMakingBulkChange(null);
                showSuccess(`${count} answer${count > 1 ? 's' : ''} added to collection`);
                fetchCollectionCounts();
            }
        } catch (err) {
            showError('There was an error making those changes', err);
            setMakingBulkChange(null);
        }
    };

    const bulkRemoveFromCollection = async (newStatus: string) => {
        try {
            setMakingBulkChange(newStatus);

            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.REMOVE_FROM_COLLECTION, {
                collectionId: selectedCollection,
            });

            const count = getSelectedAnswers().length;

            const results = await API.post(url, {
                answerIds: getSelectedAnswers(),
            });

            logEvent('ANSWER_LIBRARY_REMOVE_FROM_COLLECTION', started);

            if (results && results.data) {
                setMakingBulkChange(null);
                setSelectedAnswers({});
                setAllAnswersSelected(false);
                showSuccess(`${count} answer${count > 1 ? 's' : ''} removed from collection`);
                fetchCollectionCounts();
            }
            fetchAnswers(true);
        } catch (err) {
            showError('There was an error making those changes', err);
            setMakingBulkChange(null);
        }
    };

    const exportToCSV = () => {
        logEvent(`ANSWER_LIBRARY_EXPORT_RESULTS`, new Date());

        const headers = ['AnswerId', 'Answer', 'Original Question', 'Tags', 'Created', 'Last Updated', 'Author'];

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            filename: 'pearler-answer-library-export',
            useTextFile: false,
            useBom: true,
            headers,
        };

        const data = _map(
            answers,
            ({ answerId, answer, originalQuestion, tags, createdAt, updatedAt, createdByUserId }) => {
                const { fullName } = getUser(createdByUserId);

                return {
                    answerId,
                    answer,
                    originalQuestion,
                    tags: _map(tags, 'tagLabel').join('; '),
                    createdAt: moment(createdAt).format('YYYY-M-D'),
                    updatedAt: moment(updatedAt).format('YYYY-M-D'),
                    author: fullName,
                };
            },
        );

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    };

    const fetchAnswers = async (refresh = false, recordsToFetch = requestLimit) => {
        try {
            const started = new Date();
            setFetchingAnswers(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_ANSWERS_BY_SEARCH);

            const searchString = searchTerm ? searchTerm.trim() : '';
            let apiOffset = (currentPage - 1) * recordsToFetch;
            let apiLimit = recordsToFetch;

            if (refresh) {
                apiOffset = 0;
                apiLimit = currentPage * recordsToFetch;
            }

            let collection = {};

            if (selectedCollection === FAVOURITES_ID) {
                collection = {
                    isFavourite: true,
                };
            }
            if (selectedCollection === NEEDS_REVIEW_ID) {
                collection = {
                    answerStatus: CONSTANTS.FLAGGED,
                };
            }
            if (selectedCollection === ARCHIVE_ID) {
                collection = {
                    answerStatus: CONSTANTS.ARCHIVE_ANSWER,
                };
            }
            if (selectedCollection === LIVE_ANSWERS_ID) {
                collection = {
                    answerStatus: CONSTANTS.IN_USE,
                };
            }
            if (selectedCollection === CANDIDATES_ID) {
                collection = {
                    answerStatus: CONSTANTS.CANDIDATES,
                };
            }

            if (!_includes(defaultIds, selectedCollection)) {
                collection = {
                    collectionId: selectedCollection,
                };
            }

            const tags = _keys(_pickBy(selectedTags, _identity));

            const results = await API.post(
                url,
                {
                    ...{
                        searchTerm: searchString ? searchString : undefined,
                    },
                    ...collection,
                    tags,
                },
                {
                    'x-pearler-offset': apiOffset,
                    'x-pearler-limit': apiLimit,
                },
            );

            logEvent('ANSWER_LIBRARY_SEARCH', started, {
                searchTerm,
            });

            if (results && results.data) {
                if (currentPage === 1) {
                    setAnswers(results.data);
                } else {
                    setAnswers(_uniqBy([...answers, ...results.data], 'candidateId'));
                }
            }
            setFetchingAnswers(false);
        } catch (err) {
            showError('There was an error fetching answers', err);
            setFetchingAnswers(false);
        }
    };

    const clearTagFilters = () => {
        setSelectedTags({});
    };

    useEffect(() => {
        fetchCollections();
        fetchCollectionCounts();
    }, []);

    useEffect(() => {
        if (!showTagSelector) {
            fetchAnswers();
        }
    }, [searchTerm, currentPage, selectedCollection, showTagSelector, selectedTags]);

    useEffect(() => {
        const update = {};

        const existingKeys = Object.keys(selectedAnswers);

        existingKeys.forEach((candidateId) => {
            if (selectedAnswers[candidateId]) {
                const showingRow = _find(answers, {
                    candidateId,
                });

                if (showingRow) {
                    update[candidateId] = true;
                }
            }
        });

        setSelectedAnswers(update);
    }, [answers]);

    useEffect(() => {
        setAllAnswersSelected(false);
        setSelectedAnswers({});
        setCurrentPage(1);
    }, [selectedCollection]);

    const applySearch = (searchTerm: string) => {
        setCurrentPage(1);
        setSearchTerm(searchTerm);
    };

    const toggleAnswerSelection = (candidateId: string, newState: boolean) => {
        setAllAnswersSelected(false);

        const update = {
            ...selectedAnswers,
        };

        if (newState) {
            update[candidateId] = newState;
        } else {
            delete update[candidateId];
        }

        setSelectedAnswers(update);
    };

    const toggleAllAnswerSelection = (selectedAll: boolean) => {
        setAllAnswersSelected(selectedAll);

        const identifiers = _map(answers, 'candidateId');

        if (selectedAll) {
            const answerSelections = {};

            for (let i = 0; i < identifiers.length; i++) {
                answerSelections[identifiers[i]] = selectedAll;
            }

            setSelectedAnswers(answerSelections);
        } else {
            setSelectedAnswers({});
        }
    };

    const fetchAllAnswers = () => {
        fetchAnswers(true, 10000);
    };

    const validActions = {
        DELETE: _includes([ARCHIVE_ID], selectedCollection),
        DISCARD: _includes([CANDIDATES_ID], selectedCollection),
        CREATE: _includes([CANDIDATES_ID], selectedCollection),
        ARCHIVE: !_includes([ARCHIVE_ID, CANDIDATES_ID], selectedCollection),
        FLAGGED: !_includes([NEEDS_REVIEW_ID, CANDIDATES_ID], selectedCollection),
        'IN-USE': _includes([NEEDS_REVIEW_ID, ARCHIVE_ID], selectedCollection),
        ADD_TO: !_includes([NEEDS_REVIEW_ID, ARCHIVE_ID], selectedCollection),
        REMOVE_FROM: !_includes(defaultIds, selectedCollection),
    };

    const hasMoreData = currentPage * requestLimit === answers.length;
    let selectedCount = 0;

    for (const prop in selectedAnswers) {
        if (selectedAnswers[prop]) {
            selectedCount = selectedCount + 1;
        }
    }

    const renderTagItem = ({ text }, id) => {
        const isSelected = selectedTags[text];

        return (
            <div
                key={id}
                className="my-4 cursor-pointer select-none"
                onClick={() => {
                    setSelectedTags({
                        ...selectedTags,
                        [text]: !isSelected,
                    });
                }}
            >
                <Pill key={id} colour={isSelected ? BRIGHTBLUE : LIGHTGRAY} title={text} />
            </div>
        );
    };

    const renderCollectionItem = (collection) => {
        const isSelected = collection.collectionId === selectedCollection;

        const isStandardCollection = _includes(defaultIds, collection.collectionId);
        const countKey = isStandardCollection ? collection.countKey : collection.collectionId;

        return (
            <div
                onClick={() => {
                    setSelectedCollection(collection.collectionId);
                }}
                key={collection.collectionId}
                className={`mx-16 my-2 grid cursor-pointer select-none gap-8 truncate rounded-lg p-8 text-black hover:bg-blue-100 ${
                    isSelected ? 'bg-blue-600 text-white hover:bg-blue-600' : ''
                }`}
                style={{
                    gridTemplateColumns: 'auto 24px',
                }}
            >
                <span data-cy={testAutomation(collection.collectionName)} className="truncate">
                    {collection.collectionName}
                </span>
                <div
                    className=""
                    onMouseEnter={() => {
                        setHoveringCollection(collection.collectionId);
                    }}
                    onMouseLeave={() => {
                        setHoveringCollection(null);
                    }}
                >
                    {hoveringCollection === collection.collectionId &&
                    !_includes(defaultIds, collection.collectionId) ? (
                        <div
                            className="p-2"
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                setEditCollection(collection);
                            }}
                        >
                            <SettingsCog strokeColor={`${isSelected ? '#FFFFFF' : '#2563EB'}`} />
                        </div>
                    ) : (
                        <div
                            title={collectionCounts[countKey] || 0}
                            className={`h-24 w-24 overflow-hidden rounded-full ${
                                isSelected ? 'bg-white' : 'bg-gray-100'
                            } text-center text-10 leading-24 text-gray-600`}
                        >
                            {collectionCounts[countKey] || 0}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const currentCollectionName = _find(collections, {
        collectionId: selectedCollection,
    });

    const hasTagsSelected = _filter(selectedTags).length > 0;
    const hasFiltersApplied = hasTagsSelected || searchTerm !== '';

    // Pagination for Answer Analytics Modal UI

    let nextAnswerId, previousAnswerId;

    if (showAnswerAnalyticsforId) {
        const selectedItem = _findIndex(answers, {
            answerId: showAnswerAnalyticsforId,
        });

        if (selectedItem !== 0) {
            previousAnswerId = answers[selectedItem - 1]?.answerId;
        }

        if (selectedItem + 1 !== answers.length) {
            nextAnswerId = answers[selectedItem + 1]?.answerId;
        }
    }

    // Fetch data if available, and the user has reached the end of pagination

    useEffect(() => {
        if (showAnswerAnalyticsforId && hasMoreData && !nextAnswerId) {
            setCurrentPage(currentPage + 1);
        }
    }, [hasMoreData, nextAnswerId, showAnswerAnalyticsforId]);

    // Pagination for Candidate Modal UI
    let nextCandidateId, previousCandidateId;

    if (showCandidateforId) {
        const selectedItem = _findIndex(answers, {
            questionId: showCandidateforId,
        });

        if (selectedItem !== 0) {
            previousCandidateId = answers[selectedItem - 1]?.questionId;
        }

        if (selectedItem + 1 !== answers.length) {
            nextCandidateId = answers[selectedItem + 1]?.questionId;
        }
    }

    // Fetch data if available, and the user has reached the end of pagination

    useEffect(() => {
        if (showCandidateforId && hasMoreData && !nextCandidateId) {
            setCurrentPage(currentPage + 1);
        }
    }, [hasMoreData, nextCandidateId, showCandidateforId]);

    return (
        <Module
            moduleName="Answer Library"
            width="w-full"
            middleItem={
                <div
                    className={`w-full relative grid gap-8 pr-16`}
                    style={{
                        gridTemplateColumns: `auto ${answerTags.length > 0 ? '32px' : ''}`,
                    }}
                >
                    <SearchTextField
                        placeholder="Search your library"
                        applySearch={applySearch}
                        currentValue={searchTerm}
                        activeBackground={true}
                        testId="Answer_Library_Search_Box"
                    />
                    {answerTags.length > 0 && (
                        <IconButton
                            title="Filter by tags"
                            className="my-8"
                            Icon={FilterIcon}
                            onClick={() => {
                                setShowTagSelector(!showTagSelector);
                            }}
                            strokeColor={hasTagsSelected ? '#FFFFFF' : '#ABC3ED'}
                            hoverStrokeColor={hasTagsSelected ? '#FFFFFF' : '#ABC3ED'}
                            bg={hasTagsSelected ? 'bg-primary-blue-100 opacity-90' : ''}
                            bgHover={hasTagsSelected ? 'hover:opacity-100' : 'hover:bg-gray-100'}
                        />
                    )}
                    {showTagSelector && (
                        <FloatingElement
                            close={() => {
                                setShowTagSelector(false);
                            }}
                        >
                            {answerTags.length > 0 && (
                                <>
                                    <div className="flex min-w-274 flex-row">
                                        <h3 className="mb-8 ml-8 font-semibold text-gray-600">
                                            Filter Answers by Tags
                                        </h3>
                                        <div
                                            className="absolute right-6 top-12 cursor-pointer opacity-50 hover:opacity-100"
                                            onClick={() => {
                                                setShowTagSelector(false);
                                            }}
                                        >
                                            <ModalCross />
                                        </div>
                                    </div>
                                    <div className="flex flex-row flex-wrap">{answerTags.map(renderTagItem)}</div>
                                    {hasTagsSelected && (
                                        <ButtonBar className="mt-16">
                                            <Button
                                                label="Clear Filters"
                                                buttonType="SECONDARY"
                                                onClick={() => {
                                                    clearTagFilters();
                                                    setShowTagSelector(false);
                                                }}
                                            />
                                            <Button
                                                label="Apply Filters"
                                                onClick={() => {
                                                    setShowTagSelector(false);
                                                }}
                                            />
                                        </ButtonBar>
                                    )}
                                </>
                            )}
                        </FloatingElement>
                    )}
                </div>
            }
            rightItem={
                <ButtonBar className="mr-16">
                    <Button
                        label="Import"
                        buttonType="SECONDARY"
                        onClick={() => {
                            setShowAnswerImporter(true);
                        }}
                    />
                    <Button
                        label="Add"
                        buttonType="PRIMARY"
                        onClick={() => {
                            setShowAddAnswer(true);
                        }}
                    />
                    <Button label="Export" buttonType="TERTIARY" onClick={exportToCSV} />
                </ButtonBar>
            }
        >
            <div
                className="grid h-full max-h-full gap-16"
                style={{
                    gridTemplateColumns: '300px auto',
                }}
            >
                <div className="h-full rounded-r-lg bg-faded-gray py-16 overflow-y-auto">
                    <div className="mx-16 mb-16 grid" style={{ gridTemplateColumns: 'auto 32px' }}>
                        <h3 className="ml-8 select-none text-14 uppercase leading-32 text-black-80">Collections</h3>
                        <IconButton
                            Icon={Add}
                            title="Add Collection"
                            onClick={() => {
                                setShowAddCollection(true);
                            }}
                        />
                    </div>

                    {defaultCollections.map(renderCollectionItem)}
                    {fetchingCollections && <LoadingSpinner />}
                    {!fetchingCollections && collections.length > 0 && (
                        <>
                            <div className="my-16 border-b"></div>
                            {collections.map(renderCollectionItem)}
                        </>
                    )}
                </div>
                <div
                    className="relative grid h-full max-h-full gap-8"
                    style={{ gridTemplateRows: '41px calc(100vh - 145px)' }}
                >
                    {!_isEqual(selectedAnswers, {}) && (
                        <div className="absolute left-48 top-8 z-4 inline-block rounded-md bg-white p-16 shadow-md">
                            <ButtonBar className="mr-8">
                                {validActions['ADD_TO'] && collections.length > 0 && (
                                    <div className="flex flex-row justify-center rounded-md border border-blue-600">
                                        <div className="px-16 text-14 leading-40 text-primary-blue-100">Add to</div>
                                        <select
                                            className="text-12 text-black-80"
                                            onChange={(ev) => {
                                                setMoveToCollection(ev.target.value);
                                            }}
                                            value={moveToCollection}
                                        >
                                            {!moveToCollection && <option>Please Select</option>}
                                            {collections.map((collection) => {
                                                return (
                                                    <option
                                                        key={collection.collectionId}
                                                        value={collection.collectionId}
                                                    >
                                                        {collection.collectionName}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <div className="ml-16 border-l border-blue-600">
                                            <Button
                                                label={`Go`}
                                                buttonType="TERTIARY"
                                                onClick={() => {
                                                    if (selectedCollection === CANDIDATES_ID) {
                                                        bulkCandidateChange('CREATE', true);
                                                    } else {
                                                        bulkAddToCollection('ADD_TO');
                                                    }
                                                }}
                                                disabled={makingBulkChange || !moveToCollection}
                                                loading={makingBulkChange === 'ADD_TO'}
                                            />
                                        </div>
                                    </div>
                                )}
                                {validActions['REMOVE_FROM'] && collections.length > 0 && (
                                    <Button
                                        label={`Remove ${selectedCount} Answer${
                                            selectedCount > 1 ? 's' : ''
                                        } from ${currentCollectionName?.collectionName}`}
                                        buttonType="SECONDARY"
                                        onClick={() => {
                                            bulkRemoveFromCollection('REMOVE_FROM');
                                        }}
                                        disabled={makingBulkChange}
                                        loading={makingBulkChange === 'REMOVE_FROM'}
                                    />
                                )}
                                {validActions['ARCHIVE'] && (
                                    <Button
                                        label={`Archive ${selectedCount} Answer${selectedCount > 1 ? 's' : ''}`}
                                        buttonType="SECONDARY"
                                        onClick={() => {
                                            bulkChange('ARCHIVED');
                                        }}
                                        disabled={makingBulkChange}
                                        loading={makingBulkChange === 'ARCHIVED'}
                                    />
                                )}
                                {validActions['DELETE'] && (
                                    <Button
                                        label={`Delete ${selectedCount} Answer${selectedCount > 1 ? 's' : ''}`}
                                        buttonType="SECONDARY"
                                        onClick={() => {
                                            bulkChange('DELETE');
                                        }}
                                        disabled={makingBulkChange}
                                        loading={makingBulkChange === 'DELETE'}
                                    />
                                )}
                                {validActions['FLAGGED'] && (
                                    <Button
                                        label={`Flag ${selectedCount} Answer${selectedCount > 1 ? 's' : ''} for Review`}
                                        buttonType="SECONDARY"
                                        onClick={() => {
                                            bulkChange('FLAGGED');
                                        }}
                                        disabled={makingBulkChange}
                                        loading={makingBulkChange === 'FLAGGED'}
                                    />
                                )}
                                {validActions['IN-USE'] && (
                                    <Button
                                        label={`Make ${selectedCount} Answer${selectedCount > 1 ? 's' : ''} available`}
                                        buttonType="SECONDARY"
                                        onClick={() => {
                                            bulkChange('IN-USE');
                                        }}
                                        disabled={makingBulkChange}
                                        loading={makingBulkChange === 'IN-USE'}
                                    />
                                )}
                                {validActions['DISCARD'] && (
                                    <Button
                                        label={`Discard ${selectedCount} Answer${selectedCount > 1 ? 's' : ''}`}
                                        buttonType="SECONDARY"
                                        onClick={() => {
                                            bulkCandidateChange('DISCARD');
                                        }}
                                        disabled={makingBulkChange}
                                        loading={makingBulkChange === 'DISCARD'}
                                    />
                                )}
                                {validActions['CREATE'] && (
                                    <Button
                                        label={`Add ${selectedCount} Answer${selectedCount > 1 ? 's' : ''} to Library`}
                                        buttonType="SECONDARY"
                                        onClick={() => {
                                            bulkCandidateChange('CREATE');
                                        }}
                                        disabled={makingBulkChange}
                                        loading={makingBulkChange === 'CREATE'}
                                    />
                                )}
                            </ButtonBar>
                        </div>
                    )}

                    <Row className="mr-16 border-b border-gray-light-1">
                        <div
                            className={`w-1-of-24 pl-8`}
                            data-tooltip-content={description}
                            data-tooltip-id={tooltipId}
                        >
                            <input
                                data-cy="Select_All_Answers"
                                type="checkbox"
                                className="h-16 w-16 border-gray-200"
                                checked={allAnswersSelected}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const selected = event.target.checked;
                                    toggleAllAnswerSelection(Boolean(selected));
                                }}
                                value={'checked'}
                            />
                        </div>
                        <ColHeader className={`w-1-of-12`} text="Author" />
                        <ColHeader className={`w-10-of-12`} text="Answer" />
                    </Row>
                    <div className="h-full max-h-full overflow-y-auto pr-16">
                        {!fetchingAnswers && answers.length === 0 && !hasFiltersApplied && (
                            <EmptyState
                                width="w-full"
                                className="mt-16"
                                title="No Answers found"
                                subtitle="You can start your answer library by uploading a file, or creating an answer."
                            >
                                <div className="mt-16">
                                    <Button
                                        onClick={() => {
                                            setShowAnswerImporter(true);
                                        }}
                                        label="Import Answers"
                                    />
                                </div>
                            </EmptyState>
                        )}
                        {!fetchingAnswers && answers.length === 0 && hasFiltersApplied && (
                            <EmptyState
                                width="w-full"
                                className="mt-16"
                                title="No search results"
                                subtitle="No answers were found that matched your filters."
                            >
                                <div className="mt-16">
                                    <Button
                                        onClick={() => {
                                            setSearchTerm('');
                                            clearTagFilters();
                                        }}
                                        label="Clear Filters"
                                    />
                                </div>
                            </EmptyState>
                        )}

                        {answers.map((answer) => {
                            const showStatusPill =
                                answer.answerStatus !== 'IN-USE' && selectedCollection !== CANDIDATES_ID;

                            let pillLabel = '';
                            let pillColour = '';

                            if (answer.answerStatus === CONSTANTS.FLAGGED) {
                                pillLabel = 'Review';
                                pillColour = YELLOW;
                            }
                            if (answer.answerStatus === CONSTANTS.ARCHIVE_ANSWER) {
                                pillLabel = 'Archived';
                                pillColour = GRAY;
                            }

                            return (
                                <Row
                                    dataCy="Answer_Row"
                                    key={answer.candidateId}
                                    spacing="py-16"
                                    className={`cursor-pointer rounded-sm border-black-10 hover:bg-gray-50`}
                                    onClickHandler={() => {
                                        if (selectedCollection === CANDIDATES_ID) {
                                            setShowCandidateforId(answer.candidateId);
                                        } else {
                                            setShowAnswerAnalyticsforId(answer.candidateId);
                                        }
                                    }}
                                >
                                    <>
                                        <Col className={`flex w-1-of-24 pl-8`}>
                                            <input
                                                type="checkbox"
                                                className="h-16 w-16 border-gray-200"
                                                checked={selectedAnswers[answer.candidateId] || false}
                                                value={'checked'}
                                                onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                                                    // This has to be here otherwise the event for Click propogates before the onChange
                                                    event.stopPropagation();
                                                }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                    const selected = event.target.checked;
                                                    toggleAnswerSelection(answer.candidateId, Boolean(selected));
                                                }}
                                            />
                                        </Col>
                                        <Col className={`w-1-of-12`}>
                                            <Avatar size={32} userId={answer.authorId} />
                                        </Col>
                                        <Col className={`${showStatusPill ? 'w-9-of-12' : 'w-10-of-12'} `}>
                                            <div className="whitespace-pre-line break-words clamp-3">
                                                {answer.answer && highlightString(searchTerm, answer.answer)}
                                                {!answer.answer && (
                                                    <span className="italic text-gray-500">No preview available</span>
                                                )}
                                            </div>
                                            {(answer.tags.length > 0 || answer.collections.length > 0) && (
                                                <div className="mt-16 w-full flex-row flex">
                                                    {answer.collections.length > 0 && (
                                                        <TagList
                                                            tags={_map(answer.collections, ({ collection }) => {
                                                                return {
                                                                    tagLabel: collection.collectionName,
                                                                };
                                                            })}
                                                            colour={LIGHTYELLOW}
                                                        />
                                                    )}
                                                    {answer.tags && answer.tags.length > 0 && (
                                                        <TagList tags={answer.tags} />
                                                    )}
                                                </div>
                                            )}
                                        </Col>
                                        {showStatusPill && (
                                            <Col className={`w-1-of-12`}>
                                                <Pill title={pillLabel} colour={pillColour} />
                                            </Col>
                                        )}
                                    </>
                                </Row>
                            );
                        })}
                        {fetchingAnswers && <LoadingSpinner />}
                        {hasMoreData && (
                            <div className="mb-48 mt-32 flex flex-row justify-center gap-16">
                                <Button
                                    label={`Load More Answers`}
                                    buttonType="SECONDARY"
                                    onClick={() => {
                                        setCurrentPage(currentPage + 1);
                                    }}
                                />
                                <Button label={`Load All Answers`} buttonType="TERTIARY" onClick={fetchAllAnswers} />
                            </div>
                        )}
                        {!fetchingAnswers && !hasMoreData && answers.length > 0 && (
                            <EmptyState
                                className="w-344 mb-48 mt-32 bg-gray-50 text-center"
                                title="There are no more answers"
                                subtitle="You're reached the end of the list"
                            />
                        )}
                    </div>
                </div>
                <Tooltip id={tooltipId} />
            </div>
            {showAddAnswer && (
                <AddAnswerModal
                    closeModal={() => {
                        setShowAddAnswer(false);
                        fetchCollectionCounts();
                        fetchAnswers(true);
                    }}
                    switchToAnswerModal={(answerId) => {
                        setShowAddAnswer(false);
                        fetchCollectionCounts();
                        fetchAnswers(true);
                        setShowAnswerAnalyticsforId(answerId);
                    }}
                />
            )}
            {showAnswerAnalyticsforId && (
                <AnswerAnalyticsModal
                    closeModal={() => {
                        setShowAnswerAnalyticsforId(null);
                        fetchCollectionCounts();
                        fetchAnswers(true);
                    }}
                    answerId={showAnswerAnalyticsforId}
                    previousAnswer={() => {
                        if (previousAnswerId) {
                            setShowAnswerAnalyticsforId(previousAnswerId);
                        }
                    }}
                    hasPreviousAnswer={previousAnswerId}
                    nextAnswer={() => {
                        if (nextAnswerId) {
                            setShowAnswerAnalyticsforId(nextAnswerId);
                        }
                    }}
                    hasNextAnswer={nextAnswerId}
                />
            )}
            {showAnswerImporter && (
                <LibraryImporterModal
                    closeModal={() => {
                        setShowAnswerImporter(false);
                        fetchCollectionCounts();
                        fetchAnswers(true);
                    }}
                    collections={collections}
                />
            )}
            {showCandidateforId && (
                <CandidateReviewModal
                    closeModal={() => {
                        setShowCandidateforId(false);
                        fetchCollectionCounts();
                        fetchAnswers(true);
                    }}
                    candidate={_find(answers, {
                        questionId: showCandidateforId,
                    })}
                    previousCandidate={() => {
                        if (previousCandidateId) {
                            setShowCandidateforId(previousCandidateId);
                        }
                    }}
                    hasPreviousCandidate={previousCandidateId}
                    nextCandidate={() => {
                        if (nextCandidateId) {
                            setShowCandidateforId(nextCandidateId);
                        }
                    }}
                    hasNextCandidate={nextCandidateId}
                />
            )}
            {showAddCollection && (
                <AddCollectionModal
                    closeModal={() => {
                        setShowAddCollection(false);
                        fetchCollections();
                    }}
                />
            )}
            {editCollection && (
                <EditCollectionModal
                    closeModal={() => {
                        setEditCollection(null);
                        fetchCollections();
                    }}
                    collection={editCollection}
                />
            )}
        </Module>
    );
};
