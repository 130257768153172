export const shouldShowDiscussion = (): boolean => {
    const pathName = window.location.pathname;

    if (pathName.includes('comment')) {
        return true;
    }
};

export const shouldShowWorkflow = (): boolean => {
    const pathName = window.location.pathname;

    if (pathName.includes('workflow')) {
        return true;
    }
};

export const shouldOpenCollaboration = (): boolean => {
    if (shouldShowDiscussion() || shouldShowWorkflow()) {
        return true;
    }

    return false;
};

export const extractDetailsFromURL = (queryInfo: string): string => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(queryInfo);
};
