import { ChevronRight } from 'assets/chevron-right.svg';
import { Col, Row } from 'components/table';
import { useUserContext } from 'hooks/useUserContext';
import _truncate from 'lodash/truncate';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';

interface AssigneeListElementProps {
    item: {
        userId: string;
        assignedQuestionsCount: number;
        completedQuestionsCount: number;
        status: string;
        lastUpdate: string;
    };
    projectId: string;
}

export const AssigneeListElement = ({ item, projectId }: AssigneeListElementProps): ReactElement => {
    const { getUser } = useUserContext();
    const navigate = useNavigate();

    const assignee = getUser(item.userId);

    const setListElementData = () => {
        switch (item.status) {
            case CONSTANTS.INPROGRESS:
                return {
                    bgColor: 'bg-primary-blue-100-opace-15',
                    color: 'text-primary-blue-100',
                    text: 'In Progress',
                };
            case CONSTANTS.COMPLETE:
                return {
                    bgColor: 'bg-success-lighter',
                    color: 'text-success-dark',
                    text: 'Complete',
                };
            case CONSTANTS.NOT_STARTED:
                return {
                    bgColor: 'bg-red/10',
                    color: 'text-red',
                    text: 'No Progress',
                };
            default:
                break;
        }
    };

    const { bgColor, color, text } = setListElementData();

    const hasActivity = item.lastUpdate;

    return (
        <>
            {assignee && item.userId ? (
                <Row
                    className="border-b border-black-10 hover:bg-gray-50 cursor-pointer"
                    spacing="py-16"
                    onClickHandler={() => {
                        navigate(
                            `${createUrl(ROUTES.PROJECT_QUESTION_ANSWERING_START, {
                                projectId,
                            })}/?${CONSTANTS.ASSIGNED_TO_SPECIFIC}=${item.userId}`,
                        );
                    }}
                >
                    <Col dataCy="Assignee_Name" keyProp={item.userId} className={`w-6-of-24 pl-16`}>
                        {_truncate(assignee.fullName, { length: 42 })}
                    </Col>
                    <Col dataCy="Assigned_Question_Count" keyProp="assignedQues" className={`w-3-of-24`}>
                        {item.assignedQuestionsCount}
                    </Col>
                    <Col dataCy="Assigned_Completed_Question_Count" keyProp="completeQues" className={`w-4-of-24 `}>
                        {item.completedQuestionsCount}
                    </Col>
                    <Col keyProp="status" className={`w-4-of-24`}>
                        <span
                            data-cy="Progress_Status"
                            className={`${bgColor} ${color} small-1-med inline-block w-88 whitespace-nowrap rounded-md px-12 py-8 text-center`}
                        >
                            {text}
                        </span>
                    </Col>
                    <Col
                        dataCy="Assignee_Last_Active"
                        keyProp="status"
                        className={`w-6-of-24 ${!hasActivity ? 'italic text-gray-400' : ''}`}
                    >
                        {hasActivity ? moment(item.lastUpdate).fromNow() : 'No activity'}
                    </Col>
                    <Col keyProp="status" className={`w-1-of-24`}>
                        <ChevronRight />
                    </Col>
                </Row>
            ) : null}
        </>
    );
};
