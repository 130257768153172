import { init } from '@fullstory/browser';
import { Amplify } from 'aws-amplify';
import { TagContextProvider } from 'hooks/useTagsContext';
import { TeamContextProvider } from 'hooks/useTeamContext';
import { UserContextProvider } from 'hooks/useUserContext';
import { AccountAdministration } from 'modules/account-admin/account-admin';
import { AnswerLibraryHome } from 'modules/answer-library/answer-library';
import { AssessmentHome } from 'modules/assessments/assessments.view';
import { Dashboard } from 'modules/dashboard/dashboard.view';
import { DocumentsHome } from 'modules/documents/documents-home';
import { Insights } from 'modules/insights/insights.view';
import { CompleteRegistrationScreen } from 'modules/login/complete-registration/complete-registration-screen.component';
import { ConfirmAccountScreen } from 'modules/login/confirm-account/confirm-account-screen.component';
import { LoginScreen } from 'modules/login/login-screen/login-screen.component';
import { RegisterScreen } from 'modules/login/register/register-screen.component';
import { ResetPasswordScreen } from 'modules/login/reset-password/reset-password-screen.component';
import { Notifications } from 'modules/notifications/notifications.view';
import { AccountSetup } from 'modules/onboarding/onboarding-account-setup.view';
import { TeamCreation } from 'modules/onboarding/onboarding-team-creation.view';
import TeamSetup from 'modules/onboarding/onboarding-team-setup.component';
import { UserProfile } from 'modules/profile/user-profile.view';
import { ProjectDashboardView } from 'modules/project-dashboard/project-dashboard.view';
import { ProjectList } from 'modules/project-list/project-list.view';
import { ProjectHome } from 'modules/project/project.view';
import { Settings } from 'modules/settings/settings.view';
import { Team } from 'modules/team/team.view';
import Welcome from 'modules/welcome/welcome.component';
import React, { ReactElement } from 'react';
import 'react-dates/initialize';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ApplicationLoading } from 'routing/ApplicationLoader';
import { ApplicationRoute } from 'routing/ApplicationRoute';
import { PrivateRoute } from 'routing/PrivateRoute';
import { PublicRoute } from 'routing/PublicRoute';
import { SSOSignIn } from 'routing/SSO';
import { ROUTES } from 'utility/ApplicationRoutes';

init({ orgId: 'o-1EGRV4-na1' });

// @ts-ignore
// window.LOG_LEVEL = 'DEBUG';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
            userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
            signUpVerificationMethod: 'code',
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_COGNITO_IDP_DOMAIN,
                    scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN],
                    redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT],
                    responseType: 'code',
                },
            },
        },
    },
});

const App = (): ReactElement => {
    return (
        <>
            <Router>
                <DndProvider backend={HTML5Backend} context={window}>
                    <UserContextProvider>
                        <TeamContextProvider>
                            <TagContextProvider>
                                <Routes>
                                    <Route path={ROUTES.SSO_REDIRECT} element={<ApplicationLoading />} />
                                    <Route
                                        path={ROUTES.ACCOUNT_ADMIN}
                                        element={
                                            <ApplicationRoute>
                                                <AccountAdministration />
                                            </ApplicationRoute>
                                        }
                                    />

                                    <Route
                                        path={ROUTES.COMPLETE_REGISTRATION}
                                        element={
                                            <PrivateRoute>
                                                <CompleteRegistrationScreen />
                                            </PrivateRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.CONFIRM_REGISTRATION}
                                        element={
                                            <PublicRoute>
                                                <ConfirmAccountScreen />
                                            </PublicRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.REGISTER}
                                        element={
                                            <PublicRoute>
                                                <RegisterScreen />
                                            </PublicRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.LOGIN}
                                        element={
                                            <PublicRoute>
                                                <LoginScreen />
                                            </PublicRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.RESET_PASSWORD}
                                        element={
                                            <PublicRoute>
                                                <ResetPasswordScreen />
                                            </PublicRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.CUSTOM_LOGIN}
                                        element={
                                            <PublicRoute>
                                                <SSOSignIn />
                                            </PublicRoute>
                                        }
                                    />

                                    <Route
                                        path={ROUTES.ONBOARDING_ACCOUNT_SET_UP}
                                        element={
                                            <ApplicationRoute>
                                                <AccountSetup />
                                            </ApplicationRoute>
                                        }
                                    />

                                    <Route
                                        path={ROUTES.ONBOARDING_TEAM_SET_UP}
                                        element={
                                            <ApplicationRoute>
                                                <TeamSetup />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.ONBOARDING_TEAM_CREATION}
                                        element={
                                            <ApplicationRoute>
                                                <TeamCreation />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.PROFILE}
                                        element={
                                            <ApplicationRoute>
                                                <UserProfile />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.NOTIFICATIONS}
                                        element={
                                            <ApplicationRoute>
                                                <Notifications />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.INSIGHTS}
                                        element={
                                            <ApplicationRoute>
                                                <Insights />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.PROJECT_LIST}
                                        element={
                                            <ApplicationRoute>
                                                <ProjectList />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.PROJECT_CREATE}
                                        element={
                                            <ApplicationRoute>
                                                <ProjectList />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.PROJECT_DASHBOARD_WELCOME}
                                        element={
                                            <ApplicationRoute>
                                                <ProjectDashboardView />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.PROJECT_DASHBOARD}
                                        element={
                                            <ApplicationRoute>
                                                <ProjectDashboardView />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.PROJECT_QUESTION_ANSWERING}
                                        element={
                                            <ApplicationRoute>
                                                <ProjectHome />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.ASSSESMENTS_HOME}
                                        element={
                                            <ApplicationRoute>
                                                <AssessmentHome />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.FILES}
                                        element={
                                            <ApplicationRoute>
                                                <DocumentsHome />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.ANSWER_LIBRARY}
                                        element={
                                            <ApplicationRoute>
                                                <AnswerLibraryHome />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.SETTINGS}
                                        element={
                                            <ApplicationRoute>
                                                <Settings />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.TEAM}
                                        element={
                                            <ApplicationRoute>
                                                <Team />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.WELCOME}
                                        element={
                                            <ApplicationRoute>
                                                <Welcome />
                                            </ApplicationRoute>
                                        }
                                    />
                                    <Route
                                        path={ROUTES.HOME}
                                        element={
                                            <ApplicationRoute>
                                                <Dashboard />
                                            </ApplicationRoute>
                                        }
                                    />
                                </Routes>
                            </TagContextProvider>
                        </TeamContextProvider>
                    </UserContextProvider>
                </DndProvider>
            </Router>
        </>
    );
};

export default App;
