import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';

const useOutsideAlerter = (ref, close) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                close();
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};

export default function FloatingElement({
    close,
    children,
    showStyling = true,
    className = '',
    mode = 'fixed',
}: {
    close: () => void;
    children: ReactNode;
    showStyling?: boolean;
    className?: string;
    mode?: string;
}): ReactElement {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, close);

    return (
        <div
            ref={wrapperRef}
            className={`overflow-auto ${mode} z-5 ${showStyling ? 'rounded-md bg-white p-16 shadow-md' : ''} ${className}`}
        >
            {children}
        </div>
    );
}
