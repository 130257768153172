import { testAutomation } from 'components/duex/TestAutomationAttribute';
import React, { ReactElement } from 'react';

interface Card {
    title: string;
    currentValue?: number;
    totalValue?: number;
    percentage?: number;
}

function completionClass(percentage: number): string {
    if (percentage > 75) {
        return 'text-success-dark';
    }
    if (percentage > 50) {
        return 'text-warning-dark';
    }
    return 'text-red';
}

export const BuyerCards = ({ cards }: { cards: Card[] }): ReactElement => {
    return (
        <div className="flex gap-32">
            {cards.map((card, index) => {
                return (
                    <div className="shadow-md rounded-md p-32 justify-center text-center" key={index}>
                        <p className="subtitle-2-reg mb-5 text-black-80">{card.title}</p>
                        {!card.percentage ? (
                            <p data-cy={testAutomation(card.title)} className="h6-medium text-black-80">
                                <span className="h4-medium text-black-100">{card.currentValue}</span> of{' '}
                                <span>{card.totalValue}</span>
                            </p>
                        ) : (
                            <p
                                data-cy={testAutomation(card.title)}
                                className={`text-2xl ${completionClass(card.percentage)}`}
                            >
                                {card.percentage}%
                            </p>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
