import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import { convertToUTF8, isValidStringOfLength, isValidUUID } from 'components/validation/string';
import { isValidUrl } from 'components/validation/url';
import { useTeamContext } from 'hooks/useTeamContext';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Team from 'types/team.type';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

import {
    Photo,
    SectionTitle,
    SettingsCheckbox,
    SettingsImagePicker,
    SettingsItem,
    SettingsNumberField,
    SettingsRadioSelection,
    SettingsSection,
    SettingsTextField,
} from './settings-components';
export const TeamSettings = (): ReactElement => {
    const { refresh } = useTeamContext();
    const { getUser } = useUserContext();
    const [loading, setLoading] = useState(false);
    const [hasDifferences, setHasDifferences] = useState(false);
    const [configurationValid, setConfigurationValid] = useState(false);
    const [settings, setSettings] = useState<Team>({});
    const [originalSettings, setOriginalSettings] = useState<Team>({});
    const [companyLogoFile, setCompanyLogoFile] = useState<Photo>({});

    const compareSettings = () => {
        const settingsChanged = !_isEqual(settings, originalSettings);
        const photoChanged = companyLogoFile.fileName !== undefined;
        setHasDifferences(settingsChanged || photoChanged);
    };

    const validateRedemptionCode = () => {
        if (originalSettings.redemptionCode || !settings.redemptionCode) {
            return true;
        }

        if (settings.redemptionCode && settings.redemptionCode !== originalSettings.redemptionCode) {
            return isValidUUID(settings.redemptionCode.trim());
        }
    };

    const validateWebsite = () => {
        if (originalSettings.companyWebsite || !settings.companyWebsite) {
            return true;
        }
        return isValidUrl(settings.companyWebsite);
    };

    const validateSettings = () => {
        const name = isValidStringOfLength(settings.name, 4);
        const website = validateWebsite();
        const redemptionCode = validateRedemptionCode();

        setConfigurationValid(name && website && redemptionCode);
    };

    const updateSetting = (propertyName: string) => {
        return (newValue: string | boolean | number) => {
            setSettings({
                ...settings,
                [propertyName]: newValue,
            });
        };
    };

    const getChanges = () => {
        const properties = [
            'name',
            'companyWebsite',
            'redemptionCode',
            'importedAnswersRequireReview',
            'defaultAnswersToFlowToLibrary',
            'enableTrustCentre',
            'automaticallyCreateTrustCentre',
            'trustCentreCodeLength',
            'defaultDueDate',
            'enableChatGPTIntegration',
            'projectCreateRequireDealSize',
            'projectCreateRequireExpectedOutcome',
            'projectCreateRequireIndustry',
        ];

        const changedProperties = _filter(properties, (property) => {
            return settings[property] !== originalSettings[property];
        });

        return _pick(settings, changedProperties);
    };

    const fetchSettings = async () => {
        try {
            setLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.GET_TEAM);
            const result = await API.get(url);

            if (result) {
                setSettings(result.data);
                setOriginalSettings(result.data);
            }

            setLoading(false);
        } catch (err) {
            showError('There was an error retrieving your team settings', err);
            setLoading(false);
        }
    };

    const saveSettings = async () => {
        try {
            const started = new Date();
            setLoading(true);

            const updateObj = getChanges();

            if (companyLogoFile && companyLogoFile.fileName) {
                // File has been changed, so we need to upload before making subsequent calls
                const teamLogoUpload = await API.post(ENDPOINTS.getUrl(CONSTANTS.TEAM_LOGO_UPLOAD), {
                    fileName: convertToUTF8(companyLogoFile.fileName),
                    contentType: companyLogoFile.contentType,
                });
                const { uploadUrl, accessUrl } = teamLogoUpload.data;

                await API.putWithoutAuth(uploadUrl, companyLogoFile.file, {
                    'Content-Type': companyLogoFile.contentType,
                });

                updateObj.teamLogo = accessUrl;
            }

            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_TEAM);
            await API.post(url, updateObj);

            if (originalSettings.redemptionCode !== settings.redemptionCode) {
                showSuccess('Redemption code applied');
            } else {
                showSuccess('Settings Saved');
            }

            logEvent(`TEAM_SETTINGS_UPDATE`, started);
            setLoading(false);
            setCompanyLogoFile({});
            fetchSettings();
            refresh();
        } catch (err) {
            if (originalSettings.redemptionCode !== settings.redemptionCode) {
                showError('Please check the redemption code and try again');
            } else {
                showError('There was an error saving your team settings', err);
            }

            setLoading(false);
        }
    };

    const reset = async () => {
        setSettings(originalSettings);
        setCompanyLogoFile({});
    };

    useEffect(() => {
        compareSettings();
        validateSettings();
    }, [settings, originalSettings, companyLogoFile]);

    useEffect(() => {
        fetchSettings();
    }, []);

    useEffect(() => {
        document.title = 'Pearler | Team Settings';
    }, []);

    return (
        <div>
            <SectionTitle title="Team Settings" />

            {loading && <LoadingSpinner className="pt-32" />}

            {!loading && (
                <>
                    <SettingsSection title="Company Information" />
                    <SettingsItem>
                        <SettingsTextField
                            description="Company Name"
                            currentValue={settings.name || ''}
                            onChange={updateSetting('name')}
                            placeholder="Please provide a company name"
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsTextField
                            description="Company Website"
                            currentValue={settings.companyWebsite || 'https://www.'}
                            onChange={updateSetting('companyWebsite')}
                            placeholder="Please provide a company website address"
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsImagePicker
                            description="Company Logo"
                            currentValue={settings.teamLogo}
                            updatedSelection={companyLogoFile}
                            onChange={(logoFile: Photo) => {
                                setCompanyLogoFile(logoFile);
                            }}
                        />
                    </SettingsItem>

                    <SettingsSection title="Promotions" />
                    {settings.redemptionCodes && settings.redemptionCodes.length > 0 && (
                        <div className="m-16">
                            <div className="mb-8">Claimed Redemption Codes</div>
                            {settings.redemptionCodes.map((code) => {
                                const { fullName } = getUser(code.claimedByUserId);

                                return (
                                    <div
                                        className="flex flex-row justify-between gap-16 rounded-md p-16 text-14 leading-24 text-gray-600 hover:bg-gray-50"
                                        key={code.redemptionCode}
                                    >
                                        <div className="flex flex-row gap-16">
                                            <Avatar userId={code.claimedByUserId} size={24} />
                                            <div className="">******************************{code.redemptionCode}</div>
                                        </div>

                                        <div>
                                            Claimed by {fullName} on {moment(code.claimedAt).format('d MMM yy')}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <SettingsItem>
                        <SettingsTextField
                            description="Redemption Code"
                            currentValue={settings.redemptionCode || ''}
                            onChange={updateSetting('redemptionCode')}
                            placeholder="Enter a redemption code to claim"
                        />
                    </SettingsItem>

                    <SettingsSection title="Response Projects" />
                    <SettingsItem>
                        <SettingsRadioSelection
                            description="Do you want to capture the Expected Outcome during Project creation?"
                            currentValue={settings.projectCreateRequireExpectedOutcome || false}
                            onChange={updateSetting('projectCreateRequireExpectedOutcome')}
                            options={[
                                {
                                    label: 'No, allow users to create a Project without an Expected Outcome',
                                    value: false,
                                    isDefault: true,
                                },
                                {
                                    label: 'Yes, ensure all new Projects have an Expected Outcome',
                                    value: true,
                                },
                            ]}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsRadioSelection
                            description="Do you want to capture the Industry during Project creation?"
                            currentValue={settings.projectCreateRequireIndustry || false}
                            onChange={updateSetting('projectCreateRequireIndustry')}
                            options={[
                                {
                                    label: 'No, allow users to create a Project without an Industry',
                                    value: false,
                                    isDefault: true,
                                },
                                {
                                    label: 'Yes, ensure all new Projects have an Industry',
                                    value: true,
                                },
                            ]}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsRadioSelection
                            description="Do you want to capture the Project Value during Project creation?"
                            currentValue={settings.projectCreateRequireDealSize || false}
                            onChange={updateSetting('projectCreateRequireDealSize')}
                            options={[
                                {
                                    label: 'No, allow users to create a Project without a Project Value',
                                    value: false,
                                    isDefault: true,
                                },
                                {
                                    label: 'Yes, ensure all new Projects have a Project Value',
                                    value: true,
                                },
                            ]}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsNumberField
                            description="Default Project Due Date (days from Project creation)"
                            currentValue={settings.defaultDueDate || 14}
                            onChange={updateSetting('defaultDueDate')}
                            placeholder="Enter the number of days"
                            minValue={1}
                            maxValue={365}
                        />
                    </SettingsItem>

                    <SettingsSection title="Answer Library" />
                    <SettingsItem>
                        <SettingsRadioSelection
                            description="Should Answers created in the Answer Library be flagged for review?"
                            currentValue={settings.importedAnswersRequireReview || false}
                            onChange={updateSetting('importedAnswersRequireReview')}
                            options={[
                                {
                                    label: 'Yes, newly created answers should be flagged for review',
                                    value: true,
                                    isDefault: true,
                                },
                                {
                                    label: "No, don't flag newly created answers for review",
                                    value: false,
                                },
                            ]}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsRadioSelection
                            description="When creating Questions, should we tick 'Include in Library' for you?"
                            currentValue={settings.defaultAnswersToFlowToLibrary || false}
                            onChange={updateSetting('defaultAnswersToFlowToLibrary')}
                            options={[
                                {
                                    label: 'Yes, all Answers given in Projects should flow into the Answer Library',
                                    value: true,
                                    isDefault: true,
                                },
                                {
                                    label: "No, we don't want Answers given in a Project to flow into the Answer Library",
                                    value: false,
                                },
                            ]}
                        />
                    </SettingsItem>

                    <SettingsSection title="Trust Centre" />
                    <SettingsItem>
                        <SettingsRadioSelection
                            description="Do you want to use the Trust Centre?"
                            currentValue={settings.enableTrustCentre || false}
                            onChange={updateSetting('enableTrustCentre')}
                            options={[
                                {
                                    label: 'Yes, turn on Trust Centre globally',
                                    value: true,
                                    isDefault: true,
                                },
                                {
                                    label: 'No, turn off the Trust Centre globally',
                                    value: false,
                                },
                            ]}
                        />
                    </SettingsItem>
                    {settings.enableTrustCentre && (
                        <>
                            <SettingsItem>
                                <SettingsRadioSelection
                                    description="Should we create a Trust Centre for new Projects?"
                                    currentValue={settings.automaticallyCreateTrustCentre || false}
                                    onChange={updateSetting('automaticallyCreateTrustCentre')}
                                    options={[
                                        {
                                            label: 'Automatically create a Trust Centre for the Project',
                                            value: true,
                                            isDefault: true,
                                        },
                                        {
                                            label: "Don't create a Trust Centre. Team members manually create",
                                            value: false,
                                        },
                                    ]}
                                />
                            </SettingsItem>
                            <SettingsItem>
                                <SettingsNumberField
                                    description="Minimum Access Code Length"
                                    currentValue={settings.trustCentreCodeLength || 7}
                                    onChange={updateSetting('trustCentreCodeLength')}
                                    placeholder="Enter a number for the minimum length"
                                    minValue={4}
                                    maxValue={255}
                                />
                            </SettingsItem>
                        </>
                    )}
                    {settings.allowChatGptIntegration && (
                        <>
                            <SettingsSection title="AI Assistant" />
                            <SettingsItem>
                                <SettingsCheckbox
                                    description="Enable AI Answer Generation & Re-writing"
                                    currentValue={settings.enableChatGPTIntegration || false}
                                    onChange={updateSetting('enableChatGPTIntegration')}
                                />
                            </SettingsItem>
                        </>
                    )}

                    <ButtonBar className="mt-16">
                        <Button
                            label="Save Changes"
                            disabled={!hasDifferences || !configurationValid}
                            onClick={saveSettings}
                            loading={loading}
                        />
                        {hasDifferences && (
                            <Button buttonType="SECONDARY" label="Undo Changes" disabled={loading} onClick={reset} />
                        )}
                    </ButtonBar>
                </>
            )}
        </div>
    );
};
