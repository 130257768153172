import { SuccessTick } from 'assets/success-tick.svg';
import React from 'react';
import { toast } from 'react-toastify';

const SuccessToast = ({ msg }: { msg: string }) => {
    return (
        <div className="flex cursor-pointer items-center">
            <div className="mr-10">
                <SuccessTick />
            </div>
            <span className="small-1-med text-black-80">{msg}</span>
        </div>
    );
};

export const showSuccess = (msg: string): void => {
    toast.success(<SuccessToast msg={msg} />, {
        icon: false,
    });
};
