import Tooltip, { getTooltipId } from 'components/duex/Tooltip';
import { useUserContext } from 'hooks/useUserContext';
import React, { MouseEvent, ReactElement } from 'react';
import { QuestionCard } from 'types';
import { CONSTANTS } from 'utility/Constants';
import { PERMISSION } from 'utility/Permission';

interface QuestionCompletionCardProps {
    type: string;
    data: QuestionCard;
    heading: string;
    linkText: string;
    callBack: (value: string) => void;
    labels: string[];
}

export const QuestionCompletionCard = ({
    type,
    data,
    heading,
    linkText,
    callBack,
    labels,
}: QuestionCompletionCardProps): ReactElement => {
    const { hasPermission } = useUserContext();
    const getDataColour = (type: string) => {
        switch (type) {
            case CONSTANTS.INCOMPLETE_STATUS:
                return { dataCy: 'Incomplete_Status', dataColour: 'text-black-100' };
            case CONSTANTS.NONCOMPLIANT:
                return { dataCy: 'Noncompliant_Status', dataColour: 'text-red' };
            case CONSTANTS.PARTIALLYCOMPLIANT:
                return { dataCy: 'Partiallycompliant_Status', dataColour: 'text-warning-dark' };
            case CONSTANTS.COMPLIANT:
                return { dataCy: 'Compliant_Status', dataColour: 'text-success-dark' };
            case CONSTANTS.NOTAPPLICABLE:
                return { dataCy: 'Notapplicable_Status', dataColour: 'text-black-60' };
            default:
                return { dataColour: 'text-black-100' };
        }
    };

    const { dataColour, dataCy } = getDataColour(type);

    const hasQuestionAnswerPermission = hasPermission(PERMISSION.ANSWER_QUESTION);

    const tooltipId = getTooltipId('question-completion-card');

    let description = '';

    if (labels.length > 0) {
        description = ['Custom Compliance:', ...labels].join('<br /> - ');
    }
    return (
        <>
            <li data-value={type} className="flex w-18-percent flex-col rounded-lg shadow-md">
                <div className="flex-grow-2 p-24">
                    <p
                        className="subtitle-2-reg mb-5 text-black-80"
                        data-tooltip-id={tooltipId}
                        data-tooltip-html={description}
                    >
                        {heading}
                    </p>
                    {type === CONSTANTS.INCOMPLETE_STATUS ? (
                        <p data-cy={dataCy} className="h6-medium text-black-80">
                            <span className="h4-medium text-black-100">{data.quantity}</span> of{' '}
                            <span>{data.total}</span>
                        </p>
                    ) : (
                        <p data-cy={dataCy} className={`${dataColour} text-2xl`}>
                            {data.percentage}
                        </p>
                    )}
                </div>
                {!data.quantity && hasQuestionAnswerPermission ? (
                    <p
                        data-tooltip-id={tooltipId}
                        data-tooltip-content={`There are no ${heading.toLowerCase()} questions`}
                        data-value={type}
                        className={`button-med-14 bg-gray-50 p-16 text-black-60`}
                    >
                        {linkText}
                    </p>
                ) : data.quantity && hasQuestionAnswerPermission ? (
                    <p
                        onClick={
                            hasQuestionAnswerPermission
                                ? (event: MouseEvent<HTMLParagraphElement>) =>
                                      callBack(event.currentTarget.dataset.value)
                                : null
                        }
                        data-value={type}
                        className={`button-med-14 cursor-pointer bg-gray-50 p-16 text-primary-blue-100`}
                    >
                        {linkText}
                    </p>
                ) : (
                    <p
                        data-tooltip-content="You don’t have access to view the questionnaire"
                        data-tooltip-id={tooltipId}
                        onClick={
                            hasQuestionAnswerPermission
                                ? (event: MouseEvent<HTMLParagraphElement>) =>
                                      callBack(event.currentTarget.dataset.value)
                                : null
                        }
                        data-value={type}
                        className={`button-med-14 bg-gray-50 p-16 text-black-60`}
                    >
                        {linkText}
                    </p>
                )}
                <Tooltip id={tooltipId} />
            </li>
        </>
    );
};
