import { Cross } from 'assets/close.svg';
import { Button } from 'components/duex/Button';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { isValidEmail } from 'components/validation/string';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import React, { ReactElement, useEffect, useState } from 'react';
export function AddRecipientsModal({
    closeModal,
    recipients,
    setRecipients,
}: {
    closeModal: () => void;
    setRecipients: (updatedList: string[]) => void;
    recipients: string[];
}): ReactElement {
    const [currentRecipients, setCurrentRecipients] = useState<string[]>([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');
    const [emailValid, setEmailValid] = useState(false);

    const undoChanges = () => {
        setCurrentRecipients(recipients);
    };

    const saveChanges = () => {
        setRecipients(currentRecipients);
        closeModal();
    };

    const addEmail = () => {
        if (currentRecipients.indexOf(emailAddress) === -1) {
            setCurrentRecipients([...currentRecipients, emailAddress]);
        }
        setEmailAddress('');
    };

    const removeEmail = (email: string) => {
        setCurrentRecipients(
            _filter(currentRecipients, (emailAddress) => {
                return email !== emailAddress;
            }),
        );
    };

    const updateStatus = () => {
        setHasChanges(!_isEqual(recipients, currentRecipients));
    };

    const emailValidator = () => {
        return isValidEmail(emailAddress) && currentRecipients.indexOf(emailAddress) === -1;
    };

    useEffect(() => {
        updateStatus();
    }, [currentRecipients]);

    useEffect(() => {
        setCurrentRecipients(recipients);
    }, [recipients]);

    useEffect(() => {
        setEmailValid(emailValidator());
    }, [emailAddress]);

    return (
        <Modal
            width="w-600"
            title="Email Recipients"
            closeModal={closeModal}
            closeSafely={() => {
                return !hasChanges;
            }}
        >
            <p className="text-left text-gray-500">
                Recipients in this list will be sent an email notifying them of your comment and giving them information
                about this Question.
            </p>
            <div className="my-16 rounded-md bg-gray-50 p-16">
                <div className="grid gap-16" style={{ gridTemplateColumns: 'auto 70px' }}>
                    <FormTextField
                        valueChanged={setEmailAddress}
                        defaultValue={emailAddress}
                        validator={emailValidator}
                        placeholder="Provide an email address"
                        label="Email Address"
                        errorMessage="Pleae check the email address is valid"
                        required={true}
                    />
                    <div className="mt-24">
                        <Button buttonType="PRIMARY" disabled={!emailValid} label="Add" onClick={addEmail} />
                    </div>
                </div>
                {currentRecipients.length > 0 && <h3 className="small-1-med mb-4 mt-16 text-black-60">Recipients</h3>}
                {currentRecipients.map((recipient) => {
                    return (
                        <div
                            key={recipient}
                            className="mb-4 grid w-full gap-16 rounded-md bg-white px-16 py-8"
                            style={{ gridTemplateColumns: 'auto 20px' }}
                        >
                            {recipient}
                            <button
                                className="flex cursor-pointer flex-row items-center justify-center opacity-80 hover:opacity-100"
                                onClick={() => {
                                    removeEmail(recipient);
                                }}
                            >
                                <Cross strokeColor="#2068E3" />
                            </button>
                        </div>
                    );
                })}
            </div>
            <div className="mt-16 flex flex-row-reverse gap-16">
                <Button buttonType="SECONDARY" label="Cancel" onClick={closeModal} />
                <Button buttonType="PRIMARY" label="Save" disabled={!hasChanges} onClick={saveChanges} />
                {hasChanges && <Button buttonType="TERTIARY" label="Undo Changes" onClick={undoChanges} />}
            </div>
        </Modal>
    );
}
