import React, { ReactElement, ReactNode } from 'react';

export const ButtonBar = ({
    children,
    alignment = 'RIGHT',
    className = '',
}: {
    children: ReactNode;
    alignment?: 'LEFT' | 'RIGHT' | 'CENTRE';
    className?: string;
}): ReactElement => {
    let alignmentClass = 'flex-row-reverse';

    if (alignment === 'LEFT') {
        alignmentClass = 'flex-row';
    }
    if (alignment === 'CENTRE') {
        alignmentClass = 'flex-row justify-center';
    }

    return <div className={`flex ${className} ${alignmentClass} gap-16`}>{children}</div>;
};
