import React, { ReactElement, ReactNode } from 'react';

const Col = ({
    dataCy,
    resetDefaultStyling,
    keyProp,
    children,
    className,
    customStyling,
    onClickHandler,
    allowOverflow = false,
    style = {},
}: {
    resetDefaultStyling?: boolean;
    keyProp?: string;
    children?: ReactNode | string | number;
    className?: string;
    customStyling?: string;
    onClickHandler?;
    allowOverflow?: boolean;
    dataCy?: string;
    style?: {
        [key: string]: string;
    };
}): ReactElement => {
    return (
        <div
            data-cy={dataCy}
            key={keyProp}
            className={`${
                resetDefaultStyling ? customStyling : 'body-2-reg text-ellipsis text-black-80'
            } ${className} ${!allowOverflow ? 'overflow-hidden' : ''}`}
            onClick={onClickHandler}
            style={style}
        >
            {children}
        </div>
    );
};

export default Col;
