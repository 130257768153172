import { ChevronRight } from 'assets/chevron-right.svg';
import { Avatar } from 'components/duex/Avatar';
import { getPillForComplianceStatus } from 'components/duex/CompliancePill';
import { TextDiff } from 'components/duex/TextDiff';
import { useUserContext } from 'hooks/useUserContext';
import { Value } from 'modules/answer-library/analytics/typography';
import React from 'react';
import { getPlainText } from 'utility/StringHelper';

export const SummariseChanges = ({ changes }: { changes }) => {
    const diffKeys = {
        richTextAnswer: 'Answer',
        assumptions: 'Assumptions',
        commitments: 'Commitments',
        question: 'Question',
        information: 'Information',
    };

    const plainKeys = {
        customStatusValue: 'Status',
        responseStatus: 'Status',
        referenceNumber: 'Reference Number',
        sectionDescription: 'Section Description',
    };

    const booleanKeys = {
        'reviewStatus.answerApproved': 'Approved',
        'reviewStatus.winThemes': 'Win Themes',
        'reviewStatus.contentAccurate': 'Content Accurate',
        automaticallyManageStatus: 'Automatic Status Management',
    };

    return (
        <div className="mt-8 text-14 text-gray-500 gap-8 flex flex-col">
            <table className="w-full">
                <tr>
                    <td className="underline p-8">Field</td>
                    <td className="underline p-8 w-70-percent">Change</td>
                </tr>
                {changes.map((change) => {
                    if (change.data.valueKey === 'responseClassification') {
                        return (
                            <tr className="">
                                <td className="p-8">Compliance</td>
                                <td className="p-8 flex flex-row gap-8 items-center">
                                    {getPillForComplianceStatus(change.data.before)}
                                    <ChevronRight />
                                    {getPillForComplianceStatus(change.data.after)}
                                </td>
                            </tr>
                        );
                    }
                    if (diffKeys[change.data.valueKey]) {
                        return (
                            <tr>
                                <td className="p-8">{diffKeys[change.data.valueKey]}</td>
                                <td className="p-8">
                                    <TextDiff
                                        textA={getPlainText(change.data.before)}
                                        textB={getPlainText(change.data.after)}
                                    />
                                </td>
                            </tr>
                        );
                    }
                    if (plainKeys[change.data.valueKey]) {
                        return (
                            <tr>
                                <td className="p-8">{plainKeys[change.data.valueKey]}</td>
                                <td className="p-8">{change.data.after}</td>
                            </tr>
                        );
                    }
                    if (booleanKeys[change.data.valueKey]) {
                        return (
                            <tr>
                                <td className="p-8">{booleanKeys[change.data.valueKey]}</td>
                                <td className="p-8">{change.data.after ? 'Ticked' : 'Unticked'}</td>
                            </tr>
                        );
                    }
                    if (change.data.valueKey === 'assignedToUserId') {
                        const { getUser } = useUserContext();
                        const newAssignee = getUser(change.data.after);

                        return (
                            <tr>
                                <td className="p-8">Assignee</td>
                                <td className="p-8">
                                    <div
                                        className="grid items-center gap-8"
                                        style={{
                                            gridTemplateColumns: '32px auto',
                                        }}
                                    >
                                        <Avatar userId={newAssignee.userId} size={32} />
                                        <Value title={newAssignee.fullName || 'Unknown'} preventWrapping={true} />
                                    </div>
                                </td>
                            </tr>
                        );
                    }
                    return null;
                })}
            </table>
        </div>
    );
};
