import {
    FetchUserAttributesOutput,
    ResetPasswordOutput,
    confirmResetPassword,
    confirmSignUp,
    fetchAuthSession,
    fetchUserAttributes,
    resendSignUpCode,
    resetPassword,
    signIn,
    signOut,
    signUp,
} from 'aws-amplify/auth';
import _filter from 'lodash/filter';
import _first from 'lodash/first';
import _get from 'lodash/get';
import _includes from 'lodash/includes';
import _keyBy from 'lodash/keyBy';
import React, { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Membership, User } from 'types';
import UserStatus from 'types/user-status.type';
import { logError, trackLogin, trackLogout } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ONBOARDING_ROUTES, PUBLIC_ROUTES, ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { useCurrentPath } from './useCurrentPath';
import { useSocket } from './useSocket';
interface CognitoResult {
    success?: boolean;
    error?: boolean;
    errorMessage?: {
        message?: string;
        code?: string;
    };
    CodeDeliveryDetails?: {
        DeliveryMedium: string;
    };
}

interface UserContext {
    isLoggedIn: boolean;
    hasPermission: (permissionName: string) => boolean;
    user: User;
    teamList: Membership[];
    fullTeamList: Membership[];
    permissions: { [key: string]: boolean };
    userHasTeam: boolean;
    userStatus: UserStatus;
    username: string;
    restartApplication: () => void;
    refreshUser: () => void;
    refreshTeam: () => void;
    refreshPermissions: () => void;
    getUser: (userId: string) => User;
    getUserInfo: (userId: string) => Membership;
    authToken: string;
    signIn: (email: string, password: string) => Promise<CognitoResult>;
    signUp: (email: string, password: string) => Promise<CognitoResult>;
    confirmSignUp: (email: string, confirmationCode: string) => Promise<CognitoResult>;
    logout: () => Promise<CognitoResult>;
    forgotPasswordRequest: (email: string) => Promise<ResetPasswordOutput>;
    forgotPasswordSubmit: (email: string, code: string, password: string) => Promise<CognitoResult>;
    checkingAuth: boolean;
    getCurrentUser: () => Promise<FetchUserAttributesOutput>;
    resendConfirmationCode: (email: string) => Promise<CognitoResult>;
    homeUrl: string;
    autoSignIn: () => Promise<CognitoResult>;
    redirectUser: () => string;
    getAccountStatus: () => Promise<boolean | string>;
}

const dummyObject: Membership = {
    userId: null,
    membershipId: null,
    member: {
        userId: null,
        fullName: '',
        photo: '',
    },
};

// eslint-disable-next-line
function getUsername(currentUser: any): string {
    const { email, identities } = currentUser;

    if (email) {
        return email;
    }

    if (identities) {
        const firstIdentity = _first(JSON.parse(identities));
        if (firstIdentity) {
            if (firstIdentity.userId) {
                return firstIdentity.userId;
            }
        }
    }

    logError('Cognito object missing email address', {
        message: currentUser,
    });

    return '';
}

// @ts-ignore
const UserContext = createContext<UserContext>({});

export const UserContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userHasTeam, setHasTeam] = useState(false);
    const [username, setUsername] = useState('');
    const [user, setUser] = useState<User>({});
    const [userStatus, setUserStatus] = useState(null);
    const [team, setTeam] = useState({});
    const [teamList, setTeamList] = useState([]);
    const [fullTeamList, setFullTeamList] = useState([]);
    const [permissions, setPermissions] = useState({});
    const [homeUrl, setHomeUrl] = useState(ROUTES.HOME);
    const [credentials, setCredentials] = useState(null);
    const [originalUri, setOriginalUri] = useState(window.location.pathname);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = useCurrentPath();

    const { latestMessage, disconnect, connect } = useSocket();

    const [authToken, setAuthToken] = useState(null);
    const [checkingAuth, setCheckingAuth] = useState(true); // Important that this is defaulted to true to start

    const outcomeRedirect = (uri: string, outcome: boolean[]) => {
        const [redirect] = outcome;

        if (uri) {
            navigate(uri);
        }

        if (redirect) {
            setHomeUrl(uri);
        }

        return outcome;
    };

    const bootProcess = async (): Promise<boolean[]> => {
        const hasSession = await checkSession();

        if (!hasSession) {
            if (!_includes(PUBLIC_ROUTES, currentPath)) {
                return outcomeRedirect(ROUTES.LOGIN, [hasSession, false]);
            }
            return [hasSession, false];
        }

        const hasAccount = await checkAccount();

        if (!hasAccount) {
            const user = await getCurrentUser();
            const url = `${ROUTES.COMPLETE_REGISTRATION}?email=${encodeURIComponent(getUsername(user))}`;
            return outcomeRedirect(url, [hasAccount, false]);
        }

        setCredentials(null);

        const status = await getAccountStatus();
        const response = [hasAccount, status === CONSTANTS.USER_ACCOUNT_READY];

        if (status === CONSTANTS.USER_HAS_INVITATIONS) {
            return outcomeRedirect(ROUTES.ONBOARDING_ACCOUNT_SET_UP, response);
        }

        if (status === CONSTANTS.USER_HAS_NO_INVITATIONS) {
            return outcomeRedirect(ROUTES.ONBOARDING_TEAM_SET_UP, response);
        }

        if (status === CONSTANTS.USER_ACCOUNT_READY) {
            setHasTeam(true);

            if (originalUri && !_includes(PUBLIC_ROUTES, originalUri) && !_includes(ONBOARDING_ROUTES, originalUri)) {
                setOriginalUri(ROUTES.HOME);

                if (originalUri === ROUTES.SSO_REDIRECT) {
                    return outcomeRedirect(ROUTES.HOME, response);
                }
                return outcomeRedirect(originalUri, response);
            } else if (_includes(PUBLIC_ROUTES, location.pathname) || _includes(ONBOARDING_ROUTES, location.pathname)) {
                return outcomeRedirect(ROUTES.HOME, response);
            }

            return response;
        }
    };

    const redirectUser = (): string => {
        if (!userStatus || !userStatus.status) {
            return;
        }

        const { status } = userStatus;

        if (status === CONSTANTS.USER_ACCOUNT_READY) {
            return null;
        }

        if (status === CONSTANTS.USER_HAS_INVITATIONS && !_includes(ONBOARDING_ROUTES, location.pathname)) {
            return ROUTES.ONBOARDING_ACCOUNT_SET_UP;
        }

        if (status === CONSTANTS.USER_HAS_NO_INVITATIONS && !_includes(ONBOARDING_ROUTES, location.pathname)) {
            return ROUTES.ONBOARDING_TEAM_SET_UP;
        }
    };

    const bootSession = async () => {
        setCheckingAuth(true);
        const [loggedIn, accountReady] = await bootProcess();

        setIsLoggedIn(loggedIn);

        if (accountReady) {
            fetchTeam();
            fetchPermissions();
        }

        setCheckingAuth(false);
    };

    const checkSession = async (): Promise<boolean> => {
        try {
            const session = await getCurrentSession();
            const user = await getCurrentUser();
            setAuthToken(session.tokens.accessToken);
            setUsername(getUsername(user));
            return true;
        } catch (error) {
            setAuthToken('');
            return false;
        }
    };

    const checkAccount = async (): Promise<boolean> => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.USER);
            const result = await API.get(url);

            if (result && result.data) {
                setUser(result.data);

                const { userId } = result.data;
                trackLogin(userId, result.data);
                API.post(ENDPOINTS.getUrl(CONSTANTS.USER_LOGGED_IN));
                connect();

                return true;
            }
        } catch (err) {
            setUser(null);
        }
    };

    const getAccountStatus = async (): Promise<string | boolean> => {
        try {
            const result = await API.get(ENDPOINTS.getUrl(CONSTANTS.USER_STATUS));

            if (result && result.data) {
                const { status } = result.data;
                setUserStatus(result.data);
                return status;
            }
        } catch (err) {
            setUserStatus(null);
            return false;
        }
    };

    const getCurrentUser = async () => {
        const user = await fetchUserAttributes();
        return user;
    };

    const getCurrentSession = async () => {
        const session = await fetchAuthSession();
        return session;
    };

    const autoSignIn = async (): Promise<CognitoResult> => {
        try {
            if (!credentials) {
                return {
                    error: true,
                    errorMessage: {
                        message: 'Unable to automatically sign in',
                    },
                };
            }

            const { email, password } = credentials;

            const { success } = await logIn(email, password);

            setCredentials(null);

            return {
                success,
            };
        } catch (errorMessage) {
            return {
                error: true,
                errorMessage,
            };
        }
    };

    const logIn = async (email: string, password: string): Promise<CognitoResult> => {
        try {
            const res = await signIn({ username: email, password });

            if (res) {
                setCredentials({
                    email,
                    password,
                });
                await bootSession();
            }

            return {
                success: true,
            };
        } catch (errorMessage) {
            return {
                error: true,
                errorMessage,
            };
        }
    };

    const registerAccount = async (email: string, password: string): Promise<CognitoResult> => {
        try {
            await signUp({
                username: email,
                password: password,
                options: {
                    userAttributes: {
                        email,
                    },
                },
            });
            setCredentials({
                email,
                password,
            });
            return {
                success: true,
            };
        } catch (errorMessage) {
            return {
                error: true,
                errorMessage,
            };
        }
    };

    const confirmSignUpAccount = async (username: string, confirmationCode: string): Promise<CognitoResult> => {
        try {
            await confirmSignUp({ username, confirmationCode });
            return {
                success: true,
            };
        } catch (errorMessage) {
            return {
                error: true,
                errorMessage,
            };
        }
    };

    const logout = async (): Promise<CognitoResult> => {
        try {
            setIsLoggedIn(false);
            setUser({});
            setTeam({});
            setTeamList([]);
            setFullTeamList([]);
            setPermissions({});
            setHasTeam(false);
            setUserStatus({});
            trackLogout();
            setAuthToken(null);
            setUsername('');
            setCredentials(null);
            disconnect();
            setOriginalUri(ROUTES.HOME);

            await signOut();
            navigate(ROUTES.LOGIN);

            return {
                success: true,
            };
        } catch (errorMessage) {
            return {
                error: true,
                errorMessage,
            };
        }
    };

    const forgotPasswordRequest = async (username: string): Promise<ResetPasswordOutput> => {
        return await resetPassword({ username });
    };

    const forgotPassword = async (
        username: string,
        confirmationCode: string,
        newPassword: string,
    ): Promise<CognitoResult> => {
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });
            return {
                success: true,
            };
        } catch (errorMessage) {
            return {
                error: true,
                errorMessage,
            };
        }
    };

    const resendConfirmationCode = async (email: string): Promise<CognitoResult> => {
        try {
            await resendSignUpCode({
                username: email,
            });
            return {
                success: true,
            };
        } catch (errorMessage) {
            return {
                error: true,
                errorMessage,
            };
        }
    };

    const fetchTeam = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.ALL_TEAM);
            const result = await API.get(url);

            if (result && result.data) {
                const activeTeamList = _filter(result.data, (member) => {
                    return member.userId;
                });

                setTeam(_keyBy(activeTeamList, 'userId'));
                setTeamList(activeTeamList);
                setFullTeamList(result.data);
            }
        } catch (err) {
            // showError('There was an error retrieving your team', err);
        }
    };

    const fetchPermissions = async (): Promise<void> => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.PERMISSIONS);
            const result = await API.get(url);

            if (result && result.data) {
                setPermissions(result.data);
            }
        } catch (err) {
            // showError('There was an error retrieving your permissions', err);
        }
    };

    const hasPermission = (permissionName: string): boolean => {
        return _get(permissions, permissionName, false);
    };

    const getUser = (userId: string) => {
        if (team[userId]) {
            return team[userId].member;
        }

        return dummyObject.member;
    };

    const getUserInfo = (userId: string) => {
        if (team[userId]) {
            return team[userId];
        }

        return dummyObject;
    };

    const store = {
        isLoggedIn,
        user,
        teamList,
        fullTeamList,
        permissions,
        userHasTeam,
        userStatus,
        restartApplication: bootSession,
        refreshUser: checkAccount,
        refreshTeam: fetchTeam,
        refreshPermissions: fetchPermissions,
        hasPermission,
        getUser,
        getUserInfo,
        logout,
        authToken,
        signIn: logIn,
        signUp: registerAccount,
        confirmSignUp: confirmSignUpAccount,
        forgotPasswordRequest,
        forgotPasswordSubmit: forgotPassword,
        checkingAuth,
        getCurrentUser,
        getAccountStatus,
        resendConfirmationCode,
        username,
        homeUrl,
        autoSignIn,
        redirectUser,
    };

    const socketMessageReceived = () => {
        if (latestMessage && latestMessage.eventName) {
            if (latestMessage.eventName === 'TEAM_MEMBER_UPDATED' || latestMessage.eventName === 'USER_UPDATED') {
                fetchTeam();
            }
        }
    };

    useEffect(() => {
        socketMessageReceived();
    }, [latestMessage]);

    useEffect(() => {
        bootSession();
    }, []);

    return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
};

export const useUserContext = (): UserContext => useContext(UserContext);
