import { Link } from 'assets/link.svg';
import { EmptyState } from 'components/empty-states.component';
import { showSuccess } from 'components/success-toast.component';
import React, { ReactElement } from 'react';
import { Library } from 'types';
import { ReactComponent as PersonSearching } from '../../../../assets/person-searching.svg';
import { Loader } from '../../../../components/loader.component';
interface ExploreQuestionsProps {
    fetchingTerms: boolean;
    libraryTerms: Library[];
}

const getHostname = (url: string) => {
    // use URL constructor and return hostname
    return new URL(url).hostname;
};

export const ExploreQuestions = ({ fetchingTerms, libraryTerms }: ExploreQuestionsProps): ReactElement => {
    const renderTerms = () => {
        return (
            <ul data-cy="Library_Terms">
                {libraryTerms.map((itm, idx) => (
                    <li key={idx} className="mb-40">
                        <div className="mb-8">
                            <p data-cy="Library_Terms_Title" className="inline text-20 leading-28 text-black-80">
                                {itm.term}
                            </p>
                            <p
                                className="relative inline-block cursor-pointer"
                                onClick={() => {
                                    navigator.clipboard.writeText(itm.term);
                                    showSuccess('Definition copied to clipboard');
                                }}
                            ></p>
                        </div>
                        <p data-cy="Library_Terms_Definition" className="text-16 leading-24 text-black-80">
                            {itm.definition}
                        </p>
                        <ul className="overflow-auto">
                            {itm.links.map((itm, idx) => (
                                <li key={idx} className="float-left mr-16 mt-16 text-primary-blue-100">
                                    <a
                                        data-cy="Library_Terms_Link"
                                        href={itm}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="block text-14 leading-24 hover:underline"
                                    >
                                        {getHostname(itm)}
                                        <Link className="float-right ml-4 mt-2" />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>
        );
    };

    const renderEmptyState = () => {
        return (
            <div className="flex items-center justify-center py-32">
                <EmptyState
                    placeholderImage={<PersonSearching />}
                    title="Learn more here"
                    subTitle="See definitions and explanations to help you better answer questions."
                />
            </div>
        );
    };

    const renderLoadedState = () => {
        if (libraryTerms.length > 0) {
            return renderTerms();
        }
        return renderEmptyState();
    };

    return (
        <div>
            <div className="mx-auto w-592 py-32">{fetchingTerms ? <Loader /> : renderLoadedState()}</div>
        </div>
    );
};
