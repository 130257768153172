import React, { ReactElement } from 'react';

export const FrequentlyUsed = (): ReactElement => {
    return (
        <svg className="mr-4" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.3337 4L9.00033 10.3333L5.66699 7L0.666992 12"
                stroke="#1AB759"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.333 4H15.333V8"
                stroke="#1AB759"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
