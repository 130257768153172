import { ChevronRight } from 'assets/chevron-right.svg';
import { Avatar } from 'components/duex/Avatar';
import { EmptyState } from 'components/duex/EmptyState';
import { BLUE, Pill } from 'components/pill.component';
import { Col, ColHeader, Row } from 'components/table';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { ProjectInvite, ViewSubmission } from 'modules/assessments/view-submission.modal';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { Project } from 'types';

export const Submissions = ({
    projectInvites,
    project,
}: {
    projectId: string;
    refreshInvites: () => void;
    projectInvites: ProjectInvite[];
    project: Project;
}): ReactElement => {
    const [showSubmission, setShowSubmission] = useState(null);

    const filteredInvites = _filter(projectInvites, (invite) => {
        return invite.submittedAssessment;
    });

    return (
        <div>
            <Row className="border-b border-gray-light-1 bg-white">
                <>
                    <ColHeader className={`ml-8 w-3-of-12`} text="Company" />
                    <ColHeader className={`w-5-of-12`} text="Submitter" />
                    <ColHeader className={`w-2-of-12`} text="Submitted" />
                    <ColHeader className={`w-1-of-12`} text="Status" />
                    <ColHeader className={`w-1-of-12`} text="" />
                </>
            </Row>

            {filteredInvites.length === 0 && (
                <EmptyState
                    className="mt-32"
                    title="No submissions found"
                    subtitle="Submissions will appear here once they are submitted"
                />
            )}

            {filteredInvites.map((invite) => {
                return (
                    <Row
                        key={invite.projectInviteId}
                        className="border-b border-black-10 hover:bg-gray-50 cursor-pointer"
                        spacing="py-16"
                        onClickHandler={() => {
                            setShowSubmission(invite.projectInviteId);
                        }}
                    >
                        <>
                            <Col className={`ml-8 w-3-of-12 flex flex-row gap-8 items-center`}>
                                <img src={invite.claimedByTeam.teamLogo} className="bg-cover h-32" />
                                <span className="">{invite.claimedByTeam.name}</span>
                            </Col>
                            <Col className={`w-5-of-12 flex flex-row gap-8 items-center`}>
                                <Avatar size={32} user={invite.submittedByUser} />
                                {invite.submittedByUser.fullName}
                            </Col>
                            <Col className={`w-2-of-12`}>{moment(invite.updatedAt).fromNow()}</Col>
                            <Col className={`w-1-of-12`}>
                                <Pill colour={BLUE} title="Submitted" />
                            </Col>
                            <Col className="flex w-1-of-12 flex-row-reverse pr-16">
                                <ChevronRight />
                            </Col>
                        </>
                    </Row>
                );
            })}
            {showSubmission && (
                <ViewSubmission
                    project={project}
                    submission={_find(filteredInvites, {
                        projectInviteId: showSubmission,
                    })}
                    closeModal={() => {
                        setShowSubmission(null);
                    }}
                />
            )}
        </div>
    );
};
