import React, { ReactElement } from 'react';

export const CalenderRightArrow = (): ReactElement => {
    return (
        <svg
            width="24"
            height="24"
            className="inline"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-cy="Next_Month"
        >
            <path d="M9 18L15 12L9 6" stroke="#A0A4A8" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
