import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormLabel } from 'components/duex/FormLabel';
import { Modal } from 'components/duex/Modal';
import { InformationNotice } from 'components/duex/Notice';
import { Select } from 'components/duex/Select';
import { showError } from 'components/error-toast.component';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { SettingsRadioSelection } from 'modules/settings/settings-components';
import React, { ReactElement, useState } from 'react';
import { Membership, Question } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const BulkAssignModal = ({
    closeModal,
    projectId,
    filteredQuestionList,
    teamList,
}: {
    closeModal: () => void;
    projectId: string;
    filteredQuestionList: Question[];
    teamList: Membership[];
}): ReactElement => {
    const [saving, setSaving] = useState(false);
    const [assignee, setAssignee] = useState(null);
    const [reassignQuestions, setReassignQuestions] = useState(false);

    let questions = filteredQuestionList;

    if (!reassignQuestions) {
        questions = _filter(filteredQuestionList, {
            assignedToUserId: null,
        });
    }

    const assignQuestions = async () => {
        try {
            const started = new Date();
            setSaving(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.API_BULK_RE_ASSSIGN, {
                projectId,
            });

            const result = await API.post(url, {
                questionIds: _map(questions, 'questionId'),
                assignee,
            });

            logEvent(`QUESTION_BULK_ASSIGN`, started, {
                projectId,
            });

            if (result && result.data) {
                setSaving(false);
            }

            closeModal();
        } catch (err) {
            setSaving(false);
            showError('There was an error bulk assigning those questions', err);
        }
    };
    const saveEnabled = assignee && questions.length > 0;

    return (
        <Modal width="w-600" closeModal={closeModal} title="Bulk Assign">
            <div className="grid gap-16">
                <div>
                    <FormLabel label="New assignee" />
                    <Select
                        width="w-536"
                        placeholder="Select assignee"
                        onChange={setAssignee}
                        value={assignee}
                        items={_map(teamList, ({ member, userId }) => {
                            return {
                                label: member.fullName,
                                value: userId,
                            };
                        })}
                    />
                </div>
                <div className="text-14 text-gray-600">
                    <FormLabel label="Re-assign questions" />
                    <SettingsRadioSelection
                        showLabel={false}
                        currentValue={reassignQuestions}
                        onChange={(newValue) => {
                            setReassignQuestions(newValue);
                        }}
                        options={[
                            {
                                label: `Assign only unassigned questions`,
                                value: false,
                            },
                            {
                                label: 'Assign all questions',
                                value: true,
                            },
                        ]}
                    />
                </div>
                <InformationNotice
                    title={`Assigning ${questions.length} questions`}
                    description={`You are assigning ${questions.length} questions. 
                    You can change what questions will be assigned by changing the filters on the question list.`}
                />
            </div>
            <ButtonBar className="mt-32">
                <Button buttonType="SECONDARY" label="Cancel" onClick={closeModal} disabled={saving} />
                <Button
                    label="Assign Questions"
                    onClick={assignQuestions}
                    disabled={!saveEnabled || saving}
                    loading={saving}
                />
            </ButtonBar>
        </Modal>
    );
};
