import { BLUE, GREEN, LIGHTGRAY, Pill } from 'components/pill.component';
import { showSuccess } from 'components/success-toast.component';
import { convertToUTF8 } from 'components/validation/string';
import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const SectionTitle = ({ title }: { title: string }): ReactElement => {
    return <div className="my-16 select-none pl-16 text-26 font-medium text-black-100">{title}</div>;
};

export const SettingsSection = ({ title }: { title: string }): ReactElement => {
    return <div className="mt-24 select-none pl-16 text-20 font-medium text-blue-500">{title}</div>;
};

export const SettingsItem = ({ children }: { children: ReactNode }): ReactElement => {
    return <div className="my-0 select-none rounded-lg bg-white p-16 hover:bg-gray-50">{children}</div>;
};

export const APIGuide = ({
    apiName,
    apiRoute,
    apiMethod,
    exampleRequest,
    exampleResponse,
}: {
    apiName: string;
    apiRoute: string;
    apiMethod: 'POST' | 'GET';
    exampleRequest: string;
    exampleResponse: string;
}): ReactElement => {
    const copyToClipboard = (fieldDescription: string, value: string) => {
        navigator.clipboard.writeText(value);
        showSuccess(`${fieldDescription} copied to clipboard`);
    };

    const url = `${process.env.REACT_APP_API_SERVER_ADDRESS}${apiRoute}`;

    return (
        <div className="my-4 rounded-lg bg-white p-16 hover:bg-gray-50">
            <h3>{apiName}</h3>
            <p className="text-13 my-8 text-gray-500">
                <Pill title={apiMethod} colour={apiMethod === 'POST' ? BLUE : GREEN} alt={apiMethod} />{' '}
                <span
                    className="cursor-pointer rounded-md px-8 py-4 hover:bg-blue-50"
                    title="Click to copy to clipboard"
                    onClick={() => copyToClipboard('URL', url)}
                >
                    {url}
                </span>
            </p>
            <div className="grid grid-cols-2">
                <div>
                    <p className="my-4 text-14 uppercase text-blue-500">Example Request</p>
                    <pre
                        className="cursor-pointer whitespace-pre-wrap break-words rounded-md px-8 py-4 text-12 text-gray-500 hover:bg-blue-50"
                        onClick={() => copyToClipboard('Request Body', exampleRequest)}
                    >
                        {exampleRequest}
                    </pre>
                </div>
                <div>
                    <p className="my-4 text-14 uppercase text-blue-500">Expected Response</p>
                    <pre className="whitespace-pre-wrap break-words px-8 py-4 text-12 text-gray-500">
                        {exampleResponse}
                    </pre>
                </div>
            </div>
        </div>
    );
};

interface RadioOptions {
    value: boolean;
    label: string;
    isDefault?: boolean;
}

export const SettingsRadioSelection = ({
    description = '',
    currentValue,
    onChange,
    disabled = false,
    options,
    showLabel = true,
}: {
    description?: string;
    currentValue: boolean;
    onChange: (newState: boolean) => void;
    disabled?: boolean;
    options: RadioOptions[];
    showLabel?: boolean;
}): ReactElement => {
    const checkedValue = 'checked';

    const [identifier] = useState(uuidv4());

    return (
        <div className="grid gap-8">
            {showLabel && <label className={`cursor-pointer select-none font-semibold`}>{description}</label>}

            {options.map(({ label, value, isDefault }, index) => {
                const valueDescriptor = value ? '_True' : '_False';
                const fieldIdentifier = `id_${identifier}${valueDescriptor}`;

                return (
                    <div key={index} className="grid gap-8 py-4" style={{ gridTemplateColumns: '16px auto' }}>
                        <input
                            id={fieldIdentifier}
                            data-cy={`Input_Radio_${valueDescriptor}`}
                            type="radio"
                            checked={currentValue === value}
                            value={checkedValue}
                            disabled={disabled}
                            onChange={() => {
                                onChange(value);
                            }}
                            className="cursor-pointer"
                        />
                        <label className="cursor-pointer select-none" htmlFor={fieldIdentifier}>
                            {label} {isDefault ? <Pill title="Default" colour={LIGHTGRAY} /> : ''}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};

export const SettingsCheckbox = ({
    description,
    currentValue,
    onChange,
    disabled = false,
}: {
    description: string;
    currentValue: boolean;
    onChange?: (newState: boolean) => void;
    disabled?: boolean;
}): ReactElement => {
    const checkedValue = 'checked';

    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;

    return (
        <div className="grid gap-8" style={{ gridTemplateColumns: '16px auto' }}>
            <input
                data-cy="Input_Checkbox"
                id={fieldIdentifier}
                type="checkbox"
                checked={currentValue}
                value={checkedValue}
                disabled={disabled}
                onChange={() => {
                    if (onChange) {
                        onChange(!currentValue);
                    }
                }}
                className="cursor-pointer"
            />
            <label htmlFor={fieldIdentifier} className="cursor-pointer select-none">
                {description}
            </label>
        </div>
    );
};

export const SettingsNumberField = ({
    description,
    currentValue,
    onChange,
    placeholder,
    minValue = 0,
    maxValue = 255,
    disabled = false,
}: {
    description: string;
    currentValue: number;
    onChange?: (newState: number) => void;
    placeholder: string;
    minValue?: number;
    maxValue?: number;
    disabled?: boolean;
}): ReactElement => {
    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;

    return (
        <div className="flex flex-col gap-4">
            <label htmlFor={fieldIdentifier} className="cursor-pointer select-none">
                {description}
            </label>
            <input
                id={fieldIdentifier}
                type="number"
                min={minValue}
                max={maxValue}
                placeholder={placeholder}
                value={currentValue}
                disabled={disabled}
                onChange={(ev) => {
                    if (onChange) {
                        const newValue = parseInt(ev.target.value);

                        if (newValue >= minValue && newValue <= maxValue) {
                            onChange(newValue);
                        }
                    }
                }}
                maxLength={`${maxValue}`.length}
                className="rounded-sm border border-gray-200 px-8 py-8"
            />
        </div>
    );
};

export const SettingsTextArea = ({
    description,
    currentValue,
    onChange,
    placeholder,
    disabled = false,
    dataCy = '',
}: {
    description: string;
    currentValue: string;
    onChange: (newState: string) => void;
    placeholder: string;
    disabled?: boolean;
    dataCy?: string;
}): ReactElement => {
    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;

    return (
        <div className="flex flex-col gap-4">
            <label htmlFor={fieldIdentifier} className="cursor-pointer select-none">
                {description}
            </label>
            <textarea
                data-cy={dataCy}
                id={fieldIdentifier}
                placeholder={placeholder}
                value={currentValue}
                onChange={(ev) => {
                    onChange(ev.target.value);
                }}
                disabled={disabled}
                maxLength={255}
                className={`rounded-sm border border-gray-200 px-8 py-8 ${disabled ? 'bg-gray-50' : ''}`}
            >
                {currentValue}
            </textarea>
        </div>
    );
};

export const SettingsTextField = ({
    description,
    currentValue = '',
    onChange,
    placeholder = '',
    disabled = false,
}: {
    description: string;
    currentValue: string;
    onChange?: (newState: string) => void;
    placeholder: string;
    disabled?: boolean;
}): ReactElement => {
    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;

    return (
        <div className="flex flex-col gap-4">
            <label htmlFor={fieldIdentifier} className="cursor-pointer select-none">
                {description}
            </label>
            <input
                id={fieldIdentifier}
                type="text"
                placeholder={placeholder}
                value={currentValue}
                onChange={(ev) => {
                    if (onChange) {
                        onChange(ev.target.value);
                    }
                }}
                disabled={disabled}
                maxLength={255}
                className={`rounded-sm border border-gray-200 px-8 py-8 ${disabled ? 'bg-gray-50' : ''}`}
            />
        </div>
    );
};

export const SettingsImagePicker = ({
    description,
    currentValue,
    updatedSelection,
    onChange,
}: {
    description: string;
    currentValue: string;
    updatedSelection: Photo;
    onChange: (photo: Photo) => void;
}): ReactElement => {
    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;
    const fileUploadRef = useRef<HTMLInputElement>();
    const [logoUrl, setLogoUrl] = useState(currentValue);
    const [fileSelected, setFileSelected] = useState(false);

    const resetState = () => {
        fileUploadRef.current.value = null;
        setFileSelected(false);
        setLogoUrl(currentValue);
    };

    const fileSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            const maxFileSize = 1;

            if (fileSize > maxFileSize) {
                alert('That file is too large, please choose a smaller image. We allow up to 1MB.');
            } else {
                onChange({
                    fileName: convertToUTF8(fileUploadRef.current.files[0].name),
                    contentType: fileUploadRef.current.files[0].type,
                    file: fileUploadRef.current.files[0],
                });
                setLogoUrl(URL.createObjectURL(fileUploadRef.current.files[0]));
            }
            setFileSelected(true);
        } else {
            resetState();
        }
    };

    useEffect(() => {
        // This function is to detect when the parent has reset it's state
        // and this component should also reset it's own state

        if (!updatedSelection.fileName) {
            resetState();
        }
    }, [updatedSelection, currentValue, fileSelected]);

    return (
        <div className="flex flex-col gap-4">
            <label htmlFor={fieldIdentifier} className="cursor-pointer select-none">
                {description}
            </label>
            <div className={`${logoUrl ? 'grid gap-8' : ''}`} style={{ gridTemplateColumns: '104px auto' }}>
                {logoUrl && <img src={logoUrl} />}

                <input
                    id={fieldIdentifier}
                    type="file"
                    className="btn-secondary w-full"
                    accept="*"
                    onChange={fileSelectHandler}
                    ref={fileUploadRef}
                />
            </div>
        </div>
    );
};

export interface Photo {
    fileName?: string;
    contentType?: string;
    // eslint-disable-next-line
    file?: any;
}
