import React, { ReactElement } from 'react';

export const Logo = (): ReactElement => {
    return (
        <svg width="100" height="28" viewBox="0 0 100 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.85271 5.82894C11.2869 6.04416 13.6915 7.33586 15.0454 9.39766C16.4122 11.4792 16.6371 14.292 15.8484 16.6316C15.5313 17.591 15.0158 18.4728 14.3353 19.2198C13.6549 19.9668 12.8248 20.5621 11.8991 20.9671C10.9376 21.3725 9.9038 21.5788 8.86029 21.5735C6.66352 21.5735 4.44549 20.8544 3.44837 19.7171L3.47913 27.1257C3.47934 27.174 3.47 27.2218 3.45166 27.2665C3.43332 27.3111 3.40634 27.3517 3.37227 27.386C3.3382 27.4202 3.2977 27.4473 3.25311 27.4658C3.20852 27.4844 3.16071 27.4939 3.11242 27.4939H0.262153C0.195564 27.4939 0.131699 27.4675 0.0845893 27.4204C0.0374798 27.3734 0.01098 27.3095 0.0109125 27.2429C0.0100212 26.3578 0.00916704 25.4727 0.00835 24.5876C0.00656736 22.6846 0.00491206 20.7816 0.00338408 18.8786C0.00196752 17.0821 0.0101804 15.2854 0.000105286 13.4889C-0.0145697 10.8744 1.50613 8.30173 3.73623 6.9709C5.28026 6.06436 7.06975 5.66496 8.85271 5.82894ZM7.3146 18.2009C10.4592 18.9798 13.2586 16.0881 12.6869 12.8639C12.3082 10.7285 10.6602 9.20303 8.42447 9.20303C5.88433 9.20303 4.06279 11.2321 4.06279 13.8191C4.06279 15.9945 5.37975 17.7218 7.31456 18.2009H7.3146Z"
                fill="white"
            />
            <path
                d="M31.9184 19.8768C31.9339 19.9628 31.9218 20.0514 31.8839 20.1301C31.846 20.2087 31.7842 20.2734 31.7073 20.3148C30.0397 21.1544 28.1964 21.5858 26.3294 21.5736C21.0322 21.5736 17.6358 18.442 17.6358 13.7836C17.6358 9.2031 20.8764 5.99365 25.3168 5.99365C29.9128 5.99365 33.4027 9.45239 32.4212 14.9833H21.6087C22.0138 17.071 23.8834 18.0681 26.345 18.0681C27.9187 18.0676 29.4765 17.754 30.9277 17.1453C30.9855 17.1209 31.048 17.1099 31.1106 17.1131C31.1732 17.1162 31.2343 17.1335 31.2893 17.1636C31.3443 17.1937 31.3918 17.2358 31.4282 17.2868C31.4647 17.3378 31.4891 17.3964 31.4997 17.4581L31.9184 19.8768ZM28.8378 12.3035C28.7287 10.6365 27.4667 9.35897 25.4102 9.35897C23.2758 9.35897 21.8424 10.6054 21.671 12.3035H28.8378Z"
                fill="white"
            />
            <path
                d="M50.2086 13.3784L50.2019 21.2619H46.532C46.4672 21.2619 46.4049 21.2369 46.3582 21.1921C46.3114 21.1473 46.2837 21.0861 46.281 21.0214L46.2134 20.2898C44.7628 21.1786 43.6583 21.5735 41.3369 21.5735C37.2238 21.5735 34.0102 18.3394 34.0102 13.8057C34.0102 10.3406 36.3065 7.04682 39.6532 6.03624C43.1837 4.97016 47.2661 6.45903 49.1734 9.64051C49.8504 10.7698 50.2082 12.0617 50.2086 13.3784ZM42.2889 9.19925C39.6557 9.10467 37.8744 11.1543 37.9232 13.8573C37.9725 16.5905 40.2554 18.7659 42.8456 18.3261C45.1285 17.9384 46.6231 15.7433 46.3368 13.1087C46.1032 10.9585 44.3651 9.27383 42.2889 9.19925Z"
                fill="white"
            />
            <path
                d="M56.9568 7.93356C57.7202 6.3258 59.1692 5.93677 60.8518 5.93677C61.4482 5.94235 62.0403 6.03952 62.6072 6.22486C62.6627 6.24337 62.7103 6.28024 62.742 6.32939C62.7738 6.37854 62.7879 6.43704 62.7819 6.49526L62.5151 9.64813C62.5133 9.68561 62.5029 9.72219 62.4849 9.75511C62.4669 9.78804 62.4417 9.81644 62.4111 9.83821C62.3805 9.85997 62.3454 9.87451 62.3084 9.88075C62.2714 9.88699 62.2334 9.88476 62.1974 9.87422C61.6923 9.70941 61.1649 9.62321 60.6337 9.61864C58.8731 9.61864 57.0191 10.1685 57.0191 13.3518V21.0028C57.0202 21.0705 56.9943 21.1358 56.9472 21.1844C56.9001 21.2331 56.8356 21.261 56.7679 21.2621H53.2663C53.1986 21.261 53.1341 21.2331 53.087 21.1844C53.0398 21.1358 53.014 21.0705 53.015 21.0028V6.94857C53.014 6.88088 53.0398 6.81555 53.087 6.76693C53.1341 6.71831 53.1986 6.69039 53.2663 6.68931H56.6371C56.7031 6.69031 56.7661 6.71685 56.8129 6.76336C56.8597 6.80987 56.8867 6.87271 56.8881 6.93869L56.9568 7.93356Z"
                fill="white"
            />
            <path
                d="M91.2793 7.93356C92.0427 6.3258 93.4917 5.93677 95.1743 5.93677C95.7708 5.94235 96.3628 6.03952 96.9297 6.22486C96.9852 6.24337 97.0328 6.28023 97.0646 6.32938C97.0963 6.37853 97.1104 6.43704 97.1045 6.49526L96.8376 9.64813C96.8358 9.68562 96.8255 9.7222 96.8075 9.75512C96.7894 9.78804 96.7642 9.81645 96.7336 9.83821C96.703 9.85997 96.6679 9.87452 96.6309 9.88076C96.5939 9.88699 96.556 9.88476 96.5199 9.87422C96.0149 9.70941 95.4875 9.6232 94.9562 9.61864C93.1957 9.61864 91.3417 10.1685 91.3417 13.3518V21.0028C91.3427 21.0705 91.3168 21.1358 91.2697 21.1844C91.2226 21.2331 91.1581 21.261 91.0904 21.2621H87.5888C87.5211 21.261 87.4566 21.2331 87.4095 21.1844C87.3624 21.1358 87.3365 21.0705 87.3376 21.0028V6.94857C87.3365 6.88088 87.3624 6.81555 87.4095 6.76693C87.4566 6.71831 87.5211 6.69039 87.5888 6.68931H90.9596C91.0256 6.69033 91.0886 6.71687 91.1354 6.76337C91.1822 6.80988 91.2092 6.87271 91.2106 6.93868L91.2793 7.93356Z"
                fill="white"
            />
            <path
                d="M68.4184 21.2619H64.57C64.5342 21.2644 64.4982 21.2593 64.4645 21.2472C64.4307 21.235 64.3998 21.2159 64.3738 21.1912C64.3478 21.1664 64.3272 21.1366 64.3134 21.1034C64.2995 21.0703 64.2927 21.0347 64.2934 20.9988L64.3014 0.224509C64.3116 0.15959 64.3456 0.100772 64.3966 0.0594199C64.4477 0.0180676 64.5123 -0.00288428 64.5779 0.000612267H68.4263C68.4621 -0.00185559 68.498 0.00315449 68.5318 0.0153241C68.5656 0.0274937 68.5965 0.0465567 68.6225 0.0713017C68.6485 0.0960466 68.6691 0.125932 68.6829 0.159059C68.6968 0.192187 68.7036 0.227831 68.7029 0.263727L68.6949 21.038C68.6847 21.1029 68.6508 21.1617 68.5997 21.2031C68.5486 21.2444 68.484 21.2654 68.4184 21.2619Z"
                fill="white"
            />
            <path
                d="M84.4068 19.8768C84.4223 19.9628 84.4102 20.0514 84.3723 20.1301C84.3344 20.2087 84.2726 20.2734 84.1957 20.3148C82.5281 21.1544 80.6848 21.5858 78.8178 21.5736C73.5206 21.5736 70.1242 18.442 70.1242 13.7836C70.1242 9.2031 73.3648 5.99365 77.8051 5.99365C82.4012 5.99365 85.8911 9.45239 84.9096 14.9833H74.0971C74.5021 17.071 76.3717 18.0681 78.8334 18.0681C80.407 18.0676 81.9649 17.754 83.4161 17.1453C83.4738 17.1209 83.5364 17.1099 83.599 17.1131C83.6616 17.1162 83.7227 17.1335 83.7777 17.1636C83.8327 17.1937 83.8801 17.2358 83.9166 17.2868C83.953 17.3378 83.9775 17.3964 83.9881 17.4581L84.4068 19.8768ZM81.3262 12.3035C81.2171 10.6365 79.9551 9.35897 77.8986 9.35897C75.7642 9.35897 74.3308 10.6054 74.1594 12.3035H81.3262Z"
                fill="white"
            />
            <path
                d="M97.7251 16.9931C98.1774 16.9961 98.6187 17.133 98.9933 17.3865C99.3679 17.6401 99.6591 17.9988 99.8301 18.4176C100.001 18.8364 100.044 19.2964 99.9541 19.7397C99.8639 20.183 99.6446 20.5896 99.3237 20.9084C99.0027 21.2272 98.5946 21.4438 98.1507 21.5309C97.7068 21.618 97.2471 21.5717 96.8295 21.3979C96.4119 21.2241 96.0551 20.9305 95.8041 20.5542C95.5532 20.1779 95.4192 19.7356 95.4192 19.2833C95.418 18.9809 95.477 18.6813 95.5926 18.4018C95.7083 18.1224 95.8783 17.8687 96.0929 17.6556C96.3074 17.4425 96.5622 17.2742 96.8424 17.1604C97.1226 17.0467 97.4227 16.9898 97.7251 16.9931Z"
                fill="#19DDA5"
            />
        </svg>
    );
};
