import { RejectCross } from 'assets/reject.cross.svg';
import { Avatar } from 'components/duex/Avatar';
import { ConfirmationDialog } from 'components/duex/ConfirmationDialog';
import { EmptyState } from 'components/duex/EmptyState';
import { IconButton } from 'components/duex/IconButton';
import { showError } from 'components/error-toast.component';
import { BLUE, Pill, RED } from 'components/pill.component';
import { Col, ColHeader, Row } from 'components/table';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const Invitations = ({
    projectId,
    refreshInvites,
    projectInvites,
}: {
    projectId: string;
    refreshInvites: () => void;
    projectInvites;
}): ReactElement => {
    const [showCancelModal, setShowingCancelModal] = useState(null);

    const cancelInvitation = async (projectInviteId: string) => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_CANCEL_ASSESSMENT, {
                projectId,
                projectInviteId,
            });
            const result = await API.post(url, {});

            logEvent(`PROJECT_INVITE_CANCELLED`, started, {
                projectInviteId: result.data.projectInviteId,
            });

            if (result) {
                refreshInvites();
            }
        } catch (err) {
            showError('There was an error cancelling your Sent Invitation', err);
        }
    };

    return (
        <div>
            <Row className="border-b border-gray-light-1 bg-white">
                <>
                    <ColHeader className={`ml-8 w-1-of-12`} text="Sender" />
                    <ColHeader className={`w-6-of-12`} text="Invite Email" />
                    <ColHeader className={`w-2-of-12`} text="Sent" />
                    <ColHeader className={`w-2-of-12`} text="Status" />
                    <ColHeader className={`w-1-of-12`} text="" />
                </>
            </Row>

            {projectInvites.length === 0 && (
                <EmptyState
                    className="mt-32"
                    title="No invitations found"
                    subtitle="You can send Invitations by clicking on the Send Invitation button at the top right of this page"
                />
            )}

            {showCancelModal && (
                <ConfirmationDialog
                    title="Cancel Invitation"
                    descriptionText={[
                        'Cancelling this Invitation will prevent them from submitting their form.',
                        'Emails notifying the user have already been delivered.',
                    ]}
                    primaryLabel="Cancel Invitation"
                    primaryAction={() => {
                        cancelInvitation(showCancelModal);
                        setShowingCancelModal(false);
                    }}
                    cancelAction={() => {
                        setShowingCancelModal(false);
                    }}
                />
            )}

            {projectInvites.map((invite) => {
                const title = invite.inviteStatus;
                const alt = invite.inviteStatus;
                const colour = invite.inviteStatus === 'UNCLAIMED' ? RED : BLUE;
                const showCancelButton = invite.inviteStatus === 'UNCLAIMED';

                return (
                    <Row
                        key={invite.projectInviteId}
                        className="border-b border-black-10 hover:bg-gray-50"
                        spacing="py-16"
                    >
                        <>
                            <Col className={`ml-8 w-1-of-12`}>
                                <Avatar size={32} userId={invite.createdByUserId} />
                            </Col>
                            <Col className={`w-6-of-12`}>{invite.emailAddress}</Col>
                            <Col className={`w-2-of-12`}>{moment(invite.createdAt).fromNow()}</Col>
                            <Col className={`w-2-of-12`}>
                                <Pill title={title} alt={alt} colour={colour} />
                            </Col>
                            <Col className={`flex w-1-of-12 flex-row-reverse gap-0 pr-8`}>
                                {showCancelButton && (
                                    <>
                                        <div
                                            onClick={() => {
                                                setShowingCancelModal(invite.projectInviteId);
                                            }}
                                        >
                                            <IconButton title="Cancel Invitation" Icon={RejectCross} />
                                        </div>
                                    </>
                                )}
                            </Col>
                        </>
                    </Row>
                );
            })}
        </div>
    );
};
