import React, { ReactElement } from 'react';

export const H1Heading = ({
    label,
    className,
    leading = 'leading-30',
}: {
    label: string;
    className?: string;
    leading?: string;
}): ReactElement => {
    return <h1 className={`m-0 select-none p-0 text-26 ${leading} ${className}`}>{label}</h1>;
};

export const H2Heading = ({ label, className }: { label: string; className?: string }): ReactElement => {
    return <h2 className={`m-0 select-none p-0 text-20 leading-24 ${className}`}>{label}</h2>;
};

// export const H3Heading = ({ label, className }: { label: string; className?: string }): ReactElement => {
//     return <h3 className={`m-0 select-none p-0 text-16 leading-20 ${className}`}>{label}</h3>;
// };
