import { Administration } from 'assets/adminstration.svg';
import { AssessmentCentral } from 'assets/assessment-central.svg';
import { Logo } from 'assets/baseline-navigation-logo.svg';
import { Compass } from 'assets/compass.svg';
import { Database } from 'assets/database.svg';
import { Documents } from 'assets/documents.svg';
import { Help } from 'assets/help.svg';
import { Home } from 'assets/home.svg';
import { Notifications } from 'assets/notifications.svg';
import { People } from 'assets/people.svg';
import { ProjectList } from 'assets/project-list.svg';
import { Settings } from 'assets/settings.svg';
import { Avatar } from 'components/duex/Avatar';
import { testAutomation } from 'components/duex/TestAutomationAttribute';
import Tooltip, { getTooltipId } from 'components/duex/Tooltip';
import { useSocket } from 'hooks/useSocket';
import { useTeamContext } from 'hooks/useTeamContext';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import { Feedback } from 'modules/feedback/feedback.view';
import React, { ReactElement, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'utility/ApplicationRoutes';
import { PERMISSION } from 'utility/Permission';
import { GlobalSocket } from './global-socket.view';

const baseClasses = `
    cursor-pointer
    mb-4
    px-2
    py-8
    rounded-sm
    select-none
    w-full
    block
`;

const inactiveClasses = `
    opacity-80
    hover:opacity-100
`;

const activeClasses = `
    bg-gradient-to-r from-nav-button-start to-nav-button-end 
`;

interface NavigationItem {
    label: string;
    hoverLabel: string;
    uri?: string;
    url?: string;
    icon: ReactNode;
    available: boolean;
    omitStyle?: boolean;
}

export const ApplicationNavigationBar = (): ReactElement => {
    const { hasPermission, user, userHasTeam, homeUrl } = useUserContext();
    const { hasFeature } = useTeamContext();
    const { isConnected } = useSocket();

    const showTeamList = hasPermission(PERMISSION.CREATE_TEAM_MEMBER);
    const showAnswerLibrary = hasPermission(PERMISSION.UPDATE_ANSWER);
    const showInsights = hasPermission(PERMISSION.BULK_EXPORT_ACROSS_PROJECTS);
    const showAdmin = hasPermission(PERMISSION.READ_ALL_TEAMS);
    const showAssessments = hasFeature('enableBuyerProjects');

    const tooltipId = getTooltipId('navigation items');

    const inactiveClass = `${baseClasses} ${inactiveClasses}`;

    const applyClasses = ({ isActive }: { isActive: boolean }): string => {
        if (isActive) {
            return `${baseClasses} ${activeClasses}`;
        }

        return inactiveClass;
    };

    const renderNavItem = (item: NavigationItem, index: number) => {
        const classes = item.omitStyle ? inactiveClass : applyClasses;

        const content = (
            <div
                className="grid ml-8 gap-10 text-white"
                style={{
                    gridTemplateColumns: '20px auto',
                }}
            >
                <div className="justify-center flex">{item.icon}</div>

                <div className="text-13 font-medium leading-20 tracking-tight">{item.label}</div>
            </div>
        );

        if (item.uri) {
            return (
                <NavLink
                    data-cy={testAutomation(`Navigate To ${item.label}`)}
                    to={item.uri}
                    title={item.label}
                    className={classes}
                    data-tooltip-content={item.hoverLabel}
                    data-tooltip-id={tooltipId}
                    key={index}
                >
                    {content}
                </NavLink>
            );
        } else {
            return (
                <a href={item.url} className={inactiveClass} target="_blank" key={index}>
                    {content}
                </a>
            );
        }
    };

    const filterItems = (items: NavigationItem[]) => {
        return _filter(items, {
            available: true,
        });
    };

    const modules: NavigationItem[] = [
        {
            label: 'Dashboard',
            hoverLabel: 'View your Dashboard',
            uri: ROUTES.HOME,
            icon: <Home />,
            available: userHasTeam,
        },
        {
            label: 'Notifications',
            hoverLabel: 'View Notifications',
            uri: ROUTES.NOTIFICATIONS,
            icon: <Notifications />,
            available: userHasTeam,
        },
        {
            label: 'Projects',
            hoverLabel: 'View Projects',
            uri: ROUTES.PROJECT_LIST,
            icon: <ProjectList />,
            available: userHasTeam,
        },
        {
            label: 'Answer Library',
            hoverLabel: 'View Answer Library',
            uri: ROUTES.ANSWER_LIBRARY,
            icon: <Database />,
            available: userHasTeam && showAnswerLibrary,
        },
        {
            label: 'Insights',
            hoverLabel: 'Discover Insights',
            uri: ROUTES.INSIGHTS,
            icon: <Compass />,
            available: userHasTeam && showInsights,
        },
        {
            label: 'Documents',
            hoverLabel: 'View Documents',
            uri: ROUTES.FILES,
            icon: <Documents />,
            available: userHasTeam,
        },
        {
            label: 'Assessments',
            hoverLabel: 'View Assessments',
            uri: ROUTES.ASSSESMENTS_HOME,
            icon: <AssessmentCentral />,
            available: userHasTeam && showAssessments,
        },
    ];

    const settings: NavigationItem[] = [
        {
            label: 'Team',
            hoverLabel: 'View & Manage your Team',
            uri: ROUTES.TEAM,
            icon: <People />,
            available: userHasTeam && showTeamList,
        },
        {
            label: 'Settings',
            hoverLabel: 'View Settings',
            uri: ROUTES.SETTINGS,
            icon: <Settings />,
            available: userHasTeam,
        },
        {
            label: 'Administration',
            hoverLabel: 'Manage Customers',
            uri: ROUTES.ACCOUNT_ADMIN,
            icon: <Administration />,
            available: userHasTeam && showAdmin,
        },
        {
            label: 'Help',
            hoverLabel: 'Get Help',
            url: 'https://www.pearler.ai/help/',
            icon: <Help />,
            available: true,
            omitStyle: true,
        },
    ];

    return (
        <nav className="flex justify-between flex-col overflow-hidden bg-gradient-to-b to-nav-end from-nav-start">
            <div className="px-8 mt-30 self-top w-full">
                <NavLink to={homeUrl} title="Dashboard" className={`ml-11 block`}>
                    <Logo />
                </NavLink>
                <div className="mt-24">{filterItems(modules).map(renderNavItem)}</div>
            </div>
            <div className="px-8 self-bottom border-t border-pearler-indigo w-full py-16">
                {filterItems(settings).map(renderNavItem)}
                <div className="mt-24 w-full">
                    <NavLink
                        data-cy="Navigate_To_Profile"
                        to={ROUTES.PROFILE}
                        className={({ isActive }) => {
                            if (isActive) {
                                return `${baseClasses} ${activeClasses} text-white`;
                            }

                            return `${inactiveClass} text-pearler-teal`;
                        }}
                        title="Profile"
                        data-tooltip-content="Manage your Profile"
                        data-tooltip-id={tooltipId}
                    >
                        <div
                            className="grid gap-8 mx-4"
                            style={{
                                gridTemplateColumns: '34px auto',
                            }}
                        >
                            <Avatar
                                user={user}
                                size={32}
                                extraClasses={`${
                                    isConnected ? 'border-green' : 'border-orange-400'
                                } border-2 w-34 h-34`}
                            />
                            <div className="text-14 font-medium truncate w-full leading-32 tracking-tight">
                                {user.fullName}
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>

            <GlobalSocket />
            <Feedback />
            <Tooltip id={tooltipId} />
        </nav>
    );
};
