import React, { ReactElement } from 'react';

export const Key = ({ strokeColor = 'currentColor' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.75 4.25C16.4069 4.25 17.75 5.59315 17.75 7.25M20.75 7.25C20.75 10.5637 18.0637 13.25 14.75 13.25C14.3993 13.25 14.0555 13.2199 13.7213 13.1622C13.1583 13.0649 12.562 13.188 12.158 13.592L9.5 16.25H7.25V18.5H5V20.75H1.25V17.932C1.25 17.3352 1.48705 16.7629 1.90901 16.341L8.408 9.842C8.81202 9.43798 8.93512 8.84172 8.83785 8.2787C8.7801 7.94446 8.75 7.60074 8.75 7.25C8.75 3.93629 11.4363 1.25 14.75 1.25C18.0637 1.25 20.75 3.93629 20.75 7.25Z"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
