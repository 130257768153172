import { LeftArrow } from 'assets/left-arrow.svg';
import { RightArrow } from 'assets/right-arrow.svg';
import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { CircleButton } from 'components/duex/CircleButton';
import { EmptyState } from 'components/duex/EmptyState';
import { Modal } from 'components/duex/Modal';
import { Tabs } from 'components/duex/Tabs';
import { GRAY, GREEN, Pill, YELLOW } from 'components/pill.component';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Answer, Question } from 'types';
import { CONSTANTS } from 'utility/Constants';
import { getData, setData } from 'utility/DataHelper';
import { PERMISSION } from 'utility/Permission';
import { Organisation } from './analytics/organisation';
import { Overview } from './analytics/overview';
import { Label, Value } from './analytics/typography';
import { Usage } from './analytics/usage';
import { UpdateAnswer } from './replace-answer.modal';
interface Count {
    date: string;
    count: number;
}

export interface AnalyticsProps {
    answer: Answer;
    usage: Question[];
    usageCounts: Count[];
    updateFavourite: (newState: boolean) => void;
    flagAnswer: () => void;
    reinstateAnswer: () => void;
    archiveAnswer: () => void;
    deleteAnswer: () => void;
}

const initialTabs = [
    {
        label: 'Overview',
        isActive: true,
        component: Overview,
    },
    {
        label: 'Usage',
        isActive: false,
        component: Usage,
    },
    {
        label: 'Settings',
        isActive: false,
        restricted: true,
        component: Organisation,
    },
    // {
    //     label: 'Attachments',
    //     isActive: false,
    //     component: Attachments,
    // },
    // {
    //     label: 'History',
    //     isActive: false,
    //     component: History,
    // },
];

export const AnswerAnalyticsModal = ({
    answerId,
    closeModal,
    nextAnswer,
    previousAnswer,
    hasNextAnswer,
    hasPreviousAnswer,
}: {
    answerId: string;
    closeModal: () => void;
    hasNextAnswer?: boolean;
    hasPreviousAnswer?: boolean;
    nextAnswer?: () => void;
    previousAnswer?: () => void;
}): ReactElement => {
    const [answerAnalytics, setUserAnalytics] = useState<AnalyticsProps>(null);
    const [isLoading, setLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [tabs, setTabs] = useState(initialTabs);
    const { hasPermission } = useUserContext();

    const modifyAnswer = async (answerStatus: string) => {
        const result = await setData({
            setLoading,
            url: CONSTANTS.UPDATE_ANSWER,
            urlParams: {
                answerId,
            },
            body: {
                answerStatus,
            },
            logEventName: 'ANSWER_LIBRARY_CHANGE_ANSWER_STATUS',
            errorMessage: 'There was an error updating that answer',
        });

        if (result) {
            fetchData();
        }
    };

    const deleteAnswer = async () => {
        await setData({
            setLoading,
            url: CONSTANTS.DELETE_ANSWER,
            urlParams: {
                answerId,
            },
            body: {},
            logEventName: 'ANSWER_LIBRARY_DELETE_ANSWER',
            errorMessage: 'There was an error deleting that answer',
        });
        closeModal();
    };

    const replaceAnswer = async (answerHtml: string) => {
        const result = await setData({
            setLoading,
            url: CONSTANTS.REPLACE_ANSWER,
            urlParams: {
                answerId,
            },
            body: {
                richTextAnswer: answerHtml,
            },
            logEventName: 'ANSWER_LIBRARY_REPLACE_ANSWER',
            errorMessage: 'There was an error replacing that answer',
        });

        if (result) {
            fetchData();
        }
    };

    const fetchData = async () => {
        const data = await getData<AnalyticsProps>({
            setLoading,
            url: CONSTANTS.FETCH_ANSWER_ANALYTICS,
            urlParams: {
                answerId,
            },
            logEventName: 'ANSWER_LIBRARY_VIEW_ANSWER_ANALYTICS',
            errorMessage: 'There was an error retrieving analytics for that answer',
        });

        if (data) {
            setUserAnalytics(data);

            setTabs(
                _map(tabs, (tab) => {
                    return {
                        ...tab,
                        disabled: tab.label === 'Usage' && data.usage.length == 0,
                    };
                }),
            );
        }
    };

    const updateFavourite = async (newState: boolean) => {
        const result = await setData({
            setLoading,
            url: CONSTANTS.UPDATE_ANSWER,
            urlParams: {
                answerId,
            },
            body: {
                isFavourite: newState,
            },
            logEventName: 'ANSWER_LIBRARY_SET_FAVOURITE',
            errorMessage: 'There was an error setting that answer to be a favourite',
        });

        if (result) {
            fetchData();
        }
    };

    useEffect(() => {
        if (answerId) {
            fetchData();
        }
    }, [answerId]);

    const deleteClicked = async () => {
        const result = window.confirm('Are you sure you want to delete this answer?');

        if (result) {
            await deleteAnswer();
            closeModal();
        }
    };

    const getCreationText = (creationMethod: string): string => {
        if (creationMethod === 'FROM_QUESTION') {
            return 'In Project';
        }
        if (creationMethod === 'BULK_UPLOAD') {
            return 'Library Import';
        }
        if (creationMethod === 'MANUALLY') {
            return 'Manually';
        }
    };

    const getAvailability = (answerStatus: string) => {
        if (answerStatus === CONSTANTS.FLAGGED) {
            return ['Review', YELLOW];
        }
        if (answerStatus === CONSTANTS.ARCHIVE_ANSWER) {
            return ['Archived', GRAY];
        }
        if (answerStatus === CONSTANTS.IN_USE) {
            return ['Available', GREEN];
        }

        return [];
    };
    const { getUser } = useUserContext();
    const author = getUser(answerAnalytics?.answer?.createdByUserId);

    const answer = answerAnalytics?.answer;
    const usage = answerAnalytics?.usage;
    const usageTimes = usage?.length || 0;

    const selectedTab = _find(tabs, {
        isActive: true,
    });
    const Component = selectedTab.component;

    const [availabilityText, pillColour] = getAvailability(answer?.answerStatus);

    const componentProps = {
        updateFavourite,
        deleteAnswer: deleteClicked,
        flagAnswer: () => {
            modifyAnswer(CONSTANTS.FLAGGED);
        },
        reinstateAnswer: () => {
            modifyAnswer(CONSTANTS.IN_USE);
        },
        archiveAnswer: () => {
            modifyAnswer(CONSTANTS.ARCHIVE_ANSWER);
        },
    };

    const canEdit = hasPermission(PERMISSION.UPDATE_ANSWER);

    const filteredTabs = _filter(tabs, (tab) => {
        if (tab.restricted) {
            return canEdit;
        }

        return true;
    });

    const previousButton = (
        <CircleButton className="mt-20" onClick={previousAnswer} title="Previous Answer" disabled={!hasPreviousAnswer}>
            <LeftArrow strokeColor="currentColor" />
        </CircleButton>
    );
    const nextButton = (
        <CircleButton className="mt-20" onClick={nextAnswer} title="Next Answer" disabled={!hasNextAnswer}>
            <RightArrow strokeColor="currentColor" />
        </CircleButton>
    );

    const usedTimes = usageTimes > 0 ? `${usageTimes} time${usageTimes > 1 ? 's' : ''}` : 'Never';

    if (!isLoading && !answerAnalytics) {
        return <EmptyState title="Answer not found" subtitle="It may have been recently deleted" />;
    }
    if (!isEditing) {
        return (
            <Modal
                closeModal={closeModal}
                title="Answer Insights"
                width="w-1200"
                leftControl={previousButton}
                rightControl={nextButton}
            >
                <div className="rounded-md bg-gray-50 p-16">
                    <div className="grid" style={{ gridTemplateColumns: 'auto auto' }}>
                        <div className="grid grid-cols-7 gap-8">
                            <div className="col-span-2">
                                <Label title="Author" />
                                <div
                                    className="grid items-center gap-8"
                                    style={{
                                        gridTemplateColumns: '32px auto',
                                    }}
                                >
                                    <Avatar userId={answer?.createdByUserId} size={32} />
                                    <Value title={author.fullName || 'Unknown'} preventWrapping={true} />
                                </div>
                            </div>
                            <div>
                                <Label title="Created" />
                                <Value title={moment(answer?.createdAt).format('Do MMM YYYY')} />
                            </div>
                            <div>
                                <Label title="Updated" />
                                <Value title={moment(answer?.updatedAt).format('Do MMM YYYY')} />
                            </div>
                            <div>
                                <Label title="Used" />
                                <Value title={usedTimes} />
                            </div>
                            <div>
                                <Label title="Creation" />
                                <Value title={getCreationText(answer?.creationMethod)} />
                            </div>
                            <div className="text-center">
                                <Label title="Status" />
                                <Pill title={availabilityText} colour={pillColour} />
                            </div>
                        </div>
                        <ButtonBar className="items-center">
                            {canEdit ? (
                                <Button
                                    label="Edit"
                                    onClick={() => {
                                        setIsEditing(true);
                                    }}
                                />
                            ) : (
                                <Button
                                    label="Flag for Review"
                                    buttonType="TERTIARY"
                                    onClick={componentProps.flagAnswer}
                                    disabled={answer?.answerStatus === CONSTANTS.FLAGGED}
                                />
                            )}
                        </ButtonBar>
                    </div>
                    {canEdit && (
                        <ButtonBar alignment="CENTRE" className="mt-16 w-full border-t border-gray-200 pt-16">
                            <Button label="Delete Answer" buttonType="TERTIARY" onClick={deleteAnswer} />
                            <Button
                                label="Archive Answer"
                                buttonType="TERTIARY"
                                onClick={componentProps.archiveAnswer}
                                disabled={answer?.answerStatus === CONSTANTS.ARCHIVE_ANSWER}
                            />

                            <Button
                                label="Flag for Review"
                                buttonType="TERTIARY"
                                onClick={componentProps.flagAnswer}
                                disabled={answer?.answerStatus === CONSTANTS.FLAGGED}
                            />
                            <Button
                                label="Make Available"
                                buttonType="TERTIARY"
                                onClick={componentProps.reinstateAnswer}
                                disabled={answer?.answerStatus === CONSTANTS.IN_USE}
                            />
                        </ButtonBar>
                    )}
                </div>
                <div className="mt-16">
                    <Tabs
                        tabItems={filteredTabs}
                        onChange={(selectedTab) => {
                            setTabs(
                                _map(tabs, (tab) => {
                                    return {
                                        ...tab,
                                        isActive: selectedTab.label === tab.label,
                                    };
                                }),
                            );
                        }}
                    />
                    <div className="relative overflow-y-auto px-8 py-16" style={{ maxHeight: 'calc(100vh - 402px)' }}>
                        <div className="min-h-400">
                            {answerAnalytics && <Component {...answerAnalytics} {...componentProps} />}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Modal showClose={false} title="Replace Answer" width="w-1000">
            <UpdateAnswer
                existingAnswerId={answerId}
                existingAnswerHtml={answerAnalytics?.answer?.richTextAnswer}
                save={replaceAnswer}
                cancel={() => {
                    setIsEditing(false);
                }}
            />
        </Modal>
    );
};
