import React, { ReactElement } from 'react';
import { IconProps } from 'types/icon.type';

export const FilterIcon = ({ strokeColor = '#ABC3ED' }: IconProps): ReactElement => {
    return (
        <svg
            data-cy="FilterSVG"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.6667 2H1.33337L6.66671 8.30667V12.6667L9.33337 14V8.30667L14.6667 2Z"
                fill={strokeColor}
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
