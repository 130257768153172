import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Tags } from 'components/duex/Tags';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import _find from 'lodash/find';
import { SettingsCheckbox, SettingsItem } from 'modules/settings/settings-components';
import React, { ReactElement, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { AnalyticsProps } from '../answer-analytics.modal';
import { SectionTitle } from './typography';
export function Organisation(props: AnalyticsProps): ReactElement {
    const { answer, updateFavourite } = props;
    const [, setUpdatingAnswer] = useState(false);
    const [fetchingCollections, setFetchingCollections] = useState(false);
    const [fetchingAnswerCollections, setFetchingAnswerCollections] = useState(false);
    const [collections, setCollections] = useState([]);
    const [answerCollections, setAnswerCollections] = useState([]);

    const fetchCollectionSettings = async (initial = true) => {
        try {
            setFetchingAnswerCollections(initial);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_ANSWER_COLLECTIONS, {
                answerId: answer.answerId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                setAnswerCollections(results.data);
            }
            setFetchingAnswerCollections(false);
        } catch (err) {
            setFetchingAnswerCollections(false);
            showError('There was an error fetching the collections for this answer', err);
        }
    };

    const fetchCollections = async () => {
        try {
            setFetchingCollections(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_ANSWER_COLLECTION);

            const results = await API.get(url);
            if (results && results.data) {
                setCollections(results.data);
            }
            setFetchingCollections(false);
        } catch (err) {
            showError('There was an error fetching the list of collections', err);
            setFetchingCollections(false);
        }
    };

    const addToCollection = async (collectionId: string) => {
        try {
            setUpdatingAnswer(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.ADD_TO_COLLECTION, {
                collectionId,
            });

            const results = await API.post(url, {
                answerIds: [answer.answerId],
            });
            if (results && results.data) {
                showSuccess(`Answer added to collection`);
                fetchCollectionSettings(false);
                setUpdatingAnswer(false);
            }
        } catch (err) {
            showError('There was an error making those changes', err);
            setUpdatingAnswer(false);
        }
    };

    const removeFromCollection = async (collectionId: string) => {
        try {
            setUpdatingAnswer(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.REMOVE_FROM_COLLECTION, {
                collectionId,
            });

            const results = await API.post(url, {
                answerIds: [answer.answerId],
            });
            if (results && results.data) {
                showSuccess(`Answer removed from collection`);
                fetchCollectionSettings(false);
                setUpdatingAnswer(false);
            }
        } catch (err) {
            showError('There was an error making those changes', err);
            setUpdatingAnswer(false);
        }
    };

    useEffect(() => {
        fetchCollections();
        fetchCollectionSettings();
    }, [answer.answerId]);

    const dataLoaded = !fetchingCollections && !fetchingAnswerCollections;

    return (
        <>
            <SectionTitle title="Tags" />
            <div className="my-8">
                <Tags scopeIdentifier={answer.answerId} textInputClasses="p-8" scope="ANSWER" />
            </div>
            <SectionTitle title="Collections" />
            <SettingsItem>
                <SettingsCheckbox
                    description="Favourite Answers"
                    currentValue={answer.isFavourite}
                    onChange={() => {
                        updateFavourite(!answer.isFavourite);
                    }}
                />
            </SettingsItem>
            {!dataLoaded && <LoadingSpinner />}
            {dataLoaded &&
                collections.length > 0 &&
                collections.map((collection) => {
                    const enabled = _find(answerCollections, {
                        collectionId: collection.collectionId,
                    });

                    return (
                        <SettingsItem key={collection.collectionId}>
                            <SettingsCheckbox
                                description={collection.collectionName}
                                currentValue={enabled || false}
                                // disabled={updatingAnswer}
                                onChange={() => {
                                    if (enabled) {
                                        removeFromCollection(collection.collectionId);
                                    } else {
                                        addToCollection(collection.collectionId);
                                    }
                                }}
                            />
                        </SettingsItem>
                    );
                })}
        </>
    );
}
