import { Button } from 'components/duex/Button';
import { LIGHTBLUE, Pill } from 'components/pill.component';
import { showSuccess } from 'components/success-toast.component';
import find from 'lodash/find';
import truncate from 'lodash/truncate';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import type { Question } from 'types/';
import { addToClipboard } from 'utility/Clipboard';
import { complianceOptions } from '../answering/answering.view';
import { QuestionStatus } from './question-status.component';

export const QuickViewQuestion = ({
    quickViewQuestion,
    showQuickView,
}: {
    showQuickView: boolean;
    quickViewQuestion: {
        question?: Question;
        position?: {
            top: number;
            height: number;
        };
    };
}): ReactElement => {
    const [isMouseOver, toggleIsMouseOver] = useState(false);
    const container = useRef(null),
        transparentDiv = useRef(null),
        quickViewContainer = useRef(null);

    const copyAnswerToClipBoard = () => {
        if (quickViewQuestion?.question) {
            const { richTextAnswer, answer } = quickViewQuestion.question;
            addToClipboard(answer, richTextAnswer);
            showSuccess('Copied to clipboard');
        }
    };

    useEffect(() => {
        const topEdgePosition =
                quickViewQuestion?.position?.top -
                container.current.clientHeight / 2 +
                quickViewQuestion?.position?.height / 2,
            bottomEdgePosition = topEdgePosition + container.current.clientHeight;
        if (bottomEdgePosition >= window.innerHeight + window.scrollY) {
            container.current.style.top = `auto`;
            container.current.style.bottom = `0px`;
            transparentDiv.current.style.top = `auto`;
            transparentDiv.current.style.bottom = `0px`;
            quickViewContainer.current.style.bottom = `0px`;
        } else {
            container.current.style.top = `${topEdgePosition}px`;
            container.current.style.bottom = 'auto';
            transparentDiv.current.style.top = `${
                topEdgePosition + container.current.clientHeight / 2 - transparentDiv.current.clientHeight / 2
            }px`;
            transparentDiv.current.style.bottom = 'auto';
            quickViewContainer.current.style.bottom = `auto`;
        }
    });

    return (
        <div
            className={`absolute -right-17 z-2  flex transition duration-200 ease-linear ${
                isMouseOver || showQuickView ? 'opacity-1' : 'pointer-events-none opacity-0'
            }`}
            onMouseEnter={() => toggleIsMouseOver(true)}
            onMouseLeave={() => toggleIsMouseOver(false)}
            ref={quickViewContainer}
        >
            <div
                className="relative w-40 bg-transparent"
                style={{ height: `${quickViewQuestion?.position?.height}px` }}
                ref={transparentDiv}
            ></div>
            <div className="absolute left-34 w-488 rounded-lg bg-white p-18 shadow-lg" ref={container}>
                <div className="mb-13 flex justify-between">
                    <div className="flex w-40-percent">
                        <QuestionStatus question={quickViewQuestion?.question} />
                        <span className="small-1-med ml-8 text-black-80">
                            {find(complianceOptions, { key: quickViewQuestion?.question?.responseClassification })
                                ?.value || 'Not specified'}
                        </span>
                    </div>
                    {quickViewQuestion?.question?.referenceNumber && (
                        <div className="w-60-percent text-right">
                            <Pill
                                title={truncate(quickViewQuestion?.question?.referenceNumber, {
                                    length: 30,
                                    ommission: '...',
                                })}
                                alt={quickViewQuestion?.question?.referenceNumber}
                                colour={LIGHTBLUE}
                            />
                        </div>
                    )}
                </div>
                <div className="small-2-reg whitespace-pre-line break-words text-black-80">
                    {truncate(quickViewQuestion?.question?.question, {
                        length: 800,
                        ommission: '...',
                    })}
                </div>
                {quickViewQuestion?.question?.answer && (
                    <div className="mt-15">
                        <div className="flex items-center">
                            <span className="small-3-reg text-black-60">ANSWER</span>
                            <p className="ml-8 w-full border-b border-black-20"></p>
                        </div>
                        <div className="small-1-med mb-10 mt-10 whitespace-pre-line break-words text-black-100">
                            {truncate(quickViewQuestion?.question?.answer, {
                                length: 800,
                                ommission: '...',
                            })}
                        </div>
                        <div className="flex flex-row-reverse">
                            <Button label="Copy Answer" onClick={copyAnswerToClipBoard} buttonType="SECONDARY" />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
