import { signInWithRedirect } from 'aws-amplify/auth';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { API } from './Api';

const SSOHelper = {
    checkDomain: async (login: string): Promise<boolean> => {
        try {
            if (!login || login.indexOf('@') === -1) {
                return false;
            }

            const parts = login.split('@');
            const domain = parts[1];

            if (domain) {
                const url = ENDPOINTS.getUrl(CONSTANTS.GET_SSO_BY_DOMAIN);
                const { data } = await API.postWithoutAuth(url, {
                    domain,
                });

                if (data && data.providerName) {
                    signInWithRedirect({ provider: { custom: data.providerName } });
                    return true;
                }
            }

            return false;
        } catch (err) {
            console.log('err', err);
            return false;
        }
    },
};

export default SSOHelper;
