import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Module } from 'components/duex/Module';
import { showError } from 'components/error-toast.component';
import { ImageUpload } from 'components/image-upload.view';
import { showSuccess } from 'components/success-toast.component';
import { isValidStringOfLength } from 'components/validation/string';
import { useUserContext } from 'hooks/useUserContext';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const UserProfile = (): ReactElement => {
    const { getUserInfo, refreshTeam, refreshUser, user, logout } = useUserContext();
    const information = getUserInfo(user.userId);

    const [savingChanges, setSavingChanges] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [formValid, setFormValid] = useState(false);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const saveChanges = async () => {
        try {
            const started = new Date();
            setSavingChanges(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_USER);

            const body = {
                givenName: firstName,
                lastName: lastName,
            };

            const results = await API.post(url, body);

            if (results && results.data) {
                refreshUser();
                refreshTeam();
                showSuccess('Profile saved!');
            }

            logEvent('USER_PROFILE_UPDATED', started, body);
            setSavingChanges(false);
        } catch (err) {
            showError('There was an error saving your profile', err);
            setSavingChanges(false);
        }
    };

    const signOut = async () => {
        logout();
    };

    const undoChanges = () => {
        setFirstName(user.givenName);
        setLastName(user.lastName);
    };

    const updateFormStatus = () => {
        const firstNameChanged = user.givenName !== firstName;
        const lastNameChanged = user.lastName !== lastName;

        setHasChanges(firstNameChanged || lastNameChanged);
        setFormValid(isValidStringOfLength(firstName, 2) && isValidStringOfLength(lastName, 2));
    };

    useEffect(updateFormStatus, [user, firstName, lastName]);
    useEffect(undoChanges, [user]);

    useEffect(() => {
        document.title = 'Pearler | Profile';
    }, []);

    const Label = ({ title }: { title: string }) => {
        return <p className="text-black-80">{title}</p>;
    };
    const Value = ({ title }: { title: string }) => {
        return <p className="text-heading-blue">{title}</p>;
    };

    return (
        <Module
            moduleName="Profile"
            width="w-600"
            rightItem={<Button label="Logout" buttonType="SECONDARY" onClick={signOut} />}
        >
            <div className="my-16 grid gap-16">
                <ImageUpload />
                {information && information.role && information.group && (
                    <div
                        className="small-1-med my-16 grid select-none gap-8 rounded-md bg-gray-100 p-16"
                        style={{ gridTemplateColumns: '1fr 3fr' }}
                    >
                        <Label title="Account Type" />
                        <Value title={information.role?.name} />
                        <Label title="Team" />
                        <Value title={information.group?.groupName} />
                    </div>
                )}
                <FormTextField
                    placeholder="John"
                    label="First Name"
                    errorMessage="Please provide a first name"
                    validator={(currentValue) => {
                        return isValidStringOfLength(currentValue, 2);
                    }}
                    valueChanged={setFirstName}
                    defaultValue={firstName}
                    required={true}
                    maxLength={256}
                />
                <FormTextField
                    placeholder="Citizen"
                    label="Last Name"
                    errorMessage="Please provide a last name"
                    validator={(currentValue) => {
                        return isValidStringOfLength(currentValue, 2);
                    }}
                    valueChanged={setLastName}
                    defaultValue={lastName}
                    required={true}
                    maxLength={256}
                />
                <FormTextField
                    placeholder="john.citizen@acme.com"
                    label="Email Address"
                    errorMessage="Please provide an email address"
                    defaultValue={user.emailAddress}
                    disabled={true}
                    maxLength={256}
                />
                <ButtonBar className="mt-16">
                    <Button
                        label="Save Changes"
                        onClick={saveChanges}
                        disabled={!hasChanges || savingChanges || !formValid}
                        loading={savingChanges}
                    />
                    {hasChanges && (
                        <Button
                            buttonType="SECONDARY"
                            label="Undo Changes"
                            onClick={undoChanges}
                            disabled={savingChanges}
                        />
                    )}
                </ButtonBar>
            </div>
        </Module>
    );
};
