import React, { ReactElement } from 'react';

export const ProjectList = ({ strokeColor = 'white' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.108 3.03125L15.606 5.5332L14.4688 4.39595"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.108 8.49023L15.606 10.9922L14.4688 9.85494"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18.108 13.949L15.606 16.4509L14.4688 15.3137"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="3"
                y1="18.6865"
                x2="13.5396"
                y2="18.6865"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="3"
                y1="12.4634"
                x2="13.5396"
                y2="12.4634"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <line
                x1="3"
                y1="6.24048"
                x2="13.5396"
                y2="6.24048"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
