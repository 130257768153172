import { CollaborationIcon } from 'assets/collaboartion-icon.svg';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Indicator } from '../../../assets/discussion-indicator.svg';
import { EditQuestionModal } from '../answering/edit-question/edit.question.modal';
import { ProjectSharedProps } from '../project.view';
import { DropDownOptions } from './drop-down-options.view';
import { ShareModal } from './share-modal.component';

function useOutsideAlerter(ref, toggleDropDownState) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export const QuestionControls = (props: ProjectSharedProps): ReactElement => {
    const { toggleCollaboration, collaborationOpen, questionDiscussion, saveQuestion, question } = props;

    const [, setDropDownStatus] = useState(false);
    const [openShareModal, setShareModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => {
        setDropDownStatus(false);
    });
    useOutsideAlerter(wrapperRef, () => {
        setShareModal(false);
    });

    return (
        <div className="flex flex-row-reverse justify-end gap-16">
            <div
                data-cy="CollaborationTab"
                onClick={toggleCollaboration}
                className={`${
                    collaborationOpen ? 'bg-dark-blue text-white' : 'bg-white text-field-outline-blue'
                } relative flex h-40 cursor-pointer select-none items-center rounded-lg border border-dark-blue px-12 py-10`}
            >
                Collaboration
                <span className="pl-10">
                    <CollaborationIcon strokeColor={collaborationOpen ? '#FFFFFF' : ''} />
                </span>
                {questionDiscussion.length > 0 && (
                    <span className="absolute -right-6 -top-6">
                        <Indicator />
                    </span>
                )}
            </div>
            <div className="h-40 cursor-pointer" ref={wrapperRef}>
                <DropDownOptions
                    {...props}
                    showEditQuestion={() => {
                        setShowEditModal(true);
                    }}
                    showShareModal={() => {
                        setShareModal(true);
                    }}
                    hideShareModal={() => {
                        setShareModal(false);
                    }}
                    hideDropdown={() => {
                        setDropDownStatus(false);
                    }}
                    resetQuestion={() => {
                        saveQuestion(
                            {
                                richTextAnswer: '',
                                responseClassification: 'INCOMPLETE',
                                includeInLibrary: false,
                            },
                            true,
                        );
                    }}
                />
                {openShareModal && <ShareModal />}
                {showEditModal && (
                    <EditQuestionModal
                        question={question}
                        saveQuestion={saveQuestion}
                        closeModal={() => {
                            setShowEditModal(false);
                        }}
                    />
                )}
            </div>
        </div>
    );
};
