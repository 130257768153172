import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { StatusSymbol } from '../../assets/status-symbol.svg';
import { CONSTANTS } from '../../utility/Constants';
export const PriorityStatus = ({
    projectPriority,
    projectDueDate,
    projectStatus,
}: {
    projectPriority: string;
    projectDueDate: Date;
    projectStatus: string;
}): ReactElement => {
    let fillColor = '',
        label = '';

    const endOfToday = moment().endOf('day');
    const dueDate = moment(projectDueDate).endOf('day');
    const isPastDue = endOfToday.isAfter(dueDate);
    const isHighPriority = projectPriority === CONSTANTS.HIGH;
    const projectOpen = projectStatus == 'IN-PROGRESS';

    if (isPastDue && projectOpen) {
        fillColor = '#FF5640';
    } else {
        fillColor = isHighPriority ? '#FF9100' : '#477CD7';
    }

    label = _startCase(_toLower(projectPriority));

    return (
        <div className="flex items-center">
            <span className="">
                <StatusSymbol fillColor={fillColor} />
            </span>
            <div className="pl-8">{label}</div>
        </div>
    );
};

export const DueDate = ({
    projectPriority,
    projectDueDate,
    projectStatus,
}: {
    projectPriority: string;
    projectDueDate: Date;
    projectStatus: string;
}): ReactElement => {
    let label = '',
        className = '';

    const endOfToday = moment().endOf('day');
    const dueDate = moment(projectDueDate).endOf('day');

    const dueToday = endOfToday.isSame(dueDate);
    const isPastDue = endOfToday.isAfter(dueDate);

    const daysBetween = Math.abs(endOfToday.diff(dueDate, 'days'));

    const isHighPriority = projectPriority === CONSTANTS.HIGH;
    const projectOpen = projectStatus == 'IN-PROGRESS';

    if (!projectOpen) {
        label = `Due ${daysBetween} ${daysBetween === 1 ? 'day' : 'days'} ago`;
        className = 'text-gray-500';
    } else if (isPastDue) {
        label = `${daysBetween} ${daysBetween === 1 ? 'day' : 'days'} late`;
        className = 'text-error-light';
    } else if (dueToday) {
        label = 'Due Today';
    } else {
        label = `${daysBetween} ${daysBetween === 1 ? 'day' : 'days'} to go`;
    }

    if (!className) {
        className = isHighPriority ? 'text-dark-golden' : 'text-sea-blue';
    }
    return (
        <div className="flex items-center">
            <p className={className}>{label}</p>
        </div>
    );
};
