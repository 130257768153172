import React, { ComponentType, ReactElement, useState } from 'react';
import { IconProps } from 'types/icon.type';
import FloatingElement from './FloatingElement';

export const MenuButton = ({
    menu,
    width = 'w-full',
    forceHighlight = false,
    Icon,
    className = '',
    mode = 'fixed',
}: {
    menu: ReactElement;
    width?: string;
    forceHighlight?: boolean;
    Icon: ComponentType<IconProps>;
    className?: string;
    mode?: string;
}) => {
    const [menuOpen, setOpen] = useState(false);

    return (
        <div className={className} data-cy="Menu_Button">
            <button
                className={`block border rounded-lg py-8 px-12 ${
                    menuOpen || forceHighlight
                        ? 'bg-blue-600 text-white border-blue-600'
                        : 'hover:bg-blue-50 text-gray-500 border-gray-200'
                }`}
                onClick={(ev) => {
                    ev.stopPropagation();
                    if (!menuOpen) {
                        setOpen(true);
                    }
                }}
            >
                <Icon />
            </button>
            {menuOpen && (
                <FloatingElement
                    showStyling={false}
                    close={() => {
                        setOpen(false);
                    }}
                    className={`${width} mt-8`}
                    mode={mode}
                >
                    {menu}
                </FloatingElement>
            )}
        </div>
    );
};
