import { TeamCreationImage } from 'assets/onboarding-team-creation.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { showError } from 'components/error-toast.component';
import { getEmailDomain, isValidStringOfLength } from 'components/validation/string';
import { useUserContext } from 'hooks/useUserContext';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

function createDefaultTeamName(currentUser: User) {
    const email = currentUser?.emailAddress;

    if (email) {
        const domain = getEmailDomain(email);

        if (domain) {
            const cleanedDomain = domain.toLowerCase();

            if (cleanedDomain === 'gmail' || cleanedDomain === 'hotmail' || cleanedDomain === 'yahoo') {
                return `${currentUser.fullName}'s team`;
            }

            return `Team ${_startCase(_toLower(cleanedDomain))}`;
        }

        return `${currentUser.fullName}'s team`;
    }
}

export const TeamCreation = (): ReactElement => {
    const [teamName, updateTeamName] = useState('');
    const [isSaving, setSaving] = useState(false);
    const navigate = useNavigate();
    const { user, restartApplication } = useUserContext();

    const createTeam = async () => {
        try {
            const started = new Date();
            setSaving(true);

            const result = await API.post(ENDPOINTS.getUrl(CONSTANTS.CREATE_TEAM), {
                name: teamName,
            });

            if (result && result.data) {
                logEvent('REGISTRATION_CREATE_TEAM', started, {
                    name: teamName,
                });
                restartApplication();
                navigate(ROUTES.HOME);
            }
            setSaving(false);
        } catch (err) {
            setSaving(false);
            showError('There was an error creating your team', err);
        }
    };

    useEffect(() => {
        const defaultName = createDefaultTeamName(user);
        updateTeamName(defaultName);
    }, []);

    return (
        <div className="flex max-h-full w-full items-center justify-items-center overflow-y-auto">
            <div className="m-auto flex max-w-full flex-col">
                <div className="flex flex-col md:mt-36">
                    <div className="mx-40 flex items-center justify-items-center md:mx-0">
                        <TeamCreationImage />
                    </div>
                </div>
                <div className="grid gap-16">
                    <p className="h4-medium tracking-0.2 pt-38 text-center text-black-100">Lets make your team</p>
                    <FormTextField
                        defaultValue={teamName}
                        label="Team Name (Required)"
                        valueChanged={updateTeamName}
                        validator={(updatedValue: string) => {
                            return isValidStringOfLength(updatedValue, 3);
                        }}
                        placeholder="Your team name"
                        errorMessage="Please provide a name for the team"
                        maxLength={256}
                    />
                    <ButtonBar>
                        <Button
                            buttonType="SECONDARY"
                            disabled={isSaving}
                            label="Cancel"
                            onClick={() => {
                                navigate(ROUTES.ONBOARDING_TEAM_SET_UP);
                            }}
                        />
                        <Button
                            loading={isSaving}
                            disabled={isSaving || !isValidStringOfLength(teamName, 3)}
                            onClick={createTeam}
                            label="Create my team"
                        />
                    </ButtonBar>
                </div>
            </div>
        </div>
    );
};
