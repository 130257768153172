import { Modal } from 'components/duex/Modal';
import { useUserContext } from 'hooks/useUserContext';
import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { Avatar } from './duex/Avatar';
import { Button } from './duex/Button';
import { ButtonBar } from './duex/ButtonBar';
import { showError } from './error-toast.component';
import { convertToUTF8 } from './validation/string';

export const ImageUpload = (): ReactElement => {
    const { refreshTeam, user, refreshUser } = useUserContext();

    const [imageSelected, setImage] = useState('');
    const [showModal, toggleModalState] = useState(false);
    const [showImageOverlay, toggleImageOverlay] = useState(false);
    const inputButton = useRef<HTMLInputElement>();
    const [thumbNailImage, setThumbNailImage] = useState(user.photo);
    const [uploadingPhoto, setUploadingPhoto] = useState(false);

    const uploadPhoto = async () => {
        try {
            const started = new Date();

            setUploadingPhoto(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.CREATE_UPLOAD_URL);

            const body = {
                fileName: convertToUTF8(inputButton.current.files[0].name),
                contentType: inputButton.current.files[0].type,
            };

            const createUploadUrlRes = await API.post(url, body);

            const uploadUrl = createUploadUrlRes.data.uploadUrl;

            await API.putWithoutAuth(uploadUrl, inputButton.current.files[0], {
                'Content-Type': inputButton.current.files[0].type,
            });

            const completeUrlRes = ENDPOINTS.getUrl(CONSTANTS.COMPLETE_PHOTO_UPLOAD);

            const result = await API.post(completeUrlRes, {
                photoBucketKey: createUploadUrlRes.data.bucketKey,
            });

            if (result && result.data) {
                logEvent('USER_PROFILE_PHOTO_UPDATED', started);
                setUploadingPhoto(false);
                refreshUser();
                refreshTeam();

                toggleModalState(false);
                inputButton.current.value = null;
            }
        } catch (err) {
            setUploadingPhoto(false);
            showError('There was an error uploading your photo', err);
        }
    };

    const removePhoto = async () => {
        try {
            const started = new Date();

            setUploadingPhoto(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.COMPLETE_PHOTO_REMOVAL);

            const result = await API.post(url);

            if (result && result.data) {
                logEvent('USER_PROFILE_PHOTO_REMOVED', started);
                setUploadingPhoto(false);
                refreshUser();
                refreshTeam();
            }
        } catch (err) {
            setUploadingPhoto(false);
            showError('There was an error removing your photo', err);
        }
    };

    const fileSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            const maxFileSize = 2;

            if (fileSize > maxFileSize) {
                alert('That image is too large, please choose a smaller image. We allow up to 2MB.');
            } else {
                setImage(URL.createObjectURL(file));
                toggleModalState(true);
            }
        }
    };

    let userProfile = user;

    if (thumbNailImage) {
        userProfile = {
            ...user,
            // eslint-disable-next-line
            photo: thumbNailImage,
        };
    }

    useEffect(() => {
        setThumbNailImage(user.photo);
    }, [user.photo]);

    return (
        <>
            <div>
                <div
                    className="relative mx-auto flex h-144 w-144 select-none justify-center rounded-full"
                    onMouseEnter={() => toggleImageOverlay(true)}
                    onMouseLeave={() => toggleImageOverlay(false)}
                >
                    <Avatar
                        user={userProfile}
                        size={144}
                        classes="border-4 border-primary-blue-100 cursor-pointer text-app-blue h1-small"
                    />

                    <div
                        className={`absolute flex h-144 w-144 flex-wrap items-center justify-center rounded-full bg-primary-blue-100-opace-8 text-white transition-all duration-500 ease-in-out ${
                            showImageOverlay ? 'opacity-1' : 'opacity-0'
                        }`}
                    >
                        <button className="mt-24 cursor-pointer" onClick={() => inputButton.current.click()}>
                            {thumbNailImage ? 'Replace Photo' : 'Add Photo'}
                        </button>
                    </div>
                </div>

                <input
                    type="file"
                    className="sr-only"
                    accept="image/*"
                    onChange={fileSelectHandler}
                    ref={inputButton}
                />
                {showModal && (
                    <Modal
                        width="w-420"
                        title="Preview"
                        closeModal={() => {
                            toggleModalState(false);
                            inputButton.current.value = null;
                        }}
                    >
                        <div className="flex flex-row justify-center">
                            <img className="h-250 w-250 rounded-full object-cover" src={imageSelected} />
                        </div>
                        <ButtonBar className="mt-50">
                            <Button
                                buttonType="SECONDARY"
                                label="Cancel"
                                onClick={() => {
                                    toggleModalState(false);
                                    inputButton.current.value = null;
                                }}
                                disabled={uploadingPhoto}
                            />
                            <Button
                                label="Save Profile Photo"
                                onClick={uploadPhoto}
                                disabled={uploadingPhoto}
                                loading={uploadingPhoto}
                            />
                        </ButtonBar>
                    </Modal>
                )}
            </div>
            {thumbNailImage && (
                <p
                    className="small-1-med mb-24 mt-8 cursor-pointer text-center text-heading-blue underline"
                    onClick={removePhoto}
                >
                    Remove
                </p>
            )}
        </>
    );
};
