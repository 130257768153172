import { showError } from 'components/error-toast.component';
import { Loader } from 'components/loader.component';
import { useSocket } from 'hooks/useSocket';
import { Banner } from 'modules/closed-project-banner/banner';
import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Membership, Project, ProjectDashboard } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONFIG } from 'utility/Config';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { BuyerOversight } from './buyer-project-oversight/buyer-project-oversight.view';
import { DashboardTopBar } from './dashboard-top-bar/dasboard-top-bar.view';
import { ProjectDetails } from './project-details/project-details.view';
import { ProjectOversight } from './supplier-project-oversight/project-oversight.component';

export interface ProjectDashboardComponents {
    projectTeam: Membership[];
    project: Project;
    refresh: () => void;
    dashboardData: ProjectDashboard;
    updateProjectInfo: (propertyName: string, newValue: string | boolean, refresh?: boolean) => void;
    projectInvites: {
        projectInviteId: string;
        projectId: string;
        inviteStatus: string;
    }[];
    refreshInvites: () => void;
    loading: boolean;
}

export const ProjectDashboardView = (): ReactElement => {
    const [screensize, changeScreenSize] = useState(window.innerWidth);
    const { projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [dashboardData, setProjectData] = useState(null);
    const [projectTeam, setProjectTeam] = useState([]);
    const [inviteList, setInviteList] = useState([]);
    const { latestMessage } = useSocket();

    const fetchData = async () => {
        try {
            setLoading(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.PROJECT_DASHBOARD_ENDPOINT, {
                projectId,
            });

            const result = await API.get(url);

            if (result && result.data) {
                setProjectData(result.data);
            }
            setLoading(false);
        } catch (err) {
            showError('There was a problem fetching your Project');
            setLoading(false);
        }
    };

    const updateProjectInfo = async (propertyName: string, updatedValue: string, refresh = false) => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_PROJECT, {
                projectId,
            });

            const body = {
                [propertyName]: updatedValue,
            };

            const response = await API.post(url, body);

            if (response && response.data) {
                logEvent(`PROJECT_UPDATE_${propertyName.toUpperCase()}`, started, {
                    projectId,
                    propertyName,
                    updatedValue,
                });
                setProjectData({
                    ...dashboardData,
                    project: response.data,
                });

                if (refresh) {
                    fetchData();
                }
            }
        } catch (err) {
            showError('There was a problem updating your Project', err);
        }
    };

    const fetchProjectTeam = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_PROJECT_TEAM, {
                projectId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                setProjectTeam(response.data);
            }
        } catch (err) {
            showError('There was a problem fetching your Project Team', err);
        }
    };

    const fetchProjectInvites = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_GET_SENT, { projectId });
            const result = await API.get(url);

            if (result) {
                setInviteList(result.data);
            }
        } catch (err) {
            showError('There was an error fetching your Sent Invitations', err);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', () => changeScreenSize(window.innerWidth));
        return () => window.removeEventListener('resize', () => changeScreenSize(window.innerWidth));
    }, []);

    useEffect(() => {
        if (projectId) {
            fetchData();
            fetchProjectTeam();
            fetchProjectInvites();
        }
    }, [projectId]);

    useEffect(() => {
        if (latestMessage && latestMessage.scope.projectId === projectId && latestMessage.data) {
            switch (latestMessage.eventName) {
                case 'PROJECT_UPDATED':
                    setProjectData({
                        ...dashboardData,
                        project: latestMessage.data,
                    });

                    break;

                default:
                //console.log('Unhandled event', lastMessage.eventName);
            }
        }
    }, [latestMessage]);

    const gridTemplateAreaForBigScreen = `"top-bar top-bar"
    "oversight-area additional-info"`;

    const gridTemplateAreaForSmallScreen = `"top-bar"
    "additional-info"
    "oversight-area"
    `;

    const childProperties = {
        project: dashboardData?.project,
        dashboardData: dashboardData,
        refresh: fetchData,
        projectTeam,
        updateProjectInfo,
        projectInvites: inviteList,
        refreshInvites: fetchProjectInvites,
        loading,
    };

    const isSupplierProject = dashboardData?.project?.projectType === 'SUPPLIER';

    return (
        <div className="relative h-screen select-none overflow-y-auto">
            {dashboardData?.project.projectStatus === CONSTANTS.PROJECT_COMPLETE && <Banner />}
            {dashboardData?.project && (
                <section
                    className="grid"
                    style={{
                        height: `calc(100vh - ${
                            dashboardData?.project.projectStatus === CONSTANTS.PROJECT_COMPLETE ? '32' : '0'
                        }px)`,
                        gridTemplateRows: `${screensize <= CONFIG.BREAKPOINT_XL ? '108px 1fr 1fr' : '108px 1fr'}`,
                        gridTemplateColumns: `${screensize <= CONFIG.BREAKPOINT_XL ? '' : '3fr 1fr'}`,
                        gridTemplateAreas: `${
                            screensize <= CONFIG.BREAKPOINT_XL
                                ? gridTemplateAreaForSmallScreen
                                : gridTemplateAreaForBigScreen
                        }`,
                        columnGap: '24px',
                    }}
                >
                    <DashboardTopBar {...childProperties} />
                    {isSupplierProject ? (
                        <ProjectOversight {...childProperties} />
                    ) : (
                        <BuyerOversight {...childProperties} />
                    )}
                    <ProjectDetails {...childProperties} />
                </section>
            )}
            <div
                className={`absolute top-0 z-4 h-full w-full bg-white transition-opacity duration-500 ${
                    loading ? 'opacity-1' : 'pointer-events-none opacity-0'
                }`}
            >
                <Loader classes={`h-screen w-full flex items-center bg-cream-opace-7 justify-center`} />
            </div>
        </div>
    );
};
