import { TeamSetupImage } from 'assets/onboarding-team-setup.svg';
import { Button } from 'components/duex/Button';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utility/ApplicationRoutes';

const TeamSetup = (): ReactElement => {
    const navigate = useNavigate();
    return (
        <div className="max-h-full w-full overflow-auto">
            <div className="mx-auto flex h-full max-w-592 flex-col items-center justify-center">
                <div>
                    <TeamSetupImage />
                </div>
                <p className="h4-medium tracking-0.2 pt-38 text-black-100">Thanks for setting up your account</p>
                <div className="body-1-reg pb-32 pt-16 text-center text-black-60">
                    <p>You can now setup your own team, or wait for someone to invite you to theirs.</p>
                </div>
                <Button
                    onClick={() => {
                        navigate(ROUTES.ONBOARDING_TEAM_CREATION);
                    }}
                    label="Create a new team"
                />
            </div>
        </div>
    );
};

export default TeamSetup;
