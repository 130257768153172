import { EditPencil } from 'assets/edit.svg';
import React, { ReactElement, useEffect, useState } from 'react';

export interface ListOption {
    value: string;
    label: string;
}

interface EditableLabelProps {
    labelText: string;
    defaultText?: string;
    labelClasses?: string;
    textInputClasses?: string;
    textInputBackground?: string;
    placeholderText?: string;
    inputType?: 'text' | 'textarea' | 'select';
    listOptions?: ListOption[];
    onChange: (newValue: string) => void;
    isLink?: boolean;
    spellCheck?: boolean;
    dataCy?: string;
}

export const EditableLabel = ({
    labelText = '',
    labelClasses = '',
    defaultText = '',
    textInputClasses = '',
    textInputBackground = 'bg-white',
    placeholderText = '',
    inputType = 'text',
    listOptions,
    onChange,
    isLink = false,
    spellCheck = false,
    dataCy,
}: EditableLabelProps): ReactElement => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentValue, setCurrentValue] = useState('');
    const [showEditIcon, setShowEditIcon] = useState(false);

    const endEditing = () => {
        setIsEditing(false);
        document.removeEventListener('keydown', keyPressHandler, false);
    };

    const keyPressHandler = (event) => {
        if (event.key === 'Escape') {
            setCurrentValue(labelText);
            endEditing();
        }
    };

    useEffect(() => {
        setCurrentValue(labelText);
    }, [labelText]);

    if (!isEditing && inputType !== 'select') {
        if (isLink) {
            return (
                <span
                    className="relative"
                    onMouseEnter={() => {
                        setShowEditIcon(true);
                    }}
                    onMouseLeave={() => {
                        setShowEditIcon(false);
                    }}
                >
                    {showEditIcon && (
                        <span
                            className="absolute -right-24 -top-16 cursor-pointer rounded-sm bg-white p-2 opacity-80 hover:opacity-100"
                            onClick={() => {
                                setIsEditing(true);
                            }}
                        >
                            <EditPencil strokeColor="#52575C" />
                        </span>
                    )}

                    <a
                        data-cy={dataCy}
                        href={labelText}
                        target="_blank"
                        rel="noreferrer"
                        className="break-all hover:text-sea-blue"
                    >
                        {labelText}
                    </a>
                </span>
            );
        }

        return (
            <span
                data-cy={dataCy}
                className={`cursor-text whitespace-pre-line break-words ${labelClasses || ''}`}
                onClick={() => {
                    setIsEditing(true);
                    document.addEventListener('keydown', keyPressHandler, false);
                }}
            >
                {labelText || defaultText || ''}
            </span>
        );
    }

    const updateParent = (updatedValue: string) => {
        if (updatedValue !== labelText) {
            setCurrentValue(updatedValue.trim());
            onChange(updatedValue.trim());
        }
    };

    const updateLocal = (updatedValue: string) => {
        setCurrentValue(updatedValue);
    };

    const onSelection = (updatedValue: string) => {
        if (inputType === 'select') {
            updateParent(updatedValue);
        } else {
            updateLocal(updatedValue);
        }
    };

    const onBlur = () => {
        if (inputType === 'select') {
            updateLocal(currentValue);
        } else {
            updateParent(currentValue);
        }
        endEditing();
    };

    const props = {
        className: `rounded-sm ${textInputBackground} ${inputType !== 'select' ? 'w-90-percent' : ''} ${
            textInputClasses || ''
        }`,
        type: inputType || 'text',
        spellCheck,
        value: currentValue,
        placeholder: placeholderText,
        autoComplete: 'off',
        autoFocus: inputType !== 'select',
        onChange: (event) => {
            const value = event.target.value || '';
            onSelection(value);
        },
        onBlur: onBlur,
    };

    if (inputType === 'textarea') {
        return (
            <textarea data-cy={dataCy} {...props}>
                {currentValue}
            </textarea>
        );
    }

    if (inputType === 'select') {
        return (
            <select data-cy={dataCy} {...props}>
                {!currentValue && (
                    <option key="no-value" value="">
                        Please select
                    </option>
                )}
                {listOptions.map(({ label, value }, index) => {
                    return (
                        <option key={index} value={value}>
                            {label}
                        </option>
                    );
                })}
            </select>
        );
    }

    return <input data-cy={dataCy} {...props} />;
};
