import React, { ReactElement, ReactNode } from 'react';
import { ProjectSharedProps } from './project.view';

export function LargeLayout({
    AssigneeSelector,
    PaginationControl,
    AnsweringSection,
    CollaborationPanel,
    QuestionControls,
    sharedProperties,
}: {
    AssigneeSelector: (props: ProjectSharedProps) => ReactElement;
    PaginationControl: (props: ProjectSharedProps) => ReactElement;
    AnsweringSection: (props: ProjectSharedProps) => ReactElement;
    CollaborationPanel: (props: ProjectSharedProps) => ReactElement;
    QuestionControls: (props: ProjectSharedProps) => ReactElement;
    sharedProperties: ProjectSharedProps;
}): ReactElement {
    const VerticalCentre = ({ children }: { children: ReactNode }): ReactElement => {
        return <div className="flex h-64 w-full flex-row items-center">{children}</div>;
    };

    return (
        <>
            <div
                className="grid h-64 bg-faded-gray px-16 transition-all duration-500 ease-in-out"
                style={{
                    gridTemplateColumns: `200px auto ${sharedProperties.collaborationOpen ? '360px' : '0px'} 275px`,
                }}
            >
                <VerticalCentre>
                    <AssigneeSelector {...sharedProperties} />
                </VerticalCentre>
                <VerticalCentre>
                    <PaginationControl {...sharedProperties} />
                </VerticalCentre>
                <div className=""></div>
                <VerticalCentre>
                    <QuestionControls {...sharedProperties} />
                </VerticalCentre>
            </div>

            <div
                className={`grid transition-all duration-500 ease-in-out`}
                style={{
                    gridTemplateColumns: `auto ${sharedProperties.collaborationOpen ? '420px' : '0px'}`,
                }}
            >
                <div
                    className=""
                    style={{ height: `calc(100vh - 64px - ${sharedProperties.projectClosed ? '32' : '0'}px` }}
                >
                    <AnsweringSection {...sharedProperties} />
                </div>
                <div
                    className=""
                    style={{ height: `calc(100vh - 64px - ${sharedProperties.projectClosed ? '32' : '0'}px` }}
                >
                    <CollaborationPanel
                        {...sharedProperties}
                        closeCollaboration={() => {
                            sharedProperties.setCollaboration(false);
                        }}
                    />
                </div>
            </div>
        </>
    );
}
