import { EmptyState } from 'components/empty-states.component';
import _filter from 'lodash/filter';
import React, { ReactElement } from 'react';
import { CONSTANTS } from 'utility/Constants';
import { ReactComponent as PersonSearching } from '../../../../assets/person-searching.svg';
import { SuggestAnswersProps } from '../answering.view';
import { SuggestedAnswersListItem } from '../suggested-answers/suggested-answers-list-item.component';
import { suggestionDropownOptions } from '../suggested-answers/suggested-answers.component';
const filteredOptions = _filter(suggestionDropownOptions, ({ value }) => {
    return value !== CONSTANTS.HIDE_ANSWER;
});

export const FavouriteAnswers = ({
    project,
    favouriteAnswers,
    applyAnswer,
    hideAnswer,
    flagAnswer,
    undoHideAnswer,
    hiddenAnswers,
}: SuggestAnswersProps): ReactElement => {
    return (
        <div data-cy="Favourites_Tab" className="m-auto flex h-full flex-col pt-32">
            <div className="flex flex-grow-2 flex-col">
                {favouriteAnswers.length ? (
                    <ul>
                        {favouriteAnswers.map((answer) => (
                            <SuggestedAnswersListItem
                                key={answer.answerId}
                                answer={answer}
                                suggestionDropownOptions={filteredOptions}
                                applyAnswer={applyAnswer}
                                hideAnswer={hideAnswer}
                                flagAnswer={flagAnswer}
                                undoHideAnswer={undoHideAnswer}
                                projectStatus={project.projectStatus}
                                hiddenAnswers={hiddenAnswers}
                            />
                        ))}
                    </ul>
                ) : (
                    <div className="py-32">
                        <EmptyState
                            placeholderImage={<PersonSearching />}
                            title="Your favourites answers"
                            subTitle="You don't have any favourites yet, add some in the Answer Library"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
