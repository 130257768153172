import React, { ReactElement } from 'react';

export const CalenderLeftArrow = (): ReactElement => {
    return (
        <svg
            width="24"
            height="24"
            className="inline"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-cy="Prev_Month"
        >
            <path
                d="M15 18L9 12L15 6"
                stroke="#A0A4A8"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
