import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { FormLabel } from 'components/duex/FormLabel';
import { FormSelectField } from 'components/duex/FormSelectField';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { MultiSelectPill } from 'components/duex/MultiSelectPill';
import { showError } from 'components/error-toast.component';
import { isValidEmail, isValidString } from 'components/validation/string';
import { useTeamContext } from 'hooks/useTeamContext';
import { useUserContext } from 'hooks/useUserContext';
import _find from 'lodash/find';
import _map from 'lodash/map';
import React, { ReactElement, useState } from 'react';
import { Group, Role } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const SendInvite = ({
    roles,
    groups,
    closeModal,
}: {
    roles: Role[];
    groups: Group[];
    closeModal: () => void;
}): ReactElement => {
    const { fullTeamList } = useUserContext();
    const { hasFeature, teamDivisions } = useTeamContext();
    const [emailAddress, setEmailAddress] = useState('');
    const [roleId, setRole] = useState(null);
    const [groupId, setGroup] = useState(null);
    const [saving, setSaving] = useState(false);
    const [homeDivision, setHomeDivision] = useState(null);
    const [selectedDivisions, setSelectedDivisions] = useState([]);

    const sendInvitation = async () => {
        try {
            const started = new Date();
            setSaving(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.INVITE_MEMBER);
            const result = await API.post(url, {
                emailAddress,
                groupId,
                roleId,
                ...getDivisionSettings(),
            });

            if (result && result.data) {
                logEvent('INVITE_MEMBER', started, {
                    emailAddress,
                    groupId,
                    roleId,
                });
                setSaving(false);
                closeModal();
            } else {
                showError('There was an error inviting that person');
            }
        } catch (err) {
            console.log('Error thrown', err);
            setSaving(false);
            showError('There was an error inviting that person', err);
        }
    };

    const divisionsEnabled = hasFeature('enableDivisions');

    const getDivisionSettings = () => {
        if (!divisionsEnabled) {
            return {};
        }

        return {
            homeDivision,
            divisions: selectedDivisions,
        };
    };

    const divisionsComplete = () => {
        if (!divisionsEnabled) {
            return true;
        }

        return isValidString(homeDivision) && selectedDivisions.length > 0;
    };

    const fieldsComplete =
        isValidEmail(emailAddress) && isValidString(groupId) && isValidString(roleId) && divisionsComplete();

    const alreadyInvited = _find(fullTeamList, {
        emailAddress,
    });

    const divisionOptions = _map(teamDivisions, ({ divisionId, divisionName }) => {
        return {
            itemId: divisionId,
            itemLabel: divisionName,
        };
    });

    return (
        <>
            <Modal width="w-500" title="Invite Team Member" closeModal={closeModal}>
                <div className="grid gap-16">
                    <FormTextField
                        required={true}
                        defaultValue={emailAddress}
                        label="Email Address"
                        valueChanged={setEmailAddress}
                        validator={(newValue: string) => isValidEmail(newValue)}
                        placeholder="john.citizen@acme.com"
                        errorMessage="Please enter an email address"
                        maxLength={256}
                    />

                    <FormSelectField
                        dataCy="Select_Team"
                        required={true}
                        defaultValue={groupId}
                        label="Team"
                        valueChanged={setGroup}
                        validator={(newValue: string) => isValidString(newValue)}
                        placeholder="Select Team"
                        errorMessage="Please select a team"
                        items={groups}
                        optionLabel="groupName"
                        optionValue="groupId"
                    />

                    <FormSelectField
                        dataCy="Select_Access"
                        required={true}
                        defaultValue={roleId}
                        label="Access"
                        valueChanged={setRole}
                        validator={(newValue: string) => isValidString(newValue)}
                        placeholder="Select Access"
                        errorMessage="Please select an access level"
                        items={roles}
                        optionLabel="name"
                        optionValue="roleId"
                    />
                    {divisionsEnabled && (
                        <>
                            <FormSelectField
                                required={true}
                                defaultValue={homeDivision}
                                label="Primary Division"
                                valueChanged={(newValue: string) => {
                                    setHomeDivision(newValue);

                                    if (selectedDivisions.indexOf(newValue) === -1) {
                                        setSelectedDivisions([...selectedDivisions, newValue]);
                                    }
                                }}
                                validator={(newValue: string) => isValidString(newValue)}
                                placeholder="Select Primary Division"
                                errorMessage="Please select a primary division"
                                items={teamDivisions}
                                optionLabel="divisionName"
                                optionValue="divisionId"
                            />
                            <div>
                                <FormLabel label="Divisions" required={true} />
                                <MultiSelectPill
                                    items={divisionOptions}
                                    selections={selectedDivisions}
                                    updateSelection={setSelectedDivisions}
                                />
                            </div>
                        </>
                    )}
                    {alreadyInvited && (
                        <EmptyState
                            title="Already Invited"
                            subtitle={`${emailAddress} has already been invited to this team`}
                        />
                    )}

                    <ButtonBar>
                        <Button label="Cancel" disabled={saving} buttonType="SECONDARY" onClick={closeModal} />
                        <Button
                            label="Send Invite"
                            loading={saving}
                            disabled={saving || !fieldsComplete || alreadyInvited}
                            onClick={sendInvitation}
                        />
                    </ButtonBar>
                </div>
            </Modal>
        </>
    );
};
