import { ChevronRight } from 'assets/chevron-right.svg';
import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { EmptyState } from 'components/duex/EmptyState';
import { showError } from 'components/error-toast.component';
import { Pill, RED, YELLOW } from 'components/pill.component';
import { Col, Row } from 'components/table';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'utility/Api';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { DashboardWidget } from './dashboard-widget';

export function DashboardProjects(): ReactElement {
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_DASHBOARD_PROJECTS);
            const response = await API.get(url);

            if (response && response.data) {
                setProjects(response.data);
            }

            setIsLoading(false);
        } catch (err) {
            showError('There was a problem retrieving your projects', err);
            setIsLoading(false);
        }
    };

    const showProject = async ({ projectId }) => {
        navigate(
            createUrl(ROUTES.PROJECT_DASHBOARD, {
                projectId,
            }),
        );
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DashboardWidget
            isLoading={isLoading}
            title="Active Projects"
            showMoreTitle="See More"
            showMoreLink={ROUTES.PROJECT_LIST}
            drawBorder={true}
        >
            {projects.length === 0 && (
                <div className="flex h-full flex-col items-center justify-center">
                    <EmptyState
                        width="w-360"
                        title="No Projects"
                        subtitle="Open projects assigned to you or created by you will appear here"
                    >
                        <div className="mt-16 flex flex-row justify-center">
                            <Button
                                onClick={() => {
                                    navigate(ROUTES.PROJECT_CREATE);
                                }}
                                label="Create Project"
                            />
                        </div>
                    </EmptyState>
                </div>
            )}

            {projects.map((project, index) => {
                const lastItem = project.length === index + 1;
                const isOverdue = moment(project.dueOn).isBefore(new Date());
                const dueToday = moment(project.dueOn).isSame(new Date(), 'day');

                const dueDateHighlighting = isOverdue ? 'text-red' : dueToday ? 'text-orange' : '';

                return (
                    <Row
                        key={project.projectId}
                        spacing="py-16"
                        className={`cursor-pointer border-gray-100 hover:bg-blue-50 ${!lastItem ? 'border-b' : ''}`}
                        onClickHandler={() => {
                            showProject(project);
                        }}
                    >
                        <Col className="w-1-of-12 pl-8">
                            <Avatar
                                size={32}
                                userId={
                                    project.salesManagerUserId ||
                                    project.deliveryManagerUserId ||
                                    project.createdByUserId
                                }
                            />
                        </Col>
                        <Col className="w-6-of-12 pl-8">
                            <p className="body-2-reg tracking-0.2 truncate text-black-100">{project.name}</p>
                            <p className="small1-medium tracking-0.2 mt-4 text-light-gray">
                                Created {moment(project.createdAt).fromNow()}
                            </p>
                        </Col>
                        <Col className={`w-4-of-12 text-center`}>
                            {project.projectPriority === 'HIGH' && (
                                <span className="mb-4">
                                    <Pill title="High Priority" colour={YELLOW} alt="High Priority" />
                                </span>
                            )}
                            {project.projectPriority !== 'HIGH' && (isOverdue || dueToday) && (
                                <span className="mb-4">
                                    <Pill title="Urgent" colour={RED} alt="Urgent" />
                                </span>
                            )}
                            <p className={`${dueDateHighlighting}`}>Due {moment(project.dueOn).fromNow()}</p>
                        </Col>
                        <Col className="flex w-1-of-12 flex-row-reverse pr-16">
                            <ChevronRight />
                        </Col>
                    </Row>
                );
            })}
        </DashboardWidget>
    );
}
