import { Modal } from 'components/modal.component';
import React, { ReactElement } from 'react';
import { FileUploadComponent } from './file-upload.view';

export const FileUploadModal = ({
    closeModal,
    projectId,
    questionId,
}: {
    closeModal: () => void;
    questionId?: string;
    projectId?: string;
}): ReactElement => {
    return (
        <div className="relative mx-auto flex h-144 w-144 select-none justify-center rounded-full">
            <Modal width="w-500" isOpen={true}>
                <h5 className="h5-medium mb-24">Add Document</h5>
                <FileUploadComponent projectId={projectId} questionId={questionId} onComplete={closeModal} />
            </Modal>
        </div>
    );
};
