import { Notification } from 'components/info-toast.view';
import { useSocket } from 'hooks/useSocket';
import React, { ReactElement, useEffect } from 'react';
import { toast } from 'react-toastify';

export function GlobalSocket(): ReactElement {
    const { latestMessage } = useSocket();

    useEffect(() => {
        if (latestMessage && latestMessage.data) {
            switch (latestMessage.eventName) {
                case 'NOTIFICATION_CREATED':
                    toast.info(
                        <Notification
                            causedByUserId={latestMessage.data.causedByUserId}
                            title={latestMessage.data.title}
                            linkReferencePath={latestMessage.data.linkReferencePath}
                        />,
                        {
                            icon: false,
                        },
                    );

                    break;

                default:
                //console.log('Unhandled event', lastMessage.eventName);
            }
        }
    }, [latestMessage]);

    return null;
}
