import React, { ReactElement } from 'react';

export const Globe = ({ strokeColor = 'white' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 19C14.1926 19 17.7156 16.1332 18.7157 12.2529M10 19C5.80742 19 2.28442 16.1332 1.2843 12.2529M10 19C12.4853 19 14.5 14.9706 14.5 10C14.5 5.02944 12.4853 1 10 1M10 19C7.51472 19 5.5 14.9706 5.5 10C5.5 5.02944 7.51472 1 10 1M10 1C13.3652 1 16.299 2.84694 17.8431 5.58245M10 1C6.63481 1 3.70099 2.84694 2.15692 5.58245M17.8431 5.58245C15.7397 7.40039 12.9983 8.5 10 8.5C7.00172 8.5 4.26027 7.40039 2.15692 5.58245M17.8431 5.58245C18.5797 6.88743 19 8.39463 19 10C19 10.778 18.9013 11.5329 18.7157 12.2529M18.7157 12.2529C16.1334 13.6847 13.1619 14.5 10 14.5C6.8381 14.5 3.86662 13.6847 1.2843 12.2529M1.2843 12.2529C1.09871 11.5329 1 10.778 1 10C1 8.39463 1.42032 6.88743 2.15692 5.58245"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
