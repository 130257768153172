import { DndContext } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Drag } from 'assets/drag.svg';
import { EditPencil } from 'assets/edit.svg';
import { ModalCross } from 'assets/modal-cross.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { ConfirmationDialog } from 'components/duex/ConfirmationDialog';
import { FormTextField } from 'components/duex/FormTextField';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Tabs } from 'components/duex/Tabs';
import { EditableLabel } from 'components/editable-label.component';
import { showError } from 'components/error-toast.component';
import { isValidStringOfLength } from 'components/validation/string';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _first from 'lodash/first';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { v4 as uuidv4 } from 'uuid';

interface AssessmentItem {
    itemId: string;
    itemLabel: string;
    itemType: 'QUESTION' | 'SECTION';
    itemConfig?: string;
}

interface AssessmentPayload {
    assessmentId?: string;
    assessmentName?: string;
    assessmentDescription?: string;
    assessmentConfiguration?: AssessmentItem[];
}

// const exampleData: AssessmentItem[] = [
//     {
//         itemId: uuidv4(),
//         itemLabel: `Coffee Preferences`,
//         itemType: 'SECTION',
//     },
//     {
//         itemId: uuidv4(),
//         itemLabel: `What's your favourite type of coffee?`,
//         itemType: 'QUESTION',
//     },
//     {
//         itemId: uuidv4(),
//         itemLabel: 'What milk do you drink?',
//         itemType: 'QUESTION',
//     },
//     {
//         itemId: uuidv4(),
//         itemLabel: 'How hot do you like your coffee?',
//         itemType: 'QUESTION',
//     },
// ];

const tabs = [
    {
        label: 'Overview',
        key: 'SUMMARY',
        isActive: true,
    },
    {
        label: 'Assessment Designer',
        key: 'QUESTIONS',
        isActive: false,
    },
];

export const EditAssessment = ({
    assessment,
    refresh,
}: {
    assessment: AssessmentPayload;
    refresh: () => void;
}): ReactElement => {
    const { assessmentId } = assessment;

    const [items, setItems] = useState<AssessmentItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<AssessmentItem[]>([]);

    const [originalData, setOriginalData] = useState<AssessmentPayload>({});

    const [isSaving, setIsSaving] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);

    const [assessmentName, setAssessmentName] = useState('');
    const [assessmentDescription, setAssessmentDescription] = useState('');

    const [sortableIds, setSortableIds] = useState([]);
    const [sections, setSections] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);

    const [assessmentTabs, setAssessmentTabs] = useState(tabs);

    const [openQuestions, setOpenQuestions] = useState({});

    const [showClearChangesModal, setShowClearChangesModal] = useState(false);

    const fetchData = async () => {
        try {
            setFetchingData(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_GET_ASSESSMENT, {
                assessmentId,
            });
            const result = await API.get(url);

            if (result && result.data) {
                setItems(result.data.assessmentConfiguration || []);
                setAssessmentName(result.data.assessmentName || '');
                setAssessmentDescription(result.data.assessmentDescription || '');
                setOriginalData(result.data);
            }
            setFetchingData(false);
        } catch (err) {
            showError('There was an error retrieving your Assessment Template', err);
            setFetchingData(false);
        }
    };

    function applyTabMode() {
        const sections = _filter(items, { itemType: 'SECTION' });

        if (sections.length > 1 && items.length > 15) {
            return true;
        }

        return false;
    }

    const useTabMode = applyTabMode();

    const getCurrentPayload = () => {
        return {
            assessmentName,
            assessmentDescription,
            assessmentConfiguration: items,
        };
    };

    const getOriginalPayload = () => {
        const { assessmentName, assessmentDescription, assessmentConfiguration } = originalData;

        return {
            assessmentName,
            assessmentDescription,
            assessmentConfiguration: assessmentConfiguration,
        };
    };

    const compareVersions = () => {
        const current = getCurrentPayload();
        const original = getOriginalPayload();
        return !_isEqual(current, original);
    };

    const addSection = () => {
        const updatedItems: AssessmentItem[] = [
            ...items,
            {
                itemId: uuidv4(),
                itemLabel: 'New Section',
                itemType: 'SECTION',
            },
        ];

        setItems(updatedItems);
    };

    const renameItem = (itemId: string, newName: string) => {
        const updatedItems = _map(items, (item: AssessmentItem) => {
            if (item.itemId === itemId) {
                return {
                    ...item,
                    itemLabel: newName,
                };
            }
            return {
                ...item,
            };
        });

        setItems(updatedItems);
    };

    const removeItem = (itemId: string) => {
        const confirmation = window.confirm('Are you sure you want to remove this?');

        if (confirmation) {
            const updatedItems = _filter(items, (item: AssessmentItem) => {
                return item.itemId !== itemId;
            });
            setItems(updatedItems);
        }
    };

    const addQuestionAtIndex = (index) => {
        const newItem: AssessmentItem = {
            itemId: uuidv4(),
            itemLabel: '',
            itemType: 'QUESTION',
        };

        const nextSectionHeaderIndex = _findIndex(
            items,
            {
                itemType: 'SECTION',
            },
            index + 1,
        );

        if (nextSectionHeaderIndex < 0) {
            setItems([...items, newItem]);
        } else {
            const precedingItems = items.slice(0, nextSectionHeaderIndex);
            const remainingItems = items.slice(nextSectionHeaderIndex);

            const updatedItems = [...precedingItems, newItem, ...remainingItems];

            setItems(updatedItems);
        }
    };

    const undoChanges = async () => {
        setShowClearChangesModal(true);
    };

    const saveAssessment = async () => {
        if (!assessmentName) {
            return alert('Please give the assessment a name');
        }

        try {
            const started = new Date();
            setIsSaving(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_SAVE_ASSESSMENT, {
                assessmentId,
            });

            const createdAssessment: {
                data: AssessmentPayload;
            } = await API.post(url, {
                assessmentName,
                assessmentDescription,
                assessmentConfiguration: items,
            });

            setItems(createdAssessment.data.assessmentConfiguration);
            setAssessmentName(createdAssessment.data.assessmentName);
            setAssessmentDescription(createdAssessment.data.assessmentDescription);
            setOriginalData(createdAssessment.data);
            setIsSaving(false);

            logEvent(`ASSESSMENT_UPDATED`, started, {
                assessmentId,
                assessmentName,
                assessmentDescription,
            });

            refresh();
        } catch (err) {
            showError('There was an error saving your Assessment', err);
            setIsSaving(false);
        }
    };

    const updateTabs = () => {
        const sections = _filter(items, (item) => item.itemType === 'SECTION');

        const tabs = _map(sections, (section, index) => {
            let selected = false;

            if (!selectedTab && index === 0) {
                selected = true;
            } else if (selectedTab && selectedTab.identifier === section.itemId) {
                selected = true;
            }

            return {
                label: section.itemLabel,
                isActive: selected,
                identifier: section.itemId,
            };
        });
        if (!selectedTab) {
            if (tabs.length > 0) {
                setSelectedTab(_first(tabs));
            }
        }
        setSections(tabs);
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!active || !over) {
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = _findIndex(items, { itemId: active.id });
            const newIndex = _findIndex(items, { itemId: over.id });
            const updatedItems = arrayMove(items, oldIndex, newIndex);

            setItems(updatedItems);
        }
    };

    const recalculateFilteredItems = () => {
        // This function is logically complicated, but the complexity is isolated to this function

        const sections = _filter(items, { itemType: 'SECTION' });

        if (!applyTabMode()) {
            return setFilteredItems(items || []);
        }

        const filteredList: AssessmentItem[] = [];
        const showingSectionNumber = _findIndex(sections, {
            itemId: selectedTab?.identifier,
        });

        let seenSectionHeader = false;
        let seenFilteredSectionHeader = false;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            let add = false;

            if (showingSectionNumber === 0 && !seenSectionHeader) {
                // We haven't seen a section header, but items exist and we're on the first tab, so they should be included
                add = true;
            }

            if (item.itemType === 'SECTION') {
                // We've now seen a section header
                seenSectionHeader = true;
            }

            if (item.itemId === selectedTab?.identifier) {
                // This is the selected filter, so we want to track that
                seenFilteredSectionHeader = true;
            }

            if (seenFilteredSectionHeader && item.itemType === 'SECTION' && item.itemId !== selectedTab?.identifier) {
                // This avoids sections and subsequent items being added into the filtered list (early exit)
                break;
            }

            if (seenFilteredSectionHeader) {
                // We've seen the right section header, so we should add, knowing that the above condition will
                // early exit for us when the next section appears
                add = true;
            }

            if (add) {
                filteredList.push(item);
            }
        }

        setFilteredItems(filteredList);
    };

    const ItemRow = ({ item }: { item: AssessmentItem }) => {
        const expanded = openQuestions[item.itemId];
        const questionNumber = _findIndex(items, {
            itemId: item.itemId,
        });

        return (
            <BaseItem id={item.itemId} className="" style={{ gridTemplateColumns: '20px auto' }}>
                <div className="w-full">
                    <div className="w-full grid" style={{ gridTemplateColumns: 'auto 48px' }}>
                        <div>
                            <small className="block place-self-start text-gray-500">Question {questionNumber}</small>
                            <p className="truncate">{item.itemLabel}</p>
                            {/* <EditableLabel
                            inputType="textarea"
                            labelText={item.itemLabel || ''}
                            labelClasses="text-14 text-gray-700 w-full p-4"
                            textInputClasses="text-14 text-gray-700 w-full p-4"
                            placeholderText="Type a question"
                            defaultText="Empty question"
                            onChange={(updatedValue) => {
                                renameItem(item.itemId, updatedValue);
                            }}
                            spellCheck={true}
                        /> */}
                        </div>
                        <div className="flex flex-row gap-8">
                            <div
                                className="cursor-pointer opacity-60 hover:opacity-100"
                                onClick={() => {
                                    setOpenQuestions({
                                        ...openQuestions,
                                        [item.itemId]: !expanded,
                                    });
                                }}
                            >
                                <EditPencil strokeColor="#A0A4A8" />
                            </div>
                            <div
                                className="cursor-pointer opacity-60 hover:opacity-100"
                                onClick={() => {
                                    removeItem(item.itemId);
                                }}
                            >
                                <ModalCross strokeColor="#A0A4A8" />
                            </div>
                        </div>
                    </div>
                    {expanded && (
                        <>
                            <hr className="my-16" />
                            <div className="px-16 grid gap-16 mb-16">
                                <FormTextField
                                    placeholder="What's your favourite type of coffee?"
                                    label="Question Text"
                                    errorMessage="Please write a question"
                                    defaultValue={item.itemLabel}
                                    validator={(currentValue) => {
                                        return isValidStringOfLength(currentValue, 3);
                                    }}
                                    inputType="resizing-textarea"
                                    onBlur={(updatedValue) => {
                                        renameItem(item.itemId, updatedValue);
                                    }}
                                    required={true}
                                    spellCheck={true}
                                    maxLength={10000}
                                />
                                <FormTextField
                                    placeholder="We like to know so we can cater to your preferences"
                                    label="Background Information"
                                    defaultValue={''}
                                    inputType="resizing-textarea"
                                    onBlur={() => {
                                        // TODO
                                        // renameItem(item.itemId, updatedValue);
                                    }}
                                    required={false}
                                    spellCheck={true}
                                    maxLength={10000}
                                />
                                <FormTextField
                                    placeholder="REQ-10"
                                    label="Reference Number"
                                    defaultValue={''}
                                    onBlur={() => {
                                        // TODO
                                        // renameItem(item.itemId, updatedValue);
                                    }}
                                    required={false}
                                    spellCheck={true}
                                />
                                <FormTextField
                                    placeholder="A number between 0 and 10 (10 being most important)"
                                    label="Scoring Weight"
                                    defaultValue={''}
                                    onBlur={() => {
                                        // TODO
                                        // renameItem(item.itemId, updatedValue);
                                    }}
                                    required={false}
                                    spellCheck={true}
                                />
                            </div>
                        </>
                    )}
                </div>
            </BaseItem>
        );
    };

    const SectionItem = ({ item, index }: { item: AssessmentItem; index: number }) => {
        return (
            <BaseItem id={item.itemId} className="mt-24" style={{}}>
                <div className="grid w-full gap-16" style={{ gridTemplateColumns: 'auto 140px' }}>
                    <div>
                        <small className="ml-4 block text-gray-500">Section</small>
                        <EditableLabel
                            labelText={item.itemLabel || ''}
                            labelClasses="text-18 text-gray-900 w-full p-4"
                            textInputClasses="text-16 text-gray-900 w-full p-4"
                            placeholderText="Name the section"
                            onChange={(updatedValue) => {
                                renameItem(item.itemId, updatedValue);
                            }}
                        />
                    </div>
                    <Button buttonType="TERTIARY" label="Add Question" onClick={() => addQuestionAtIndex(index)} />
                </div>
                <div
                    className={`cursor-pointer opacity-60 hover:opacity-100`}
                    onClick={() => {
                        removeItem(item.itemId);
                    }}
                >
                    <ModalCross strokeColor="#A0A4A8" />
                </div>
            </BaseItem>
        );
    };

    const BaseItem = ({
        id,
        children,
        style,
        className,
    }: {
        id: string;
        children: ReactNode;
        className: string;
        style?: { [key: string]: string };
    }) => {
        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

        const styles = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return (
            <div ref={setNodeRef} {...attributes} className={className} style={styles}>
                <div
                    className="my-4 flex flex-row items-center justify-between gap-8 rounded-lg bg-white px-16 py-8"
                    style={style}
                >
                    <div
                        className="cursor-pointer opacity-60 hover:opacity-100"
                        title="Drag question to change the order"
                        {...listeners}
                    >
                        <Drag color="#A0A4A8" />
                    </div>
                    {children}
                </div>
            </div>
        );
    };

    useEffect(() => {
        fetchData();
        setAssessmentTabs(tabs);
    }, [assessmentId]);

    useEffect(() => {
        const ids = _map(items, (item) => item.itemId);
        setSortableIds(ids);
        updateTabs();
        recalculateFilteredItems();
    }, [assessmentId, items, selectedTab]);

    const hasChanges = compareVersions();

    const selectedAssesmentTab = _find(assessmentTabs, { isActive: true });

    const showSummary = selectedAssesmentTab.key === 'SUMMARY';
    const showForm = selectedAssesmentTab.key === 'QUESTIONS';

    return (
        <div>
            {fetchingData && <LoadingSpinner />}
            {!fetchingData && (
                <div className="rounded-l-lg bg-very-light-blue p-16">
                    <div className="mb-32">
                        <h2 className="h6-medium text-xl mb-16 text-primary-blue-60">{assessment.assessmentName}</h2>

                        <div className="mb-16 grid grid-cols-2">
                            <Tabs
                                tabItems={assessmentTabs}
                                onChange={({ key }) => {
                                    setAssessmentTabs(
                                        _map(assessmentTabs, (tab) => {
                                            return {
                                                ...tab,
                                                isActive: tab.key === key,
                                            };
                                        }),
                                    );
                                }}
                                showBottomBorder={false}
                            />

                            <ButtonBar>
                                <Button
                                    onClick={saveAssessment}
                                    disabled={!hasChanges || isSaving}
                                    loading={isSaving}
                                    label="Save Changes"
                                />
                                {hasChanges && (
                                    <Button
                                        onClick={undoChanges}
                                        buttonType="SECONDARY"
                                        disabled={!hasChanges}
                                        label="Discard Changes"
                                    />
                                )}
                            </ButtonBar>
                        </div>

                        {showSummary && (
                            <div className="grid gap-16">
                                <FormTextField
                                    placeholder="Vendor Information Security Checklist"
                                    label="Assessment Name"
                                    errorMessage="Please provide a name for the assessment"
                                    defaultValue={assessmentName}
                                    validator={(currentValue) => {
                                        return isValidStringOfLength(currentValue, 3);
                                    }}
                                    valueChanged={setAssessmentName}
                                    required={true}
                                    spellCheck={true}
                                    maxLength={256}
                                />
                                <FormTextField
                                    placeholder="We use this assessment for checking the security posture of new vendors"
                                    label="Description"
                                    errorMessage="Please provide a description for the assessment"
                                    defaultValue={assessmentDescription}
                                    validator={(currentValue) => {
                                        return isValidStringOfLength(currentValue, 3);
                                    }}
                                    inputType="resizing-textarea"
                                    valueChanged={setAssessmentDescription}
                                    required={true}
                                    spellCheck={true}
                                    maxLength={10000}
                                />
                                <ButtonBar>
                                    <Button
                                        onClick={() => {
                                            // TODO
                                        }}
                                        buttonType="SECONDARY"
                                        disabled={hasChanges}
                                        label="Duplicate Assessment"
                                    />
                                </ButtonBar>
                            </div>
                        )}
                        {showForm && (
                            <>
                                {useTabMode && sections.length > 1 && (
                                    <div className="mt-16 mb-32">
                                        <Tabs
                                            tabItems={sections}
                                            onChange={(newlySelectedTab) => {
                                                setSelectedTab(newlySelectedTab);
                                            }}
                                            showBottomBorder={false}
                                            selectedTabClassName="bg-blue-500 text-white rounded-full border-transparent"
                                            tabClassName="rounded-full"
                                        />
                                    </div>
                                )}

                                <DndContext onDragEnd={handleDragEnd}>
                                    <SortableContext items={sortableIds} strategy={verticalListSortingStrategy}>
                                        {filteredItems &&
                                            filteredItems.map((item, index) => {
                                                if (item.itemType === 'SECTION') {
                                                    return (
                                                        <SectionItem
                                                            item={item}
                                                            key={item.itemId}
                                                            index={index}
                                                        ></SectionItem>
                                                    );
                                                }

                                                if (item.itemType === 'QUESTION') {
                                                    return <ItemRow item={item} key={item.itemId}></ItemRow>;
                                                }
                                            })}
                                    </SortableContext>
                                </DndContext>
                                <div className="mt-16 flex flex-row justify-items-center">
                                    <Button
                                        buttonType="TERTIARY"
                                        label="Add a new section"
                                        onClick={addSection}
                                        className="mx-auto"
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    {showClearChangesModal && (
                        <ConfirmationDialog
                            title="Discard Changes"
                            descriptionText={[
                                'Are you sure you want to discard changes?',
                                'Any unsaved changes will be lost.',
                            ]}
                            primaryLabel="Discard Changes"
                            primaryAction={() => {
                                setShowClearChangesModal(false);
                                setItems(originalData.assessmentConfiguration);
                                setAssessmentName(originalData.assessmentName);
                                setAssessmentDescription(originalData.assessmentDescription);
                            }}
                            cancelAction={() => {
                                setShowClearChangesModal(false);
                            }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
