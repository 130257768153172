import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import _map from 'lodash/map';
import _times from 'lodash/times';
import React, { useEffect, useState } from 'react';
import { logError } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

const NumberPicker = ({
    currentSelection,
    changeSelection,
}: {
    currentSelection: number;
    changeSelection: (update: number) => void;
}) => {
    return (
        <div className="my-8 flex flex-row gap-16">
            {_map(_times(10), (number) => {
                const value = number + 1;
                const isSelected = currentSelection === value;

                return (
                    <div
                        data-cy={`rating_${number + 1}`}
                        onClick={() => {
                            changeSelection(value);
                        }}
                        key={value}
                        className={`h-32 w-32 cursor-pointer rounded-full p-4 text-center ${
                            isSelected ? 'bg-blue-700 text-white' : 'bg-gray-100 hover:bg-blue-500 hover:text-white'
                        }`}
                    >
                        {value}
                    </div>
                );
            })}
        </div>
    );
};

export const Feedback = () => {
    const [showModal, setShowModal] = useState(false);
    const [recentScore, setRecentScore] = useState(null);
    const [overallScore, setOverallScore] = useState(null);
    const [recentComments, setRecentComments] = useState('');
    const [overallComments, setOverallComments] = useState('');
    const [saving, setSaving] = useState(false);

    const fetchStatus = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_FEEDBACK);

            const results = await API.get(url);

            if (results && results.data) {
                if (results.data.status === 'REQUEST_FEEDBACK') {
                    setTimeout(() => {
                        setShowModal(true);
                    }, 1000);
                }
            }
        } catch (err) {
            // Do nothing
        }
    };

    const submit = async () => {
        try {
            setSaving(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.SUBMIT_FEEDBACK);

            const results = await API.post(url, {
                recentExperienceScore: recentScore,
                recentExperienceComments: recentComments,
                likelihoodToRecommend: overallScore,
                likelihoodToRecommendComments: overallComments,
            });

            if (results && results.data) {
                setShowModal(false);
            }
            setSaving(false);
        } catch (err) {
            logError('Error while submitting feedback', err);
            setShowModal(false);
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchStatus();
    }, []);

    if (showModal) {
        const formComplete = recentScore && overallScore;

        return (
            <Modal
                dataCy="Feedback_Modal"
                width="w-600"
                title="We'd love your feedback"
                closeModal={() => {
                    setShowModal(false);
                }}
            >
                <h2 className="font-semibold text-blue-600">In the last two weeks...</h2>
                <div className="my-8">In the last two weeks how has your experience with Pearler been?</div>
                <NumberPicker changeSelection={setRecentScore} currentSelection={recentScore} />
                {recentScore && (recentScore < 9 || recentComments) && (
                    <FormTextField
                        placeholder="Please let us know what how we could improve (optional)"
                        label="Comments"
                        inputType="textarea"
                        rows={3}
                        required={false}
                        valueChanged={setRecentComments}
                        containerClasses="my-16"
                    />
                )}

                <h2 className="mt-32 font-semibold text-blue-600">Overall</h2>
                <div className="my-8">Overall, how likely are you to suggest Pearler to a colleague?</div>
                <NumberPicker changeSelection={setOverallScore} currentSelection={overallScore} />
                {overallScore && (overallScore < 9 || overallComments) && (
                    <FormTextField
                        placeholder="Let us know what how we could improve that score (optional)"
                        label="Comments"
                        inputType="textarea"
                        rows={3}
                        required={false}
                        valueChanged={setOverallComments}
                        containerClasses="mt-16"
                    />
                )}
                <ButtonBar className="mt-32">
                    <Button
                        testId="Submit_Feedback"
                        label="Submit Feedback"
                        onClick={submit}
                        loading={saving}
                        disabled={!formComplete || saving}
                    />
                    <Button
                        label="Ask me next time"
                        buttonType="SECONDARY"
                        onClick={() => {
                            setShowModal(false);
                        }}
                        disabled={saving}
                    />
                </ButtonBar>
            </Modal>
        );
    }

    return null;
};
