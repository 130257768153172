import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { isValidStringOfLength } from 'components/validation/string';
import React, { ReactElement, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const AddCollectionModal = ({ closeModal }: { closeModal: () => void }): ReactElement => {
    const [collectionName, setCollectionName] = useState('');
    const [isSaving, setIsSaving] = useState(false);

    const save = async () => {
        try {
            if (getSaveReadiness()) {
                const started = new Date();
                setIsSaving(true);

                const url = ENDPOINTS.getUrl(CONSTANTS.CREATE_ANSWER_COLLECTION);
                const result = await API.post(url, {
                    collectionName,
                });

                logEvent(`ANSWER_LIBRARY_ADD_COLLECTION`, started);

                if (result.data) {
                    setIsSaving(false);
                    closeModal();
                }
            }
        } catch (err) {
            setIsSaving(false);
            showError('There was an error creating your collection', err);
        }
    };

    const getSaveReadiness = () => {
        return isValidStringOfLength(collectionName, 3);
    };

    return (
        <Modal
            width="w-400"
            closeModal={closeModal}
            title="Add Collection"
            closeSafely={() => {
                return !collectionName;
            }}
        >
            <>
                <fieldset className="flex flex-col gap-16">
                    <FormTextField
                        placeholder="Enter your collection name here."
                        label="Collection Name"
                        errorMessage="Please provide a Collection Name"
                        validator={(currentValue) => {
                            return isValidStringOfLength(currentValue, 3);
                        }}
                        valueChanged={setCollectionName}
                        required={true}
                        spellCheck={true}
                        maxLength={256}
                    />
                </fieldset>
                <ButtonBar className="mt-24">
                    <Button label="Cancel" onClick={closeModal} buttonType="SECONDARY" disabled={isSaving} />
                    <Button
                        label="Add Collection"
                        onClick={save}
                        disabled={!getSaveReadiness() || isSaving}
                        loading={isSaving}
                    />
                </ButtonBar>
            </>
        </Modal>
    );
};
