import _map from 'lodash/map';
import { matchRoutes, useLocation } from 'react-router-dom';
import { ROUTES } from 'utility/ApplicationRoutes';

const routes = _map(Object.keys(ROUTES), (name: string) => {
    return {
        path: ROUTES[name],
    };
});

export const useCurrentPath = () => {
    const location = useLocation();
    const [{ route }] = matchRoutes(routes, location);

    return route.path;
};
