import { showError, showErrorToastOnly } from 'components/error-toast.component';
import { Loader } from 'components/loader.component';
import { useSocket } from 'hooks/useSocket';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _first from 'lodash/first';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _slice from 'lodash/slice';
import _uniqBy from 'lodash/uniqBy';
import { Banner } from 'modules/closed-project-banner/banner';
import React, { ReactElement, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Activity, Answer, Comment, Membership, Project, Question, User } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { shouldOpenCollaboration, shouldShowDiscussion, shouldShowWorkflow } from 'utility/Common';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { Answering } from './answering/answering.view';
import { CollaborationView } from './collaboration/collaboration.view';
import { LargeLayout } from './large-layout';
import { QuestionAssignee } from './project-top-bar/question-assignee';
import { QuestionControls } from './project-top-bar/question-controls';
import { QuestionPagination } from './project-top-bar/question-pagination';
import { QuestionList } from './question-list/question-list.view';

const validFilters = [
    CONSTANTS.COMPLETE,
    CONSTANTS.INPROGRESS,
    CONSTANTS.INCOMPLETE_STATUS,
    CONSTANTS.COMPLIANT,
    CONSTANTS.PARTIALLYCOMPLIANT,
    CONSTANTS.NONCOMPLIANT,
    CONSTANTS.NOTAPPLICABLE,
    CONSTANTS.INCOMPLETE_COMPLIANCE,
    CONSTANTS.ASSIGNEDTOME,
    CONSTANTS.ASSIGNEDTOELSE,
    CONSTANTS.UNASSIGNED,
    CONSTANTS.IM_MENTIONED,
    CONSTANTS.ASSIGNED_TO_SPECIFIC,
];

export interface QuestionListFilter {
    key: string;
    data?: string[];
}

export interface ProjectSharedProps {
    dataCy?: string;
    projectId: string;
    questionId: string;
    project: Project;
    question: Question;
    questionList: Question[];
    filteredQuestionList: Question[];
    questionActivity: Activity[];
    questionDiscussion: Comment[];
    projectTeam: Membership[];
    favouriteAnswers: Answer[];
    completedQuestionCount: number;
    filteredQuestionCount: number;
    questionCount: number;
    filters: QuestionListFilter[];
    searchFilter: string;
    clearFilter: () => void;
    setSearchFilter: (updatedSearchFilter: string) => void;
    setFilters: (updatedFilters: QuestionListFilter[]) => void;
    setAnswerHasChanges: (newValue: boolean) => void;
    setCollaborationHasChanges: (newValue: boolean) => void;
    toggleCollaboration: () => void;
    collaborationOpen: boolean;
    setCollaboration: (newStatus: boolean) => void;
    answerHasChanges: boolean;
    collaborationHasChanges: boolean;
    updateQuestionAssignee: (newAssignee: User) => void;
    showUnsavedChangesModal: () => boolean;
    fetchActivity: () => void;
    fetchDiscussion: () => void;
    createComment: (comment: string, recipients: string[]) => Promise<boolean>;
    savingComment: boolean;
    closeCollaboration?: () => void;
    markQuestionComplete: () => void;
    markNotAQuestion: () => void;
    deleteQuestion: () => void;
    saveQuestion: (update: Question, refresh?: boolean) => Promise<boolean>;
    savingChanges: boolean;
    patchQuestion: (updatedQuestion: Question) => void;
    nextQuestion: () => void;
    previousQuestion: () => void;
    nextIncompleteQuestion: () => void;
    userSubscribedToQuestion: boolean;
    fetchSubscription: () => void;
    fetchFavouriteAnswers: () => void;
    incomingChanges: {
        userId: string;
        question: Question;
    };
    applyIncomingChanges: () => void;
    discardIncomingChanges: () => void;
    fetchingDiscussion: boolean;
    fetchingHistory: boolean;
    projectClosed: boolean;
    updatingAssignee: boolean;
    setShowRewriteAnswer: (newStatus: boolean) => void;
    showRewriteAnswer: boolean;
    setShowGenerateAnswer: (newStatus: boolean) => void;
    showGenerateAnswer: boolean;
    collaborationTab: string;
    changeCollaborationTab: (newStatus: string) => void;
}

export const ProjectHome = (): ReactElement => {
    const navigate = useNavigate();
    const { projectId, questionId } = useParams();
    const {
        user: { userId },
    } = useUserContext();
    const { latestMessage } = useSocket();

    // Meta Data
    const [loadingProject, setLoadingProject] = useState(false);
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    const [answerHasChanges, setAnswerHasChanges] = useState(false);
    const [collaborationHasChanges, setCollaborationHasChanges] = useState(false);
    const [savingComment, setSavingComment] = useState(false);
    const [savingChanges, setSavingChanges] = useState(false);
    const [completedQuestionCount, setCompletedQuestionCount] = useState(0);
    const [userSubscribedToQuestion, setUserSubscribedToQuestion] = useState(false);

    const [fetchingDiscussion, setFetchingDiscussion] = useState(false);
    const [fetchingHistory, setFetchingHistory] = useState(false);
    const [updatingAssignee, setUpdatingAssignee] = useState(false);

    const [collaborationOpen, setCollaboration] = useState(false);
    const [collaborationTab, changeCollaborationTab] = useState(CONSTANTS.DISCUSSION);

    const [showRewriteAnswer, setShowRewriteAnswer] = useState(false);
    const [showGenerateAnswer, setShowGenerateAnswer] = useState(false);

    const patchQuestionReducer = (state, action): Question[] => {
        if (state.reset) {
            return [];
        }

        let updatedQuestionList: Question[];

        if (action.update && action.questionId) {
            updatedQuestionList = _map(state, (question) => {
                if (question.questionId === action.questionId) {
                    return {
                        ...question,
                        ...action.update,
                    };
                }
                return question;
            });
        }

        if (action.questionList) {
            updatedQuestionList = [...action.questionList, ...state];
        }

        if (action.question) {
            const updatedQuestion = action.question;
            const existingQuestion = _find(state, {
                questionId: updatedQuestion.questionId,
            });

            if (!existingQuestion) {
                updatedQuestionList = [...state, updatedQuestion];
            } else if (existingQuestion) {
                updatedQuestionList = _map(state, (question) => {
                    if (question.questionId === updatedQuestion.questionId) {
                        return {
                            ...updatedQuestion,
                            loggedInUserMentioned:
                                updatedQuestion.loggedInUserMentioned || question.loggedInUserMentioned,
                            tags: updatedQuestion.tags || question.tags,
                        };
                    }
                    return question;
                });
            }
        }

        const filteredList = _filter(updatedQuestionList, (question: Question) => {
            return question.isLinked && !question.deletedAt && question.projectId === projectId;
        });

        const cleanedList = _uniqBy(filteredList, 'questionId');

        return cleanedList;
    };

    // Source Data
    // @ts-ignore
    const [project, setProject] = useState<Project>({});
    const [questionList, setQuestionList] = useReducer(patchQuestionReducer, []);
    const [projectTeam, setProjectTeam] = useState([]);
    const [questionDiscussion, setQuestionDiscussion] = useState<Comment[]>([]);
    const [questionActivity, setQuestionActivity] = useState([]);
    const [favouriteAnswers, setFavouriteAnswers] = useState([]);

    const [incomingChanges, setIncomingChanges] = useState(null);

    // Mutatable Data
    const [filters, setFilters] = useState<QuestionListFilter[]>([]);
    const [searchFilter, setSearchFilter] = useState('');
    const [filteredQuestionList, setFilteredQuestionsList] = useState([]);

    const patchQuestion = (updatedQuestion: Question) => {
        setQuestionList({
            question: updatedQuestion,
        });
    };

    const partialPatch = (updatedProperties, questionId: string) => {
        setQuestionList({
            update: updatedProperties,
            questionId,
        });
    };

    const fetchFavouriteAnswers = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_FAVOURITE_ANSWERS);
            const response = await API.get(url);

            if (response && response.data) {
                setFavouriteAnswers(response.data);
            }
        } catch (err) {
            showError('There was a problem fetching your Favourite Answers', err);
        }
    };

    const applyIncomingChanges = () => {
        patchQuestion(incomingChanges.question);
        discardIncomingChanges();
    };

    const discardIncomingChanges = () => {
        setIncomingChanges(null);
    };

    const toggleCollaboration = () => {
        setCollaboration(!collaborationOpen);
    };

    const clearFilter = () => {
        setFilters([]);
        setSearchFilter('');
    };

    const setupFiltersFromUrl = (projectChanged = false) => {
        if (!projectChanged && filters.length) {
            return;
        }

        const urlFilters = window.location.search || '';
        const individualFilters = urlFilters.replace('?', '').split(',');

        if (shouldOpenCollaboration()) {
            if (!collaborationOpen) {
                setCollaboration(true);
            }

            if (shouldShowDiscussion()) {
                changeCollaborationTab(CONSTANTS.DISCUSSION);
                individualFilters.push(CONSTANTS.IM_MENTIONED);
            }

            if (shouldShowWorkflow()) {
                changeCollaborationTab(CONSTANTS.WORKFLOW);
            }
        }

        if (individualFilters.length > 0) {
            const cleanedFilters = _filter(individualFilters, (filter) => {
                const filterName = filter.split('=')[0];
                return validFilters.indexOf(filterName) !== -1;
            });

            setFilters(
                _map(cleanedFilters, (filter) => {
                    const [filterName, data] = filter.split('=');
                    return {
                        key: filterName,
                        data: data?.split(',') || [],
                    };
                }),
            );
        }
    };

    const fetchProject = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.ENDPOINT_GET_PROJECT, {
                projectId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                setProject(response.data);
            }
        } catch (err) {
            showError('There was a problem fetching your project', err);
        }
    };

    const fetchQuestion = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_QUESTION, {
                projectId,
                questionId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                if (response.data.isLinked && !response.data.deletedAt) {
                    patchQuestion(response.data);
                } else {
                    showErrorToastOnly('That question no longer exists');
                }
            }
        } catch (err) {
            showError('There was a problem fetching the Question', err);
        }
    };

    const fetchQuestionList = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.QUESTION, {
                projectId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                setQuestionList({
                    questionList: response.data,
                });
            }
        } catch (err) {
            showError('There was a problem fetching the Question List', err);
        }
    };

    const fetchProjectTeam = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_PROJECT_TEAM, {
                projectId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                setProjectTeam(response.data);
            }
        } catch (err) {
            showError('There was a problem fetching your Project Team', err);
        }
    };

    const fetchQuestionActivity = async () => {
        try {
            setFetchingHistory(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_ACTIVITY, {
                questionId,
                projectId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                setQuestionActivity(response.data);
            }
            setFetchingHistory(false);
        } catch (err) {
            showError('There was a problem fetching the Question Activity', err);
            setFetchingHistory(false);
        }
    };

    const fetchQuestionDiscussion = async () => {
        try {
            setFetchingDiscussion(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_DISCUSSION, {
                questionId,
                projectId,
            });
            const response = await API.get(url);

            if (response && response.data) {
                setQuestionDiscussion(response.data);
            }
            setFetchingDiscussion(false);
        } catch (err) {
            setFetchingDiscussion(false);
            showError('There was a problem fetching the Question Activity', err);
        }
    };

    const createComment = async (comment: string, emailList: string[]): Promise<boolean> => {
        try {
            setSavingComment(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.CREATE_DISCUSSION, {
                projectId,
                questionId,
            });
            const response = await API.post(url, {
                comment: comment.replace(/@\[+([^\][]+)]+/g, ''),
                emailList,
            });

            if (response && response.data) {
                setQuestionDiscussion([...questionDiscussion, response.data]);
            }
            setSavingComment(false);

            return true;
        } catch (err) {
            setSavingComment(false);
            showError('There was a problem saving your comment', err);
        }
    };

    const fetchProjectData = async () => {
        if (projectId) {
            setLoadingProject(true);

            const promises = [fetchProject(), fetchQuestionList()];

            fetchProjectTeam();
            fetchFavouriteAnswers();

            Promise.all(promises)
                .then(() => {
                    setLoadingProject(false);
                })
                .catch(() => {
                    setLoadingProject(false);
                });
        }
    };

    const fetchQuestionData = async () => {
        if (questionId) {
            setLoadingQuestion(true);

            const promises = [fetchQuestionActivity(), fetchQuestionDiscussion(), fetchSubscription(), fetchQuestion()];

            Promise.all(promises)
                .then(() => {
                    setLoadingQuestion(false);
                })
                .catch(() => {
                    setLoadingQuestion(false);
                });
        }
    };

    const fetchSubscription = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.QUESTION_SUBSCRIPTION_STATUS, {
                projectId,
                questionId,
            });
            const result = await API.get(url);

            if (result && result.data) {
                setUserSubscribedToQuestion(result.data.userSubscribed);
            }
        } catch (err) {
            showError('There was an error retrieving your subscription status', err);
        }
    };

    const updateQuestionAssignee = async ({ userId }: { userId: string }) => {
        try {
            setUpdatingAssignee(true);
            let url;
            const started = new Date();

            if (userId) {
                url = ENDPOINTS.getUrl(CONSTANTS.QUESTION_UPDATE, {
                    projectId,
                    questionId,
                });
            } else {
                url = ENDPOINTS.getUrl(CONSTANTS.QUESTION_UNASSIGN, {
                    projectId,
                    questionId,
                });
            }

            const response = await API.post(url, {
                assignedToUserId: userId,
            });

            logEvent('PROJECT_ANSWER_ASSIGN_QUESTION', started, {
                projectId,
                questionId,
                assignedToUserId: userId,
            });

            if (response && response.data) {
                patchQuestion(response.data);
            }
            setUpdatingAssignee(false);
        } catch (err) {
            showError('There was a problem updating the assignee', err);
            setUpdatingAssignee(false);
        }
    };

    const saveQuestion = async (changes, refreshActivity = false) => {
        try {
            const started = new Date();
            setSavingChanges(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.QUESTION_UPDATE, {
                questionId,
                projectId,
            });

            const response = await API.post(url, changes);

            logEvent('PROJECT_ANSWER_SAVE_QUESTION', started, {
                projectId,
                questionId,
                ...changes,
            });

            if (response && response.data) {
                patchQuestion(response.data);
                if (refreshActivity) {
                    fetchQuestionActivity();
                }
            }
            setSavingChanges(false);
            return true;
        } catch (err) {
            showError('There was a problem saving your changes', err);
            setSavingChanges(false);
            return false;
        }
    };

    const markQuestionComplete = async () => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.COMPLETE_QUESTION, {
                projectId,
                questionId,
            });
            const response = await API.post(url, {
                projectId,
                questionId,
            });

            logEvent('PROJECT_ANSWER_MARK_QUESTION_COMPLETE', started, {
                questionId,
                projectId,
            });

            if (response && response.data) {
                patchQuestion(response.data);
            }
        } catch (err) {
            showError('There was a problem marking that Question as complete', err);
        }
    };

    const deleteQuestion = async () => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.DELETE_QUESTION, {
                projectId,
                questionId,
            });
            const response = await API.post(url, {
                projectId,
                questionId,
            });

            logEvent('PROJECT_ANSWER_DELETE_QUESTION', started, {
                questionId,
                projectId,
            });

            if (response && response.data) {
                patchQuestion(response.data);
                handleRemoveQuestion();
            }
        } catch (err) {
            showError('There was a problem deleting that Question', err);
        }
    };

    const markNotAQuestion = async () => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.NOT_A_QUESTION, {
                projectId,
                questionId,
            });
            const response = await API.post(url, {
                projectId,
                questionId,
            });

            logEvent('PROJECT_ANSWER_MARK_NOT_A_QUESTION', started, {
                questionId,
                projectId,
            });

            if (response && response.data) {
                handleRemoveQuestion();
                patchQuestion(response.data);
            }
        } catch (err) {
            showError('There was a problem marking that as not a Question', err);
        }
    };

    const safelyPatchQuestion = (updatedQuestion: Question, userId: string) => {
        const comparisonProperties = ['answer', 'responseClassification', 'includeInLibrary'];

        if (!_isEqual(_pick(updatedQuestion, comparisonProperties), _pick(question, comparisonProperties))) {
            setIncomingChanges({
                userId,
                question: updatedQuestion,
            });
        } else {
            patchQuestion(updatedQuestion);
        }
    };

    const handleRemoveQuestion = () => {
        if (getNextQuestion()) {
            nextQuestion();
        } else if (getPreviousQuestion()) {
            previousQuestion();
        }
    };

    const getNextQuestion = (): Question => {
        const indexOfQuestion = _findIndex(filteredQuestionList, {
            questionId: question.questionId,
        });

        if (indexOfQuestion < filteredQuestionList.length) {
            return filteredQuestionList[indexOfQuestion + 1];
        }
    };

    const nextQuestion = () => {
        const nextQuestion = getNextQuestion();
        if (nextQuestion) {
            navigate(
                createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                    questionId: nextQuestion.questionId,
                    projectId: projectId,
                }),
            );
        }
    };

    const getPreviousQuestion = (): Question => {
        const indexOfQuestion = _findIndex(filteredQuestionList, {
            questionId: question.questionId,
        });

        if (indexOfQuestion > 0) {
            return filteredQuestionList[indexOfQuestion - 1];
        }
    };

    const previousQuestion = () => {
        const previousQuestion = getPreviousQuestion();
        if (previousQuestion) {
            navigate(
                createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                    questionId: previousQuestion.questionId,
                    projectId: projectId,
                }),
            );
        }
    };

    const nextIncompleteQuestion = () => {
        if (question) {
            const indexOfQuestion = _findIndex(filteredQuestionList, {
                questionId: question.questionId,
            });

            if (indexOfQuestion !== -1) {
                const remainingQuestions = _slice(
                    filteredQuestionList,
                    indexOfQuestion + 1,
                    filteredQuestionList.length,
                );
                const previousQuestions = _slice(filteredQuestionList, 0, indexOfQuestion);
                const reorderedArrayOfQuestions = [...remainingQuestions, ...previousQuestions];

                const unansweredList = reorderedArrayOfQuestions.filter(
                    (question) =>
                        question.responseStatus === CONSTANTS.INCOMPLETE ||
                        question.responseStatus !== CONSTANTS.COMPLETE,
                );

                const nextQuestion = _first(unansweredList);

                if (nextQuestion) {
                    return navigate(
                        createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                            questionId: nextQuestion.questionId,
                            projectId: projectId,
                        }),
                    );
                }
            }
        }

        const nextQuestion = _first(filteredQuestionList);
        if (nextQuestion) {
            navigate(
                createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                    questionId: nextQuestion.questionId,
                    projectId: projectId,
                }),
            );
        }
    };

    const calculateCompletedQuestions = () => {
        const completedQuestions = _filter(questionList, {
            responseStatus: CONSTANTS.COMPLETE,
        });
        setCompletedQuestionCount(completedQuestions.length);
    };

    const showUnsavedChangesModal = (): boolean => {
        if (answerHasChanges || collaborationHasChanges) {
            return window.confirm('You have unsaved changes, are you sure you want to continue?');
        }
        return true;
    };

    const filterEnabled = (filterName: string) => {
        return (
            _findIndex(filters, {
                key: filterName,
            }) !== -1
        );
    };
    const getFilterData = (filterName: string) => {
        return _find(filters, {
            key: filterName,
        })?.data;
    };

    const filterQuestionList = () => {
        let filteredQuestions = questionList;

        // This is a filter to remove anything that doesn't match
        if (searchFilter.trim()) {
            const textFilter = searchFilter.toLowerCase();

            filteredQuestions = _filter(filteredQuestions, ({ question, referenceNumber, answer }) => {
                return (
                    question?.toLowerCase()?.includes(textFilter) ||
                    referenceNumber?.toLowerCase()?.includes(textFilter) ||
                    answer?.toLowerCase()?.includes(textFilter)
                );
            });
        }

        // These are filters to include (no selection from a group is the same as all being selected)
        if (filters && filters.length > 0) {
            // Question Status filters

            const statusCompleted = filterEnabled(CONSTANTS.COMPLETE);
            const statusInProgress = filterEnabled(CONSTANTS.INPROGRESS);
            const statusIncomplete = filterEnabled(CONSTANTS.INCOMPLETE_STATUS);

            if (statusCompleted || statusInProgress || statusIncomplete) {
                filteredQuestions = _filter(filteredQuestions, ({ responseStatus }) => {
                    if (responseStatus === CONSTANTS.COMPLETE && statusCompleted) {
                        return true;
                    }
                    if (responseStatus === CONSTANTS.INPROGRESS && statusInProgress) {
                        return true;
                    }
                    if (responseStatus === CONSTANTS.INCOMPLETE && statusIncomplete) {
                        return true;
                    }

                    return false;
                });
            }

            // Compliance Status filters

            const compliant = filterEnabled(CONSTANTS.COMPLIANT);
            const partiallyCompliant = filterEnabled(CONSTANTS.PARTIALLYCOMPLIANT);
            const nonCompliant = filterEnabled(CONSTANTS.NONCOMPLIANT);
            const notApplicable = filterEnabled(CONSTANTS.NOTAPPLICABLE);
            const incomplete = filterEnabled(CONSTANTS.INCOMPLETE_COMPLIANCE);

            if (compliant || partiallyCompliant || nonCompliant || notApplicable || incomplete) {
                filteredQuestions = _filter(filteredQuestions, ({ responseClassification }) => {
                    if (responseClassification === CONSTANTS.COMPLIANT && compliant) {
                        return true;
                    }
                    if (responseClassification === CONSTANTS.PARTIALLYCOMPLIANT && partiallyCompliant) {
                        return true;
                    }
                    if (responseClassification === CONSTANTS.NONCOMPLIANT && nonCompliant) {
                        return true;
                    }
                    if (responseClassification === CONSTANTS.NOTAPPLICABLE && notApplicable) {
                        return true;
                    }
                    if (responseClassification === CONSTANTS.INCOMPLETE && incomplete) {
                        return true;
                    }

                    return false;
                });
            }

            // Assignee filters

            const assignedMe = filterEnabled(CONSTANTS.ASSIGNEDTOME);
            const assignedOthers = filterEnabled(CONSTANTS.ASSIGNEDTOELSE);
            const unassigned = filterEnabled(CONSTANTS.UNASSIGNED);
            const specificAssignee = filterEnabled(CONSTANTS.ASSIGNED_TO_SPECIFIC);

            if (assignedMe || assignedOthers || unassigned || specificAssignee) {
                filteredQuestions = _filter(filteredQuestions, ({ assignedToUserId }) => {
                    if (assignedToUserId === userId && assignedMe) {
                        return true;
                    }
                    if (assignedToUserId && assignedToUserId !== userId && assignedOthers) {
                        return true;
                    }
                    if (!assignedToUserId && unassigned) {
                        return true;
                    }

                    if (specificAssignee) {
                        const assignee = getFilterData(CONSTANTS.ASSIGNED_TO_SPECIFIC);

                        if (assignee) {
                            const userId = _first(assignee);

                            if (userId === assignedToUserId) {
                                return true;
                            }
                        }
                    }
                    return false;
                });
            }

            const imMentioned = filterEnabled(CONSTANTS.IM_MENTIONED);

            if (imMentioned) {
                filteredQuestions = _filter(filteredQuestions, {
                    loggedInUserMentioned: true,
                });
            }

            // Tag filters

            const filterByTags = filterEnabled(CONSTANTS.HAS_TAG);

            if (filterByTags) {
                const cleanedTags = _map(getFilterData(CONSTANTS.HAS_TAG), (tag) => {
                    return tag.toLowerCase();
                });

                filteredQuestions = _filter(filteredQuestions, ({ tags }) => {
                    if (!tags || tags.length === 0) {
                        return false;
                    }

                    for (let i = 0; i < tags.length; i++) {
                        if (cleanedTags.indexOf(tags[i].toLowerCase()) !== -1) {
                            return true;
                        }
                    }

                    return false;
                });
            }

            // Document, Section & Sheet filters

            if (filterEnabled(CONSTANTS.FILTER_DOCUMENT)) {
                const sourceDocumentId = _first(getFilterData(CONSTANTS.FILTER_DOCUMENT));

                filteredQuestions = _filter(filteredQuestions, ({ projectSourceId }) => {
                    return sourceDocumentId === projectSourceId;
                });
            }

            if (filterEnabled(CONSTANTS.FILTER_SHEET)) {
                const filterSheetId = _first(getFilterData(CONSTANTS.FILTER_SHEET));

                filteredQuestions = _filter(filteredQuestions, ({ sheetId }) => {
                    return filterSheetId === sheetId;
                });
            }

            if (filterEnabled(CONSTANTS.FILTER_SECTION)) {
                const sectionName = _first(getFilterData(CONSTANTS.FILTER_SECTION));

                filteredQuestions = _filter(filteredQuestions, ({ sectionDescription }) => {
                    return sectionName === sectionDescription;
                });
            }
        }

        setFilteredQuestionsList(filteredQuestions);
    };

    useEffect(() => {
        const sameProject = latestMessage.scope.projectId === projectId || latestMessage.data.projectId === projectId;

        if (latestMessage && latestMessage.data) {
            switch (latestMessage.eventName) {
                case 'PROJECT_UPDATED':
                    if (sameProject) {
                        setProject(latestMessage.data);
                    }

                    break;
                case 'QUESTION_UPDATED':
                    if (sameProject) {
                        const entity = latestMessage.data;

                        if (questionId === entity.questionId) {
                            // Someone editing the same question as us
                            safelyPatchQuestion(entity, latestMessage.scope.userId);
                        } else if (projectId === entity.projectId) {
                            // Someone working in the same project as us
                            patchQuestion(entity);
                        }
                    }

                    break;

                case 'TAG_ADDED': {
                    const { resourceId, resourceType, tagLabel } = latestMessage.data;

                    if (resourceType === 'QUESTION') {
                        const question = _find(questionList, {
                            questionId: resourceId,
                        });

                        if (question) {
                            partialPatch(
                                {
                                    tags: [...question.tags, tagLabel],
                                },
                                questionId,
                            );
                        }
                    }
                    break;
                }
                case 'TAG_DELETED': {
                    const { resourceId, resourceType, tagLabel } = latestMessage.data;

                    if (resourceType === 'QUESTION') {
                        const question = _find(questionList, {
                            questionId: resourceId,
                        });

                        if (question) {
                            partialPatch(
                                {
                                    tags: _filter(question.tags, (tag) => {
                                        return tag !== tagLabel;
                                    }),
                                },
                                questionId,
                            );
                        }
                    }
                    break;
                }
                case 'COMMENT_CREATED':
                    if (sameProject && questionId === latestMessage.data.questionId) {
                        fetchQuestionDiscussion();
                    }
                    break;

                case 'ACTIVITY_CREATED':
                    if (sameProject && questionId === latestMessage.data.questionId) {
                        fetchQuestionActivity();
                    }
                    break;

                case 'NOTIFICATION_CREATED':
                    if (sameProject) {
                        const { wasMentioned, questionId } = latestMessage.data;

                        if (wasMentioned) {
                            partialPatch(
                                {
                                    loggedInUserMentioned: wasMentioned,
                                },
                                questionId,
                            );
                        }
                    }
                    break;

                default:
                //console.log('Unhandled event', lastMessage.eventName);
            }
        }
    }, [latestMessage]);

    useEffect(() => {
        setQuestionList({
            reset: true,
        });
    }, [projectId]);

    useEffect(() => {
        setupFiltersFromUrl(true);
    }, [projectId]);

    useEffect(() => {
        setupFiltersFromUrl(false);
    }, [questionId]);

    useEffect(() => {
        filterQuestionList();
    }, [questionList, filters, searchFilter]);

    useEffect(() => {
        calculateCompletedQuestions();
    }, [questionList]);

    useEffect(() => {
        fetchProjectData();
    }, [projectId]);

    useEffect(() => {
        if (questionId !== 'start') {
            fetchQuestionData();
        }
    }, [questionId]);

    useEffect(() => {
        if (questionId === 'start') {
            if (filteredQuestionList.length > 0) {
                const { questionId } = filteredQuestionList[0];

                navigate(
                    createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                        questionId: questionId,
                        projectId: projectId,
                    }),
                );
            }
        }
    }, [questionId, questionList, filteredQuestionList]);

    useEffect(() => {
        if (project && project.name) {
            document.title = `Pearler | ${project.name}`;
        }
    }, [project]);

    const question = _find(questionList, { questionId });

    // Data to share with subcomponents
    const sharedProperties = {
        questionId,
        question,
        projectId,
        project,
        questionList,
        questionActivity,
        questionDiscussion,
        projectTeam,
        completedQuestionCount,
        questionCount: questionList.length,
        filteredQuestionCount: filteredQuestionList.length,
        filteredQuestionList,
        filters,
        setFilters,
        searchFilter,
        clearFilter,
        setSearchFilter,
        setAnswerHasChanges,
        setCollaborationHasChanges,
        toggleCollaboration,
        collaborationOpen,
        setCollaboration,
        answerHasChanges,
        collaborationHasChanges,
        updateQuestionAssignee,
        showUnsavedChangesModal,
        fetchActivity: fetchQuestionActivity,
        fetchDiscussion: fetchQuestionDiscussion,
        createComment,
        savingComment,
        markQuestionComplete,
        markNotAQuestion,
        deleteQuestion,
        saveQuestion,
        savingChanges,
        patchQuestion,
        nextQuestion,
        nextIncompleteQuestion,
        previousQuestion,
        favouriteAnswers,
        fetchSubscription,
        userSubscribedToQuestion,
        fetchFavouriteAnswers,
        incomingChanges,
        discardIncomingChanges,
        applyIncomingChanges,
        fetchingDiscussion,
        fetchingHistory,
        projectClosed: project?.projectStatus === CONSTANTS.PROJECT_COMPLETE,
        updatingAssignee,
        setShowRewriteAnswer,
        setShowGenerateAnswer,
        showRewriteAnswer,
        showGenerateAnswer,
        collaborationTab,
        changeCollaborationTab,
    };

    return (
        <>
            {loadingProject ? (
                <Loader classes={`h-screen w-full flex items-center bg-cream justify-center`} />
            ) : (
                <div>
                    {sharedProperties.projectClosed && <Banner />}
                    <section
                        className="grid"
                        style={{
                            gridTemplateColumns: `356px auto`,
                            height: `calc(100vh - ${sharedProperties.projectClosed ? '32' : '0'}px)`,
                        }}
                    >
                        {loadingProject && loadingQuestion ? <Loader /> : <QuestionList {...sharedProperties} />}
                        {questionId !== 'start' && question && (
                            <section>
                                <LargeLayout
                                    AssigneeSelector={QuestionAssignee}
                                    PaginationControl={QuestionPagination}
                                    AnsweringSection={Answering}
                                    CollaborationPanel={CollaborationView}
                                    QuestionControls={QuestionControls}
                                    sharedProperties={sharedProperties}
                                />
                                {/* <SwitchLayout
                                    breakpoint={0} // 1680
                                    Small={() => {
                                        return <p>Test</p>;
                                    }}
                                    Large={() => {
                                        return (
                                            <LargeLayout
                                                AssigneeSelector={QuestionAssignee}
                                                PaginationControl={QuestionPagination}
                                                AnsweringSection={Answering}
                                                CollaborationPanel={CollaborationView}
                                                QuestionControls={QuestionControls}
                                                sharedProperties={sharedProperties}
                                            />
                                        );
                                    }}
                                /> */}
                            </section>
                        )}
                    </section>
                </div>
            )}
        </>
    );
};
