import React, { ReactElement, ReactNode } from 'react';

export const Modal = ({
    children,
    width,
    isOpen,
    parentClasses,
    id,
    containerPadding,
}: {
    children: ReactNode;
    width?: string;
    isOpen: boolean;
    parentClasses?: string;
    id?: string;
    containerPadding?: string;
}): ReactElement => {
    return (
        <div
            className={`fixed inset-0 z-10 overflow-y-auto transition-opacity duration-300 ${
                isOpen ? 'opacity-1' : 'pointer-events-none opacity-0'
            }`}
        >
            <div className="min-h-screen mx-auto flex items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
                <div className="fixed inset-0 transition-opacity">
                    <div className="absolute inset-0 bg-primary-blue-90-opace-4 opacity-75"></div>
                </div>
                <span className="hidden sm:inline-block sm:h-screen sm:align-middle"></span>
                <div
                    className={`${parentClasses} relative z-10 inline-block rounded-lg bg-white align-bottom ${
                        containerPadding ? containerPadding : 'px-40 py-28'
                    } sm:max-w-lg overflow-hidden text-left shadow-xl sm:my-8 sm:align-middle ${width}`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    id={id}
                >
                    <div>
                        <div className="mt-3 text-center sm:mt-5">{children}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};
