import { Inbox } from 'assets/inbox.svg';
import { Button } from 'components/duex/Button';
import { AddQuestionModal } from 'modules/add-question/add-question-modal';
import React, { ReactElement, useState } from 'react';
import { Question } from 'types';

export const EmptyQuestionList = ({
    projectId,
    patchQuestion,
}: {
    projectId: string;
    patchQuestion: (newQuestion: Question) => void;
}): ReactElement => {
    const [showingModal, setShowingModal] = useState(false);

    return (
        <div className="ml-40 mr-40 mt-70 flex flex-col items-center">
            <Inbox />
            <p className="tracking-0.1 mb-8 mt-30 select-none text-center text-16 font-medium leading-24">
                No Questions
            </p>
            <p className="mb-16 select-none text-center text-14 font-normal leading-22 text-black-60">
                The project is currently empty. Add some questions and they will appear here.
            </p>
            <Button label="Create Question" onClick={() => setShowingModal(true)} />
            {showingModal && (
                <AddQuestionModal
                    closeModal={() => {
                        setShowingModal(false);
                    }}
                    patchQuestion={patchQuestion}
                    projectId={projectId}
                />
            )}
        </div>
    );
};
