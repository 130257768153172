import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project, ProjectDashboard } from 'types';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { QuestionCompletionCard } from './question-completion-card.component';

export const CardsWrapper = ({
    dashboardData,
    project,
}: {
    dashboardData: ProjectDashboard;
    project: Project;
}): ReactElement => {
    const { incompleteQuestions, notCompliant, partiallyCompliant, compliant, notApplicable } = dashboardData;
    const navigate = useNavigate();

    const nonCompliantLabels = [];
    const partiallyCompliantLabels = [];
    const compliantLabels = [];
    const notApplicableLabels = [];

    if (project.complianceOverride) {
        const projectOverrides = project.complianceOverride[project.projectId];

        if (projectOverrides) {
            if (projectOverrides.nonCompliant) {
                nonCompliantLabels.push(projectOverrides.nonCompliant);
            }
            if (projectOverrides.partiallyCompliant) {
                partiallyCompliantLabels.push(projectOverrides.partiallyCompliant);
            }

            if (projectOverrides.compliant) {
                compliantLabels.push(projectOverrides.compliant);
            }
            if (projectOverrides.nonCompliant) {
                notApplicableLabels.push(projectOverrides.nonCompliant);
            }
        }

        if (project.complianceOverride.aliases) {
            project.complianceOverride.aliases.forEach((alias) => {
                if (alias.complianceType === CONSTANTS.COMPLIANT) {
                    nonCompliantLabels.push(alias.label);
                }
                if (alias.complianceType === CONSTANTS.PARTIALLYCOMPLIANT) {
                    partiallyCompliantLabels.push(alias.label);
                }
                if (alias.complianceType === CONSTANTS.COMPLIANT) {
                    compliantLabels.push(alias.label);
                }
                if (alias.complianceType === CONSTANTS.NOTAPPLICABLE) {
                    notApplicableLabels.push(alias.label);
                }
            });
        }
    }
    const cards = [
        {
            type: CONSTANTS.INCOMPLETE_STATUS,
            data: incompleteQuestions,
            heading: 'Incomplete Questions',
            linkText: 'View Incomplete',
            labels: [],
        },
        {
            type: CONSTANTS.NONCOMPLIANT,
            data: notCompliant,
            heading: 'Non Compliant',
            linkText: 'View Non Compliant',
            labels: nonCompliantLabels,
        },
        {
            type: CONSTANTS.PARTIALLYCOMPLIANT,
            data: partiallyCompliant,
            heading: 'Partially Compliant',
            linkText: 'View Partially Compliant',
            labels: partiallyCompliantLabels,
        },
        {
            type: CONSTANTS.COMPLIANT,
            data: compliant,
            heading: 'Compliant',
            linkText: 'View Compliant',
            labels: compliantLabels,
        },
        {
            type: CONSTANTS.NOTAPPLICABLE,
            data: notApplicable,
            heading: 'Not Applicable',
            linkText: 'View Not Applicable',
            labels: notApplicableLabels,
        },
    ];

    const callBack = (selectedFilter: string): void => {
        if (CONSTANTS.INCOMPLETE_STATUS === selectedFilter) {
            navigate(
                `${createUrl(ROUTES.PROJECT_QUESTION_ANSWERING_START, {
                    projectId: project.projectId,
                })}/?${CONSTANTS.INCOMPLETE_STATUS},${CONSTANTS.INPROGRESS}`,
            );
        } else {
            navigate(
                `${createUrl(ROUTES.PROJECT_QUESTION_ANSWERING_START, {
                    projectId: project.projectId,
                })}/?${selectedFilter}`,
            );
        }
    };

    return (
        <div className="mb-32">
            <ul className="flex justify-between">
                {cards.map((item, index) => (
                    <QuestionCompletionCard key={index} {...item} callBack={callBack} />
                ))}
            </ul>
        </div>
    );
};
