import { Button } from 'components/duex/Button';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Module } from 'components/duex/Module';
import SearchTextField from 'components/duex/SearchTextField';
import { Tabs } from 'components/duex/Tabs';
import { showError } from 'components/error-toast.component';
import { ColHeader, Row, StyledTable } from 'components/table';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import { NewProjectModal } from 'modules/new-project/new-project-modal';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Project } from 'types';
import { API } from 'utility/Api';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { PERMISSION } from 'utility/Permission';
import { ProjectListRow } from './project-row.component';

const initialTabs = [
    {
        label: CONSTANTS.OPEN_PROJECT,
        key: CONSTANTS.INPROGRESS,
        isActive: true,
    },
    {
        label: CONSTANTS.CLOSED_PROJECT,
        key: CONSTANTS.PROJECT_COMPLETE,
        isActive: false,
    },
];

export const ProjectList = (): ReactElement => {
    const { hasPermission } = useUserContext();
    const [showingNewProjectModal, setShowingNewProjectModal] = useState(false);
    const [fetchingProjects, setFetchingProjects] = useState(false);
    const [projectList, setProjectList] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const [tabs, updateTabState] = useState(initialTabs);

    const fetchProjects = async () => {
        try {
            setFetchingProjects(true);

            const result = await API.get(ENDPOINTS.getUrl(CONSTANTS.PROJECTS));

            if (result && result.data) {
                setProjectList(result.data);
            }

            setFetchingProjects(false);
        } catch (err) {
            setFetchingProjects(false);
            showError('There was an error fetching your projects', err);
        }
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    useEffect(() => {
        document.title = 'Pearler | Projects';
    }, []);

    useEffect(() => {
        // Check the URL and automatically open the Create Project modal
        const pathName = location.pathname;

        if (pathName === ROUTES.PROJECT_CREATE) {
            setShowingNewProjectModal(true);
        }
    }, [location]);

    const hasCreatePermission = hasPermission(PERMISSION.CREATE_PROJECT);

    const activeTab = _find(tabs, {
        isActive: true,
    });

    const filteredList = _filter(projectList, (project: Project) => {
        let match = true;

        if (searchTerm.trim()) {
            match = false;

            const lowerCaseSearchTerm = searchTerm.toLowerCase();

            const projectName = project.name.toLowerCase();

            if (projectName.indexOf(lowerCaseSearchTerm) !== -1) {
                match = true;
            }
        }

        return project.projectStatus === activeTab.key && match;
    });

    return (
        <Module
            moduleName="Projects"
            width={'w-full'}
            middleItem={
                <div className="mx-auto w-600">
                    <SearchTextField
                        applySearch={setSearchTerm}
                        placeholder="Search by Project Name"
                        debounceDuration={0}
                    />
                </div>
            }
            rightItem={
                hasCreatePermission ? (
                    <Button
                        onClick={() => {
                            setShowingNewProjectModal(true);
                        }}
                        className="mr-16"
                        label="New Project"
                    />
                ) : null
            }
        >
            {showingNewProjectModal && (
                <NewProjectModal
                    closeModal={() => {
                        fetchProjects();
                        setShowingNewProjectModal(false);
                        navigate(ROUTES.PROJECT_LIST);
                    }}
                />
            )}
            <div className="mx-16 mb-32">
                <div className="mb-32">
                    <Tabs
                        tabItems={tabs}
                        onChange={(selectedTab) => {
                            updateTabState(
                                _map(tabs, (tab) => {
                                    return {
                                        ...tab,
                                        isActive: selectedTab.label === tab.label,
                                    };
                                }),
                            );
                        }}
                    />
                </div>
                {!fetchingProjects && filteredList.length === 0 && (
                    <EmptyState
                        width="w-400"
                        className="mt-32"
                        title="No Projects found"
                        subtitle="You don't have any projects at the moment, but you can create one!"
                    >
                        <div className="mt-16 flex flex-row justify-center">
                            <Button
                                onClick={() => {
                                    setShowingNewProjectModal(true);
                                }}
                                label="New Project"
                            />
                        </div>
                    </EmptyState>
                )}
                {fetchingProjects && <LoadingSpinner />}
                {filteredList.length !== 0 && (
                    <StyledTable>
                        <>
                            <Row className="border-b border-gray-light-1 bg-white">
                                <ColHeader text="Project Type" className={`w-1-of-12`} />
                                <ColHeader text="Priority" className={`w-1-of-12`} />
                                <ColHeader text="Name" className={`w-3-of-12`} />
                                <ColHeader text="Progress" className={`w-1-of-12`} />
                                <ColHeader text="Status" className={`w-2-of-12`} />
                                <ColHeader text="Due Date" className={`w-2-of-12`} />
                                <ColHeader text="Contributors" className={`w-1-of-12`} />
                                <ColHeader text="" className={`w-1-of-12`} />
                            </Row>

                            {filteredList.map((project) => {
                                return <ProjectListRow key={project.projectId} project={project} />;
                            })}
                        </>
                    </StyledTable>
                )}
            </div>
        </Module>
    );
};
