// Copied from here: https://stackoverflow.com/questions/12360268/a-regex-to-remove-id-style-class-attributes-from-html-tags-in-js

const walk_the_DOM = function walk(node, func) {
    func(node);
    node = node.firstChild;
    while (node) {
        walk(node, func);
        node = node.nextSibling;
    }
};

export function cleanNode(innerHtml: string): string {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = innerHtml;

    walk_the_DOM(wrapper, function (element) {
        if (element.removeAttribute) {
            element.removeAttribute('id');
            element.removeAttribute('style');
            element.removeAttribute('class');
            element.removeAttribute('alt');
            element.removeAttribute('title');
        }
    });

    return wrapper.innerHTML;
}
