import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import { SettingsCheckbox, SettingsItem, SettingsTextArea } from 'modules/settings/settings-components';
import React, { ReactElement, useEffect, useState } from 'react';
import { Project } from 'types';
import Team from 'types/team.type';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const TrustCentreModal = ({
    closeModal,
    project,
}: {
    closeModal: () => void;
    project: Project;
}): ReactElement => {
    const [localSettings, setLocalSettings] = useState(project);
    const [teamSettings, setTeamSettings] = useState<Team>({});
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);

    const url = ENDPOINTS.getUrl(CONSTANTS.PROJECT_TRUST_CENTRE, {
        projectId: project.projectId,
    });

    const updateSettings = (propertyName, newValue) => {
        setLocalSettings({
            ...localSettings,
            [propertyName]: newValue,
        });
    };

    const properties = ['includeTrustLinksInExport', 'enableTrustCentre', 'projectAccessCode'];

    const fetchTeamSettings = async () => {
        try {
            setLoading(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_TEAM);
            const result = await API.get(url);

            if (result) {
                setTeamSettings(result.data);
            }
            setLoading(false);
        } catch (err) {
            showError('There was an error retrieving your team settings', err);
            setLoading(false);
        }
    };

    const saveChanges = async () => {
        try {
            setSaving(true);
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_PROJECT, {
                projectId: project.projectId,
            });

            const body = _pick(localSettings, properties);

            const response = await API.post(url, body);

            if (response && response.data) {
                logEvent(`PROJECT_UPDATE_TRUST_CENTRE`, started, body);
                setSaving(false);
                closeModal();
            }
        } catch (err) {
            setSaving(false);
            showError('There was a problem saving your changes', err);
        }
    };

    const undoChanges = () => {
        setLocalSettings(project);
    };

    useEffect(() => {
        fetchTeamSettings();
    }, []);

    const hasChanges = !_isEqual(_pick(project, properties), _pick(localSettings, properties));
    const ableToSave = teamSettings.trustCentreCodeLength <= localSettings.projectAccessCode.length;

    return (
        <Modal title="Trust Centre" closeModal={closeModal} width="w-600">
            <p className="mx-16 mb-16 text-gray-500">
                When the Project Trust Centre is enabled, your customers can access your Trust Centre to access
                documents you&apos;ve attached.
            </p>
            {loading && <LoadingSpinner />}
            {!loading && !teamSettings.enableTrustCentre && (
                <EmptyState
                    title="Trust Centre Disabled"
                    subtitle="The Trust Centre has been turned off for all projects. Check with your administrator for further information."
                />
            )}
            {!loading && (
                <>
                    {teamSettings.enableTrustCentre && localSettings.enableTrustCentre && (
                        <>
                            <Button
                                buttonType="LARGE_PRIMARY"
                                label="Launch Trust Centre"
                                onClick={() => {
                                    navigator.clipboard.writeText(project.projectAccessCode);
                                    showSuccess('Access Code copied to clipboard, Redirecting you now...');
                                    setTimeout(() => {
                                        window.open(url, '_blank');
                                    }, 1000);
                                }}
                            />
                            <p className="mb-4 mt-32 border-b pb-4 pl-16 text-gray-600">Settings</p>
                        </>
                    )}
                    {teamSettings.enableTrustCentre && (
                        <SettingsItem>
                            <SettingsCheckbox
                                description="Enable Trust Centre"
                                currentValue={localSettings.enableTrustCentre || false}
                                onChange={(newValue) => {
                                    updateSettings('enableTrustCentre', newValue);
                                }}
                            />
                        </SettingsItem>
                    )}

                    {teamSettings.enableTrustCentre && localSettings.enableTrustCentre && (
                        <>
                            <SettingsItem>
                                <SettingsCheckbox
                                    description="Include link to Trust Centre in Exports"
                                    currentValue={localSettings.includeTrustLinksInExport || false}
                                    onChange={(newValue) => {
                                        updateSettings('includeTrustLinksInExport', newValue);
                                    }}
                                />
                            </SettingsItem>
                            <SettingsItem>
                                <SettingsTextArea
                                    description="Access URL"
                                    currentValue={url}
                                    disabled={true}
                                    onChange={() => {
                                        // Do nothing
                                    }}
                                    placeholder="Project Access Code"
                                    dataCy="Access_URL"
                                />
                            </SettingsItem>
                            <SettingsItem>
                                <SettingsTextArea
                                    description={`Access Code (Min: ${teamSettings.trustCentreCodeLength})`}
                                    currentValue={localSettings.projectAccessCode || ''}
                                    onChange={(newValue: string) => {
                                        updateSettings('projectAccessCode', newValue);
                                    }}
                                    placeholder="Project Access Code"
                                    dataCy="Access_Code"
                                />
                            </SettingsItem>
                        </>
                    )}
                </>
            )}
            {teamSettings.enableTrustCentre && (
                <ButtonBar className="mx-16 mt-32">
                    <Button
                        label="Save Changes"
                        onClick={saveChanges}
                        loading={saving}
                        disabled={!hasChanges || saving || !ableToSave}
                    />
                    {hasChanges && (
                        <Button
                            buttonType="SECONDARY"
                            label="Undo Changes"
                            onClick={undoChanges}
                            disabled={!hasChanges || saving}
                        />
                    )}
                </ButtonBar>
            )}
        </Modal>
    );
};
