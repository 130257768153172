import { ChevronLeft } from 'assets/chevron-left.svg';
import React, { ReactElement } from 'react';
import { LoadingSpinner } from './LoadingSpinner';
import { testAutomation } from './TestAutomationAttribute';

const baseButtons = 'leading-20 font-500 outline-none rounded-md select-none outline-none';
const colouredButtons = 'px-24';
const outlineButtons = 'px-23 py-9 border';
const hover = 'hover:opacity-100';

const styles = {
    PRIMARY: `text-14 py-10 text-white bg-field-outline-blue border-field-outline-blue ${colouredButtons}`,
    LARGE_PRIMARY: `text-16 py-14 text-white bg-field-outline-blue border-field-outline-blue ${colouredButtons}`,
    SECONDARY: `text-14 text-primary-blue-100 bg-white border-primary-blue-100 ${outlineButtons}`,
    TERTIARY: `text-14 py-10 text-primary-blue-100 bg-white ${colouredButtons}`,
    BACK: `text-14 py-10 text-primary-blue-100 bg-white ${outlineButtons}`,
    DANGER: `text-14 py-10 text-white bg-red ${colouredButtons}`,
    SUCCESS: `text-14 py-10 text-white bg-green ${colouredButtons}`,
};

const spinnerColours = {
    PRIMARY: '#FFFFFF',
    LARGE_PRIMARY: '#FFFFFF',
    SECONDARY: '#2068E3',
    TERTIARY: '#2068E3',
    BACK: '#2068E3',
    DANGER: '#FFFFFF',
    SUCCESS: '#FFFFFF',
};

export function Button({
    className = '',
    buttonType = 'PRIMARY',
    onClick,
    disabled = false,
    loading = false,
    label,
    action = 'button',
    testId,
}: {
    className?: string;
    buttonType?: 'PRIMARY' | 'SECONDARY' | 'TERTIARY' | 'LARGE_PRIMARY' | 'DANGER' | 'SUCCESS' | 'BACK';
    onClick?: () => void;
    label: string;
    disabled?: boolean;
    loading?: boolean;
    action?: 'button' | 'submit';
    testId?: string;
}): ReactElement {
    return (
        <button
            className={`relative flex flex-row justify-center transition duration-200 ease-in ${styles[buttonType]} ${
                !disabled && buttonType === 'TERTIARY' ? 'hover:underline' : ''
            } ${className} ${baseButtons} ${disabled ? 'cursor-not-allowed opacity-50' : `opacity-90 ${hover}`} `}
            onClickCapture={() => {
                if (onClick) {
                    onClick();
                }
            }}
            disabled={disabled}
            type={action}
            data-cy={testAutomation(testId || label)}
        >
            {buttonType === 'BACK' && !loading && (
                <ChevronLeft strokeColour={spinnerColours[buttonType]} className="mt-4 mr-12" />
            )}
            <LoadingSpinner
                strokeColor={spinnerColours[buttonType]}
                className={`absolute w-full ${!loading ? 'invisible' : ''}`}
            />
            <span className={`${loading ? 'invisible' : ''}`}>{label}</span>
        </button>
    );
}
