import { BlueTick } from 'assets/blue-tick.svg';
import React, { ReactElement } from 'react';
import { testAutomation } from './TestAutomationAttribute';

interface MenuItem {
    label?: string;
    testId?: string;
    isDivider?: boolean;
    icon?: ReactElement;
    onClick?: () => void;
    isSelected?: boolean;
    isDisabled?: boolean;
    onDrag?: (event: React.DragEvent) => void;
}

export const Menu = ({ items }: { items: MenuItem[] }) => {
    return (
        <div className="bg-white shadow-lg rounded-md text-14 py-8 overflow-hidden border border-gray-50">
            {items.map((item, index) => {
                if (item.isDivider) {
                    return <hr key={index} className="my-8" />;
                }

                const segments = [];

                if (item.icon) {
                    segments.push('24px');
                }

                segments.push('auto');

                if (item.isSelected) {
                    segments.push('24px');
                }

                return (
                    <button
                        className={`flex flex-row gap-8 w-full ${
                            item.isSelected
                                ? 'bg-blue-50 text-blue-700'
                                : 'text-black-80 hover:bg-blue-50 hover:text-blue-700'
                        } ${item.isDisabled ? 'opacity-50 pointer-events-none' : ''}  px-16 py-8 items-center`}
                        style={{
                            gridTemplateColumns: segments.join(' '),
                        }}
                        key={index}
                        draggable={item.onDrag ? 'true' : 'false'}
                        data-cy={testAutomation(item.testId || item.label)}
                        onClick={(ev) => {
                            ev.stopPropagation();

                            if (item.onClick && !item.isDisabled) {
                                item.onClick();
                            }
                        }}
                        onMouseUp={(ev) => {
                            ev.stopPropagation();
                        }}
                        onDragStart={item.onDrag}
                    >
                        {item.icon && (
                            <div className="w-24 flex flex-row items-center justify-center align-middle">
                                {item.icon}
                            </div>
                        )}
                        <span className="flex-grow text-left">{item.label}</span>

                        {item.isSelected && <BlueTick strokeColor="currentColor" />}
                    </button>
                );
            })}
        </div>
    );
};
