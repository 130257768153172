import React, { ReactElement } from 'react';

export const Database = ({ strokeColor }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 5.73169V5C5 3.96447 5.83947 3.125 6.875 3.125H13.125C14.1605 3.125 15 3.96447 15 5V5.73169M5 5.73169C5.19549 5.6626 5.40585 5.625 5.625 5.625H14.375C14.5941 5.625 14.8045 5.6626 15 5.73169M5 5.73169C4.27175 5.98909 3.75 6.68361 3.75 7.5V8.23169M15 5.73169C15.7282 5.98909 16.25 6.68361 16.25 7.5V8.23169M16.25 8.23169C16.0545 8.1626 15.8441 8.125 15.625 8.125H4.375C4.15585 8.125 3.94549 8.1626 3.75 8.23169M16.25 8.23169C16.9782 8.48909 17.5 9.18361 17.5 10V15C17.5 16.0355 16.6605 16.875 15.625 16.875H4.375C3.33947 16.875 2.5 16.0355 2.5 15V10C2.5 9.18361 3.02175 8.48909 3.75 8.23169"
                stroke={strokeColor || 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
