import React, { ReactElement } from 'react';

export const NumberCircle = ({ strokeColor = 'currentColor' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.61479 7.90909V13H7.53844V8.93075H7.50861L6.34277 9.66158V8.70703L7.60307 7.90909H8.61479ZM10.1802 13.0646C10.0162 13.0646 9.8753 13.0066 9.75763 12.8906C9.64163 12.773 9.58363 12.6321 9.58363 12.468C9.58363 12.3056 9.64163 12.1664 9.75763 12.0504C9.8753 11.9344 10.0162 11.8764 10.1802 11.8764C10.3393 11.8764 10.4785 11.9344 10.5978 12.0504C10.7172 12.1664 10.7768 12.3056 10.7768 12.468C10.7768 12.5774 10.7486 12.6777 10.6923 12.7688C10.6376 12.8583 10.5655 12.9304 10.476 12.9851C10.3865 13.0381 10.2879 13.0646 10.1802 13.0646ZM13.2154 7.90909V13H12.139V8.93075H12.1092L10.9434 9.66158V8.70703L12.2037 7.90909H13.2154Z"
                fill={strokeColor}
            />
        </svg>
    );
};
