import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { isValidStringOfLength } from 'components/validation/string';
import React, { ReactElement, useState } from 'react';
import Collection from 'types/collection.type';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const EditCollectionModal = ({
    closeModal,
    collection,
}: {
    closeModal: () => void;
    collection: Collection;
}): ReactElement => {
    const [collectionName, setCollectionName] = useState(collection.collectionName);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const save = async () => {
        try {
            if (getSaveReadiness()) {
                const started = new Date();
                setIsSaving(true);

                const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_COLLECTION, {
                    collectionId: collection.collectionId,
                });
                const result = await API.post(url, {
                    collectionName,
                });

                logEvent(`ANSWER_LIBRARY_UPDATE_COLLECTION`, started);

                if (result.data) {
                    setIsSaving(false);
                    closeModal();
                }
            }
        } catch (err) {
            setIsSaving(false);
            showError('There was an error updating your collection', err);
        }
    };

    const deleteCollection = async () => {
        try {
            const started = new Date();
            setIsDeleting(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.DELETE_COLLECTION, {
                collectionId: collection.collectionId,
            });
            const result = await API.post(url);

            logEvent(`ANSWER_LIBRARY_DELETE_COLLECTION`, started);

            if (result.data) {
                setIsDeleting(false);
                closeModal();
            }
        } catch (err) {
            setIsDeleting(false);
            showError('There was an error deleting your collection', err);
        }
    };

    const getSaveReadiness = () => {
        return isValidStringOfLength(collectionName, 3);
    };

    return (
        <Modal
            width="w-600"
            closeModal={closeModal}
            title="Edit Collection"
            closeSafely={() => {
                return !collectionName;
            }}
        >
            <>
                <fieldset className="flex flex-col gap-16">
                    <FormTextField
                        placeholder="Enter your collection name here."
                        label="Collection Name"
                        errorMessage="Please provide a Collection Name"
                        validator={(currentValue) => {
                            return isValidStringOfLength(currentValue, 3);
                        }}
                        valueChanged={setCollectionName}
                        defaultValue={collectionName}
                        required={true}
                        spellCheck={true}
                        maxLength={256}
                    />
                </fieldset>
                <div className="grid grid-cols-2 mt-24">
                    <ButtonBar alignment="LEFT">
                        <Button
                            label="Delete"
                            onClick={deleteCollection}
                            buttonType="DANGER"
                            disabled={isDeleting || isSaving}
                            loading={isDeleting}
                        />
                    </ButtonBar>
                    <ButtonBar>
                        <Button
                            label="Cancel"
                            onClick={closeModal}
                            buttonType="SECONDARY"
                            disabled={isDeleting || isSaving}
                        />
                        <Button
                            label="Save Changes"
                            onClick={save}
                            disabled={!getSaveReadiness() || isDeleting || isSaving}
                            loading={isSaving}
                        />
                    </ButtonBar>
                </div>
            </>
        </Modal>
    );
};
