import { GenericDictionary } from 'types/generic-dictionary';

export const ROUTES = {
    HOME: '/',
    PROJECT_LIST: '/Projects',
    PROJECT_DASHBOARD_WELCOME: '/Projects/:projectId/welcome',
    PROJECT_DASHBOARD: '/Projects/:projectId',
    PROJECT_QUESTION_ANSWERING: '/Projects/:projectId/:questionId/*',
    PROJECT_QUESTION_ANSWERING_START: '/Projects/:projectId/start',
    PROJECT_CREATE: '/Projects/Create',
    NOTIFICATIONS: '/Notifications',
    PROFILE: '/Profile',
    ANSWER_LIB: '/Library',
    ANSWER_LIBRARY: '/Answers',
    SETTINGS: '/Settings',
    TEAM: '/Team',
    WELCOME: '/Welcome',
    INSIGHTS: '/Insights',
    FILES: '/Files',
    ONBOARDING_ACCOUNT_SET_UP: '/Signup/Account',
    ONBOARDING_TEAM_SET_UP: '/Signup/Teams',
    ONBOARDING_TEAM_CREATION: '/Signup/Teams/Create',
    COMPLETE_REGISTRATION: '/Signup/Complete',
    LOGIN: '/login',
    RESET_PASSWORD: '/reset',
    REGISTER: '/Signup',
    CONFIRM_REGISTRATION: '/Signup/Confirm',
    CUSTOM_LOGIN: '/login/:customerName',
    ASSSESMENTS_HOME: '/Assessments/',
    IMPORTER: '/Import',
    DOC_VIEW: '/Long_Form',
    SSO_REDIRECT: '/token',
    ACCOUNT_ADMIN: '/admin',
};

export const PUBLIC_ROUTES = [
    ROUTES.LOGIN,
    ROUTES.RESET_PASSWORD,
    ROUTES.REGISTER,
    ROUTES.CONFIRM_REGISTRATION,
    ROUTES.CUSTOM_LOGIN,
];

export const ONBOARDING_ROUTES = [
    ROUTES.PROFILE,
    ROUTES.ONBOARDING_ACCOUNT_SET_UP,
    ROUTES.ONBOARDING_TEAM_SET_UP,
    ROUTES.ONBOARDING_TEAM_CREATION,
    ROUTES.COMPLETE_REGISTRATION,
];

export const createUrl = (baseUrl: string, data: GenericDictionary): string => {
    const segments = baseUrl.split('/');

    return segments
        .map((segment) => {
            if (segment.startsWith(':')) {
                const dataIdentifier = segment.replace(':', '');

                if (!data[dataIdentifier]) {
                    throw new Error(`Data missing property '${dataIdentifier}'`);
                }

                return data[dataIdentifier];
            }

            return segment;
        })
        .join('/')
        .replace('*', '');
};
