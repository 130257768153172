import { ClosedEye, Eye } from 'assets/eye.svg';
import { Key } from 'assets/key.svg';
import { Mail } from 'assets/mail.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { isValidEmail, isValidStringOfLength } from 'components/validation/string';
import { useUserContext } from 'hooks/useUserContext';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import SSOHelper from 'utility/SSOHelper';

export const Login = (): ReactElement => {
    const navigate = useNavigate();
    const auth = useUserContext();

    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [showSpinner, toggleSpinner] = useState(false);

    const [password, setPassword] = useState('');
    const [showPassword, toggleShowPassword] = useState(false);

    const login = async () => {
        try {
            toast.dismiss();
            const cleanedEmail = email.trim().toLowerCase();
            toggleSpinner(true);

            const ssoResult = await SSOHelper.checkDomain(email);

            if (!ssoResult) {
                const { error, errorMessage } = await auth.signIn(cleanedEmail, password);
                toggleSpinner(false);

                if (error && errorMessage.code === CONSTANTS.USER_NOT_CONFIRMED_EXPECTION) {
                    navigate(`${ROUTES.CONFIRM_REGISTRATION}?email=${encodeURIComponent(cleanedEmail)}`);
                    await auth.resendConfirmationCode(cleanedEmail);
                } else if (error) {
                    console.log(errorMessage.message);
                    setErrorMsg('Please check your username and password');
                }
            }
        } catch (error) {
            toggleSpinner(false);
            console.error({ error });
        }
    };

    const formIsValid = isValidEmail(email) && isValidStringOfLength(password, 8);

    useEffect(() => {
        document.title = 'Pearler | Sign In';
    }, []);

    return (
        <div className="w-full">
            <form
                onSubmit={async (e) => {
                    e.preventDefault();
                    await login();
                }}
                className="grid gap-16"
            >
                <h1 className="h3-bold select-none text-center text-primary-blue-80">Sign in to your account</h1>

                {errorMsg && <p className={`subtitle-2-reg min-h-20 text-left text-red`}>{errorMsg}</p>}
                <fieldset className="grid gap-16">
                    <FormTextField
                        defaultValue={email}
                        label="Work Email"
                        validator={(value: string) => isValidEmail(value)}
                        valueChanged={(val: string) => {
                            setEmail(val);
                        }}
                        onBlur={() => {
                            SSOHelper.checkDomain(email);
                            setErrorMsg('');
                        }}
                        placeholder="Work Email"
                        autoComplete="email"
                        errorMessage="Please enter your work email address"
                        image={<Mail />}
                        maxLength={250}
                    />
                    <div className="relative">
                        <FormTextField
                            defaultValue={password}
                            inputType={showPassword ? 'text' : 'password'}
                            label="Password"
                            validator={(value: string) => isValidStringOfLength(value, 8)}
                            valueChanged={(val: string) => {
                                setPassword(val);
                                setErrorMsg('');
                            }}
                            placeholder="Password"
                            autoComplete="password"
                            errorMessage="Please enter your password"
                            image={<Key />}
                            maxLength={250}
                        />
                        <div
                            className="absolute right-20 top-36 cursor-pointer"
                            onClick={() => toggleShowPassword(!showPassword)}
                            title={showPassword ? 'Hide Password' : 'Reveal Password'}
                        >
                            {showPassword ? <Eye /> : <ClosedEye />}
                        </div>
                    </div>
                </fieldset>
                <div className="grid w-full gap-16">
                    <ButtonBar>
                        <Button
                            buttonType="LARGE_PRIMARY"
                            action="submit"
                            label="Sign In"
                            loading={showSpinner}
                            className="w-full"
                            disabled={!formIsValid || showSpinner}
                        />
                    </ButtonBar>
                    <ButtonBar className="grid w-full grid-cols-2">
                        <Button
                            buttonType="SECONDARY"
                            label="Create Account"
                            onClick={() => {
                                navigate(ROUTES.REGISTER);
                            }}
                        />
                        <Button
                            buttonType="SECONDARY"
                            label="Forgot Password"
                            onClick={() => {
                                navigate(ROUTES.RESET_PASSWORD);
                            }}
                        />
                    </ButtonBar>
                </div>
            </form>
        </div>
    );
};
