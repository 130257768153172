import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import React, { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';

export function DashboardWidget({
    title,
    isLoading,
    children,
    showMoreTitle,
    showMoreLink,
    drawBorder,
}: {
    title: string;
    isLoading: boolean;
    children: ReactNode;
    showMoreTitle?: string;
    showMoreLink?: string;
    drawBorder?: boolean;
}): ReactElement {
    return (
        <div className="">
            <div className="row flex justify-between">
                <span className="ml-8 text-14 text-gray-600">{title}</span>
                {showMoreTitle && (
                    <Link
                        to={showMoreLink}
                        className="mr-8 text-14 text-blue-700 underline opacity-80 hover:opacity-100"
                    >
                        {showMoreTitle}
                    </Link>
                )}
            </div>
            <div className="my-8">
                {isLoading && (
                    <div className="flex h-408 flex-col justify-center overflow-y-auto rounded-md border border-gray-100">
                        <LoadingSpinner className="mx-auto my-32" />
                    </div>
                )}
                {!isLoading && (
                    <div className={`${drawBorder ? ' overflow-y-auto rounded-md border border-gray-100' : ''} h-408`}>
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
}
