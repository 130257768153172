import { showError } from 'components/error-toast.component';
import { makeCancelable } from 'hooks/makeCancellable';
import { useTagContext } from 'hooks/useTagsContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import { WithOutContext as ReactTags } from 'react-tag-input';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const Tags = ({
    scopeIdentifier,
    textInputClasses = '',
    containerClasses = '',
    scope,
    readOnly = false,
}: {
    scopeIdentifier: string;
    scope: 'ANSWER' | 'QUESTION' | 'PROJECT';
    readOnly?: boolean;
    textInputClasses?: string;
    containerClasses?: string;
}): ReactElement => {
    const [tags, setTags] = useState([]);
    const { allTags: fullTagList, refreshTags } = useTagContext();

    useEffect(() => {
        if (scopeIdentifier) {
            const url = ENDPOINTS.getUrl('GET_TAGS_LIST', {
                resourceId: scopeIdentifier,
            });

            const { promise, cancel } = makeCancelable(API.get(url));

            promise.then((result) => {
                if (result && result.data) {
                    setTags(
                        _map(result.data, (tag) => {
                            return {
                                id: tag.tagLabel.replace(' ', ''),
                                text: tag.tagLabel,
                            };
                        }),
                    );
                }
            });

            return () => {
                cancel();
            };
        }
    }, [scopeIdentifier]);

    const handleDelete = async (i) => {
        try {
            const started = new Date();
            const originalTag = tags[i];

            setTags(
                _filter(tags, (tag) => {
                    return tag.id !== originalTag.id;
                }),
            );

            const url = ENDPOINTS.getUrl(CONSTANTS.REMOVE_TAG, {
                resourceId: scopeIdentifier,
            });

            await API.post(url, {
                tagLabel: originalTag.text,
            });

            logEvent(`${scope}_TAG_DELETE`, started, {
                resourceId: scopeIdentifier,
            });
        } catch (err) {
            showError('There was an error deleting that tag', err);
        }
    };

    const handleAddition = async (tag) => {
        try {
            const started = new Date();

            if (
                !_find(tags, {
                    tagLabel: tag.text,
                })
            ) {
                setTags([...tags, tag]);

                const url = ENDPOINTS.getUrl(CONSTANTS.ADD_TAG, {
                    resourceId: scopeIdentifier,
                });

                await API.post(url, {
                    tagLabel: tag.text,
                    resourceType: scope,
                });

                logEvent(`${scope}_TAG_ADD`, started, {
                    resourceId: scopeIdentifier,
                });

                refreshTags();
            }
        } catch (err) {
            showError('There was an error adding that tag', err);
        }
    };

    // Not all styles are able to applied via this approach, for li elements they needed CSS :(

    if (readOnly && tags.length === 0) {
        return null;
    }

    return (
        <div className={`${containerClasses} clear-both overflow-auto`}>
            <ReactTags
                tags={tags}
                suggestions={fullTagList}
                delimiters={delimiters}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                inputFieldPosition="bottom"
                autocomplete
                autofocus={false}
                placeholder="Type here to add tags"
                allowDragDrop={false}
                readOnly={readOnly}
                classNames={{
                    tags: 'w-full',
                    tagInput: 'float-left',
                    tagInputField: `rounded-full p-2 text-xs font-medium ${textInputClasses}`,
                    selected: 'flex flex-row w-full mb-4 flex-wrap',
                    tag: `${
                        readOnly ? 'py-4' : 'py-2'
                    } rounded-full small-1-semibold px-8 mr-4 mb-4 max-w-80 truncate text-primary-blue-80 bg-primary-blue-100-opace-1`,
                    remove: 'px-4 text-sm',
                    suggestions: 'bg-white rounded-md shadow-lg text-xs font-medium overflow-hidden my-4',
                    activeSuggestion: 'bg-primary-blue-100-opace-1 p-4',
                    // editTagInput: '',
                    // editTagInputField: '',
                    // clearAll: '',
                }}
            />
        </div>
    );
};
