import React, { ReactElement } from 'react';
export const Folder = ({ strokeColor = 'white' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 9C19 7.75736 17.9926 6.75 16.75 6.75H13C13 8.40685 11.6569 9.75 10 9.75C8.34315 9.75 7 8.40685 7 6.75H3.25C2.00736 6.75 1 7.75736 1 9M19 9V15C19 16.2426 17.9926 17.25 16.75 17.25H3.25C2.00736 17.25 1 16.2426 1 15V9M19 9V6M1 9V6M19 6C19 4.75736 17.9926 3.75 16.75 3.75H3.25C2.00736 3.75 1 4.75736 1 6M19 6V3C19 1.75736 17.9926 0.75 16.75 0.75H3.25C2.00736 0.75 1 1.75736 1 3V6"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
