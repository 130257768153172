import React, { ReactElement } from 'react';

export const ThinSingleChevron = ({ className = '' }: { className?: string }): ReactElement => {
    return (
        <svg
            width="13"
            height="13"
            viewBox="0 0 13 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.613961 11.6517C0.613961 12.0659 0.949748 12.4017 1.36396 12.4016L11.9706 12.4017C12.3848 12.4017 12.7206 12.0659 12.7206 11.6517C12.7206 11.2374 12.3848 10.9017 11.9706 10.9016L2.11396 10.9016L2.11396 1.04505C2.11396 0.630834 1.77818 0.295048 1.36396 0.295048C0.949748 0.295048 0.613961 0.630835 0.613962 1.04505L0.613961 11.6517Z"
                fill="#999"
            />
        </svg>
    );
};
