import { diffWords } from 'diff';
import React from 'react';

export const TextDiff = ({ textA, textB }: { textA: string; textB: string }) => {
    const diff = diffWords(textA || '', textB || '');

    return (
        <>
            {diff.map((part, index) => {
                return (
                    <span
                        key={index}
                        className={`${part.added ? 'bg-emerald-100' : part.removed ? 'bg-rose-100' : ''} p-1`}
                    >
                        {part.value}
                    </span>
                );
            })}
        </>
    );
};
