import { Module } from 'components/duex/Module';
import { Tabs } from 'components/duex/Tabs';
import { useTeamContext } from 'hooks/useTeamContext';
import { useUserContext } from 'hooks/useUserContext';
import _find from 'lodash/find';
import React, { ReactElement, useEffect, useState } from 'react';
import { CONSTANTS } from 'utility/Constants';
import { PERMISSION } from 'utility/Permission';
import { Divisions } from './divisions';
import { TeamSettings } from './team-settings';
import { UserAPISettings } from './user-api-settings';
import { UserSettings } from './user-settings';
const basicTabs = [
    {
        label: CONSTANTS.SETTINGS_MY,
        isActive: true,
        component: UserSettings,
    },
];

const teamSettings = {
    label: CONSTANTS.SETTINGS_TEAM,
    component: TeamSettings,
    isActive: false,
};

const divisionTab = {
    label: 'Divisions',
    component: Divisions,
    isActive: false,
};

const apiAccessTab = {
    label: 'API Access',
    component: UserAPISettings,
    isActive: false,
};

export const Settings = (): ReactElement => {
    const { hasPermission } = useUserContext();
    const { hasFeature } = useTeamContext();

    const isTeamAdmin = hasPermission(PERMISSION.UPDATE_TEAM);
    const divisionsEnabled = hasFeature('enableDivisions');
    const apiEnabled = hasFeature('enableApi');
    const [tabs, setTabs] = useState([]);

    const selectedTab = _find(tabs, { isActive: true });
    const CurrentComponent = selectedTab?.component;

    useEffect(() => {
        const tabs = [...basicTabs];

        if (isTeamAdmin) {
            tabs.push(teamSettings);

            if (divisionsEnabled) {
                tabs.push(divisionTab);
            }

            if (apiEnabled) {
                tabs.push(apiAccessTab);
            }
        }

        setTabs(tabs);
    }, [isTeamAdmin, divisionsEnabled, apiEnabled]);

    return (
        <Module moduleName="Settings">
            {selectedTab && (
                <div className="mx-16">
                    <Tabs
                        tabItems={tabs}
                        onChange={(selectedTab) => {
                            setTabs(
                                tabs.map((tab) => {
                                    return {
                                        ...tab,
                                        isActive: tab.label === selectedTab.label,
                                    };
                                }),
                            );
                        }}
                    />
                    <div className="py-32 max-w-1000">
                        <CurrentComponent />
                    </div>
                </div>
            )}
        </Module>
    );
};
