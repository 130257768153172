import React, { ReactElement } from 'react';

export const Notifications = (): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15 6.66675C15 5.34067 14.4732 4.0689 13.5355 3.13121C12.5979 2.19353 11.3261 1.66675 10 1.66675C8.67392 1.66675 7.40215 2.19353 6.46447 3.13121C5.52678 4.0689 5 5.34067 5 6.66675C5 12.5001 2.5 14.1667 2.5 14.1667H17.5C17.5 14.1667 15 12.5001 15 6.66675Z"
                stroke="#CDDBF4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.4417 17.5C11.2951 17.7526 11.0849 17.9622 10.8318 18.1079C10.5788 18.2537 10.292 18.3304 9.99999 18.3304C9.708 18.3304 9.42114 18.2537 9.16813 18.1079C8.91512 17.9622 8.70483 17.7526 8.55832 17.5"
                stroke="#CDDBF4"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
