import React, { ReactElement } from 'react';

export const SuccessTick = (): ReactElement => {
    return (
        <svg width="17" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6663 1L5.49967 10.1667L1.33301 6"
                stroke="#1AB759"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
