import { isValidString } from './string';

export const isValidUrl = (url: string): boolean => {
    if (isValidString(url)) {
        const parts = url.split('.');

        if (url.startsWith('http://www') || url.startsWith('https://www')) {
            return parts.length > 2;
        } else if (url.startsWith('http://') || url.startsWith('https://')) {
            return parts.length > 1;
        }
    }

    return false;
};
