import { BlueTick } from 'assets/blue-tick.svg';
import { Cross } from 'assets/cross.svg';
import { Dash } from 'assets/dash.svg';
import { FilterIcon } from 'assets/filter.svg';
import { Tick } from 'assets/tick.svg';
import { IconButton } from 'components/duex/IconButton';
import { Select } from 'components/duex/Select';
import { testAutomation } from 'components/duex/TestAutomationAttribute';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _first from 'lodash/first';
import _identity from 'lodash/identity';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import { QuestionListFilter } from 'modules/project/project.view';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Membership, Project, Question } from 'types';
import { CONSTANTS } from '../../../../utility/Constants';

interface Filter {
    value: string;
    key: string;
    showIcon: boolean;
    iconClass?: string;
    showLabel?: boolean;
    display?: boolean;
}
interface Section {
    sectionTitle: string;
    options: Filter[];
    display?: boolean;
}

function getUniqueKeys(questionList: Question[], key: string): string[] {
    const rawList = _uniqBy(questionList, key);
    const refinedList = _map(rawList, key);
    return _filter(refinedList, _identity);
}

const FilterMenu = ({
    applyFilters,
    currentFilters,
    isOpen,
    teamList,
    project,
    questionList,
}: {
    applyFilters: (filters: QuestionListFilter[]) => void;
    currentFilters: QuestionListFilter[];
    isOpen: boolean;
    teamList: Membership[];
    project: Project;
    questionList;
}): ReactElement => {
    const toggleFilterSelection = (filter: QuestionListFilter) => {
        if (
            _findIndex(currentFilters, {
                key: filter.key,
            }) !== -1
        ) {
            applyFilters(
                currentFilters.filter(({ key }) => {
                    return key !== filter.key;
                }),
            );
        } else {
            // Adding filter
            applyFilters([...currentFilters, filter]);
        }
    };

    const updateFilterSelection = (filterKey) => {
        return (newSelection) => {
            if (!newSelection) {
                applyFilters(
                    currentFilters.filter(({ key }) => {
                        return key !== filterKey;
                    }),
                );
            } else {
                const baseFilters = currentFilters.filter(({ key }) => {
                    return key !== filterKey;
                });

                applyFilters([
                    ...baseFilters,
                    {
                        key: filterKey,
                        data: [newSelection],
                    },
                ]);
            }
        };
    };

    const documents = _orderBy(
        _map(getUniqueKeys(questionList, 'projectSourceId'), (projectSourceId: string) => {
            const document = _find(project.sources, {
                projectSourceId,
            });

            return {
                value: projectSourceId,
                label: document.fileName,
            };
        }),
        'label',
    );

    const sheets = _orderBy(
        _map(getUniqueKeys(questionList, 'sheetId'), (sheetId: string) => {
            const sheet = _find(project.sheets, {
                sheetId,
            });

            return {
                value: sheetId,
                label: sheet?.sheetName,
            };
        }),
        'label',
    );

    const sections = _orderBy(
        _map(getUniqueKeys(questionList, 'sectionDescription'), (sectionDescription: string) => {
            return {
                value: sectionDescription,
                label: sectionDescription,
            };
        }),
        'label',
    );

    const assignees = _orderBy(
        _map(getUniqueKeys(questionList, 'assignedToUserId'), (userId: string) => {
            const user = _find(teamList, {
                userId,
            });

            return {
                value: userId,
                label: user?.member?.fullName,
            };
        }),
        'label',
    );

    const rawTags = [];

    questionList.forEach((question) => {
        if (question.tags) {
            question.tags.forEach((tag: string) => {
                if (rawTags.indexOf(tag) === -1) {
                    rawTags.push(tag);
                }
            });
        }
    });

    const tags = rawTags.sort();

    const questionListFilters: Section[] = [
        {
            sectionTitle: CONSTANTS.STATUS,
            options: [
                {
                    value: 'Complete',
                    key: CONSTANTS.COMPLETE,
                    showIcon: true,
                    iconClass: 'bg-field-outline-blue',
                },
                {
                    value: 'In Progress',
                    key: CONSTANTS.INPROGRESS,
                    showIcon: true,
                    iconClass: 'bg-faded-blue',
                },
                {
                    value: 'Incomplete',
                    key: CONSTANTS.INCOMPLETE_STATUS,
                    showIcon: true,
                    iconClass: 'bg-faded-gray',
                },
            ],
        },
        {
            sectionTitle: CONSTANTS.ASSIGNEE,
            options: [
                {
                    value: 'Assigned to me',
                    key: CONSTANTS.ASSIGNEDTOME,
                    showIcon: false,
                },
                {
                    value: 'Unassigned',
                    key: CONSTANTS.UNASSIGNED,
                    showIcon: false,
                },
                {
                    value: 'Assigned to others',
                    key: CONSTANTS.ASSIGNEDTOELSE,
                    showIcon: false,
                },
                {
                    value: 'Assigned to',
                    key: CONSTANTS.ASSIGNED_TO_SPECIFIC,
                    showIcon: false,
                    showLabel: false,
                    display: assignees.length > 0,
                },
            ],
        },
        {
            sectionTitle: CONSTANTS.COMPLIANCE,
            options: [
                {
                    value: 'Compliant',
                    key: CONSTANTS.COMPLIANT,
                    showIcon: true,
                    iconClass: 'bg-success-dark',
                },
                {
                    value: 'Partially Compliant',
                    key: CONSTANTS.PARTIALLYCOMPLIANT,
                    showIcon: true,
                    iconClass: 'bg-warning-dark',
                },
                {
                    value: 'Non Compliant',
                    key: CONSTANTS.NONCOMPLIANT,
                    showIcon: true,
                    iconClass: 'bg-red',
                },
                {
                    value: 'Not Applicable',
                    key: CONSTANTS.NOTAPPLICABLE,
                    showIcon: true,
                    iconClass: 'bg-black-60',
                },
                {
                    value: 'Not Specified',
                    key: CONSTANTS.INCOMPLETE_COMPLIANCE,
                    showIcon: true,
                    iconClass: 'bg-faded-gray',
                },
            ],
        },
        {
            sectionTitle: 'Source',
            options: [
                {
                    value: 'Document',
                    key: CONSTANTS.FILTER_DOCUMENT,
                    showIcon: false,
                    showLabel: false,
                    display: documents.length > 0,
                },
                {
                    value: 'Sheet',
                    key: CONSTANTS.FILTER_SHEET,
                    showIcon: false,
                    showLabel: false,
                    display: sheets.length > 0,
                },
                {
                    value: 'Section',
                    key: CONSTANTS.FILTER_SECTION,
                    showIcon: false,
                    showLabel: false,
                    display: sections.length > 0,
                },
            ],
        },
        {
            sectionTitle: 'Collaboration',
            options: [
                {
                    value: "Discussion where I'm mentioned",
                    key: CONSTANTS.IM_MENTIONED,
                    showIcon: false,
                },
            ],
        },
        {
            sectionTitle: 'Tags',
            options: [
                {
                    value: 'Tags',
                    key: CONSTANTS.HAS_TAG,
                    showIcon: false,
                    showLabel: false,
                },
            ],
            display: tags.length > 0,
        },
    ];

    return (
        <aside
            className={`relative z-5 transition-opacity duration-300 ${
                isOpen ? 'opacity-1 animate-grow-side-dropdown-animation' : 'pointer-events-none opacity-0'
            }`}
        >
            <div className="absolute left-24 z-3 mt-2 w-600 rounded-md shadow-lg" style={{ top: '-80px' }}>
                <div
                    className="shadow-xs rounded-md bg-white grid grid-cols-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="options-menu"
                >
                    {questionListFilters.map(({ sectionTitle, options, display }) => {
                        if (display !== undefined && !display) {
                            return null;
                        }
                        return (
                            <div key={sectionTitle}>
                                <div className="flex items-center px-16 py-8 text-12 leading-16 text-light-gray">
                                    <span className="select-none">{sectionTitle}</span>
                                    <p className="ml-4 inline-block w-full border-t border-gray-light-1"></p>
                                </div>
                                <ul>
                                    {options.map((filter) => {
                                        if (filter.display !== undefined && !filter.display) {
                                            return null;
                                        }

                                        const isSelected =
                                            _findIndex(currentFilters, {
                                                key: filter.key,
                                            }) !== -1;

                                        const filterData = _find(currentFilters, {
                                            key: filter.key,
                                        })?.data;

                                        return (
                                            <li
                                                onClick={() => {
                                                    if (filter.showLabel !== false) {
                                                        // Quick hack to avoid filtering applying when other elements being clicked eg dropdowns
                                                        toggleFilterSelection(filter);
                                                    }
                                                }}
                                                className={`grid gap-16 cursor-pointer rounded-sm select-none items-center mx-8 my-4 px-8 py-4 text-light-black hover:bg-faded-gray hover:text-field-outline-blue group
                                ${isSelected ? 'bg-field-outline-blue/10' : ''}`}
                                                key={filter.key}
                                                style={{
                                                    gridTemplateColumns: filter.showIcon ? '16px auto' : 'auto',
                                                }}
                                            >
                                                {filter.showIcon && (
                                                    <div
                                                        className={`flex h-16 w-17 items-center justify-center rounded-full ${filter.iconClass}`}
                                                    >
                                                        {filter.key === CONSTANTS.COMPLETE && <Tick />}
                                                        {filter.key === CONSTANTS.INPROGRESS && <Dash />}
                                                    </div>
                                                )}
                                                <div
                                                    className="leading-18 flex w-full items-center justify-between text-14 overflow-hidden min-h-32"
                                                    data-cy={testAutomation(filter.value)}
                                                >
                                                    <div>
                                                        {filter.showLabel !== false && <span>{filter.value}</span>}

                                                        {filter.key === 'HAS_TAG' && (
                                                            <Select
                                                                width="w-232"
                                                                placeholder="Select Tag"
                                                                onChange={updateFilterSelection('HAS_TAG')}
                                                                value={_first(filterData)}
                                                                items={_map(tags, (tag) => {
                                                                    return {
                                                                        label: tag,
                                                                        value: tag,
                                                                    };
                                                                })}
                                                            />
                                                        )}
                                                        {filter.key === CONSTANTS.ASSIGNED_TO_SPECIFIC && (
                                                            <Select
                                                                width="w-232"
                                                                placeholder="Select Assignee"
                                                                onChange={updateFilterSelection(
                                                                    CONSTANTS.ASSIGNED_TO_SPECIFIC,
                                                                )}
                                                                value={_first(filterData)}
                                                                items={assignees}
                                                            />
                                                        )}
                                                        {filter.key === CONSTANTS.FILTER_DOCUMENT && (
                                                            <Select
                                                                width="w-232"
                                                                placeholder="Select Document"
                                                                onChange={updateFilterSelection(
                                                                    CONSTANTS.FILTER_DOCUMENT,
                                                                )}
                                                                value={_first(filterData)}
                                                                items={documents}
                                                            />
                                                        )}
                                                        {filter.key === CONSTANTS.FILTER_SHEET && (
                                                            <Select
                                                                width="w-232"
                                                                placeholder="Select Sheet"
                                                                onChange={updateFilterSelection(CONSTANTS.FILTER_SHEET)}
                                                                value={_first(filterData)}
                                                                items={sheets}
                                                            />
                                                        )}
                                                        {filter.key === CONSTANTS.FILTER_SECTION && (
                                                            <Select
                                                                width="w-232"
                                                                placeholder="Select Section"
                                                                onChange={updateFilterSelection(
                                                                    CONSTANTS.FILTER_SECTION,
                                                                )}
                                                                value={_first(filterData)}
                                                                items={sections}
                                                            />
                                                        )}
                                                    </div>

                                                    {isSelected && (
                                                        <div>
                                                            <button
                                                                data-cy="Tick_Exists"
                                                                title="Click to clear this filter"
                                                                className="group-hover:hidden h-32 w-32 flex cursor-pointer items-center justify-center"
                                                            >
                                                                <BlueTick />
                                                            </button>
                                                            <IconButton
                                                                Icon={Cross}
                                                                title="Clear filter"
                                                                className="group-hover:flex hidden"
                                                                onClick={() => {
                                                                    toggleFilterSelection(filter);
                                                                }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                </div>
            </div>
        </aside>
    );
};

export default function Filter({
    applyFilters,
    currentFilters,
    teamList,
    project,
    questionList,
}: {
    applyFilters: (filters: QuestionListFilter[]) => void;
    currentFilters: QuestionListFilter[];
    teamList: Membership[];
    project: Project;
    questionList: Question[];
}): ReactElement {
    const [showMenu, toggleMenu] = useState(false);

    const wrapperRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                try {
                    let depth = 0;
                    let parentElement = event.target;
                    let ignoreClick = false;

                    while (depth < 4) {
                        if (parentElement.classList.contains('pearler-suggestions')) {
                            ignoreClick = true;
                            break;
                        } else {
                            depth = depth + 1;
                            parentElement = parentElement.parentElement;
                        }
                    }

                    if (!ignoreClick) {
                        toggleMenu(false);
                    }
                } catch (err) {
                    console.log(err);
                    toggleMenu(false);
                }
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    return (
        <div ref={wrapperRef}>
            <div
                className="cursor-pointer"
                onClick={() => {
                    toggleMenu(!showMenu);
                }}
            >
                <FilterIcon />
            </div>
            <FilterMenu
                applyFilters={applyFilters}
                currentFilters={currentFilters}
                isOpen={showMenu}
                teamList={teamList}
                project={project}
                questionList={questionList}
            />
        </div>
    );
}
