import { FlaggedForReview } from 'assets/flagged-for-review.svg';
import { FrequentlyUsed } from 'assets/frequently-used.svg';
import { Avatar } from 'components/duex/Avatar';
import { TagList } from 'components/duex/TagList';
import { GREEN, LIGHTYELLOW, Pill, RED, YELLOW } from 'components/pill.component';
import { useUserContext } from 'hooks/useUserContext';
import _map from 'lodash/map';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { Answer } from 'types';
import { ButtonDropDown } from '../../../../components/button-drop-down.component';
import { Modal } from '../../../../components/modal.component';
import { CONSTANTS } from '../../../../utility/Constants';
interface SuggestedAnswersListItemProps {
    answer: Answer;
    suggestionDropownOptions;
    projectStatus: string;
    applyAnswer: (answer: Answer) => void;
    hideAnswer: (answer: Answer) => void;
    undoHideAnswer: (answer: Answer) => void;
    flagAnswer: (answer: Answer, reason: string) => void;
    hiddenAnswers: string[];
}

export const SuggestedAnswersListItem = ({
    answer,
    suggestionDropownOptions,
    projectStatus,
    applyAnswer,
    hideAnswer,
    undoHideAnswer,
    flagAnswer,
    hiddenAnswers,
}: SuggestedAnswersListItemProps): ReactElement => {
    const [shouldShowList, toggleList] = useState(false);
    const [isModalOpen, toggleModalSate] = useState(false);
    const [flaggedReasonOption, setFlaggedReasonOption] = useState(CONSTANTS.FLAG_INCORRECT_INFORMATION);
    const [flaggedReason, setFlaggedReason] = useState('');
    const [showError, toggleErrorStatus] = useState(false);
    const { getUser } = useUserContext();

    const dropDownCallBack = (selectedOption) => {
        switch (selectedOption) {
            case CONSTANTS.USE_THIS_ANSWER:
                applyAnswer(answer);
                break;
            case CONSTANTS.FLAG_ANSWER:
                toggleModalSate(!isModalOpen);
                break;
            case CONSTANTS.HIDE_ANSWER:
                hideAnswer(answer);
                break;
            default:
                break;
        }
    };

    const author = getUser(answer.createdByUserId);

    return (
        <>
            {hiddenAnswers.indexOf(answer.answerId) !== -1 ? (
                <li className="tracking00.1 relative m-auto mb-24 flex h-56 w-592 select-none content-center justify-between rounded-lg bg-gray-light-1 px-32 pt-16 text-16 font-medium leading-24 text-light-gray">
                    {`You hid ${author.fullName}'s answer`}
                    <button
                        className="flex content-center text-center text-light-gray focus:outline-none"
                        onClick={() => undoHideAnswer(answer)}
                    >
                        Undo
                    </button>
                </li>
            ) : (
                <li
                    key={answer.answerId}
                    className="rounded relative m-auto mb-24 w-592 rounded-lg bg-white font-semibold shadow-md"
                >
                    <div className="px-32 py-16">
                        <div className="flex-start flex justify-between">
                            <div>
                                <div>
                                    {answer.frequentlyUsed ? (
                                        <span className="tracking-0.2 inline-flex select-none rounded-lg bg-success-lighter px-8 py-4 text-12 font-medium leading-16 text-success-dark">
                                            <FrequentlyUsed />
                                            Frequently Used
                                        </span>
                                    ) : null}
                                    {answer.answerStatus === CONSTANTS.FLAGGED ? (
                                        <span className="tracking-0.2 ml-8 inline-flex select-none rounded-lg bg-warning-lighter px-8 py-4 text-12 font-medium leading-16 text-warning-dark">
                                            <FlaggedForReview />
                                            Flagged for Review
                                        </span>
                                    ) : null}
                                </div>

                                <div>
                                    <div
                                        className={`${
                                            answer.frequentlyUsed || answer.answerStatus === CONSTANTS.FLAGGED
                                                ? 'mt-20 flex items-center'
                                                : 'flex items-center'
                                        }`}
                                    >
                                        <div>
                                            <Avatar size={32} userId={answer.createdByUserId} />
                                        </div>
                                        <div className="tracking-0.2 ml-10 select-none truncate text-18 font-medium leading-24 text-black">
                                            <p className="w-296 truncate">{author.fullName}</p>

                                            <p className="tracking-0.2 select-none truncate text-12 font-medium leading-16 text-light-gray">
                                                Last updated on {moment(answer.updatedAt).format('Do MMM YYYY')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {projectStatus !== CONSTANTS.PROJECT_COMPLETE && (
                                <div>
                                    <ButtonDropDown
                                        buttonOption={suggestionDropownOptions[0] || {}}
                                        dropdownOptions={suggestionDropownOptions.slice(1)}
                                        callBack={dropDownCallBack}
                                    />
                                </div>
                            )}
                        </div>

                        <p
                            data-cy="Suggested_Answer"
                            className="tracking-0.1 mt-16 text-14 font-normal leading-20 text-black render-html-normally"
                            dangerouslySetInnerHTML={{
                                __html: answer.richTextAnswer,
                            }}
                        />
                        {(answer.tags?.length > 0 || answer.collections?.length > 0) && (
                            <div className="flex flex-row-reverse mt-8 flex-wrap">
                                {answer.collections.length > 0 && (
                                    <TagList
                                        tags={_map(answer.collections, ({ collection }) => {
                                            return {
                                                tagLabel: collection.collectionName,
                                            };
                                        })}
                                        colour={LIGHTYELLOW}
                                    />
                                )}
                                {answer.tags.length > 0 && <TagList tags={answer.tags} />}
                            </div>
                        )}
                    </div>
                    <div
                        className={`${
                            answer.questions && answer.questions.length ? 'border-faded-gray-1 border-b' : null
                        }`}
                    ></div>
                    <div className={`${answer.questions && answer.questions.length ? 'px-32 py-16' : null}`}>
                        {answer.questions && answer.questions.length ? (
                            <div
                                onClick={() => toggleList(!shouldShowList)}
                                className={`${
                                    shouldShowList
                                        ? 'flex-no-wrap tracking-0.2 flex cursor-pointer select-none text-center text-14 font-medium leading-20 text-field-outline-blue'
                                        : ' tracking-0.2 text-gray flex cursor-pointer select-none text-center text-14 font-medium leading-20 active:text-field-outline-blue'
                                }`}
                            >
                                See questions where this answer applied.
                                <div className="flex-end inline-flex">
                                    <svg className="ml-245 h-20 w-20" viewBox="0 0 20 20" fill="currentColor">
                                        <path
                                            fillRule="evenodd"
                                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </div>
                            </div>
                        ) : null}

                        {shouldShowList && (
                            <ul>
                                {answer.questions.map((question, index) => (
                                    <li
                                        className={`tracking-0.1 pb-24 pt-24 text-14 font-normal leading-20 text-light-black ${
                                            index === answer.questions.length - 1 ? '' : 'border-b'
                                        } border-gray-light-1`}
                                        key={index}
                                    >
                                        <div className="mb-8 text-right">
                                            {question.responseClassification === 'COMPLIANT' && (
                                                <Pill
                                                    title="Compliant"
                                                    alt="The answer above was considered Compliant for this question"
                                                    colour={GREEN}
                                                />
                                            )}
                                            {question.responseClassification === 'PARTIALLY-COMPLIANT' && (
                                                <Pill
                                                    title="Partially Compliant"
                                                    alt="The answer above was considered Partially Compliant for this question"
                                                    colour={YELLOW}
                                                />
                                            )}
                                            {question.responseClassification === 'NON-COMPLIANT' && (
                                                <Pill
                                                    title="Non Compliant"
                                                    alt="The answer above was considered Non Compliant for this question"
                                                    colour={RED}
                                                />
                                            )}
                                        </div>
                                        <pre className="select-none whitespace-pre-line break-words font-body">
                                            {question.question}
                                        </pre>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <Modal width="385" isOpen={isModalOpen}>
                            <div className="">
                                <h2 className="tracking-0.2 select-none text-left text-20 font-medium leading-28 text-black">
                                    Flag Suggested Answer
                                </h2>
                                <p className="tracking-0.1 mt-12 select-none text-left text-14 font-normal leading-20 text-light-black">
                                    Why should this answer be reviewed?
                                </p>
                                <ul className="mt-24 text-left">
                                    <li className="align-center mb-16 flex">
                                        <input
                                            className="form-radio h-20 w-20"
                                            type="radio"
                                            id="incorrectInfo"
                                            onChange={() => {
                                                setFlaggedReasonOption(CONSTANTS.FLAG_INCORRECT_INFORMATION);
                                                toggleErrorStatus(false);
                                            }}
                                            checked={flaggedReasonOption === CONSTANTS.FLAG_INCORRECT_INFORMATION}
                                        />
                                        <label
                                            className="tracking-0.1 ml-10 select-none content-start text-14 font-normal leading-20 text-black"
                                            htmlFor="incorrectInfo"
                                        >{`${CONSTANTS.FLAG_INCORRECT_INFORMATION}`}</label>
                                    </li>
                                    <li className="align-center mb-16 flex">
                                        <input
                                            type="radio"
                                            id="outdatedInfo"
                                            onChange={() => {
                                                setFlaggedReasonOption(CONSTANTS.FLAG_OUTDATED_INFORMATION);
                                                toggleErrorStatus(false);
                                            }}
                                            checked={flaggedReasonOption === CONSTANTS.FLAG_OUTDATED_INFORMATION}
                                            className="form-radio h-20 w-20"
                                        />
                                        <label
                                            className="tracking-0.1 ml-10 select-none text-left text-14 font-normal leading-20 text-black"
                                            htmlFor="outdatedInfo"
                                        >{`${CONSTANTS.FLAG_OUTDATED_INFORMATION}`}</label>
                                    </li>
                                    <li className="align-center flex">
                                        <input
                                            type="radio"
                                            id="otherReason"
                                            onChange={() => setFlaggedReasonOption(CONSTANTS.FLAG_OTHER_REASON)}
                                            checked={flaggedReasonOption === CONSTANTS.FLAG_OTHER_REASON}
                                            className="form-radio h-20 w-20"
                                        />
                                        <label
                                            className="tracking-0.1 ml-10 select-none text-left text-14 font-normal leading-20 text-black"
                                            htmlFor="otherReason"
                                        >{`${CONSTANTS.FLAG_OTHER_REASON}`}</label>
                                    </li>
                                </ul>
                                <input
                                    className="mt-20 flex w-304 focus:outline-none"
                                    type="text"
                                    autoComplete="off"
                                    value={flaggedReason}
                                    placeholder={`Give it to us straight, what's going on?`}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setFlaggedReason(e.target.value);
                                        if (e.target.value) toggleErrorStatus(false);
                                        if (flaggedReasonOption === CONSTANTS.FLAG_OTHER_REASON && !e.target.value)
                                            toggleErrorStatus(true);
                                    }}
                                />
                                <div
                                    className={`border-b-2 ${
                                        showError ? 'border-red' : 'border-field-outline-blue'
                                    } mt-5 flex w-304 active:border-b active:border-field-outline-blue`}
                                ></div>
                                {showError && (
                                    <p className="subtitle-2-reg mt-8 select-none text-left text-red">
                                        Please don&apos;t leave it up to us.
                                        <span role="img" aria-labelledby="Sad Face">
                                            😟
                                        </span>{' '}
                                        <span role="img" aria-labelledby="Scared Face">
                                            🔮
                                        </span>
                                    </p>
                                )}
                                <div className="mt-50 text-right">
                                    <button
                                        className="tracking-0.2 border-1 border-bg-black-60 h-40 w-99 select-none rounded-lg border px-28 py-10 text-14 font-medium leading-20 text-black-60"
                                        onClick={() => toggleModalSate(!isModalOpen)}
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        className="tracking-0.2 border-1 ml-16 h-40 w-96 select-none rounded-lg border bg-field-outline-blue px-28 py-10 text-14 font-medium leading-20 text-white"
                                        onClick={() => {
                                            if (flaggedReasonOption === CONSTANTS.FLAG_OTHER_REASON && !flaggedReason) {
                                                toggleErrorStatus(true);
                                            } else {
                                                flagAnswer(
                                                    answer,
                                                    flaggedReasonOption === CONSTANTS.FLAG_OTHER_REASON
                                                        ? flaggedReason
                                                        : flaggedReasonOption,
                                                );
                                                toggleModalSate(!isModalOpen);
                                            }
                                        }}
                                    >
                                        Flag
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </li>
            )}
        </>
    );
};
