import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { APIGuide, SectionTitle, SettingsSection } from './settings-components';

interface ApiKey {
    teamId?: string;
    apiKey?: string;
}

export const UserAPISettings = (): ReactElement => {
    const [loading, setLoading] = useState(false);
    const [apiAccess, setApiAccess] = useState<ApiKey>({});

    const fetchAccess = async () => {
        try {
            setLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.GET_API_ACCESS);
            const result = await API.get(url);

            if (result && result.data) {
                setApiAccess(result.data);
            }

            setLoading(false);
        } catch (err) {
            // Don't show error, as the API currently returns an error if the user doesn't have an ApiKey.
            // This is not perfect, but easier fix than changing the return payload and adjusting logic in this right now.
            // TODO - change backend logic to return a 'Not generated' message, and front end to interpret correctly.
            // showError('There was an error retrieving your API Key', err);
            setLoading(false);
        }
    };

    const generateAccess = async () => {
        try {
            const started = new Date();
            setLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.GENERATE_API_ACCESS);
            const result = await API.post(url, {});

            if (result && result.data) {
                setApiAccess(result.data);
            }

            logEvent(`USER_API_KEY_GENERATED`, started);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            showError('There was an error generating your access key', err);
        }
    };

    useEffect(() => {
        fetchAccess();
    }, []);

    useEffect(() => {
        document.title = 'Pearler | API Access';
    }, []);

    const copyToClipboard = (fieldDescription: string, value = '') => {
        navigator.clipboard.writeText(value.trim());
        showSuccess(`${fieldDescription} copied to clipboard`);
    };

    const CopyableText = ({ className, label, value }: { className: string; label: string; value: string }) => {
        return (
            <p
                onClick={() => {
                    copyToClipboard(label, value);
                }}
                className={`${className} cursor-pointer rounded-md px-8 py-4 text-14 hover:bg-blue-50`}
            >
                {value}
            </p>
        );
    };

    return (
        <div>
            <SectionTitle title="API Access" />

            {loading && <LoadingSpinner className="pt-32" />}

            {!loading && (
                <>
                    <SettingsSection title="Your API key gives access to your account and all of your data. Private projects will be accessible." />
                    {apiAccess.apiKey && (
                        <div className="m-16 rounded-md bg-gray-50 p-16">
                            <h3 className="mb-16 text-14 uppercase text-gray-500">Authentication Headers (Required)</h3>
                            <div className="grid" style={{ gridTemplateColumns: '200px auto' }}>
                                <CopyableText
                                    className="text-gray-500"
                                    label="Client ID Header Key"
                                    value="x-pearler-api-client-id"
                                />
                                <CopyableText
                                    className="text-blue-500"
                                    label="Client ID Header Value"
                                    value={`client_${apiAccess.teamId}`}
                                />
                                <CopyableText
                                    className="text-gray-500"
                                    label="Client Secret Header Key"
                                    value="x-pearler-api-client-secret"
                                />
                                <CopyableText
                                    className="text-blue-500"
                                    label="Client Secret Header Value"
                                    value={apiAccess.apiKey}
                                />
                            </div>
                            <h3 className="my-16 text-14 uppercase text-gray-500">Pagination Headers (Optional)</h3>
                            <div className="grid" style={{ gridTemplateColumns: '200px auto' }}>
                                <CopyableText
                                    className="text-gray-500"
                                    label="Pagination Limit"
                                    value="x-pearler-limit"
                                />
                                <CopyableText className="text-blue-500" label="Pagination Limit Value" value="100" />
                                <CopyableText
                                    className="text-gray-500"
                                    label="Pagination Offset"
                                    value="x-pearler-offset"
                                />
                                <CopyableText className="text-blue-500" label="Pagination Offset Value" value="0" />
                            </div>
                        </div>
                    )}

                    <ButtonBar alignment="CENTRE" className="mt-16">
                        {apiAccess.apiKey && (
                            <Button
                                label="Re-generate Access Secret"
                                buttonType="SECONDARY"
                                disabled={loading}
                                loading={loading}
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            'Re-generating will immediately stop the existing key from working and generate you a new key.',
                                        )
                                    ) {
                                        generateAccess();
                                    }
                                }}
                            />
                        )}
                        {!apiAccess.apiKey && (
                            <ButtonBar className="mt-16">
                                <Button
                                    label="Generate Access"
                                    buttonType="PRIMARY"
                                    onClick={generateAccess}
                                    disabled={loading}
                                    loading={loading}
                                />
                            </ButtonBar>
                        )}
                    </ButtonBar>

                    <SettingsSection title="API Endpoints" />
                    <APIGuide
                        apiName="Question Analytics"
                        apiRoute="/api/analytics/questions"
                        apiMethod="POST"
                        exampleRequest={`{
    "after": "2022-11-08",
    "before": "2023-02-28",
    "complianceStatusList": "COMPLIANT,NON-COMPLIANT,PARTIALLY-COMPLIANT,NOT-APPLICABLE",
    "industryVertical": "Amusement & Recreation",
    "projectId": "8e4a8a7f-9960-429e-ae6e-d32f69ec802a",
    "projectExpectedOutcome": "Won",
    "projectExpectedOutcome": "5"
}`}
                        exampleResponse={`[{
    "questionId":"8e4a8a7f-9960-429e-ae6e-d32f69ec802a",
    "projectId":"87e438f6-648c-4760-bfd5-cea17879860d",
    "question":"Please describe in detail your privacy policy",
    "answer":"Yes, we comply with this requirement.",
    "answeredByUserId":"4bdd3782-6a39-485e-9d76-1bf981ded316",
    "classifiedByUserId":"4bdd3782-6a39-485e-9d76-1bf981ded316",
    "responseClassification":"COMPLIANT",
    "updatedAt":"2023-02-02T05:01:49.681Z",
    "name":"Testing",
    "industryVertical":"Amusement & Recreation",
    "answeredByUser":"Robin Garnham",
    "classifiedByUser":"Robin Garnham",
    "tags":[]
}]`}
                    />
                    <APIGuide
                        apiName="Project Analytics"
                        apiRoute="/api/analytics/projects"
                        apiMethod="POST"
                        exampleRequest={`{
    "after": "2022-11-08",
    "before": "2023-02-28",
    "industryVertical": "Amusement & Recreation",
    "projectId": "8e4a8a7f-9960-429e-ae6e-d32f69ec802a",
    "projectExpectedOutcome": "Won",
    "projectExpectedOutcome": "5"
}`}
                        exampleResponse={`[{
    "projectId": "87e438f6-648c-4760-bfd5-cea17879860d",
    "name": "Testing Project",
    "updatedAt": "2023-01-12T23:34:51.130Z",
    "projectOutcome": "Win",
    "projectExpectedOutcome": "5",
    "industryVertical": "Amusement & Recreation",
    "projectValue": "10,000",
    "projectOutcomeReason": "We had SOC2 and our competitors didn't",
    "createdByUserId": "4bdd3782-6a39-485e-9d76-1bf981ded316",
    "tags": [],
    "createdBy": {
        "fullName": "Robin Garnham",
        "userId": "4bdd3782-6a39-485e-9d76-1bf981ded316",
        "givenName": "Robin",
        "lastName": "Garnham"
    }
}]`}
                    />
                </>
            )}
        </div>
    );
};
