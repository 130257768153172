function permissionName(name: string): string {
    return `USER_PERMISSION_${name}`;
}

export const PERMISSION = {
    READ_PROJECT: permissionName('READ_PROJECT'),
    CREATE_PROJECT: permissionName('CREATE_PROJECT'),
    UPDATE_PROJECT: permissionName('UPDATE_PROJECT'),
    DELETE_PROJECT: permissionName('DELETE_PROJECT'),
    CREATE_QUESTION: permissionName('CREATE_QUESTION'),
    READ_QUESTION: permissionName('READ_QUESTION'),
    UPDATE_QUESTION: permissionName('UPDATE_QUESTION'),
    DELETE_QUESTION: permissionName('DELETE_QUESTION'),
    CREATE_COMMENT: permissionName('CREATE_COMMENT'),
    READ_COMMENT: permissionName('READ_COMMENT'),
    UPDATE_COMMENT: permissionName('UPDATE_COMMENT'),
    DELETE_COMMENT: permissionName('DELETE_COMMENT'),
    CREATE_TEAM: permissionName('CREATE_TEAM'),
    READ_TEAM: permissionName('READ_TEAM'),
    READ_ALL_TEAMS: permissionName('READ_ALL_TEAMS'),
    UPDATE_TEAM: permissionName('UPDATE_TEAM'),
    DELETE_TEAM: permissionName('DELETE_TEAM'),
    CREATE_TEAM_MEMBER: permissionName('CREATE_TEAM_MEMBER'),
    READ_TEAM_MEMBER: permissionName('READ_TEAM_MEMBER'),
    UPDATE_TEAM_MEMBER: permissionName('UPDATE_TEAM_MEMBER'),
    DELETE_TEAM_MEMBER: permissionName('DELETE_TEAM_MEMBER'),
    CREATE_USER: permissionName('CREATE_USER'),
    READ_USER: permissionName('READ_USER'),
    READ_ALL_USERS: permissionName('READ_ALL_USERS'),
    UPDATE_ALL_USERS: permissionName('UPDATE_ALL_USERS'),
    DELETE_ALL_USERS: permissionName('DELETE_ALL_USERS'),
    UPDATE_USER: permissionName('UPDATE_USER'),
    DELETE_USER: permissionName('DELETE_USER'),
    CREATE_ROLE: permissionName('CREATE_ROLE'),
    READ_ROLE: permissionName('READ_ROLE'),
    UPDATE_ROLE: permissionName('UPDATE_ROLE'),
    DELETE_ROLE: permissionName('DELETE_ROLE'),
    CREATE_GROUP: permissionName('CREATE_GROUP'),
    READ_GROUP: permissionName('READ_GROUP'),
    UPDATE_GROUP: permissionName('UPDATE_GROUP'),
    DELETE_GROUP: permissionName('DELETE_GROUP'),
    CREATE_FILE: permissionName('CREATE_FILE'),
    READ_FILE: permissionName('READ_FILE'),
    UPDATE_FILE: permissionName('UPDATE_FILE'),
    DELETE_FILE: permissionName('DELETE_FILE'),
    CREATE_ANSWER: permissionName('CREATE_ANSWER'),
    READ_ANSWER: permissionName('READ_ANSWER'),
    FLAG_ANSWER: permissionName('FLAG_ANSWER'),
    UPDATE_ANSWER: permissionName('UPDATE_ANSWER'),
    DELETE_ANSWER: permissionName('DELETE_ANSWER'),
    CREATE_ACTIVITY: permissionName('CREATE_ACTIVITY'),
    READ_ACTIVITY: permissionName('READ_ACTIVITY'),
    CREATE_LIBRARY: permissionName('CREATE_LIBRARY'),
    READ_LIBRARY: permissionName('READ_LIBRARY'),
    UPDATE_LIBRARY: permissionName('UPDATE_LIBRARY'),
    DELETE_LIBRARY: permissionName('DELETE_LIBRARY'),
    READ_NOTIFICATION: permissionName('READ_NOTIFICATION'),
    UPDATE_NOTIFICATION: permissionName('UPDATE_NOTIFICATION'),
    ANSWER_QUESTION: permissionName('ANSWER_QUESTION'),
    ASSIGN_PROJECT_SALES_MANAGER: permissionName('ASSIGN_PROJECT_SALES_MANAGER'),
    ASSIGN_PROJECT_DELIVERY_MANAGER: permissionName('ASSIGN_PROJECT_DELIVERY_MANAGER'),
    BULK_EXPORT_ACROSS_PROJECTS: permissionName('BULK_EXPORT_ACROSS_PROJECTS'),
    NOTIFY_PROJECT_CREATION: permissionName('NOTIFY_PROJECT_CREATION'),
    ACCEPT_ASSESSMENT_INVITE: permissionName('ACCEPT_ASSESSMENT_INVITE'),
    ADD_TAG: permissionName('ADD_TAG'),
    ADD_TO_COLLECTION: permissionName('ADD_TO_COLLECTION'),
    CANCEL_ASSESSMENT_INVITE: permissionName('CANCEL_ASSESSMENT_INVITE'),
    COLLABORATOR: permissionName('COLLABORATOR'),
    CREATE_COLLECTION: permissionName('CREATE_COLLECTION'),
    CREATE_DOCUMENT: permissionName('CREATE_DOCUMENT'),
    CREATE_DOCUMENT_TYPE: permissionName('CREATE_DOCUMENT_TYPE'),
    CREATE_QUESTION_DOCUMENT: permissionName('CREATE_QUESTION_DOCUMENT'),
    CREATE_ASSESSMENT: permissionName('CREATE_ASSESSMENT'),
    CREATE_ASSESSMENT_INVITE: permissionName('CREATE_ASSESSMENT_INVITE'),
    DECLINE_ASSESSMENT_INVITE: permissionName('DECLINE_ASSESSMENT_INVITE'),
    DELETE_COLLECTION: permissionName('DELETE_COLLECTION'),
    DELETE_DOCUMENT: permissionName('DELETE_DOCUMENT'),
    DELETE_DOCUMENT_TYPE: permissionName('DELETE_DOCUMENT_TYPE'),
    DELETE_QUESTION_DOCUMENT: permissionName('DELETE_QUESTION_DOCUMENT'),
    READ_COLLECTION: permissionName('READ_COLLECTION'),
    READ_DOCUMENT: permissionName('READ_DOCUMENT'),
    READ_DOCUMENT_TYPE: permissionName('READ_DOCUMENT_TYPE'),
    READ_INVITATION: permissionName('READ_INVITATION'),
    READ_QUESTION_DOCUMENT: permissionName('READ_QUESTION_DOCUMENT'),
    READ_ASSESSMENT: permissionName('READ_ASSESSMENT'),
    READ_TAGS: permissionName('READ_TAGS'),
    REMOVE_FROM_COLLECTION: permissionName('REMOVE_FROM_COLLECTION'),
    REMOVE_TAG: permissionName('REMOVE_TAG'),
    UPDATE_COLLECTION: permissionName('UPDATE_COLLECTION'),
    UPDATE_DOCUMENT: permissionName('UPDATE_DOCUMENT'),
    UPDATE_DOCUMENT_TYPE: permissionName('UPDATE_DOCUMENT_TYPE'),
    UPDATE_INVITATION: permissionName('UPDATE_INVITATION'),
    UPDATE_QUESTION_DOCUMENT: permissionName('UPDATE_QUESTION_DOCUMENT'),
    UPDATE_ASSESSMENT: permissionName('UPDATE_ASSESSMENT'),
};
