import React, { ReactElement } from 'react';

export const Tick = ({ strokeColor = 'white' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 1L3.5 6.5L1 4"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
