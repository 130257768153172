import { GRAY, GREEN, Pill, RED, YELLOW } from 'components/pill.component';
import React from 'react';

export const getPillForComplianceStatus = (responseClassification: string) => {
    if (responseClassification === 'COMPLIANT') {
        return (
            <Pill
                title="Compliant"
                alt="The submitter marked their submission as Compliant for this question"
                colour={GREEN}
            />
        );
    }

    if (responseClassification === 'PARTIALLY-COMPLIANT') {
        return (
            <Pill
                title="Partially Compliant"
                alt="The submitter marked their submission as Partially Compliant for this question"
                colour={YELLOW}
            />
        );
    }

    if (responseClassification === 'NON-COMPLIANT') {
        return (
            <Pill
                title="Non Compliant"
                alt="The submitter marked their submission as Non Compliant for this question"
                colour={RED}
            />
        );
    }

    if (responseClassification === 'NOT-APPLICABLE') {
        return (
            <Pill
                title="Not Applicable"
                alt="The submitter marked this question as Not Applicable for this submission"
                colour={GRAY}
            />
        );
    }
};
