import { Error } from 'components/error.component';
import { useUserContext } from 'hooks/useUserContext';
import { ApplicationNavigationBar } from 'modules/application-navigation-bar/application-navigation-bar.view';
import React, { ReactElement } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { ApplicationLoading } from './ApplicationLoader';

export const ApplicationRoute = ({ children }: RouteProps): ReactElement => {
    const { redirectUser, userStatus, checkingAuth, restartApplication } = useUserContext();

    if (checkingAuth) {
        return <ApplicationLoading />;
    }

    const result = redirectUser();

    if (result) {
        return <Navigate to={result} />;
    }

    if (!userStatus) {
        restartApplication();
        return <ApplicationLoading />;
    }

    return (
        <>
            <Error />
            <div
                className="grid h-screen w-screen min-w-full max-w-full bg-white"
                style={{ gridTemplateColumns: '170px 1fr' }}
            >
                <ApplicationNavigationBar />
                {children}
            </div>
        </>
    );
};
