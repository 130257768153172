import React, { DragEvent, forwardRef, memo, ReactElement } from 'react';
import './style.css';

function Render(
    { html, drop }: { html: string; drop?: (event: DragEvent<HTMLDivElement>) => void },
    ref,
): ReactElement {
    return (
        <div
            ref={ref}
            onDrop={drop}
            onDragOver={(event: DragEvent<HTMLDivElement>) => {
                event.preventDefault();
                //@ts-ignore
                event.target.classList.add('pearler_generated_classes', 'being-hovered');
            }}
            onDragLeave={(event: DragEvent<HTMLDivElement>) => {
                event.preventDefault();
                //@ts-ignore
                event.target.classList.remove('pearler_generated_classes', 'being-hovered');
            }}
            className="_pearler-doc select-text render-html-normally"
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
}

export const RenderDocument = memo(forwardRef(Render));
