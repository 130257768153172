import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const DeleteProjectModal = ({
    closeModal,
    projectId,
}: {
    closeModal: () => void;
    projectId: string;
}): ReactElement => {
    const [savingChanges, setSavingChanges] = useState(false);
    const navigate = useNavigate();

    const deleteProject = async () => {
        try {
            const started = new Date();
            setSavingChanges(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.DELETE_PROJECT, { projectId });

            const results = await API.post(url);

            if (results && results.data) {
                showSuccess('Project Deleted');
            }

            logEvent('PROJECT_DELETED', started);
            setSavingChanges(false);
            navigate(ROUTES.PROJECT_LIST);
        } catch (err) {
            showError('There was an error deleting the project', err);
            setSavingChanges(false);
        }
    };

    return (
        <Modal title="Delete Project" closeModal={closeModal} showClose={true} width="w-600">
            <p className={`text-left text-gray-500`}>Deleting this project is not reversible</p>
            <p className={`text-left text-gray-500`}>
                All data associated with this project will be permanantly deleted
            </p>

            <ButtonBar className="mt-32">
                <Button buttonType="SECONDARY" label="Cancel" onClick={closeModal} disabled={savingChanges} />
                <Button
                    label="Yes, I want to delete this project"
                    buttonType="DANGER"
                    onClick={deleteProject}
                    disabled={savingChanges}
                    loading={savingChanges}
                />
            </ButtonBar>
        </Modal>
    );
};
