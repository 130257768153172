import { useUserContext } from 'hooks/useUserContext';
import React, { ReactElement, useState } from 'react';
import { Membership } from 'types';
import { Avatar } from './duex/Avatar';
import { TeamListWithSearch } from './team-list-search.component';

import _find from 'lodash/find';

interface ProjectSharingProps {
    visibilityStatus: 'TEAM' | 'PRIVATE';
    sharingWith: Membership[];
    onChangeVisibility: (newSetting: string) => void;
    onAddUser: (user: Membership) => void;
    onRemoveUser: (user: Membership) => void;
    memberListExcludingUser: Membership[];
    creatingNewProject: boolean;
    currentUserId: string;
    projectCreatorUserId: string;
}

export const ProjectSharing = ({
    visibilityStatus,
    sharingWith,
    onChangeVisibility,
    onAddUser,
    onRemoveUser,
    memberListExcludingUser,
    creatingNewProject,
    currentUserId,
    projectCreatorUserId,
}: ProjectSharingProps): ReactElement => {
    const [showTeamList, setShowTeamList] = useState(false);
    const { getUserInfo } = useUserContext();

    return (
        <div className={'mb-32 overflow-y-auto text-left'}>
            <div className="my-6 flex flex-row p-16">
                <input
                    type="checkBox"
                    checked={visibilityStatus === 'TEAM'}
                    className="form-checkbox round-12 mr-16 h-24 w-24 cursor-pointer border-2 border-light-gray focus:shadow-none"
                    id={`visibility-team`}
                    onChange={() => {
                        onChangeVisibility('TEAM');
                    }}
                />
                <div>
                    <h2
                        className="cursor-pointer text-16 font-semibold text-black-80"
                        onClick={() => onChangeVisibility('TEAM')}
                    >
                        Team
                    </h2>
                    <p className="text-14 text-black-80">Everyone in your team will be able to access this project.</p>
                </div>
            </div>

            <div className="my-6 flex flex-row p-16">
                <input
                    type="checkBox"
                    checked={visibilityStatus === 'PRIVATE'}
                    className="form-checkbox round-12 mr-16 h-24 w-24 cursor-pointer border-2 border-light-gray focus:shadow-none"
                    id={`visibility-private`}
                    onChange={() => {
                        onChangeVisibility('PRIVATE');
                    }}
                />
                <div>
                    <h2
                        className="cursor-pointer text-16 font-semibold text-black-80"
                        onClick={() => onChangeVisibility('PRIVATE')}
                    >
                        Private
                    </h2>
                    <p className="text-14 text-black-80">
                        Only {creatingNewProject ? 'you and ' : ''}the people listed below will be able to access this
                        project.
                    </p>
                </div>
            </div>
            {visibilityStatus === 'PRIVATE' && (
                <div>
                    <div className="grid grid-cols-2">
                        <h2 className="mb-16 text-16 font-semibold text-black-80">Project Team</h2>
                        <a
                            className="cursor-pointer select-none pt-2 text-right text-14 text-brand-light-blue underline"
                            onClick={() => {
                                setShowTeamList(true);
                            }}
                        >
                            Add
                        </a>
                        {showTeamList && (
                            <TeamListWithSearch
                                selectedTeamMembers={sharingWith}
                                teamMembers={memberListExcludingUser}
                                hideList={() => {
                                    setShowTeamList(false);
                                }}
                                onSelect={(user: Membership) => {
                                    if (
                                        _find(sharingWith, {
                                            userId: user.userId,
                                        })
                                    ) {
                                        onRemoveUser(user);
                                    } else {
                                        onAddUser(user);
                                    }
                                }}
                            />
                        )}
                    </div>
                    <div className="border-gray-80 min-h-200 rounded-lg border p-16">
                        {sharingWith.length === 0 && (
                            <p className="text-center text-14 text-black-80">
                                Only you can access this project right now. <br /> Add some team mates.
                            </p>
                        )}
                        {sharingWith.length > 0 && (
                            <ul>
                                {sharingWith.map(({ userId }, index) => {
                                    const user = getUserInfo(userId);
                                    const {
                                        member: { fullName },
                                        group: { groupName },
                                    } = user;

                                    return (
                                        <div
                                            key={userId}
                                            className={`grid ${index < sharingWith.length - 1 ? 'pb-24' : ''}`}
                                            style={{ gridTemplateColumns: '48px auto 44px' }}
                                        >
                                            <Avatar size={32} userId={userId} />
                                            <div
                                                className="flex flex-wrap items-center overflow-hidden"
                                                style={{
                                                    maxWidth: '100%',
                                                }}
                                            >
                                                <p
                                                    data-cy="DropDownName"
                                                    className="subtitle-2-reg w-full select-none truncate"
                                                >
                                                    {fullName}
                                                </p>
                                                <p className="small-3-reg mt-4 w-full select-none truncate">
                                                    {groupName}
                                                </p>
                                            </div>
                                            <div className="text-center">
                                                {projectCreatorUserId === userId && (
                                                    <a className="select-none text-12 text-gray-600">Owner</a>
                                                )}
                                                {currentUserId === userId && projectCreatorUserId !== userId && (
                                                    <a className="select-none text-12 text-gray-600">You</a>
                                                )}
                                                {currentUserId !== userId && projectCreatorUserId !== userId && (
                                                    <a
                                                        className="cursor-pointer select-none text-12 text-brand-light-blue hover:underline"
                                                        onClick={() => {
                                                            onRemoveUser(user);
                                                        }}
                                                    >
                                                        Remove
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
