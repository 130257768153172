import { CONSTANTS } from './Constants';

interface Data {
    projectId?: string;
    questionId?: string;
    libraryId?: string;
    answerId?: string;
    membershipId?: string;
    notificationId?: string;
    userId?: string;
    assessmentId?: string;
    projectInviteId?: string;
    documentId?: string;
    resourceId?: string;
    projectSourceId?: string;
    sheetId?: string;
    sourceDocumentId?: string;
    answerSourceId?: string;
    collectionId?: string;
    divisionId?: string;
}

export const ENDPOINTS = {
    getLiteralUrl: (endpointRoute: string): string => {
        const origin = process.env.REACT_APP_API_SERVER_ADDRESS;
        return `${origin}${endpointRoute}`;
    },

    getUrl: function (endpointName: string, data?: Data): string {
        try {
            const origin = process.env.REACT_APP_API_SERVER_ADDRESS;

            switch (endpointName) {
                case CONSTANTS.ASSIGNED_QUESTIONS:
                    return `${origin}/projects/questions/assigned`;
                case CONSTANTS.QUESTION:
                    return `${origin}/projects/${data.projectId}/questions`;
                case CONSTANTS.QUESTION_UPDATE:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/update`;
                case CONSTANTS.COMMENTS:
                    return `${origin}/projects/${data.projectId}/${data.questionId}/comments`;
                case CONSTANTS.FETCH_QUESTION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/fetch`;
                case CONSTANTS.LIBRARY_TERMS:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/library-terms`;
                case CONSTANTS.PROJECT_SUGGESTION_SEARCH:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/suggestions`;
                case CONSTANTS.PROJECT_ANSWER_SEARCH:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/search`;
                case CONSTANTS.COMBINED_SEARCH_ANSWERS:
                    return `${origin}/answers/combined/search`;
                case CONSTANTS.UPDATE_ANSWER:
                    return `${origin}/answers/${data.answerId}/update`;
                case CONSTANTS.FLAG_ANSWER:
                    return `${origin}/answers/${data.answerId}/flag`;
                case CONSTANTS.DELETE_ANSWER:
                    return `${origin}/answers/${data.answerId}/delete`;
                case CONSTANTS.PROJECTS:
                    return `${origin}/projects`;
                case CONSTANTS.DELETE_PROJECT:
                    return `${origin}/projects/${data.projectId}/delete`;
                case CONSTANTS.TEAM:
                    return `${origin}/team/members`;
                case CONSTANTS.GET_TEAM:
                    return `${origin}/team/mine`;
                case CONSTANTS.UPDATE_TEAM:
                    return `${origin}/team/mine/update`;
                case CONSTANTS.TEAM_LOGO_UPLOAD:
                    return `${origin}/team/mine/logo/upload`;
                case CONSTANTS.USER:
                    return `${origin}/user`;
                case CONSTANTS.USER_LOGGED_IN:
                    return `${origin}/user/logged-in`;
                case CONSTANTS.USER_LOG_ACTIVITY:
                    return `${origin}/user/activity`;
                case CONSTANTS.FETCH_ACTIVITY:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/activity`;
                case CONSTANTS.FETCH_DISCUSSION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/comments`;
                case CONSTANTS.CREATE_DISCUSSION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/comments/create`;
                case CONSTANTS.QUESTION_UNASSIGN:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/unassign`;
                case CONSTANTS.API_BULK_RE_ASSSIGN:
                    return `${origin}/projects/${data.projectId}/bulk-assign`;
                case CONSTANTS.CREATE_PROJECT:
                    return `${origin}/projects/create`;
                case CONSTANTS.SUBMIT_PROJECT:
                    return `${origin}/projects/${data.projectId}/submit`;
                case CONSTANTS.UPDATE_PROJECT:
                    return `${origin}/projects/${data.projectId}/update`;
                case CONSTANTS.PROJECT_UNASSIGN:
                    return `${origin}/projects/${data.projectId}/unassign`;
                case CONSTANTS.EXPORT_PROJECT_DETAILS:
                    return `${origin}/projects/${data.projectId}/export`;
                case CONSTANTS.UPDATE_USER:
                    return `${origin}/user/update`;
                case CONSTANTS.FETCH_DASHBOARD_PROJECTS:
                    return `${origin}/dashboard/projects`;
                case CONSTANTS.FETCH_NOTIFICATIONS:
                    return `${origin}/notifications`;
                case CONSTANTS.READ_NOTIFICATIONS:
                    return `${origin}/notifications/${data.notificationId}/read`;
                case CONSTANTS.CREATE_UPLOAD_URL:
                    return `${origin}/user/photo/update`;
                case CONSTANTS.COMPLETE_PHOTO_UPLOAD:
                    return `${origin}/user/photo/complete`;
                case CONSTANTS.COMPLETE_PHOTO_REMOVAL:
                    return `${origin}/user/photo/remove`;
                case CONSTANTS.GET_API_ACCESS:
                    return `${origin}/user/api`;
                case CONSTANTS.GENERATE_API_ACCESS:
                    return `${origin}/user/api/generate`;
                case CONSTANTS.SAVE_QUESTIONS_AND_ANSWERS:
                    return `${origin}/answers/import`;
                case CONSTANTS.FETCH_ANSWER_ANALYTICS:
                    return `${origin}/answers/${data.answerId}`;
                case CONSTANTS.REPLACE_ANSWER:
                    return `${origin}/answers/${data.answerId}/replace`;
                case CONSTANTS.FETCH_ROLES:
                    return `${origin}/roles`;
                case CONSTANTS.FETCH_GROUPS:
                    return `${origin}/groups`;
                case CONSTANTS.UPDATE_MEMBERSHIP:
                    return `${origin}/team/members/${data.membershipId}/update`;
                case CONSTANTS.INVITE_MEMBER:
                    return `${origin}/team/members/invite`;
                case CONSTANTS.ALL_TEAM:
                    return `${origin}/team/members/all`;
                case CONSTANTS.COMPLETE_REGISTRATION:
                    return `${origin}/public/user`;
                case CONSTANTS.USER_TEAM:
                    return `${origin}/user/teams`;
                case CONSTANTS.PERMISSIONS:
                    return `${origin}/permissions`;
                case CONSTANTS.FETCH_INVITATIONS:
                    return `${origin}/invitations`;
                case CONSTANTS.CREATE_TEAM:
                    return `${origin}/team`;
                case CONSTANTS.UPDATE_INVITATION:
                    return `${origin}/invitations/${data.membershipId}/respond`;
                case CONSTANTS.REINVITE_MEMBERSHIP:
                    return `${origin}/team/${data.membershipId}/resend`;
                case CONSTANTS.USER_STATUS:
                    return `${origin}/user/status`;
                case CONSTANTS.ADD_QUESTION:
                    return `${origin}/projects/${data.projectId}/question`;
                case CONSTANTS.GET_MANUALLY_CREATED_QUESTIONS:
                    return `${origin}/projects/${data.projectId}/manual/questions`;
                case CONSTANTS.COMPLETE_QUESTION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/complete`;
                case CONSTANTS.DELETE_QUESTION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/delete`;
                case CONSTANTS.NOT_A_QUESTION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/notQuestion`;
                case CONSTANTS.PROJECT_DASHBOARD_ENDPOINT:
                    return `${origin}/projects/${data.projectId}/dashboard`;
                case CONSTANTS.FETCH_ANSWERS_BY_SEARCH:
                    return `${origin}/answers/search`;
                case CONSTANTS.FETCH_USER_SETTINGS:
                    return `${origin}/user/settings`;
                case CONSTANTS.FETCH_USER_ACTIVITY:
                    return `${origin}/user/activity`;
                case CONSTANTS.UPDATE_USER_SETTINGS:
                    return `${origin}/user/settings/update`;
                case CONSTANTS.ENDPOINT_GET_PROJECT:
                    return `${origin}/projects/${data.projectId}`;
                case CONSTANTS.GET_PROJECT_TEAM:
                    return `${origin}/projects/${data.projectId}/team`;
                case CONSTANTS.GET_PROJECT_SHARE_TEAM:
                    return `${origin}/projects/${data.projectId}/access/team`;
                case CONSTANTS.UPDATE_PROJECT_SHARING_SETTINGS:
                    return `${origin}/projects/${data.projectId}/access/team/update`;
                case CONSTANTS.COMPLIANCE_OVERRIDE:
                    return `${origin}/projects/${data.projectId}/compliance`;
                case CONSTANTS.FETCH_FAVOURITE_ANSWERS:
                    return `${origin}/answers/favourites`;
                case CONSTANTS.SEARCH_ALL_PROJECTS:
                    return `${origin}/analytics/projects/search`;
                case CONSTANTS.EXPORT_ALL_PROJECTS:
                    return `${origin}/analytics/projects/export`;
                case CONSTANTS.SEARCH_ALL_QUESTIONS:
                    return `${origin}/analytics/questions/search`;
                case CONSTANTS.EXPORT_ALL_QUESTIONS:
                    return `${origin}/analytics/questions/export`;
                case CONSTANTS.GET_INDUSTRY_LIST:
                    return `${origin}/analytics/list/industries`;
                case CONSTANTS.GET_PROJECT_LIST:
                    return `${origin}/analytics/list/projects`;
                case CONSTANTS.GET_SSO_BY_ALIAS:
                    return `${origin}/sso/alias/check`;
                case CONSTANTS.GET_SSO_BY_DOMAIN:
                    return `${origin}/sso/domain/check`;
                case CONSTANTS.QUESTION_SUBSCRIPTION_STATUS:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/subscription/status`;
                case CONSTANTS.QUESTION_CREATE_SUBSCRIPTION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/subscription/create`;
                case CONSTANTS.QUESTION_REMOVE_SUBSCRIPTION:
                    return `${origin}/projects/${data.projectId}/questions/${data.questionId}/subscription/remove`;
                case CONSTANTS.ASSESSMENT_GET_FULL_TEMPLATES:
                    return `${origin}/assessment/templates/complete`;
                case CONSTANTS.ASSESSMENT_GET_TEMPLATES:
                    return `${origin}/assessment/templates`;
                case CONSTANTS.ASSESSMENT_GET_ASSESSMENT:
                    return `${origin}/assessment/${data.assessmentId}/fetch`;
                case CONSTANTS.ASSESSMENT_CREATE_ASSESSMENT:
                    return `${origin}/assessment/create`;
                case CONSTANTS.ASSESSMENT_SAVE_ASSESSMENT:
                    return `${origin}/assessment/${data.assessmentId}/update`;
                case CONSTANTS.ASSESSMENT_GET_INBOX:
                    return `${origin}/assessment/inbox`;
                case CONSTANTS.ASSESSMENT_GET_SENT:
                    return `${origin}/projects/${data.projectId}/invites/sent`;
                case CONSTANTS.ASSESSMENT_INVITE_ASSESSMENT:
                    return `${origin}/projects/${data.projectId}/invites/send`;
                case CONSTANTS.ASSESSMENT_ACCEPT_ASSESSMENT:
                    return `${origin}/invites/${data.projectInviteId}/accept`;
                case CONSTANTS.ASSESSMENT_DECLINE_ASSESSMENT:
                    return `${origin}/invites/${data.projectInviteId}/decline`;
                case CONSTANTS.ASSESSMENT_CANCEL_ASSESSMENT:
                    return `${origin}/projects/${data.projectId}/invites/${data.projectInviteId}/cancel`;
                case CONSTANTS.GET_ALL_TAGS:
                    return `${origin}/tags`;
                case CONSTANTS.GET_TAGS_LIST:
                    return `${origin}/tags/${data.resourceId}/tags`;
                case CONSTANTS.ADD_TAG:
                    return `${origin}/tags/${data.resourceId}/add`;
                case CONSTANTS.REMOVE_TAG:
                    return `${origin}/tags/${data.resourceId}/remove`;
                case CONSTANTS.GET_DOC_LIBRARY:
                    return `${origin}/documents`;
                case CONSTANTS.GET_DOC_LIBRARY_ALL:
                    return `${origin}/documents/all`;
                case CONSTANTS.GET_DOC_QUESTION:
                    return `${origin}/projects/${data.projectId}/${data.questionId}/documents`;
                case CONSTANTS.ADD_DOC_QUESTION:
                    return `${origin}/projects/${data.projectId}/${data.questionId}/documents/add`;
                case CONSTANTS.REMOVE_DOC_QUESTION:
                    return `${origin}/projects/${data.projectId}/${data.questionId}/documents/remove`;
                case CONSTANTS.GET_DOC_PROJECT:
                    return `${origin}/projects/${data.projectId}/documents`;
                case CONSTANTS.ADD_DOC_PROJECT:
                    return `${origin}/projects/${data.projectId}/documents/add`;
                case CONSTANTS.REMOVE_DOC_PROJECT:
                    return `${origin}/projects/${data.projectId}/documents/remove`;
                case CONSTANTS.GET_DOC_TYPE:
                    return `${origin}/documents/types`;
                case CONSTANTS.CREATE_DOC:
                    return `${origin}/documents/create`;
                case CONSTANTS.COMPLETE_DOC_UPLOAD:
                    return `${origin}/documents/${data.documentId}/complete`;
                case CONSTANTS.ARCHIVE_DOCUMENT:
                    return `${origin}/documents/${data.documentId}/archive`;
                case CONSTANTS.REINSTATE_DOCUMENT:
                    return `${origin}/documents/${data.documentId}/reinstate`;
                case CONSTANTS.UPDATE_DOCUMENT:
                    return `${origin}/documents/${data.documentId}/update`;
                case CONSTANTS.PROJECT_TRUST_CENTRE:
                    return `${origin}/trust/${data.projectId}`;
                case CONSTANTS.GET_SOURCE_DOCS:
                    return `${origin}/projects/${data.projectId}/source`;
                case CONSTANTS.START_SOURCE_DOC_UPLOAD:
                    return `${origin}/projects/${data.projectId}/source/create`;
                case CONSTANTS.COMPLETE_SOURCE_DOC_UPLOAD:
                    return `${origin}/projects/${data.projectId}/source/complete`;
                case CONSTANTS.SAVE_SOURCE_SHEET_CONFIGURATION:
                    return `${origin}/projects/${data.projectId}/sources/${data.sheetId}/spreadsheet/configure`;
                case CONSTANTS.SAVE_SOURCE_DOCUMENT_CONFIGURATION:
                    return `${origin}/projects/${data.projectId}/sources/${data.sourceDocumentId}/document/configure`;
                case CONSTANTS.DOWNLOAD_PROJECT_SOURCE:
                    return `${origin}/projects/${data.projectId}/sources/${data.projectSourceId}/spreadsheet/download`;
                case CONSTANTS.DOWNLOAD_PROJECT_SOURCE_DOCUMENT:
                    return `${origin}/projects/${data.projectId}/sources/${data.projectSourceId}/document/download`;
                case CONSTANTS.DOWNLOAD_DOCUMENT_AS_CSV:
                    return `${origin}/projects/${data.projectId}/sources/${data.projectSourceId}/csv/download`;
                case CONSTANTS.UPLOAD_ANSWER_LIBRARY_START:
                    return `${origin}/answers/upload`;
                case CONSTANTS.UPLOAD_ANSWER_LIBRARY_COMPLETE:
                    return `${origin}/answers/${data.answerSourceId}/complete`;
                case CONSTANTS.GET_ANSWER_SOURCE:
                    return `${origin}/answers/sources/${data.answerSourceId}`;
                case CONSTANTS.ANSWER_LIBRARY_BULK_CHANGE:
                    return `${origin}/answers/bulk/update`;
                case CONSTANTS.ANSWER_LIBRARY_BULK_CANDIDATE_CHANGE:
                    return `${origin}/answers/candidates/update`;
                case CONSTANTS.CREATE_ANSWER_COLLECTION:
                    return `${origin}/collections/create`;
                case CONSTANTS.GET_ANSWER_COLLECTION_COUNTS:
                    return `${origin}/collections/counts`;
                case CONSTANTS.GET_ANSWER_COLLECTION:
                    return `${origin}/collections`;
                case CONSTANTS.UPDATE_COLLECTION:
                    return `${origin}/collections/${data.collectionId}/update`;
                case CONSTANTS.DELETE_COLLECTION:
                    return `${origin}/collections/${data.collectionId}/delete`;
                case CONSTANTS.ADD_TO_COLLECTION:
                    return `${origin}/collections/${data.collectionId}/add`;
                case CONSTANTS.REMOVE_FROM_COLLECTION:
                    return `${origin}/collections/${data.collectionId}/remove`;
                case CONSTANTS.GET_ANSWER_COLLECTIONS:
                    return `${origin}/answers/${data.answerId}/collections`;
                case CONSTANTS.REWRITE_ANSWER:
                    return `${origin}/answers/rewrite`;
                case CONSTANTS.GENERATE_NEW_ANSWER:
                    return `${origin}/answers/suggest`;
                case CONSTANTS.ADMIN_TEAM_SEARCH:
                    return `${origin}/admin/teams/search`;
                case CONSTANTS.ADMIN_TEAM_UPDATE:
                    return `${origin}/admin/teams/update`;
                case CONSTANTS.ADMIN_TEAM_ACTIVITY:
                    return `${origin}/admin/teams/activity`;
                case CONSTANTS.GET_FEEDBACK:
                    return `${origin}/feedback`;
                case CONSTANTS.SUBMIT_FEEDBACK:
                    return `${origin}/feedback/submit`;
                case CONSTANTS.GET_DIVISIONS:
                    return `${origin}/divisions`;
                case CONSTANTS.CREATE_DIVISION:
                    return `${origin}/divisions/create`;
                case CONSTANTS.UPDATE_DIVISION:
                    return `${origin}/divisions/${data.divisionId}/update`;

                default:
                    const error = `No matching URL: ${endpointName}`;
                    console.log(error);
                    throw new Error(error);
            }
        } catch (err) {
            console.log('Error in Endpoints.ts', err);
            throw new Error(err);
        }
    },
};
