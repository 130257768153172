import { ModalCross } from 'assets/modal-cross.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { ConfirmationDialog } from 'components/duex/ConfirmationDialog';
import { testAutomation } from 'components/duex/TestAutomationAttribute';
import { EditableLabel } from 'components/editable-label.component';
import { showError } from 'components/error-toast.component';
import { Modal } from 'components/modal.component';
import { LIGHTBLUE, Pill } from 'components/pill.component';
import { Select } from 'components/select.component';
import { showSuccess } from 'components/success-toast.component';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import { AnswerSourceModal } from 'modules/answer-sources/answer-source-modal';
import { ComplianceModal } from 'modules/compliance/ComplianceModal';
import { ExporterModal } from 'modules/exporter/exporter.modal';
import { ProjectStartedModal } from 'modules/guides/project-started';
import { ImporterModal } from 'modules/project-importer/importer.modal';
import { Documents } from 'modules/project/answering/documents/documents.view';
import { ThirdPartyLinkModal } from 'modules/third-party-link/third-party-link.modal';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { PERMISSION } from 'utility/Permission';
import { SUCCESS_MESSAGES } from 'utility/SuccessMessages';
import { DueDate } from '../../project/common/due-date.component';
import { Priority } from '../../project/common/priority.component';
import { InviteModal } from '../buyer-project-oversight/send-invite-modal';
import { DeleteProjectModal } from '../delete-project-modal/delete-project.modal';
import { ProjectDashboardComponents } from '../project-dashboard.view';
import { TrustCentreModal } from '../trust-centre/trust-centre-modal.view';
import { SharingSettingsModal } from './sharing-settings.view';

export const DashboardTopBar = (props: ProjectDashboardComponents): ReactElement => {
    const { project, refresh, updateProjectInfo, refreshInvites, dashboardData } = props;
    const { projectPriority, dueOn, name, thirdPartyProjectUrl, projectStatus, projectId, projectType } = project;

    const navigate = useNavigate();
    const location = useLocation();
    const { hasPermission } = useUserContext();

    const [dropdownOpen, toggleDropDownState] = useState(false);
    const [showSharingModal, setShowSharingModal] = useState(false);
    const [showFilesModal, setShowFilesModal] = useState(false);
    const [showTrustCentreModal, setShowTrustCentreModal] = useState(false);
    const [showImporterModal, setShowImporterModal] = useState(false);
    const [showAddPortalDetailsModal, setShowAddPortalDetailsModal] = useState(false);
    const [showExportModal, setShowExportModal] = useState(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showComplianceModal, setShowComplianceModal] = useState(false);
    const [showAnswerSourcesModal, setShowAnswerSourcesModal] = useState(false);
    const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);

    const projectClosed = projectStatus === CONSTANTS.PROJECT_COMPLETE;
    const isSupplierProject = projectType === 'SUPPLIER';
    const isBuyerProject = projectType === 'BUYER';

    const allQuestionsAnswered = dashboardData.incompleteQuestions.quantity === 0;
    const isSubmittable = project.projectInviteId;

    const dropDownItems = [
        {
            label: 'Add Questions',
            include: isSupplierProject && !isSubmittable,
            callback: () => {
                setShowImporterModal(true);
            },
        },
        {
            label: 'Answer Sources',
            include: isSupplierProject,
            callback: () => {
                setShowAnswerSourcesModal(true);
            },
        },
        {
            label: thirdPartyProjectUrl ? 'Update Portal Link' : 'Add Portal Link',
            include: isSupplierProject && !isSubmittable,
            callback: () => {
                setShowAddPortalDetailsModal(true);
            },
        },
        {
            label: 'Manage Compliance',
            include: isSupplierProject && !isSubmittable,
            callback: () => {
                setShowComplianceModal(true);
            },
        },
        {
            label: 'Export Project',
            include: isSupplierProject,
            callback: () => {
                setShowExportModal(true);
            },
        },
        {
            label: 'Manage Access',
            include: true,
            callback: () => {
                setShowSharingModal(true);
            },
        },
        {
            label: 'Manage Documents',
            include: isSupplierProject,
            callback: () => {
                setShowFilesModal(true);
            },
        },
        {
            label: 'Project Trust Centre',
            include: isSupplierProject,
            callback: () => {
                setShowTrustCentreModal(true);
            },
        },
        {
            label: projectClosed ? 'Re-open Project' : 'Close Project',
            include: true,
            callback: () => {
                if (projectClosed) {
                    updateProjectStatus(CONSTANTS.PROJECT_IN_PROGRESS);
                } else {
                    updateProjectStatus(CONSTANTS.PROJECT_COMPLETE);
                }
            },
        },
        {
            label: 'Delete Project',
            include: hasPermission(PERMISSION.DELETE_PROJECT),
            callback: () => {
                setShowDeleteProjectModal(true);
            },
        },
    ];

    const filteredItems = _filter(dropDownItems, {
        include: true,
    });

    const submitProject = async () => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.SUBMIT_PROJECT, {
                projectId,
            });

            const result = await API.post(url);

            logEvent(`PROJECT_SUBMITTED`, started, {
                projectId,
            });

            if (result && result.data) {
                refresh();
            }
        } catch (err) {
            showError('There was an error retrieving submitting your project', err);
        }
    };

    const updateProjectStatus = async (projectStatus: string) => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_PROJECT, {
                projectId,
            });

            const result = await API.post(url, {
                projectStatus,
            });

            logEvent(`PROJECT_UPDATE_STATUS`, started, {
                projectId,
                projectStatus,
            });

            if (result && result.data) {
                if (projectStatus === CONSTANTS.PROJECT_COMPLETE) {
                    showSuccess(SUCCESS_MESSAGES.PROJECT_CLOSED);
                    navigate(`${ROUTES.PROJECT_LIST}`);
                } else {
                    showSuccess(SUCCESS_MESSAGES.PROJECT_OPENED);
                    refresh();
                }
            }
        } catch (err) {
            showError('There was an error retrieving updating your project', err);
        }
    };

    const openExternalQuestionnaireCallBack = (): void => {
        window.open(thirdPartyProjectUrl, '_blank');
    };

    const openQuestionnaire = (): void => {
        navigate(
            createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                questionId: 'start',
                projectId: projectId,
            }),
        );
    };

    const questionAnswerPermission = hasPermission(PERMISSION.ANSWER_QUESTION);

    useEffect(() => {
        // Check the URL and automatically open the Welcome modal
        const pathName = location.pathname;

        if (pathName.endsWith('welcome')) {
            setShowWelcomeModal(true);
        }
    }, [location]);

    return (
        <div
            className="flex items-center border-b border-solid border-gray-light-1-opace-8 p-24"
            style={{
                gridArea: 'top-bar',
            }}
        >
            <div className="w-50-percent">
                <h1 className={`weight-semibold h4-medium mb-8 select-none truncate text-left text-primary-blue-90`}>
                    <EditableLabel
                        dataCy="Project_Name"
                        placeholderText="Project's must always have a name"
                        labelText={name || ''}
                        labelClasses="line-clamp-1"
                        onChange={(updatedValue) => {
                            if (updatedValue) {
                                updateProjectInfo('name', updatedValue);
                            }
                        }}
                    />
                </h1>
                <div className="flex items-center gap-x-8">
                    {project.industryVertical && (
                        <Pill title={project.industryVertical} alt="Industry Vertical" colour={LIGHTBLUE} />
                    )}
                    {projectPriority !== 'NORMAL' && <Priority projectPriority={projectPriority} />}

                    <DueDate projectPriority={projectPriority} projectDueDate={dueOn} projectStatus={projectStatus} />
                    <span className="text-14 leading-24 text-black-80">
                        Due on {moment(dueOn).format('Do MMMM YYYY')}
                    </span>
                </div>
            </div>
            <div className="flex w-50-percent justify-end">
                <ButtonBar className="">
                    {thirdPartyProjectUrl && (
                        <Button
                            label="Launch External Portal"
                            onClick={openExternalQuestionnaireCallBack}
                            buttonType="SECONDARY"
                        />
                    )}
                    {isBuyerProject && (
                        <Button
                            label="Send Invitation"
                            onClick={() => {
                                setShowInviteModal(true);
                            }}
                        />
                    )}
                    {isSupplierProject && (
                        <Button
                            label="Open Assessment"
                            onClick={openQuestionnaire}
                            disabled={!questionAnswerPermission}
                            buttonType={allQuestionsAnswered ? 'SECONDARY' : 'PRIMARY'}
                        />
                    )}
                    {isSubmittable && (
                        <Button
                            label={`${project.projectSubmitted ? 'Already Submitted' : 'Submit Response'}`}
                            disabled={!allQuestionsAnswered || project.projectSubmitted}
                            buttonType={allQuestionsAnswered ? 'PRIMARY' : 'SECONDARY'}
                            onClick={() => {
                                setShowSubmitModal(true);
                            }}
                        />
                    )}
                    <div className="flex-initial">
                        <Select
                            classesForWrapper={`form-input py-10 px-16 text-black-80 focus:outline-none focus:shadow-none ${
                                dropdownOpen ? 'border-primary-blue-100' : ''
                            }`}
                            classesForInput="whitespace-nowrap button-med-14"
                            value="Manage Project"
                            showArrow={true}
                            onClickCallBack={(val: boolean) => toggleDropDownState(val)}
                        >
                            <ul className="w-full rounded-lg bg-white py-8 shadow-md">
                                {filteredItems.map((itm, index) => (
                                    <li
                                        data-cy={testAutomation(itm.label)}
                                        key={index}
                                        onClick={itm.callback}
                                        className="subtitle-2-reg cursor-pointer px-16 py-10 text-black-80 hover:bg-primary-blue-100-opace-4 hover:text-primary-blue-100"
                                    >
                                        {itm.label}
                                    </li>
                                ))}
                            </ul>
                        </Select>
                    </div>
                </ButtonBar>
            </div>

            {showSharingModal && (
                <SharingSettingsModal
                    {...props}
                    closeModal={() => {
                        setShowSharingModal(false);
                        refresh();
                    }}
                />
            )}

            {showFilesModal && (
                <Modal isOpen={true} parentClasses="w-970">
                    <p className="h4-medium mb-24 select-none">Project Files</p>
                    <div
                        className="absolute right-24 top-38 cursor-pointer"
                        onClick={() => {
                            setShowFilesModal(false);
                        }}
                    >
                        <ModalCross />
                    </div>
                    <Documents projectId={projectId} className="h-screen text-left" showHeading={false} />
                </Modal>
            )}
            {showTrustCentreModal && (
                <TrustCentreModal
                    project={project}
                    closeModal={() => {
                        setShowTrustCentreModal(false);
                        refresh();
                    }}
                />
            )}
            {showWelcomeModal && (
                <ProjectStartedModal
                    closeModal={() => {
                        setShowWelcomeModal(false);
                        navigate(
                            createUrl(ROUTES.PROJECT_DASHBOARD, {
                                projectId: project.projectId,
                            }),
                        );
                    }}
                    openAddPortal={() => {
                        setShowAddPortalDetailsModal(true);
                        setShowWelcomeModal(false);
                    }}
                    openFileUpload={() => {
                        setShowImporterModal(true);
                        setShowWelcomeModal(false);
                    }}
                />
            )}
            {showImporterModal && (
                <ImporterModal
                    projectId={projectId}
                    closeModal={() => {
                        setShowImporterModal(false);
                        refresh();
                    }}
                />
            )}
            {showAddPortalDetailsModal && (
                <ThirdPartyLinkModal
                    closeModal={() => {
                        setShowAddPortalDetailsModal(false);
                        refresh();
                    }}
                    projectId={projectId}
                    existingNotes={project.thirdPartyProjectNotes}
                    existingUrl={project.thirdPartyProjectUrl}
                />
            )}
            {showExportModal && (
                <ExporterModal
                    closeModal={() => {
                        setShowExportModal(false);
                    }}
                    projectId={projectId}
                    project={project}
                />
            )}
            {showComplianceModal && (
                <ComplianceModal
                    closeModal={() => {
                        setShowComplianceModal(false);
                    }}
                    projectId={projectId}
                />
            )}
            {showAnswerSourcesModal && (
                <AnswerSourceModal
                    closeModal={() => {
                        setShowAnswerSourcesModal(false);
                    }}
                    projectId={projectId}
                />
            )}
            {showDeleteProjectModal && (
                <DeleteProjectModal
                    closeModal={() => {
                        setShowDeleteProjectModal(false);
                    }}
                    projectId={projectId}
                />
            )}
            {showInviteModal && (
                <InviteModal
                    closeModal={() => {
                        setShowInviteModal(false);
                        refreshInvites();
                    }}
                    projectId={projectId}
                />
            )}
            {showSubmitModal && (
                <ConfirmationDialog
                    title="Submit Response"
                    descriptionText={[
                        'Submitting your response will finalise your project.',
                        'This action is not reversible.',
                    ]}
                    primaryLabel="Submit Response"
                    primaryAction={() => {
                        submitProject();
                        setShowSubmitModal(false);
                    }}
                    cancelAction={() => {
                        setShowSubmitModal(false);
                    }}
                />
            )}
        </div>
    );
};
