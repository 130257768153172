import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { ProgressMeter, ProgressSegment } from 'components/duex/ProgressMeter';
import Tooltip, { getTooltipId } from 'components/duex/Tooltip';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import { ExportToCsv } from 'export-to-csv-fix-source-map';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import React, { ReactElement, useEffect, useState } from 'react';
import Project, { ProgressCount } from 'types/project.type';
import { API } from 'utility/Api';
import { addToClipboard } from 'utility/Clipboard';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

const colours = {
    INCOMPLETE: '',
    'IN-PROGRESS': 'bg-gray-400',
    COMPLETE: 'bg-success-dark',
};

const order = {
    INCOMPLETE: 3,
    'IN-PROGRESS': 2,
    COMPLETE: 1,
};

const getCompletionText = (progessCounts: ProgressCount[], file): string => {
    let totalCount = 0;

    const relevantCounts = _filter(progessCounts, {
        projectSourceId: file.projectSourceId,
    });

    relevantCounts.forEach(({ count }) => {
        totalCount = totalCount + parseInt(count, 10);
    });

    let incomplete = 0;

    for (let i = 0; i < relevantCounts.length; i++) {
        const { count, responseStatus } = relevantCounts[i];
        const num = parseInt(count, 10);

        if (responseStatus !== 'COMPLETE') {
            incomplete = incomplete + num;
        }
    }

    // const percentage = ((totalCount - incomplete) / totalCount) * 100;

    if (relevantCounts.length === 0) {
        return `Unavailable`;
    } else if (incomplete > 0) {
        return `Incomplete (${incomplete} to go)`;
    } else if (totalCount === 0) {
        return `No Questions`;
    } else {
        return 'Completed (100%)';
    }
};

const formatProgress = (progessCounts: ProgressCount[], file): [string, ProgressSegment[]] => {
    let totalCount = 0;
    let completed = 0;

    const relevantCounts = _filter(progessCounts, {
        projectSourceId: file.projectSourceId,
    });

    relevantCounts.forEach(({ count, responseStatus }) => {
        totalCount = totalCount + parseInt(count, 10);

        if (responseStatus === 'COMPLETE') {
            completed = completed + parseInt(count, 10);
        }
    });

    const segments = _sortBy(
        relevantCounts.map(({ count, responseStatus }) => {
            return {
                percentage: parseInt(count, 10) / totalCount,
                colour: colours[responseStatus],
                order: order[responseStatus],
            };
        }),
        'order',
    );

    const description = totalCount > 0 ? `${completed} of ${totalCount} Completed` : '';

    return [description, segments];
};

export const Exporter = ({ projectId, project }: { projectId: string; project: Project }): ReactElement => {
    const [nonFiles, setNonFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [isSaving, setIsSaving] = useState({});
    const [fetchingQuestions, setFetchingQuestions] = useState(false);
    const [fetchingFiles, setFetchingFiles] = useState(false);

    const updateSaving = (identifier: string, status: boolean) => {
        setIsSaving({
            ...isSaving,
            [identifier]: status,
        });
    };

    const fetchManuallyCreated = async () => {
        try {
            setFetchingQuestions(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_MANUALLY_CREATED_QUESTIONS, {
                projectId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                setQuestions(results.data);
            }
            setFetchingQuestions(false);
        } catch (err) {
            setFetchingQuestions(false);
            showError('There was an error fetching the manually created Questions', err);
        }
    };

    const fetchData = async () => {
        try {
            setFetchingFiles(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_SOURCE_DOCS, {
                projectId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                setFiles(
                    results.data.filter((source) => {
                        return source.sourceFile;
                    }),
                );
                setNonFiles(
                    results.data.filter((source) => {
                        return !source.sourceFile;
                    }),
                );
            }
            setFetchingFiles(false);
        } catch (err) {
            showError('There was an error fetching the list of source documents', err);
            setFetchingFiles(false);
        }
    };

    const download = async (selectedFile) => {
        const identifier = `ANSWERS_${selectedFile.projectSourceId}`;

        try {
            updateSaving(identifier, true);

            let url = '';
            const isPdf = selectedFile.fileFormat === 'pdf';

            if (selectedFile.fileFormat === 'docx' || isPdf) {
                url = ENDPOINTS.getUrl(CONSTANTS.DOWNLOAD_PROJECT_SOURCE_DOCUMENT, {
                    projectId,
                    projectSourceId: selectedFile.projectSourceId,
                });
            } else {
                url = ENDPOINTS.getUrl(CONSTANTS.DOWNLOAD_PROJECT_SOURCE, {
                    projectId,
                    projectSourceId: selectedFile.projectSourceId,
                });
            }

            const response = await API.getBlob(url);

            if (response && response.data) {
                const downloadUrl = URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.href = downloadUrl;

                if (isPdf) {
                    // Because it's originally a PDF, it'll be downloaded as a DocX for the user
                    a.download = selectedFile.fileName.replace('.pdf', '.docx');
                } else {
                    a.download = selectedFile.fileName;
                }

                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            updateSaving(identifier, false);
        } catch (err) {
            showError('There was an error downloading your source document', err);
            updateSaving(identifier, false);
        }
    };

    const downloadManualCSV = () => {
        const headers = ['Question', 'Answer', 'Compliance Status'];

        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            filename: 'pearler-export',
            useTextFile: false,
            useBom: true,
            headers,
        };

        const data = _map(questions, ({ question, exportAnswer, exportClassification }) => {
            return {
                question,
                exportAnswer,
                exportClassification,
            };
        });

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
    };

    const downloadCSV = async (selectedFile) => {
        const identifier = `CSV_${selectedFile.projectSourceId}`;

        try {
            updateSaving(identifier, true);

            const url = ENDPOINTS.getUrl(CONSTANTS.DOWNLOAD_DOCUMENT_AS_CSV, {
                projectId,
                projectSourceId: selectedFile.projectSourceId,
            });

            const response = await API.getBlob(url);

            if (response && response.data) {
                const downloadUrl = URL.createObjectURL(response.data);
                const a = document.createElement('a');
                a.href = downloadUrl;

                if (selectedFile.fileFormat === 'xlsx') {
                    a.download = 'pearler-export.xlsx';
                } else {
                    a.download = 'pearler-export.csv';
                }

                document.body.appendChild(a);
                a.click();
                a.remove();
            }

            updateSaving(identifier, false);
        } catch (err) {
            showError('There was an error downloading your data', err);
            updateSaving(identifier, false);
        }
    };

    const downloadOriginal = (selectedFile) => {
        window.open(selectedFile.downloadLink, '_blank');
    };

    useEffect(() => {
        fetchData();
        fetchManuallyCreated();
    }, [projectId]);

    const headerClasses = `p-16 text-left text-12 sticky top-0 z-1 bg-white border-b border-gray-100 text-gray-500`;
    const formattedCellClasses = `p-16 overflow-hidden font-body`;
    const rowClasses = `${formattedCellClasses} whitespace-pre-line break-words`;

    if (fetchingQuestions || fetchingFiles) {
        return <LoadingSpinner className="" />;
    }

    if (!fetchingQuestions && !fetchingFiles && questions.length === 0 && files.length === 0) {
        return (
            <EmptyState
                width="w-440"
                title="No Files or Questions to Export"
                subtitle="This is where you're able to export Questions that you've imported or created."
            />
        );
    }

    const tooltipId = getTooltipId('exporter');

    return (
        <>
            <div className="flex max-h-full w-full flex-row gap-16">
                <div className="w-100-percent">
                    {questions.length > 0 && (
                        <>
                            <div className="flex flex-row justify-between">
                                <div className="my-4 ml-16 text-12 font-bold uppercase">Created Questions</div>
                                <Button buttonType="SECONDARY" onClick={downloadManualCSV} label="Download CSV" />
                            </div>
                            <table className="mb-32 table w-full">
                                <thead>
                                    <tr key="header-row" className="text-black">
                                        <th className={`${headerClasses} w-2/5`}>Question</th>
                                        <th className={`${headerClasses} w-2/5`}>Answer</th>
                                        <th className={`${headerClasses} w-1/5`}>Compliance Status</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {questions.map((question) => {
                                        return (
                                            <tr
                                                key={question.questionId}
                                                className={`overflow-hidden rounded-lg text-12 hover:bg-gray-50`}
                                                data-tooltip-content="Click on a cell to copy the content"
                                                data-tooltip-id={tooltipId}
                                            >
                                                <td
                                                    className={`${rowClasses} ${
                                                        question.question ? 'cursor-pointer' : ''
                                                    }`}
                                                    onClick={() => {
                                                        if (question.question) {
                                                            navigator.clipboard.writeText(question.question);
                                                            showSuccess('Question copied to clipboard');
                                                        }
                                                    }}
                                                >
                                                    {question.question}
                                                </td>
                                                <td
                                                    data-cy={'Answer_' + question.responseClassification}
                                                    className={`${formattedCellClasses} ${
                                                        question.exportHtmlAnswer ? 'cursor-pointer' : ''
                                                    } render-html-normally`}
                                                    onClick={() => {
                                                        if (question.exportHtmlAnswer) {
                                                            addToClipboard(question.answer, question.exportHtmlAnswer);
                                                            showSuccess('Answer copied to clipboard');
                                                        }
                                                    }}
                                                    dangerouslySetInnerHTML={{
                                                        __html: question.exportHtmlAnswer,
                                                    }}
                                                />
                                                <td
                                                    data-cy={'Status_' + question.responseClassification}
                                                    className={`${rowClasses} ${
                                                        question.exportClassification ? 'cursor-pointer' : ''
                                                    }`}
                                                    onClick={() => {
                                                        if (question.exportClassification) {
                                                            navigator.clipboard.writeText(
                                                                question.exportClassification,
                                                            );
                                                            showSuccess('Compliance Status copied to clipboard');
                                                        }
                                                    }}
                                                >
                                                    {question.exportClassification}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <Tooltip id={tooltipId} />
                        </>
                    )}
                    {files.length > 0 && (
                        <div className="flex flex-row justify-between">
                            <div className="my-4 ml-16 text-12 font-bold uppercase">Uploaded Files</div>
                            <div className="my-4 mr-16 text-12 font-bold uppercase">Download</div>
                        </div>
                    )}
                    {files.map((file, fileIndex) => {
                        const CSV_IDENTIFIER = `CSV_${file.projectSourceId}`;
                        const ANSWERS_IDENTIFIER = `ANSWERS_${file.projectSourceId}`;

                        const [description, segments] = formatProgress(project.progressCount, file);

                        return (
                            <div
                                key={fileIndex}
                                className="mb-16 overflow-hidden rounded-lg opacity-90 hover:opacity-100"
                            >
                                <div className="select-none bg-gray-50 px-8 py-16 text-12 font-medium text-blue-800 hover:bg-blue-50">
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <p className="leading-20">File: {file.fileName}</p>
                                            {file.fileFormat !== 'docx' && file.fileFormat !== 'pdf' && (
                                                <p className="leading-20 text-gray-700">
                                                    Contains {file.sheets.length}{' '}
                                                    {file.sheets.length > 1 ? 'sheets' : 'sheet'}
                                                </p>
                                            )}
                                        </div>

                                        <div className="flex flex-row-reverse gap-16">
                                            <Button
                                                onClick={() => {
                                                    download(file);
                                                }}
                                                loading={isSaving[ANSWERS_IDENTIFIER]}
                                                disabled={isSaving[ANSWERS_IDENTIFIER]}
                                                label="Download with Answers"
                                            />

                                            <Button
                                                buttonType="TERTIARY"
                                                onClick={() => {
                                                    downloadOriginal(file);
                                                }}
                                                label="Original File"
                                            />
                                            {(file.sheets || file.document) && (
                                                <Button
                                                    buttonType="TERTIARY"
                                                    onClick={() => {
                                                        downloadCSV(file);
                                                    }}
                                                    loading={isSaving[CSV_IDENTIFIER]}
                                                    disabled={isSaving[CSV_IDENTIFIER]}
                                                    label="Download Data"
                                                />
                                            )}
                                            <div className="w-144">
                                                <div className="mb-8">
                                                    {getCompletionText(project.progressCount, file)}
                                                </div>
                                                <ProgressMeter
                                                    segments={segments}
                                                    description={description}
                                                    width={100}
                                                    baseColor="bg-gray-200"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    {nonFiles.length > 0 && (
                        <div className="my-4 ml-16 text-12 font-bold uppercase">Copied into Pearler</div>
                    )}
                    {/* This below section is legacy and is just to ensure that old data could be exported */}
                    {nonFiles.map((file, fileIndex) => {
                        return (
                            <div
                                key={fileIndex}
                                className="mb-16 overflow-hidden rounded-lg opacity-90 hover:opacity-100"
                            >
                                <div className="select-none bg-gray-50 px-8 py-16 text-12 font-medium text-blue-800 hover:bg-blue-50">
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <p className="leading-20">
                                                {file.fileName ? `File: ${file.fileName}` : 'Copied into Pearler'}
                                            </p>
                                            <p className="leading-20 text-gray-700">
                                                Contains {file.sheets.length} {file.fileName ? `sheet` : 'section'}
                                                {file.sheets.length > 1 ? 's' : ''}
                                            </p>
                                        </div>

                                        <ButtonBar>
                                            <Button
                                                label="Download with Answers"
                                                disabled={isSaving[file.projectSourceId]}
                                                onClick={() => {
                                                    download(file);
                                                }}
                                                loading={isSaving[file.projectSourceId]}
                                            />
                                        </ButtonBar>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
