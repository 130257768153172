import React, { ReactElement, ReactNode } from 'react';

export const Label = ({ title }: { title: string }): ReactElement => {
    return <p className="select-none text-14 font-medium text-gray-600">{title}</p>;
};
export const Value = ({
    title,
    preventWrapping = false,
}: {
    title: string;
    preventWrapping?: boolean;
}): ReactElement => {
    return (
        <p
            className={`text-black-90 h-40 text-14 leading-40 ${
                preventWrapping ? 'overflow-hidden text-ellipsis whitespace-nowrap' : ''
            }`}
        >
            {title}
        </p>
    );
};
export const FormattedValue = ({ title }: { title: string | ReactNode }): ReactElement => {
    return <p className="text-black-90 whitespace-pre-line break-words text-14 leading-40">{title}</p>;
};

export const SectionTitle = ({ title }: { title: string }): ReactElement => {
    return <div className="mt-8 select-none text-18 font-medium text-blue-500">{title}</div>;
};

export const TextBlock = ({ children }: { children: ReactNode }): ReactElement => {
    return <div className="my-4 select-none rounded-lg bg-gray-50 p-16">{children}</div>;
};
