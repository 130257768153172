import { EmptyState } from 'components/duex/EmptyState';
import _findIndex from 'lodash/findIndex';
import { SearchResult } from 'modules/answer-library/analytics/search-result';
import { AnswerAnalyticsModal } from 'modules/answer-library/answer-analytics.modal';
import React, { ReactElement, useState } from 'react';
import { Answer } from 'types';

export const SearchResults = ({ results }: { results: Answer[] }): ReactElement => {
    const [showAnswerAnalyticsforId, setShowAnswerAnalyticsforId] = useState(null);

    let nextAnswerId, previousAnswerId;

    if (showAnswerAnalyticsforId) {
        const selectedItem = _findIndex(results, {
            answerId: showAnswerAnalyticsforId,
        });

        if (selectedItem !== 0) {
            previousAnswerId = results[selectedItem - 1]?.answerId;
        }

        if (selectedItem + 1 !== results.length) {
            nextAnswerId = results[selectedItem + 1]?.answerId;
        }
    }

    return (
        <div>
            <h1 className="my-16 text-center text-lg text-primary-blue-100">Search Results</h1>
            {results.length === 0 && (
                <EmptyState title="No results found" subtitle="Your library doesn't have any matching Answers" />
            )}
            {results.length > 0 && (
                <div className="grid gap-16">
                    {results.map((answer) => {
                        return (
                            <SearchResult
                                answer={answer}
                                key={answer.answerId}
                                showMore={() => {
                                    setShowAnswerAnalyticsforId(answer.answerId);
                                }}
                            />
                        );
                    })}
                </div>
            )}
            {showAnswerAnalyticsforId && (
                <AnswerAnalyticsModal
                    closeModal={() => {
                        setShowAnswerAnalyticsforId(null);
                    }}
                    answerId={showAnswerAnalyticsforId}
                    previousAnswer={() => {
                        if (previousAnswerId) {
                            setShowAnswerAnalyticsforId(previousAnswerId);
                        }
                    }}
                    hasPreviousAnswer={previousAnswerId}
                    nextAnswer={() => {
                        if (nextAnswerId) {
                            setShowAnswerAnalyticsforId(nextAnswerId);
                        }
                    }}
                    hasNextAnswer={nextAnswerId}
                />
            )}
        </div>
    );
};
