import React from 'react';

interface Item {
    value: string;
    label: string;
}

export const SimpleTextSelection = ({
    items,
    itemClasses,
    value,
    changeSelection,
    disabled = false,
}: {
    items: Item[];
    itemClasses?: string;
    value: string;
    changeSelection: (newValue: string) => void;
    disabled?: boolean;
}) => {
    return (
        <div className={`${disabled ? 'opacity-50' : ''} inline-block rounded-full border border-gray-300 px-4`}>
            {items.map((item: Item, index: number) => {
                const selected = value === item.value;
                const classes = `${
                    selected ? 'text-blue-500' : `text-black-80 font-medium ${!disabled ? 'hover:underline' : ''}`
                } ${index === items.length - 1 ? '' : 'border-r border-gray-300'} ${
                    !disabled ? 'cursor-pointer' : ''
                } body-2-reg px-4 py-2 ${itemClasses}`;

                return (
                    <span
                        key={index}
                        className={classes}
                        onClick={() => {
                            if (!disabled) {
                                changeSelection(item.value);
                            }
                        }}
                    >
                        {item.label}
                    </span>
                );
            })}
        </div>
    );
};
