import { Check } from 'assets/check.svg';
import { Cross } from 'assets/close.svg';
import { DropDownArrow } from 'assets/drop-close-arrow.svg';
import { Search } from 'assets/magnify.svg';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import _truncate from 'lodash/truncate';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { EnhancedUser, Membership, Role, User } from 'types';
import { Avatar } from './duex/Avatar';
import { LoadingSpinner } from './duex/LoadingSpinner';

function useOutsideAlerter(ref, toggleDropDownState) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
interface SearchItem {
    id?: string;
    skipFiltering?: boolean;
    skipSorting?: boolean;
    name: string;
    userId: string;
    photo: string;
    emailAddress: string;
    member: {
        givenName: string;
        lastName: string;
        fullName: string;
    };
}
interface DropDownWithSearchProps {
    teamMembers:
        | {
              id: string;
              display: string;
              photo: string;
              member: User;
              role: Role;
          }[]
        | Membership[];
    selectedMember;
    showArrow: boolean;
    showThumbNail: boolean;
    classForInput: string;
    stylingOfValue: string;
    showListItemCheck: boolean;
    callBackFunction: (newItem: EnhancedUser) => void;
    defaultItem?: SearchItem;
    alwaysShowDefault: boolean;
    showLoading?: boolean;
    dataCy?: string;
}

export const DropDownWithSearch = ({
    teamMembers,
    selectedMember,
    showArrow,
    classForInput,
    showThumbNail,
    stylingOfValue,
    showListItemCheck,
    callBackFunction,
    defaultItem,
    alwaysShowDefault,
    showLoading = false,
    dataCy,
}: DropDownWithSearchProps): ReactElement => {
    let sortedList = sortBy(teamMembers, (membership: Membership) => {
        const fullName = get(membership, 'member.fullName', '');
        return fullName.toLowerCase();
    });

    if (defaultItem) {
        sortedList = [
            {
                ...defaultItem,
                isDefault: true,
            },
            ...sortedList,
        ];
    }

    const [searchQuery, updateSearch] = useState('');
    const [dropdownList, updateDropdownList] = useState(sortedList);
    const [isOpen, toggleDropDownState] = useState(false);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleDropDownState);

    const updateList = (data: string) => {
        const value = data.toLowerCase();
        if (value) {
            const filteredList = sortedList.filter((itm) => {
                const name = `${itm.member.givenName} ${itm.member.lastName}`.toLowerCase(),
                    team = (itm.group && itm.group.groupName && itm.group.groupName.toLowerCase()) || '',
                    isPass = name.includes(value) || team.includes(value);
                return isPass;
            });
            updateDropdownList(filteredList);
        } else {
            updateDropdownList(sortedList);
        }
        updateSearch(value);
    };

    const defaultItemSelected = selectedMember?.id === defaultItem?.id;
    const showDefaultItem = alwaysShowDefault || !defaultItemSelected;

    useEffect(() => {
        if (!isOpen) {
            updateSearch('');
            updateDropdownList(sortedList);
        }
    }, [isOpen, teamMembers]);

    return (
        <div className="relative" ref={wrapperRef}>
            {showLoading && <LoadingSpinner className="mt-24" />}
            <div
                className={`cursor-pointer ${showLoading ? 'invisible' : 'visible'} ${classForInput}`}
                onClick={() => toggleDropDownState(!isOpen)}
            >
                {showThumbNail && <Avatar size={32} userId={selectedMember.id} />}
                <div
                    data-cy="Assigned_Member"
                    className={`${stylingOfValue} truncate ${isOpen ? 'select-none text-primary-blue-100' : ''}`}
                >
                    {_truncate(selectedMember.name, { length: 50 })}
                </div>
                {showArrow && (
                    <p
                        data-cy={dataCy}
                        className={`ml-20 transition duration-200 ease-linear ${isOpen ? 'rotate-180' : ''}`}
                    >
                        <DropDownArrow color={` ${isOpen ? '#2068E3' : 'currentColor'}`} />
                    </p>
                )}
            </div>
            <div
                className={`relative top-10 z-2 ${
                    isOpen ? 'opacity-1 animate-grow-down-dropdown-animation' : 'pointer-events-none opacity-0'
                }`}
            >
                <div className="absolute -left-15 w-256 rounded-md bg-white py-8 shadow-lg">
                    <div className="mb-24 flex items-center px-16">
                        <div className="absolute left-22">
                            <Search strokeColor={'#2068E3'} />
                        </div>
                        <input
                            type="text"
                            value={searchQuery}
                            autoComplete="off"
                            onChange={(e?: React.ChangeEvent<HTMLInputElement>) => updateList(e.target.value)}
                            className="body-2-reg w-full select-none truncate rounded-full border border-primary-blue-100 px-40 py-8 focus:border-none focus:outline"
                        />
                        <div className="absolute right-22 cursor-pointer" onClick={() => updateList('')}>
                            <Cross strokeColor={'#2068E3'} />
                        </div>
                    </div>
                    <div className="mb-8 flex items-center px-16">
                        <span className="small-1-med mr-6 select-none text-black-60">People</span>
                        <div className="w-full border-t border-solid border-gray-light-1"></div>
                    </div>
                    <ul className="max-h-192 overflow-auto">
                        {dropdownList.map((itm) => {
                            let isSelected = false;
                            if (itm.member.userId) {
                                isSelected = selectedMember.id === itm.member.userId;
                            } else {
                                isSelected = selectedMember.name === `${itm.member.givenName} ${itm.member.lastName}`;
                            }

                            return (
                                <li
                                    key={itm.userId || 'default'}
                                    onClick={() => {
                                        toggleDropDownState(!isOpen);
                                        callBackFunction(itm);
                                    }}
                                    className={`flex cursor-pointer items-center justify-between py-9 ${
                                        isSelected
                                            ? 'bg-primary-blue-100-opace-1 text-primary-blue-100'
                                            : 'hover:bg-primary-blue-100-opace-4'
                                    }
                                        ${
                                            !showDefaultItem && itm.isDefault ? 'hidden' : ''
                                        } px-8 text-black-80 hover:text-primary-blue-100`}
                                >
                                    <div className="grid gap-x-8" style={{ gridTemplateColumns: '38px auto 20px' }}>
                                        <div
                                            className={`h-38 w-38 rounded-full border p-2 ${
                                                isSelected ? 'border-primary-blue-100' : 'border-transparent'
                                            } `}
                                        >
                                            <Avatar size={32} userId={itm.member.userId} />
                                        </div>
                                        <div
                                            className="flex flex-wrap items-center overflow-hidden"
                                            style={{
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <p
                                                data-cy="DropDownName"
                                                className="subtitle-2-reg w-full select-none truncate"
                                            >
                                                {`${itm.member.givenName} ${itm.member.lastName}`}
                                            </p>
                                            {itm.group && (
                                                <p className="small-3-reg mt-4 w-full select-none truncate">
                                                    {itm.group.groupName}
                                                </p>
                                            )}
                                        </div>
                                        {isSelected && showListItemCheck && (
                                            <p className="pt-8">
                                                <Check strokeColor={'#2068E3'} />
                                            </p>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};
