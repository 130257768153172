import { CopyLink } from 'assets/copy-link.svg';
import { Button } from 'components/duex/Button';
import { showSuccess } from 'components/success-toast.component';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

function useOutsideAlerter(ref, toggleDropDownState) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export const ShareModal = (): ReactElement => {
    const url = window.location.href;
    const [valueCopied, toggleCopyStatus] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);
        showSuccess('Link copied to clipboard');
        toggleCopyStatus(true);
    };

    const [, toggleDropDownState] = useState(true);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleDropDownState);

    useEffect(() => {
        let timerReference;

        if (timerReference) {
            clearTimeout(timerReference);
        }

        if (valueCopied) {
            timerReference = setTimeout(() => toggleCopyStatus(false), 3000);
        }

        return () => {
            clearTimeout(timerReference);
        };
    }, [valueCopied]);

    return (
        <>
            <div className="arrow-up-white absolute -bottom-15 right-20-percent z-2 inline"></div>
            <div className="absolute right-0 top-50 z-3 w-565 rounded-md text-left shadow-lg">
                <div className="shadow-xs cursor-default rounded-md bg-white px-32 py-24">
                    <h5 className="h5-medium mb-8 select-none">Share Question</h5>
                    <p className="body-1-reg mb-30 select-none">
                        Copy and paste the link below into emails, chats or browsers.
                    </p>
                    <div className="mb-24 flex justify-between">
                        <div className="flex w-80-percent items-center rounded-md border border-light-gray px-16 py-12 ">
                            <p>
                                <CopyLink />
                            </p>
                            <p className="body-1-reg ml-9 select-none truncate text-black-60">{url}</p>
                        </div>
                        <div className="pt-5">
                            <Button
                                buttonType={valueCopied ? 'SUCCESS' : 'PRIMARY'}
                                label={valueCopied ? 'Copied!' : 'Copy'}
                                onClick={copyToClipboard}
                            />
                        </div>
                    </div>
                    <p className="subtitle-2-reg select-none text-black-60">
                        Don&apos;t worry, only authenticated users can use this link.
                    </p>
                </div>
            </div>
        </>
    );
};
