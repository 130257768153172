import { useUserContext } from 'hooks/useUserContext';
import _map from 'lodash/map';
import React, { ReactElement } from 'react';
import { User } from 'types';
const sizeClasses = {
    24: 'w-24 h-24',
    32: 'w-32 h-32',
    40: 'w-40 h-40',
    48: 'w-48 h-48',
    144: 'w-144 h-144',
};

interface AvatarProps {
    classes?: string;
    userId?: string;
    user?: User;
    size: 24 | 32 | 40 | 48 | 144;
    extraClasses?: string;
}

function getInitials(fullName = ''): string {
    const names = fullName.split(' ');
    const initials = _map(names, (name: string) => {
        return name.charAt(0).toUpperCase();
    });
    return initials.join('');
}

export const Avatar = ({ userId, size, user: userProfile, extraClasses = '' }: AvatarProps): ReactElement => {
    const baseClasses = `rounded-full ${extraClasses}`;
    const { getUser } = useUserContext();

    if (!userId && !userProfile) {
        return (
            <span
                className={`${sizeClasses[size]} ${baseClasses} small-1-med flex select-none items-center justify-center bg-gray-100 text-app-blue`}
            ></span>
        );
    }

    const user = userProfile || getUser(userId);
    const imageSrc = user.photo;
    const imageAltText = user.fullName;
    const initials = getInitials(user.fullName);

    return (
        <>
            {imageSrc ? (
                <img
                    src={imageSrc}
                    className={`${sizeClasses[size]} ${baseClasses} bg-faded-blue object-cover`}
                    alt={`${imageAltText}'s photo`}
                    title={imageAltText}
                />
            ) : (
                <span
                    className={`${sizeClasses[size]} ${baseClasses} ${
                        initials.length > 3 ? 'small-4-reg' : 'small-1-med'
                    } flex select-none items-center  justify-center overflow-hidden bg-faded-blue text-app-blue`}
                    title={imageAltText}
                >
                    {initials}
                </span>
            )}
        </>
    );
};
