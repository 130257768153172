import _filter from 'lodash/filter';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _orderBy from 'lodash/orderBy';
import _uniqBy from 'lodash/uniqBy';
import { Activity, Comment, Membership } from 'types';
export interface Segment {
    content: string;
    type: 'TEXT' | 'MEMBER';
    id?: string;
}

function findMentions(text: string, teamMemberList: Membership[]) {
    return _filter(teamMemberList, ({ member: { userId } }) => {
        return text.indexOf(`{user:${userId}}`) !== -1;
    });
}

function truncateNameIfNecessary(name: string) {
    if (name.length > 30) {
        return `${name.slice(0, 27)}...`;
    }
    return name;
}

const createCommentSegments = (
    item: Activity | Comment,
    teamMemberList: Membership[],
    commentKey: string,
): {
    segments: Segment[];
} => {
    const description = item[commentKey];

    const mentionedMembers = findMentions(description, teamMemberList);

    const indexes = _map(mentionedMembers, ({ member: { fullName, userId } }) => {
        const key = `{user:${userId}}`;
        const matches = Array.from(description.matchAll(key));
        return _map(matches, (match) => {
            return {
                index: match.index,
                fullName,
                endIndex: match.index + key.length,
                userId,
            };
        });
    });
    const cleanedIndexes = _orderBy(_uniqBy(_flatten(indexes), 'index'), 'index');

    const segments = [];
    let position = 0;

    for (let i = 0; i < cleanedIndexes.length; i++) {
        const { index, fullName, userId, endIndex } = cleanedIndexes[i];
        const isLastItem = i >= cleanedIndexes.length - 1;

        if (index > position) {
            segments.push({
                type: 'TEXT',
                content: description.slice(position, index),
            });
            position = index;
        }

        segments.push({
            type: 'MEMBER',
            content: truncateNameIfNecessary(fullName),
            id: userId,
        });

        position = endIndex;

        if (isLastItem) {
            segments.push({
                type: 'TEXT',
                content: description.slice(position, description.length),
            });

            position = description.length - 1;
        }
    }

    if (position < description.length - 1) {
        segments.push({
            type: 'TEXT',
            content: description.slice(position, description.length),
        });
    }

    return {
        ...item,
        segments,
    };
};

// eslint-disable-next-line
export const prepareActivityListElements = (activityList: Activity[], teamMembersList: Membership[]) => {
    return _map(activityList, (activity) => {
        return createCommentSegments(activity, teamMembersList, 'activityDescription');
    });
};

// eslint-disable-next-line
export const prepareDiscussionListElements = (discussionList: Comment[], teamMembersList: Membership[]) => {
    return _map(discussionList, (comment) => {
        return createCommentSegments(comment, teamMembersList, 'comment');
    });
};
