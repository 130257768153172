import React, { ReactElement } from 'react';

export const Check = ({ strokeColor }: { strokeColor: string }): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6668 5L7.50016 14.1667L3.3335 10"
                stroke={strokeColor || '#2068E3'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
