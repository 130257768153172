import { ModalCross } from 'assets/modal-cross.svg';
import React, { ReactElement, ReactNode } from 'react';

const Shield = ({
    children,
    showClose,
    closeModal,
}: {
    closeModal: () => void;
    children: ReactNode;
    showClose?: boolean;
}) => (
    <div className={'fixed inset-0 z-10'}>
        <div
            className="fixed h-full w-full bg-primary-blue-90-opace-4 opacity-75"
            onClick={() => {
                if (showClose) closeModal();
            }}
        ></div>
        <div className="mx-auto flex h-full flex-col items-center justify-center overflow-y-auto">{children}</div>
    </div>
);

export const Modal = ({
    dataCy = '',
    closeModal,
    children,
    width,
    height = 'auto',
    layout = 'flex-col',
    title,
    showClose = true,
    closeSafely,
    leftControl,
    rightControl,
}: {
    dataCy?: string;
    closeModal?: () => void;
    children: ReactNode;
    width: string;
    height?: 'auto' | 'full';
    layout?: 'flex-col' | 'flex-row';
    title: string;
    showClose?: boolean;
    closeSafely?: () => boolean;
    leftControl?: ReactNode;
    rightControl?: ReactNode;
}): ReactElement => {
    const wrappedClose = () => {
        if (closeSafely) {
            if (!closeSafely()) {
                if (window.confirm('Are you sure you want to close? Any unsaved work will be lost.')) {
                    if (closeModal) {
                        closeModal();
                    }
                } else {
                    return;
                }
            }
        }
        if (closeModal) {
            closeModal();
        }
    };

    let startingHeightClass = '';

    if (height === 'full') {
        startingHeightClass = 'h-full';
    }

    return (
        <Shield closeModal={wrappedClose} showClose={showClose}>
            <div
                className={`${
                    leftControl || rightControl ? 'flex flex-row gap-16 items-center' : ''
                } relative z-10 max-h-full ${width} ${startingHeightClass}`}
            >
                {leftControl}
                <div
                    data-cy={dataCy}
                    className={`relative z-10 gap-24 grid grow max-h-full overflow-hidden rounded-lg bg-white py-32 shadow-xl ${startingHeightClass}`}
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    style={{
                        gridTemplateRows: '28px calc(100% - 50px)',
                    }}
                >
                    <div className="px-32">
                        <h5 className="h5-medium select-none text-center">{title}</h5>
                        {showClose && (
                            <div
                                className="absolute right-32 top-36 cursor-pointer opacity-50 hover:opacity-100"
                                onClick={wrappedClose}
                            >
                                <ModalCross />
                            </div>
                        )}
                    </div>
                    <div className={`px-32 overflow-y-auto flex max-h-full ${layout}`}>{children}</div>
                </div>
                {rightControl}
            </div>
        </Shield>
    );
};
