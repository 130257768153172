import { Dash } from 'assets/dash.svg';
import { Tick } from 'assets/tick.svg';
import React, { ReactElement } from 'react';
import type { Question as QuestionType } from 'types/';
import { CONSTANTS } from 'utility/Constants';

export const QuestionStatus = ({ question }: { question: QuestionType }): ReactElement => {
    let statusColor;
    switch (question?.responseClassification) {
        case CONSTANTS.INCOMPLETE:
            statusColor = 'bg-faded-blue';
            break;
        case CONSTANTS.COMPLIANT:
            statusColor = 'bg-success-dark';
            break;
        case CONSTANTS.PARTIALLYCOMPLIANT:
            statusColor = 'bg-warning-dark';
            break;
        case CONSTANTS.NONCOMPLIANT:
            statusColor = 'bg-red';
            break;
        case CONSTANTS.NOTAPPLICABLE:
            statusColor = 'bg-black-60';
            break;
        default:
            statusColor = 'bg-faded-blue';
            break;
    }
    return (
        <div className={`flex h-16 w-16 items-center justify-center rounded-full ${statusColor}`}>
            {question?.responseStatus === CONSTANTS.COMPLETE ? (
                <Tick />
            ) : question?.responseStatus === CONSTANTS.INPROGRESS ? (
                <Dash />
            ) : (
                ''
            )}
        </div>
    );
};
