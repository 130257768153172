import React, { ReactElement } from 'react';

export const ComplianceExport = ({ strokeColor = 'currentColor' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.75 0.25H19C19.1989 0.25 19.3897 0.329018 19.5303 0.46967C19.671 0.610322 19.75 0.801088 19.75 1V6.25C19.75 6.66421 19.4142 7 19 7C18.5858 7 18.25 6.66421 18.25 6.25V2.81066L12.8535 8.79287C12.5607 9.08577 12.0858 9.08577 11.7929 8.79287C11.5 8.49997 11.5 8.02517 11.7929 7.73227L17.1893 1.75H13.75C13.3358 1.75 13 1.41421 13 1C13 0.585787 13.3358 0.25 13.75 0.25ZM3.25 4.75C2.42157 4.75 1.75 5.42157 1.75 6.25V16.75C1.75 17.5784 2.42157 18.25 3.25 18.25H13.75C14.5784 18.25 15.25 17.5784 15.25 16.75V8.5C15.25 8.08579 15.5858 7.75 16 7.75C16.4142 7.75 16.75 8.08579 16.75 8.5V16.75C16.75 18.4069 15.4069 19.75 13.75 19.75H3.25C1.59315 19.75 0.25 18.4069 0.25 16.75V6.25C0.25 4.59315 1.59315 3.25 3.25 3.25H11.5C11.9142 3.25 12.25 3.58579 12.25 4C12.25 4.41421 11.9142 4.75 11.5 4.75H3.25Z"
                fill={strokeColor}
            />
            <path
                d="M11.7892 10.2734H10.2338C10.2054 10.0722 10.1474 9.89347 10.0598 9.73722C9.97224 9.5786 9.85979 9.44366 9.72248 9.33239C9.58517 9.22112 9.42655 9.13589 9.24663 9.0767C9.06907 9.01752 8.87612 8.98793 8.66779 8.98793C8.29137 8.98793 7.96348 9.08144 7.68413 9.26847C7.40477 9.45312 7.18815 9.72301 7.03427 10.0781C6.88039 10.4309 6.80344 10.8594 6.80344 11.3636C6.80344 11.8821 6.88039 12.3177 7.03427 12.6705C7.19052 13.0232 7.40832 13.2895 7.68768 13.4695C7.96703 13.6494 8.29019 13.7393 8.65714 13.7393C8.8631 13.7393 9.05368 13.7121 9.22887 13.6577C9.40643 13.6032 9.56386 13.5239 9.70117 13.4197C9.83848 13.3132 9.95212 13.1842 10.0421 13.0327C10.1344 12.8812 10.1983 12.7083 10.2338 12.5142L11.7892 12.5213C11.749 12.8551 11.6484 13.1771 11.4874 13.4872C11.3288 13.795 11.1145 14.0708 10.8446 14.3146C10.5771 14.5561 10.2575 14.7479 9.88583 14.8899C9.51651 15.0296 9.09866 15.0994 8.63228 15.0994C7.98361 15.0994 7.40359 14.9527 6.89222 14.6591C6.38323 14.3655 5.98076 13.9406 5.68484 13.3842C5.39128 12.8279 5.2445 12.1544 5.2445 11.3636C5.2445 10.5705 5.39364 9.89583 5.69194 9.33949C5.99023 8.78314 6.39506 8.35937 6.90643 8.06818C7.41779 7.77462 7.99308 7.62784 8.63228 7.62784C9.05368 7.62784 9.44431 7.68703 9.80415 7.8054C10.1664 7.92377 10.4872 8.09659 10.7665 8.32386C11.0459 8.54877 11.2731 8.82457 11.4483 9.15128C11.6259 9.47798 11.7395 9.85204 11.7892 10.2734Z"
                fill={strokeColor}
            />
        </svg>
    );
};
