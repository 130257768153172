import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { useUserContext } from 'hooks/useUserContext';
import _map from 'lodash/map';
import { prepareDiscussionListElements } from 'modules/project/collaboration/collaboration.controller';
import moment from 'moment';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { Comment } from 'types';
import { CONSTANTS } from 'utility/Constants';
import { ProjectSharedProps } from '../project.view';
import { AddRecipientsModal } from './add-recipients';
import { InitialDiscussionStatus } from './initial-discussion.component';
import { Suggestion } from './suggestion.component';
export const Discussion = (props: ProjectSharedProps): ReactElement => {
    const {
        savingComment,
        questionId,
        questionDiscussion,
        project,
        createComment,
        projectTeam,
        setCollaborationHasChanges,
        fetchingDiscussion,
    } = props;

    const el = useRef(null);

    const { teamList, getUser } = useUserContext();

    const [showModal, setShowModal] = useState(false);
    const [recipients, setRecipients] = useState([]);
    const [comments, setComments] = useState('');

    useEffect(() => {
        if (el.current) {
            el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
    }, [questionDiscussion]);

    useEffect(() => {
        setComments('');
        setRecipients([]);
        setCollaborationHasChanges(false);
    }, [questionId]);

    useEffect(() => {
        setCollaborationHasChanges(Boolean(recipients.length > 0 || comments));
    }, [comments, recipients]);

    // It's important that this is coming via props because it's the Project team, not the whole team.
    const mentionsList = _map(projectTeam, ({ member, userId }) => {
        return {
            id: userId,
            display: member.fullName,
            photo: member.photo,
            member,
        };
    });

    // We use TeamList here because it's possible they were removed, and we want the photos to remain
    const discussionItems = prepareDiscussionListElements(questionDiscussion, teamList);

    function getDisplayName(comment: Comment): string {
        if (comment.createdByUserId) {
            const user = getUser(comment.createdByUserId);

            if (user && user.userId) {
                return user.fullName;
            }
        }
        if (comment.commentData) {
            return comment.commentData.fromEmailAddress;
        }
    }

    let recipientsButtonLabel = 'Email CC';

    if (recipients.length > 0) {
        recipientsButtonLabel = `CC to ${recipients.length} Email${recipients.length > 1 ? 's' : ''}`;
    }

    return (
        <div
            className="grid h-full max-h-full pt-24"
            style={{
                gridTemplateRows: 'auto 170px',
                height: `calc(100vh - 112px - ${props.projectClosed ? '32' : '0'}px)`,
            }}
        >
            {fetchingDiscussion && (
                <div className="flex h-full max-h-full items-center justify-center">
                    <LoadingSpinner />
                </div>
            )}
            {!fetchingDiscussion && discussionItems.length === 0 && <InitialDiscussionStatus />}
            {!fetchingDiscussion && discussionItems.length > 0 && (
                <ul className="h-full max-h-full overflow-y-auto px-16">
                    {discussionItems.map((itm) => (
                        <li key={itm.commentId}>
                            <div>
                                <div className="flex flex-row">
                                    <div>
                                        <Avatar size={32} userId={itm.createdByUserId} />
                                    </div>
                                    <div className="body-2-reg tracking-0.1 ml-8 w-348 truncate text-black-80">
                                        {getDisplayName(itm)}
                                        <p className="small-3-reg tracking-0.2 truncate text-light-gray">
                                            {moment(itm.createdAt).format('dddd, Do MMMM YYYY')}
                                        </p>
                                    </div>
                                </div>
                                <div className="body-1-reg tracking-0.1 mb-40 mt-16 whitespace-pre-line break-words text-black-100">
                                    {itm.segments.map((segment, index) => {
                                        if (segment.type === 'MEMBER') {
                                            return (
                                                <span title={segment.content} style={{ color: '#2068E3' }} key={index}>
                                                    {segment.content}
                                                </span>
                                            );
                                        }
                                        return segment.content;
                                    })}
                                </div>
                            </div>
                        </li>
                    ))}
                    <div id={'el'} ref={el}></div>
                </ul>
            )}
            {project.projectStatus !== CONSTANTS.PROJECT_COMPLETE && (
                <div className="flex flex-col border-t border-gray-200 bg-gray-50 p-16">
                    <div className="flex">
                        <MentionsInput
                            maxLength={4000}
                            value={comments}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setComments(e.target.value)}
                            style={{
                                input: {
                                    overflow: 'auto',
                                    height: 80,
                                },
                                highlighter: {
                                    boxSizing: 'border-box',
                                    overflow: 'hidden',
                                    height: 80,
                                },
                            }}
                            className="mentions body-1-reg tracking-0.1 block w-full rounded-md bg-white text-black transition duration-150 ease-in-out focus:border-none active:border-none"
                            allowSuggestionsAboveCursor={true}
                            placeholder="Use @ to mention team members..."
                            a11ySuggestionsListLabel={'Suggested mentions'}
                            spellCheck={true}
                        >
                            <Mention
                                markup="@[__display__]{user:__id__}"
                                trigger="@"
                                data={mentionsList}
                                renderSuggestion={(data) => <Suggestion {...data} />}
                                className="mentions__mention"
                            />
                        </MentionsInput>
                    </div>
                    <div className="mt-16 flex min-w-200 flex-row-reverse gap-16 rounded-lg">
                        <Button
                            label={recipientsButtonLabel}
                            buttonType="TERTIARY"
                            onClick={() => {
                                setShowModal(true);
                            }}
                            disabled={savingComment}
                        />
                        <Button
                            label="Send"
                            onClick={async () => {
                                const created = await createComment(comments, recipients);

                                if (created) {
                                    setRecipients([]);
                                    setComments('');
                                }
                            }}
                            loading={savingComment}
                            disabled={savingComment || !comments}
                        />
                    </div>
                </div>
            )}
            {showModal && (
                <AddRecipientsModal
                    recipients={recipients}
                    setRecipients={(recipientList) => {
                        setRecipients(recipientList);
                    }}
                    closeModal={() => {
                        setShowModal(false);
                    }}
                />
            )}
        </div>
    );
};
