import { ChainLink } from 'assets/chain.svg';
import { CheckShield } from 'assets/check-shield.svg';
import { Cross } from 'assets/cross.svg';
import { AnswerExport } from 'assets/export-answer.svg';
import { ComplianceExport } from 'assets/export-compliance.svg';
import { InformationCircle } from 'assets/information-circle.svg';
import { Key } from 'assets/key-hollow.svg';
import { ListItems } from 'assets/list-items.svg';
import { NumberCircle } from 'assets/number-circle.svg';
import { QuestionCircle } from 'assets/question-circle.svg';
import { Menu } from 'components/duex/Menu';
import _reject from 'lodash/reject';
import _startCase from 'lodash/startCase';
import React, { ReactElement } from 'react';
import { Annotation } from './document-view';

export const PopupMenuDimensions = {
    width: 271,
    height: 405,
};

export const PopupMenu = ({
    selectedAnnotation,
    addQuestion,
    removeQuestion,
    removeLink,
    linkAnswer,
    mode = 'PROJECT',
    isTracking,
    setTracking,
    selectionInTable,
}: {
    selectedAnnotation: Annotation;
    // hasLinkedAnswer: boolean;
    // hasLinkedComplianceStatus: boolean;
    addQuestion: (addOneOnly: boolean) => void;
    removeQuestion: () => void;
    removeLink: () => void;
    linkAnswer: () => void;
    mode?: 'PROJECT' | 'LIBRARY';
    isTracking: string;
    setTracking: (nodeId: string) => void;
    selectionInTable?: boolean;
    // highlightAnswer: (highlight: boolean) => void;
    // highlightComplianceStatus: (highlight: boolean) => void;
}): ReactElement => {
    const onDrag = (linkType: string) => {
        return (ev: React.DragEvent) => {
            ev.dataTransfer.clearData();
            ev.dataTransfer.setData('text/plain', linkType);
        };
    };

    let isQuestion = false;
    // let isAnswer = false;
    // let isComplianceStatus = false;
    // let isReferenceNumber = false;
    // let isInformation = false;
    let isExistingAnnotation = false;

    if (selectedAnnotation) {
        isExistingAnnotation = true;

        if (selectedAnnotation.annotationType === 'QUESTION') {
            isQuestion = true;
        }
        // if (selectedAnnotation.annotationType === 'ANSWER') {
        //     isAnswer = true;
        // }
        // if (selectedAnnotation.annotationType === 'COMPLIANCE') {
        //     isComplianceStatus = true;
        // }
        // if (selectedAnnotation.annotationType === 'REFERENCE') {
        //     isReferenceNumber = true;
        // }
        // if (selectedAnnotation.annotationType === 'INFORMATION') {
        //     isInformation = true;
        // }
    }

    const selectedAnnotationIsMostRecent = isTracking && selectedAnnotation?.nodeId === isTracking;

    const menuOptions = [
        {
            label: 'Options',
            isDisabled: true,
        },
        {
            icon: <ListItems />,
            label: 'Mark this column as Questions',
            onClick: () => {
                addQuestion(false);
            },
            exclude: !selectionInTable || isExistingAnnotation,
        },
        {
            icon: <QuestionCircle />,
            label: 'Mark selection as a Question',
            onClick: () => {
                addQuestion(true);
            },
            exclude: isExistingAnnotation,
        },
        {
            icon: <CheckShield />,
            label: 'Mark selection as Answer for the most recent Question',
            onClick: linkAnswer,
            isDisabled: !isTracking,
            exclude: isExistingAnnotation,
        },
        {
            icon: <Cross />,
            label: `Remove Question`,
            onClick: removeQuestion,
            exclude: !selectedAnnotation || !isQuestion,
        },
        {
            icon: <Cross />,
            label: `Remove ${_startCase(selectedAnnotation?.annotationType.toLowerCase())}`,
            onClick: removeLink,
            exclude: !selectedAnnotation || isQuestion,
        },
        {
            icon: <Key />,
            label: selectedAnnotationIsMostRecent ? 'Most recent question' : `Make this Question the most recent`,
            isSelected: selectedAnnotationIsMostRecent,
            onClick: () => {
                setTracking(selectedAnnotation.nodeId);
            },
            isDisabled: selectedAnnotationIsMostRecent,
            exclude: !isQuestion,
        },
        {
            isDivider: true,
            exclude: !isQuestion || mode === 'LIBRARY',
        },
        {
            label: 'Export Links (Drag & Drop)',
            isDisabled: true,
            exclude: !isQuestion || mode === 'LIBRARY',
        },
        {
            icon: <AnswerExport />,
            label: `Export Answer`,
            onDrag: onDrag('ANSWER'),
            isDisabled: !selectedAnnotation,
            exclude: !isQuestion || mode === 'LIBRARY',
        },
        {
            icon: <ComplianceExport />,
            label: `Export Compliance Status`,
            onDrag: onDrag('COMPLIANCE'),
            isDisabled: !selectedAnnotation,
            exclude: !isQuestion || mode === 'LIBRARY',
        },
        {
            isDivider: true,
            exclude: !isQuestion,
        },
        {
            label: 'Import Links (Drag & Drop)',
            isDisabled: true,
            exclude: !isQuestion,
        },
        {
            icon: <ChainLink />,
            label: `Import Answer`,
            onDrag: onDrag('ANSWER'),
            isDisabled: !selectedAnnotation,
            exclude: !isQuestion || mode === 'PROJECT',
        },
        {
            icon: <InformationCircle />,
            label: `Import Information`,
            onDrag: onDrag('INFORMATION'),
            isDisabled: !selectedAnnotation,
            exclude: !isQuestion || mode === 'LIBRARY',
        },
        {
            icon: <NumberCircle />,
            label: `Import Reference Number`,
            onDrag: onDrag('REFERENCE'),
            isDisabled: !selectedAnnotation,
            exclude: !isQuestion || mode === 'LIBRARY',
        },
    ];
    const filteredItems = _reject(menuOptions, {
        exclude: true,
    });

    return <Menu items={filteredItems} />;
};
