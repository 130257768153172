import React, { ReactElement } from 'react';

export const LibraryCross = ({
    strokeColor,
    strokeColorCross,
    strokeColorCircle,
    width,
    height,
}: {
    strokeColor?: string;
    strokeColorCross?: string;
    strokeColorCircle?: string;
    width: string;
    height: string;
}): ReactElement => {
    return (
        <svg
            width={width || '40'}
            height={height || '40'}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="20"
                cy="20"
                r="14"
                fill={strokeColorCircle}
                stroke={strokeColorCircle || strokeColor || '#2068E3'}
            />
            <path
                d="M25 15L15 25"
                stroke={strokeColorCross || strokeColor || '#2068E3'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15 15L25 25"
                stroke={strokeColorCross || strokeColor || '#2068E3'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
