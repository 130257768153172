import { ChevronRight } from 'assets/chevron-right.svg';
import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Module } from 'components/duex/Module';
import SearchTextField from 'components/duex/SearchTextField';
import { Tabs } from 'components/duex/Tabs';
import { showError } from 'components/error-toast.component';
import { Col, ColHeader, Row } from 'components/table';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { FileUploadModal } from 'modules/file-upload/file-upload-modal.view';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Document from 'types/document.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { EditDocumentModal } from './edit-document';

export const DocumentsHome = (): ReactElement => {
    const StartingTabs = [
        {
            label: 'Available',
            isActive: true,
            filterValue: 'UPLOADED',
        },
        {
            label: 'Archived',
            isActive: false,
            filterValue: 'DEACTIVE',
        },
    ];

    const [tabs, setTabs] = useState(StartingTabs);

    const selectedTab = _find(tabs, { isActive: true });

    const [library, setLibrary] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
    const [showEditDocumentId, setShowEditDocumentId] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);

    const fetchLibraryOfDocuments = async (initialLoad = true) => {
        try {
            setFetchingData(initialLoad);

            const results = await API.get(ENDPOINTS.getUrl(CONSTANTS.GET_DOC_LIBRARY_ALL));
            if (results && results.data) {
                setLibrary(results.data);
            }
            setFetchingData(false);
        } catch (err) {
            showError('There was an error retrieving your library of documents', err);
            setFetchingData(false);
        }
    };

    const fetchDocumentTypes = async () => {
        try {
            const results = await API.get(ENDPOINTS.getUrl(CONSTANTS.GET_DOC_TYPE));
            if (results && results.data) {
                setDocumentTypes(results.data);
            }
        } catch (err) {
            showError('There was an error retrieving the document types', err);
        }
    };

    useEffect(() => {
        fetchDocumentTypes();
        fetchLibraryOfDocuments(true);
    }, []);

    function selectTab(tabLabel: string) {
        setTabs(
            tabs.map((tab) => {
                return {
                    ...tab,
                    isActive: tab.label === tabLabel,
                };
            }),
        );
    }

    const filteredList = _filter(library, (document: Document) => {
        let match = true;

        if (searchTerm.trim()) {
            match = false;

            const lowerCaseSearchTerm = searchTerm.toLowerCase();

            const documentName = document.documentName?.toLowerCase();
            const fileName = document.fileName?.toLowerCase();
            const externalUrl = document.externalUrl?.toLowerCase();

            const containsSearch = _filter([documentName, fileName, externalUrl], (value: string) => {
                return value && value.indexOf(lowerCaseSearchTerm) !== -1;
            });

            if (containsSearch.length > 0) {
                match = true;
            }
        }

        return document.documentStatus === selectedTab?.filterValue && match;
    });

    return (
        <Module
            moduleName="Document Library"
            middleItem={
                <div className="mx-auto w-600">
                    <SearchTextField
                        applySearch={setSearchTerm}
                        placeholder="Search by document name or file name"
                        debounceDuration={0}
                    />
                </div>
            }
            rightItem={
                <Button
                    label="Add Document"
                    onClick={() => {
                        setShowAddDocumentModal(true);
                    }}
                    className="mr-16"
                />
            }
        >
            <div className="mx-16">
                <Tabs
                    tabItems={tabs}
                    onChange={(selectedTab) => {
                        selectTab(selectedTab.label);
                    }}
                />
                <div className={`m-auto mx-auto flex h-full flex-col py-8`}>
                    <div className="flex flex-grow-2 flex-col">
                        {fetchingData && <LoadingSpinner className="pt-32" />}

                        {!fetchingData && filteredList.length === 0 && (
                            <EmptyState
                                width="w-440"
                                className="mt-32"
                                title="Your Document Library is empty"
                                subtitle="This is where you're able to manage your Document Library"
                            >
                                <div className="mt-16 flex flex-row justify-center">
                                    <Button
                                        onClick={() => {
                                            setShowAddDocumentModal(true);
                                        }}
                                        label="Upload Document"
                                    />
                                </div>
                            </EmptyState>
                        )}

                        {filteredList.length > 0 && (
                            <div className="rounded-md bg-white p-8">
                                <Row className="border-b border-gray-light-1">
                                    <>
                                        <ColHeader className={`w-1-of-12 pl-8`} text="Added By" />
                                        <ColHeader className={`w-2-of-12`} text="Last Updated" />
                                        <ColHeader className={`w-3-of-12`} text="Document" />
                                        <ColHeader className={`w-3-of-12`} text="Open Document" />
                                        <ColHeader className={`w-3-of-12 pr-8`} text="" />
                                    </>
                                </Row>

                                {filteredList.map((document, index) => {
                                    const lastAttachment = index === filteredList.length - 1;

                                    return (
                                        <Row
                                            spacing="py-16"
                                            key={document.documentId}
                                            className={`mt-4 ${
                                                !lastAttachment ? '' : ''
                                            } rounded-sm border-black-10 hover:bg-gray-50 cursor-pointer`}
                                            onClickHandler={() => {
                                                setShowEditDocumentId(document.documentId);
                                            }}
                                        >
                                            <>
                                                <Col className={`w-1-of-12 pl-8`}>
                                                    <Avatar size={32} userId={document.createdByUserId} />
                                                </Col>
                                                <Col className={`w-2-of-12`}>
                                                    {moment(document.updatedAt).fromNow()}
                                                </Col>
                                                <Col className={`w-3-of-12`}>{document.documentName}</Col>
                                                <Col className={`w-5-of-12`}>
                                                    <a
                                                        href={
                                                            document.storeFile
                                                                ? document.accessUrl
                                                                : document.externalUrl
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        className="text-primary-blue-100-opace-8 underline"
                                                    >
                                                        {document.storeFile ? document.fileName : document.externalUrl}
                                                    </a>
                                                </Col>
                                                <Col className="flex w-1-of-12 flex-row-reverse pr-16">
                                                    <ChevronRight />
                                                </Col>
                                            </>
                                        </Row>
                                    );
                                })}
                            </div>
                        )}
                        {showAddDocumentModal && (
                            <FileUploadModal
                                closeModal={() => {
                                    setShowAddDocumentModal(false);
                                    fetchLibraryOfDocuments();
                                }}
                            />
                        )}
                        {showEditDocumentId && (
                            <EditDocumentModal
                                closeModal={() => {
                                    setShowEditDocumentId(null);
                                    fetchLibraryOfDocuments();
                                }}
                                refresh={() => {
                                    fetchLibraryOfDocuments();
                                }}
                                document={_find(library, {
                                    documentId: showEditDocumentId,
                                })}
                                documentTypes={documentTypes}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Module>
    );
};
