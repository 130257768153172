import { FileUploadIcon } from 'assets/file-upload-icon.svg';
import { LinkIcon } from 'assets/link-icon.svg';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { Modal } from 'components/duex/Modal';
import React, { ReactElement } from 'react';

export const ProjectStartedModal = ({
    closeModal,
    openFileUpload,
    openAddPortal,
}: {
    closeModal: () => void;
    openFileUpload: () => void;
    openAddPortal: () => void;
}): ReactElement => {
    return (
        <Modal width="w-800" closeModal={closeModal} title="Welcome to your Project">
            <p className="text-gray-500">Congratulations on creating a new project.</p>
            <p className="mb-8 text-gray-500">
                You can import questions from Word Documents, Excel Spreadsheets & PDF's.
            </p>
            <ButtonBar className="mb-16" alignment="LEFT">
                <Button
                    onClick={() => {
                        window.open('https://www.pearler.ai/guides/import-questions-from-spreadsheet/', '_blank');
                    }}
                    buttonType="SECONDARY"
                    label="Watch Video: Import from Excel"
                />
                <Button
                    onClick={() => {
                        window.open('https://www.pearler.ai/guides/import-word-documents/', '_blank');
                    }}
                    buttonType="SECONDARY"
                    label="Watch Video: Import from Word & PDF"
                />
            </ButtonBar>
            <p className="text-gray-500 font-semibold mb-8">Next Step: Add Questions</p>
            <div className="mb-8 grid grid-cols-2 gap-16 rounded-sm bg-gray-50 p-16 text-center">
                <div
                    onClick={openFileUpload}
                    className="cursor-pointer rounded-lg bg-white p-16 text-gray-500 shadow-lg hover:bg-very-light-blue hover:text-primary-blue-100"
                >
                    <div className="my-16 flex justify-center">
                        <FileUploadIcon />
                    </div>
                    <h4 className="text-15 my-8 font-medium">Upload File</h4>
                    <p className="text-14 text-gray-500">Add questions from Word, Excel & PDF</p>
                    <p className="mt-4 text-12 text-gray-500">Manage Project &gt; Add Questions</p>
                </div>
                <div
                    onClick={openAddPortal}
                    className="cursor-pointer rounded-lg bg-white p-16 text-gray-500 shadow-lg hover:bg-very-light-blue hover:text-primary-blue-100"
                >
                    <div className="my-16 flex justify-center">
                        <LinkIcon />
                    </div>
                    <h4 className="text-15 my-8 font-medium">Add Portal Link</h4>
                    <p className="text-14 text-gray-500">Add a link to a third party system</p>
                    <p className="mt-4 text-12 text-gray-500">Manage Project &gt; Add Portal Link</p>
                </div>
            </div>
        </Modal>
    );
};
