import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

const Welcome = (): ReactElement => {
    return (
        <>
            <div className="height-0 container mx-auto flex items-center justify-center p-32">
                <div className="max-w-screen-xl px-4">
                    <div className="text-center">
                        <h2 className="text-2xl font-extrabold leading-10 tracking-tight text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                            Breeze through with <span className="text-indigo-600">Pearler</span>
                        </h2>
                        <p className="max-w-md md:max-w-3xl mx-auto mt-3 text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl">
                            Finally, a tool to make answering Security Assessments simple, fast and comprehensive.
                        </p>
                        <div className="max-w-md mx-auto mt-24 sm:flex sm:justify-center">
                            <div className="rounded-md shadow">
                                <Link
                                    to="/create"
                                    className="focus:shadow-outline-indigo flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out hover:bg-indigo-500 focus:outline-none md:px-10 md:py-4 md:text-lg"
                                >
                                    Start
                                </Link>
                            </div>
                            <div className="mt-3 rounded-md shadow sm:ml-3 sm:mt-0">
                                <a
                                    href="/demo"
                                    className="focus:shadow-outline-blue flex w-full items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium leading-6 text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none md:px-10 md:py-4 md:text-lg"
                                >
                                    Live demo
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Welcome;
