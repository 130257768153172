import React, { ReactElement } from 'react';
import { CONSTANTS } from 'utility/Constants';

interface ComplianceSelectProps {
    selections: string[];
    onUpdate: (newState: string[]) => void;
}

export const options = [
    {
        key: CONSTANTS.COMPLIANT,
        label: 'Compliant',
    },
    {
        key: CONSTANTS.NONCOMPLIANT,
        label: 'Non compliant',
    },
    {
        key: CONSTANTS.PARTIALLYCOMPLIANT,
        label: 'Partially Compliant',
    },
    {
        key: CONSTANTS.NOTAPPLICABLE,
        label: 'Not Applicable',
    },
];

export const ComplianceSelect = ({ selections, onUpdate }: ComplianceSelectProps): ReactElement => {
    return (
        <div className="flex flex-row">
            {options.map((option) => {
                const id = `compliance-selection-${option.key}`;
                const selected = selections.indexOf(option.key) !== -1;
                return (
                    <div
                        key={option.key}
                        className={`small-1-semibold small-1-med mr-4 flex select-none flex-row rounded-full px-8 py-6 ${
                            selected ? 'bg-primary-blue-100-opace-1' : 'bg-faded-gray'
                        } `}
                    >
                        <input
                            type="checkbox"
                            className="mr-4 mt-4"
                            id={id}
                            checked={selected}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const selected = event.target.checked;
                                if (selected) {
                                    onUpdate([option.key, ...selections]);
                                } else {
                                    onUpdate(
                                        selections.filter((selection) => {
                                            return selection !== option.key;
                                        }),
                                    );
                                }
                            }}
                        />
                        <label htmlFor={id} className={`pt-3 ${selected ? 'text-primary-blue-100' : 'text-black-60'}`}>
                            {option.label}
                        </label>
                    </div>
                );
            })}
        </div>
    );
};
