import { ThreeDots } from 'assets/three-dots.svg';
import { Button } from 'components/duex/Button';
import { Menu } from 'components/duex/Menu';
import { MenuButton } from 'components/duex/MenuButton';
import { SimpleTextSelection } from 'components/duex/SimpleTextSelection';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _reject from 'lodash/reject';
import _times from 'lodash/times';
import React, { ReactElement, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

interface Column {
    content: string;
}
interface Row {
    rowIdentifier: string;
    rowNumber: number;
    columns: Column[];
}

interface Sheet {
    sheetId: string;
    sheetName: string;
    isLinked: boolean;
    columnClassifications: [];
    rowInclusion: [];
    sheetContent: Row[];
    columnCount: number;
    rowSections: [];
}

export const SheetView = ({
    projectId,
    fetchData,
    setUnsavedChanges,
    undoChanges,
    selectedFile,
    selectedSheetIndex,
    selectedSheet,
}: {
    projectId: string;
    fetchData: () => void;
    setUnsavedChanges: (newValue: boolean) => void;
    undoChanges: () => void;
    selectedFile: {
        fileName: string;
    };
    selectedSheetIndex: number;
    selectedSheet: Sheet;
}): ReactElement => {
    const classifications = [
        'Ignore',
        'Question',
        'Answer',
        'Compliance Status',
        'Reference Number',
        'Information',
        'Section',
        'Importance',
    ];

    const [sheetIncluded, setSheetIncluded] = useState(false);

    const [includedRows, setIncludedRows] = useState({});
    const [sectionConfiguration, setSectionConfiguration] = useState([]);
    const [classifiedColumns, setClassifiedColumns] = useState([]);

    const [isSaving, setIsSaving] = useState(false);
    const [columnConfigurationError, setColumnConfigurationError] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [filterSelection, setFilterSelection] = useState('ALL');

    const setupInitialSelections = () => {
        if (!selectedSheet) {
            setSectionConfiguration([]);
            return setIncludedRows({});
        }

        setSectionConfiguration(selectedSheet.rowSections || []);
        setIncludedRows(_mapValues(_keyBy(selectedSheet.rowInclusion, 'rowNumber'), 'isLinked'));
    };

    const setupInitialClassifications = () => {
        if (!selectedSheet) {
            return setClassifiedColumns([]);
        }

        setClassifiedColumns(selectedSheet.columnClassifications);
    };

    const resetDefaults = () => {
        setupInitialSelections();
        setupInitialClassifications();
        setSheetIncluded(Boolean(selectedSheet.isLinked));
        setFilterSelection('ALL');
    };

    const classifyColumn = (columnIndex: number, newValue: string) => {
        const update = _map(classifiedColumns, (columnConfig, index) => {
            if (index === columnIndex) {
                return {
                    ...columnConfig,
                    columnType: newValue,
                };
            }
            return columnConfig;
        });

        setClassifiedColumns(update);
    };

    const changeRowSelection = (rowNumber: number, newValue: boolean) => {
        const update = {
            ...includedRows,
            [rowNumber]: newValue,
        };

        setIncludedRows(update);
    };

    const getColumnClassifications = () => {
        return classifiedColumns;
    };

    const getSheetInclusion = () => {
        return sheetIncluded;
    };

    const getRowInclusions = () => {
        return _map(selectedSheet.rowInclusion, ({ rowNumber }) => {
            return {
                rowNumber,
                isLinked: includedRows[rowNumber],
            };
        });
    };

    const getRowSections = () => {
        return sectionConfiguration;
    };

    const saveChanges = async () => {
        try {
            setIsSaving(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.SAVE_SOURCE_SHEET_CONFIGURATION, {
                projectId,
                sheetId: selectedSheet.sheetId,
            });

            const results = await API.post(url, {
                columnClassifications: getColumnClassifications(),
                isLinked: getSheetInclusion(),
                rowInclusion: getRowInclusions(),
                rowSections: getRowSections(),
            });
            if (results && results.data) {
                setIsSaving(false);
                fetchData();
                showSuccess('Success! Questions updated');
            }
        } catch (err) {
            showError('There was an error saving your sheet configuration', err);
            setIsSaving(false);
        }
    };

    const undoUserChanges = () => {
        undoChanges();
        resetDefaults();
    };

    const addSection = (firstRowNumber: number, columnIndex: number, sectionName: string) => {
        setSectionConfiguration([
            ...sectionConfiguration,
            {
                firstRowNumber,
                sectionName,
                columnIndex,
            },
        ]);
    };

    const removeSection = (firstRowNumber: number, columnIndex: number) => {
        setSectionConfiguration(
            _reject(sectionConfiguration, {
                firstRowNumber,
                columnIndex,
            }),
        );
    };

    const checkForChanges = () => {
        if (!selectedFile) {
            return;
        }

        // Column Classifications
        const columnConfigChanged = !_isEqual(selectedSheet.columnClassifications, getColumnClassifications());

        // Include Sheet Checkbox
        const inclusionChanged = !_isEqual(selectedSheet.isLinked, getSheetInclusion());

        // Get row selection changes
        const rowSelectedChanged = !_isEqual(selectedSheet.rowInclusion, getRowInclusions());

        // Get section selection changes
        const sectionConfigChanged = !_isEqual(selectedSheet.rowSections, getRowSections());

        // All Changes
        const hasChanges = columnConfigChanged || inclusionChanged || rowSelectedChanged || sectionConfigChanged;

        setHasChanges(hasChanges && !columnConfigurationError);
        setUnsavedChanges(hasChanges);
    };

    const checkQuestionColumnSpecified = () => {
        if (!sheetIncluded) {
            return setColumnConfigurationError(null);
        }

        const questionColumns = _filter(classifiedColumns, { columnType: 'Question' });
        const answerColumns = _filter(classifiedColumns, { columnType: 'Answer' });
        const referenceColumns = _filter(classifiedColumns, { columnType: 'Reference Number' });
        const complianceColumns = _filter(classifiedColumns, { columnType: 'Compliance Status' });
        const groupColumns = _filter(classifiedColumns, { columnType: 'Group' });
        const importanceColumns = _filter(classifiedColumns, { columnType: 'Importance' });

        if (questionColumns.length === 0) {
            return setColumnConfigurationError('Please choose a column as Question');
        }

        if (answerColumns.length === 0) {
            return setColumnConfigurationError('Please choose a column as Answer');
        }

        if (answerColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column as Answer');
        }

        if (referenceColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column as Reference Number');
        }

        if (groupColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column as Group');
        }

        if (importanceColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column as Importance');
        }

        if (complianceColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column as Compliance Status');
        }

        setColumnConfigurationError(null);
    };

    useEffect(() => {
        checkQuestionColumnSpecified();
    }, [selectedSheet, classifiedColumns, sheetIncluded]);

    useEffect(resetDefaults, [selectedSheet]);

    useEffect(() => {
        checkForChanges();
    }, [
        includedRows,
        classifiedColumns,
        selectedSheetIndex,
        sheetIncluded,
        includedRows,
        columnConfigurationError,
        sectionConfiguration,
    ]);

    const attributeLabelClasses = 'small-1-med mb-5 text-black-100 font-semibold';
    const attributeValueClasses = 'body-2-reg text-black-80';
    const headingClasses = 'sticky top-0 z-1 bg-white border-b border-gray-100';

    console.log('selectedSheet', selectedSheet);
    console.log('sectionConfiguration', sectionConfiguration);

    return (
        <>
            <div className="">
                <h3 className="my-4 text-12 font-bold uppercase">Spreadsheet Configuration</h3>
                <div className="grid grid-cols-3 gap-16 rounded-lg border border-gray-100 bg-gray-50 px-8 py-16">
                    <div>
                        <p className={attributeLabelClasses}>File Name</p>
                        <p className={attributeValueClasses}>{selectedFile.fileName || 'Copied into Pearler'} </p>
                    </div>
                    <div>
                        <p className={attributeLabelClasses}>Sheet Name</p>
                        <p className={attributeValueClasses}>{selectedSheet.sheetName}</p>
                    </div>
                    <div>
                        <div className="flex flex-row-reverse gap-16">
                            <Button
                                label="Save Changes"
                                onClick={saveChanges}
                                disabled={!hasChanges || isSaving}
                                loading={isSaving}
                            />
                            {hasChanges && (
                                <Button
                                    buttonType="SECONDARY"
                                    label="Discard Changes"
                                    onClick={undoUserChanges}
                                    disabled={!hasChanges}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <p className={attributeLabelClasses}>Contains Questions</p>
                        <p className={attributeLabelClasses}>
                            <input
                                id="importer-sheet-included-checkbox"
                                type="checkbox"
                                className="mr-4 mt-4"
                                checked={sheetIncluded}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const selected = event.target.checked;
                                    setSheetIncluded(Boolean(selected));
                                    if (!Boolean(selected)) {
                                        setFilterSelection('ALL');
                                    }
                                }}
                            />
                            <label htmlFor="importer-sheet-included-checkbox" className={attributeValueClasses}>
                                Include Sheet
                            </label>
                        </p>
                    </div>
                    <div>
                        <p className={attributeLabelClasses}>
                            {selectedSheet.sheetContent.length} Rows in {selectedFile.fileName ? 'file' : 'source'}
                        </p>
                        <p className={attributeValueClasses}>
                            <SimpleTextSelection
                                changeSelection={setFilterSelection}
                                value={filterSelection}
                                disabled={!sheetIncluded}
                                items={[
                                    {
                                        label: 'All',
                                        value: 'ALL',
                                    },
                                    {
                                        label: 'Selected',
                                        value: 'SELECTED',
                                    },
                                    {
                                        label: 'Not Selected',
                                        value: 'NOT_SELECTED',
                                    },
                                ]}
                            />
                        </p>
                    </div>
                    <div>
                        <p className={`${attributeValueClasses} text-right`}>
                            {columnConfigurationError && <span className="text-red">{columnConfigurationError}</span>}
                        </p>
                    </div>
                </div>
            </div>
            <table className="block overflow-auto" style={{ height: 'calc(100% - 165px)' }}>
                <thead>
                    {selectedSheet && (
                        <tr key="header-row" className={` ${sheetIncluded ? 'text-black' : 'text-gray-400'}`}>
                            <th className={`p-16 text-12 ${headingClasses}`}>Include</th>
                            {_times(selectedSheet.columnCount, (key) => {
                                return (
                                    <th
                                        data-cy={`Import_Options_${key}`}
                                        key={key}
                                        className={`p-16 text-12 ${headingClasses} text-left`}
                                    >
                                        <select
                                            disabled={!sheetIncluded}
                                            value={classifiedColumns[key]?.columnType || false}
                                            onChange={(newValue) => {
                                                classifyColumn(key, newValue.target.value);
                                            }}
                                        >
                                            {classifications.map((label, key) => {
                                                return (
                                                    <option key={key} value={label}>
                                                        {label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </th>
                                );
                            })}
                        </tr>
                    )}
                </thead>
                <tbody className="">
                    {selectedSheet &&
                        selectedSheet.sheetContent
                            .filter((row) => {
                                if (filterSelection === 'ALL') {
                                    return true;
                                }

                                if (filterSelection === 'SELECTED') {
                                    return includedRows[row.rowNumber];
                                }

                                if (filterSelection === 'NOT_SELECTED') {
                                    return !includedRows[row.rowNumber];
                                }
                            })
                            .map((row) => {
                                const sectionHeader = Boolean(
                                    _find(sectionConfiguration, {
                                        firstRowNumber: row.rowNumber,
                                    }),
                                );

                                return (
                                    <tr
                                        key={row.rowNumber}
                                        className={`overflow-hidden rounded-lg text-12 ${
                                            sheetIncluded ? '' : 'text-gray-400'
                                        } ${sectionHeader ? 'bg-emerald-100' : 'hover:bg-gray-50'}`}
                                        onClick={() => {
                                            if (sheetIncluded && !sectionHeader) {
                                                changeRowSelection(row.rowNumber, !includedRows[row.rowNumber]);
                                            }
                                        }}
                                    >
                                        <td className="p-16">
                                            {!sectionHeader && (
                                                <input
                                                    data-cy={`Include_Question_${row.rowNumber - 1}`}
                                                    type="checkbox"
                                                    checked={includedRows[row.rowNumber] || false}
                                                    value={'checked'}
                                                    disabled={!sheetIncluded || sectionHeader}
                                                />
                                            )}
                                        </td>

                                        {_times(selectedSheet.columnCount, (key) => {
                                            const cellSectionHeader = Boolean(
                                                _find(sectionConfiguration, {
                                                    firstRowNumber: row.rowNumber,
                                                    columnIndex: key,
                                                }),
                                            );

                                            return (
                                                <td key={key} className={`p-16 group`}>
                                                    {row.columns[key] && (
                                                        <div className="flex flex-row gap-8 justify-between relative">
                                                            <pre
                                                                className={`overflow-hidden whitespace-pre-line break-words font-body ${
                                                                    !includedRows[row.rowNumber] ||
                                                                    (sectionHeader && !cellSectionHeader)
                                                                        ? 'text-gray-400'
                                                                        : ''
                                                                } ${cellSectionHeader ? 'font-bold text-emerald-500 text-15' : ''}`}
                                                            >
                                                                {row.columns[key].content}
                                                            </pre>
                                                            {row.columns[key].content && sheetIncluded && (
                                                                <MenuButton
                                                                    Icon={ThreeDots}
                                                                    width="w-200"
                                                                    className="group-hover:visible invisible text-gray-500"
                                                                    mode="absolute"
                                                                    menu={
                                                                        <Menu
                                                                            items={[
                                                                                {
                                                                                    label: 'Start of Section',
                                                                                    isDisabled: cellSectionHeader,
                                                                                    onClick: () => {
                                                                                        addSection(
                                                                                            row.rowNumber,
                                                                                            key,
                                                                                            row.columns[key].content,
                                                                                        );
                                                                                        changeRowSelection(
                                                                                            row.rowNumber,
                                                                                            false,
                                                                                        );
                                                                                    },
                                                                                },
                                                                                {
                                                                                    label: 'Undo Section',
                                                                                    isDisabled: !cellSectionHeader,
                                                                                    onClick: () => {
                                                                                        removeSection(
                                                                                            row.rowNumber,
                                                                                            key,
                                                                                        );
                                                                                    },
                                                                                },
                                                                            ]}
                                                                        />
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                </tbody>
            </table>
        </>
    );
};
