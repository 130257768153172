import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { RichTextEditor } from 'components/duex/RichTextEditor';
import { showError } from 'components/error-toast.component';
import { isValidStringOfLength } from 'components/validation/string';
import { useTeamContext } from 'hooks/useTeamContext';
import React, { ReactElement, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export function generateTimestamp(): string {
    return `${new Date().getTime()}`;
}

export const UpdateAnswer = ({
    save,
    cancel,
    existingAnswerHtml,
}: {
    save: (answerHtml: string) => Promise<void>;
    cancel: () => void;
    existingAnswerHtml: string;
    existingAnswerId: string;
}): ReactElement => {
    const { settings } = useTeamContext();

    const [answerHtml, setAnswerHtml] = useState(existingAnswerHtml);
    const [isSaving, setIsSaving] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [suggestion, setSuggestion] = useState<{ answerRichText: string; answerText: string }>(null);

    const savePressed = async () => {
        if (getSaveReadiness()) {
            setIsSaving(true);
            await save(answerHtml);
            cancel();
        }
    };

    const rewriteAnswer = async () => {
        try {
            setIsGenerating(true);

            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.REWRITE_ANSWER);

            const results = await API.post(url, {
                answerText: answerHtml,
            });

            logEvent('ANSWER_LIBRARY_REWRITE_ANSWER', started);

            if (results && results.data) {
                setIsGenerating(false);
                setSuggestion(results.data);
            }
        } catch (err) {
            showError('There was an error generating a new answer', err);
            setIsGenerating(false);
            setSuggestion(null);
        }
    };

    const getSaveReadiness = () => {
        return isValidStringOfLength(answerHtml, 10) && answerHtml !== existingAnswerHtml;
    };

    // Required for RichTextEditor to function correctly
    const [forceUpdate, setForceUpdate] = useState(generateTimestamp());

    return (
        <div className="overflow-y-auto">
            <fieldset>
                <RichTextEditor
                    dataCy="Answer"
                    forceUpdate={forceUpdate}
                    htmlText={answerHtml}
                    placeholder="Enter your answer here"
                    label="Answer"
                    errorMessage="Please provide an answer"
                    validator={(currentValue) => {
                        return isValidStringOfLength(currentValue, 10);
                    }}
                    valueChanged={(text: string, html: string) => {
                        setAnswerHtml(html);
                    }}
                    onBlur={() => {
                        setForceUpdate(generateTimestamp());
                    }}
                    required={true}
                />
            </fieldset>
            {suggestion && (
                <div className="mt-16 rounded-md bg-blue-50 p-16">
                    <div className="mb-8 text-14 uppercase text-gray-500">AI Suggestion</div>
                    <div className="whitespace-pre-line break-words">{suggestion.answerText}</div>
                    <div className="mt-16 flex flex-row-reverse">
                        <Button
                            buttonType="TERTIARY"
                            label="Apply Answer"
                            onClick={() => {
                                setAnswerHtml(suggestion.answerRichText);
                                setForceUpdate(generateTimestamp());
                            }}
                            disabled={isGenerating || isSaving}
                            loading={isGenerating}
                        />
                    </div>
                </div>
            )}
            <div className="mt-16 grid grid-cols-2">
                <ButtonBar alignment="LEFT">
                    {settings.enableChatGPTIntegration && (
                        <Button
                            buttonType="SECONDARY"
                            label="Rewrite Answer using AI"
                            onClick={rewriteAnswer}
                            disabled={isGenerating || isSaving}
                            loading={isGenerating}
                        />
                    )}
                </ButtonBar>
                <ButtonBar>
                    <Button
                        label="Cancel"
                        buttonType="SECONDARY"
                        onClick={cancel}
                        disabled={isSaving || isGenerating}
                    />
                    <Button
                        label="Save Answer"
                        onClick={savePressed}
                        disabled={!getSaveReadiness() || isSaving || isGenerating}
                        loading={isSaving}
                    />
                </ButtonBar>
            </div>
        </div>
    );
};
