import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormFileUpload } from 'components/duex/FormFileUpload';
import { FormLabel } from 'components/duex/FormLabel';
import { FormSelectField } from 'components/duex/FormSelectField';
import { FormTextField } from 'components/duex/FormTextField';
import { InformationNotice } from 'components/duex/Notice';
import { showError } from 'components/error-toast.component';
import { convertToUTF8, isValidStringOfLength } from 'components/validation/string';
import { isValidUrl } from 'components/validation/url';
import _find from 'lodash/find';
import { SettingsRadioSelection } from 'modules/settings/settings-components';
import React, { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const FileUploadComponent = ({
    onComplete,
    projectId,
    questionId,
}: {
    onComplete: () => void;
    questionId?: string;
    projectId?: string;
}): ReactElement => {
    const [uploading, setUploading] = useState(false);
    const inputButton = useRef<HTMLInputElement>();
    const [documentTypes, setDocumentTypes] = useState([]);
    const [useUploadedFile, setUploadFile] = useState(true);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [documentName, setDocumentName] = useState('');
    const [documentLinkUrl, setDocumentLinkUrl] = useState('https://');
    const [, setFileIdentifier] = useState(null);

    const fetchDocumentTypes = async () => {
        try {
            const results = await API.get(ENDPOINTS.getUrl(CONSTANTS.GET_DOC_TYPE));
            if (results && results.data) {
                setDocumentTypes(results.data);
            }
        } catch (err) {
            showError('There was an error retrieving the document types', err);
        }
    };

    useEffect(() => {
        fetchDocumentTypes();
    }, []);

    const fileSelectHandler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files.length) {
            const file = e.target.files[0];
            const fileSize = file.size / 1024 / 1024;
            const maxFileSize = 25;

            if (fileSize > maxFileSize) {
                alert('That file is too large, please choose a smaller image. We allow up to 25MB.');
            }

            setFileIdentifier(Math.random());
        } else {
            setFileIdentifier(null);
        }
    };

    const uploadFile = async () => {
        try {
            const started = new Date();
            setUploading(true);

            let fileInformation = {};

            if (useUploadedFile) {
                const fileName = inputButton.current.files[0].name;
                const contentType = inputButton.current.files[0].type;

                fileInformation = {
                    fileName: convertToUTF8(fileName),
                    contentType,
                };
            }

            const body = {
                ...fileInformation,
                documentName,
                documentTypeId: selectedDocumentType,
                storeFile: useUploadedFile,
                availableToAttachToProjects: true,
                externalUrl: documentLinkUrl,
            };
            const createdDocument = await API.post(ENDPOINTS.getUrl(CONSTANTS.CREATE_DOC), body);

            if (useUploadedFile) {
                const contentType = inputButton.current.files[0].type;
                const file = inputButton.current.files[0];
                const { uploadUrl } = createdDocument.data;

                await API.putWithoutAuth(uploadUrl, file, {
                    'Content-Type': contentType,
                });
            }

            await API.post(
                ENDPOINTS.getUrl(CONSTANTS.COMPLETE_DOC_UPLOAD, {
                    documentId: createdDocument.data.documentId,
                }),
                {
                    projectId,
                    questionId,
                },
            );

            logEvent(`FILE_UPLOADED`, started, {
                documentId: createdDocument.data.documentId,
                projectId,
                questionId,
            });

            setUploading(false);
            onComplete();
        } catch (err) {
            setUploading(false);
            showError('There was an error uploading your file', err);
        }
    };

    const checkInputs = () => {
        if (useUploadedFile) {
            return (
                inputButton.current &&
                inputButton.current.files &&
                inputButton.current.files.length > 0 &&
                selectedDocumentType &&
                documentName
            );
        }

        return selectedDocumentType && documentName && documentLinkUrl;
    };

    const saveEnabled = checkInputs();

    return (
        <div>
            <div className="my-32 text-left grid gap-16">
                <FormSelectField
                    dataCy="File_Type"
                    label="Document Type"
                    placeholder="Select the type of document"
                    errorMessage="Select the type of document"
                    defaultValue={selectedDocumentType}
                    required={true}
                    items={documentTypes}
                    optionLabel="documentName"
                    optionValue="documentTypeId"
                    valueChanged={(newValue: string) => {
                        setSelectedDocumentType(newValue);

                        const selectedOption = _find(documentTypes, {
                            documentTypeId: newValue,
                        });

                        if (!documentName && selectedOption.documentName !== 'Other') {
                            setDocumentName(selectedOption.documentName);
                        }
                    }}
                    validator={(newValue: string) => {
                        return isValidStringOfLength(newValue, 3);
                    }}
                />
                <FormTextField
                    label="Document Name"
                    placeholder="Give the document a name"
                    errorMessage="Give the document a name"
                    defaultValue={documentName}
                    required={true}
                    valueChanged={setDocumentName}
                    validator={(newValue: string) => {
                        return isValidStringOfLength(newValue, 3);
                    }}
                />
                <div>
                    <FormLabel label="Document Location" />
                    <SettingsRadioSelection
                        showLabel={false}
                        currentValue={useUploadedFile}
                        onChange={(newValue) => {
                            setUploadFile(newValue);
                        }}
                        options={[
                            {
                                label: 'I want to upload a file',
                                value: true,
                            },
                            {
                                label: 'I want to link to another location',
                                value: false,
                            },
                        ]}
                    />
                </div>
                {useUploadedFile && (
                    <FormFileUpload
                        valueChanged={fileSelectHandler}
                        ref={inputButton}
                        label="File"
                        required={true}
                        dataCy="Select_File"
                    />
                )}
                {!useUploadedFile && (
                    <>
                        <InformationNotice
                            title="Trust Centre"
                            description="Documents that are linked are not available for download in the Trust Centre."
                        />
                        <FormTextField
                            placeholder="Enter your URL that links to the document here."
                            label="Document URL"
                            errorMessage="Please provide a URL"
                            validator={(currentValue) => {
                                return isValidUrl(currentValue);
                            }}
                            defaultValue={documentLinkUrl}
                            valueChanged={(newValue: string) => {
                                setDocumentLinkUrl(newValue);
                            }}
                            required={true}
                            maxLength={1024}
                        />
                    </>
                )}
            </div>

            <ButtonBar>
                <Button buttonType="SECONDARY" label="Cancel" disabled={uploading} onClick={onComplete} />
                <Button label="Save" disabled={!saveEnabled || uploading} loading={uploading} onClick={uploadFile} />
            </ButtonBar>
        </div>
    );
};
