import React, { ReactElement } from 'react';

export const DropDownArrow = ({ color }: { color: string }): ReactElement => {
    return (
        <svg className="h-20 w-20" viewBox="0 0 20 20" fill={color}>
            <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
            />
        </svg>
    );
};

// export const CloseUpArrow = (): ReactElement => {
//     return (
//         <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path
//                 d="M11 6.5L6 1.5L1 6.5"
//                 stroke="#2068E3"
//                 strokeWidth="1.5"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//             />
//         </svg>
//     );
// };
