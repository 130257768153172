import { useUserContext } from 'hooks/useUserContext';
import React, { ReactElement } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { ROUTES } from 'utility/ApplicationRoutes';
import { ApplicationLoading } from './ApplicationLoader';

export const PublicRoute = ({ children }: RouteProps): ReactElement => {
    const auth = useUserContext();

    if (auth.checkingAuth) {
        return <ApplicationLoading />;
    }

    if (!auth.authToken) {
        return <>{children}</>;
    }

    return <Navigate to={ROUTES.HOME} />;
};
