import { API } from 'utility/Api';
import { ROUTES, createUrl } from './ApplicationRoutes';
import { CONSTANTS } from './Constants';
import { ENDPOINTS } from './Endpoints';

function generate(prefix: string): string {
    return `${prefix} ${Math.random()}`;
}

let __systemUnderTest = false;

const startTesting = () => {
    __systemUnderTest = true;
};
const stopTesting = () => {
    __systemUnderTest = false;
};
const isUnderTest = () => {
    return __systemUnderTest;
};

const harness = {
    startTesting,
    stopTesting,
    isUnderTest,
    fastCreateProject: async () => {
        startTesting();

        const projectName = generate('Project');

        const { data: project } = await API.post(ENDPOINTS.getUrl(CONSTANTS.CREATE_PROJECT), {
            name: projectName,
            projectType: 'SUPPLIER',
        });

        const { projectId } = project;

        return {
            accessUrl: createUrl(ROUTES.PROJECT_DASHBOARD, { projectId }),
            project,
            projectId,
        };
    },
    fastDeleteProject: async (projectId: string) => {
        startTesting();
        const url = ENDPOINTS.getUrl(CONSTANTS.DELETE_PROJECT, { projectId });
        return API.post(url);
    },
    fastBulkDeleteProjects: async () => {
        startTesting();
        const { data } = await API.get(ENDPOINTS.getUrl(CONSTANTS.PROJECTS));
        data.forEach(async ({ projectId }: { projectId: string }) => {
            await harness.fastDeleteProject(projectId);
        });
    },
    fastCreateQuestion: async (existingProjectId?: string) => {
        startTesting();
        let projectId = existingProjectId;
        let newProject = null;

        if (!existingProjectId) {
            newProject = await harness.fastCreateProject();
            projectId = newProject.projectId;
        }

        const questionText = generate('Question');

        const url = ENDPOINTS.getUrl(CONSTANTS.ADD_QUESTION, { projectId });

        const { data: question } = await API.post(url, {
            question: questionText,
        });

        const { questionId } = question;

        return {
            accessUrl: createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, { projectId, questionId }),
            question,
            newProject,
            projectId,
            questionId,
        };
    },
    fastCreateAnswer: async () => {
        startTesting();
        const questionText = generate('Question');
        const answerText = generate('Answer');

        const url = ENDPOINTS.getUrl(CONSTANTS.SAVE_QUESTIONS_AND_ANSWERS);

        const { data: answerIds } = await API.post(url, {
            answers: [
                {
                    question: questionText,
                    answer: answerText,
                    richTextAnswer: answerText,
                },
            ],
        });

        const { answerIds: answerId } = answerIds;

        return {
            answerId,
        };
    },
    fastDeleteAnswer: async (answerId: string) => {
        startTesting();
        const url = ENDPOINTS.getUrl(CONSTANTS.DELETE_ANSWER, { answerId });
        return API.post(url);
    },
};

export default harness;
