import { Avatar } from 'components/duex/Avatar';
import React, { ReactElement } from 'react';

export const Suggestion = ({ id, display }: { id: string; display: string }): ReactElement => {
    return (
        <div className="flex w-256 cursor-pointer items-center px-16 py-14 text-black-80 hover:bg-primary-blue-100-opace-4 hover:text-primary-blue-100">
            <span className="mr-12 rounded-full p-2">
                <Avatar size={32} userId={id} />
            </span>
            <span
                data-cy="TagUser"
                className="subtitle-2-reg truncate"
                style={{
                    width: '80%',
                }}
            >
                {display}
            </span>
        </div>
    );
};
