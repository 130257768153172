import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import _isEqual from 'lodash/isEqual';
import { SectionTitle } from 'modules/answer-library/analytics/typography';
import { SettingsCheckbox, SettingsItem } from 'modules/settings/settings-components';
import { default as React, ReactElement, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
export const AnswerSourceModal = ({
    closeModal,
    projectId,
}: {
    closeModal: () => void;
    projectId: string;
}): ReactElement => {
    const unorganisedCollectionsId = 'UNORGANIZED_COLLECTIONS';

    const [saving, setSaving] = useState(false);
    const [fetchingCollections, setFetchingCollections] = useState(false);
    const [fetchingProjectSettings, setFetchingProjectSettings] = useState(false);
    const [collections, setCollections] = useState([]);
    const [projectSettings, setProjectSettings] = useState(null);
    const [currentSettings, setCurrentSettings] = useState(null);

    const fetchProjectSettings = async (initial = true) => {
        try {
            setFetchingProjectSettings(initial);
            const url = ENDPOINTS.getUrl(CONSTANTS.ENDPOINT_GET_PROJECT, {
                projectId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                setProjectSettings(results.data.answerSourcesConfig);
                setCurrentSettings(results.data.answerSourcesConfig);
            }
            setFetchingProjectSettings(false);
        } catch (err) {
            setFetchingProjectSettings(false);
            showError('There was an error fetching the collections for this answer', err);
        }
    };

    const fetchCollections = async () => {
        try {
            setFetchingCollections(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_ANSWER_COLLECTION);

            const results = await API.get(url);
            if (results && results.data) {
                setCollections(results.data);
            }
            setFetchingCollections(false);
        } catch (err) {
            showError('There was an error fetching the list of collections', err);
            setFetchingCollections(false);
        }
    };

    const checkAll = () => {
        setCurrentSettings(null);
    };

    const uncheckAll = () => {
        const update = {};

        collections.map(({ collectionId }) => {
            update[collectionId] = false;
        });
        setCurrentSettings(update);
    };

    const toggleCheck = (toggleCollectionId: string, newState: boolean) => {
        if (currentSettings === null) {
            const update = {};

            collections.map(({ collectionId }) => {
                if (collectionId !== toggleCollectionId) {
                    update[collectionId] = true;
                } else {
                    update[collectionId] = false;
                }
            });
            setCurrentSettings(update);
        } else {
            setCurrentSettings({
                ...currentSettings,
                [toggleCollectionId]: newState,
            });
        }
    };

    const undoChanges = () => {
        setCurrentSettings(projectSettings);
    };

    const saveChanges = async () => {
        try {
            setSaving(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_PROJECT, {
                projectId,
            });

            const results = await API.post(url, {
                answerSourcesConfig: currentSettings,
            });
            if (results && results.data) {
                setSaving(false);
                closeModal();
            }
        } catch (err) {
            showError('There was an error making those changes', err);
            setSaving(false);
        }
    };

    useEffect(() => {
        fetchCollections();
        fetchProjectSettings();
    }, [projectId]);

    const dataLoaded = !fetchingCollections && !fetchingProjectSettings;
    const hasChanges = !_isEqual(projectSettings, currentSettings);

    return (
        <Modal width="w-600" closeModal={closeModal} title="Answer Sources">
            {!dataLoaded && <LoadingSpinner />}
            {dataLoaded && collections.length === 0 && (
                <EmptyState
                    title="No Collections"
                    subtitle="You don't have any collections setup. This project will have all Answers available to it"
                />
            )}

            {dataLoaded && collections.length > 0 && (
                <>
                    <SectionTitle title="Suggest Answers from" />
                    <SettingsItem>
                        <SettingsCheckbox
                            description={'All Sources'}
                            currentValue={currentSettings === null}
                            onChange={() => {
                                if (currentSettings === null) {
                                    uncheckAll();
                                } else {
                                    checkAll();
                                }
                            }}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsCheckbox
                            description={'Unorganized Answers'}
                            currentValue={
                                currentSettings === null || currentSettings[unorganisedCollectionsId] || false
                            }
                            onChange={(updatedState: boolean) => {
                                toggleCheck(unorganisedCollectionsId, updatedState);
                            }}
                        />
                    </SettingsItem>
                    <SectionTitle title="Collections" />
                    {collections.map((collection) => {
                        return (
                            <SettingsItem key={collection.collectionId}>
                                <SettingsCheckbox
                                    description={collection.collectionName}
                                    currentValue={
                                        currentSettings === null || currentSettings[collection.collectionId] || false
                                    }
                                    onChange={(updatedState: boolean) => {
                                        toggleCheck(collection.collectionId, updatedState);
                                    }}
                                />
                            </SettingsItem>
                        );
                    })}
                    <ButtonBar className="mx-16 mt-32">
                        <Button
                            label="Save Changes"
                            onClick={saveChanges}
                            loading={saving}
                            disabled={!hasChanges || saving}
                        />
                        {hasChanges && (
                            <Button
                                buttonType="SECONDARY"
                                label="Undo Changes"
                                onClick={undoChanges}
                                disabled={!hasChanges || saving}
                            />
                        )}
                    </ButtonBar>
                </>
            )}
        </Modal>
    );
};
