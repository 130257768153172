import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { isValidStringOfLength } from 'components/validation/string';
import { useUserContext } from 'hooks/useUserContext';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONFIG } from 'utility/Config';

export const ConfirmAccount = (): React.ReactElement => {
    const navigate = useNavigate();
    const auth = useUserContext();
    const [verificationCode, setVerificationCode] = useState('');
    const [errorMsgConfirmAcc, setErrorMsgConfirmAcc] = useState('');
    const [showSpinnerConfirmAcc, toggleSpinnerConfirmAcc] = useState(false);
    const [showSpinnerResendCode, toggleSpinnerResendCode] = useState(false);
    const [showSuccessMsg, toggleSuccessMsg] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = decodeURIComponent(urlParams.get('email'));

    if (!userEmail) {
        navigate(ROUTES.LOGIN);
    }

    const annotateErrorMessage = (errorMessage: string): string => {
        if (errorMessage === 'Confirmation code cannot be empty') {
            return 'Please check your verification code and try again.';
        }
        if (errorMessage === 'Attempt limit exceeded, please try after some time.') {
            return 'Whoa, hold on there. Please check your verification code and email are correct';
        }

        return errorMessage;
    };

    useEffect(() => {
        document.title = 'Pearler | Confirm Account';
    }, []);

    const confirmRegistration = async () => {
        try {
            const cleanedEmail = userEmail.trim().toLowerCase();
            const cleanedVerificationCode = verificationCode.replace(/\D/g, '');
            toggleSpinnerConfirmAcc(true);
            const { error, errorMessage, success } = await auth.confirmSignUp(cleanedEmail, cleanedVerificationCode);

            await auth.autoSignIn();

            if (success) {
                navigate(`${ROUTES.COMPLETE_REGISTRATION}?email=${encodeURIComponent(cleanedEmail)}`);
            } else if (error) {
                setErrorMsgConfirmAcc(annotateErrorMessage(errorMessage.message));
            }
            toggleSpinnerConfirmAcc(false);
        } catch (error) {
            // TODO: Error handling
            console.error(error);
        }
    };

    const resendCode = async () => {
        try {
            const cleanedEmail = userEmail.trim().toLowerCase();
            toggleSpinnerResendCode(true);
            const { success, error, errorMessage } = await auth.resendConfirmationCode(cleanedEmail);

            if (success) {
                toggleSuccessMsg(true);
            } else if (error) {
                setErrorMsgConfirmAcc(errorMessage.message);
            }
            toggleSpinnerResendCode(false);
        } catch (error) {
            // TODO: Error handling
            console.error(error);
        }
    };

    if (showSuccessMsg) {
        setTimeout(() => {
            toggleSuccessMsg(false);
        }, CONFIG.TIME_TO_CHANGE_BUTTON_STATE);
    }

    const alreadyConfirmed = 'User cannot be confirmed. Current status is CONFIRMED' === errorMsgConfirmAcc;

    const formIsValid = isValidStringOfLength(verificationCode, 6);

    return (
        <div className="grid gap-16">
            <h1 className="h3-bold select-none text-center text-primary-blue-80">Confirm email</h1>
            <h2 className="h6-medium text-center text-primary-blue-60">We&apos;ve emailed you a verification code.</h2>
            {(alreadyConfirmed || errorMsgConfirmAcc) && (
                <p
                    className={`subtitle-2-reg min-h-20 select-none text-left
                text-red`}
                >
                    {alreadyConfirmed && (
                        <p>
                            You&amp;ve already verified your account. Click{' '}
                            <Link style={{ color: '#2068E3' }} to={ROUTES.LOGIN}>
                                here
                            </Link>{' '}
                            to login to your account.
                        </p>
                    )}
                    {!alreadyConfirmed && errorMsgConfirmAcc}
                </p>
            )}

            <form
                onSubmit={async (event) => {
                    event.preventDefault();
                    await confirmRegistration();
                }}
                className="grid gap-16"
            >
                <fieldset>
                    <FormTextField
                        defaultValue={verificationCode}
                        label="Verification Code"
                        validator={(value: string) => isValidStringOfLength(value, 6)}
                        valueChanged={(val: string) => {
                            setVerificationCode(val);
                        }}
                        onBlur={() => {
                            setErrorMsgConfirmAcc('');
                        }}
                        placeholder="Verification Code"
                        errorMessage="Please enter the verification code"
                        autoComplete="one-time-code"
                        maxLength={10}
                    />
                </fieldset>
                <div className="grid w-full gap-16">
                    <Button
                        action="submit"
                        buttonType="LARGE_PRIMARY"
                        label="Confirm Account"
                        loading={showSpinnerConfirmAcc}
                        disabled={!formIsValid || showSpinnerResendCode}
                    />
                    <ButtonBar className="grid w-full grid-cols-2">
                        <Button
                            buttonType="SECONDARY"
                            label="Back to Sign In"
                            onClick={() => {
                                navigate(ROUTES.LOGIN);
                            }}
                        />
                        <Button
                            buttonType="SECONDARY"
                            onClick={resendCode}
                            label="Resend Verification Code"
                            loading={showSpinnerResendCode}
                            disabled={showSpinnerResendCode}
                        />
                    </ButtonBar>
                </div>
            </form>
        </div>
    );
};
