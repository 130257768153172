import { Logo } from 'assets/logo.svg';
import React from 'react';
import { Loader } from '../components/loader.component';

export const ApplicationLoading = (): React.ReactElement => {
    return (
        <div
            className="flex h-screen w-full flex-col justify-center"
            style={{
                backgroundColor: '#2F80ED',
                background: 'linear-gradient(113.01deg, #2F80ED 0.36%, #103668 50.18%, #2F80ED 100%)',
            }}
        >
            <div className="flex flex-col items-center justify-items-center">
                <div className="my-32">
                    <Logo />
                </div>
                <Loader strokeColor="#ffffff" />
            </div>
        </div>
    );
};
