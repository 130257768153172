import { orderBy } from 'lodash';
import _each from 'lodash/each';
import _sortBy from 'lodash/sortBy';
import { Library, Question } from 'types';
import { CONSTANTS } from 'utility/Constants';
interface ButtonParams {
    value: string;
    label: string;
}

export const getDefaultButton = (currentQuestion: Question, questionList: Question[]): ButtonParams => {
    const unansweredList = questionList.filter((question) => {
        return question.responseStatus !== CONSTANTS.COMPLETE;
    });

    if (unansweredList.length > 1) {
        return {
            value: CONSTANTS.ANSWERING_SAVE_NEXT,
            label: 'Save & Next',
        };
    }

    if (unansweredList.length === 1) {
        if (unansweredList[0].questionId === currentQuestion.questionId) {
            return {
                value: CONSTANTS.ANSWERING_SAVE,
                label: 'Save',
            };
        } else {
            return {
                value: CONSTANTS.ANSWERING_SAVE_NEXT,
                label: 'Save & Next',
            };
        }
    }

    if (unansweredList.length === 0) {
        return {
            value: CONSTANTS.ANSWERING_SAVE,
            label: 'Save',
        };
    }
};

export const getSaveOptions = (questionList: Question[]): ButtonParams[] => {
    const unansweredList = questionList.filter((question) => question.responseStatus !== CONSTANTS.COMPLETE);

    if (unansweredList.length < 2) {
        return [
            {
                value: CONSTANTS.ANSWERING_COPY_SAVE,
                label: 'Save & Copy',
            },
        ];
    }

    return [
        {
            value: CONSTANTS.ANSWERING_SAVE,
            label: 'Save',
        },
        {
            value: CONSTANTS.ANSWERING_COPY_SAVE,
            label: 'Save & Copy',
        },
    ];
};

export const hideStarterResponses = (complianceValue: string, answerValue: string): boolean => {
    const hasChosenCompliance = complianceValue !== CONSTANTS.INCOMPLETE;
    return Boolean(hasChosenCompliance || answerValue);
};

const getIndicesOf = (termString = '', questionString = '') => {
    // Handling empty strings/edge cases
    if (!questionString || !termString || questionString.length === 0 || termString.length === 0) {
        return [];
    }

    // Process to return indicies
    termString = termString.toLowerCase();
    questionString = questionString.toLowerCase();

    if (termString.indexOf(' ') === -1) {
        // Term doesn't have a space in it
        const words = questionString.split(' ');

        const indexes = [];
        let index = 0;

        _each(words, (word) => {
            const processedWord = word.replace(/\W/g, '');

            if (processedWord === termString) {
                const actualIndex = index + word.indexOf(termString);

                if (actualIndex >= 0) {
                    indexes.push({
                        start: actualIndex,
                        length: termString.length,
                        end: actualIndex + termString.length,
                        term: termString,
                    });
                }
            }

            index = index + word.length + 1;
        });
        return indexes;
    } else {
        // Term has a space in it
        let index = 0;
        let startIndex = 0;
        const indexes = [];
        while ((index = questionString.indexOf(termString, startIndex)) > -1) {
            if (index >= 0) {
                indexes.push({
                    start: index,
                    length: termString.length,
                    end: index + termString.length,
                    term: termString,
                });
            }
            startIndex = index + termString.length;
        }
        return indexes;
    }
};

const createKey = (phrase: string, identifier: number): string => {
    const withoutWords = phrase?.replaceAll(' ', '');
    return `${withoutWords}-${identifier}`;
};

type segment = {
    type: string;
    content: string;
    key: string;
};

export const splitQuestionIntoSegments = (question = '', terms: Library[]): segment[] => {
    const segments: segment[] = [];

    if (terms.length === 0 || !question) {
        return [
            {
                type: 'TEXT',
                content: question,
                key: createKey('just-text', 0),
            },
        ];
    }

    let indicies = [];

    const sortedTerms = _sortBy(terms, ({ term }) => -term.length);

    sortedTerms.forEach(({ term }) => {
        indicies = indicies.concat(getIndicesOf(term, question));
    });

    const ordered = orderBy(indicies, 'start');

    let processingIndex = 0;

    ordered.forEach((term) => {
        if (processingIndex < term.start) {
            const text = question.substr(processingIndex, term.start - processingIndex);
            segments.push({
                type: 'TEXT',
                content: text,
                key: createKey(text, processingIndex),
            });
        }

        if (processingIndex <= term.start) {
            const text = question.substr(term.start, term.length);
            segments.push({
                type: 'TERM',
                content: text,
                key: createKey(text, processingIndex),
            });
            processingIndex = term.end;
        }
    });

    if (processingIndex < question.length) {
        const text = question.substr(processingIndex, question.length - processingIndex);
        segments.push({
            type: 'TEXT',
            content: text,
            key: createKey(text, processingIndex),
        });
    }

    return segments;
};
