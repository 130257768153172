import React, { ReactElement } from 'react';
import { IconProps } from 'types/icon.type';

export const EditPencil = ({ strokeColor }: IconProps): ReactElement => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.16669 3.83334H3.33335C2.89133 3.83334 2.4674 4.00894 2.15484 4.3215C1.84228 4.63406 1.66669 5.05798 1.66669 5.50001V17.1667C1.66669 17.6087 1.84228 18.0326 2.15484 18.3452C2.4674 18.6577 2.89133 18.8333 3.33335 18.8333H15C15.442 18.8333 15.866 18.6577 16.1785 18.3452C16.4911 18.0326 16.6667 17.6087 16.6667 17.1667V11.3333"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.4167 2.58332C15.7482 2.2518 16.1978 2.06555 16.6667 2.06555C17.1355 2.06555 17.5852 2.2518 17.9167 2.58332C18.2482 2.91484 18.4345 3.36448 18.4345 3.83332C18.4345 4.30216 18.2482 4.7518 17.9167 5.08332L10 13L6.66669 13.8333L7.50002 10.5L15.4167 2.58332Z"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
