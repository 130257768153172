import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Module } from 'components/duex/Module';
import { showError } from 'components/error-toast.component';
import React, { ReactElement, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { AddAssessmentModal } from './create-assessment.modal';
import { EditAssessment } from './edit-assessment';

export const AssessmentHome = (): ReactElement => {
    const [assessmentList, setAssessmentList] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [selectedAssessment, setSelectedAssessment] = useState(null);
    const [showAddAssessment, setShowAddAssessment] = useState(false);
    // const navigate = useNavigate();

    const fetchData = async () => {
        try {
            setFetchingData(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_GET_TEMPLATES);
            const result = await API.get(url);

            setFetchingData(false);

            if (result) {
                setAssessmentList(result.data);
            }
        } catch (err) {
            showError('There was an error retrieving your Assessment Templates', err);
            setFetchingData(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        document.title = 'Pearler | Assessments';
    }, []);

    return (
        <Module
            moduleName="Assessments"
            rightItem={
                <Button
                    buttonType="SECONDARY"
                    className="mr-16"
                    label="Create Assessment"
                    onClick={() => {
                        setShowAddAssessment(true);
                    }}
                />
            }
        >
            <div
                className="grid h-full max-h-full gap-16"
                style={{
                    gridTemplateColumns: '300px auto',
                }}
            >
                <div className="h-full rounded-r-lg bg-faded-gray px-8 py-16">
                    {fetchingData && <LoadingSpinner />}
                    <div className="grid gap-8">
                        <h3 className="ml-8 select-none text-14 uppercase leading-32 text-black-80">Templates</h3>
                        {assessmentList.map((assessment) => {
                            const isSelected = selectedAssessment?.assessmentId === assessment.assessmentId;

                            return (
                                <div
                                    className={`flex flex-row items-center gap-16 rounded-lg p-8 cursor-pointer ${
                                        isSelected ? 'bg-blue-600 text-white' : 'hover:bg-blue-100'
                                    }`}
                                    key={assessment.assessmentId}
                                    onClick={() => {
                                        setSelectedAssessment(assessment);
                                    }}
                                >
                                    <Avatar size={32} userId={assessment.createdByUserId} />
                                    <div className="select-none">
                                        <div className={`truncate ${isSelected ? 'text-white' : 'text-black'}`}>
                                            {assessment.assessmentName}
                                        </div>
                                        <div
                                            className={`truncate ${
                                                isSelected ? 'text-white' : 'text-gray-500'
                                            } text-14`}
                                        >
                                            {assessment.assessmentDescription}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {selectedAssessment && <EditAssessment assessment={selectedAssessment} refresh={fetchData} />}
                {showAddAssessment && (
                    <AddAssessmentModal
                        closeModal={() => {
                            fetchData();
                            setShowAddAssessment(false);
                        }}
                    />
                )}
            </div>
        </Module>
    );
};
