import { DatePicker } from 'components/date-picker.component';
import { DropDownWithSearch } from 'components/dropdown-with-search.component';
import { Tags } from 'components/duex/Tags';
import { EditableLabel, ListOption } from 'components/editable-label.component';
import { showError } from 'components/error-toast.component';
import { industryOptions } from 'data/industry-vertical-options';
import { useTeamContext } from 'hooks/useTeamContext';
import { useUserContext } from 'hooks/useUserContext';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _upperFirst from 'lodash/upperFirst';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Division from 'types/division.type';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONFIG } from 'utility/Config';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { PERMISSION } from 'utility/Permission';
import { ProjectDashboardComponents } from '../project-dashboard.view';

const priorityOptions = [
    {
        value: 'Low',
        label: 'Low',
    },
    {
        value: 'Normal',
        label: 'Normal',
    },
    {
        value: 'High',
        label: 'High',
    },
];

export const outcomeOptions = [
    {
        value: 'In Progress',
        label: 'In Progress',
    },
    {
        value: 'Won',
        label: 'Won',
    },
    {
        value: 'Lost',
        label: 'Lost',
    },
    {
        value: 'Unknown',
        label: 'Unknown',
    },
];

export const expectedOutcomeOptions = [
    {
        value: '1',
        label: 'Unlikely to Win',
    },
    {
        value: '3',
        label: 'Even Chance',
    },
    {
        value: '5',
        label: 'Likely to Win',
    },
];

const notProvided = 'Not Provided';

const InfoBlock = ({
    classes,
    title,
    body,
    isLink,
}: {
    classes?: string;
    title: string;
    body: string;
    isLink?: boolean;
}): ReactElement => (
    <div className={classes}>
        <p className="small-1-med mb-5 font-semibold text-black-100">{title}</p>
        <div className={`body-2-reg  ${body === notProvided ? 'italic text-black-60' : 'text-black-80'} `}>
            {isLink ? (
                <a href={body}>
                    <div className="hover:text-sea-blue">{body}</div>
                </a>
            ) : (
                body
            )}
        </div>
    </div>
);

const UpdatableInfoBlock = ({
    classes,
    title,
    isLink,
    value,
    inputType,
    placeholder,
    onChange,
    screensize,
    listOptions,
    spellCheck = false,
    dataCy,
}: {
    classes?: string;
    title: string;
    value: string;
    isLink?: boolean;
    screensize: number;
    inputType?: 'text' | 'textarea' | 'select';
    placeholder?: string;
    listOptions?: ListOption[];
    spellCheck?: boolean;
    onChange: (updatedValue: string) => void;
    dataCy?: string;
}): ReactElement => (
    <div className={classes}>
        <p className="small-1-med mb-5 font-semibold text-black-100">{title}</p>
        <div className="body-2-reg">
            <EditableLabel
                labelClasses={`${value ? 'text-black-80' : 'text-black-60 italic'} hover:underline`}
                labelText={value || ''}
                defaultText="Not Provided"
                inputType={inputType}
                listOptions={listOptions}
                textInputBackground={`${screensize <= CONFIG.BREAKPOINT_XL ? 'bg-white' : 'bg-transparent'}`}
                textInputClasses={`${screensize <= CONFIG.BREAKPOINT_XL ? '' : 'p-4'}`}
                placeholderText={placeholder}
                onChange={onChange}
                isLink={isLink}
                spellCheck={spellCheck}
                dataCy={dataCy}
            />
        </div>
    </div>
);

export const ProjectDetails = ({
    dashboardData,
    refresh,
    updateProjectInfo,
    projectTeam,
}: ProjectDashboardComponents): ReactElement => {
    interface BasicUser {
        id: string;
        name: string;
    }

    const unassigned = {
        id: 'Not assigned',
        name: 'Not assigned',
    };

    const [screensize, changeScreenSize] = useState(window.innerWidth);
    const { hasPermission } = useUserContext();
    const { hasFeature, userDivisions } = useTeamContext();
    const { project } = dashboardData;
    const { projectId } = project;
    const isSupplierProject = project.projectType === 'SUPPLIER';

    const changeProjectAssignee = async (updatedRole: string, userIdAssignedToUpdatedRole: string) => {
        try {
            const started = new Date();
            let url = '';
            const data = {
                projectId,
            };

            if (updatedRole) {
                url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_PROJECT, data);
            } else {
                url = ENDPOINTS.getUrl(CONSTANTS.PROJECT_UNASSIGN, data);
            }

            const body = {
                [updatedRole]: userIdAssignedToUpdatedRole,
            };

            const response = await API.post(url, body);

            if (response && response.data) {
                if (updatedRole === 'deliveryManagerUserId') {
                    logEvent('PROJECT_DELIVERY_MANAGER_UPDATED', started, {
                        projectId,
                        updatedRole,
                        userIdAssignedToUpdatedRole,
                    });
                } else {
                    logEvent('PROJECT_SALES_MANAGER_UPDATED', started, {
                        projectId,
                        updatedRole,
                        userIdAssignedToUpdatedRole,
                    });
                }
                refresh();
            }
        } catch (err) {
            showError('There was a problem updating the assignee', err);
        }
    };

    const setupManagers = () => {
        const fetchedSalesManager = _find(projectTeam, { member: { userId: project.salesManagerUserId } });
        const fetchedDeliveryManager = _find(projectTeam, { member: { userId: project.deliveryManagerUserId } });

        // Assigned Sales Manager
        let assignedSalesManager = unassigned;

        if (fetchedSalesManager) {
            assignedSalesManager = {
                id: fetchedSalesManager.userId,
                name: fetchedSalesManager.member.fullName,
            };

            setSalesManager(assignedSalesManager);
        }

        // Assigned Delivery Manager
        let assignedDeliveryManager = unassigned;

        if (fetchedDeliveryManager) {
            assignedDeliveryManager = {
                id: fetchedDeliveryManager.userId,
                name: fetchedDeliveryManager.member.fullName,
            };

            setDeliveryManager(assignedDeliveryManager);
        }
    };

    useEffect(() => {
        setupManagers();
    }, [project, projectTeam]);

    useEffect(() => {
        if (project && project.name) {
            document.title = `Pearler | ${project.name}`;
        }
    }, [project]);

    useEffect(() => {
        window.removeEventListener('resize', () => changeScreenSize(window.innerWidth));
        window.addEventListener('resize', () => changeScreenSize(window.innerWidth));
        return window.removeEventListener('resize', () => changeScreenSize(window.innerWidth));
    }, []);

    const [deliveryManager, setDeliveryManager] = useState<BasicUser>(unassigned);
    const [salesManager, setSalesManager] = useState<BasicUser>(unassigned);

    const cbUpdateSalesManager = (newItem) => {
        const temp = {
            id: newItem.member.userId,
            name: newItem.member.fullName,
        };
        if (temp.id !== salesManager.id) {
            setSalesManager(temp);
            changeProjectAssignee('salesManagerUserId', newItem.member.userId);
        }
    };

    const cbUpdateDeliveryManager = (newItem) => {
        const temp = {
            id: newItem.member.userId,
            name: newItem.member.fullName,
        };
        if (temp.id !== deliveryManager.id) {
            setDeliveryManager(temp);
            //thunk to change update projects with new details
            changeProjectAssignee('deliveryManagerUserId', newItem.member.userId);
        }
    };

    const canAssignDeliveryManager = hasPermission(PERMISSION.ASSIGN_PROJECT_DELIVERY_MANAGER);
    const canAssignSalesManager = hasPermission(PERMISSION.ASSIGN_PROJECT_SALES_MANAGER);

    const divisionsEnabled = hasFeature('enableDivisions');

    const divisionOptions = _map(userDivisions, ({ divisionName, divisionId }: Division) => {
        return {
            label: divisionName,
            value: divisionId,
        };
    });

    return (
        <div
            className={`${
                screensize <= CONFIG.BREAKPOINT_XL ? 'flex flex-wrap border-b border-black-10' : ' bg-cream'
            } p-24`}
            style={{
                gridArea: 'additional-info',
            }}
        >
            <div
                className={`${
                    screensize <= CONFIG.BREAKPOINT_XL ? 'w-50-percent border-r' : 'border-b'
                } border-black-10`}
            >
                <h2 className="subtitle-1-med mb-16">Project Details</h2>
                <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'justify-between' : 'flex-col'} flex`}>
                    <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'w-30-percent' : ''} mb-24`}>
                        {divisionsEnabled && (
                            <UpdatableInfoBlock
                                classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                                title="Division"
                                screensize={screensize}
                                placeholder="Select a division"
                                value={project.division}
                                inputType="select"
                                listOptions={divisionOptions}
                                onChange={(updatedValue) => {
                                    updateProjectInfo('division', updatedValue);
                                }}
                                dataCy="Project_Division"
                            />
                        )}

                        <UpdatableInfoBlock
                            classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : ''} mb-24`}
                            title="Description"
                            placeholder="Enter a description of the project"
                            value={project.description}
                            inputType="textarea"
                            spellCheck={true}
                            screensize={screensize}
                            onChange={(updatedValue) => {
                                updateProjectInfo('description', updatedValue);
                            }}
                            dataCy="Project_Description"
                        />
                    </div>
                    <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'w-30-percent' : ''} mb-24`}>
                        {isSupplierProject && (
                            <>
                                {canAssignSalesManager ? (
                                    <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'}`}>
                                        <p className="small-1-med mb-5 font-semibold text-black-100">Sales Manager</p>
                                        <div className="relative -left-12" data-cy="SalesManager">
                                            <DropDownWithSearch
                                                {...{
                                                    teamMembers: projectTeam,
                                                    selectedMember: salesManager,
                                                    showArrow: true,
                                                    classForInput: 'select-none flex items-center',
                                                    showThumbNail: false,
                                                    stylingOfValue:
                                                        'w-150 select-none ml-12 inline font-normal text-14 leading-20 tracking-0.1 text-light-black',
                                                    showListItemCheck: true,
                                                    callBackFunction: cbUpdateSalesManager,
                                                    alwaysShowDefault: false,
                                                    dataCy: 'Sales_Manager_Dropdown',
                                                }}
                                            ></DropDownWithSearch>
                                        </div>
                                    </div>
                                ) : (
                                    <InfoBlock
                                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'}`}
                                        title="Sales Manager"
                                        body={salesManager.name}
                                    />
                                )}
                            </>
                        )}
                        {canAssignDeliveryManager ? (
                            <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'}`}>
                                <p className="small-1-med mb-5 font-semibold text-black-100">Delivery Manager</p>
                                <div className="relative -left-12" data-cy="DeliveryManager">
                                    <DropDownWithSearch
                                        {...{
                                            teamMembers: projectTeam,
                                            selectedMember: deliveryManager,
                                            showArrow: true,
                                            classForInput: 'select-none flex items-center',
                                            showThumbNail: false,
                                            stylingOfValue:
                                                'w-150 select-none ml-12 inline font-normal text-14 leading-20 tracking-0.1 text-light-black',
                                            showListItemCheck: true,
                                            callBackFunction: cbUpdateDeliveryManager,
                                            alwaysShowDefault: false,
                                            dataCy: 'Delivery_Manager_Dropdown',
                                        }}
                                    ></DropDownWithSearch>
                                </div>
                            </div>
                        ) : (
                            <InfoBlock
                                classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'}`}
                                title="Delivery Manager"
                                body={deliveryManager.name}
                            />
                        )}

                        <p className="small-1-med mb-5 font-semibold text-black-100">Internal Due Date</p>

                        <div
                            className={`dashboard-date-picker-container cursor-pointer ${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'}`}
                            data-cy="Due_Date"
                        >
                            <DatePicker
                                id="project-dashboard-due-date-picker"
                                dueDate={moment(project.dueOn)}
                                placeholder="Please choose a due date"
                                allowPastDates={false}
                                setDueDate={(updatedDate) => {
                                    updateProjectInfo('dueOn', moment(updatedDate).format(), true);
                                }}
                            />
                        </div>

                        <p className="small-1-med mb-5 font-semibold text-black-100">Submisson Due Date</p>

                        <div className="dashboard-date-picker-container cursor-pointer" data-cy="Submission_Due_Date">
                            <DatePicker
                                id="project-dashboard-submission-date-picker"
                                dueDate={moment(project.submissionDueDate)}
                                placeholder="Please choose a due date"
                                allowPastDates={false}
                                setDueDate={(updatedDate) => {
                                    updateProjectInfo('submissionDueDate', moment(updatedDate).format(), true);
                                }}
                            />
                        </div>
                    </div>
                    <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'w-30-percent' : ''} mb-24`}>
                        <UpdatableInfoBlock
                            classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                            title="Priority"
                            screensize={screensize}
                            value={_upperFirst(project.projectPriority.toLowerCase())}
                            inputType="select"
                            listOptions={priorityOptions}
                            onChange={(updatedValue) => {
                                updateProjectInfo('projectPriority', updatedValue.toUpperCase());
                            }}
                            dataCy="Project_Priority"
                        />
                    </div>
                </div>
            </div>
            {project.thirdPartyProjectUrl && (
                <div
                    className={`${
                        screensize <= CONFIG.BREAKPOINT_XL ? 'w-25-percent border-r pl-24' : 'border-b pt-24'
                    } border-black-10`}
                >
                    <h2 className="subtitle-1-med mb-16">External Portal</h2>
                    <UpdatableInfoBlock
                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                        title="Link"
                        screensize={screensize}
                        isLink={true}
                        placeholder="Provide the link to the other system"
                        value={project.thirdPartyProjectUrl}
                        onChange={(updatedValue) => {
                            updateProjectInfo('thirdPartyProjectUrl', updatedValue);
                        }}
                        dataCy="External_Link"
                    />
                    <UpdatableInfoBlock
                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                        title="Notes"
                        screensize={screensize}
                        inputType="textarea"
                        spellCheck={true}
                        placeholder="Add notes to help your team"
                        value={project.thirdPartyProjectNotes}
                        onChange={(updatedValue) => {
                            updateProjectInfo('thirdPartyProjectNotes', updatedValue);
                        }}
                        dataCy="Link_Notes"
                    />
                </div>
            )}
            <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'w-25-percent pl-24' : 'border-b pt-24'}`}>
                <h2 className="subtitle-1-med mb-16">Business Contact</h2>

                <UpdatableInfoBlock
                    classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                    title="Business Contact"
                    screensize={screensize}
                    placeholder="Provide a contact name"
                    value={project.companyContactName}
                    onChange={(updatedValue) => {
                        updateProjectInfo('companyContactName', updatedValue);
                    }}
                    dataCy="Business_Contact"
                />

                <UpdatableInfoBlock
                    classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                    title="Email"
                    screensize={screensize}
                    placeholder="Provide a contact email address"
                    value={project.companyContactEmail}
                    onChange={(updatedValue) => {
                        updateProjectInfo('companyContactEmail', updatedValue);
                    }}
                    dataCy="Contact_Email"
                />

                <UpdatableInfoBlock
                    classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                    title="Phone"
                    screensize={screensize}
                    placeholder="Provide a contact phone number"
                    value={project.companyContactPhone}
                    onChange={(updatedValue) => {
                        updateProjectInfo('companyContactPhone', updatedValue);
                    }}
                    dataCy="Phone_Number"
                />
                {isSupplierProject && (
                    <UpdatableInfoBlock
                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                        title="Industry"
                        screensize={screensize}
                        placeholder="Industry Vertical"
                        value={project.industryVertical}
                        inputType="select"
                        listOptions={industryOptions}
                        onChange={(updatedValue) => {
                            updateProjectInfo('industryVertical', updatedValue);
                        }}
                        dataCy="Project_Industry"
                    />
                )}
            </div>
            {isSupplierProject && (
                <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'w-25-percent pl-24' : 'border-b pt-24'}`}>
                    <h2 className="subtitle-1-med mb-16">Outcomes</h2>

                    <UpdatableInfoBlock
                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                        title="Project Value ($)"
                        screensize={screensize}
                        placeholder="Provide a value in currency"
                        value={project.projectValue}
                        onChange={(updatedValue) => {
                            updateProjectInfo('projectValue', updatedValue);
                        }}
                        dataCy="Project_Value"
                    />

                    <UpdatableInfoBlock
                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                        title="Expected Outcome"
                        screensize={screensize}
                        placeholder="Select from the outcomes"
                        value={project.projectExpectedOutcome}
                        inputType="select"
                        listOptions={expectedOutcomeOptions}
                        onChange={(updatedValue) => {
                            updateProjectInfo('projectExpectedOutcome', updatedValue);
                        }}
                    />

                    <UpdatableInfoBlock
                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                        title="Project Outcome"
                        screensize={screensize}
                        placeholder="Select from the outcomes"
                        value={project.projectOutcome}
                        inputType="select"
                        listOptions={outcomeOptions}
                        onChange={(updatedValue) => {
                            updateProjectInfo('projectOutcome', updatedValue);
                        }}
                        dataCy="Project_Outcome"
                    />

                    <UpdatableInfoBlock
                        classes={`${screensize <= CONFIG.BREAKPOINT_XL ? 'mb-20' : 'mb-24'} `}
                        title="Reasons for outcome"
                        screensize={screensize}
                        placeholder="Record information that helps with understanding the outcome"
                        value={project.projectOutcomeReason}
                        inputType="textarea"
                        spellCheck={true}
                        onChange={(updatedValue) => {
                            updateProjectInfo('projectOutcomeReason', updatedValue);
                        }}
                        dataCy="Outcome_Reason"
                    />
                </div>
            )}
            <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'hidden' : 'pt-24'}`}>
                <h2 className="subtitle-1-med mb-16">Tags</h2>
                <Tags scopeIdentifier={project.projectId} textInputClasses="p-8" scope="PROJECT" />
            </div>
        </div>
    );
};
