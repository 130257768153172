import { Button } from 'components/duex/Button';
import { FormSelectField } from 'components/duex/FormSelectField';
import { FormTextField } from 'components/duex/FormTextField';
import { showError } from 'components/error-toast.component';
import { Loader } from 'components/loader.component';
import { showSuccess } from 'components/success-toast.component';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import { DocumentView } from 'modules/project-importer/document-view';
import nl2br from 'nl2br';
import React, { ReactElement, useEffect, useState } from 'react';
import Collection from 'types/collection.type';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { SheetView } from './sheet-view';

export interface Column {
    content: string;
    columnNumber: number;
    columnType: string;
    richTextContent: string;
}
export interface Row {
    rowIdentifier: string;
    rowNumber: number;
    columns: Column[];
}

export interface Sheet {
    rowInclusion: {
        [key: string]: boolean;
    };
    sheetNumber: number;
    sheetName: string;
    columnClassifications: Column[];
    isLinked: boolean;
    columnCount: number;
    sheetContent: Row[];
}

export interface SheetConfiguration {
    columnClassifications: Column[];
    rowInclusion: {
        [key: number]: boolean;
    };
    sheetNumber: number;
    sheetName: string;
    columnCount: number;
    sheetContent: {
        rowNumber: number;
        columns: {
            columnNumber: number;
            content: string;
            hasValidFields: boolean;
            defaultInclude: boolean;
        }[];
    }[];
}

interface AnswerSource {
    teamId: string;
    fileName: string;
    documentContent: string | Sheet[];
    fileFormat: string;
    documentHtml: string;
}

export interface DocumentCombination {
    question: string;
    richTextAnswer: string;
}

export const LibraryImporter = ({
    currentAnswerSource,
    collections,
    fetchData,
}: {
    currentAnswerSource: AnswerSource;
    collections: Collection[];
    fetchData: () => void;
}): ReactElement => {
    const [selectedSheet, setSelectedSheet] = useState<Sheet>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [addToCollection, setAddToCollection] = useState(null);
    const [tags, setTags] = useState('');

    const [sheetsConfiguration, setSheetsConfiguration] = useState<SheetConfiguration>(null);
    const [includedQuestionsCount, setIncludedQuestionsCount] = useState({});
    const [documentCombinations, setDocumentCombinations] = useState<DocumentCombination[]>([]);

    const isSpreadsheet = currentAnswerSource.fileFormat === 'csv' || currentAnswerSource.fileFormat === 'xlsx';
    const isDocument = currentAnswerSource.fileFormat === 'docx' || currentAnswerSource.fileFormat === 'pdf';

    const saveAnswers = async () => {
        try {
            const started = new Date();
            setIsLoading(true);

            const helper = API;
            const url = ENDPOINTS.getUrl(CONSTANTS.SAVE_QUESTIONS_AND_ANSWERS);
            const result = await helper.post(url, {
                answers: getAnswerData(),
                collectionId: addToCollection,
            });
            logEvent(`ANSWER_LIBRARY_BULK_ADD_ANSWER`, started);

            if (result && result.data) {
                setIsLoading(false);
                showSuccess(`Imported ${result.data.imported} Answers`);
            }
        } catch (err) {
            showError('There was an error importing your data', err);
            setIsLoading(false);
        }
    };

    const getContentFromCell = (row: Row, columnNumber: number, preferRichText = false) => {
        for (let i = 0; i < row.columns.length; i++) {
            if (row.columns[i].columnNumber === columnNumber) {
                if (preferRichText && row.columns[i].richTextContent) {
                    return row.columns[i].richTextContent;
                }
                return row.columns[i].content;
            }
        }
    };

    const getRowData = (questionColumns: Column[], answerColumns: Column[], tagColumns: Column[], dataRow: Row) => {
        const questions = _map(questionColumns, ({ columnNumber }) => {
            return getContentFromCell(dataRow, columnNumber);
        });
        const answers = _map(answerColumns, ({ columnNumber }) => {
            return getContentFromCell(dataRow, columnNumber);
        });
        const richTextAnswers = _map(answerColumns, ({ columnNumber }) => {
            return getContentFromCell(dataRow, columnNumber, !isDocument);
        });
        const tagCols = _map(tagColumns, ({ columnNumber }) => {
            return getContentFromCell(dataRow, columnNumber);
        });

        const question = questions.join('\n\n').trim();
        const answer = answers.join('\n\n').trim();
        const richTextAnswer = richTextAnswers.join('').trim();
        const tags = tagCols.join(';').trim();

        if (question && answer) {
            return {
                question,
                richTextAnswer: richTextAnswer || nl2br(answer),
                tags,
            };
        }
    };

    const getAnswerData = () => {
        if (isSpreadsheet) {
            const data = [];

            for (const sheetNumber in sheetsConfiguration) {
                const rowInclusion = sheetsConfiguration[sheetNumber].rowInclusion;
                const sheetData = _find(currentAnswerSource.documentContent, {
                    sheetNumber: parseInt(sheetNumber),
                });

                const questionColumns = _filter(sheetsConfiguration[sheetNumber].columnClassifications, {
                    columnType: 'Question',
                });
                const answerColumns = _filter(sheetsConfiguration[sheetNumber].columnClassifications, {
                    columnType: 'Answer',
                });
                const tagColumns = _filter(sheetsConfiguration[sheetNumber].columnClassifications, {
                    columnType: 'Tags',
                });

                for (const rowNumber in rowInclusion) {
                    if (rowInclusion[rowNumber]) {
                        const row = _find(sheetData.sheetContent, {
                            rowNumber: parseInt(rowNumber),
                        });

                        const rowData = getRowData(questionColumns, answerColumns, tagColumns, row);

                        if (rowData) {
                            data.push(rowData);
                        }
                    }
                }
            }

            return data;
        }

        if (isDocument) {
            return _map(documentCombinations, (combination) => {
                return {
                    ...combination,
                    tags,
                };
            });
        }
    };

    const selectSheet = (sheetIndex: number) => {
        if (currentAnswerSource && isSpreadsheet && currentAnswerSource.documentContent.length > 0) {
            // @ts-ignore
            setSelectedSheet(currentAnswerSource.documentContent[sheetIndex]);
        }
    };

    const getTotalCount = (): number => {
        let count = 0;

        for (const key in includedQuestionsCount) {
            count = count + includedQuestionsCount[key];
        }

        count = count + documentCombinations.length;

        return count;
    };

    const calculateRowCounts = () => {
        const counts = {};

        for (const sheetNumber in sheetsConfiguration) {
            let count = 0;

            const dataSource = sheetsConfiguration[sheetNumber].rowInclusion;

            for (const row in dataSource) {
                if (dataSource[row]) {
                    count = count + 1;
                }
            }

            counts[sheetNumber] = count;
        }

        setIncludedQuestionsCount(counts);
    };

    useEffect(calculateRowCounts, [sheetsConfiguration]);

    useEffect(() => {
        if (currentAnswerSource && currentAnswerSource.documentContent) {
            setSheetsConfiguration(_keyBy(currentAnswerSource.documentContent, 'sheetNumber'));
        }

        if (!selectedSheet && currentAnswerSource) {
            selectSheet(0);
        }
    }, [currentAnswerSource]);

    const totalQuestionCount = getTotalCount();

    return (
        <>
            {isLoading && (
                <div className="mx-auto mt-64">
                    <Loader />
                </div>
            )}
            {!isLoading && currentAnswerSource && (
                <div className="flex max-h-full w-full flex-row gap-16">
                    <div className="w-250">
                        <div className="mx-8 my-4 flex flex-row justify-between text-12 font-bold uppercase">
                            <span>File</span>
                        </div>
                        <div className="overflow-y-auto" style={{ maxHeight: 'calc(100% - 24px - 72px)' }}>
                            <div className={`mb-16 overflow-hidden rounded-lg`}>
                                <div className="select-none bg-blue-50 px-8 py-16 text-12 font-medium text-blue-800">
                                    {currentAnswerSource.fileName || 'Copied into Pearler'}
                                </div>
                                {currentAnswerSource.documentContent && (
                                    <div>
                                        {isSpreadsheet &&
                                            // @ts-ignore
                                            currentAnswerSource.documentContent.map((sheet, sheetIndex) => {
                                                const questionCount = includedQuestionsCount[sheet?.sheetNumber] || 0;

                                                return (
                                                    <div
                                                        key={sheetIndex}
                                                        className={`cursor-pointer bg-gray-50 px-8 py-8 text-12 text-gray-600 ${
                                                            sheet?.sheetNumber === selectedSheet?.sheetNumber
                                                                ? 'bg-blue-100 text-gray-800'
                                                                : 'hover:bg-blue-100 hover:text-gray-800'
                                                        }  flex flex-row justify-between`}
                                                        onClick={() => {
                                                            selectSheet(sheetIndex);
                                                        }}
                                                    >
                                                        <span className="leading-24">{sheet.sheetName}</span>
                                                        <span
                                                            className={`h-24 w-24 rounded-full bg-white text-center text-10 font-medium leading-24 ${
                                                                questionCount === 0 ? 'text-red' : 'text-gray-400'
                                                            }`}
                                                        >
                                                            {questionCount}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex w-full flex-col justify-center py-16 gap-16">
                            {collections.length > 0 && (
                                <FormSelectField
                                    label="Add to collection (Optional)"
                                    valueChanged={(value) => {
                                        setAddToCollection(value);
                                    }}
                                    defaultValue={addToCollection}
                                    items={collections}
                                    optionLabel="collectionName"
                                    optionValue="collectionId"
                                    errorMessage="Please make a selection"
                                    placeholder="Select collection"
                                />
                            )}
                            {isDocument && (
                                <FormTextField
                                    label="Add tags (Optional)"
                                    valueChanged={setTags}
                                    defaultValue={tags}
                                    errorMessage="Please check that your tags are valid"
                                    placeholder="E.g. Security;Risk;InfoSec"
                                />
                            )}

                            <Button
                                testId="Import_Answers"
                                className="w-full"
                                onClick={saveAnswers}
                                label={`Import ${totalQuestionCount ? totalQuestionCount : ''} Answers`}
                                disabled={totalQuestionCount === 0 || isLoading}
                                loading={isLoading}
                            />
                        </div>
                    </div>
                    <div className="max-h-full w-80-percent">
                        {isSpreadsheet &&
                            selectedSheet &&
                            sheetsConfiguration &&
                            sheetsConfiguration[selectedSheet.sheetNumber] && (
                                <SheetView
                                    selectedFile={currentAnswerSource}
                                    selectedSheet={selectedSheet}
                                    questionCount={includedQuestionsCount[selectedSheet.sheetNumber]}
                                    sheetConfiguration={sheetsConfiguration[selectedSheet.sheetNumber]}
                                    setSheetConfiguration={(updatedConfiguration) => {
                                        setSheetsConfiguration({
                                            ...sheetsConfiguration,
                                            [selectedSheet.sheetNumber]: updatedConfiguration,
                                        });
                                    }}
                                />
                            )}
                        {isDocument && currentAnswerSource && (
                            <DocumentView
                                originalHtml={currentAnswerSource.documentHtml}
                                annotationsUpdated={setDocumentCombinations}
                                mode="LIBRARY"
                                fetchData={fetchData}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    );
};
