import React, { ReactElement } from 'react';

export const CollaborationIcon = ({ strokeColor }: { strokeColor: string }): ReactElement => {
    return (
        <svg width="15" height="15" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.5 8.08336C16.5029 9.18325 16.2459 10.2683 15.75 11.25C15.162 12.4265 14.2581 13.416 13.1395 14.1078C12.021 14.7995 10.7319 15.1662 9.41667 15.1667C8.31678 15.1696 7.23176 14.9126 6.25 14.4167L1.5 16L3.08333 11.25C2.58744 10.2683 2.33047 9.18325 2.33333 8.08336C2.33384 6.76815 2.70051 5.47907 3.39227 4.36048C4.08402 3.2419 5.07355 2.338 6.25 1.75002C7.23176 1.25413 8.31678 0.997156 9.41667 1.00002H9.83333C11.5703 1.09585 13.2109 1.82899 14.4409 3.05907C15.671 4.28915 16.4042 5.92973 16.5 7.66669V8.08336Z"
                stroke={strokeColor || '#2068E3'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
