import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { getText } from 'components/duex/RichTextEditor';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _forEach from 'lodash/forEach';
import _identity from 'lodash/identity';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import { RenderDocument } from 'modules/document-viewer/document-render';
import { DocumentCombination } from 'modules/library-importer/library-importer';
import React, { DragEvent, ReactElement, useEffect, useReducer, useRef, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { cleanNode } from './clean-node';
import { PopupMenu } from './popup-menu';

interface Highlight {
    nodeId: string;
    selectedNodes: string[];
}
export interface Annotation {
    nodeId: string;
    annotationType: string;
    parentNodeId?: string;
    inTable?: boolean;
    rowNodeId?: string;
    hasContent?: boolean;
    isCompleted: boolean;
    selectedNodes?: string[];
}

interface Configuration {
    tableFillEnabled: boolean;
    documentTableFillEnabled: boolean;
}

interface AnnotationDict {
    [key: string]: Annotation;
}

const dontImportMessage = "Won't be imported";

const validTags = ['TD', 'TH', 'P', 'LI', 'UL', 'OL', 'DIV', 'SPAN', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'];

function isValidTag(tagName: string): boolean {
    return validTags.indexOf(tagName.toUpperCase()) !== -1;
}

export const DocumentView = ({
    projectId = null,
    fetchData = null,
    setUnsavedChanges = null,
    undoChanges = null,
    selectedFile,
    mode = 'PROJECT',
    annotationsUpdated,
    originalHtml,
}: {
    projectId?: string;
    fetchData?: () => void;
    setUnsavedChanges?: (newValue: boolean) => void;
    undoChanges?: () => void;
    mode?: 'PROJECT' | 'LIBRARY';
    originalHtml?: string;
    selectedFile?: {
        fileName: string;
        document: {
            originalHtml: string;
            isLinked: boolean;
            configuration: Configuration;
            annotations: AnnotationDict;
            sourceDocumentId: string;
        };
    };
    annotationsUpdated?: (update: DocumentCombination[]) => void;
}): ReactElement => {
    const annotationReducer = (state, { change, annotation }): AnnotationDict => {
        if (change === 'RESET') {
            return {};
        }
        if (change === 'INITIAL') {
            return annotation;
        }

        if (!annotation.nodeId) {
            return state;
        }

        const newState = {
            ...state,
        };

        if (state[annotation.nodeId] || change === 'REMOVE_QUESTION') {
            // Overwriting something
            for (const nodeId in state) {
                if (state[nodeId].parentNodeId === annotation.nodeId) {
                    delete newState[nodeId];
                }
            }
        }

        if (change === 'ADD_QUESTION' || change === 'ADD_LINK') {
            newState[annotation.nodeId] = annotation;
        }
        if (change === 'REMOVE_QUESTION' || change === 'REMOVE_LINK') {
            delete newState[annotation.nodeId];
        }

        if (change === 'ADD_LINK' && mode === 'PROJECT') {
            for (const nodeId in state) {
                if (
                    state[nodeId].parentNodeId === annotation.parentNodeId &&
                    state[nodeId].annotationType === annotation.annotationType
                ) {
                    // Annotation of this type has already been made
                    delete newState[nodeId];
                }
            }
        }

        // Reconnect nodes
        for (const nodeId in newState) {
            const annotation = newState[nodeId];

            if (annotation.parentNodeId) {
                newState[nodeId].isCompleted = Boolean(newState[annotation.parentNodeId]);
            } else {
                let found = false;

                for (const item in newState) {
                    if (newState[item].parentNodeId === nodeId) {
                        found = true;
                        break;
                    }
                }

                newState[nodeId].isCompleted = found;
            }
        }

        return newState;
    };

    const [showMenu, setShowMenu] = useState(false);
    const [coords, setCoords] = useState({
        x: 0,
        y: 0,
    });
    const scrollRef = useRef(null);
    const documentRef = useRef(null);

    const [documentIncluded, setDocumentIncluded] = useState(true);
    const [tableFillEnabled, setTableFillEnabled] = useState(true);
    const [documentTableFillEnabled, setDocumentTableFillEnabled] = useState(true);

    const [isTracking, setTracking] = useState(null);
    const [highlightedNodes, setHighlightedNodes] = useState([]);

    const [highlight, setHighlight] = useState<Highlight>(null);

    const [annotations, updateAnnotations] = useReducer(annotationReducer, {});

    const [isSaving, setIsSaving] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);

    const getSelectedAnnotation = (nodeId: string) => {
        selectedAnnotation = annotations[nodeId];

        if (!selectedAnnotation) {
            for (const prop in annotations) {
                const annotation = annotations[prop];

                if (annotation.selectedNodes) {
                    if (annotation.selectedNodes.indexOf(nodeId) !== -1) {
                        selectedAnnotation = annotation;
                    }
                }
            }
        }

        return selectedAnnotation;
    };

    let selectedAnnotation;

    if (highlight && highlight.nodeId) {
        selectedAnnotation = getSelectedAnnotation(highlight.nodeId);
    }

    const saveChanges = async () => {
        try {
            setIsSaving(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.SAVE_SOURCE_DOCUMENT_CONFIGURATION, {
                projectId,
                sourceDocumentId: selectedFile.document.sourceDocumentId,
            });

            const results = await API.post(url, {
                annotations,
                configuration: getCurrentConfiguration(),
                isLinked: documentIncluded,
                questions: getQuestionsFromAnnotations(),
            });
            if (results && results.data) {
                setIsSaving(false);
                fetchData();
                showSuccess('Success! Questions updated');
            }
        } catch (err) {
            showError('There was an error saving your document configuration', err);
            setIsSaving(false);
        }
    };

    const getQuestionsAndAnswersFromAnnotations = () => {
        const answers: DocumentCombination[] = [];

        for (const key in annotations) {
            const annotation = annotations[key];

            if (annotation.annotationType === 'QUESTION') {
                const questionNodes = [annotation.nodeId];
                const answerNodes = [];

                if (annotation.selectedNodes) {
                    _forEach(annotation.selectedNodes, (nodeId) => {
                        if (questionNodes.indexOf(nodeId) === -1) {
                            questionNodes.push(nodeId);
                        }
                    });
                }

                for (const key in annotations) {
                    if (
                        annotations[key].annotationType === 'ANSWER' &&
                        annotations[key].parentNodeId === annotation.nodeId
                    ) {
                        answerNodes.push(key);
                    }
                }

                const question = _map(questionNodes.sort(sortNodes), (nodeId: string) => {
                    return getElementById(nodeId).innerText.trim();
                }).join('/n');

                const richTextAnswer = _map(answerNodes.sort(sortNodes), (nodeId: string) => {
                    return cleanNode(getElementById(nodeId).innerHTML.trim());
                }).join('<br />');

                const plainText = getText(richTextAnswer).trim();

                const validAnswer = plainText !== dontImportMessage;

                if (richTextAnswer && question && validAnswer) {
                    answers.push({
                        richTextAnswer,
                        question,
                    });
                }
            }
        }

        return answers;
    };

    const getQuestionsFromAnnotations = () => {
        const questions = [];

        for (const key in annotations) {
            const annotation = annotations[key];

            if (annotation.annotationType === 'QUESTION') {
                const questionNodes = [annotation.nodeId];
                const answerNodes = [];
                let reference = '';
                let information = '';

                if (annotation.selectedNodes) {
                    _forEach(annotation.selectedNodes, (nodeId) => {
                        if (questionNodes.indexOf(nodeId) === -1) {
                            questionNodes.push(nodeId);
                        }
                    });
                }

                for (const key in annotations) {
                    if (
                        annotations[key].annotationType === 'ANSWER' &&
                        annotations[key].parentNodeId === annotation.nodeId &&
                        annotation.inTable
                    ) {
                        answerNodes.push(key);
                    } else if (annotations[key].parentNodeId === annotation.nodeId) {
                        if (annotations[key].annotationType === 'REFERENCE') {
                            reference = getElementById(key).innerText.trim();
                        }
                        if (annotations[key].annotationType === 'INFORMATION') {
                            information = getElementById(key).innerText.trim();
                        }
                    }
                }

                const question = _map(questionNodes.sort(sortNodes), (nodeId: string) => {
                    return getElementById(nodeId).innerText.trim();
                }).join('/n');

                const richTextAnswer = _map(answerNodes.sort(sortNodes), (nodeId: string) => {
                    return cleanNode(getElementById(nodeId).innerHTML.trim());
                }).join('<br />');

                const plainText = getText(richTextAnswer).trim();

                const validAnswer = plainText !== 'ANSWER';

                if (question) {
                    questions.push({
                        richTextAnswer: validAnswer ? richTextAnswer : '',
                        answer: validAnswer ? plainText : '',
                        question,
                        reference,
                        information,
                        nodeId: key,
                    });
                }
            }
        }

        return questions;
    };

    const undoUserChanges = () => {
        const { configuration, isLinked, annotations } = selectedFile.document;

        updateAnnotations({
            change: 'INITIAL',
            annotation: annotations || {},
        });
        setDocumentIncluded(isLinked);

        if (configuration) {
            setDocumentTableFillEnabled(configuration.documentTableFillEnabled);
            setTableFillEnabled(configuration.tableFillEnabled);
        } else {
            setDocumentTableFillEnabled(true);
            setTableFillEnabled(true);
        }

        undoChanges();
    };

    const getCurrentConfiguration = () => {
        return {
            documentTableFillEnabled,
            tableFillEnabled,
        };
    };

    const getOriginalConfiguration = () => {
        if (!selectedFile.document.configuration) {
            return {
                documentTableFillEnabled: true,
                tableFillEnabled: true,
            };
        }

        const { documentTableFillEnabled, tableFillEnabled } = selectedFile.document.configuration;

        return {
            documentTableFillEnabled,
            tableFillEnabled,
        };
    };

    const checkForChanges = () => {
        if (!selectedFile) {
            return;
        }

        // Include Document Checkbox
        const inclusionChanged = !_isEqual(selectedFile.document.isLinked, documentIncluded);

        // Include Configuration Changes
        const configurationChanged = !_isEqual(getOriginalConfiguration(), getCurrentConfiguration());

        // Include Configuration Changes
        const originalAnnotations = selectedFile.document.annotations || {};
        const annotationChanged = !_isEqual(originalAnnotations, annotations);

        // All Changes
        const hasChanges = inclusionChanged || configurationChanged || annotationChanged;

        setHasChanges(hasChanges);
        if (setUnsavedChanges) {
            setUnsavedChanges(hasChanges);
        }
    };

    const getParentElement = (element) => {
        if (element) {
            return element.parentElement;
        }
    };

    const getElementById = (elementId: string) => {
        return documentRef.current.querySelector(`#${elementId}`);
    };

    const getCellId = (elementId: string) => {
        if (!elementId) {
            return;
        }

        const selectedNode = getElementById(elementId);

        const maxDepth = 6;

        let parentElement = null;
        let childElement = selectedNode;

        for (let i = 0; i < maxDepth; i++) {
            parentElement = getParentElement(childElement);

            if (parentElement) {
                if (parentElement.tagName === 'TD' || parentElement.tagName === 'TH') {
                    return parentElement.id;
                }
                childElement = parentElement;
            } else {
                return childElement;
            }
        }

        return null;
    };

    const getParentTableId = (elementId: string) => {
        if (!elementId) {
            return;
        }

        const selectedNode = getElementById(elementId);

        const maxDepth = 6;

        let parentElement = null;
        let childElement = selectedNode;

        for (let i = 0; i < maxDepth; i++) {
            parentElement = getParentElement(childElement);

            if (parentElement.tagName === 'TABLE') {
                return parentElement.id;
            }
            childElement = parentElement;
        }

        return null;
    };

    const getTaggableElement = (element: HTMLElement) => {
        return getElementById(getTaggableId(element));
    };

    const getTaggableId = (element: HTMLElement) => {
        if (!element) {
            return;
        }

        const maxDepth = 10;
        let node = element;

        for (let i = 0; i < maxDepth; i++) {
            if (node.id) {
                if (isValidTag(node.tagName)) {
                    return node.id;
                }
            }
            node = getParentElement(node);
        }

        return null;
    };

    const getRowIndexForRowInTable = (cellId: string) => {
        const tableId = getParentTableId(cellId);
        const table = getElementById(tableId);

        if (table?.rows?.length) {
            for (let i = 0; i < table.rows.length; i++) {
                const tableRow = table.rows[i];

                for (let j = 0; j < tableRow.cells.length; j++) {
                    if (tableRow.cells[j].id === cellId) {
                        return {
                            rowIndex: i,
                            cellIndex: j,
                        };
                    }
                }
            }
        }
    };

    const getTablesAfterTable = (tableElementId: string) => {
        const allTables = documentRef.current.getElementsByTagName('table');

        const tablesAfterTable = [];
        let startCapturing = false;

        for (let i = 0; i < allTables.length; i++) {
            const table = allTables[i];

            if (table.id === tableElementId) {
                startCapturing = true;
            } else if (startCapturing) {
                tablesAfterTable.push(table);
            }
        }

        return tablesAfterTable;
    };

    const getSimilarTables = (tableElementId: string, likelyHeaderRow: number) => {
        const followingTables = getTablesAfterTable(tableElementId);

        const sourceTable = getElementById(tableElementId);
        if (sourceTable.rows.length === 0) {
            return [];
        }

        const sourceRow = sourceTable.rows[likelyHeaderRow];

        if (sourceRow) {
            const cellCount = sourceRow.cells.length;
            const cellContent = [];

            for (let i = 0; i < sourceRow.cells.length; i++) {
                const cell = sourceRow.cells[i];
                cellContent.push(cell.innerText);
            }

            const similarTables = [];

            for (let i = 0; i < followingTables.length; i++) {
                const table = followingTables[i];

                if (table.rows.length > 0) {
                    const comparisonRow = table.rows[likelyHeaderRow];

                    if (comparisonRow?.cells?.length === cellCount) {
                        let cellsMatch = true;

                        for (let i = 0; i < comparisonRow.cells.length; i++) {
                            const cell = comparisonRow.cells[i];
                            if (cell.innerText !== cellContent[i]) {
                                cellsMatch = false;
                                break;
                            }
                        }

                        if (cellsMatch) {
                            similarTables.push(table);
                        }
                    }
                }
            }

            return similarTables;
        }
    };

    const getTableData = (elementId: string, verifyQuestionExists = false) => {
        if (!elementId) {
            return;
        }
        const selectedNode = getElementById(elementId);

        const maxDepth = 6;

        let inspectionElement = selectedNode;

        let inTableRow = false;
        let ownCellId = null;
        let ownRowId = null;

        for (let i = 0; i < maxDepth; i++) {
            if (inspectionElement.tagName === 'TD' || inspectionElement.tagName === 'TH') {
                inTableRow = true;
                ownCellId = inspectionElement.id;
            }

            if (inspectionElement.tagName === 'TR') {
                inTableRow = true;
                ownRowId = inspectionElement.id;
                break;
            }

            inspectionElement = getParentElement(inspectionElement);
        }

        if (!inTableRow || !tableFillEnabled) {
            return false;
        }

        if (inTableRow && ownRowId) {
            // Find all the subsequent rows to complete also
            let cellIndex = null;

            const tableRow = getElementById(ownRowId);

            for (let i = 0; i < tableRow.cells.length; i++) {
                const cell = tableRow.cells[i];
                if (cell.id === ownCellId) {
                    cellIndex = i;
                }
            }

            const tableRowParent = getParentElement(tableRow);
            let seenSelectedRow = false;
            let selectedRowCellCount = 0;

            const validCellIds = [];

            for (let i = 0; i < tableRowParent.rows.length; i++) {
                const row = tableRowParent.rows[i];
                if (row.id === ownRowId) {
                    seenSelectedRow = true;
                    selectedRowCellCount = row.cells.length;
                }

                if (seenSelectedRow && row.cells[cellIndex] && selectedRowCellCount === row.cells.length) {
                    let addCell = !verifyQuestionExists;

                    if (verifyQuestionExists) {
                        const matchingQuestion = _find(annotations, {
                            annotationType: 'QUESTION',
                            rowNodeId: row.id,
                        });

                        addCell = Boolean(matchingQuestion);
                    }

                    if (addCell) {
                        validCellIds.push({
                            id: row.cells[cellIndex].id,
                            rowNodeId: row.id,
                        });
                    }
                }
            }

            return validCellIds;
        }
    };

    const getCandidateCellId = (tableId: string, rowIndex: number, cellIndex: number) => {
        try {
            const table = getElementById(tableId);
            return table.rows[rowIndex].cells[cellIndex].id;
        } catch (err) {
            // Do nothing
        }
    };

    const addQuestionsRecursively = (nodeId: string, recursivelyFill = false) => {
        const tableData = getTableData(nodeId);

        if (!tableData) {
            setTracking(nodeId);
            return addQuestion(nodeId, Boolean(getParentTableId(nodeId)));
        }

        tableData.forEach(({ id, rowNodeId }, index) => {
            if (index === 0) {
                setTracking(id);
                setHighlight({
                    nodeId: id,
                    selectedNodes: [], // TODO - check how this works
                });
            }

            if (getElementById(id).innerText) {
                addQuestion(id, true, rowNodeId);
            }
        });

        if (documentTableFillEnabled && recursivelyFill) {
            const tableId = getParentTableId(nodeId);
            const { cellIndex, rowIndex } = getRowIndexForRowInTable(tableData[0].id);

            const rowOffset = Math.max(0, rowIndex - 1);
            const similarTables = getSimilarTables(tableId, rowOffset);

            if (similarTables) {
                similarTables.forEach((table) => {
                    const candidateCellId = getCandidateCellId(table.id, rowIndex, cellIndex);
                    addQuestionsRecursively(candidateCellId);
                });
            }
        }
    };

    const addQuestions = (addOneOnly = false) => {
        try {
            const { nodeId, selectedNodes } = highlight;

            // selectedNodes.length === 1 &&
            if (addOneOnly === false) {
                addQuestionsRecursively(nodeId, true);
            } else {
                setTracking(nodeId);
                addQuestion(nodeId, Boolean(getParentTableId(nodeId)), null, selectedNodes);
            }
        } catch (err) {
            // Do Nothing
            setTracking(null);
        }
    };

    const addQuestion = (nodeId, inTable = false, rowNodeId = null, selectedNodes = []) => {
        updateAnnotations({
            change: 'ADD_QUESTION',
            annotation: {
                nodeId,
                annotationType: 'QUESTION',
                inTable,
                rowNodeId,
                selectedNodes,
            },
        });
    };

    const removeLink = () => {
        const { nodeId } = selectedAnnotation;
        setTracking(null);

        updateAnnotations({
            change: 'REMOVE_LINK',
            annotation: {
                nodeId,
            },
        });
    };

    const removeQuestion = () => {
        const { nodeId } = selectedAnnotation;

        setTracking(null);

        updateAnnotations({
            change: 'REMOVE_QUESTION',
            annotation: {
                nodeId,
            },
        });
    };

    const addLink = (annotationType: string, nodeId: string, parentNodeId: string) => {
        const inTable = Boolean(getParentTableId(nodeId));
        const element = getElementById(nodeId);

        if (!element) {
            return;
        }

        const textContent = element.innerText.trim();
        const hasImage = element.innerHTML.indexOf('img') !== -1;

        const hasContent = Boolean(textContent || hasImage);

        updateAnnotations({
            change: 'ADD_LINK',
            annotation: {
                annotationType,
                nodeId,
                parentNodeId,
                inTable,
                hasContent,
            },
        });
    };

    const addLinksRecursively = (annotationType: string, nodeId: string, recursivelyFill = false) => {
        const tableData = getTableData(nodeId, true);

        if (!selectedAnnotation || !selectedAnnotation.nodeId) {
            return;
        }
        if (!tableData || tableData.length === 0) {
            return addLink(annotationType, nodeId, selectedAnnotation.nodeId);
        }

        let continueFill = documentTableFillEnabled && recursivelyFill;

        tableData.forEach(({ id, rowNodeId }) => {
            const matchingQuestion = _find(annotations, {
                annotationType: 'QUESTION',
                rowNodeId,
            });

            // Checking the ID's is important - BASE-1809
            if (matchingQuestion && id !== matchingQuestion.nodeId) {
                // Matching question in the same row
                addLink(annotationType, id, matchingQuestion.nodeId);
            } else {
                // Check if cell already contains a Question and remove that
                const cellId = getCellId(nodeId);

                const existingAnnotation = _find(annotations, {
                    nodeId: cellId,
                });

                if (existingAnnotation) {
                    addLink(annotationType, cellId, selectedAnnotation.nodeId);
                } else {
                    addLink(annotationType, nodeId, selectedAnnotation.nodeId);
                }

                // Different row (going vertical. Don't autocomplete)
                continueFill = false;
            }
        });

        if (continueFill && tableData.length > 0) {
            const tableId = getParentTableId(nodeId);
            const { cellIndex, rowIndex } = getRowIndexForRowInTable(tableData[0]?.id);

            const rowOffset = Math.max(0, rowIndex - 1);
            const similarTables = getSimilarTables(tableId, rowOffset);

            if (similarTables) {
                similarTables.forEach((table) => {
                    const candidateCellId = getCandidateCellId(table.id, rowIndex, cellIndex);
                    addLinksRecursively(annotationType, candidateCellId);
                });
            }
        }
    };

    const addLinks = (annotationType: string, nodeId: string) => {
        try {
            addLinksRecursively(annotationType, nodeId, true);
        } catch (err) {
            // Do Nothing
            console.log('Error', err);
        }
    };

    const linkAnswer = () => {
        const questionNodeId = isTracking;

        if (highlightedNodes) {
            highlightedNodes.forEach((node) => {
                addLink('ANSWER', node, questionNodeId);
            });
        }
    };

    const createLinkElement = (annotationType: string, textValue: string) => {
        const colours = {
            COMPLIANCE: 'bg-dark-golden',
            ANSWER: 'bg-success-dark',
        };

        const newElement = document.createElement('span');
        const classes = ['pearler_generated_node', colours[annotationType], 'rounded-md', 'text-white', 'px-8', 'py-4'];
        newElement.appendChild(document.createTextNode(textValue));
        newElement.classList.add(...classes);

        return newElement;
    };

    const refreshAnnotationRendering = () => {
        if (!documentRef.current) {
            return;
        }

        const baseClasses = ['pearler_generated_classes', 'rounded-md', 'text-white', 'px-8', 'py-4'];

        const questionClasses = ['bg-heading-blue'];
        const informationClasses = ['bg-blue-500'];
        const referenceClasses = ['bg-gray-600'];
        const answerClasses = ['bg-success-dark'];
        const disabledClasses = ['grayscale'];

        const temporaryNodes = documentRef.current.querySelectorAll('.pearler_generated_node');

        if (temporaryNodes.length > 0) {
            for (const node of temporaryNodes) {
                node.remove();
            }
        }

        const classes = [
            ...baseClasses,
            ...questionClasses,
            ...informationClasses,
            ...referenceClasses,
            ...answerClasses,
            ...disabledClasses,
            'being-hovered',
            'opacity-50',
        ];

        classes.forEach((className) => {
            const nodes = documentRef.current.querySelectorAll(`.${className}`);

            if (nodes.length > 0) {
                for (const node of nodes) {
                    node.classList.remove(...classes);
                }
            }
        });

        function applyAnnotation(annotation) {
            const element = getElementById(annotation.nodeId);

            const isAnswer = annotation.annotationType === 'ANSWER';
            const isCompliance = annotation.annotationType === 'COMPLIANCE';

            const newClasses = [];

            if (!annotation.isCompleted) {
                newClasses.push('opacity-50');
            }

            let includeBaseClasses = true;

            if (annotation.annotationType === 'QUESTION') {
                newClasses.push(questionClasses);
            } else if (annotation.annotationType === 'REFERENCE') {
                newClasses.push(referenceClasses);
            } else if (annotation.annotationType === 'INFORMATION') {
                newClasses.push(informationClasses);
            } else if (isAnswer || isCompliance) {
                if (mode === 'LIBRARY') {
                    if (isAnswer) {
                        newClasses.push(answerClasses);
                    }

                    if (!annotation.hasContent) {
                        newClasses.push(disabledClasses);
                        includeBaseClasses = false;
                        element.appendChild(createLinkElement(annotation.annotationType, dontImportMessage));
                    }
                } else {
                    if (isAnswer && annotation.inTable && annotation.hasContent) {
                        newClasses.push(answerClasses);
                    }
                    if (!annotation.hasContent || !annotation.inTable || isCompliance) {
                        element.appendChild(createLinkElement(annotation.annotationType, annotation.annotationType));
                        includeBaseClasses = false;
                    }
                }
            }

            if (includeBaseClasses) {
                newClasses.push(baseClasses);
            } else {
                newClasses.push('pearler_generated_classes');
            }

            element.classList.add(..._flatten(newClasses));
        }

        _forEach(annotations, (annotation) => {
            applyAnnotation(annotation);

            if (annotation.selectedNodes) {
                _forEach(annotation.selectedNodes, (annotationId) => {
                    if (annotationId !== annotation.nodeId) {
                        applyAnnotation({
                            ...annotation,
                            nodeId: annotationId,
                        });
                    }
                });
            }
        });
    };

    const sortNodes = (a: string, b: string) => {
        const aPosition = a.replace('pearler-id-', '');
        const bPosition = b.replace('pearler-id-', '');

        return parseInt(aPosition) - parseInt(bPosition);
    };

    const updateParent = () => {
        if (!annotationsUpdated) {
            return;
        }

        annotationsUpdated(getQuestionsAndAnswersFromAnnotations());
    };

    function getTextNodesBetween(selection) {
        if (!selection) {
            return;
        }
        const range = selection.getRangeAt(0),
            rootNode = range.commonAncestorContainer,
            startNode = range.startContainer,
            endNode = range.endContainer,
            textNodes = [];
        let pastStartNode = false,
            reachedEndNode = false;

        function getTextNodes(node) {
            const val = node.nodeValue;
            if (node == startNode && node == endNode && node !== rootNode) {
                if (val) textNodes.push(node);
                pastStartNode = reachedEndNode = true;
            } else if (node == startNode) {
                if (val) textNodes.push(node);
                pastStartNode = true;
            } else if (node == endNode) {
                if (val) textNodes.push(node);
                reachedEndNode = true;
            } else if (node.nodeType == 3) {
                if (val && pastStartNode && !reachedEndNode && !/^\s*$/.test(val)) {
                    textNodes.push(node);
                }
            }
            for (let i = 0, len = node.childNodes.length; !reachedEndNode && i < len; ++i) {
                getTextNodes(node.childNodes[i]);
            }
        }
        getTextNodes(rootNode);
        return textNodes;
    }

    const getSelectedNodes = () => {
        const selection = window.getSelection();

        const textNodes = getTextNodesBetween(selection);

        const nodes = _map(textNodes, (textNode) => {
            return getTaggableElement(textNode.parentNode);
        });

        return _filter(nodes, _identity);
    };

    useEffect(updateParent, [annotations]);

    useEffect(refreshAnnotationRendering, [annotations]);

    useEffect(() => {
        checkForChanges();
    }, [annotations, documentIncluded, tableFillEnabled, documentTableFillEnabled]);

    useEffect(() => {
        if (!selectedFile) {
            return;
        }
        const { configuration, isLinked, annotations } = selectedFile?.document;

        updateAnnotations({
            change: 'INITIAL',
            annotation: annotations || {},
        });
        setDocumentIncluded(isLinked);

        if (configuration) {
            setDocumentTableFillEnabled(configuration.documentTableFillEnabled);
            setTableFillEnabled(configuration.tableFillEnabled);
        } else {
            setDocumentTableFillEnabled(true);
            setTableFillEnabled(true);
        }
    }, [selectedFile]);

    const attributeLabelClasses = 'small-1-med mb-5 text-black-100 font-semibold';
    const attributeValueClasses = 'body-2-reg text-black-80';

    const currentlyConverting =
        originalHtml === 'Converting Document...' || selectedFile?.document?.originalHtml === 'Converting Document...';

    const hasAnnotations = Object.keys(annotations).length > 0;

    // console.log(annotations);

    // console.log('highlight?.nodeId', highlight?.nodeId);
    // console.log('getParentTableId(highlight?.nodeId)', getParentTableId(highlight?.nodeId));

    return (
        <>
            <div className="">
                <h3 className="my-4 text-12 font-bold uppercase">Document Configuration</h3>
                <div className="grid grid-cols-5 gap-16 rounded-lg border border-gray-100 bg-gray-50 px-8 py-16">
                    {mode === 'PROJECT' && (
                        <>
                            <div className="col-span-3">
                                <p className={attributeLabelClasses}>File Name</p>
                                <p className={attributeValueClasses}>{selectedFile.fileName} </p>
                            </div>
                            <div className="col-span-2">
                                <ButtonBar>
                                    <Button
                                        onClick={saveChanges}
                                        disabled={!hasChanges || isSaving}
                                        loading={isSaving}
                                        label="Save Changes"
                                    />
                                    {hasChanges && (
                                        <Button
                                            onClick={undoUserChanges}
                                            buttonType="SECONDARY"
                                            disabled={isSaving}
                                            label="Discard Changes"
                                        />
                                    )}
                                </ButtonBar>
                            </div>
                            <div>
                                <p className={attributeLabelClasses}>Contains Questions</p>
                                <p className={attributeLabelClasses}>
                                    <input
                                        id="importer-document-included-checkbox"
                                        type="checkbox"
                                        className="mr-4 mt-4"
                                        checked={documentIncluded}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            const selected = event.target.checked;
                                            setDocumentIncluded(Boolean(selected));
                                        }}
                                    />
                                    <label
                                        htmlFor="importer-document-included-checkbox"
                                        className={attributeValueClasses}
                                    >
                                        Include File
                                    </label>
                                </p>
                            </div>
                        </>
                    )}
                    <div>
                        <p className={attributeLabelClasses}>Import Auto Complete</p>
                        <p className={attributeLabelClasses}>
                            <input
                                id="importer-document-table-autocomplete-checkbox"
                                type="checkbox"
                                className="mr-4 mt-4"
                                disabled={!tableFillEnabled}
                                checked={documentTableFillEnabled}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const selected = event.target.checked;
                                    setDocumentTableFillEnabled(Boolean(selected));
                                }}
                            />
                            <label
                                htmlFor="importer-document-table-autocomplete-checkbox"
                                className={`${attributeValueClasses} ${!tableFillEnabled ? 'opacity-50' : ''}`}
                            >
                                Similar tables in Document
                            </label>
                        </p>
                    </div>
                    <div className="col-span-3">
                        <p className={attributeLabelClasses}>Instructions</p>
                        <p className={attributeValueClasses}>
                            <ul className="list-disc">
                                <li>
                                    Start by highlighting the question text and click mark as a question in the pop-up
                                </li>
                                <li>
                                    Drag and drop the (A)nswer
                                    {mode === 'PROJECT' && `, (C)ompliance, (I)nformation or (R)eference`} to the
                                    location that corresponds to that question, or highlight the Answer text and click
                                    'Link Answer'
                                </li>
                                <li>
                                    <a
                                        className="underline text-blue-500"
                                        target="_blank"
                                        href="https://www.pearler.ai/guides/import-word-documents/"
                                    >
                                        View our support guide and video
                                    </a>
                                </li>
                            </ul>
                        </p>
                    </div>
                    {mode === 'LIBRARY' && (
                        <div>
                            <ButtonBar>
                                <Button
                                    buttonType="SECONDARY"
                                    label="Discard Selections"
                                    disabled={!hasAnnotations}
                                    onClick={() => {
                                        if (window.confirm('Are you sure you want to discard all of your changes?')) {
                                            updateAnnotations({
                                                change: 'RESET',
                                                annotation: {},
                                            });
                                        }
                                    }}
                                />
                            </ButtonBar>
                        </div>
                    )}
                </div>
            </div>
            <div
                className={`relative my-16 block rounded-md bg-cream p-16 ${
                    documentIncluded ? 'overflow-auto' : 'cursor-not-allowed overflow-hidden opacity-50'
                }`}
                style={{ height: 'calc(100% - 165px)' }}
                ref={scrollRef}
                onMouseUp={(event) => {
                    if (currentlyConverting) {
                        return;
                    }

                    const { top, left } = scrollRef.current.getBoundingClientRect();

                    try {
                        const selection = window.getSelection();
                        const nodes = getSelectedNodes();

                        const selectedNodes = _map(nodes, (node) => {
                            if (node && node.id) {
                                return node.id;
                            }
                        });

                        setHighlightedNodes(selectedNodes);

                        const { width } = selection.getRangeAt(0).getBoundingClientRect();
                        const fromId = getTaggableId(selection.anchorNode.parentElement);

                        let existingAnnotation = getSelectedAnnotation(fromId);

                        let highlightSet = false;

                        if (!existingAnnotation) {
                            const cellId = getCellId(fromId);

                            if (cellId) {
                                existingAnnotation = annotations[cellId];

                                if (existingAnnotation) {
                                    setHighlight({
                                        nodeId: cellId,
                                        selectedNodes,
                                    });
                                    highlightSet = true;
                                }
                            }
                        }

                        if (!highlightSet) {
                            setHighlight({
                                nodeId: fromId,
                                selectedNodes,
                            });
                        }

                        const text = selection.getRangeAt(0).toString();

                        if (existingAnnotation) {
                            setShowMenu(true);
                        } else if (text.trim() && width > 2) {
                            setShowMenu(true);
                        } else {
                            return setShowMenu(false);
                        }

                        setCoords({
                            x: Math.max(0, event.clientX - left + 10),
                            y: Math.max(0, event.clientY + scrollRef.current.scrollTop - top - 60),
                        });
                    } catch (err) {
                        console.log(err);
                    }
                }}
            >
                {currentlyConverting && (
                    <EmptyState
                        className="text-center"
                        title="Converting your Document"
                        subtitle="We're converting your PDF to a Word Document. This page will automatically update once it's finished."
                        transparent={true}
                    >
                        <LoadingSpinner className="mt-16" />
                        <ButtonBar className="mt-16" alignment="CENTRE">
                            <Button label="Refresh" onClick={fetchData} buttonType="TERTIARY" />
                        </ButtonBar>
                    </EmptyState>
                )}
                {!currentlyConverting && (
                    <RenderDocument
                        html={selectedFile?.document?.originalHtml || originalHtml}
                        ref={documentRef}
                        drop={(ev: DragEvent<HTMLDivElement>) => {
                            ev.preventDefault();
                            const linkType = ev.dataTransfer.getData('text');
                            const targetNodeId = getTaggableId(ev.target as HTMLElement);
                            addLinks(linkType, targetNodeId);
                        }}
                    />
                )}

                {!currentlyConverting && showMenu && (
                    <div
                        className="absolute flex flex-col items-center"
                        style={{
                            left: coords.x,
                            top: coords.y,
                        }}
                    >
                        <PopupMenu
                            selectedAnnotation={selectedAnnotation}
                            addQuestion={addQuestions}
                            removeQuestion={removeQuestion}
                            removeLink={removeLink}
                            mode={mode}
                            isTracking={isTracking}
                            linkAnswer={linkAnswer}
                            setTracking={setTracking}
                            selectionInTable={Boolean(getParentTableId(highlight?.nodeId))}
                        />
                    </div>
                )}
            </div>
        </>
    );
};
