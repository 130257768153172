import { LeftArrow } from 'assets/left-arrow.svg';
import { RightArrow } from 'assets/right-arrow.svg';
import _findIndex from 'lodash/findIndex';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Question } from 'types';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { ProjectSharedProps } from '../project.view';
export const QuestionPagination = ({
    questionId,
    projectId,
    filteredQuestionList,
    filteredQuestionCount,
    showUnsavedChangesModal,
}: ProjectSharedProps): ReactElement => {
    const navigate = useNavigate();

    let nextQuestion: Question, previousQuestion: Question;
    let nextQuestionId: string, previousQuestionId: string;

    const currentQuestionIndex = _findIndex(filteredQuestionList, {
        questionId: questionId,
    });

    if (filteredQuestionList.length > currentQuestionIndex + 1) {
        nextQuestion = filteredQuestionList[currentQuestionIndex + 1];
        nextQuestionId = nextQuestion.questionId;
    }

    if (currentQuestionIndex > 0) {
        previousQuestion = filteredQuestionList[currentQuestionIndex - 1];
        previousQuestionId = previousQuestion.questionId;
    }

    const currentQuestionNumber = currentQuestionIndex + 1;

    return (
        <>
            <div className="mx-auto">
                {currentQuestionNumber === 1 ? (
                    <LeftArrow strokeColor={'#DBDDE0'} />
                ) : (
                    <a
                        onClick={() => {
                            if (showUnsavedChangesModal()) {
                                navigate(
                                    createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                                        questionId: previousQuestionId,
                                        projectId: projectId,
                                    }),
                                );
                            }
                        }}
                    >
                        <LeftArrow strokeColor={'#A0A4A8'} className="cursor-pointer" />
                    </a>
                )}
                <div className="tracking-0.2 mx-12 inline select-none text-center text-14 font-medium leading-20 text-light-gray">
                    {`${currentQuestionNumber} of ${filteredQuestionCount}`}
                </div>
                {currentQuestionNumber === filteredQuestionCount ? (
                    <RightArrow strokeColor={'#DBDDE0'} />
                ) : (
                    <a
                        onClick={() => {
                            if (showUnsavedChangesModal()) {
                                navigate(
                                    createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                                        questionId: nextQuestionId,
                                        projectId: projectId,
                                    }),
                                );
                            }
                        }}
                    >
                        <RightArrow strokeColor={'#A0A4A8'} className="cursor-pointer" />
                    </a>
                )}
            </div>
        </>
    );
};
