import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormSelectField } from 'components/duex/FormSelectField';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { isValidStringOfLength } from 'components/validation/string';
import React, { ReactElement, useState } from 'react';
import Division from 'types/division.type';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const AddDivisionModal = ({
    closeModal,
    divisions,
}: {
    closeModal: () => void;
    divisions: Division[];
}): ReactElement => {
    const [divisionName, setDivisionName] = useState('');
    const [parentDivision, setParentDivision] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    const save = async () => {
        try {
            if (getSaveReadiness()) {
                const started = new Date();
                setIsSaving(true);

                const url = ENDPOINTS.getUrl(CONSTANTS.CREATE_DIVISION);
                const result = await API.post(url, {
                    divisionName,
                    parentDivisionId: parentDivision,
                });

                logEvent(`ADD_DIVISION`, started);

                if (result.data) {
                    setIsSaving(false);
                    closeModal();
                }
            }
        } catch (err) {
            setIsSaving(false);
            showError('There was an error creating your division', err);
        }
    };

    const getSaveReadiness = () => {
        return isValidStringOfLength(divisionName, 3);
    };

    return (
        <Modal
            width="w-400"
            closeModal={closeModal}
            title="Add Division"
            closeSafely={() => {
                return !divisionName;
            }}
        >
            <>
                <fieldset className="flex flex-col gap-16">
                    <FormTextField
                        placeholder="Enter your division name here."
                        label="Division Name"
                        errorMessage="Please provide a Division Name"
                        validator={(currentValue) => {
                            return isValidStringOfLength(currentValue, 3);
                        }}
                        valueChanged={setDivisionName}
                        required={true}
                        spellCheck={true}
                        maxLength={256}
                    />
                    {divisions.length > 0 && (
                        <FormSelectField
                            required={false}
                            defaultValue={parentDivision}
                            label="Parent Division"
                            valueChanged={setParentDivision}
                            placeholder="Select Parent Division"
                            errorMessage="Please select a division"
                            items={divisions}
                            optionLabel="divisionName"
                            optionValue="divisionId"
                        />
                    )}
                </fieldset>
                <ButtonBar className="mt-24">
                    <Button label="Cancel" onClick={closeModal} buttonType="SECONDARY" disabled={isSaving} />
                    <Button
                        label="Add Division"
                        onClick={save}
                        disabled={!getSaveReadiness() || isSaving}
                        loading={isSaving}
                    />
                </ButtonBar>
            </>
        </Modal>
    );
};
