import { DropDownArrow } from 'assets/drop-close-arrow.svg';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

function useOutsideAlerter(ref, toggleDropDownState) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export const Select = ({
    classesForInput,
    onFocusChange,
    value,
    children,
    showArrow,
    classesForWrapper,
    modalId,
    onClickCallBack,
}: {
    classesForInput?: string;
    onFocusChange?: (bool) => void;
    onClickCallBack?: (isOpen?: boolean) => void;
    value: string;
    children: ReactElement;
    showArrow: boolean;
    classesForWrapper?: string;
    modalId?: string;
}): ReactElement => {
    const [inputFocused, toggleInputFocus] = useState(false);
    const [isUpwards, setDropDownPosition] = useState(true);
    const [isShrinked, shrinkDropDown] = useState(true);

    const wrapperRef = useRef(null);
    const listRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleInputFocus);

    const shouldRenderUpwards = (modalId?: string) => {
        let bottomOfDesiredRef;
        if (modalId) {
            const desiredRef = document.getElementById(modalId);
            if (desiredRef) bottomOfDesiredRef = desiredRef.getBoundingClientRect().bottom;
            else bottomOfDesiredRef = window.innerHeight + window.scrollY;
        } else {
            bottomOfDesiredRef = window.innerHeight + window.scrollY;
        }
        const bottomOfList =
            listRef.current.getBoundingClientRect().top +
            listRef.current.firstElementChild.offsetHeight +
            window.scrollY;
        return bottomOfList >= bottomOfDesiredRef;
    };

    useEffect(() => {
        if (inputFocused) {
            const isUpwards = shouldRenderUpwards(modalId);
            setDropDownPosition(isUpwards);
            shrinkDropDown(false);
        } else {
            shrinkDropDown(true);
        }
        onClickCallBack && onClickCallBack(inputFocused);
    }, [inputFocused]);

    return (
        <div className="w-full text-14 leading-20" ref={wrapperRef}>
            <div
                data-cy="Answer_Library_Drop_Down"
                className={`flex cursor-pointer items-center justify-between ${classesForWrapper}`}
                tabIndex={-1}
                onClick={() => toggleInputFocus(!inputFocused)}
                onFocus={() => onFocusChange && onFocusChange(true)}
                onBlur={() => onFocusChange && onFocusChange(false)}
            >
                <p className={`${classesForInput} ${inputFocused ? 'text-primary-blue-100' : ''}`}>{value}</p>
                {showArrow && (
                    <p className={`ml-20 transition duration-200 ease-linear ${inputFocused ? 'rotate-180' : ''}`}>
                        <DropDownArrow color={` ${inputFocused ? '#2068E3' : 'currentColor'}`} />
                    </p>
                )}
            </div>
            <div
                onClick={() => toggleInputFocus(!inputFocused)}
                className={`relative z-10 ${
                    inputFocused ? 'opacity-1 animate-grow-down-dropdown-animation' : 'pointer-events-none opacity-0'
                }`}
            >
                <div
                    ref={listRef}
                    className={`absolute z-10 w-full ${isShrinked ? 'h-0 overflow-hidden' : ''}`}
                    style={{
                        top: `${isShrinked ? 'auto' : isUpwards ? 'auto' : '8px'}`,
                        bottom: `${isShrinked ? 'auto' : isUpwards ? `${wrapperRef.current.offsetHeight}px` : 'auto'}`,
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};
