import React, { ReactElement } from 'react';

export const LeftArrow = ({ strokeColor, className }: { strokeColor: string; className?: string }): ReactElement => {
    return (
        <svg
            className={`inline ${className}`}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="24" height="24" fill="none" />
            <path
                d="M17.8332 12H6.1665"
                stroke={strokeColor || '#DBDDE0'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.9998 17.8337L6.1665 12.0003L11.9998 6.16699"
                stroke={strokeColor || '#DBDDE0'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
