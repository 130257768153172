import React, { ReactElement } from 'react';

export const Inbox = (): ReactElement => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M36.6663 20H26.6663L23.333 25H16.6663L13.333 20H3.33301"
                stroke="#DBDDE0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.08301 8.5165L3.33301 19.9998V29.9998C3.33301 30.8839 3.6842 31.7317 4.30932 32.3569C4.93444 32.982 5.78229 33.3332 6.66634 33.3332H33.333C34.2171 33.3332 35.0649 32.982 35.69 32.3569C36.3152 31.7317 36.6663 30.8839 36.6663 29.9998V19.9998L30.9163 8.5165C30.6404 7.96115 30.215 7.49379 29.6879 7.16697C29.1609 6.84015 28.5531 6.66683 27.933 6.6665H12.0663C11.4462 6.66683 10.8384 6.84015 10.3114 7.16697C9.78438 7.49379 9.35897 7.96115 9.08301 8.5165V8.5165Z"
                stroke="#DBDDE0"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
