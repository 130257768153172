import React, { ReactElement } from 'react';
import Tooltip, { getTooltipId } from './Tooltip';

export interface ProgressSegment {
    percentage: number;
    colour: string;
}

export function ProgressMeter({
    segments,
    width,
    baseColor = 'bg-gray-100',
    description = '',
}: {
    segments: ProgressSegment[];
    width: number;
    baseColor?: string;
    description?: string;
}): ReactElement {
    const tooltipId = getTooltipId(`${description}`);

    return (
        <>
            <div
                className={`flex h-16 flex-row overflow-hidden rounded-md ${baseColor}`}
                data-tooltip-content={description}
                data-tooltip-id={tooltipId}
                style={{
                    width: `${width}px`,
                }}
            >
                {segments.map((segment, index) => {
                    return (
                        <div
                            key={index}
                            className={`${segment.colour}`}
                            style={{
                                width: `${segment.percentage * width}px`,
                            }}
                        ></div>
                    );
                })}
            </div>
            <Tooltip id={tooltipId} />
        </>
    );
}
