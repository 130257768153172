import { showError } from 'components/error-toast.component';
import { Select } from 'components/select.component';
import { useTeamContext } from 'hooks/useTeamContext';
import React, { ReactElement, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { ProjectSharedProps } from '../project.view';

interface DropdownProps extends ProjectSharedProps {
    showShareModal: () => void;
    hideShareModal: () => void;
    hideDropdown: () => void;
    resetQuestion: () => void;
    showEditQuestion: () => void;
}

export const DropDownOptions = ({
    markQuestionComplete,
    showEditQuestion,
    deleteQuestion,
    markNotAQuestion,
    resetQuestion,
    setShowRewriteAnswer,
    setShowGenerateAnswer,
    projectId,
    questionId,
    question,
    showShareModal,
    hideShareModal,
    hideDropdown,
    userSubscribedToQuestion,
    fetchSubscription,
}: DropdownProps): ReactElement => {
    const { settings } = useTeamContext();

    const getParams = () => {
        return {
            projectId,
            questionId,
        };
    };

    const removeSubscription = async () => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.QUESTION_REMOVE_SUBSCRIPTION, getParams());

            logEvent(`QUESTION_SUBSCRIPTION_REMOVE`, started, {
                projectId,
                questionId,
            });

            API.post(url, {}).then(fetchSubscription);
        } catch (err) {
            showError('There was an error removing your subscription', err);
        }
    };

    const createSubscription = async () => {
        try {
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.QUESTION_CREATE_SUBSCRIPTION, getParams());

            API.post(url, {}).then(fetchSubscription);

            logEvent(`QUESTION_SUBSCRIPTION_ADD`, started, {
                projectId,
                questionId,
            });
        } catch (err) {
            showError('There was an error adding your subscription', err);
        }
    };

    const menuSections = [
        {
            label: 'Question',
            items: [
                {
                    label: 'Edit Question',
                    display: true,
                    onClick: showEditQuestion,
                },
                {
                    label: 'Delete Question',
                    display: question.creationType === CONSTANTS.QUESTION_MANUAL_CREATE,
                    onClick: deleteQuestion,
                },
                {
                    label: 'Share Question',
                    display: true,
                    onClick: showShareModal,
                },
                {
                    label: 'Mark Completed',
                    display: true,
                    onClick: markQuestionComplete,
                },
                {
                    label: 'Not a Question',
                    display: question.creationType !== CONSTANTS.QUESTION_MANUAL_CREATE,
                    onClick: markNotAQuestion,
                },
            ],
        },
    ];

    if (settings.enableChatGPTIntegration) {
        menuSections.push({
            label: 'Answer',
            items: [
                {
                    label: 'Re-write answer',
                    display: true,
                    onClick: () => {
                        setShowRewriteAnswer(true);
                    },
                },
                {
                    label: 'Generate answer',
                    display: true,
                    onClick: () => {
                        setShowGenerateAnswer(true);
                    },
                },
            ],
        });
    }

    menuSections.push({
        label: 'Other Actions',
        items: [
            {
                label: 'Clear Response',
                display: true,
                onClick: resetQuestion,
            },
            {
                label: 'Subscribe to updates',
                display: !userSubscribedToQuestion,
                onClick: createSubscription,
            },
            {
                label: 'Unsubscribe from updates',
                display: userSubscribedToQuestion,
                onClick: removeSubscription,
            },
        ],
    });

    const [dropdownOpen, toggleDropDownState] = useState(false);

    return (
        <Select
            classesForWrapper={`border border-black-40 px-16 py-10 rounded-lg select-none button-med-14 ${
                dropdownOpen ? 'border-primary-blue-100' : ''
            }`}
            value="More"
            showArrow={true}
            onClickCallBack={(showDropdown) => {
                if (showDropdown) {
                    hideShareModal();
                }

                toggleDropDownState(showDropdown);
            }}
            onFocusChange={() => {
                hideDropdown();
            }}
        >
            <ul className=" relative z-2 w-200 select-none bg-white py-8 shadow">
                {menuSections.map((section) => {
                    return (
                        <div key={section.label}>
                            <li className="px-8 py-8 text-left text-12 uppercase text-gray-500">{section.label}</li>
                            {section.items
                                .filter(({ display }) => display)
                                .map((item) => {
                                    return (
                                        <li
                                            onClick={() => {
                                                item.onClick();
                                                hideDropdown();
                                            }}
                                            key={item.label.replace(' ', '')}
                                            className="tracking-0.1 block w-full px-8 py-10 text-left text-14 font-normal leading-20 text-light-black 
                             hover:bg-primary-blue-100-opace-4 hover:text-field-outline-blue focus:outline-none"
                                        >
                                            {item.label}
                                        </li>
                                    );
                                })}
                        </div>
                    );
                })}
            </ul>
        </Select>
    );
};
