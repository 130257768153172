import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { RichTextEditor } from 'components/duex/RichTextEditor';
import { showError } from 'components/error-toast.component';
import { isValidStringOfLength } from 'components/validation/string';
import { useTeamContext } from 'hooks/useTeamContext';
import _first from 'lodash/first';
import React, { ReactElement, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { generateTimestamp } from './replace-answer.modal';

export const AddAnswerModal = ({
    closeModal,
    switchToAnswerModal,
}: {
    closeModal: () => void;
    switchToAnswerModal: (answerId: string) => void;
}): ReactElement => {
    const { settings } = useTeamContext();

    const [answerHtml, setAnswerHtml] = useState('');
    const [question, setQuestion] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);
    const [suggestion, setSuggestion] = useState<{ answerRichText: string; answerText: string }>(null);

    const [forceUpdate, setForceUpdate] = useState(generateTimestamp());

    const generateAnswer = async () => {
        try {
            setIsGenerating(true);

            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.GENERATE_NEW_ANSWER);

            const results = await API.post(url, {
                questionText: question,
            });

            logEvent('ANSWER_LIBRARY_GENERATE_ANSWER', started);

            if (results && results.data) {
                setIsGenerating(false);
                setSuggestion(results.data);
            }
        } catch (err) {
            showError('There was an error generating a new answer', err);
            setIsGenerating(false);
            setSuggestion(null);
        }
    };

    const save = async () => {
        try {
            const started = new Date();
            setIsSaving(true);

            const helper = API;
            const url = ENDPOINTS.getUrl(CONSTANTS.SAVE_QUESTIONS_AND_ANSWERS);
            const results = await helper.post(url, {
                answers: [
                    {
                        question,
                        richTextAnswer: answerHtml,
                    },
                ],
            });

            logEvent(`ANSWER_LIBRARY_ADD_ANSWER`, started);

            if (results && results.data && results.data.answerIds) {
                setIsSaving(false);
                switchToAnswerModal(_first(results.data.answerIds));
            }
        } catch (err) {
            showError('There was an error adding a new answer', err);
            setIsSaving(false);
        }
    };

    const getSaveReadiness = () => {
        return isValidStringOfLength(question, 10) && isValidStringOfLength(answerHtml, 10);
    };

    const height = 'calc(100vh - 256px)';

    return (
        <Modal
            width="w-90-percent"
            closeModal={closeModal}
            title="Add Answer"
            closeSafely={() => {
                return !(question || answerHtml);
            }}
        >
            <div className="overflow-y-auto">
                <fieldset
                    className="grid gap-8 overflow-y-auto"
                    style={{ gridTemplateRows: '1fr 1fr', maxHeight: height }}
                >
                    <FormTextField
                        placeholder="Enter your question here. Having a good example question lets everyone know how to use this answer, and helps us to suggest it at the right times"
                        label="Question"
                        errorMessage="Please provide a question"
                        validator={(currentValue) => {
                            return isValidStringOfLength(currentValue, 10);
                        }}
                        valueChanged={setQuestion}
                        required={true}
                        inputType="resizing-textarea"
                        spellCheck={true}
                        maxLength={10000}
                        maxRows={15}
                    />
                    <RichTextEditor
                        dataCy="Answer"
                        forceUpdate={forceUpdate}
                        htmlText={answerHtml}
                        placeholder="Enter your answer here"
                        label="Answer"
                        errorMessage="Please provide an answer"
                        validator={(currentValue) => {
                            return isValidStringOfLength(currentValue, 10);
                        }}
                        valueChanged={(text: string, html: string) => {
                            setAnswerHtml(html);
                        }}
                        onBlur={() => {
                            setForceUpdate(generateTimestamp());
                        }}
                        required={true}
                    />
                    {suggestion && (
                        <div className="mt-16 rounded-md bg-blue-50 p-16">
                            <div className="mb-8 text-14 uppercase text-gray-500">AI Suggestion</div>
                            <div className="whitespace-pre-line break-words">{suggestion.answerText}</div>
                            <div className="mt-16 flex flex-row-reverse">
                                <Button
                                    buttonType="TERTIARY"
                                    label="Apply Answer"
                                    onClick={() => {
                                        setAnswerHtml(suggestion.answerRichText);
                                        setForceUpdate(generateTimestamp());
                                    }}
                                    disabled={isGenerating || isSaving}
                                    loading={isGenerating}
                                />
                            </div>
                        </div>
                    )}
                </fieldset>
                <div className="mt-16 grid grid-cols-2">
                    <ButtonBar alignment="LEFT">
                        {settings.enableChatGPTIntegration && (
                            <Button
                                buttonType="SECONDARY"
                                label="Generate Answer using AI"
                                onClick={generateAnswer}
                                disabled={isGenerating || isSaving || !isValidStringOfLength(question, 10)}
                                loading={isGenerating}
                            />
                        )}
                    </ButtonBar>
                    <ButtonBar>
                        <Button label="Cancel" onClick={closeModal} buttonType="SECONDARY" disabled={isSaving} />
                        <Button
                            label="Add Answer"
                            onClick={save}
                            disabled={!getSaveReadiness() || isSaving}
                            loading={isSaving}
                        />
                    </ButtonBar>
                </div>
            </div>
        </Modal>
    );
};
