import { showError } from 'components/error-toast.component';
import { GenericDictionary } from 'types/generic-dictionary';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ENDPOINTS } from './Endpoints';

export async function getData<T>({
    setLoading,
    url,
    urlParams,
    logParams,
    logEventName,
    errorMessage,
}: {
    setLoading: (newStatus: boolean) => void;
    url: string;
    urlParams?: GenericDictionary;
    logParams?: GenericDictionary;
    logEventName: string;
    errorMessage: string;
}): Promise<T> {
    try {
        const started = new Date();
        setLoading(true);

        logEvent(logEventName, started, {
            ...urlParams,
            ...logParams,
        });

        const result = await API.get(ENDPOINTS.getUrl(url, urlParams));
        setLoading(false);

        if (result && result.data) {
            return result.data;
        } else {
            throw new Error('Request failed');
        }
    } catch (err) {
        setLoading(false);
        showError(errorMessage, err);
    }
}

export async function setData<T>({
    setLoading,
    url,
    urlParams,
    logParams,
    body,
    logEventName,
    errorMessage,
}: {
    setLoading: (newStatus: boolean) => void;
    url: string;
    urlParams?: GenericDictionary;
    logParams?: GenericDictionary;
    body: GenericDictionary;
    logEventName: string;
    errorMessage: string;
}): Promise<T> {
    try {
        const started = new Date();
        setLoading(true);

        logEvent(logEventName, started, {
            ...urlParams,
            ...logParams,
        });

        const result = await API.post(ENDPOINTS.getUrl(url, urlParams), body);

        if (result && result.data) {
            return result.data;
        } else {
            throw new Error('Request failed');
        }
    } catch (err) {
        showError(errorMessage, err);
    }
}
