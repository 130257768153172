import { LoaderFile } from 'assets/loader-file.svg';
import React, { ReactElement } from 'react';

interface LoaderProps {
    classes?: string;
    strokeColor?: string;
}

export const Loader = ({ classes, strokeColor }: LoaderProps): ReactElement => {
    return (
        <div className={`${classes ? classes : 'flex h-full items-center justify-center'}`}>
            <LoaderFile strokeColor={strokeColor} />
        </div>
    );
};
