import { LibraryCross } from 'assets/answer-library-cross.svg';
import { BackArrow } from 'assets/back-arrow.svg';
import { Search } from 'assets/magnify.svg';
import { ThreeDots } from 'assets/three-dots.svg';
import { Menu } from 'components/duex/Menu';
import { MenuButton } from 'components/duex/MenuButton';
import { LIGHTBLUE, Pill } from 'components/pill.component';
import { AddQuestionModal } from 'modules/add-question/add-question-modal';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VariableSizeList as List } from 'react-window';
import { Question } from 'types';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { DueDate } from '../common/due-date.component';
import { Priority } from '../common/priority.component';
import { ProjectSharedProps } from '../project.view';
import { BulkAssignModal } from './bulk-assign-modal';
import { EmptyQuestionList } from './empty-question-list.component';
import FilterResultCount from './filter/filter-result-count.component';
import Filter from './filter/filter.component';
import { QuestionNoResults } from './question-no-results.component';
import { QuestionRow } from './question.component';
import { QuickViewQuestion } from './quick-view-question.view';

const getfontStyle = (company: string) => {
    const length = company && company.length;
    if (length < 9) {
        return 'h1-large';
    } else if (length >= 9 && length < 15) {
        return 'h1-medium';
    } else {
        return 'h1-small';
    }
};

export const QuestionList = ({
    filteredQuestionList,
    projectId,
    project,
    questionId,
    questionCount,
    completedQuestionCount,
    setFilters,
    filters,
    searchFilter,
    clearFilter,
    setSearchFilter,
    filteredQuestionCount,
    showUnsavedChangesModal,
    patchQuestion,
    projectClosed,
    projectTeam,
    questionList,
}: ProjectSharedProps): ReactElement => {
    const navigate = useNavigate();

    const rowHeights = useRef({});
    const listRef = useRef<List>(null);

    const [screenHeight, changeScreenSize] = useState(window.innerHeight);

    const [showingModal, setShowingModal] = useState(false);
    const [showQuickView, setShowQuickView] = useState(false);
    const [showBulkAssign, setShowBulkAssign] = useState(false);
    const [quickViewQuestion, setQuickViewQuestion] = useState<{
        question?: Question;
        id?: string;
        position?: {
            top: number;
            height: number;
        };
    }>(null);

    const goToDashboard = () =>
        navigate(
            createUrl(ROUTES.PROJECT_DASHBOARD, {
                projectId: projectId,
            }),
        );

    useEffect(() => {
        window.addEventListener('resize', () => changeScreenSize(window.innerHeight));
        return () => window.removeEventListener('resize', () => changeScreenSize(window.innerHeight));
    }, []);

    const getRowHeight = (index: number) => rowHeights.current[index] || 30;

    const setRowHeight = (index: number, size: number) => {
        listRef.current.resetAfterIndex(0);
        rowHeights.current = { ...rowHeights.current, [index]: size };
    };

    const completion = `${((completedQuestionCount / questionCount) * 100).toFixed()}%`;

    const selectQuestion = (question: Question) => {
        const url = createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
            questionId: question.questionId,
            projectId: projectId,
        });

        if (showUnsavedChangesModal()) {
            navigate(url);
        }
        setShowQuickView(false);
    };

    const hoverQuestion = ({
        question,
        position,
    }: {
        question: Question;
        position: {
            top: number;
            height: number;
        };
    }) => {
        setQuickViewQuestion({
            question,
            position,
        });
        setShowQuickView(true);
    };

    const blurQuestion = () => {
        setShowQuickView(false);
    };

    return (
        <>
            <div
                className="relative border-r-2 border-solid border-faded-gray"
                onMouseLeave={() => {
                    setShowQuickView(false);
                }}
                style={{
                    height: `calc(100vh - ${projectClosed ? '32' : '0'}px)`,
                }}
            >
                <QuickViewQuestion quickViewQuestion={quickViewQuestion} showQuickView={showQuickView} />

                {showingModal && (
                    <AddQuestionModal
                        closeModal={() => {
                            setShowingModal(false);
                        }}
                        patchQuestion={patchQuestion}
                        projectId={projectId}
                    />
                )}
                {showBulkAssign && (
                    <BulkAssignModal
                        filteredQuestionList={filteredQuestionList}
                        projectId={projectId}
                        teamList={projectTeam}
                        closeModal={() => {
                            setShowBulkAssign(false);
                        }}
                    />
                )}
                <nav
                    className="grid max-h-full min-h-full bg-white"
                    style={{ gridTemplateRows: '72px 40px 55px 24px 1fr' }}
                >
                    <div
                        data-cy="Back_Button"
                        className="m-16 flex cursor-pointer items-center rounded-lg p-8 hover:bg-very-light-blue"
                        onClick={goToDashboard}
                    >
                        <BackArrow />
                        <h1
                            className={`h4-medium max-w-274 select-none truncate pl-8 text-heading-blue ${getfontStyle(
                                project.name,
                            )}`}
                            title={`${project.name}`}
                        >
                            {project.name}
                        </h1>
                    </div>
                    <div className="flex items-center justify-between px-16">
                        <Priority projectPriority={project.projectPriority} />
                        {questionCount === completedQuestionCount && (
                            <Pill colour={LIGHTBLUE} title="Completed" alt="All Questions Completed" />
                        )}
                        {questionCount !== completedQuestionCount && questionCount > 0 && (
                            <Pill colour={LIGHTBLUE} title={completion} alt={`${completion} of Questions Completed`} />
                        )}
                        <DueDate
                            projectPriority={project.projectPriority}
                            projectDueDate={project.dueOn}
                            projectStatus={project.projectStatus}
                        />
                    </div>
                    <div className="flex h-32 flex-row items-center gap-16 pl-16 pr-16">
                        <div className="relative w-full">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center stroke-current px-10 py-10 text-light-blue">
                                <Search strokeColor={'#CDDBF4'} />
                            </div>
                            <input
                                data-cy="QuestionListSearch"
                                autoComplete="off"
                                className={`h-32 w-full appearance-none rounded-md border-none bg-faded-gray px-32 text-14 leading-22 text-light-faded-blue placeholder-light-faded-blue transition duration-150 ease-in-out hover:border-light-faded-blue 
                                hover:outline-1 focus:text-field-outline-blue focus:outline-1 focus:outline-field-outline-blue`}
                                placeholder={'Search by question or reference'}
                                value={searchFilter}
                                maxLength={250}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setSearchFilter(event.target.value);
                                }}
                            />
                            {searchFilter.trim() && (
                                <div
                                    onClick={() => {
                                        setSearchFilter('');
                                    }}
                                    className={`absolute inset-y-0 right-24 top-4 select-none px-10 text-primary-blue-60 ${
                                        searchFilter ? 'opacity-1 cursor-pointer' : 'pointer-events-none opacity-0'
                                    }`}
                                    data-cy="ClearButton"
                                >
                                    <LibraryCross
                                        width="24"
                                        height="24"
                                        strokeColorCross={'#FFFFFF'}
                                        strokeColorCircle={'#CACCCF'}
                                    />
                                </div>
                            )}
                            <div className="absolute inset-y-0 right-0 my-10 mr-10 flex cursor-pointer items-center">
                                <Filter
                                    currentFilters={filters}
                                    applyFilters={setFilters}
                                    teamList={projectTeam}
                                    project={project}
                                    questionList={questionList}
                                />
                            </div>
                        </div>
                        <MenuButton
                            Icon={ThreeDots}
                            width="w-200"
                            menu={
                                <Menu
                                    items={[
                                        {
                                            label: 'Add question',
                                            testId: 'addQuestionButton',
                                            onClick: () => {
                                                setShowingModal(true);
                                            },
                                        },
                                        {
                                            label: `Assign ${filteredQuestionCount} questions`,
                                            onClick: () => {
                                                setShowBulkAssign(true);
                                            },
                                        },
                                    ]}
                                />
                            }
                        />
                    </div>
                    <div className="bg-faded-blue">
                        <FilterResultCount
                            data-cy="filterbutton"
                            onClickHandler={clearFilter}
                            filteredTotalQuestionCount={filteredQuestionCount}
                            totalQuestionCount={questionCount}
                            isFilterApplied={filters.length > 0}
                            isSearchApplied={searchFilter !== ''}
                        />
                    </div>
                    <div className="overflow-auto" data-cy="questionList">
                        {filteredQuestionList.length ? (
                            <div className="relative min-h-full overflow-y-hidden">
                                <List
                                    height={screenHeight - 191} // Don't love this, but the auto resizer causing issues
                                    itemCount={filteredQuestionList.length}
                                    width={354}
                                    ref={listRef}
                                    itemSize={getRowHeight}
                                    itemData={{
                                        onClick: selectQuestion,
                                        onHover: hoverQuestion,
                                        onBlur: blurQuestion,
                                        setRowHeight,
                                        questionList: filteredQuestionList,
                                        activeQuestion: questionId,
                                    }}
                                >
                                    {QuestionRow}
                                </List>
                            </div>
                        ) : !questionCount ? (
                            <EmptyQuestionList projectId={projectId} patchQuestion={patchQuestion} />
                        ) : (
                            <QuestionNoResults clearFilter={clearFilter} />
                        )}
                    </div>
                </nav>
            </div>
        </>
    );
};
