import { ChevronRight } from 'assets/chevron-right.svg';
import { NotificationSymbol } from 'assets/notifications-status-symbol.svg';
import { Avatar } from 'components/duex/Avatar';
import { GRAY, Pill } from 'components/pill.component';
import { Col, Row } from 'components/table';
import { useUserContext } from 'hooks/useUserContext';
import moment from 'moment';
import React, { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SummariseChanges } from './summarise-changes';

export const NotificationRow = ({
    notification,
    markNotificationAsRead,
    tooltipId,
}: {
    notification;
    markNotificationAsRead;
    tooltipId;
}): ReactElement => {
    const { getUser } = useUserContext();
    const navigate = useNavigate();

    const user = getUser(notification.causedByUserId);

    const hasDetails = notification.notificationData;

    const [showDetails, setShowDetails] = useState(false);

    return (
        <Row
            dataCy="Notification_Row"
            key={notification.notificationId}
            className="my-4 grid cursor-pointer gap-16 rounded-sm bg-white hover:bg-gray-50"
            style={{
                gridTemplateColumns: '32px 32px 200px auto 24px',
            }}
            onClickHandler={() => {
                markNotificationAsRead(notification.notificationId);
                navigate(notification.linkReferencePath);
            }}
        >
            <Col className="pl-16">
                <div
                    data-tooltip-content="Mark notification as read"
                    data-tooltip-id={tooltipId}
                    onClick={(ev) => {
                        ev.stopPropagation();
                        markNotificationAsRead(notification.notificationId);
                    }}
                >
                    <NotificationSymbol color={notification.readAt ? '#EEF3FB' : '#FF9100'} />
                </div>
            </Col>
            <Col>
                <Avatar size={32} userId={notification.causedByUserId} />
            </Col>
            <Col>
                <p className="h6-medium tracking-0.2 truncate text-black-100">{user.fullName}</p>
                <p className="small1-medium tracking-0.2 mt-4 text-light-gray">
                    {moment(notification.createdAt).fromNow()}
                </p>
            </Col>
            <Col>
                {notification.title}{' '}
                {notification.question.referenceNumber && (
                    <Pill colour={GRAY} title={notification.question.referenceNumber} />
                )}
                <div className="small1-medium text-light-gray flex flex-row gap-8 ">
                    <span>{notification.project.name}</span>
                    {hasDetails && showDetails && (
                        <span
                            className="hover:underline text-blue-600"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                setShowDetails(false);
                            }}
                        >
                            Hide Changes
                        </span>
                    )}
                    {hasDetails && !showDetails && (
                        <span
                            className="hover:underline text-blue-600"
                            onClick={(ev) => {
                                ev.stopPropagation();
                                setShowDetails(true);
                            }}
                        >
                            Show Changes
                        </span>
                    )}
                </div>
                {hasDetails && showDetails && <SummariseChanges changes={notification.notificationData.changes} />}
            </Col>

            <Col>
                <ChevronRight />
            </Col>
        </Row>
    );
};
