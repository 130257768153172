import { Button } from 'components/duex/Button';
import { Module } from 'components/duex/Module';
import { showError } from 'components/error-toast.component';
// import { Pagination } from 'components/pagination.component';
import { Avatar } from 'components/duex/Avatar';
import SearchTextField from 'components/duex/SearchTextField';
import { Col, ColHeader, Row, StyledTable } from 'components/table';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { Membership } from 'types';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { EditTeamMember } from './edit-team.view';
import { SendInvite } from './send-invite.component';

export const Team = (): ReactElement => {
    const [roles, setRoles] = useState([]);
    const [groups, setGroups] = useState([]);
    const { refreshTeam, fullTeamList: teamList } = useUserContext();
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [selectedUser, showUserModal] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchRoles = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_ROLES);
            const result = await API.get(url);

            if (result && result.data) {
                setRoles(result.data);
            }
        } catch (err) {
            showError('There was an error retrieving available roles', err);
        }
    };

    const fetchGroups = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_GROUPS);
            const result = await API.get(url);

            if (result && result.data) {
                setGroups(result.data);
            }
        } catch (err) {
            showError('There was an error retrieving available groups', err);
        }
    };

    const lastLoggedIn = (date: string) => {
        if (date) {
            return moment(date).fromNow();
        } else {
            return 'Never';
        }
    };

    // const paginate = (pageNumber) => setCurrentPage(pageNumber);

    useEffect(() => {
        fetchRoles();
        fetchGroups();
        document.title = 'Pearler | Team Management';
    }, []);

    const filteredTeamList = _filter(teamList, (teamMember: Membership) => {
        const search = searchTerm.trim().toLowerCase();

        if (search) {
            const emailAddress = teamMember.emailAddress.toLowerCase();

            if (emailAddress.indexOf(search) !== -1) {
                return true;
            }

            if (teamMember.member) {
                const fullName = teamMember.member.fullName.toLowerCase();
                if (fullName.indexOf(search) !== -1) {
                    return true;
                }
            }
            return false;
        }

        return true;
    });

    return (
        <Module
            moduleName="Team"
            width="w-full"
            rightItem={
                <Button
                    onClick={() => {
                        setShowInviteModal(true);
                    }}
                    label="Invite Team Member"
                    className="mr-16"
                />
            }
            middleItem={
                <div className="mx-auto w-600">
                    <SearchTextField
                        applySearch={setSearchTerm}
                        placeholder="Search by Team Member Name or Email"
                        debounceDuration={0}
                        dataCy="Team_Search"
                    />
                </div>
            }
        >
            <div className="px-16">
                <StyledTable>
                    <>
                        <Row className="border-b border-gray-light-1 bg-white">
                            <ColHeader text="Name" className={`w-5-of-24 pl-16`} />
                            <ColHeader text="Email Address" className={`w-6-of-24`} />
                            <ColHeader text="Team" className={`w-3-of-24 pl-8`} />
                            <ColHeader text="Role" className={`w-3-of-24 pl-8`} />
                            <ColHeader text="Added" className={`w-3-of-24`} />
                            <ColHeader text="Last Seen" className={`w-3-of-24`} />
                        </Row>
                        {filteredTeamList.map((membership) => {
                            const lastSeen = _get(membership, 'member.lastSeen');

                            return (
                                <Row
                                    key={membership.membershipId}
                                    onClickHandler={() => {
                                        showUserModal(membership.membershipId);
                                    }}
                                    dataCy={`teamRow`}
                                    className={`${
                                        membership.invitationStatus === 'ACCEPTED'
                                            ? ''
                                            : ' bg-primary-white-100-opace-4 opacity-40'
                                    } baseline flex cursor-pointer select-none items-center border-b border-gray-light-1 hover:bg-faded-gray`}
                                >
                                    <>
                                        {membership.userId ? (
                                            <Col
                                                keyProp={membership.userId}
                                                className={`grid w-5-of-24 gap-8 px-8`}
                                                style={{
                                                    gridTemplateColumns: '32px auto',
                                                }}
                                            >
                                                <>
                                                    <Avatar size={32} userId={membership.member.userId} />
                                                    <div className="truncate leading-32">
                                                        {membership.member.fullName}
                                                    </div>
                                                </>
                                            </Col>
                                        ) : (
                                            <Col keyProp="userName" className={`flex w-5-of-24 items-center`}>
                                                <>
                                                    <div className="mx-16 flex h-32 w-32 select-none items-center justify-center rounded-full bg-gray-light-1 object-cover text-11 leading-16 text-cream"></div>
                                                    <div className="text-black-60">Not Accepted</div>
                                                </>
                                            </Col>
                                        )}
                                        <Col keyProp="userEmail" className={`w-6-of-24 truncate pr-10`}>
                                            {membership.userId
                                                ? membership.member.emailAddress
                                                : membership.emailAddress}
                                        </Col>
                                        <Col keyProp="userTeam" className={`w-3-of-24 pr-16`}>
                                            <div className="pl-8">{membership.group.groupName}</div>
                                        </Col>
                                        <Col keyProp="userRole" className={`w-3-of-24 pr-16`}>
                                            <div className="pl-8">{membership.role.name}</div>
                                        </Col>
                                        <Col keyProp="dateAdded" className={`w-3-of-24`}>
                                            {moment(membership.createdAt).format('Do MMMM YYYY')}
                                        </Col>

                                        <Col
                                            keyProp="lastActive"
                                            className={`w-3-of-24 ${lastSeen ? 'text-black-80' : 'text-black-60'}`}
                                        >
                                            {membership.userId ? lastLoggedIn(lastSeen) : 'Never'}
                                        </Col>
                                    </>
                                </Row>
                            );
                        })}
                    </>
                </StyledTable>
                <div className="my-24 ml-auto flex select-none flex-row justify-end">
                    {/* <Pagination
                    postsPerPage={numbOfRowsPerPage}
                    totalPosts={activeTab === CONSTANTS.ACTIVE ? activeTeamList.length : teamList.length}
                    paginate={paginate}
                    currentPage={currentPage}
                /> */}
                </div>
                {selectedUser && (
                    <EditTeamMember
                        membership={_find(teamList, {
                            membershipId: selectedUser,
                        })}
                        groups={groups}
                        roles={roles}
                        closeModal={() => {
                            showUserModal(null);
                            refreshTeam();
                        }}
                    />
                )}
                {showInviteModal && (
                    <SendInvite
                        groups={groups}
                        roles={roles}
                        closeModal={() => {
                            setShowInviteModal(false);
                            refreshTeam();
                        }}
                    />
                )}
            </div>
        </Module>
    );
};
