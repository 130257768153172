import { ButtonDropDown } from 'components/button-drop-down.component';
import { Button } from 'components/duex/Button';
import { RichTextEditor, getText } from 'components/duex/RichTextEditor';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import { getComplianceSortKey, getComplianceStorageKey } from 'data/compliance-status-options';
import { useDebounce } from 'hooks/useDebounce';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import { generateTimestamp } from 'modules/answer-library/replace-answer.modal';
import React, { ReactElement, useEffect, useState } from 'react';
import { Answer } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { addToClipboard } from 'utility/Clipboard';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { getPlainText } from 'utility/StringHelper';
import { ProjectSharedProps } from '../project.view';
import { getDefaultButton, getSaveOptions, hideStarterResponses, splitQuestionIntoSegments } from './answering.helper';
import { GenerateModal } from './assistant/generate-modal';
import { RewriteModal } from './assistant/rewrite-modal';
import { Documents } from './documents/documents.view';
import { ExploreQuestions } from './explore-question/explore-question.component';
import { FavouriteAnswers } from './favourite-answers/favourite-answers.view';
import { QuestionChangedModal } from './question-changed/QuestionChangedModal';
import { QuestionContext } from './question-context/question-context.component';
import { SearchAnswers } from './search-answers/search-answers.component';
import { SuggestedAnswers } from './suggested-answers/suggested-answers.component';

export interface SuggestAnswersProps extends ProjectSharedProps {
    applyAnswer: (answer: Answer) => void;
    hideAnswer: (answer: Answer) => void;
    flagAnswer: (answer: Answer, flaggedReason: string) => void;
    undoHideAnswer: (answer: Answer) => void;
    hiddenAnswers: string[];
}

const comparisonProperties = [
    'answer',
    'richTextAnswer',
    'responseClassification',
    'includeInLibrary',
    'customStatusValue',
];

const starters = [
    {
        type: CONSTANTS.COMPLIANT,
        fullText: 'Yes, we comply with this requirement.',
        abbreviation: 'Yes, we comply.',
        isSelected: false,
    },
    {
        type: CONSTANTS.PARTIALLYCOMPLIANT,
        fullText:
            "We don't currently comply, but are willing to commit to undertaking the following changes to satisfy this requirement:",
        abbreviation: "Currently, we don't comply…",
        isSelected: false,
    },
    {
        type: CONSTANTS.NONCOMPLIANT,
        fullText: 'No, we do not comply with this requirement.',
        abbreviation: "No, we don't comply…",
        isSelected: false,
    },
    {
        type: CONSTANTS.NOTAPPLICABLE,
        fullText: 'Not applicable.',
        abbreviation: 'Not applicable',
        isSelected: false,
    },
];
export const complianceOptions = [
    {
        key: CONSTANTS.COMPLIANT,
        value: 'Compliant',
        isSelected: false,
        complianceValue: CONSTANTS.COMPLIANT,
        custom: false,
    },
    {
        key: CONSTANTS.PARTIALLYCOMPLIANT,
        value: 'Partially Compliant',
        isSelected: false,
        complianceValue: CONSTANTS.PARTIALLYCOMPLIANT,
        custom: false,
    },
    {
        key: CONSTANTS.NONCOMPLIANT,
        value: 'Non Compliant',
        isSelected: false,
        complianceValue: CONSTANTS.NONCOMPLIANT,
        custom: false,
    },
    {
        key: CONSTANTS.NOTAPPLICABLE,
        value: 'Not Applicable',
        isSelected: false,
        complianceValue: CONSTANTS.NOTAPPLICABLE,
        custom: false,
    },
];

export const Answering = (props: ProjectSharedProps): ReactElement => {
    const {
        projectId,
        questionId,
        questionList,
        question,
        project,
        saveQuestion,
        savingChanges,
        nextIncompleteQuestion,
        setAnswerHasChanges,
        fetchFavouriteAnswers,
        favouriteAnswers,
        incomingChanges: sourceIncomingChanges,
        applyIncomingChanges,
        discardIncomingChanges,
        setShowGenerateAnswer,
        setShowRewriteAnswer,
        showRewriteAnswer,
        showGenerateAnswer,
        setFilters,
    } = props;

    const incomingChanges = useDebounce(sourceIncomingChanges, 300);

    const [hasChanges, setHasChanges] = useState(false);
    const [answerHtml, setAnswerHtml] = useState('');
    const [responseClassification, setResponseClassification] = useState('');
    const [customResponseClassification, setCustomResponseClassification] = useState('');
    const [includeInLibrary, setIncludeInLibrary] = useState(false);
    const [fetchingTerms, setFetchingTerms] = useState(false);
    const [libraryTerms, setLibraryTerms] = useState([]);
    const [hiddenAnswers, setHiddenAnswers] = useState([]);

    const [textChangedByUser, setTextChanged] = useState(false);

    const applyAnswer = async (answer: Answer) => {
        updateAnswer(answer.answer, answer.richTextAnswer);
    };

    const undoHideAnswer = async (answer: Answer) => {
        setHiddenAnswers(
            _filter(hiddenAnswers, (answerId: string) => {
                return answerId !== answer.answerId;
            }),
        );

        try {
            const { answerId } = answer;
            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.FLAG_ANSWER, {
                answerId,
            });

            await API.post(url, {
                answerStatus: CONSTANTS.IN_USE,
            });

            logEvent('PROJECT_ANSWER_UNDO_HIDE_ANSWER', started, {
                answerId,
            });
        } catch (err) {
            showError('There was a problem changing that answer', err);
        }
    };

    const hideAnswer = async (answer: Answer) => {
        try {
            const { answerId } = answer;
            const started = new Date();

            const existingAnswer = hiddenAnswers.indexOf(answerId) !== -1;

            if (!existingAnswer) {
                setHiddenAnswers([...hiddenAnswers, answerId]);
            }

            const url = ENDPOINTS.getUrl(CONSTANTS.FLAG_ANSWER, {
                answerId,
            });

            await API.post(url, {
                answerStatus: CONSTANTS.ARCHIVE_ANSWER,
            });

            logEvent('PROJECT_ANSWER_HIDE_ANSWER', started, {
                answerId,
            });
        } catch (err) {
            showError('There was a problem changing that answer', err);
        }
    };

    const flagAnswer = async (answer: Answer, flaggedReason: string) => {
        try {
            const { answerId } = answer;
            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.FLAG_ANSWER, {
                answerId,
            });

            await API.post(url, {
                flaggedReason,
                answerStatus: CONSTANTS.FLAGGED,
            });

            if (
                _find(favouriteAnswers, {
                    answerId,
                })
            ) {
                fetchFavouriteAnswers();
            }

            logEvent('PROJECT_ANSWER_FLAG_ANSWER', started, {
                answerId,
                flaggedReason,
                answerStatus: CONSTANTS.FLAGGED,
            });
        } catch (err) {
            showError('There was a problem flagging that answer', err);
        }
    };

    const manageAnswers = {
        applyAnswer,
        undoHideAnswer,
        hideAnswer,
        flagAnswer,
        hiddenAnswers,
    };

    const searchForTerms = async () => {
        try {
            setFetchingTerms(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.LIBRARY_TERMS, {
                questionId,
                projectId,
            });

            const response = await API.get(url);

            if (response && response.data) {
                setLibraryTerms(response.data);
            }
            setFetchingTerms(false);
        } catch (err) {
            showError('There was a problem searching for answers', err);
            setFetchingTerms(false);
        }
    };

    const getOriginalState = () => {
        const { questionId, responseClassification, includeInLibrary, richTextAnswer, customStatusValue } = question;

        return {
            questionId,
            responseClassification,
            richTextAnswer: richTextAnswer?.trim() || '',
            includeInLibrary,
            customStatusValue,
        };
    };

    const getCurrentState = () => {
        return {
            questionId,
            responseClassification,
            richTextAnswer: answerHtml?.trim(),
            includeInLibrary,
            customStatusValue: customResponseClassification,
        };
    };

    const compare = () => {
        const originalState = getOriginalState();
        const currentState = getCurrentState();

        const hasDifferences = !_isEqual(originalState, currentState);

        if (hasDifferences && !textChangedByUser) {
            delete originalState.richTextAnswer;
            delete currentState.richTextAnswer;

            if (_isEqual(originalState, currentState)) {
                // This is an edge case where the stored html doesn't match,
                // usually because it wasn't authored here but was instead imported
                setHasChanges(false);
                setAnswerHasChanges(false);
                return;
            }
        }

        setHasChanges(hasDifferences);
        setAnswerHasChanges(hasDifferences);
    };

    const undoChanges = () => {
        setAnswerHtml(question.richTextAnswer || '');
        setResponseClassification(question.responseClassification);
        setCustomResponseClassification(question.customStatusValue);
        setIncludeInLibrary(question.includeInLibrary);
        setForceUpdate(generateTimestamp());
    };

    const applyStarter = (answerText: string, responseClassification) => {
        setAnswerHtml(answerText);
        setForceUpdate(generateTimestamp());
        setResponseClassification(responseClassification);
        setCustomResponseClassification(null);
    };

    const updateAnswer = (newAnswerText: string, newAnswerHtml: string) => {
        const stripped = getText(answerHtml.trim());

        if (stripped) {
            setAnswerHtml(`${answerHtml.trim()}<br />${newAnswerHtml}`.trim());
        } else {
            setAnswerHtml(`${newAnswerHtml}`.trim());
        }
        setForceUpdate(generateTimestamp());
    };

    useEffect(() => {
        if (projectId && questionId) {
            searchForTerms();
        }
        setAnswerHtml(question.richTextAnswer || '');
        setResponseClassification(question.responseClassification);
        setCustomResponseClassification(question.customStatusValue);
        setIncludeInLibrary(question.includeInLibrary);
        setAnswerHasChanges(false);
        setTextChanged(false);
        setForceUpdate(generateTimestamp());
    }, [questionId, projectId, question]);

    useEffect(() => {
        compare();
    }, [
        question,
        questionId,
        projectId,
        responseClassification,
        customResponseClassification,
        answerHtml,
        includeInLibrary,
    ]);

    const [activeTab, setActiveTab] = useState(CONSTANTS.SUGGESTED_ANSWERS);
    const tabs = [
        CONSTANTS.SUGGESTED_ANSWERS,
        CONSTANTS.SEARCH_ANSWERS,
        CONSTANTS.FAVOURITE_ANSWERS,
        CONSTANTS.EXPLORE_QUESTIONS,
        CONSTANTS.DOCUMENTS,
    ];

    let complianceCheckboxes = [...complianceOptions];

    if (project.complianceOverride) {
        if (project.complianceOverride[project.projectId]) {
            // Overrides for the default options
            complianceCheckboxes = _map(complianceOptions, (defaultOption) => {
                const storageKey = getComplianceStorageKey(defaultOption.key);
                const overrideValue = project.complianceOverride[project.projectId][storageKey];

                if (overrideValue) {
                    return {
                        ...defaultOption,
                        value: overrideValue,
                    };
                } else {
                    return defaultOption;
                }
            });
        }

        if (project.complianceOverride.aliases) {
            complianceCheckboxes = [
                ...complianceCheckboxes,
                ..._map(project.complianceOverride.aliases, (alias) => {
                    return {
                        key: alias.id,
                        custom: true,
                        value: alias.label,
                        complianceValue: alias.complianceType,
                    };
                }),
            ];
        }
    }

    complianceCheckboxes = complianceCheckboxes.sort(
        (
            { complianceValue: complianceValueA, custom: customA },
            { complianceValue: complianceValueB, custom: customB },
        ) => {
            const difference = getComplianceSortKey(complianceValueA) - getComplianceSortKey(complianceValueB);

            if (difference === 0) {
                if (customA && !customB) {
                    return 1;
                } else if (customB && !customA) {
                    return -1;
                }
            }

            return getComplianceSortKey(complianceValueA) - getComplianceSortKey(complianceValueB);
        },
    );

    const saveCallback = async (arg) => {
        switch (arg) {
            case CONSTANTS.ANSWERING_SAVE:
                saveQuestion(
                    {
                        richTextAnswer: answerHtml,
                        responseClassification,
                        customStatusValue: customResponseClassification,
                        includeInLibrary,
                    },
                    true,
                );

                break;
            case CONSTANTS.ANSWERING_SAVE_NEXT:
                saveQuestion(
                    {
                        richTextAnswer: answerHtml,
                        responseClassification,
                        customStatusValue: customResponseClassification,
                        includeInLibrary,
                    },

                    false,
                );
                nextIncompleteQuestion();
                break;
            case CONSTANTS.ANSWERING_COPY_SAVE:
                saveQuestion(
                    {
                        richTextAnswer: answerHtml,
                        responseClassification,
                        customStatusValue: customResponseClassification,
                        includeInLibrary,
                    },
                    true,
                );
                addToClipboard(getPlainText(answerHtml), answerHtml);
                showSuccess('Answer copied to clipboard');
                break;
        }
    };

    const segments = splitQuestionIntoSegments(question.question, libraryTerms);
    const informationSegments = splitQuestionIntoSegments(question.information, libraryTerms);

    const defaultSaveButton = getDefaultButton(question, questionList);
    const saveButtonOptions = getSaveOptions(questionList);

    const hideStarterOptions = hideStarterResponses(responseClassification, answerHtml);

    const privateProject = project.accessVisibility === 'PRIVATE';
    const includeInLibraryDescription = privateProject
        ? "Answers in Private Projects aren't able to be included in the Answer Library"
        : 'This checkbox controls whether your answer will be included in the Answer Library for future use';

    // Required for RichTextEditor to function correctly
    const [forceUpdate, setForceUpdate] = useState(generateTimestamp());

    let checkboxSelection = '';

    if (customResponseClassification) {
        checkboxSelection = customResponseClassification;
    } else {
        checkboxSelection = responseClassification;
    }

    return (
        <div className={`h-full w-full overflow-y-auto`}>
            <div className="flex h-full flex-col bg-white pt-24">
                <div className=" relative bg-white">
                    <div className="m-auto w-592">
                        <QuestionContext question={question} project={project} setFilters={setFilters} />

                        {question.information && (
                            <p className="mb-8 block w-full text-12 font-medium uppercase text-gray-400">Question</p>
                        )}
                        <div
                            className={`tracking-0.1 container mx-auto mb-24 flex self-start rounded-md bg-blue-50 p-16 text-18 leading-32 text-black-100`}
                        >
                            <pre
                                data-cy="Question_Block"
                                className="overflow-hidden whitespace-pre-line break-words font-body font-400"
                            >
                                {segments.map((segment) => {
                                    if (segment.type === 'TERM') {
                                        return (
                                            <span
                                                title={segment.content}
                                                className="cursor-pointer font-bold"
                                                key={segment.key}
                                                onClick={() => {
                                                    setActiveTab(CONSTANTS.EXPLORE_QUESTIONS);
                                                }}
                                            >
                                                {segment.content}
                                            </span>
                                        );
                                    }
                                    return segment.content;
                                })}
                            </pre>
                        </div>
                        {question.information && (
                            <>
                                <p className="mb-8 block w-full text-12 font-medium uppercase text-gray-400">
                                    Information
                                </p>
                                <div
                                    className={`${
                                        savingChanges ? 'text-black-40' : 'text-black-100'
                                    } rounded tracking-0.1 container mx-auto mb-24 flex self-start rounded-md bg-gray-50 p-16 text-14 leading-32`}
                                >
                                    <pre
                                        data-cy="Information_Block"
                                        className="overflow-hidden whitespace-pre-line break-words font-body font-400"
                                    >
                                        {informationSegments.map((segment) => {
                                            if (segment.type === 'TERM') {
                                                return (
                                                    <span
                                                        title={segment.content}
                                                        className="cursor-pointer font-bold"
                                                        key={segment.key}
                                                        onClick={() => {
                                                            setActiveTab(CONSTANTS.EXPLORE_QUESTIONS);
                                                        }}
                                                    >
                                                        {segment.content}
                                                    </span>
                                                );
                                            }
                                            return segment.content;
                                        })}
                                    </pre>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="m-auto w-592">
                    {!hideStarterOptions && (
                        <div className="tracking-0.2 mb-32 flex flex-row gap-16 text-12 font-medium leading-16">
                            {starters.map((response, index) => {
                                let className = '';

                                switch (response.type) {
                                    case CONSTANTS.COMPLIANT:
                                        className = `bg-success-light/20 hover:bg-success-light/30 text-success-dark`;
                                        break;
                                    case CONSTANTS.NONCOMPLIANT:
                                        className = `bg-error-light/20 hover:bg-error-light/30 text-error-dark`;
                                        break;
                                    case CONSTANTS.PARTIALLYCOMPLIANT:
                                        className = `bg-warning-light/20 hover:bg-warning-light/30 text-warning-dark`;
                                        break;
                                    case CONSTANTS.NOTAPPLICABLE:
                                        className = `bg-black-60/20 hover:bg-black-60/30 text-black-80`;
                                        break;
                                }

                                return (
                                    <div
                                        key={index}
                                        className={`${className} flex-no-wrap block cursor-pointer select-none truncate rounded-lg px-12 py-8 text-center`}
                                        data-title={response.fullText}
                                        onClick={() => {
                                            applyStarter(response.fullText, response.type);
                                        }}
                                    >
                                        {response.abbreviation}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <div>
                        <RichTextEditor
                            dataCy="Answer"
                            forceUpdate={forceUpdate}
                            htmlText={answerHtml}
                            placeholder="Type your answer, or select a template from above to get started."
                            label="Answer"
                            showLabel={false}
                            errorMessage="Please provide an answer"
                            valueChanged={(text: string, html: string) => {
                                setAnswerHtml(html);
                                setTextChanged(true);
                            }}
                            onBlur={() => {
                                setForceUpdate(generateTimestamp());
                            }}
                            disabled={savingChanges}
                            required={true}
                        />
                        <ul className="mt-24 flex flex-row flex-wrap gap-16 text-14 leading-20">
                            {complianceCheckboxes.map((itm) => {
                                const isSelected = checkboxSelection === itm.key;

                                return (
                                    <li key={itm.key} className={`flex items-center`}>
                                        <input
                                            data-cy="Compliance_Option"
                                            type="checkbox"
                                            id={itm.key}
                                            value={itm.key}
                                            className="form-checkbox round-4 mr-10 h-20 w-20 select-none border-2 border-light-gray focus:shadow-none focus:outline-none"
                                            name={itm.value}
                                            disabled={savingChanges}
                                            checked={isSelected}
                                            onChange={() => {
                                                if (isSelected) {
                                                    setResponseClassification(CONSTANTS.INCOMPLETE);
                                                    setCustomResponseClassification(null);
                                                } else {
                                                    setResponseClassification(itm.complianceValue);
                                                    if (itm.custom) {
                                                        setCustomResponseClassification(itm.key);
                                                    } else {
                                                        setCustomResponseClassification(null);
                                                    }
                                                }
                                            }}
                                        ></input>
                                        <label htmlFor={itm.key} className="select-none">
                                            {itm.value}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                        {project.projectStatus !== CONSTANTS.PROJECT_COMPLETE && (
                            <div
                                className="mb-16 mt-32 grid gap-x-16 rounded-md"
                                style={{ gridTemplateColumns: `168px 1fr 1fr` }}
                            >
                                <ButtonDropDown
                                    buttonOption={defaultSaveButton}
                                    dropdownOptions={saveButtonOptions}
                                    callBack={(arg) => {
                                        saveCallback(arg);
                                    }}
                                    isDisabled={!hasChanges || savingChanges}
                                    textClassNames={
                                        hasChanges
                                            ? 'text-white w-132 bg-field-outline-blue text-14 px-24 py-9 outline-none rounded-l-md'
                                            : 'text-black-60 w-132 bg-faded-gray outline-none text-14 px-24 py-9 rounded-l-md'
                                    }
                                    dropDownClassNames={
                                        hasChanges
                                            ? 'bg-field-outline-blue border-l border-white border-r-none border-y-none'
                                            : 'border-l border-white bg-faded-gray border-r-none border-y-none'
                                    }
                                    dropDownArrowColor={hasChanges ? '#FFFFFF' : '#A0A4A8'}
                                />
                                <div className="flex flex-row items-center text-light-gray">
                                    <input
                                        type="checkBox"
                                        disabled={privateProject || savingChanges}
                                        checked={includeInLibrary && !privateProject}
                                        className={`form-checkbox round-4 mr-10 h-16 w-16 border-2 border-light-gray text-14 leading-20 focus:shadow-none ${
                                            privateProject ? 'opacity-50' : ''
                                        }`}
                                        title={includeInLibraryDescription}
                                        id={`include-in-library`}
                                        onChange={() => {
                                            setIncludeInLibrary(!includeInLibrary);
                                        }}
                                    ></input>
                                    <label
                                        htmlFor="include-in-library"
                                        className={`select-none text-14 leading-20 ${
                                            privateProject ? 'opacity-50' : ''
                                        }`}
                                        title={includeInLibraryDescription}
                                    >
                                        Include answer in Library
                                    </label>
                                </div>
                                {hasChanges && (
                                    <Button
                                        buttonType="TERTIARY"
                                        label="Undo Changes"
                                        onClick={undoChanges}
                                        disabled={!hasChanges || savingChanges}
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div className="boder-faded-gray mt-24 border-b border-solid ">
                    <ul className="tracking-0.1 m-auto flex w-592 justify-between text-14 font-normal">
                        {tabs.map((tab) => {
                            return (
                                <li
                                    key={tab}
                                    data-key={tab}
                                    className={`mr-5 inline-block w-30-percent cursor-pointer select-none ${
                                        activeTab === tab
                                            ? 'text-center text-field-outline-blue'
                                            : 'text-center text-black-80 hover:text-hover-blue'
                                    }`}
                                    onClick={() => {
                                        setActiveTab(tab);
                                    }}
                                >
                                    <span className="inline-block select-none pb-12">{tab}</span>
                                    {activeTab === tab && (
                                        <div className="h-2 w-full rounded-md bg-field-outline-blue"></div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className=" flex-grow-2 bg-cream pb-32">
                    <div className="m-auto h-full">
                        {activeTab === CONSTANTS.SUGGESTED_ANSWERS && (
                            <SuggestedAnswers {...props} {...manageAnswers} />
                        )}
                        {activeTab === CONSTANTS.SEARCH_ANSWERS && <SearchAnswers {...props} {...manageAnswers} />}
                        {activeTab === CONSTANTS.FAVOURITE_ANSWERS && (
                            <FavouriteAnswers {...props} {...manageAnswers} />
                        )}
                        {activeTab === CONSTANTS.EXPLORE_QUESTIONS && (
                            <ExploreQuestions fetchingTerms={fetchingTerms} libraryTerms={libraryTerms} />
                        )}
                        {activeTab === CONSTANTS.DOCUMENTS && (
                            <Documents
                                questionId={questionId}
                                projectId={projectId}
                                className="h-full w-80-percent py-8"
                            />
                        )}
                    </div>
                </div>
                {incomingChanges &&
                    !_isEqual(
                        _pick(incomingChanges.question, comparisonProperties),
                        _pick(question, comparisonProperties),
                    ) && (
                        <QuestionChangedModal
                            incomingChanges={incomingChanges}
                            question={question}
                            applyIncomingChanges={applyIncomingChanges}
                            discardIncomingChanges={discardIncomingChanges}
                        />
                    )}
                {showGenerateAnswer && (
                    <GenerateModal
                        closeModal={() => {
                            setShowGenerateAnswer(false);
                        }}
                        information={question.information || ''}
                        question={question.question || ''}
                        setAnswer={(updatedText: string) => {
                            setAnswerHtml(updatedText);
                            setForceUpdate(generateTimestamp());
                        }}
                    />
                )}
                {showRewriteAnswer && (
                    <RewriteModal
                        closeModal={() => {
                            setShowRewriteAnswer(false);
                        }}
                        answer={getPlainText(answerHtml)}
                        setAnswer={(updatedText: string) => {
                            setAnswerHtml(updatedText);
                            setForceUpdate(generateTimestamp());
                        }}
                    />
                )}
            </div>
        </div>
    );
};
