import { Heatmap } from 'components/duex/Heatmap';
import { showError } from 'components/error-toast.component';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { DashboardWidget } from './dashboard-widget';

export function DashboardActivity(): ReactElement {
    const [isLoading, setIsLoading] = useState(false);
    const [activity, setActivity] = useState([]);

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_USER_ACTIVITY);
            const response = await API.get(url);

            if (response && response.data) {
                setActivity(response.data);
            }

            setIsLoading(false);
        } catch (err) {
            showError('There was a problem retrieving your activity', err);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <DashboardWidget isLoading={isLoading} title="Your Activity" drawBorder={true}>
            <Heatmap activity={activity} startDate={moment().subtract(90, 'day')} height={'h-320'} />
        </DashboardWidget>
    );
}
