import React, { ReactElement } from 'react';

export const BlueTick = ({ strokeColor = '#2068E3' }): ReactElement => {
    return (
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6663 1L5.49967 10.1667L1.33301 6"
                stroke={strokeColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
