import { BLUE, Pill } from 'components/pill.component';
import _map from 'lodash/map';
import React, { ReactElement } from 'react';
import Tag from 'types/tag.type';
export const TagList = ({
    tags = '',
    delimiter = ';',
    colour = BLUE,
}: {
    tags: Tag[] | string;
    delimiter?: string;
    colour?: string;
}): ReactElement => {
    let tagsList: {
        tagLabel: string;
    }[];

    if (Array.isArray(tags)) {
        tagsList = tags;
    } else {
        const components = tags.split(delimiter) || [];

        tagsList = _map(components, (tagLabel: string) => {
            return {
                tagLabel,
            };
        });
    }

    if (tagsList.length === 0) {
        return null;
    }

    return (
        <>
            {tagsList.map(({ tagLabel }, index: number) => {
                return <Pill key={index} colour={colour} title={tagLabel} />;
            })}
        </>
    );
};
