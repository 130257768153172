import React, { ReactElement } from 'react';

export const ModalCross = ({ strokeColor }: { strokeColor?: string }): ReactElement => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            data-cy="Modal_Close"
        >
            <path
                d="M15 5L5 15"
                stroke={strokeColor || '#2068E3'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5 5L15 15"
                stroke={strokeColor || '#2068E3'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
