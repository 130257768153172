import React, { ReactElement } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export default function Tooltip({ id }: { id }): ReactElement {
    return <ReactTooltip delayShow={500} id={id} place="top" />;
}

export const getTooltipId = (id: string): string => {
    return `tooltip_${id.replaceAll(' ', '_').toLowerCase()}`;
};
