import { Modal } from 'components/duex/Modal';
import React, { ReactElement } from 'react';
import { Button } from './Button';
import { ButtonBar } from './ButtonBar';

export const ConfirmationDialog = ({
    title,
    primaryLabel,
    secondaryLabel = 'Cancel',
    primaryAction,
    cancelAction,
    descriptionText,
    allowCopyDescription = false,
}: {
    title: string;
    primaryLabel: string;
    secondaryLabel?: string;
    descriptionText: string[];
    primaryAction: () => void;
    cancelAction: () => void;
    allowCopyDescription?: boolean;
}): ReactElement => {
    return (
        <Modal title={title} closeModal={cancelAction} showClose={true} width="w-600">
            {descriptionText.map((text, index) => {
                return (
                    <p key={index} className={`text-left text-gray-500 ${allowCopyDescription ? 'select-text' : ''}`}>
                        {text}
                    </p>
                );
            })}

            <ButtonBar className="mt-32">
                <Button buttonType="SECONDARY" label={secondaryLabel} onClick={cancelAction} />
                <Button label={primaryLabel} onClick={primaryAction} />
            </ButtonBar>
        </Modal>
    );
};
