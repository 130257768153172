import { fetchAuthSession } from 'aws-amplify/auth';
import axios, { AxiosResponse } from 'axios';
import { GenericDictionary } from 'types/generic-dictionary';
import { v4 as uuid } from 'uuid';
import harness from './Automation';
import { log } from './Logging';
import { sleep } from './Sleep';

// @ts-ignore
window.__Automation = harness;

const browserSessionIdentifier = uuid();

export const API = {
    getSessionIdentifier(): string {
        return browserSessionIdentifier;
    },

    async refreshToken(): Promise<void> {
        await fetchAuthSession();
    },

    async getAccessToken(): Promise<string> {
        const session = await fetchAuthSession();
        return session?.tokens?.accessToken.toString();
    },

    async requestAuthToken(): Promise<string> {
        try {
            const token = await this.getAccessToken();
            return token;
        } catch (err) {
            await this.refreshToken();
            const token = await this.getAccessToken();
            return token;
        }
    },

    getTestingHeaders() {
        if (harness.isUnderTest()) {
            return {
                'x-pearler-integration-test': 'true',
            };
        }
        return {};
    },

    async getAuthHeaders() {
        return {
            Authorization: await this.requestAuthToken(),
            'x-pearler-chaos': false,
            'x-pearler-session-identifier': browserSessionIdentifier,
            ...this.getTestingHeaders(),
        };
    },

    getXHROptions() {
        return {
            withCredentials: false,
            responseType: 'json',
            timeout: 120000, // 2 mins
        };
    },

    async resilientRequest(options): Promise<AxiosResponse> {
        let attempt = 1;
        const maxAttempts = 2;

        while (attempt <= maxAttempts) {
            const response = await axios(options).catch(async (error) => {
                if (error.response?.status === 401 && !options.excludeAuth) {
                    // Token has expired.
                    try {
                        await this.refreshToken();

                        options.headers = {
                            ...options.headers,
                            Authorization: await this.requestAuthToken(),
                        };
                    } catch (err) {
                        log('RequestAuthToken Request failed', err);
                    }
                }
                if (error.response?.status === 400) {
                    // Invalid request, attempting to sleep through it
                    await sleep(attempt * 200);
                }
                if (error.response?.status >= 500) {
                    // Server unable to process response for some reason, giving more time
                    await sleep(attempt * 500);
                }
            });

            if (response && response?.status === 200) {
                return response;
            }

            await sleep(100);

            attempt = attempt + 1;
        }

        throw new Error('Request failed');
    },

    async createRequest({
        url,
        method,
        headers = {},
        excludeAuth = false,
    }: {
        url: string;
        method: 'POST' | 'GET' | 'PUT';
        headers?: { [key: string]: string };
        excludeAuth: boolean;
    }) {
        const authHeaders = excludeAuth ? {} : await this.getAuthHeaders();
        const requestOptions = this.getXHROptions();
        const options = {
            method,
            url,
            headers: {
                ...authHeaders,
                ...headers,
            },
            ...requestOptions,
            excludeAuth,
        };

        return options;
    },

    async getBlob(url: string): Promise<AxiosResponse> {
        try {
            const options = await this.createRequest({
                url,
                method: 'GET',
            });
            options.responseType = 'blob';
            return this.resilientRequest(options);
        } catch (err) {
            log('GET Blob Request failed to ', url, err);
        }
    },

    async get(url: string): Promise<AxiosResponse> {
        try {
            const options = await this.createRequest({
                url,
                method: 'GET',
            });
            return this.resilientRequest(options);
        } catch (err) {
            log('GET Request failed to ', url, err);
        }
    },

    async post(url: string, data = {}, headers = {}): Promise<AxiosResponse> {
        try {
            const options = await this.createRequest({
                url,
                method: 'POST',
                headers,
            });
            options.data = data;
            return this.resilientRequest(options);
        } catch (err) {
            log('POST Request failed to ', url, err);
        }
    },

    async postWithoutAuth(url: string, data: unknown, headers = {}): Promise<AxiosResponse> {
        try {
            const options = await this.createRequest({
                url,
                method: 'POST',
                headers,
                excludeAuth: true,
            });
            options.data = data;
            return this.resilientRequest(options);
        } catch (err) {
            log('POST Request failed to ', url, err);
        }
    },

    async putWithoutAuth(
        url: string,
        data: GenericDictionary | File,
        headers: GenericDictionary,
    ): Promise<AxiosResponse> {
        try {
            const options = await this.createRequest({
                url,
                method: 'PUT',
                headers,
                excludeAuth: true,
            });
            options.data = data;
            return this.resilientRequest(options);
        } catch (err) {
            log('PUT Request failed to ', url, err);
        }
    },
};
