import React from 'react';
import { IconProps } from 'types/icon.type';

export const Information = ({ strokeColor = '#52575C' }: IconProps): React.ReactElement => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.4 19.6499C13.8 19.7999 13.5 20.3999 13.5 20.9999C13.5 21.7499 14.1 22.4999 15 22.4999C15.9 22.4999 16.5 21.8999 16.5 20.9999C16.5 19.7999 15.3 19.1999 14.4 19.6499Z"
                fill={strokeColor}
            />
            <path
                d="M15 7.5C14.1 7.5 13.5 8.1 13.5 9V16.5C13.5 17.4 14.1 18 15 18C15.9 18 16.5 17.4 16.5 16.5V9C16.5 8.1 15.9 7.5 15 7.5Z"
                fill={strokeColor}
            />
            <path
                d="M15 0C6.75 0 0 6.75 0 15C0 23.25 6.75 30 15 30C23.25 30 30 23.25 30 15C30 6.75 23.25 0 15 0ZM15 27C8.4 27 3 21.6 3 15C3 8.4 8.4 3 15 3C21.6 3 27 8.4 27 15C27 21.6 21.6 27 15 27Z"
                fill={strokeColor}
            />
        </svg>
    );
};
