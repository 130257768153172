import _filter from 'lodash/filter';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import React, { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { ENDPOINTS } from 'utility/Endpoints';
import { useUserContext } from './useUserContext';
interface TagContext {
    answerTags: Tag[];
    allTags: Tag[];
    refreshTags: () => void;
}

interface Tag {
    id: string;
    text: string;
}

const TagContext = createContext<TagContext>({
    answerTags: [],
    allTags: [],
    refreshTags: () => {
        // Do nothing
    },
});

export const TagContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
    const { userHasTeam } = useUserContext();
    const [answerTags, setAnswerTags] = useState<Tag[]>([]);
    const [allTags, setAllTags] = useState<Tag[]>([]);

    const fetchData = async () => {
        try {
            const url = ENDPOINTS.getUrl('GET_ALL_TAGS');

            const result = await API.get(url);

            if (result && result.data) {
                setAllTags(
                    _map(_uniqBy(result.data, 'tagLabel'), (item) => {
                        return {
                            id: item.tagLabel,
                            text: item.tagLabel,
                        };
                    }),
                );

                setAnswerTags(
                    _map(
                        _filter(result.data, (item) => {
                            return item.resourceType === 'ANSWER';
                        }),
                        (item) => {
                            return {
                                id: item.tagLabel,
                                text: item.tagLabel,
                            };
                        },
                    ),
                );
            }
        } catch (err) {
            console.log('There was an error retrieving tags', err);
        }
    };
    useEffect(() => {
        if (userHasTeam) {
            fetchData();
        } else {
            setAllTags([]);
            setAnswerTags([]);
        }
    }, [userHasTeam]);

    const store = {
        allTags,
        answerTags,
        refreshTags: fetchData,
    };

    return <TagContext.Provider value={store}>{children}</TagContext.Provider>;
};

export const useTagContext = (): TagContext => useContext(TagContext);
