import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Module } from 'components/duex/Module';
import SearchTextField from 'components/duex/SearchTextField';
import { Tabs } from 'components/duex/Tabs';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import { useTabs } from 'hooks/useTabs';
import { useUserContext } from 'hooks/useUserContext';
import _find from 'lodash/find';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import Team from 'types/team.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { TeamViewModal } from './team-view';

const defaultSearchOptions = [
    {
        label: 'Team',
        description: 'Search for Team by name',
        isActive: true,
    },
    {
        label: 'User',
        description: 'Search for Team by a Users email address',
        isActive: false,
    },
];

export const AccountAdministration = (): ReactElement => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searching, setSearching] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const { user } = useUserContext();

    const defaultTabs = [
        {
            label: 'Current Trials',
            isActive: true,
            filters: {
                currentTrial: true,
            },
        },
        {
            label: 'Search',
            isActive: false,
            filters: {},
        },
    ];

    if (user.emailAddress === 'robin+admin@garnham.com.au') {
        defaultTabs.push({
            label: 'Actions',
            isActive: false,
            filters: {},
        });
    }

    const {
        tabs: searchOptions,
        changeTab: setSearchOptions,
        selectedTab: selectedSearchOption,
    } = useTabs(defaultSearchOptions);

    const { tabs, changeTab, selectedTab } = useTabs(defaultTabs);

    const getFilters = () => {
        return selectedTab.filters;
    };

    const searchTeams = async () => {
        try {
            setSearching(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.ADMIN_TEAM_SEARCH);

            const results = await API.post(url, {
                searchTerm,
                searchBy: selectedSearchOption.label,
                ...getFilters(),
            });

            if (results && results.data) {
                setSearchResults(results.data);

                if (selectedTeam) {
                    selectTeam(
                        _find(results.data, {
                            teamId: selectedTeam,
                        }),
                    );
                }
            }
            setSearching(false);
        } catch (err) {
            showError('There was an error searching for teams', err);
            setSearching(false);
        }
    };

    const recalculateIndex = async () => {
        if (
            confirm(
                'This is destructive and breaks the ability to use suggestions and search until the index recalculates',
            )
        ) {
            try {
                const url = ENDPOINTS.getLiteralUrl('/admin/actions/flush-elastic');

                const results = await API.post(url);
                if (results && results.data) {
                    showSuccess('Request placed');
                }
            } catch (err) {
                showError('There was an error placing that request', err);
            }
        }
    };

    const selectTeam = (team: Team) => {
        setSelectedTeam(team);
    };

    useEffect(() => {
        setSelectedTeam(null);
        searchTeams();
    }, [searchTerm, selectedSearchOption, selectedTab]);

    const showResults = selectedTab.label !== 'Actions';

    return (
        <Module
            moduleName="Administration"
            middleItem={
                <ButtonBar alignment="CENTRE">
                    <div className="w-800">
                        <SearchTextField
                            placeholder={selectedSearchOption.description}
                            applySearch={setSearchTerm}
                            currentValue={searchTerm}
                            activeBackground={true}
                        />
                    </div>
                    <Tabs
                        tabItems={searchOptions}
                        onChange={setSearchOptions}
                        showBottomBorder={false}
                        selectedTabClassName="bg-blue-500 text-white rounded-full border-transparent"
                        tabClassName="rounded-full"
                    />
                </ButtonBar>
            }
        >
            <div className="mx-16">
                <div className="mb-16">
                    <Tabs tabItems={tabs} onChange={changeTab} />
                </div>
                {searching && <LoadingSpinner />}
                {!searching && showResults && (
                    <div>
                        {searchResults.length === 0 && (
                            <EmptyState title="No teams found" subtitle="Your search didn't match any teams" />
                        )}
                        {searchResults.length > 0 && (
                            <div className="grid gap-16">
                                {searchResults.map((team: Team, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className="grid cursor-pointer gap-16 rounded-lg p-16 leading-32 hover:bg-blue-50"
                                            style={{
                                                gridTemplateColumns: '34px 1fr 1fr',
                                            }}
                                            onClick={() => {
                                                selectTeam(team);
                                            }}
                                        >
                                            <div className="h-32 w-32 rounded-full border border-gray-200 bg-gray-200">
                                                {team.teamLogo && (
                                                    <img
                                                        className="h-full w-full rounded-full object-cover"
                                                        src={team.teamLogo}
                                                    />
                                                )}
                                            </div>
                                            <div>
                                                <div className="font-medium">{team.name}</div>
                                                <div className="text-sm text-gray-500">{team.companyWebsite}</div>
                                            </div>
                                            <div className="text-right text-gray-500">
                                                {moment(team.createdAt).fromNow()}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                )}
                {!showResults && (
                    <div className="grid gap-32 m-32">
                        <h1>These are dangerous actions, only for Robin</h1>
                        <div>
                            <Button
                                label="Re-create Search Indexes"
                                buttonType="SECONDARY"
                                onClick={recalculateIndex}
                            />
                        </div>
                    </div>
                )}
                {selectedTeam && (
                    <TeamViewModal
                        originalSettings={selectedTeam}
                        closeModal={() => {
                            setSelectedTeam(null);
                        }}
                    />
                )}
            </div>
        </Module>
    );
};
