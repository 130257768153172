import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { EmptyState } from 'components/duex/EmptyState';
import { FormLabel } from 'components/duex/FormLabel';
import { FormSelectField } from 'components/duex/FormSelectField';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { MultiSelectPill } from 'components/duex/MultiSelectPill';
import { showError } from 'components/error-toast.component';
import { showSuccess } from 'components/success-toast.component';
import { isValidEmail, isValidString } from 'components/validation/string';
import { useTeamContext } from 'hooks/useTeamContext';
import { useUserContext } from 'hooks/useUserContext';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _pick from 'lodash/pick';
import _startCase from 'lodash/startCase';
import _toLower from 'lodash/toLower';
import React, { ReactElement, useState } from 'react';
import { Group, Membership, Role } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const EditTeamMember = ({
    closeModal,
    roles,
    groups,
    membership,
}: {
    closeModal: () => void;
    membership: Membership;
    roles: Role[];
    groups: Group[];
}): ReactElement => {
    const {
        getUser,
        user: { userId },
    } = useUserContext();
    const { hasFeature, teamDivisions } = useTeamContext();
    const user = getUser(membership?.userId);
    const [saving, setSaving] = useState(null);
    const [currentChanges, setChanges] = useState(membership);
    const [homeDivision, setHomeDivision] = useState(membership.homeDivision || null);
    const [selectedDivisions, setSelectedDivisions] = useState(membership.divisions || []);

    const updateField = (propertyName: string, newValue: string) => {
        setChanges({
            ...currentChanges,
            [propertyName]: newValue,
        });
    };

    const properties = ['groupId', 'roleId'];

    const hasChanges =
        !_isEqual(_pick(currentChanges, properties), _pick(membership, properties)) ||
        !_isEqual(homeDivision, membership.homeDivision) ||
        !_isEqual(selectedDivisions, membership.divisions);

    const saveChanges = async () => {
        try {
            setSaving('SAVING');
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_MEMBERSHIP, {
                membershipId: membership.membershipId,
            });
            const result = await API.post(url, {
                ..._pick(currentChanges, properties),
                ...getDivisionSettings(),
            });

            if (result) {
                logEvent('MEMBERSHIP_UPDATED', started);

                showSuccess('Access updated');
                setSaving(null);
                closeModal();
            }
        } catch (err) {
            showError('There was an error updating that person', err);
            setSaving(null);
        }
    };

    const deleteAccount = async () => {
        try {
            if (window.confirm('Are you sure you want to delete that account?')) {
                setSaving('DELETING');
                const started = new Date();
                const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_MEMBERSHIP, {
                    membershipId: membership.membershipId,
                });
                const result = await API.post(url, {
                    invitationStatus: 'DEACTIVE',
                });

                if (result) {
                    logEvent('MEMBERSHIP_REMOVED', started);

                    showSuccess('Account Removed');
                    setSaving(null);
                    closeModal();
                }
            }
        } catch (err) {
            showError('There was an error removing that person', err);
            setSaving(null);
        }
    };

    const cancelInvite = async () => {
        try {
            if (window.confirm('Are you sure you want to cancel the invitation?')) {
                setSaving('CANCELLING');
                const started = new Date();
                const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_MEMBERSHIP, {
                    membershipId: membership.membershipId,
                });
                const result = await API.post(url, {
                    invitationStatus: 'CANCELLED',
                });

                if (result) {
                    logEvent('MEMBERSHIP_INVITE_CANCELLED', started);

                    showSuccess('Invitation Removed');
                    setSaving(null);
                    closeModal();
                }
            }
        } catch (err) {
            showError('There was an error removing that person', err);
            setSaving(null);
        }
    };

    const reactivateAccount = async () => {
        try {
            setSaving('REACTIVATE');
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_MEMBERSHIP, {
                membershipId: membership.membershipId,
            });
            const result = await API.post(url, {
                invitationStatus: 'ACCEPTED',
            });

            if (result) {
                logEvent('MEMBERSHIP_REACTIVATED', started);

                showSuccess('Account Reactivated');
                setSaving(null);
                closeModal();
            }
        } catch (err) {
            showError('There was an error reactivating that person', err);
            setSaving(null);
        }
    };

    const resendInviteEmail = async () => {
        try {
            setSaving('INVITING');
            const started = new Date();
            const url = ENDPOINTS.getUrl(CONSTANTS.REINVITE_MEMBERSHIP, {
                membershipId: membership.membershipId,
            });
            const result = await API.post(url);

            if (result) {
                logEvent('MEMBERSHIP_INVITE_RE_SEND', started);
                showSuccess('Invitation Sent');
                setSaving(null);
                closeModal();
            }
        } catch (err) {
            showError('There was an error inviting that person', err);
            setSaving(null);
        }
    };

    const divisionsEnabled = hasFeature('enableDivisions');

    const getDivisionSettings = () => {
        if (!divisionsEnabled) {
            return {};
        }

        return {
            homeDivision,
            divisions: selectedDivisions,
        };
    };

    const isTeamOwner = membership.role.code === 'ROLE_ADMIN_TEAM_OWNER';
    const allowRoleChange = userId !== membership.userId && !isTeamOwner;

    const divisionOptions = _map(teamDivisions, ({ divisionId, divisionName }) => {
        return {
            itemId: divisionId,
            itemLabel: divisionName,
        };
    });

    return (
        <Modal width="w-720" title={`${user.fullName || 'Update'}`} closeModal={closeModal}>
            <div className="grid gap-16">
                <div className="flex flex-row justify-center">
                    {membership.invitationStatus === 'UNACCEPTED' || membership.invitationStatus === 'DECLINED' ? (
                        <EmptyState
                            title="This invitation hasn't been accepted"
                            subtitle=""
                            className="flex flex-col items-center text-center"
                        >
                            <Button
                                label="Re-send Invitation"
                                disabled={saving}
                                loading={saving === 'INVITING'}
                                onClick={resendInviteEmail}
                                className="mt-16"
                            />
                        </EmptyState>
                    ) : (
                        <Avatar userId={membership.userId} size={144} />
                    )}
                </div>
                <FormTextField
                    required={false}
                    defaultValue={currentChanges.emailAddress}
                    label="Email Address"
                    valueChanged={(updateValue: string) => {
                        updateField('emailAddress', updateValue);
                    }}
                    validator={(newValue: string) => isValidEmail(newValue)}
                    placeholder="john.citizen@acme.com"
                    errorMessage="Please enter an email address"
                    maxLength={256}
                    disabled={true}
                />

                <FormSelectField
                    required={true}
                    defaultValue={currentChanges.groupId}
                    label="Team"
                    valueChanged={(updateValue: string) => {
                        updateField('groupId', updateValue);
                    }}
                    validator={(newValue: string) => isValidString(newValue)}
                    placeholder="Select Team"
                    errorMessage="Please select a team"
                    items={groups}
                    optionLabel="groupName"
                    optionValue="groupId"
                />
                {isTeamOwner && (
                    <FormTextField
                        required={false}
                        defaultValue={_startCase(_toLower(membership.role.name))}
                        label="Access"
                        maxLength={256}
                        disabled={true}
                    />
                )}
                {!isTeamOwner && (
                    <FormSelectField
                        required={allowRoleChange}
                        defaultValue={currentChanges.roleId}
                        label="Access"
                        valueChanged={(updateValue: string) => {
                            updateField('roleId', updateValue);
                        }}
                        validator={(newValue: string) => isValidString(newValue)}
                        placeholder="Select Access"
                        errorMessage="Please select an access level"
                        items={roles}
                        optionLabel="name"
                        optionValue="roleId"
                        disabled={!allowRoleChange}
                    />
                )}
                {divisionsEnabled && (
                    <>
                        <FormSelectField
                            required={false}
                            defaultValue={homeDivision}
                            label="Primary Division"
                            valueChanged={(newValue: string) => {
                                setHomeDivision(newValue);

                                if (newValue && selectedDivisions.indexOf(newValue) === -1) {
                                    setSelectedDivisions([...selectedDivisions, newValue]);
                                }
                            }}
                            placeholder="Select Primary Division"
                            errorMessage="Please select a primary division"
                            items={teamDivisions}
                            optionLabel="divisionName"
                            optionValue="divisionId"
                        />
                        <div>
                            <FormLabel label="Divisions" required={true} />
                            <MultiSelectPill
                                items={divisionOptions}
                                selections={selectedDivisions}
                                updateSelection={setSelectedDivisions}
                            />
                        </div>
                    </>
                )}

                <FormTextField
                    required={false}
                    defaultValue={_startCase(_toLower(membership.invitationStatus))}
                    label="Invite Status"
                    maxLength={256}
                    disabled={true}
                />
                <div className="flex flex-row justify-between">
                    <ButtonBar>
                        {userId !== membership.userId && (
                            <>
                                {membership.invitationStatus === 'DEACTIVE' && (
                                    <Button
                                        label="Reactivate Account"
                                        disabled={saving}
                                        loading={saving === 'REACTIVATE'}
                                        buttonType="TERTIARY"
                                        onClick={reactivateAccount}
                                    />
                                )}
                                {membership.invitationStatus === 'ACCEPTED' && (
                                    <Button
                                        label="Deactivate Account"
                                        disabled={saving}
                                        loading={saving === 'DELETING'}
                                        buttonType="TERTIARY"
                                        onClick={deleteAccount}
                                    />
                                )}
                                {(membership.invitationStatus === 'UNACCEPTED' ||
                                    membership.invitationStatus === 'DECLINED') && (
                                    <Button
                                        label="Cancel Invitation"
                                        disabled={saving}
                                        loading={saving === 'CANCELLING'}
                                        buttonType="TERTIARY"
                                        onClick={cancelInvite}
                                    />
                                )}
                            </>
                        )}
                    </ButtonBar>
                    <ButtonBar>
                        <Button label="Cancel" disabled={saving} buttonType="SECONDARY" onClick={closeModal} />
                        <Button
                            label="Save Changes"
                            loading={saving === 'SAVING'}
                            disabled={saving || !hasChanges}
                            onClick={saveChanges}
                        />
                    </ButtonBar>
                </div>
            </div>
        </Modal>
    );
};
