import { Clock } from 'assets/time.svg';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { CONSTANTS } from 'utility/Constants';

export const DueDate = ({
    projectPriority,
    projectDueDate,
    projectStatus,
}: {
    projectPriority: string;
    projectDueDate: Date;
    projectStatus: string;
}): ReactElement => {
    const baseClasses = 'text-16 leading-24 ml-5';
    let label = '',
        className = baseClasses,
        strokeColor = '';

    const endOfToday = moment().endOf('day');
    const dueDate = moment(projectDueDate).endOf('day');

    const dueToday = endOfToday.isSame(dueDate);
    const isPastDue = endOfToday.isAfter(dueDate);

    const daysBetween = Math.abs(endOfToday.diff(dueDate, 'days'));

    const isHighPriority = projectPriority === CONSTANTS.HIGH;
    const projectOpen = projectStatus === 'IN-PROGRESS';

    if (!projectOpen) {
        label = `${daysBetween} ${daysBetween === 1 ? 'day' : 'days'} ago`;
        className = `${baseClasses} text-gray-500`;
        strokeColor = '#6b7280';
    } else if (isPastDue) {
        label = `${daysBetween} ${daysBetween === 1 ? 'day' : 'days'} late`;
        className = `${baseClasses} text-error-light`;
        strokeColor = '#FF5640';
    } else if (dueToday) {
        label = 'Due Today';
    } else {
        label = `${daysBetween} ${daysBetween === 1 ? 'day' : 'days'} to go`;
    }

    if (baseClasses === className) {
        className = `${baseClasses} ${isHighPriority ? 'text-dark-golden' : 'text-sea-blue'}`;
        strokeColor = isHighPriority ? '#FF9100' : '#477CD7';
    }

    return (
        <div className="flex select-none items-center">
            <Clock strokeColor={strokeColor} />
            <p className={className}>{label}</p>
        </div>
    );
};
