import _map from 'lodash/map';
const industries = [
    'Accounting & Auditing',
    'Agriculture',
    'Airlines',
    'Amusement & Recreation',
    'Arts & Culture',
    'Banking',
    'Business Services',
    'Chemical Industry',
    'Construction',
    'Commercial',
    'Consumer Discretionary',
    'Consumer Services',
    'Consumer Staples',
    'Corrections',
    'Ecommerce',
    'Education',
    'Education - Primary',
    'Education - Secondary',
    'Education - Tertiary',
    'Energy',
    'Engineering',
    'Entertainment',
    'Environment',
    'Fashion',
    'Forestry',
    'Government',
    'Healthcare',
    'Heavy Industry',
    'Hotels',
    'Information Technology',
    'Infrastructure',
    'Insurance',
    'Law',
    'Life Sciences',
    'Logistics',
    'Manufacturing',
    'Media',
    'Mining',
    'Non-profits',
    'Oil & Gas',
    'Professional Services',
    'Publishing',
    'Rail',
    'Real Estate',
    'Restaurants',
    'Retail',
    'Science & Technology',
    'Space',
    'Telecom',
    'Tourism',
    'Transportation',
    'Utilities',
    'Wholesale',
];

export const industryOptions = _map(industries, (industry) => {
    return {
        label: industry,
        value: industry,
    };
});
