export const CONFIG = {
    BREAKPOINT_SM: 640,
    BREAKPOINT_MD: 768,
    BREAKPOINT_LG: 1024,
    BREAKPOINT_XL: 1440,
    BREAKPOINT_2XL: 1680,
    TIME_TO_REMOVE_ANSWER: 5000,
    ANSWER_APPEND_INTERVAL: 20,
    TIME_CLEAR_APPEND_INTERVAL: 180,
    TIME_TO_CHANGE_BUTTON_STATE: 1500,
    SEARCH_FIELD_DEBOUNCE: 300,
    TEAM_VIEW_LIST_NUMBER: 8,
    ROW_HEIGHT: 49,
    PROJECTS_PER_PAGE: 8,
    SINGLE_MEMBER: 1,
    HEATMAP_LEVEL_1_BREAKPOINT: 4,
    HEATMAP_LEVEL_2_BREAKPOINT: 8,
    HEATMAP_LEVEL_3_BREAKPOINT: 12,
    HEATMAP_LEVEL_4_BREAKPOINT: 16,
    ANSWER_LIBRARY_OFFSET: 10,
    ANSWER_LIBRARY_LIMIT: 20,
    SEARCH_LIMIT_MAX: 255,
    SEARCH_LIMIT_MIN: 3,
};
