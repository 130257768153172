import moment from 'moment';
import React, { ReactElement } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import { Tooltip } from 'react-tooltip';

export interface Activity {
    date: string;
    count: number;
}

const configuration = [
    {
        threshold: 16,
        colourClass: 'fill-primary-blue-90 bg-primary-blue-90',
    },
    {
        threshold: 12,
        colourClass: 'fill-heading-blue bg-heading-blue',
    },
    {
        threshold: 8,
        colourClass: 'fill-primary-blue-50 bg-primary-blue-50',
    },
    {
        threshold: 4,
        colourClass: 'fill-light-faded-blue bg-light-faded-blue',
    },
    {
        threshold: 0,
        colourClass: 'fill-primary-blue-100-opace-15 bg-primary-blue-100-opace-15',
    },
];

const reversedConfiguration = configuration.slice().reverse();

export function Heatmap({
    activity,
    descriptor = 'Contributions',
    startDate,
    endDate = moment(),
    scale = 1,
    height = '',
    showLegend = true,
}: {
    descriptor?: string;
    activity: Activity[];
    startDate: moment.Moment;
    endDate?: moment.Moment;
    scale?: number;
    height?: string;
    showLegend?: boolean;
}): ReactElement {
    const toolTipAttributes = (value) => {
        let content = 'No Activity';

        if (value.date) {
            content = `${moment(value.date).format('dddd Do MMMM YYYY')} <br />
            ${descriptor}: ${value.count || '-'}`;
        }
        return {
            'data-tooltip-html': content,
            'data-tooltip-id': 'chart',
        };
    };

    const getClassForValue = (item: { count: number }) => {
        if (item) {
            const { count } = item;

            for (let i = 0; i < configuration.length; i++) {
                const { threshold, colourClass } = configuration[i];

                if (count * scale >= threshold) {
                    return colourClass;
                }
            }
        } else {
            return 'fill-faded-gray';
        }
    };

    return (
        <div className={`flex h-full flex-col items-center justify-center`}>
            <div className={`w-100-percent ${height}`}>
                <CalendarHeatmap
                    startDate={startDate}
                    endDate={endDate}
                    values={activity}
                    classForValue={getClassForValue}
                    showOutOfRangeDays={false}
                    tooltipDataAttrs={toolTipAttributes}
                    showWeekdayLabels={true}
                />
            </div>
            {showLegend && (
                <ul className="small-3-reg mb-32 flex items-center justify-center gap-8 text-black-60">
                    <li>Less</li>
                    {reversedConfiguration.map(({ colourClass }, index) => {
                        return <li key={index} className={`${colourClass} p-8`}></li>;
                    })}
                    <li>More</li>
                </ul>
            )}

            <Tooltip id="chart" />
        </div>
    );
}
