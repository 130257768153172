import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Question } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const AddQuestionModal = ({
    closeModal,
    projectId,
    patchQuestion,
}: {
    closeModal: () => void;
    projectId: string;
    patchQuestion: (newQuestion: Question) => void;
}): ReactElement => {
    const [question, setQuestion] = useState('');
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();

    const validate = (question = ''): boolean => {
        const cleaned = question.trim();
        return cleaned && cleaned.length > 10;
    };

    const createQuestion = async () => {
        try {
            const started = new Date();
            setSaving(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.ADD_QUESTION, {
                projectId,
            });

            const result = await API.post(url, {
                question: question.trim(),
            });

            logEvent(`PROJECT_ADD_QUESTION`, started, {
                projectId,
            });

            if (result && result.data) {
                setSaving(false);
                patchQuestion(result.data);
                navigate(createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, result.data));
            }

            closeModal();
        } catch (err) {
            setSaving(false);
            showError('There was an error creating your question', err);
        }
    };

    useEffect(() => {
        setSaveEnabled(validate(question));
    }, [question]);

    return (
        <Modal width="w-680" closeModal={closeModal} title="Add Question">
            <div>
                <FormTextField
                    placeholder="Type or copy in the question"
                    label="Question"
                    inputType="resizing-textarea"
                    errorMessage="Please check the question"
                    validator={validate}
                    valueChanged={setQuestion}
                    required={true}
                    spellCheck={true}
                    maxLength={10000}
                />
            </div>
            <ButtonBar className="mt-32">
                <Button buttonType="SECONDARY" label="Cancel" onClick={closeModal} disabled={saving} />
                <Button
                    label="Add Question"
                    onClick={createQuestion}
                    disabled={!saveEnabled || saving}
                    loading={saving}
                />
            </ButtonBar>
        </Modal>
    );
};
