import React, { ReactElement, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const FormSelectField = ({
    valueChanged,
    validator,
    placeholder,
    label,
    errorMessage,
    required,
    containerClasses = '',
    defaultValue = '',
    maxLength = 255,
    disabled = false,
    // image = null,
    items = [],
    optionValue = 'value',
    optionLabel = 'label',
    includeLabel = true,
    size = 'STANDARD',
    dataCy,
}: {
    validator?: (value: string) => boolean;
    valueChanged?: (value: string) => void;
    placeholder: string;
    label: string;
    required?: boolean;
    errorMessage: string;
    containerClasses?: string;
    defaultValue?: string;
    maxLength?: number;
    disabled?: boolean;
    image?: ReactElement;
    // eslint-disable-next-line
    items: any[];
    optionValue?: string;
    optionLabel?: string;
    includeLabel?: boolean;
    size?: 'STANDARD' | 'SMALL';
    dataCy?: string;
}): ReactElement => {
    const [identifier] = useState(uuidv4());
    const fieldIdentifier = `id_${identifier}`;
    const [fieldValue, setFieldValue] = useState('');
    const [fieldFocussed, setFieldFocussed] = useState(false);
    const [showingError, setShowingError] = useState(false);
    const [hasFocussed, setHasFocussed] = useState(false);

    let labelClasses = '';
    let inputClasses = '';

    if (showingError) {
        labelClasses = 'showing-error text-red';
        inputClasses = 'showing-error text-red border-red bg-white';
    } else if (fieldFocussed) {
        labelClasses = 'text-primary-blue-100';
        inputClasses = 'text-primary-blue-100 border-primary-blue-100 bg-white';
    } else if (disabled) {
        labelClasses = 'text-black-60';
        inputClasses = 'text-gray-600 bg-gray-100';
    } else {
        labelClasses = 'text-black-60';
        inputClasses = 'bg-white';
    }

    useEffect(() => {
        setFieldValue(defaultValue || '');
        if (validator) {
            setShowingError(!validator(defaultValue) && hasFocussed);
        }
    }, [defaultValue]);

    const inputProps = {
        maxLength,
        id: fieldIdentifier,
        className: `form-input mt-1 block w-full ${
            size === 'SMALL' ? 'py-4 px-8 body-2-reg' : 'body-1-reg py-12 px-16'
        } focus:outline-none focus:shadow-none ${inputClasses}`,
        value: fieldValue,
        disabled,
        onChange: (e) => {
            setFieldValue(e.target.value);
            if (valueChanged) {
                valueChanged(e.target.value);
            }
        },
        onFocus: () => {
            setFieldFocussed(true);
            setShowingError(false);
            setHasFocussed(true);
        },
        onBlur: () => {
            setFieldFocussed(false);

            if (validator) {
                setShowingError(!validator(fieldValue));
            }
        },
        placeholder,
    };

    return (
        <div className={containerClasses}>
            {includeLabel && (
                <label
                    htmlFor={fieldIdentifier}
                    className={`small-1-med mb-4 flex flex-row justify-between ${labelClasses}`}
                >
                    <span>{label}</span>
                    {required && <span className="float-right text-black-60">Required</span>}
                </label>
            )}

            <select data-cy={dataCy} {...inputProps}>
                {(!fieldValue || !required) && (
                    <option key="placeholder-selection" value="">
                        {placeholder}
                    </option>
                )}
                {items.map((item) => {
                    const label = item[optionLabel];
                    const value = item[optionValue];

                    return (
                        <option key={value} value={value}>
                            {label}
                        </option>
                    );
                })}
            </select>
            {showingError && <span className="text-12 text-black-60">{errorMessage}</span>}
        </div>
    );
};
