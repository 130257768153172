import { SimpleTextSelection } from 'components/duex/SimpleTextSelection';
import { TagList } from 'components/duex/TagList';
import _filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _times from 'lodash/times';
import React, { ReactElement, useEffect, useState } from 'react';
import { Column, Row, Sheet, SheetConfiguration } from './library-importer';
export const SheetView = ({
    selectedFile,
    selectedSheet,
    sheetConfiguration,
    setSheetConfiguration,
    questionCount,
}: {
    selectedFile: {
        fileName: string;
    };
    selectedSheet: Sheet;
    sheetConfiguration: SheetConfiguration;
    setSheetConfiguration: (updatedConfiguration: SheetConfiguration) => void;
    questionCount: number;
}): ReactElement => {
    const classifications = ['Ignore', 'Question', 'Answer', 'Tags']; // , 'Compliance Status', 'Information'
    const [columnConfigurationError, setColumnConfigurationError] = useState(null);
    const [hasAnswerColumn, setHasAnswerColumn] = useState(false);
    const [hasQuestionColumn, setHasQuestionColumn] = useState(false);
    const [filterSelection, setFilterSelection] = useState('ALL');

    const classifyColumn = (columnIndex: number, newValue: string) => {
        setSheetConfiguration({
            ...sheetConfiguration,
            columnClassifications: _map(sheetConfiguration.columnClassifications, (columnConfig, index) => {
                if (index === columnIndex) {
                    return {
                        ...columnConfig,
                        columnType: newValue,
                    };
                }
                return columnConfig;
            }),
        });
    };

    const changeRowSelection = (rowNumber: number, newValue: boolean) => {
        const update = {
            ...sheetConfiguration.rowInclusion,
            [rowNumber]: newValue,
        };

        setSheetConfiguration({
            ...sheetConfiguration,
            rowInclusion: update,
        });
    };

    const checkQuestionColumnSpecified = () => {
        if (!sheetConfiguration) {
            return setColumnConfigurationError(null);
        }

        const classifiedColumns = sheetConfiguration.columnClassifications;

        const questionColumns = _filter(classifiedColumns, { columnType: 'Question' });
        const answerColumns = _filter(classifiedColumns, { columnType: 'Answer' });
        const complianceColumns = _filter(classifiedColumns, { columnType: 'Compliance Status' });
        const tagColumns = _filter(classifiedColumns, { columnType: 'Tags' });

        setHasQuestionColumn(questionColumns.length > 0);
        setHasAnswerColumn(answerColumns.length > 0);

        if (questionColumns.length === 0) {
            return setColumnConfigurationError('Please choose a column as Question');
        }

        if (answerColumns.length === 0) {
            return setColumnConfigurationError('Please choose a column as Answer');
        }

        if (answerColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column as Answer');
        }

        if (complianceColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column as Compliance Status');
        }

        if (tagColumns.length > 1) {
            return setColumnConfigurationError('Please choose only one column for Tags');
        }

        setColumnConfigurationError(null);
    };

    const getContentFromCell = (row: Row, columnNumber: number) => {
        for (let i = 0; i < row.columns.length; i++) {
            if (row.columns[i].columnNumber === columnNumber) {
                return row.columns[i].content;
            }
        }
    };

    const meetsCriteria = (contentString = ''): boolean => {
        try {
            return contentString && contentString.trim() && contentString.split(' ').length > 3;
        } catch (err) {
            console.log('contentString', contentString);
            console.log(err);
        }
    };

    const getColumnData = (classifiedColumns: Column[], sheetContent: Row[]) => {
        const questionColumns = _filter(classifiedColumns, { columnType: 'Question' });
        const answerColumns = _filter(classifiedColumns, { columnType: 'Answer' });

        return _mapValues(
            _keyBy(
                _map(sheetContent, (row) => {
                    const questions = _filter(
                        _map(questionColumns, ({ columnNumber }) => {
                            return getContentFromCell(row, columnNumber);
                        }),
                        meetsCriteria,
                    );
                    const answers = _filter(
                        _map(answerColumns, ({ columnNumber }) => {
                            return getContentFromCell(row, columnNumber);
                        }),
                        meetsCriteria,
                    );

                    return {
                        rowNumber: row.rowNumber,
                        isLinked: questions.length > 0 && answers.length > 0,
                    };
                }),
                'rowNumber',
            ),
            'isLinked',
        );
    };

    const setupRowInclusion = () => {
        if (!hasAnswerColumn || !hasQuestionColumn) {
            setSheetConfiguration({
                ...sheetConfiguration,
                rowInclusion: {},
            });
        } else {
            const classifiedColumns = sheetConfiguration.columnClassifications;
            const columnData = getColumnData(classifiedColumns, selectedSheet.sheetContent);

            setSheetConfiguration({
                ...sheetConfiguration,
                rowInclusion: columnData,
            });
        }
    };

    useEffect(() => {
        checkQuestionColumnSpecified();
        setFilterSelection('ALL');
    }, [sheetConfiguration]);

    useEffect(setupRowInclusion, [hasAnswerColumn, hasQuestionColumn]);

    const attributeLabelClasses = 'small-1-med mb-5 text-black-100 font-semibold';
    const attributeValueClasses = 'body-2-reg text-black-80';
    const headingClasses = 'sticky top-0 z-1 bg-white border-b border-gray-100';

    return (
        <>
            <div className="">
                <h3 className="my-4 text-12 font-bold uppercase">Spreadsheet Content</h3>
                <div className="grid grid-cols-3 gap-16 rounded-lg border border-gray-100 bg-gray-50 px-8 py-16">
                    <div>
                        <p className={attributeLabelClasses}>File Name</p>
                        <p className={attributeValueClasses}>{selectedFile.fileName || 'Copied into Pearler'} </p>
                    </div>
                    <div>
                        <p className={attributeLabelClasses}>Sheet Name</p>
                        <p className={attributeValueClasses}>{selectedSheet.sheetName}</p>
                    </div>
                    <div>
                        <p className={attributeLabelClasses}>Rows in {selectedFile.fileName ? 'file' : 'source'}</p>
                        <p className={attributeValueClasses}>{selectedSheet.sheetContent.length}</p>
                    </div>
                    <div>
                        <p className={attributeLabelClasses}>{questionCount} Answers selected</p>
                        <SimpleTextSelection
                            changeSelection={setFilterSelection}
                            value={filterSelection}
                            items={[
                                {
                                    label: 'All',
                                    value: 'ALL',
                                },
                                {
                                    label: 'Selected',
                                    value: 'SELECTED',
                                },
                                {
                                    label: 'Not Selected',
                                    value: 'NOT_SELECTED',
                                },
                            ]}
                        />
                    </div>
                    <div>
                        <p className={attributeLabelClasses}>Tag Import</p>
                        <p className={attributeValueClasses}>E.g. Security; Governance; Risk</p>
                    </div>
                    <div>
                        <p className={`${attributeValueClasses} text-right`}>
                            {columnConfigurationError && <span className="text-red">{columnConfigurationError}</span>}
                        </p>
                    </div>
                </div>
            </div>
            <table className="block overflow-auto" style={{ height: 'calc(100% - 165px)' }}>
                <thead>
                    {selectedSheet && (
                        <tr key="header-row" className={`'text-black'}`}>
                            <th className={`p-16 text-12 ${headingClasses}`}>Import</th>
                            {_times(selectedSheet.columnCount, (key) => {
                                return (
                                    <th key={key} className={`p-16 text-12 ${headingClasses} text-left`}>
                                        <select
                                            value={sheetConfiguration.columnClassifications[key]?.columnType || ''}
                                            onChange={(newValue) => {
                                                classifyColumn(key, newValue.target.value);
                                            }}
                                        >
                                            {classifications.map((label, key) => {
                                                return (
                                                    <option key={key} value={label}>
                                                        {label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </th>
                                );
                            })}
                        </tr>
                    )}
                </thead>
                <tbody className="">
                    {selectedSheet &&
                        selectedSheet.sheetContent
                            .filter((row) => {
                                if (filterSelection === 'ALL') {
                                    return true;
                                }

                                if (filterSelection === 'SELECTED') {
                                    return sheetConfiguration.rowInclusion[row.rowNumber];
                                }

                                if (filterSelection === 'NOT_SELECTED') {
                                    return !sheetConfiguration.rowInclusion[row.rowNumber];
                                }
                            })
                            .map((row) => {
                                return (
                                    <tr
                                        key={row.rowNumber}
                                        className={`overflow-hidden rounded-lg text-12 hover:bg-gray-50`}
                                        onClick={() => {
                                            if (hasAnswerColumn && hasQuestionColumn) {
                                                changeRowSelection(
                                                    row.rowNumber,
                                                    !sheetConfiguration.rowInclusion[row.rowNumber],
                                                );
                                            }
                                        }}
                                    >
                                        <td className="p-16">
                                            <input
                                                type="checkbox"
                                                checked={sheetConfiguration.rowInclusion[row.rowNumber] || false}
                                                value={'checked'}
                                                disabled={!hasAnswerColumn && !hasQuestionColumn}
                                            />
                                        </td>

                                        {_times(selectedSheet.columnCount, (key) => {
                                            const columnType =
                                                sheetConfiguration.columnClassifications[key]?.columnType;

                                            return (
                                                <td key={key} className="p-16 min-w-200 max-w-592">
                                                    {row.columns[key] && (
                                                        <>
                                                            {columnType === 'Tags' &&
                                                                sheetConfiguration.rowInclusion[row.rowNumber] && (
                                                                    <TagList tags={row.columns[key].content} />
                                                                )}
                                                            {columnType !== 'Tags' && (
                                                                <pre
                                                                    className={`overflow-hidden whitespace-pre-wrap font-body ${
                                                                        columnType !== 'Ignore' &&
                                                                        sheetConfiguration.rowInclusion[row.rowNumber]
                                                                            ? ''
                                                                            : 'text-gray-400'
                                                                    }`}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: row.columns[key].richTextContent,
                                                                    }}
                                                                />
                                                            )}
                                                        </>
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                </tbody>
            </table>
        </>
    );
};
