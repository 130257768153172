import React, { ReactElement } from 'react';
import { AnalyticsProps } from '../answer-analytics.modal';
import { SectionTitle } from './typography';
import { UsageCard } from './usage-card';

export function Usage(props: AnalyticsProps): ReactElement {
    const { usage } = props;

    return (
        <>
            <SectionTitle title="Usage" />
            <div className="mt-16">
                {usage.map((question, index) => {
                    return (
                        <UsageCard
                            question={question}
                            {...props}
                            key={question.questionId}
                            lastItem={index + 1 === usage.length}
                        />
                    );
                })}
            </div>
        </>
    );
}
