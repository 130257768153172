import { Modal } from 'components/duex/Modal';
import { useSocket } from 'hooks/useSocket';
import React, { ReactElement, useEffect, useState } from 'react';
import Collection from 'types/collection.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { LibraryImportFileUpload } from './import-file';
import { LibraryImporter } from './library-importer';

export const LibraryImporterModal = ({
    closeModal,
    collections,
}: {
    closeModal: () => void;
    collections: Collection[];
}): ReactElement => {
    const { latestMessage } = useSocket();
    const [showUploader, setShowUploader] = useState(true);
    const [currentAnswerSource, setCurrentAnswerSource] = useState(null);

    const fetchData = async () => {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_ANSWER_SOURCE, {
                answerSourceId: currentAnswerSource.answerSourceId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                setCurrentAnswerSource(results.data);
            }
        } catch (err) {}
    };

    const socketMessageReceived = () => {
        if (latestMessage && latestMessage.eventName && currentAnswerSource) {
            if (latestMessage.eventName === 'SOURCE_DOUMENT_UPDATED') {
                const sameFile = latestMessage?.data?.answerSourceId === currentAnswerSource.answerSourceId;

                if (sameFile) {
                    fetchData();
                }
            }
        }
    };

    useEffect(() => {
        socketMessageReceived();
    }, [latestMessage]);

    if (showUploader) {
        return (
            <Modal width="w-478" closeModal={closeModal} title="Import From File">
                <LibraryImportFileUpload
                    onComplete={(answerSource) => {
                        setShowUploader(false);
                        setCurrentAnswerSource(answerSource);
                    }}
                    closeModal={closeModal}
                />
            </Modal>
        );
    }

    return (
        <Modal
            width="w-95-percent"
            height="full"
            layout="flex-row"
            closeSafely={() => {
                return false;
            }}
            closeModal={closeModal}
            title="Library Importer"
        >
            <LibraryImporter
                currentAnswerSource={currentAnswerSource}
                collections={collections}
                fetchData={fetchData}
            />
        </Modal>
    );
};
