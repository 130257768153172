import { EmptyState } from 'components/empty-states.component';
import React, { ReactElement } from 'react';
import { ReactComponent as DiscussionImage } from '../../../assets/DiscussionImage.svg';

export const InitialDiscussionStatus = (): ReactElement => {
    return (
        <div className="flex flex-grow-2 items-center justify-center">
            <EmptyState
                placeholderImage={<DiscussionImage />}
                title="There's no discussion on this Question"
                subTitle="But this is where you can mention your team mates, and keep track of things."
            />
        </div>
    );
};
