import React, { ReactElement } from 'react';

export const AcceptTick = ({ strokeColor }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20 6L9 17L4 12"
                stroke={strokeColor || '#302D2D'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
