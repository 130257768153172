import React, { ReactElement, ReactNode, useEffect } from 'react';
import { H1Heading } from './Heading';

export const Module = ({
    moduleName,
    children,
    rootClassName = '',
    width = 'w-full',
    middleItem,
    rightItem,
}: {
    moduleName: string;
    children?: ReactNode;
    rootClassName?: string;
    width?: string;
    middleItem?: ReactNode;
    rightItem?: ReactNode;
}): ReactElement => {
    const maxHeight = 'calc(100vh - 96px)';

    useEffect(() => {
        document.title = `Pearler | ${moduleName}`;
    }, []);

    const gridTemplateColumns = middleItem ? `1fr 2fr 1fr` : `1fr 1fr`;

    return (
        <div className={`h-screen overflow-hidden ${rootClassName}`}>
            <div className="relative h-screen overflow-y-auto">
                <div className={`mx-auto ${width}`}>
                    <div className="grid py-24" style={{ gridTemplateColumns }}>
                        <H1Heading label={moduleName} className="ml-24" leading={'leading-48'} />
                        {middleItem && (
                            <div className={`flex flex-row items-center ${width === 'w-full' ? 'pl-8' : ''}`}>
                                {middleItem}
                            </div>
                        )}

                        <div className={`flex flex-row-reverse items-center ${width === 'w-full' ? 'pl-8' : ''}`}>
                            {rightItem}
                        </div>
                    </div>
                    <div
                        style={{
                            maxHeight,
                            height: maxHeight,
                        }}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
