import React, { ReactNode } from 'react';

export function CircleButton({
    children,
    className = '',
    onClick,
    disabled = false,
    title = '',
}: {
    children: ReactNode;
    className?: string;
    onClick: () => void;
    disabled: boolean;
    title: string;
}) {
    return (
        <button
            onClick={() => {
                if (!disabled) {
                    onClick();
                }
            }}
            disabled={disabled}
            title={title}
            className={`${
                disabled
                    ? 'opacity-50 text-gray-400'
                    : 'hover:text-white text-blue-500 hover:bg-blue-500 cursor-pointer'
            } bg-white h-48 w-48 flex items-center justify-center rounded-full shrink-0 ${className}`}
        >
            {children}
        </button>
    );
}
