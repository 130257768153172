import { LibraryCross } from 'assets/answer-library-cross.svg';
import { Search } from 'assets/magnify.svg';
import { EmptyState } from 'components/empty-states.component';
import { showError } from 'components/error-toast.component';
import { useDebounce } from 'hooks/useDebounce';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import { Answer } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { ReactComponent as PersonSearching } from '../../../../assets/person-searching.svg';
import { Loader } from '../../../../components/loader.component';
import { SuggestAnswersProps } from '../answering.view';
import { SuggestedAnswersListItem } from '../suggested-answers/suggested-answers-list-item.component';
import { suggestionDropownOptions } from '../suggested-answers/suggested-answers.component';

export const SearchAnswers = ({
    project,
    projectId,
    questionId,
    applyAnswer,
    hideAnswer,
    flagAnswer,
    undoHideAnswer,
    hiddenAnswers,
}: SuggestAnswersProps): ReactElement => {
    const [searching, setSearching] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 500);

    const reset = () => {
        setSearching(false);
        setAnswers([]);
        setSearchTerm('');
    };

    const performSearch = async () => {
        try {
            const started = new Date();
            setSearching(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.PROJECT_ANSWER_SEARCH, {
                questionId,
                projectId,
            });

            const response = await API.post(url, {
                searchQuery: searchTerm,
            });

            logEvent('PROJECT_ANSWER_SEARCH_ANSWERS', started, {
                projectId,
                questionId,
            });

            if (response && response.data) {
                setAnswers(response.data);
            }
            setSearching(false);
        } catch (err) {
            showError('There was a problem searching for answers', err);
            setSearching(false);
        }
    };

    const updateQuestion = async (updatedSuggestion: Answer) => {
        setAnswers(
            _map(answers, (suggestion) => {
                if (suggestion.answerId === updatedSuggestion.answerId) {
                    return updatedSuggestion;
                }

                return suggestion;
            }),
        );
    };

    useEffect(() => {
        if (debouncedSearchTerm.trim()) {
            performSearch();
        } else {
            reset();
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        reset();
    }, [projectId, questionId]);

    return (
        <div className="m-auto flex h-full flex-col py-24">
            <div className="mx-auto w-592" data-cy="searchbox">
                <div className={`w-full`} style={{ position: 'relative' }}>
                    <div className="pointer-events-none absolute inline justify-center stroke-current px-8 py-16 text-light-faded-blue">
                        <Search strokeColor={'#CDDBF4'} />
                    </div>
                    <input
                        data-cy="Answer_Library_Search_Box"
                        autoComplete="off"
                        className={`body-1-reg flex h-48 w-full appearance-none items-center rounded-lg border-none bg-white px-32 transition duration-150 ease-in-out focus:text-field-outline-blue focus:outline-none focus:outline-field-outline-blue focus:ring`}
                        placeholder={'Try "IP Traffic"'}
                        value={searchTerm}
                        maxLength={250}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSearchTerm(event.target.value);
                        }}
                    />
                    {searchTerm.trim() && (
                        <div
                            onClick={() => {
                                setSearchTerm('');
                            }}
                            className={`absolute inset-y-0 right-0 ml-8 select-none px-10 py-10 text-primary-blue-60 ${
                                searchTerm ? 'opacity-1 cursor-pointer' : 'pointer-events-none opacity-0'
                            }`}
                            data-cy="ClearButton"
                        >
                            <LibraryCross
                                width="30"
                                height="30"
                                strokeColorCross={'#FFFFFF'}
                                strokeColorCircle={'#CACCCF'}
                            />
                        </div>
                    )}
                </div>
            </div>
            {searching && <Loader />}

            {!searching && answers.length === 0 && (
                <div className="py-50">
                    <EmptyState
                        placeholderImage={<PersonSearching />}
                        title="Light-speed search"
                        subTitle="Our robots will find the answers you seek"
                    />
                </div>
            )}

            {!searching && answers.length > 0 && (
                <div className="flex flex-grow-2 flex-col justify-center">
                    {answers.length > 0 && (
                        <div>
                            <p className="tracking-0.2 m-auto mb-16 mt-14 text-center text-12 font-medium leading-16 text-heading-blue">
                                Showing {answers.length} {answers.length > 1 ? 'Results' : 'Result'}
                            </p>
                            <ul>
                                {answers.map((answer) => (
                                    <SuggestedAnswersListItem
                                        key={answer.answerId}
                                        answer={answer}
                                        suggestionDropownOptions={suggestionDropownOptions}
                                        applyAnswer={applyAnswer}
                                        hideAnswer={hideAnswer}
                                        flagAnswer={(answer: Answer, flaggedReason: string) => {
                                            flagAnswer(answer, flaggedReason);
                                            updateQuestion({
                                                ...answer,
                                                answerStatus: 'FLAGGED',
                                                flaggedReason,
                                            });
                                        }}
                                        undoHideAnswer={undoHideAnswer}
                                        projectStatus={project.projectStatus}
                                        hiddenAnswers={hiddenAnswers}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
