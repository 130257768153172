import { PersonProfile } from 'assets/person-profile.svg';
import { Button } from 'components/duex/Button';
import { FormTextField } from 'components/duex/FormTextField';
import { isValidStringOfLength } from 'components/validation/string';
import { useUserContext } from 'hooks/useUserContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { logEvent, trackLogin } from 'utility/Analytics';
import { API } from 'utility/Api';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { ERROR_MESSAGES } from 'utility/ErrorMessages';

export const CompleteRegistration = (): React.ReactElement => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [saving, setSaving] = useState(false);
    const navigate = useNavigate();
    const { restartApplication } = useUserContext();

    const urlParams = new URLSearchParams(window.location.search);
    const userEmail = decodeURIComponent(urlParams.get('email'));

    if (!userEmail) {
        navigate(ROUTES.LOGIN);
    }

    const completeRegistration = async () => {
        try {
            const started = new Date();
            setSaving(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.COMPLETE_REGISTRATION);
            const data = {
                givenName: firstName,
                lastName,
                emailAddress: userEmail.trim().toLowerCase(),
            };
            const result = await API.postWithoutAuth(url, data);

            if (result && result.data) {
                const { userId } = result.data;
                trackLogin(userId, result.data);
                logEvent('SIGNUP_FINISH_REGISTRATION', started, data);
                restartApplication();
            } else {
                setErrorMessage(ERROR_MESSAGES.COMPLETE_REGISTRATION_FAIL);
            }
            setSaving(false);
        } catch (err) {
            setErrorMessage(ERROR_MESSAGES.COMPLETE_REGISTRATION_FAIL);
            setSaving(false);
        }
    };

    useEffect(() => {
        document.title = 'Pearler | Create Account';
    }, []);

    const fieldsComplete = isValidStringOfLength(firstName, 3) && isValidStringOfLength(lastName, 3);

    return (
        <div className="grid gap-16">
            <h1 className="h3-bold select-none text-center text-primary-blue-80">Create Account</h1>

            {errorMessage && <p className={`subtitle-2-reg min-h-20 text-left text-red`}>{errorMessage}</p>}

            <div className="grid gap-16">
                <FormTextField
                    defaultValue={firstName}
                    validator={(newValue: string) => isValidStringOfLength(newValue, 3)}
                    label="First name"
                    valueChanged={setFirstName}
                    placeholder="John"
                    required={true}
                    maxLength={256}
                    errorMessage="Please provide a first name"
                    autoComplete="given-name"
                    image={<PersonProfile strokeColor="#CACCCF" />}
                />

                <FormTextField
                    defaultValue={lastName}
                    validator={(newValue: string) => isValidStringOfLength(newValue, 3)}
                    label="Last name"
                    valueChanged={setLastName}
                    placeholder="Citizen"
                    required={true}
                    maxLength={256}
                    errorMessage="Please provide a last name"
                    autoComplete="family-name"
                    image={<PersonProfile strokeColor="#CACCCF" />}
                />

                <Button
                    onClick={completeRegistration}
                    label="Complete Registration"
                    disabled={saving || !fieldsComplete}
                    loading={saving}
                    buttonType="LARGE_PRIMARY"
                    className="w-full"
                />
            </div>
        </div>
    );
};
