import React, { ReactNode } from 'react';

export const highlightString = (searchTerm: string, answer: string): ReactNode => {
    if (!searchTerm || !answer) {
        return answer;
    }

    const parts = answer.split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
        <span>
            {parts.map((part, index) =>
                part.toLowerCase() === searchTerm.toLowerCase() ? (
                    <span key={index} className="bg-dark-golden py-4 font-medium text-white">
                        {part}
                    </span>
                ) : (
                    part
                ),
            )}
        </span>
    );
};
