import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { TagList } from 'components/duex/TagList';
import { LIGHTYELLOW } from 'components/pill.component';
import { useUserContext } from 'hooks/useUserContext';
import _map from 'lodash/map';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { Answer } from 'types';
import { Label, SectionTitle, Value } from './typography';

export function SearchResult({ answer, showMore }: { answer: Answer; showMore: () => void }): ReactElement {
    const { getUser } = useUserContext();
    const author = getUser(answer?.createdByUserId);

    return (
        <div className="w-full rounded-md shadow-md">
            <div className="grid grid-cols-5 justify-between rounded-t-md bg-blue-50 p-16">
                <div className="col-span-3">
                    <Label title="Author" />
                    <div
                        className="grid items-center gap-8"
                        style={{
                            gridTemplateColumns: '32px auto',
                        }}
                    >
                        <Avatar userId={answer?.createdByUserId} size={32} />
                        <Value title={author.fullName || 'Unknown'} preventWrapping={true} />
                    </div>
                </div>
                <div>
                    <Label title="Created" />
                    <Value title={moment(answer?.createdAt).format('Do MMM YYYY')} />
                </div>
                <div className="flex flex-col justify-center">
                    <Button buttonType="TERTIARY" onClick={showMore} label="More Info" />
                </div>
            </div>
            {(answer.tags.length > 0 || answer.collections.length > 0) && (
                <div className="mx-8 mt-16 w-full flex-row flex">
                    {answer.collections.length > 0 && (
                        <TagList
                            tags={_map(answer.collections, ({ collection }) => {
                                return {
                                    tagLabel: collection.collectionName,
                                };
                            })}
                            colour={LIGHTYELLOW}
                        />
                    )}
                    {answer.tags.length > 0 && <TagList tags={answer.tags} />}
                </div>
            )}
            <div className="px-16">
                <SectionTitle title="Question" />
                <div className="my-16">
                    <p className="select-text whitespace-pre-line break-words">{answer.originalQuestion}</p>
                </div>
                <SectionTitle title="Answer" />
                <div className="my-16">
                    <div
                        className="select-text render-html-normally"
                        dangerouslySetInnerHTML={{
                            __html: answer.richTextAnswer,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
