import React, { ReactElement } from 'react';

export const Logo = (): ReactElement => {
    return (
        <svg width="231" height="53" viewBox="0 0 231 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M77.84 12.7996C81.9303 13.1612 85.971 15.3317 88.246 18.7963C90.5427 22.294 90.9205 27.0205 89.5953 30.952C89.0624 32.5641 88.1961 34.0459 87.0528 35.3011C85.9094 36.5562 84.5146 37.5566 82.9591 38.2372C81.3434 38.9184 79.6063 39.265 77.8528 39.2562C74.1615 39.2562 70.4344 38.0478 68.7589 36.1367L68.8106 48.5857C68.8109 48.6669 68.7952 48.7473 68.7644 48.8223C68.7336 48.8974 68.6882 48.9656 68.631 49.0231C68.5737 49.0806 68.5057 49.1262 68.4308 49.1573C68.3558 49.1885 68.2755 49.2045 68.1944 49.2045H63.4049C63.293 49.2045 63.1857 49.1601 63.1065 49.081C63.0273 49.0019 62.9828 48.8946 62.9827 48.7827C62.9812 47.2955 62.9798 45.8082 62.9784 44.3209C62.9754 41.1232 62.9726 37.9254 62.97 34.7277C62.9677 31.709 62.9815 28.6898 62.9645 25.6711C62.9399 21.2778 65.4952 16.9548 69.2425 14.7186C71.837 13.1952 74.844 12.5241 77.84 12.7996V12.7996ZM75.2554 33.5889C80.5394 34.8976 85.2435 30.0386 84.2827 24.6208C83.6464 21.0326 80.8772 18.4692 77.1204 18.4692C72.852 18.4692 69.7912 21.8787 69.7912 26.2259C69.7913 29.8814 72.0043 32.7837 75.2554 33.5889Z"
                fill="white"
            />
            <path
                d="M116.599 36.4047C116.625 36.5491 116.605 36.698 116.541 36.8302C116.477 36.9624 116.373 37.071 116.244 37.1406C113.442 38.5514 110.345 39.2764 107.207 39.2558C98.3062 39.2558 92.5991 33.9937 92.5991 26.1659C92.5991 18.4691 98.0444 13.076 105.506 13.076C113.229 13.076 119.093 18.888 117.444 28.1818H99.2748C99.9555 31.6899 103.097 33.3654 107.233 33.3654C109.878 33.3646 112.496 32.8375 114.934 31.8148C115.031 31.7738 115.136 31.7553 115.241 31.7606C115.347 31.7659 115.449 31.795 115.542 31.8455C115.634 31.896 115.714 31.9668 115.775 32.0525C115.836 32.1382 115.877 32.2366 115.895 32.3404L116.599 36.4047ZM111.422 23.6789C111.239 20.8776 109.119 18.731 105.663 18.731C102.076 18.731 99.6676 20.8254 99.3796 23.6789H111.422Z"
                fill="white"
            />
            <path
                d="M147.333 25.4853L147.322 38.7324H141.155C141.046 38.7323 140.941 38.6903 140.863 38.615C140.784 38.5398 140.738 38.4371 140.733 38.3283L140.62 37.0989C138.182 38.5924 136.326 39.2559 132.425 39.2559C125.514 39.2559 120.114 33.8216 120.114 26.2033C120.114 20.3807 123.972 14.846 129.596 13.1479C135.529 11.3565 142.388 13.8583 145.593 19.2043C146.731 21.102 147.332 23.2728 147.333 25.4853V25.4853ZM134.025 18.4628C129.6 18.3039 126.607 21.748 126.689 26.29C126.772 30.8828 130.608 34.5382 134.96 33.7991C138.797 33.1476 141.308 29.4592 140.827 25.032C140.434 21.419 137.514 18.5882 134.025 18.4628Z"
                fill="white"
            />
            <path
                d="M158.672 16.3359C159.955 13.6343 162.39 12.9806 165.217 12.9806C166.219 12.99 167.214 13.1532 168.167 13.4647C168.26 13.4958 168.34 13.5577 168.393 13.6403C168.447 13.7229 168.47 13.8212 168.46 13.9191L168.012 19.2169C168.009 19.2799 167.992 19.3414 167.961 19.3967C167.931 19.452 167.889 19.4998 167.837 19.5363C167.786 19.5729 167.727 19.5973 167.665 19.6078C167.602 19.6183 167.539 19.6145 167.478 19.5968C166.629 19.3199 165.743 19.175 164.851 19.1674C161.892 19.1674 158.777 20.0912 158.777 25.4404V38.2967C158.778 38.4104 158.735 38.5202 158.656 38.6019C158.577 38.6836 158.468 38.7306 158.355 38.7324H152.471C152.357 38.7306 152.248 38.6837 152.169 38.6019C152.09 38.5202 152.047 38.4105 152.048 38.2967V14.6807C152.047 14.5669 152.09 14.4571 152.169 14.3754C152.248 14.2937 152.357 14.2468 152.471 14.245H158.135C158.245 14.2467 158.351 14.2913 158.43 14.3694C158.509 14.4476 158.554 14.5532 158.556 14.664L158.672 16.3359Z"
                fill="white"
            />
            <path
                d="M216.346 16.3359C217.629 13.6343 220.064 12.9806 222.891 12.9806C223.893 12.99 224.888 13.1532 225.841 13.4647C225.934 13.4958 226.014 13.5577 226.068 13.6403C226.121 13.7229 226.145 13.8212 226.135 13.9191L225.686 19.2169C225.683 19.2799 225.666 19.3414 225.635 19.3967C225.605 19.452 225.563 19.4997 225.511 19.5363C225.46 19.5729 225.401 19.5973 225.339 19.6078C225.277 19.6183 225.213 19.6145 225.152 19.5968C224.304 19.3199 223.417 19.175 222.525 19.1674C219.566 19.1674 216.451 20.0912 216.451 25.4404V38.2967C216.453 38.4105 216.409 38.5202 216.33 38.6019C216.251 38.6837 216.143 38.7306 216.029 38.7324H210.145C210.031 38.7306 209.923 38.6837 209.844 38.6019C209.764 38.5202 209.721 38.4105 209.723 38.2967V14.6807C209.721 14.5669 209.764 14.4571 209.844 14.3754C209.923 14.2937 210.031 14.2468 210.145 14.245H215.809C215.92 14.2467 216.026 14.2913 216.104 14.3694C216.183 14.4476 216.228 14.5532 216.231 14.664L216.346 16.3359Z"
                fill="white"
            />
            <path
                d="M177.931 38.7324H171.465C171.405 38.7365 171.344 38.7281 171.288 38.7077C171.231 38.6872 171.179 38.6552 171.135 38.6136C171.091 38.572 171.057 38.5218 171.034 38.4661C171.01 38.4105 170.999 38.3506 171 38.2902L171.013 3.38214C171.031 3.27304 171.088 3.1742 171.174 3.10471C171.259 3.03522 171.368 3.00002 171.478 3.00591H177.945C178.005 3.00177 178.065 3.01019 178.122 3.03064C178.179 3.05109 178.231 3.08312 178.274 3.1247C178.318 3.16628 178.353 3.2165 178.376 3.27216C178.399 3.32783 178.411 3.38772 178.41 3.44804L178.396 38.3562C178.379 38.4653 178.322 38.5641 178.236 38.6336C178.15 38.7031 178.042 38.7383 177.931 38.7324Z"
                fill="white"
            />
            <path
                d="M204.798 36.4047C204.824 36.5491 204.803 36.698 204.74 36.8302C204.676 36.9624 204.572 37.071 204.443 37.1406C201.641 38.5514 198.543 39.2764 195.406 39.2558C186.505 39.2558 180.798 33.9937 180.798 26.1659C180.798 18.4691 186.243 13.076 193.704 13.076C201.428 13.076 207.292 18.888 205.642 28.1818H187.474C188.154 31.6899 191.296 33.3654 195.432 33.3654C198.077 33.3646 200.694 32.8375 203.133 31.8148C203.23 31.7738 203.335 31.7553 203.44 31.7606C203.545 31.7659 203.648 31.795 203.74 31.8455C203.833 31.896 203.913 31.9668 203.974 32.0525C204.035 32.1382 204.076 32.2366 204.094 32.3404L204.798 36.4047ZM199.621 23.6789C199.438 20.8776 197.317 18.731 193.862 18.731C190.275 18.731 187.866 20.8254 187.578 23.6789H199.621Z"
                fill="white"
            />
            <path
                d="M227.177 31.5592C227.937 31.5643 228.679 31.7944 229.308 32.2204C229.938 32.6464 230.427 33.2493 230.714 33.953C231.002 34.6567 231.074 35.4297 230.923 36.1745C230.771 36.9194 230.403 37.6027 229.864 38.1383C229.324 38.674 228.638 39.0379 227.893 39.1843C227.147 39.3307 226.374 39.253 225.673 38.9609C224.971 38.6688 224.371 38.1755 223.95 37.5431C223.528 36.9108 223.303 36.1677 223.303 35.4076C223.301 34.8995 223.4 34.396 223.594 33.9264C223.788 33.4569 224.074 33.0307 224.435 32.6726C224.795 32.3144 225.223 32.0316 225.694 31.8405C226.165 31.6493 226.669 31.5537 227.177 31.5592V31.5592Z"
                fill="white"
            />
            <path
                d="M44.1988 13.9317L43.3887 12.6094L23.656 24.7028V1.55908H22.1048V24.6972L2.41951 12.5377L1.60432 13.8573L21.4003 26.0853L1.56152 38.2437L2.3719 39.5659L22.1048 27.4723V50.6163H23.656V27.4784L43.3413 39.6379L44.1565 38.3183L24.36 26.0904L44.1988 13.9317Z"
                fill="white"
            />
            <path
                d="M22.9024 19.0098C26.9747 19.0098 30.1259 22.1609 30.1259 26.1363C30.1259 30.1601 26.9747 33.2628 22.9024 33.2628C18.8301 33.2628 15.7274 30.1601 15.7274 26.1363C15.7181 25.1937 15.8976 24.2588 16.2555 23.3868C16.6134 22.5148 17.1423 21.7233 17.8111 21.059C18.4799 20.3947 19.275 19.8712 20.1494 19.5192C21.0238 19.1673 21.9599 18.994 22.9024 19.0098Z"
                fill="white"
            />
            <path
                d="M34.7436 14.1843C35.6146 14.1903 36.4643 14.454 37.1856 14.9422C37.9069 15.4304 38.4675 16.1212 38.7967 16.9276C39.1259 17.734 39.2089 18.6198 39.0354 19.4733C38.8619 20.3268 38.4395 21.1098 37.8215 21.7236C37.2035 22.3374 36.4176 22.7545 35.563 22.9222C34.7083 23.09 33.8231 23.0009 33.0189 22.6663C32.2148 22.3316 31.5278 21.7663 31.0445 21.0417C30.5612 20.3171 30.3033 19.4656 30.3033 18.5946C30.3009 18.0123 30.4144 17.4353 30.6371 16.8972C30.8598 16.3591 31.1873 15.8706 31.6005 15.4603C32.0137 15.0499 32.5043 14.7257 33.0439 14.5067C33.5835 14.2876 34.1613 14.1781 34.7436 14.1843V14.1843Z"
                fill="white"
            />
            <path
                d="M11.0202 14.4549C11.6045 14.4468 12.1846 14.5548 12.7268 14.7728C13.2691 14.9908 13.7625 15.3143 14.1786 15.7247C14.5947 16.135 14.9251 16.624 15.1507 17.1631C15.3762 17.7022 15.4923 18.2808 15.4923 18.8652C15.4923 19.4496 15.3762 20.0281 15.1507 20.5673C14.9251 21.1064 14.5947 21.5953 14.1786 22.0057C13.7625 22.416 13.2691 22.7396 12.7268 22.9576C12.1846 23.1756 11.6045 23.2836 11.0202 23.2755C9.85047 23.2755 8.7287 22.8108 7.90161 21.9837C7.07452 21.1566 6.60986 20.0349 6.60986 18.8652C6.60986 17.6955 7.07452 16.5737 7.90161 15.7466C8.7287 14.9195 9.85047 14.4549 11.0202 14.4549V14.4549Z"
                fill="white"
            />
            <path
                d="M22.8925 7.81556C23.7635 7.82149 24.6132 8.08519 25.3345 8.57339C26.0558 9.06159 26.6164 9.75244 26.9456 10.5588C27.2748 11.3652 27.3579 12.251 27.1843 13.1045C27.0108 13.958 26.5884 14.7411 25.9704 15.3549C25.3524 15.9687 24.5666 16.3857 23.7119 16.5535C22.8572 16.7212 21.972 16.6321 21.1679 16.2975C20.3637 15.9628 19.6767 15.3975 19.1934 14.6729C18.7101 13.9483 18.4522 13.0968 18.4522 12.2259C18.4499 11.6435 18.5634 11.0665 18.7861 10.5284C19.0088 9.99032 19.3362 9.50187 19.7494 9.09148C20.1626 8.6811 20.6532 8.35695 21.1928 8.13791C21.7324 7.91887 22.3102 7.80929 22.8925 7.81556V7.81556Z"
                fill="white"
            />
            <path
                d="M4.09236 22.9804C4.51033 22.9748 4.92525 23.0523 5.31302 23.2084C5.70079 23.3645 6.0537 23.5961 6.35124 23.8897C6.64878 24.1833 6.88504 24.5331 7.04629 24.9187C7.20755 25.3044 7.29058 25.7182 7.29058 26.1362C7.29058 26.5542 7.20755 26.9681 7.04629 27.3537C6.88504 27.7394 6.64878 28.0892 6.35124 28.3828C6.0537 28.6764 5.70079 28.908 5.31302 29.064C4.92525 29.2201 4.51033 29.2977 4.09236 29.2921C3.67588 29.2953 3.26289 29.216 2.87719 29.0589C2.49149 28.9017 2.14071 28.6698 1.84507 28.3764C1.54944 28.083 1.3148 27.734 1.15467 27.3496C0.994548 26.9651 0.912109 26.5527 0.912109 26.1362C0.912109 25.7197 0.994548 25.3074 1.15467 24.9229C1.3148 24.5384 1.54944 24.1894 1.84507 23.8961C2.14071 23.6027 2.49149 23.3708 2.87719 23.2136C3.26289 23.0564 3.67588 22.9772 4.09236 22.9804V22.9804Z"
                fill="white"
            />
            <path
                d="M12.8351 5.72137C13.4583 5.7256 14.0664 5.91428 14.5826 6.26362C15.0987 6.61295 15.4999 7.10731 15.7355 7.68434C15.9711 8.26137 16.0305 8.89524 15.9063 9.50602C15.7822 10.1168 15.4799 10.6771 15.0377 11.1164C14.5955 11.5556 14.0331 11.854 13.4215 11.9741C12.8099 12.0941 12.1765 12.0304 11.6011 11.7909C11.0256 11.5514 10.534 11.1469 10.1882 10.6284C9.84232 10.1099 9.65775 9.50058 9.65774 8.87731C9.65607 8.46059 9.73727 8.0477 9.89662 7.66265C10.056 7.2776 10.2903 6.92807 10.5859 6.63441C10.8816 6.34074 11.2327 6.10878 11.6188 5.95204C12.0049 5.79529 12.4184 5.71689 12.8351 5.72137V5.72137Z"
                fill="white"
            />
            <path
                d="M2.77416 11.0779C3.13921 11.0731 3.50157 11.1408 3.84023 11.2771C4.17889 11.4135 4.48709 11.6158 4.74694 11.8722C5.00679 12.1287 5.21311 12.4342 5.35393 12.771C5.49476 13.1078 5.56727 13.4692 5.56727 13.8343C5.56727 14.1994 5.49476 14.5608 5.35393 14.8977C5.21311 15.2345 5.00679 15.54 4.74694 15.7964C4.48709 16.0529 4.17889 16.2551 3.84023 16.3915C3.50157 16.5279 3.13921 16.5956 2.77416 16.5908C2.41073 16.593 2.05045 16.5234 1.71403 16.3859C1.37762 16.2484 1.07171 16.0457 0.813929 15.7895C0.556143 15.5333 0.351563 15.2287 0.21196 14.8932C0.0723575 14.5576 0.000488281 14.1978 0.000488281 13.8343C0.000488281 13.4709 0.0723575 13.111 0.21196 12.7755C0.351563 12.4399 0.556143 12.1353 0.813929 11.8791C1.07171 11.6229 1.37762 11.4203 1.71403 11.2827C2.05045 11.1452 2.41073 11.0756 2.77416 11.0779V11.0779Z"
                fill="white"
            />
            <path
                d="M22.9819 0.000424861C23.3474 -0.00600581 23.7105 0.0606216 24.0499 0.196399C24.3894 0.332176 24.6982 0.534368 24.9585 0.791116C25.2187 1.04786 25.4251 1.35399 25.5654 1.69155C25.7058 2.02911 25.7773 2.3913 25.7758 2.75687C25.7683 3.49043 25.4716 4.19137 24.9502 4.70743C24.4288 5.22349 23.7248 5.51296 22.9912 5.51296C22.2577 5.51296 21.5537 5.22349 21.0323 4.70743C20.5109 4.19137 20.2142 3.49043 20.2067 2.75687C20.2031 2.3923 20.2725 2.0307 20.4109 1.69341C20.5494 1.35612 20.754 1.04998 21.0126 0.793048C21.2713 0.53612 21.5788 0.333613 21.917 0.197478C22.2553 0.0613442 22.6173 -0.00565844 22.9819 0.000424861V0.000424861Z"
                fill="white"
            />
            <path
                d="M43.0223 11.0778C43.5667 11.0815 44.0978 11.2463 44.5486 11.5514C44.9994 11.8565 45.3498 12.2883 45.5556 12.7923C45.7614 13.2963 45.8133 13.85 45.7048 14.3834C45.5964 14.9169 45.3324 15.4063 44.9461 15.79C44.5599 16.1736 44.0687 16.4343 43.5345 16.5391C43.0003 16.644 42.447 16.5883 41.9444 16.3791C41.4418 16.1699 41.0124 15.8166 40.7104 15.3637C40.4083 14.9108 40.2471 14.3786 40.2471 13.8342C40.2456 13.4703 40.3166 13.1096 40.4558 12.7733C40.5949 12.437 40.7996 12.1317 41.0579 11.8753C41.3161 11.6188 41.6227 11.4162 41.96 11.2793C42.2972 11.1424 42.6583 11.0739 43.0223 11.0778V11.0778Z"
                fill="white"
            />
            <path
                d="M32.9591 5.61322C33.5824 5.61744 34.1904 5.80612 34.7066 6.15546C35.2227 6.5048 35.6239 6.99915 35.8595 7.57618C36.0951 8.15322 36.1545 8.78709 36.0304 9.39786C35.9062 10.0086 35.6039 10.569 35.1617 11.0082C34.7195 11.4474 34.1572 11.7459 33.5456 11.8659C32.934 11.986 32.3005 11.9222 31.7251 11.6828C31.1497 11.4433 30.658 11.0388 30.3122 10.5203C29.9663 10.0017 29.7818 9.39243 29.7818 8.76916C29.7801 8.35244 29.8613 7.93955 30.0206 7.5545C30.18 7.16945 30.4143 6.81993 30.71 6.52626C31.0056 6.23259 31.3567 6.00064 31.7429 5.84389C32.129 5.68715 32.5424 5.60873 32.9591 5.61322V5.61322Z"
                fill="white"
            />
            <path
                d="M41.9274 22.9804C42.5506 22.9846 43.1587 23.1733 43.6748 23.5226C44.191 23.872 44.5922 24.3663 44.8278 24.9434C45.0634 25.5204 45.1228 26.1543 44.9986 26.765C44.8745 27.3758 44.5722 27.9362 44.13 28.3754C43.6878 28.8146 43.1254 29.1131 42.5138 29.2331C41.9022 29.3532 41.2688 29.2894 40.6933 29.05C40.1179 28.8105 39.6263 28.406 39.2804 27.8874C38.9346 27.3689 38.75 26.7596 38.75 26.1363C38.7483 25.7196 38.8295 25.3067 38.9889 24.9217C39.1482 24.5366 39.3826 24.1871 39.6782 23.8934C39.9739 23.5998 40.325 23.3678 40.7111 23.2111C41.0972 23.0543 41.5107 22.9759 41.9274 22.9804V22.9804Z"
                fill="white"
            />
            <path
                d="M11.0718 38.0885C10.4874 38.0966 9.9073 37.9885 9.36508 37.7706C8.82287 37.5526 8.32937 37.229 7.91328 36.8187C7.49718 36.4083 7.16678 35.9194 6.94126 35.3803C6.71574 34.8411 6.59961 34.2626 6.59961 33.6782C6.59961 33.0938 6.71574 32.5152 6.94126 31.9761C7.16678 31.437 7.49718 30.948 7.91328 30.5377C8.32937 30.1273 8.82287 29.8038 9.36508 29.5858C9.9073 29.3678 10.4874 29.2597 11.0718 29.2679C11.6509 29.2679 12.2244 29.382 12.7595 29.6036C13.2946 29.8252 13.7808 30.1501 14.1903 30.5596C14.5998 30.9692 14.9247 31.4553 15.1463 31.9904C15.368 32.5255 15.4821 33.099 15.4821 33.6782C15.4821 34.2573 15.368 34.8308 15.1463 35.3659C14.9247 35.901 14.5998 36.3872 14.1903 36.7967C13.7808 37.2063 13.2946 37.5311 12.7595 37.7527C12.2244 37.9744 11.6509 38.0885 11.0718 38.0885Z"
                fill="white"
            />
            <path
                d="M34.7948 37.8179C33.9239 37.812 33.0741 37.5483 32.3528 37.0601C31.6315 36.5719 31.0709 35.8811 30.7417 35.0747C30.4125 34.2683 30.3294 33.3825 30.503 32.529C30.6765 31.6754 31.0989 30.8924 31.7169 30.2786C32.3349 29.6648 33.1207 29.2478 33.9754 29.08C34.8301 28.9123 35.7153 29.0013 36.5194 29.336C37.3236 29.6707 38.0106 30.2359 38.4939 30.9605C38.9772 31.6852 39.2351 32.5366 39.2351 33.4076C39.2375 33.99 39.124 34.567 38.9013 35.1051C38.6786 35.6432 38.3511 36.1316 37.9379 36.542C37.5247 36.9524 37.0341 37.2765 36.4945 37.4956C35.9549 37.7146 35.3772 37.8242 34.7948 37.8179V37.8179Z"
                fill="white"
            />
            <path
                d="M22.9228 44.4573C22.0518 44.4514 21.2021 44.1877 20.4808 43.6995C19.7595 43.2113 19.1989 42.5205 18.8696 41.7141C18.5404 40.9077 18.4574 40.0219 18.6309 39.1684C18.8045 38.3149 19.2268 37.5318 19.8448 36.918C20.4628 36.3042 21.2487 35.8872 22.1033 35.7194C22.958 35.5517 23.8432 35.6407 24.6474 35.9754C25.4515 36.3101 26.1385 36.8753 26.6218 37.6C27.1051 38.3246 27.363 39.176 27.3631 40.047C27.3654 40.6294 27.2519 41.2064 27.0292 41.7445C26.8065 42.2826 26.479 42.771 26.0658 43.1814C25.6527 43.5918 25.162 43.9159 24.6224 44.135C24.0828 44.354 23.5051 44.4636 22.9228 44.4573Z"
                fill="white"
            />
            <path
                d="M32.9802 46.5514C32.357 46.5472 31.7489 46.3585 31.2328 46.0092C30.7166 45.6599 30.3154 45.1655 30.0798 44.5885C29.8443 44.0114 29.7848 43.3776 29.909 42.7668C30.0332 42.156 30.3354 41.5957 30.7776 41.1565C31.2198 40.7172 31.7822 40.4188 32.3938 40.2987C33.0054 40.1787 33.6388 40.2424 34.2143 40.4819C34.7897 40.7214 35.2813 41.1259 35.6272 41.6444C35.973 42.1629 36.1576 42.7722 36.1576 43.3955C36.1593 43.8122 36.0781 44.2251 35.9187 44.6102C35.7594 44.9952 35.525 45.3447 35.2294 45.6384C34.9337 45.9321 34.5826 46.164 34.1965 46.3208C33.8104 46.4775 33.3969 46.5559 32.9802 46.5514V46.5514Z"
                fill="white"
            />
            <path
                d="M43.041 41.1951C42.4966 41.1914 41.9656 41.0266 41.5147 40.7214C41.0639 40.4163 40.7135 39.9845 40.5077 39.4805C40.3019 38.9765 40.25 38.4229 40.3585 37.8894C40.467 37.3559 40.731 36.8665 41.1172 36.4829C41.5035 36.0992 41.9947 35.8386 42.5289 35.7337C43.0631 35.6289 43.6164 35.6846 44.119 35.8938C44.6215 36.103 45.0509 36.4563 45.353 36.9092C45.6551 37.3621 45.8162 37.8943 45.8162 38.4387C45.8177 38.8027 45.7467 39.1633 45.6075 39.4996C45.4683 39.8359 45.2637 40.1411 45.0054 40.3976C44.7472 40.6541 44.4405 40.8567 44.1033 40.9936C43.7661 41.1305 43.405 41.199 43.041 41.1951V41.1951Z"
                fill="white"
            />
            <path
                d="M22.8335 52.2726C22.2891 52.2689 21.758 52.1041 21.3072 51.799C20.8564 51.4938 20.506 51.062 20.3002 50.558C20.0944 50.054 20.0425 49.5004 20.151 48.9669C20.2594 48.4335 20.5234 47.944 20.9097 47.5604C21.2959 47.1768 21.7871 46.9161 22.3213 46.8113C22.8555 46.7064 23.4088 46.7621 23.9114 46.9713C24.414 47.1804 24.8434 47.5338 25.1454 47.9867C25.4475 48.4396 25.6087 48.9717 25.6087 49.5161C25.6101 49.8801 25.5392 50.2407 25.4 50.577C25.2608 50.9133 25.0562 51.2186 24.7979 51.4751C24.5397 51.7316 24.233 51.9342 23.8958 52.0711C23.5586 52.208 23.1975 52.2765 22.8335 52.2726V52.2726Z"
                fill="white"
            />
            <path
                d="M2.79311 41.195C2.42807 41.1998 2.0657 41.1321 1.72704 40.9957C1.38838 40.8594 1.08019 40.6571 0.820337 40.4007C0.560487 40.1442 0.354162 39.8387 0.213339 39.5019C0.0725163 39.1651 0 38.8036 0 38.4386C0 38.0735 0.0725163 37.712 0.213339 37.3752C0.354162 37.0384 0.560487 36.7329 0.820337 36.4765C1.08019 36.22 1.38838 36.0178 1.72704 35.8814C2.0657 35.745 2.42807 35.6773 2.79311 35.6821C3.15654 35.6799 3.51683 35.7495 3.85324 35.887C4.18966 36.0245 4.49556 36.2272 4.75334 36.4834C5.01113 36.7396 5.21571 37.0442 5.35531 37.3797C5.49492 37.7153 5.56679 38.0751 5.56679 38.4386C5.56679 38.802 5.49492 39.1618 5.35531 39.4974C5.21571 39.833 5.01113 40.1376 4.75334 40.3938C4.49556 40.65 4.18966 40.8526 3.85324 40.9901C3.51683 41.1277 3.15654 41.1973 2.79311 41.195Z"
                fill="white"
            />
            <path
                d="M12.8562 46.6597C12.233 46.6555 11.6249 46.4668 11.1087 46.1175C10.5926 45.7681 10.1914 45.2738 9.95582 44.6967C9.72023 44.1197 9.66077 43.4858 9.78495 42.8751C9.90913 42.2643 10.2114 41.704 10.6536 41.2647C11.0958 40.8255 11.6582 40.527 12.2698 40.407C12.8814 40.2869 13.5148 40.3507 14.0902 40.5902C14.6657 40.8296 15.1573 41.2341 15.5031 41.7527C15.849 42.2712 16.0335 42.8805 16.0336 43.5038C16.0352 43.9205 15.954 44.3334 15.7947 44.7184C15.6353 45.1035 15.401 45.453 15.1054 45.7467C14.8097 46.0404 14.4586 46.2723 14.0725 46.4291C13.6864 46.5858 13.2729 46.6642 12.8562 46.6597Z"
                fill="white"
            />
        </svg>
    );
};
