import React, { ReactElement } from 'react';

export const CheckShield = ({ strokeColor = 'currentColor' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 11.7498L9.25 13.9998L13 8.74985M10 1.71411C7.8495 3.75073 4.94563 4.99986 1.75 4.99986C1.69922 4.99986 1.64852 4.99955 1.59789 4.99892C1.2099 6.17903 1 7.43995 1 8.74991C1 14.3414 4.82432 19.0397 10 20.3719C15.1757 19.0397 19 14.3414 19 8.74991C19 7.43995 18.7901 6.17903 18.4021 4.99892C18.3515 4.99955 18.3008 4.99986 18.25 4.99986C15.0544 4.99986 12.1505 3.75073 10 1.71411Z"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
