import React, { ReactElement } from 'react';

export const RightArrow = ({ strokeColor, className }: { strokeColor: string; className?: string }): ReactElement => {
    return (
        <svg
            className={`inline ${className}`}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.16699 10H15.8337"
                stroke={strokeColor || '#A0A4A8'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10 4.16699L15.8333 10.0003L10 15.8337"
                stroke={strokeColor || '#A0A4A8'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
