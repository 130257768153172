import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ConfirmationDialog } from 'components/duex/ConfirmationDialog';
import { EmptyState } from 'components/duex/EmptyState';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { InformationNotice } from 'components/duex/Notice';
import { showError } from 'components/error-toast.component';
import { Col, ColHeader, Row } from 'components/table';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { FileUploadModal } from 'modules/file-upload/file-upload-modal.view';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const Documents = ({
    projectId,
    questionId,
    className = '',
    showHeading = true,
}: {
    projectId: string;
    questionId?: string;
    className?: string;
    showHeading?: boolean;
}): ReactElement => {
    const [attachments, setAttachments] = useState([]);
    const [filteredLibrary, setFilteredLibrary] = useState([]);
    const [library, setLibrary] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [showingAttachModal, setShowingAttachModal] = useState(false);
    const [showRemoveModal, setShowingRemoveModal] = useState('');
    const [removeUsageCount, setRemoveUsageCount] = useState(0);

    const fetchAttachedDocuments = async (initialLoad = true) => {
        try {
            setFetchingData(initialLoad);

            let url = ENDPOINTS.getUrl(CONSTANTS.GET_DOC_PROJECT, {
                projectId,
            });

            if (questionId) {
                url = ENDPOINTS.getUrl(CONSTANTS.GET_DOC_QUESTION, {
                    projectId,
                    questionId,
                });
            }

            const results = await API.get(url);
            if (results && results.data) {
                setAttachments(results.data);
            }

            setFetchingData(false);
        } catch (err) {
            showError('There was an error retrieving attached documents', err);
            setFetchingData(false);
        }
    };

    const fetchLibraryOfDocuments = async (initialLoad = true) => {
        try {
            setFetchingData(initialLoad);

            const results = await API.get(ENDPOINTS.getUrl(CONSTANTS.GET_DOC_LIBRARY));
            if (results && results.data) {
                setLibrary(results.data);
            }
            setFetchingData(false);
        } catch (err) {
            showError('There was an error retrieving your document library', err);
            setFetchingData(false);
        }
    };

    const removeDocumentFromQuestion = async (identifier: string) => {
        try {
            const started = new Date();
            let url = ENDPOINTS.getUrl(CONSTANTS.REMOVE_DOC_PROJECT, {
                projectId,
            });

            if (questionId) {
                url = ENDPOINTS.getUrl(CONSTANTS.REMOVE_DOC_QUESTION, {
                    projectId,
                    questionId,
                });
            }

            const results = await API.post(url, {
                documentId: identifier,
            });

            logEvent(`PROJECT_DOCUMENTS_DETACH_DOCUMENT`, started, {
                projectId,
                questionId,
                questionDocumentId: identifier,
                documentId: identifier,
            });

            if (results && results.data) {
                fetchAttachedDocuments();
            }
        } catch (err) {
            showError('There was an error removing that document', err);
        }
    };

    const addDocumentToQuestion = async (documentId: string) => {
        try {
            const started = new Date();
            let url = ENDPOINTS.getUrl(CONSTANTS.ADD_DOC_PROJECT, {
                projectId,
            });

            if (questionId) {
                url = ENDPOINTS.getUrl(CONSTANTS.ADD_DOC_QUESTION, {
                    projectId,
                    questionId,
                });
            }

            const results = await API.post(url, {
                documentId,
            });

            logEvent(`PROJECT_DOCUMENTS_ATTACH_DOCUMENT`, started, {
                projectId,
                questionId,
                documentId,
            });

            if (results && results.data) {
                fetchAttachedDocuments();
            }
        } catch (err) {
            showError('There was an error attaching that document', err);
        }
    };

    useEffect(() => {
        fetchAttachedDocuments(true);
        fetchLibraryOfDocuments(true);
    }, [questionId, projectId]);

    useEffect(() => {
        setFilteredLibrary(
            _filter(library, (item) => {
                const attached = _find(attachments, {
                    document: {
                        documentId: item.documentId,
                    },
                });

                return !attached;
            }),
        );
    }, [library, attachments]);

    const externalLinks = _filter(attachments, ({ document }) => {
        return !document.storeFile;
    });

    return (
        <div className={`m-auto mx-auto flex flex-col ${className}`}>
            <div className="flex flex-grow-2 flex-col">
                <div className="flex flex-row-reverse justify-between py-16">
                    <Button
                        onClick={() => {
                            setShowingAttachModal(true);
                        }}
                        label="Add Document"
                    />
                    {showHeading && <h2 className="text-20 text-black-80">Documents</h2>}
                </div>
                {fetchingData && <LoadingSpinner className="pt-32" />}
                {!fetchingData && attachments.length === 0 && filteredLibrary.length === 0 && (
                    <EmptyState
                        className="pt-64"
                        transparent={true}
                        title="There are no files attached to this question"
                        subtitle="You can attach documents and files by clicking on the Upload File button above."
                    />
                )}
                {externalLinks.length > 0 && (
                    <InformationNotice
                        className="mb-16"
                        title="Trust Centre"
                        description="Some of the attached documents are links which are not available for download in the Trust Centre."
                    />
                )}
                {attachments.length > 0 && (
                    <>
                        <h2 className="pb-16 pt-8 text-sm font-medium uppercase text-gray-400">Attached Documents</h2>
                        <div className="rounded-md bg-white p-8">
                            <Row className="border-b border-gray-light-1">
                                <>
                                    <ColHeader className={`w-1-of-12 pl-8`} text="Added By" />
                                    <ColHeader className={`w-2-of-12`} text="Added" />
                                    <ColHeader className={`w-2-of-12`} text="Document" />
                                    <ColHeader className={`w-3-of-12`} text="Open Document" />
                                    <ColHeader className={`w-2-of-12`} text="Document Last Updated" />
                                    <ColHeader className={`w-2-of-12 pr-8`} text="" />
                                </>
                            </Row>
                            {attachments.map(({ link, document, accessUrl, usageCount }, index) => {
                                const lastAttachment = index === attachments.length - 1;

                                return (
                                    <Row
                                        spacing="py-16"
                                        key={link.questionDocumentId}
                                        className={`mt-4 ${
                                            !lastAttachment ? '' : ''
                                        } rounded-sm border-black-10 hover:bg-gray-50`}
                                    >
                                        <>
                                            <Col className={`w-1-of-12 pl-8`}>
                                                <Avatar size={32} userId={link.createdByUserId} />
                                            </Col>
                                            <Col className={`w-2-of-12`}>{moment(link.createdAt).fromNow()}</Col>
                                            <Col className={`w-2-of-12`}>{document.documentName}</Col>
                                            <Col className={`w-3-of-12`}>
                                                <a
                                                    href={document.storeFile ? accessUrl : document.externalUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-primary-blue-100-opace-8 underline"
                                                >
                                                    {document.storeFile ? document.fileName : document.externalUrl}
                                                </a>
                                            </Col>
                                            <Col className={`w-2-of-12`}>{moment(document.updatedAt).fromNow()}</Col>
                                            <Col className={`flex w-2-of-12 flex-row-reverse pr-8`}>
                                                <Button
                                                    onClick={() => {
                                                        if (usageCount > 1) {
                                                            setRemoveUsageCount(usageCount);
                                                            setShowingRemoveModal(link.documentId);
                                                        } else {
                                                            removeDocumentFromQuestion(link.documentId);
                                                        }
                                                    }}
                                                    label="Remove"
                                                    buttonType="TERTIARY"
                                                />
                                            </Col>
                                        </>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                )}

                {filteredLibrary.length > 0 && (
                    <>
                        <h2 className="pb-16 pt-32 text-sm font-medium uppercase text-gray-400">Available Documents</h2>
                        <div className="rounded-md bg-white p-8">
                            <Row className="border-b border-gray-light-1">
                                <>
                                    <ColHeader className={`w-1-of-12 pl-8`} text="Added By" />
                                    <ColHeader className={`w-2-of-12`} text="Last Updated" />
                                    <ColHeader className={`w-3-of-12`} text="Document" />
                                    <ColHeader className={`w-3-of-12`} text="File Name" />
                                    <ColHeader className={`w-3-of-12 pr-8`} text="" />
                                </>
                            </Row>

                            {filteredLibrary.map((document, index) => {
                                const lastAttachment = index === filteredLibrary.length - 1;

                                return (
                                    <Row
                                        key={document.documentId}
                                        spacing="py-16"
                                        className={`mt-4 ${
                                            !lastAttachment ? '' : ''
                                        } rounded-sm border-black-10 hover:bg-gray-50`}
                                    >
                                        <>
                                            <Col className={`w-1-of-12 pl-8`}>
                                                <Avatar size={32} userId={document.createdByUserId} />
                                            </Col>
                                            <Col className={`w-2-of-12`}>{moment(document.updatedAt).fromNow()}</Col>
                                            <Col className={`w-3-of-12`}>{document.documentName}</Col>
                                            <Col className={`w-3-of-12`}>
                                                <a
                                                    href={document.accessUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className="text-primary-blue-100-opace-8 underline"
                                                >
                                                    {document.fileName}
                                                </a>
                                            </Col>
                                            <Col className={`flex w-3-of-12 flex-row-reverse pr-8`}>
                                                <Button
                                                    onClick={() => {
                                                        addDocumentToQuestion(document.documentId);
                                                    }}
                                                    label="Attach"
                                                    buttonType="TERTIARY"
                                                />
                                            </Col>
                                        </>
                                    </Row>
                                );
                            })}
                        </div>
                    </>
                )}

                {showingAttachModal && (
                    <FileUploadModal
                        projectId={projectId}
                        questionId={questionId}
                        closeModal={() => {
                            setShowingAttachModal(false);
                            fetchAttachedDocuments();
                            fetchLibraryOfDocuments();
                        }}
                    />
                )}
                {showRemoveModal && removeUsageCount > 0 && (
                    <ConfirmationDialog
                        title="Remove File"
                        descriptionText={[
                            `This file is attached to ${removeUsageCount} Question(s).`,
                            'By continuing, you will remove it from all attached questions',
                        ]}
                        cancelAction={() => {
                            setShowingRemoveModal('');
                        }}
                        primaryAction={() => {
                            removeDocumentFromQuestion(showRemoveModal);
                            setShowingRemoveModal('');
                        }}
                        primaryLabel="Remove File"
                    />
                )}
            </div>
        </div>
    );
};
