import { ErrorExclamation } from 'assets/error-exclamation.svg';
import React from 'react';
import { toast } from 'react-toastify';
import { logError } from 'utility/Analytics';

const ErrorToast = ({ msg }: { msg: string }) => {
    return (
        <div className="flex cursor-pointer items-center">
            <div className="mr-10">
                <ErrorExclamation strokeColor="#FF5640" />
            </div>
            <span data-cy="Error_Toast" className="small-1-med text-error-light">
                {msg}
            </span>
        </div>
    );
};

export const showError = (msg: string, stackTrace?: Error): void => {
    toast.error(<ErrorToast msg={msg} />, {
        icon: false,
    });
    logError(msg, stackTrace);
};
export const showErrorToastOnly = (msg: string): void => {
    toast.error(<ErrorToast msg={msg} />, {
        icon: false,
    });
};
