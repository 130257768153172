import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar } from './duex/Avatar';

export function Notification({
    title,
    linkReferencePath,
    causedByUserId,
}: {
    title: string;
    linkReferencePath: string;
    causedByUserId: string;
}): ReactElement {
    const navigate = useNavigate();
    return (
        <div
            onClick={() => navigate(linkReferencePath)}
            className="grid cursor-pointer gap-16"
            style={{
                gridTemplateColumns: '40px auto',
            }}
        >
            <Avatar size={40} userId={causedByUserId} />
            <div className="small-1-med text-black-80">{title}</div>
        </div>
    );
}
