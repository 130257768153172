import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { showError } from 'components/error-toast.component';
import { useUserContext } from 'hooks/useUserContext';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { PERMISSION } from 'utility/Permission';
import { SectionTitle, SettingsCheckbox, SettingsItem, SettingsSection } from './settings-components';

interface UserSettingsInterface {
    sendEmailOnProjectAssigned?: boolean;
    sendEmailOnBeingMentioned?: boolean;
    sendEmailOnQuestionAssigned?: boolean;
    sendEmailOnBeingRepliedTo?: boolean;
    sendEmailOnProjectCreated?: boolean;
}

const updateableProperties = [
    'sendEmailOnProjectAssigned',
    'sendEmailOnBeingMentioned',
    'sendEmailOnQuestionAssigned',
    'sendEmailOnBeingRepliedTo',
    'sendEmailOnProjectCreated',
];

export const UserSettings = (): ReactElement => {
    const { hasPermission } = useUserContext();
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState<UserSettingsInterface>({});
    const [originalSettings, setOriginalSettings] = useState<UserSettingsInterface>({});

    const updateSetting = (propertyName: string) => {
        return (newValue: boolean) => {
            setSettings({
                ...settings,
                [propertyName]: newValue,
            });
        };
    };

    const fetchSettings = async () => {
        try {
            setLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_USER_SETTINGS);
            const result = await API.get(url);

            if (result) {
                setSettings(result.data);
                setOriginalSettings(result.data);
            }

            setLoading(false);
        } catch (err) {
            showError('There was an error retrieving your settings', err);
            setLoading(false);
        }
    };

    const saveSettings = async () => {
        try {
            const started = new Date();
            setLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_USER_SETTINGS);
            await API.post(url, _pick(settings, updateableProperties));

            logEvent(`USER_SETTINGS_UPDATE`, started);
            setLoading(false);

            fetchSettings();
        } catch (err) {
            setLoading(false);
            showError('There was an error saving your settings', err);
        }
    };

    const reset = async () => {
        setSettings(originalSettings);
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    useEffect(() => {
        document.title = 'Pearler | User Settings';
    }, []);

    const hasAdminNotifications = hasPermission(PERMISSION.NOTIFY_PROJECT_CREATION);
    const hasDifferences = !_isEqual(
        _pick(settings, updateableProperties),
        _pick(originalSettings, updateableProperties),
    );

    return (
        <div>
            <SectionTitle title="My Settings" />

            {loading && <LoadingSpinner className="pt-32" />}

            {!loading && (
                <>
                    <SettingsSection title="Emails" />
                    <SettingsItem>
                        <SettingsCheckbox
                            description="Email me when a project is assigned to me"
                            currentValue={settings.sendEmailOnProjectAssigned}
                            onChange={updateSetting('sendEmailOnProjectAssigned')}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsCheckbox
                            description="Email me when a question is assigned to me"
                            currentValue={settings.sendEmailOnQuestionAssigned}
                            onChange={updateSetting('sendEmailOnQuestionAssigned')}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsCheckbox
                            description="Email me when someone mentions me in a comment"
                            currentValue={settings.sendEmailOnBeingMentioned}
                            onChange={updateSetting('sendEmailOnBeingMentioned')}
                        />
                    </SettingsItem>
                    <SettingsItem>
                        <SettingsCheckbox
                            description="Email me when someone comments on question's I'm subscribed to"
                            currentValue={settings.sendEmailOnBeingRepliedTo}
                            onChange={updateSetting('sendEmailOnBeingRepliedTo')}
                        />
                    </SettingsItem>
                    {hasAdminNotifications && (
                        <>
                            <SettingsSection title="Admin Emails" />
                            <SettingsItem>
                                <SettingsCheckbox
                                    description="Email me when new projects are created"
                                    currentValue={settings.sendEmailOnProjectCreated}
                                    onChange={updateSetting('sendEmailOnProjectCreated')}
                                />
                            </SettingsItem>
                        </>
                    )}

                    <ButtonBar className="mt-16">
                        <Button
                            label="Save Changes"
                            disabled={!hasDifferences}
                            onClick={saveSettings}
                            loading={loading}
                        />
                        {hasDifferences && (
                            <Button buttonType="SECONDARY" label="Undo Changes" disabled={loading} onClick={reset} />
                        )}
                    </ButtonBar>
                </>
            )}
        </div>
    );
};
