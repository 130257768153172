import React, { ReactElement } from 'react';

export const FormLabel = ({
    label,
    required,
    containerClasses = '',
}: {
    label: string;
    required?: boolean;
    containerClasses?: string;
}): ReactElement => {
    return (
        <div className={containerClasses}>
            <label className={`small-1-med mb-4 flex flex-row justify-between text-black-60`}>
                <span>{label}</span>
                {required && <span className="float-right text-black-60">Required</span>}
            </label>
        </div>
    );
};
