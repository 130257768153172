export const CONSTANTS = {
    INCOMPLETE: 'INCOMPLETE',
    INCOMPLETE_COMPLIANCE: 'INCOMPLETE_COMPLIANCE',
    INCOMPLETE_STATUS: 'INCOMPLETE_STATUS',
    INPROGRESS: 'IN-PROGRESS',
    COMPLETE: 'COMPLETE',
    COMPLIANT: 'COMPLIANT',
    PARTIALLYCOMPLIANT: 'PARTIALLY-COMPLIANT',
    NOTAPPLICABLE: 'NOT-APPLICABLE',
    NONCOMPLIANT: 'NON-COMPLIANT',
    ASSIGNEDTOME: 'SELFASSIGNED',
    COMPLIANT_KEY: 'compliant',
    PARTIALLY_COMPLIANT_KEY: 'partiallyCompliant',
    NOT_APPLICABLE_KEY: 'notApplicable',
    NON_COMPLIANT_KEY: 'nonCompliant',
    ASSIGNEDTOELSE: 'ASSIGNEDTOELSE',
    UNASSIGNED: 'UNASSIGNED',
    STATUS: 'Status',
    COMPLIANCE: 'Compliance',
    ASSIGNEE: 'Assignee',
    IM_MENTIONED: 'IM_MENTIONED',
    QUESTION: 'ENDPOINT_QUESTION',
    QUESTIONS: 'ENDPOINT_QUESTIONS',
    QUESTION_REMAINDER: 'ENDPOINT_QUES_REMIND',
    QUESTION_UPDATE: 'ENDPOINT_QUES_UPDATE',
    FETCH_QUESTION: 'ENDPOINT_FETCH_QUESTION',
    SUGGESTIONS: 'ENDPOINT_SUGGESTIONS',
    COMMENTS: 'ENDPOINT_COMMENTS',
    NOTIFICATIONS: 'ENDPOINT_NOTIFICATIONS',
    PROJECTS: 'ENDPOINT_PROJECTS',
    TEAM: 'ENDPOINT_TEAM',
    UPDATE_TEAM: 'ENDPOINT_UPDATE_TEAM',
    GET_TEAM: 'ENDPOINT_GET_TEAM',
    TEAM_LOGO_UPLOAD: 'ENDPOINT_TEAM_LOGO_UPLOAD',
    USER: 'ENDPOINT_USER',
    USER_LOG_ACTIVITY: 'USER_LOG_ACTIVITY',
    ASSIGNED_QUESTIONS: 'ENDPOINT_ASSIGNED_QUESTIONS',
    FETCH_ACTIVITY: 'ENDPOINT_ACTIVITY',
    FETCH_DISCUSSION: 'ENDPOINT_DISCUSSION',
    DISCUSSION_UPDATE: 'DISCUSSION_UPDATE',
    CREATE_DISCUSSION: 'CREATE_DISCUSSION',
    LOW: 'LOW',
    HIGH: 'HIGH',
    NORMAL: 'NORMAL',
    SUGGESTED_ANSWERS: 'Suggested',
    SEARCH_ANSWERS: 'Search',
    EXPLORE_QUESTIONS: 'Learn',
    DOCUMENTS: `Docs`,
    FAVOURITE_ANSWERS: 'Favourites',
    USE_THIS_ANSWER: 'Use this Answer',
    FLAG_ANSWER: 'Flag for Review',
    HIDE_ANSWER: "Don't show this Again",
    FLAGGED: 'FLAGGED',
    CANDIDATES: 'CANDIDATES',
    FREQUENTLY_USED: 'FREQUENTLY_USED',
    FLAG_INCORRECT_INFORMATION: 'Incorrect Information',
    FLAG_OUTDATED_INFORMATION: 'Outdated information',
    FLAG_OTHER_REASON: 'Other',
    UPDATE_ANSWER: 'UPDATE_ANSWER',
    DELETE_ANSWER: 'DELETE_ANSWER',
    ARCHIVE_ANSWER: 'ARCHIVED',
    UNDO_ARCHIVE: 'UNDO_ARCHIEVE',
    IN_USE: 'IN-USE',
    LIBRARY_TERMS: 'LIBRARY_TERMS',
    PROJECT_HOME: 'PROJECT_HOME',
    PROJECT_DASHBOARD: 'PROJECT_DASHBOARD',
    DISCUSSION: 'Discussion',
    ACTIVITY: 'Activity',
    PROJECT_DETAILS: 'PROJECT_DETAILS',
    BUSINESS_CONTACT: 'BUSINESS_CONTACT',
    IMPORT_QUESTIONS: 'IMPORT_QUESTIONS',
    SHARING_SETTINGS: 'SHARING_SETTINGS',
    REVIEW_IMPORT: 'REVIEW_IMPORT',
    OPEN_PROJECT: 'Open',
    CLOSED_PROJECT: 'Closed',
    CREATE_PROJECT: 'CREATE_PROJECT',
    UPDATE_PROJECT: 'UPDATE_PROJECT',
    EXPORT_PROJECT_DETAILS: 'EXPORT_PROJECT_DETAILS',
    PROJECT_COMPLETE: 'COMPLETED',
    PROJECT_ARCHIVED: 'ARCHIVED',
    PROJECT_IN_PROGRESS: 'IN-PROGRESS',
    UPDATE_USER: 'UPDATE_USER',
    UNREAD: 'Unread',
    ALL: 'All',
    READ: 'READ',
    NOTIFICATION: 'NOTIFICATIONS',
    DASHBOARD: 'DASHBOARD',
    FETCH_DASHBOARD_PROJECTS: 'ENDPOINT_DASHBOARD_PROJECTS',
    FETCH_NOTIFICATIONS: 'ENDPOINT_NOTIFICATIONS',
    READ_NOTIFICATIONS: 'READ_NOTIFICATIONS',
    CREATE_UPLOAD_URL: 'CREATE_UPLOAD_URL',
    COMPLETE_PHOTO_UPLOAD: 'COMPLETE_PHOTO_UPLOAD',
    COMPLETE_PHOTO_REMOVAL: 'COMPLETE_PHOTO_REMOVAL',
    IMPORT_ANSWERS_OPTIONS: 'IMPORT_ANSWERS_OPTIONS',
    IMPORT_ANSWERS_COPY_PASTE: 'IMPORT_ANSWERS_COPY_PASTE',
    SAVE_QUESTIONS_AND_ANSWERS: 'SAVE_QUESTIONS_AND_ANSWERS',
    FETCH_ROLES: 'ENDPOINT_FETCH_ROLES',
    FETCH_GROUPS: 'ENDPOINT_FETCH_GROUPS',
    UPDATE_MEMBERSHIP: 'UPDATE_MEMBERSHIP',
    REINVITE_MEMBERSHIP: 'REINVITE_MEMBERSHIP',
    INVITE_MEMBER: 'INVITE_MEMBER',
    ALL_TEAM: 'ALL_TEAM',
    ACTIVE: 'Active',
    COMPLETE_REGISTRATION: 'ENDPOINT_COMPLETE_REGISTRATION',
    USER_NOT_CONFIRMED_EXPECTION: 'UserNotConfirmedException',
    USER_TEAM: 'ENDPOINT_USER_TEAM',
    PERMISSIONS: 'ENDPOINT_PERMISSIONS',
    UPDATE_MEMBERSHIP_FAIL: 'UPDATE_MEMBERSHIP_FAIL',
    FETCH_INVITATIONS: 'ENDPOINT_FETCH_INVITATIONS',
    CREATE_TEAM: 'ENDPOINT_CREATE_TEAM',
    FULFILLED_REDUX_STATUS: 'fulfilled',
    UPDATE_INVITATION: 'ENDPOINT_UPDATE_INVITATION',
    ACCEPT_INVITATION: 'ACCEPTED',
    DECLINE_INVITATION: 'DECLINED',
    QUESTION_UNASSIGN: 'ENDPOINT_QUESTION_UNASSIGN',
    PROJECT_UNASSIGN: 'ENDPOINT_PROJECT_UNASSIGN',
    ONE_COLUMN_LAYOUT: 'ONE_COLUMN_LAYOUT',
    TWO_COLUMN_LAYOUT: 'TWO_COLUMN_LAYOUT',
    USER_STATUS: 'USER_STATUS',
    USER_HAS_INVITATIONS: 'USER_HAS_INVITATIONS',
    USER_HAS_NO_INVITATIONS: 'USER_HAS_NO_INVITATIONS',
    USER_ACCOUNT_READY: 'USER_ACCOUNT_READY',
    USER_NOT_REGISTERED: 'USER_NOT_REGISTERED',
    ANSWERING_SAVE: 'ANSWERING_SAVE',
    ANSWERING_COPY_SAVE: 'ANSWERING_COPY_SAVE',
    ANSWERING_SAVE_NEXT: 'ANSWERING_SAVE_NEXT',
    ANSWERING_RESET_QUESTION: 'ANSWERING_RESET_QUESTION',
    ADD_QUESTION: 'ENDPOINT_ADD_QUESTION',
    DELETE_QUESTION: 'DELETE_QUESTION',
    SHARE_QUESTION: 'SHARE_QUESTION',
    QUESTION_COMPLETED: 'QUESTION_COMPLETED',
    CLEAR_RESPONSE: 'CLEAR_RESPONSE',
    NOT_A_QUESTION: 'NOT_A_QUESTION',
    COMPLETE_QUESTION: 'COMPLETE_QUESTION',
    PROJECT_THIRD_PARTY: 'THIRD_PARTY',
    PROJECT_IMPORT_COPY_PASTE: 'IMPORT_COPY_PASTE',
    QUESTION_BULK_CREATION: 'BULK_CREATION',
    QUESTION_MANUAL_CREATE: 'MANUAL_CREATE',
    PROJECT_LIST_DEFAULT_PRIORITY_FILTER: 'PROJECT_LIST_DEFAULT_PRIORITY_FILTER',
    PROJECT_LIST_PRIORITY_FILTER: 'PROJECT_LIST_PRIORITY_FILTER',
    PROJECT_LIST_OWNER_FILTER: 'PROJECT_LIST_OWNER_FILTER',
    CLOSE_ASSESSMENT: 'CLOSE_ASSESSMENT',
    OPEN_ASSESSMENT: 'OPEN_ASSESSMENT',
    DELETE_PROJECT: 'DELETE_PROJECT',
    EXPORT_ASSESSMENT: 'EXPORT_ASSESSMENT',
    EXPORT_ASSESSMENT_2: 'EXPORT_ASSESSMENT_2',
    PROJECT_DASHBOARD_ENDPOINT: 'PROJECT_DASHBOARD_ENDPOINT',
    NOT_STARTED: 'NOT-STARTED',
    FETCH_ANSWERS_BY_SEARCH: 'FETCH_ANSWERS_BY_SEARCH',
    ANSWER_LIB_ALL: 'ALL',
    PROJECT_ADD_QUESTION: 'PROJECT_ADD_QUESTION',
    LAYOUT_SELECTION: 'LAYOUT_SELECTION',
    IMPORT_AND_REVIEW: 'IMPORT_AND_REVIEW',
    MULTI_COLUMN_LAYOUT: 'MULTI_COLUMN',
    SINGLE_COLUMN_LAYOUT: 'SINGLE_COLUMN',
    CLASSIFICATION_REFERENCE: 'REFERENCE',
    CLASSIFICATION_QUESTION: 'QUESTION',
    CLASSIFICATION_ANSWER: 'ANSWER',
    CLASSIFICATION_IGNORE: 'IGNORE',
    CLASSIFICATION_COMPLIANCE_STATUS: 'COMPLIANCE_STATUS',
    SETTINGS_MY: 'My Settings',
    SETTINGS_TEAM: 'Team Settings',
    FETCH_USER_SETTINGS: 'FETCH_USER_SETTINGS',
    UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ENDPOINT_GET_PROJECT: 'ENDPOINT_GET_PROJECT',
    ANSWERS_AND_COMPLIANCE: 'ANSWERS_AND_COMPLIANCE',
    ANSWERS_ONLY: 'ANSWERS_ONLY',
    COMPLIANCE_ONLY: 'COMPLIANCE_ONLY',
    COPY_ALL: 'COPY_ALL',
    COMPLIANCE_OVERRIDE: 'FETCH_COMPLIANCE_OVERRIDE',
    FETCH_ANSWER_ANALYTICS: 'FETCH_ANSWER_ANALYTICS',
    FETCH_FAVOURITE_ANSWERS: 'FETCH_FAVOURITE_ANSWERS',
    SEARCH_ALL_PROJECTS: 'SEARCH_ALL_PROJECTS',
    EXPORT_ALL_PROJECTS: 'EXPORT_ALL_PROJECTS',
    SEARCH_ALL_QUESTIONS: 'SEARCH_ALL_QUESTIONS',
    EXPORT_ALL_QUESTIONS: 'EXPORT_ALL_QUESTIONS',
    USER_LOGGED_IN: 'ENDPOINT_USER_LOGGED_IN',
    GET_PROJECT_LIST: 'ENDPOINT_GET_PROJECT_LIST',
    GET_INDUSTRY_LIST: 'ENDPOINT_GET_INDUSTRY_LIST',
    REPLACE_ANSWER: 'ENDPOINT_REPLACE_ANSWER',
    GET_PROJECT_TEAM: 'ENDPOINT_GET_PROJECT_TEAM',
    GET_PROJECT_SHARE_TEAM: 'GET_PROJECT_SHARE_TEAM',
    CHANGE_SHARING_SETTINGS: 'CHANGE_SHARING_SETTINGS',
    UPDATE_PROJECT_SHARING_SETTINGS: 'UPDATE_PROJECT_SHARING_SETTINGS',
    GET_SSO_BY_ALIAS: 'GET_SSO_BY_ALIAS',
    GET_SSO_BY_DOMAIN: 'GET_SSO_BY_DOMAIN',

    QUESTION_SUBSCRIPTION_STATUS: 'QUESTION_SUBSCRIPTION_STATUS',
    QUESTION_CREATE_SUBSCRIPTION: 'QUESTION_CREATE_SUBSCRIPTION',
    QUESTION_REMOVE_SUBSCRIPTION: 'QUESTION_REMOVE_SUBSCRIPTION',

    ASSESSMENT_GET_FULL_TEMPLATES: 'ASSESSMENT_GET_FULL_TEMPLATES',
    ASSESSMENT_GET_TEMPLATES: 'ASSESSMENT_GET_TEMPLATES',
    ASSESSMENT_GET_ASSESSMENT: 'ASSESSMENT_GET_ASSESSMENT',
    ASSESSMENT_CREATE_ASSESSMENT: 'ASSESSMENT_CREATE_ASSESSMENT',
    ASSESSMENT_SAVE_ASSESSMENT: 'ASSESSMENT_SAVE_ASSESSMENT',
    ASSESSMENT_INVITE_ASSESSMENT: 'ASSESSMENT_INVITE_ASSESSMENT',
    ASSESSMENT_GET_INBOX: 'ASSESSMENT_GET_INBOX',
    ASSESSMENT_GET_SENT: 'ASSESSMENT_GET_SENT',
    ASSESSMENT_ACCEPT_ASSESSMENT: 'ASSESSMENT_ACCEPT_ASSESSMENT',
    ASSESSMENT_DECLINE_ASSESSMENT: 'ASSESSMENT_DECLINE_ASSESSMENT',
    ASSESSMENT_CANCEL_ASSESSMENT: 'ASSESSMENT_CANCEL_ASSESSMENT',

    GET_TAGS_LIST: 'GET_TAGS_LIST',
    ADD_TAG: 'ADD_TAG',
    REMOVE_TAG: 'REMOVE_TAG',
    GET_ALL_TAGS: 'GET_ALL_TAGS',
    GET_DOC_LIBRARY: 'GET_DOC_LIBRARY',
    GET_DOC_LIBRARY_ALL: 'GET_DOC_LIBRARY_ALL',
    GET_DOC_QUESTION: 'GET_DOC_QUESTION',
    GET_DOC_PROJECT: 'GET_DOC_PROJECT',
    GET_DOC_TYPE: 'GET_DOC_TYPE',
    CREATE_DOC: 'CREATE_DOC',
    ADD_DOC_QUESTION: 'ADD_DOC_QUESTION',
    REMOVE_DOC_QUESTION: 'REMOVE_DOC_QUESTION',
    ADD_DOC_PROJECT: 'ADD_DOC_PROJECT',
    REMOVE_DOC_PROJECT: 'REMOVE_DOC_PROJECT',
    COMPLETE_DOC_UPLOAD: 'COMPLETE_DOC_UPLOAD',
    ARCHIVE_DOCUMENT: 'ARCHIVE_DOCUMENT',
    REINSTATE_DOCUMENT: 'REINSTATE_DOCUMENT',
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',

    ACTION_FILES: 'ACTION_FILES',
    OPEN_TRUST_CENTRE: 'OPEN_TRUST_CENTRE',
    PROJECT_TRUST_CENTRE: 'PROJECT_TRUST_CENTRE',

    OPEN_PROJECT_IMPORTER: 'OPEN_PROJECT_IMPORTER',

    START_SOURCE_DOC_UPLOAD: 'START_SOURCE_DOC_UPLOAD',
    COMPLETE_SOURCE_DOC_UPLOAD: 'COMPLETE_SOURCE_DOC_UPLOAD',
    DOWNLOAD_PROJECT_SOURCE: 'DOWNLOAD_PROJECT_SOURCE',
    DOWNLOAD_PROJECT_SOURCE_DOCUMENT: 'DOWNLOAD_PROJECT_SOURCE_DOCUMENT',
    DOWNLOAD_DOCUMENT_AS_CSV: 'DOWNLOAD_DOCUMENT_AS_CSV',
    GET_SOURCE_DOCS: 'GET_SOURCE_DOCS',
    SAVE_SOURCE_DOC_CONFIGURATION: 'SAVE_SOURCE_DOC_CONFIGURATION',

    OPEN_PORTAL_LINK_MODAL: 'OPEN_PORTAL_LINK_MODAL',

    FETCH_USER_ACTIVITY: 'FETCH_USER_ACTIVITY',
    GET_MANUALLY_CREATED_QUESTIONS: 'GET_MANUALLY_CREATED_QUESTIONS',

    GET_API_ACCESS: 'GET_API_ACCESS',
    GENERATE_API_ACCESS: 'GENERATE_API_ACCESS',

    SAVE_SOURCE_SHEET_CONFIGURATION: 'SAVE_SOURCE_SHEET_CONFIGURATION',
    SAVE_SOURCE_DOCUMENT_CONFIGURATION: 'SAVE_SOURCE_DOCUMENT_CONFIGURATION',

    UPLOAD_ANSWER_LIBRARY_START: 'UPLOAD_ANSWER_LIBRARY_START',
    UPLOAD_ANSWER_LIBRARY_COMPLETE: 'UPLOAD_ANSWER_LIBRARY_COMPLETE',

    ANSWER_LIBRARY_BULK_CHANGE: 'ANSWER_LIBRARY_BULK_CHANGE',
    ANSWER_LIBRARY_BULK_CANDIDATE_CHANGE: 'ANSWER_LIBRARY_BULK_CANDIDATE_CHANGE',

    GET_ANSWER_COLLECTION: 'GET_ANSWER_COLLECTION',
    CREATE_ANSWER_COLLECTION: 'CREATE_ANSWER_COLLECTION',
    UPDATE_ANSWER_COLLECTION: 'UPDATE_ANSWER_COLLECTION',
    ADD_TO_COLLECTION: 'ADD_TO_COLLECTION',
    REMOVE_FROM_COLLECTION: 'REMOVE_FROM_COLLECTION',
    GET_ANSWER_COLLECTIONS: 'GET_ANSWER_COLLECTIONS',
    OPEN_ANSWER_SOURCES: 'OPEN_ANSWER_SOURCES',
    PROJECT_ANSWER_SEARCH: 'PROJECT_ANSWER_SEARCH',
    COMBINED_SEARCH_ANSWERS: 'COMBINED_SEARCH_ANSWERS',
    PROJECT_SUGGESTION_SEARCH: 'PROJECT_SUGGESTION_SEARCH',
    GET_ANSWER_COLLECTION_COUNTS: 'GET_ANSWER_COLLECTION_COUNTS',
    UPDATE_COLLECTION: 'UPDATE_COLLECTION',

    REWRITE_ANSWER: 'REWRITE_ANSWER',
    GENERATE_NEW_ANSWER: 'GENERATE_NEW_ANSWER',

    ADMIN_TEAM_SEARCH: 'ADMIN_TEAM_SEARCH',
    ADMIN_TEAM_UPDATE: 'ADMIN_TEAM_UPDATE',
    ADMIN_TEAM_ACTIVITY: 'ADMIN_TEAM_ACTIVITY',

    GET_FEEDBACK: 'GET_FEEDBACK',
    SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',

    GET_DIVISIONS: 'GET_DIVISIONS',
    CREATE_DIVISION: 'CREATE_DIVISION',
    UPDATE_DIVISION: 'UPDATE_DIVISION',

    GET_ANSWER_SOURCE: 'GET_ANSWER_SOURCE',

    SUBMIT_PROJECT: 'SUBMIT_PROJECT',

    ASSIGNED_TO_SPECIFIC: 'ASSIGNED_TO_SPECIFIC',
    HAS_TAG: 'HAS_TAG',
    WORKFLOW: 'Workflow',

    FILTER_SECTION: 'FILTER_SECTION',
    FILTER_DOCUMENT: 'FILTER_DOCUMENT',
    FILTER_SHEET: 'FILTER_SHEET',

    API_BULK_RE_ASSSIGN: 'API_BULK_RE_ASSSIGN',
    DELETE_COLLECTION: 'DELETE_COLLECTION',
};
