import { DropDownArrow } from 'assets/drop-close-arrow.svg';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

function useOutsideAlerter(ref, toggleDropDownState) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}

export const ButtonDropDown = ({
    buttonOption,
    isDisabled,
    dropdownOptions,
    textClassNames,
    dropDownClassNames,
    dropDownArrowColor,
    callBack,
}: {
    isDisabled?: boolean;
    buttonOption: {
        value: string;
        label: string;
    };
    dropdownOptions: {
        value: string;
        label: string;
    }[];
    callBack: (string) => void;
    textClassNames?: string;
    dropDownClassNames?: string;
    dropDownArrowColor?: string;
}): ReactElement => {
    const handleClick = (event) => {
        toggleDropDownState(false);
        callBack(event.target.dataset.value);
    };

    const [isOpen, toggleDropDownState] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, toggleDropDownState);

    return (
        <span
            ref={wrapperRef}
            className={`${
                isDisabled ? 'pointer-events-none' : 'shadow-sm'
            } relative z-1 inline-flex rounded-md text-center`}
        >
            <button
                onClick={isDisabled ? null : handleClick}
                data-value={buttonOption.value}
                type="button"
                className={` ${
                    textClassNames
                        ? textClassNames
                        : 'border-border-1 relative box-border inline-flex h-40 select-none items-center rounded-l-md border border-field-outline-blue bg-white px-16 py-11 text-14 font-500 leading-20 text-field-outline-blue transition duration-500 ease-in-out focus:outline-none active:bg-dark-blue active:text-white'
                } select-none font-500 transition duration-500 ease-in-out`}
            >
                {buttonOption.label}
            </button>
            <span className="relative -ml-px block">
                <button
                    type="button"
                    onClick={isDisabled ? null : () => toggleDropDownState(!isOpen)}
                    className={` ${
                        dropDownClassNames
                            ? dropDownClassNames
                            : 'rounded-r-md border border-field-outline-blue bg-white font-medium text-field-outline-blue hover:text-field-outline-blue active:border-none active:bg-dark-blue active:text-white'
                    } relative flex h-40 w-36 select-none justify-center rounded-r-md p-12 transition duration-500 ease-in-out`}
                    aria-label="Expand"
                >
                    <p className={`duration-200 ease-linear ${isOpen ? 'rotate-180' : ''}`}>
                        <DropDownArrow color={`${dropDownArrowColor ? dropDownArrowColor : '#2068E3'}`} />
                    </p>
                </button>

                <div
                    className={`absolute right-0 -mr-1 mt-2 w-168 origin-top-right rounded-md shadow-lg ${
                        isOpen ? 'opacity-1 animate-grow-down-dropdown-animation' : 'pointer-events-none opacity-0'
                    }`}
                >
                    <div className="shadow-xs rounded-md bg-white">
                        <div className="py-1">
                            {dropdownOptions.map((itm) => (
                                <button
                                    data-value={itm.value}
                                    onClick={handleClick}
                                    key={itm.value}
                                    className="tracking-0.1 block w-full select-none p-10 text-left text-14 font-normal leading-20 
                                text-light-black hover:bg-field-outline-blue-light hover:text-field-outline-blue focus:bg-field-outline-blue-light focus:text-field-outline-blue focus:outline-none"
                                >
                                    {itm.label}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </span>
        </span>
    );
};
