import { FormTextField } from 'components/duex/FormTextField';
import { Select } from 'components/duex/Select';
import { SettingsCheckbox, SettingsItem, SettingsSection } from 'modules/settings/settings-components';
import React, { ReactElement } from 'react';
import { CONSTANTS } from 'utility/Constants';
import { ProjectSharedProps } from '../project.view';

export const WorkflowPanel = (props: ProjectSharedProps): ReactElement => {
    const updateProperty = (propertyName: string) => {
        return (newValue: boolean | string) => {
            props.saveQuestion(
                {
                    [propertyName]: newValue,
                },
                false,
            );
        };
    };
    const updateSubProperty = (propertyName: string) => {
        return (newValue: boolean | string) => {
            props.saveQuestion(
                {
                    reviewStatus: {
                        ...props.question.reviewStatus,
                        [propertyName]: newValue,
                    },
                },
                false,
            );
        };
    };

    return (
        <div className="px-16 overflow-y-auto">
            <SettingsSection title="Status" />
            <SettingsItem>
                <SettingsCheckbox
                    description="Automatically manage"
                    currentValue={props.question.automaticallyManageStatus}
                    onChange={updateProperty('automaticallyManageStatus')}
                />
                {props.question.automaticallyManageStatus === false && (
                    <div className="mt-16">
                        <Select
                            placeholder="Select Status"
                            onChange={updateProperty('responseStatus')}
                            value={props.question.responseStatus}
                            items={[
                                {
                                    label: 'Incomplete',
                                    value: CONSTANTS.INCOMPLETE,
                                },
                                {
                                    label: 'In Progress',
                                    value: CONSTANTS.INPROGRESS,
                                },
                                {
                                    label: 'Complete',
                                    value: CONSTANTS.COMPLETE,
                                },
                            ]}
                        />
                    </div>
                )}
            </SettingsItem>

            <SettingsSection title="Review" />
            <SettingsItem>
                <SettingsCheckbox
                    description="Content accurate"
                    currentValue={props.question.reviewStatus?.contentAccurate}
                    onChange={updateSubProperty('contentAccurate')}
                />
            </SettingsItem>
            <SettingsItem>
                <SettingsCheckbox
                    description="Incorporates win themes"
                    currentValue={props.question.reviewStatus?.winThemes}
                    onChange={updateSubProperty('winThemes')}
                />
            </SettingsItem>
            <SettingsItem>
                <SettingsCheckbox
                    description="Approved"
                    currentValue={props.question.reviewStatus?.answerApproved}
                    onChange={updateSubProperty('answerApproved')}
                />
            </SettingsItem>
            <SettingsSection title="Tracking" />
            <SettingsItem>
                <FormTextField
                    placeholder="List any commitments here"
                    label="Commitments"
                    defaultValue={props.question.commitments || ''}
                    onBlur={updateProperty('commitments')}
                    inputType="resizing-textarea"
                    spellCheck={true}
                    maxLength={10000}
                    maxRows={15}
                />
            </SettingsItem>
            <SettingsItem>
                <FormTextField
                    placeholder="List any assumptions here"
                    label="Assumptions"
                    defaultValue={props.question.assumptions || ''}
                    onBlur={updateProperty('assumptions')}
                    inputType="resizing-textarea"
                    spellCheck={true}
                    maxLength={10000}
                    maxRows={15}
                />
            </SettingsItem>
        </div>
    );
};
