import React, { ReactElement } from 'react';
import { AnalyticsProps } from '../answer-analytics.modal';
import { OverviewGraph } from './overview-usage-graph';
import { SectionTitle, TextBlock } from './typography';

export function Overview(props: AnalyticsProps): ReactElement {
    const { answer } = props;

    return (
        <div>
            <SectionTitle title="Usage" />
            <OverviewGraph {...props} />
            <SectionTitle title="Original Question" />
            <TextBlock>
                <p className="select-text whitespace-pre-line break-words">{answer.originalQuestion}</p>
            </TextBlock>
            <SectionTitle title="Answer" />
            <TextBlock>
                <div
                    data-cy="Answer_Block"
                    className="select-text render-html-normally"
                    dangerouslySetInnerHTML={{
                        __html: answer.richTextAnswer,
                    }}
                />
            </TextBlock>
        </div>
    );
}
