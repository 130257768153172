import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { ProjectSharing } from 'components/project-sharing.component';
import { useUserContext } from 'hooks/useUserContext';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import { Membership } from 'types';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { ProjectDashboardComponents } from '../project-dashboard.view';

interface SharingSettingsModalProps extends ProjectDashboardComponents {
    closeModal: () => void;
}

export const SharingSettingsModal = ({ project, closeModal, projectTeam }: SharingSettingsModalProps): ReactElement => {
    const { teamList } = useUserContext();

    const [visibilityStatus, setVisibilityStatus] = useState(project.accessVisibility);
    const [teamMembers, setTeamMembers] = useState([]);
    const [originalTeamMembers, setOriginalTeamMembers] = useState([]);
    const [saving, setSaving] = useState(false);

    const { user } = useUserContext();

    async function fetchData() {
        try {
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_PROJECT_SHARE_TEAM, {
                projectId: project.projectId,
            });
            const result = await API.get(url);

            if (result && result.data) {
                let data = result.data;

                const criterion = {
                    userId: user.userId,
                };

                if (!_find(data, criterion)) {
                    const user = _find(projectTeam, criterion);
                    data = [user, ...data];
                }

                setTeamMembers(data);
                setOriginalTeamMembers(data);
            }
        } catch (err) {
            showError('There was an error retrieving your project team', err);
        }
    }

    async function saveData() {
        try {
            if (saving) {
                return;
            }
            const started = new Date();

            setSaving(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.UPDATE_PROJECT_SHARING_SETTINGS, {
                projectId: project.projectId,
            });

            logEvent(`PROJECT_ACCESS_UPDATE_SETTINGS_${visibilityStatus}`, started, {
                projectId: project.projectId,
                accessVisibility: visibilityStatus,
            });

            await API.post(url, {
                shareWith: _map(teamMembers, ({ userId }) => {
                    return {
                        userId,
                    };
                }),
                accessVisibility: visibilityStatus,
            });
            setSaving(false);
            closeModal();
        } catch (err) {
            showError('There was an error saving your updates', err);
        }
    }

    function dataChanged() {
        if (project.accessVisibility !== visibilityStatus) {
            return true;
        }

        if (originalTeamMembers.length !== teamMembers.length) {
            return true;
        }

        const originals = _map(originalTeamMembers, 'userId').sort();
        const current = _map(teamMembers, 'userId').sort();

        if (originals.join('') !== current.join('')) {
            return true;
        }

        return false;
    }

    useEffect(() => {
        fetchData();
    }, [project.projectId]);

    function addMember(user: Membership) {
        const existing = _find(teamMembers, {
            userId: user.userId,
        });

        if (!existing) {
            setTeamMembers([...teamMembers, user]);
        }
    }

    function removeMember({ userId }) {
        const newList = _filter(teamMembers, ({ userId: teamMemberId }) => {
            return teamMemberId !== userId;
        });
        setTeamMembers(newList);
    }

    function resetData() {
        setVisibilityStatus(project.accessVisibility);
        setTeamMembers(originalTeamMembers);
    }

    const enableSave = dataChanged();

    return (
        <Modal width="w-462" closeModal={closeModal} title="Sharing Settings">
            <ProjectSharing
                visibilityStatus={visibilityStatus}
                sharingWith={teamMembers}
                memberListExcludingUser={_filter(
                    teamList,
                    (teamMember: Membership) => teamMember.userId !== user.userId,
                )}
                projectCreatorUserId={project.createdByUserId}
                creatingNewProject={false}
                currentUserId={user.userId}
                onChangeVisibility={(newSetting: 'TEAM' | 'PRIVATE') => {
                    setVisibilityStatus(newSetting);
                }}
                onAddUser={(user) => {
                    addMember(user);
                }}
                onRemoveUser={(user) => {
                    removeMember(user);
                }}
            />
            <ButtonBar>
                <Button label="Save" onClick={saveData} disabled={!enableSave || saving} loading={saving} />
                {enableSave && (
                    <Button label="Undo Changes" buttonType="SECONDARY" onClick={resetData} disabled={saving} />
                )}
            </ButtonBar>
        </Modal>
    );
};
