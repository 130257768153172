import React, { ReactElement } from 'react';

export const FileUploadIcon = ({ strokeColor = 'currentColor' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="71" height="80" viewBox="0 0 71 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.3943 33.865H48.3153C49.1648 33.865 49.8536 33.1762 49.8536 32.3266C49.8536 31.4771 49.1648 30.7883 48.3153 30.7883H11.3943C10.5448 30.7883 9.85596 31.4771 9.85596 32.3266C9.85596 33.1762 10.5448 33.865 11.3943 33.865Z"
                fill={strokeColor}
            />
            <path
                d="M48.4507 43.0548H11.5298C10.6802 43.0548 9.99139 43.7436 9.99139 44.5932C9.99139 45.4427 10.6802 46.1316 11.5298 46.1316H48.4507C49.3002 46.1316 49.9891 45.4427 49.9891 44.5932C49.9891 43.7436 49.3002 43.0548 48.4507 43.0548Z"
                fill={strokeColor}
            />
            <path
                d="M58.3842 52.4538V23.1456C58.4316 22.6882 58.264 22.235 57.9302 21.9191L33.3198 0.381847C33.2944 0.35987 33.258 0.361243 33.2319 0.341327C33.0554 0.20672 32.8514 0.112628 32.6337 0.0666177C32.5245 0.0446409 32.4153 0.0226642 32.3061 0H4.45717C3.27522 0.00137346 2.14275 0.471129 1.30693 1.30625C0.471128 2.14136 0.00137135 3.27448 0 4.45578V66.3071C0.00137363 67.4891 0.471128 68.6223 1.30693 69.4581C2.14205 70.2939 3.27517 70.7643 4.45717 70.7657H43.9239C45.0214 73.8904 47.2046 76.5167 50.0767 78.1657C52.9488 79.8153 56.3181 80.3771 59.5699 79.7501C62.8218 79.1224 65.7399 77.3478 67.792 74.7484C69.8448 72.1496 70.8948 68.899 70.7513 65.5909C70.6071 62.2821 69.2796 59.1352 67.0098 56.7232C64.7394 54.3119 61.6785 52.7969 58.3846 52.4536L58.3842 52.4538ZM33.8458 4.9278L52.8232 21.5375H35.2242C34.4619 21.5361 33.8451 20.918 33.8451 20.1557L33.8458 4.9278ZM4.45674 67.6881C3.69375 67.6874 3.07632 67.0687 3.07632 66.3063V4.45496C3.0777 3.69334 3.6951 3.07662 4.45674 3.07591H30.7673V20.1547C30.7687 21.3366 31.2384 22.4698 32.0742 23.3056C32.9094 24.1414 34.0425 24.6118 35.2245 24.6132H55.4519V52.4516C52.6897 52.7421 50.0799 53.865 47.9701 55.6718H11.4342C10.5847 55.6718 9.89583 56.36 9.89583 57.2102C9.89583 58.0597 10.5847 58.7486 11.4342 58.7486H45.259C43.8381 60.9552 43.0798 63.5237 43.0744 66.1486C43.0964 66.6643 43.1479 67.1774 43.2282 67.6869L4.45674 67.6881ZM56.9172 76.9184C54.0616 76.9184 51.3228 75.7838 49.3029 73.7646C47.2831 71.7449 46.1486 69.006 46.1486 66.1497C46.1486 63.2942 47.2831 60.5546 49.3029 58.5354C51.3226 56.5157 54.0614 55.3811 56.9172 55.3811C59.7735 55.3811 62.5123 56.5157 64.532 58.5354C66.5511 60.5545 67.6858 63.294 67.6858 66.1497C67.6824 69.0046 66.5471 71.7422 64.528 73.7606C62.5097 75.7796 59.7728 76.9148 56.9172 76.9184Z"
                fill={strokeColor}
            />
            <path
                d="M64.3354 64.1595L58.4107 58.2588C58.2775 58.1318 58.1222 58.0295 57.9526 57.9567C57.9382 57.9505 57.932 57.934 57.9176 57.9278H57.9073V57.9285C57.6593 57.8351 57.3915 57.8076 57.1298 57.8495C57.0653 57.8578 57.0014 57.8701 56.9382 57.8873C56.6869 57.9464 56.4554 58.0693 56.2659 58.2451L56.2446 58.2568L50.157 64.2723C49.861 64.558 49.6921 64.9501 49.688 65.3608C49.6832 65.7722 49.8432 66.1677 50.1323 66.4603C50.4214 66.7522 50.8156 66.917 51.2263 66.9163C51.6377 66.9163 52.0312 66.7522 52.3204 66.4596L55.7934 63.0284L55.8113 72.9558V72.9551C55.8133 73.8039 56.5015 74.4907 57.3496 74.4907H57.3531C57.761 74.49 58.1518 74.3273 58.4396 74.0381C58.728 73.749 58.8894 73.3575 58.888 72.9496L58.8708 63.0601L62.1667 66.3422C62.4537 66.6382 62.8486 66.8064 63.2607 66.8085C63.6734 66.8105 64.0697 66.6471 64.3609 66.3545C64.6514 66.062 64.8128 65.665 64.808 65.2529C64.8032 64.8402 64.6329 64.4467 64.3355 64.161L64.3354 64.1595Z"
                fill={strokeColor}
            />
        </svg>
    );
};
