import { CalenderLeftArrow } from 'assets/calender-left-arrow.svg';
import { CalenderRightArrow } from 'assets/calender-right-arrow.svg';
import { Calender } from 'assets/calender.svg';
import moment, { Moment } from 'moment';
import React, { ReactElement, useState } from 'react';
import { SingleDatePicker } from 'react-dates';

export const DatePicker = ({
    id,
    toggleDateFocus,
    setDueDate,
    dueDate,
    placeholder,
    allowPastDates,
}: {
    id: string;
    toggleDateFocus?: (bool) => void;
    setDueDate: (Date) => void;
    dueDate: Date | Moment;
    placeholder: string;
    allowPastDates: boolean;
}): ReactElement => {
    const [focused, toggleFocus] = useState(false);

    return (
        <SingleDatePicker
            readOnly={true}
            isOutsideRange={(day: Moment) => {
                if (allowPastDates) {
                    return false;
                }

                return day.isBefore(moment());
            }}
            date={dueDate || null}
            onDateChange={(date) => setDueDate(date)}
            focused={focused}
            displayFormat={`DD MMMM YYYY`}
            placeholder={placeholder}
            numberOfMonths={1}
            customInputIcon={<Calender />}
            navPrev={<CalenderLeftArrow />}
            navNext={<CalenderRightArrow />}
            noBorder
            onFocusChange={({ focused }) => {
                if (toggleDateFocus) {
                    toggleDateFocus(focused);
                }
                toggleFocus(focused);
            }}
            id={id}
        />
    );
};
