import { Check } from 'assets/check.svg';
import { Cross } from 'assets/close.svg';
import { Search } from 'assets/magnify.svg';
import _find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Membership } from 'types';
import { Avatar } from './duex/Avatar';
function useOutsideAlerter(ref, toggleDropDownState) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                toggleDropDownState(false);
            }
        }
        // Bind the event listener
        document.removeEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
interface TeamListWithSearchProps {
    teamMembers: Membership[];
    selectedTeamMembers: Membership[];
    onSelect: (User: Membership) => void;
    hideList: () => void;
}

export const TeamListWithSearch = ({
    teamMembers,
    onSelect,
    hideList,
    selectedTeamMembers,
}: TeamListWithSearchProps): ReactElement => {
    const sortedList = _sortBy(teamMembers, 'member.fullName');

    const [searchQuery, updateSearch] = useState('');
    const [dropdownList, updateDropdownList] = useState(sortedList);

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, hideList);

    const updateList = (data: string) => {
        const value = data.toLowerCase();
        if (value) {
            const filteredList = sortedList.filter((teamMember: Membership) => {
                const name = teamMember.member.fullName.toLowerCase();
                return name.includes(value);
            });
            updateDropdownList(filteredList);
        } else {
            updateDropdownList(sortedList);
        }
        updateSearch(value);
    };

    return (
        <div className="relative" ref={wrapperRef}>
            <div className={`opacity-1 relative top-10 z-2 animate-grow-down-dropdown-animation`}>
                <div className="absolute -left-15 w-256 rounded-md bg-white py-8 shadow-lg">
                    <div className="mb-24 flex items-center px-16">
                        <div className="absolute left-22">
                            <Search strokeColor={'#2068E3'} />
                        </div>
                        <input
                            type="text"
                            value={searchQuery}
                            autoComplete="off"
                            onChange={(e?: React.ChangeEvent<HTMLInputElement>) => updateList(e.target.value)}
                            className="body-2-reg w-full select-none truncate rounded-full border border-primary-blue-100 px-40 py-8 focus:border-none focus:outline-none"
                        />
                        <div className="absolute right-22 cursor-pointer" onClick={() => updateList('')}>
                            <Cross strokeColor={'#2068E3'} />
                        </div>
                    </div>
                    <div className="mb-8 flex items-center px-16">
                        <span className="small-1-med mr-6 select-none text-black-60">People</span>
                        <div className="w-full border-t border-solid border-gray-light-1"></div>
                    </div>
                    <ul className="max-h-192 overflow-auto">
                        {dropdownList.map((itm) => {
                            const isSelected = _find(selectedTeamMembers, {
                                userId: itm.userId,
                            });

                            return (
                                <li
                                    key={itm.userId || 'default'}
                                    onClick={() => {
                                        onSelect(itm);
                                    }}
                                    className={`flex cursor-pointer items-center justify-between px-16 py-9 text-black-80 hover:bg-primary-blue-100-opace-4 hover:text-primary-blue-100`}
                                >
                                    <div className="grid grid-cols-dropdown-list-item gap-x-1">
                                        <div className={`rounded-full border border-transparent p-2`}>
                                            <Avatar size={32} userId={itm.userId} />
                                        </div>
                                        <div
                                            className="flex flex-wrap items-center overflow-hidden"
                                            style={{
                                                maxWidth: '100%',
                                            }}
                                        >
                                            <p
                                                data-cy="DropDownName"
                                                className="subtitle-2-reg w-full select-none truncate"
                                            >
                                                {itm.member.fullName}
                                            </p>
                                            {itm.group && (
                                                <p className="small-3-reg mt-4 w-full select-none truncate">
                                                    {itm.group.groupName}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    {isSelected && (
                                        <p>
                                            <Check strokeColor={'#2068E3'} />
                                        </p>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};
