import React, { ReactElement } from 'react';

interface TabItem {
    label: string;
    isActive: boolean;
    identifier?: string;
    disabled?: boolean;
}

export const Tabs = ({
    tabItems,
    onChange,
    rootClassName = '',
    tabClassName = '',
    selectedTabClassName = '',
    showBottomBorder = true,
}: {
    tabItems: TabItem[];
    onChange: (TabItem) => void;
    rootClassName?: string;
    selectedTabClassName?: string;
    tabClassName?: string;
    showBottomBorder?: boolean;
}): ReactElement => {
    return (
        <div className="relative">
            <div className={`mb-6 flex items-center justify-start ${rootClassName}`}>
                {tabItems && tabItems.length > 0 && (
                    <ul className="flex justify-between">
                        {tabItems.map((tab, tabIndex) => {
                            let classes;

                            if (tab.isActive) {
                                classes = `border-blue-600 text-field-outline-blue ${selectedTabClassName}`;
                            } else if (tab.disabled) {
                                classes = 'text-black-40 border-transparent';
                            } else {
                                classes = 'text-black-80 border-transparent opacity-80 hover:opacity-100';
                            }

                            return (
                                <li
                                    data-cy="TabNavigation"
                                    key={tab.identifier || tabIndex}
                                    className={`-mb-4 ${
                                        tab.disabled ? '' : ' cursor-pointer'
                                    } select-none whitespace-nowrap border-b-2 px-24 py-6 text-16 ${classes} ${tabClassName}`}
                                    onClick={() => {
                                        if (!tab.disabled) {
                                            onChange(tab);
                                        }
                                    }}
                                >
                                    {tab.label}
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
            {showBottomBorder && <hr className="absolute left-0 right-0 -mt-2 w-full" />}
        </div>
    );
};
