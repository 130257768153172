import React, { ReactElement } from 'react';

export const ListItems = ({ strokeColor = 'currentColor' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1.5H15.25M1 6H10.75M1 10.5H10.75M15.25 6V18M15.25 18L11.5 14.25M15.25 18L19 14.25"
                stroke={strokeColor}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
