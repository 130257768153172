import { Avatar } from 'components/duex/Avatar';
import { Modal } from 'components/duex/Modal';
import { TextDiff } from 'components/duex/TextDiff';
import { useUserContext } from 'hooks/useUserContext';
import { Label, SectionTitle, Value } from 'modules/answer-library/analytics/typography';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { Activity } from 'types';
import { getPlainText } from 'utility/StringHelper';

const RenderValue = ({ value }) => {
    const { getUser } = useUserContext();
    const assignee = getUser(value);

    if (typeof value === 'boolean') {
        value = value ? 'Ticked' : 'Unticked';
    }

    if (!value) {
        return <div className="my-16 italic text-gray-500">No value</div>;
    }

    if (assignee.userId) {
        return (
            <div
                className="grid items-center gap-8"
                style={{
                    gridTemplateColumns: '32px auto',
                }}
            >
                <Avatar userId={assignee.userId} size={32} />
                <Value title={assignee.fullName || 'Unknown'} preventWrapping={true} />
            </div>
        );
    }

    return (
        <p
            className="select-text whitespace-pre-line break-words"
            dangerouslySetInnerHTML={{
                __html: value,
            }}
        />
    );
};

export const ActivityModal = ({
    closeModal,
    activity,
}: {
    closeModal: () => void;
    activity: Activity;
}): ReactElement => {
    const { getUser } = useUserContext();
    const author = getUser(activity.createdByUserId);

    const { before, after, valueKey } = activity.activityData;

    return (
        <Modal width="w-800" title="Activity Detail" closeModal={closeModal}>
            <div className="grid grid-cols-5 justify-between rounded-md bg-blue-50 p-16">
                <div className="col-span-3">
                    <Label title="Performed By" />
                    <div
                        className="grid items-center gap-8"
                        style={{
                            gridTemplateColumns: '32px auto',
                        }}
                    >
                        <Avatar userId={activity.createdByUserId} size={32} />
                        <Value title={author.fullName || 'Unknown'} preventWrapping={true} />
                    </div>
                </div>
                <div>
                    <Label title="Performed At" />
                    <Value title={moment(activity?.createdAt).format('Do MMM YYYY')} />
                </div>
            </div>
            <div className="p-16">
                {activity.segments && (
                    <>
                        <SectionTitle title="Summary" />
                        <div className="my-16">
                            <p className="select-text">
                                {activity.segments.map((segment) => {
                                    return segment.content;
                                })}
                            </p>
                        </div>
                    </>
                )}
                <SectionTitle title="Before Change" />
                <RenderValue value={before} />
                <SectionTitle title="After Change" />
                <RenderValue value={after} />
                {valueKey && valueKey === 'richTextAnswer' && (
                    <>
                        <SectionTitle title="Difference" />
                        <TextDiff textA={getPlainText(before)} textB={getPlainText(after)} />
                    </>
                )}
            </div>
        </Modal>
    );
};
