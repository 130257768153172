import JoditEditor, { Jodit } from 'jodit-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormLabel } from './FormLabel';

/*  This component is a bit of a pain in the ass because the underlying library
    has issues with re-renders being triggered from state changes which cause the
    cursor to reset to the beginning of the textarea whilst typing
    https://github.com/jodit/jodit-react/issues/217

    The docs for this component are also a bit crap, the playground is the best way
    to find out the keys and so on, forget the generated docs. They are useless.
*/

export const getText = (html: string) => {
    return Jodit.modules.Helpers.stripTags(html);
};

let JoditEditorInstance;

export const RichTextEditor = ({
    forceUpdate = '',
    htmlText,
    valueChanged,
    validator,
    placeholder = '',
    label,
    errorMessage = '',
    required,
    disabled = false,
    testId = '',
    showLabel = true,
    onBlur = null,
    dataCy = '',
}: {
    forceUpdate: string;
    htmlText: string;
    valueChanged: (text: string, html: string) => void;
    validator?: (value: string) => boolean;
    placeholder?: string;
    label: string;
    errorMessage?: string;
    required?: boolean;
    rows?: number;
    disabled?: boolean;
    testId?: string;
    showLabel?: boolean;
    onBlur: () => void;
    dataCy?: string;
}) => {
    const editor = useRef(null);
    const [html, setValue] = useState(htmlText || '');
    const [showingError, setShowingError] = useState(false);

    let labelClasses = '';

    if (showingError) {
        labelClasses = 'showing-error text-red';
    } else if (disabled) {
        labelClasses = 'text-black-60';
    } else {
        labelClasses = 'text-black-60';
    }

    useEffect(() => {
        setValue(htmlText || '');
        try {
            if (JoditEditorInstance) {
                JoditEditorInstance.history.clear();
            }
        } catch (err) {
            console.log('Error thrown when attempting to clear the editor history', err);
        }
    }, [forceUpdate]);

    // Memo required or it loses focus
    const config = useMemo(
        () => ({
            id: testId,
            readonly: disabled || false,
            placeholder: placeholder,
            showCharsCounter: false,
            showWordsCounter: false,
            showXPathInStatusbar: false,
            toolbarAdaptive: false,
            uploader: {
                insertImageAsBase64URI: true,
            },
            disablePlugins: ['addNewLine'],
            buttons: 'bold,italic,underline,strikethrough,|,ul,ol,indent,outdent,|,link,image,table,|,eraser',
            // https://stackoverflow.com/questions/71128083/how-to-disable-list-types-for-jodit-editor
            controls: {
                ul: {
                    list: Jodit.atom({
                        default: 'Default',
                    }),
                },
                ol: {
                    list: Jodit.atom({
                        default: 'Default',
                    }),
                },
            },
            events: {
                afterInit: (instance) => {
                    JoditEditorInstance = instance;
                },
            },
        }),
        [],
    );

    const onBlurFn = (htmlText: string) => {
        if (validator) {
            setShowingError(!validator(htmlText));
        }
        if (onBlur) {
            onBlur();
        }
    };

    const onChange = (htmlText: string) => {
        valueChanged(getText(htmlText), htmlText);
    };

    return (
        <div data-cy={dataCy} className="render-html-normally">
            {showLabel && <FormLabel label={label} required={required} containerClasses={labelClasses} />}
            <JoditEditor ref={editor} value={html} config={config} onBlur={onBlurFn} onChange={onChange} />
            {showingError && <span className="text-12 text-black-60">{errorMessage}</span>}
        </div>
    );
};
