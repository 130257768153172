import { ModalCross } from 'assets/modal-cross.svg';
import React, { ReactElement } from 'react';
import { CONSTANTS } from '../../../utility/Constants';
import { ProjectSharedProps } from '../project.view';
import { Activity } from './activity.component';
import { Discussion } from './discussion.component';
import { WorkflowPanel } from './workflow-panel';

const tabs = [
    {
        key: CONSTANTS.DISCUSSION,
        label: CONSTANTS.DISCUSSION,
        testTag: 'DiscussionLog',
    },
    {
        key: CONSTANTS.WORKFLOW,
        label: CONSTANTS.WORKFLOW,
        testTag: 'WorkflowPanel',
    },
    {
        key: CONSTANTS.ACTIVITY,
        label: CONSTANTS.ACTIVITY,
        testTag: 'ActivityLog',
    },
];

export const CollaborationView = (props: ProjectSharedProps): ReactElement => {
    const { fetchActivity, fetchDiscussion, changeCollaborationTab, collaborationTab, closeCollaboration } = props;

    const changeActiveTab = (key) => {
        changeCollaborationTab(key);

        if (key === CONSTANTS.DISCUSSION) {
            fetchDiscussion();
        }

        if (key === CONSTANTS.ACTIVITY) {
            fetchActivity();
        }
    };
    return (
        <div
            className="grid w-full overflow-hidden border-l bg-white"
            style={{
                gridTemplateRows: '46px auto',
                height: `calc(100vh - 64px - ${props.projectClosed ? '32' : '0'}px)`,
            }}
        >
            <div className="flex items-center justify-between border-b-2">
                <ul className="body-2-reg tracking-0.1 flex text-center text-field-outline-blue">
                    {tabs.map((tab) => {
                        return (
                            <li
                                key={tab.key}
                                data-cy={tab.testTag}
                                className={`${
                                    collaborationTab === tab.key
                                        ? 'ml-16 h-48 w-112 cursor-pointer select-none border-b-2 border-field-outline-blue px-24 py-14 text-field-outline-blue'
                                        : 'ml-16 h-48 w-112 cursor-pointer select-none px-24 py-14 text-black-80'
                                }`}
                                onClick={() => {
                                    changeActiveTab(tab.key);
                                }}
                            >
                                {tab.label}
                            </li>
                        );
                    })}
                </ul>
                <div className="cursor-pointer pr-22" onClick={closeCollaboration}>
                    <ModalCross strokeColor="#A0A4A8" />
                </div>
            </div>
            {collaborationTab === CONSTANTS.DISCUSSION && <Discussion {...props} />}
            {collaborationTab === CONSTANTS.ACTIVITY && <Activity dataCy="Activity" {...props} />}
            {collaborationTab === CONSTANTS.WORKFLOW && <WorkflowPanel {...props} />}
        </div>
    );
};
