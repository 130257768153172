import React, { ReactElement } from 'react';

export const Drag = ({ color, styling }: { color: string; styling?: string }): ReactElement => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styling}
        >
            <line x1="5" y1="8" x2="15" y2="8" stroke={color} strokeWidth="2" strokeLinecap="round" />
            <line x1="5" y1="12" x2="15" y2="12" stroke={color} strokeWidth="2" strokeLinecap="round" />
        </svg>
    );
};
