import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const RewriteModal = ({
    closeModal,
    answer,
    setAnswer,
}: {
    closeModal: () => void;
    answer: string;
    setAnswer: (newAnswer: string) => void;
}): ReactElement => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [suggestion, setSuggestion] = useState('');

    const rewriteAnswer = async () => {
        try {
            setIsGenerating(true);

            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.REWRITE_ANSWER);

            const results = await API.post(url, {
                answerText: answer,
            });

            logEvent('PROJECT_ANSWERING_REWRITE_ANSWER', started);

            if (results && results.data) {
                setIsGenerating(false);
                setSuggestion(results.data.answerText);
            }
        } catch (err) {
            showError('There was an error generating a new answer', err);
            setIsGenerating(false);
            setSuggestion('');
        }
    };

    useEffect(() => {
        rewriteAnswer();
    }, []);

    return (
        <Modal width="w-720" closeModal={closeModal} title="Rewrite Answer">
            {isGenerating && <LoadingSpinner />}
            {suggestion && (
                <>
                    <div className="mt-16 rounded-md bg-gray-50 p-16">
                        <div className="mb-8 text-14 uppercase text-gray-500">Your Answer</div>
                        <div className="whitespace-pre-line break-words">{answer}</div>
                    </div>
                    <div className="mt-16 rounded-md bg-blue-50 p-16">
                        <div className="mb-8 text-14 uppercase text-gray-500">AI Suggestion</div>
                        <div className="whitespace-pre-line break-words">{suggestion}</div>
                    </div>

                    <ButtonBar className="mt-16">
                        <Button
                            label="Apply Suggested Answer"
                            onClick={() => {
                                setAnswer(suggestion);
                                closeModal();
                            }}
                            disabled={isGenerating}
                            loading={isGenerating}
                        />
                    </ButtonBar>
                </>
            )}
        </Modal>
    );
};
