import { Avatar } from 'components/duex/Avatar';
import { getPillForComplianceStatus } from 'components/duex/CompliancePill';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { Tabs } from 'components/duex/Tabs';
import { showError } from 'components/error-toast.component';
import _find from 'lodash/find';
import _keyBy from 'lodash/keyBy';
import { Label, Value } from 'modules/answer-library/analytics/typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Project, User } from 'types';
import Team from 'types/team.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export interface ProjectInvite {
    projectInviteId: string;
    submittedAssessment: {
        responses: {
            assessmentQuestionId: string;
            responseClassification: string;
            richTextAnswer: string;
        }[];
    };
    sentByUser: User;
    sentByTeam: Team;
    claimedByUser: User;
    claimedByTeam: Team;
    submittedByUser: User;
    createdAt: string;
    updatedAt: string;
    submittedAt: string;
    inviteStatus: string;
    claimable: boolean;
}

export function ViewSubmission({
    closeModal,
    submission,
    project,
}: {
    closeModal: () => void;
    submission: ProjectInvite;
    project: Project;
}) {
    const [assessmentList, setAssessmentList] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [assessmentTabs, setAssessmentTabs] = useState([]);

    const fetchData = async () => {
        try {
            setFetchingData(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_GET_FULL_TEMPLATES);
            const result = await API.get(url);

            setFetchingData(false);

            if (result) {
                setAssessmentList(result.data);
            }
        } catch (err) {
            showError('There was an error retrieving your Assessment Templates', err);
            setFetchingData(false);
        }
    };

    const requiredAssessments = [];

    for (const key in project.assessmentConfiguration.requiredAssessments) {
        if (project.assessmentConfiguration.requiredAssessments[key] === true) {
            const assessment = _find(assessmentList, {
                assessmentId: key,
            });
            if (assessment) {
                requiredAssessments.push(assessment);
            }
        }
    }

    const switchTab = (selectedTab) => {
        setAssessmentTabs(
            requiredAssessments.map((assessment) => {
                return {
                    label: assessment.assessmentName,
                    key: assessment.assessmentId,
                    isActive: selectedTab.key === assessment.assessmentId,
                };
            }),
        );
    };

    const selectedTab = _find(assessmentTabs, { isActive: true });

    const responses = _keyBy(submission.submittedAssessment.responses, 'assessmentQuestionId');

    const selectedAssessment = _find(assessmentList, {
        assessmentId: selectedTab?.key,
    });

    useEffect(() => {
        fetchData();
    }, [submission.projectInviteId]);

    useEffect(() => {
        if (!selectedTab && requiredAssessments.length > 0) {
            switchTab({
                key: requiredAssessments[0].assessmentId,
            });
        }
    }, [assessmentList, selectedAssessment]);

    // console.log('submission', submission);

    return (
        <Modal width="w-95-percent" height="full" closeModal={closeModal} title="View Submission">
            {fetchingData && <LoadingSpinner />}
            {!fetchingData && (
                <div className="grid gap-16">
                    <div className="rounded-md p-16 bg-blue-50">
                        <div className="grid grid-cols-4 justify-between rounded-t-md bg-blue-50 p-16">
                            <div className="flex flex-row gap-16 items-center h-48">
                                <img src={submission.claimedByTeam.teamLogo} className="bg-cover h-48" />
                                <span className="text-xl">{submission.claimedByTeam.name}</span>
                            </div>
                            <div className="col-span-2">
                                <Label title="Accepted by" />
                                <div
                                    className="grid items-center gap-8"
                                    style={{
                                        gridTemplateColumns: '32px auto',
                                    }}
                                >
                                    <Avatar user={submission.claimedByUser} size={32} />
                                    <Value
                                        title={submission.claimedByUser.fullName || 'Unknown'}
                                        preventWrapping={true}
                                    />
                                </div>
                                <Label title="Submitted by" />
                                <div
                                    className="grid items-center gap-8"
                                    style={{
                                        gridTemplateColumns: '32px auto',
                                    }}
                                >
                                    <Avatar user={submission.submittedByUser} size={32} />
                                    <Value
                                        title={submission.submittedByUser.fullName || 'Unknown'}
                                        preventWrapping={true}
                                    />
                                </div>
                            </div>
                            <div className="">
                                <div>
                                    <Label title="Invited" />
                                    <Value title={moment(submission?.createdAt).format('Do MMM YYYY HH:mm')} />
                                </div>
                                <div>
                                    <Label title="Submitted" />
                                    <Value title={moment(submission?.submittedAt).format('Do MMM YYYY HH:mm')} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <Tabs tabItems={assessmentTabs} onChange={switchTab} />
                    {selectedAssessment && (
                        <div className="grid gap-16">
                            {selectedAssessment.assessmentConfiguration.map((item) => {
                                const isSection = item.itemType === 'SECTION';
                                const answer = responses[item.itemId];

                                return (
                                    <div
                                        className={`${
                                            isSection ? 'bg-blue-50' : 'bg-gray-50'
                                        } rounded-md p-8 grid gap-16`}
                                    >
                                        <div className="flex flex-row justify-between items-center">
                                            <span className="select-text">{item.itemLabel}</span>
                                            {!isSection && (
                                                <div>
                                                    {answer &&
                                                        getPillForComplianceStatus(answer.responseClassification)}
                                                    {/* <Pill colour={LIGHTBLUE} title={item.itemType} /> */}
                                                </div>
                                            )}
                                        </div>
                                        {answer && (
                                            <div
                                                className="select-text render-html-normally text-14"
                                                dangerouslySetInnerHTML={{
                                                    __html: answer.richTextAnswer,
                                                }}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            )}
        </Modal>
    );
}
