import React, { ReactElement, ReactNode } from 'react';

const Row = ({
    resetDefaultStyling,
    onClickHandler,
    children,
    className,
    customStyling,
    style = {},
    spacing = 'py-8',
    dataCy = '',
}: {
    resetDefaultStyling?: boolean;
    onClickHandler?: (event) => void;
    children: ReactNode;
    className?: string;
    customStyling?: string;
    dataCy?: string;
    spacing?: string;
    style?: {
        [key: string]: string;
    };
}): ReactElement => {
    return (
        <div
            className={`${resetDefaultStyling ? customStyling : 'flex items-center'} ${spacing} ${className}`}
            onClick={onClickHandler}
            data-cy={dataCy}
            style={{
                ...style,
            }}
        >
            {children}
        </div>
    );
};

export default Row;
