import { showError } from 'components/error-toast.component';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import React, { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import Division from 'types/division.type';
import Team from 'types/team.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { useUserContext } from './useUserContext';

interface TeamContext {
    settings: Team;
    refresh: () => void;
    hasFeature: (featureName: string) => boolean;
    teamDivisions: Division[];
    userDivisions: Division[];
}

const TeamContext = createContext<TeamContext>({
    settings: {},
    refresh: () => {
        // Do nothing
    },
    hasFeature: () => {
        // Do nothing
        return false;
    },
    teamDivisions: [],
    userDivisions: [],
});

export const TeamContextProvider = ({ children }: { children: ReactElement }): ReactElement => {
    const { userHasTeam, user, teamList } = useUserContext();
    const [settings, setSettings] = useState<Team>({});
    const [teamDivisions, setTeamDivisions] = useState<Division[]>([]);
    const [userDivisions, setUserDivisions] = useState<Division[]>([]);

    const fetchDivisions = async () => {
        if (!userHasTeam) {
            setTeamDivisions([]);
        } else {
            try {
                const url = ENDPOINTS.getUrl(CONSTANTS.GET_DIVISIONS);
                const result = await API.get(url);

                if (result) {
                    setTeamDivisions(result.data);
                }
            } catch (err) {
                showError('There was an error retrieving your divisions', err);
            }
        }
    };

    const fetchData = async () => {
        if (!userHasTeam) {
            setSettings({});
        } else {
            try {
                const url = ENDPOINTS.getUrl(CONSTANTS.GET_TEAM);
                const result = await API.get(url);

                if (result) {
                    setSettings(result.data);
                }
            } catch (err) {
                showError('There was an error retrieving your team settings', err);
            }
        }
    };

    const getDivisionById = (divisionId: string) => {
        return _find(teamDivisions, {
            divisionId,
        });
    };

    const computeDivisions = () => {
        if (!user || !teamList.length) {
            return;
        }
        const userMembership = _find(teamList, {
            userId: user.userId,
        });

        if (userMembership) {
            // Get the baseline of divisions this user has access to

            const explicitDivisions = _filter(teamDivisions, (division: Division) => {
                return userMembership.divisions.indexOf(division.divisionId) !== -1;
            });

            const allUserDivisions = [...explicitDivisions];

            // Now expand this based on the children of those divisions

            // This code only works for 3 layers of hierachy.
            teamDivisions.forEach((division) => {
                const alreadyAdded = _find(allUserDivisions, {
                    divisionId: division.divisionId,
                });

                if (!alreadyAdded && division.parentDivisionId) {
                    const parent = getDivisionById(division.parentDivisionId);

                    const parentHasAccess = _find(allUserDivisions, {
                        divisionId: parent.divisionId,
                    });

                    if (parentHasAccess) {
                        // parent has access, so implicitly this gets access
                        allUserDivisions.push(division);
                    } else if (!parentHasAccess && parent.parentDivisionId) {
                        const grandParent = getDivisionById(parent.parentDivisionId);

                        const grandParentHasAccess = _find(allUserDivisions, {
                            divisionId: grandParent.divisionId,
                        });

                        if (grandParentHasAccess) {
                            // grant parent has access, so implicitly this gets access
                            allUserDivisions.push(division);
                        }
                    }
                }
            });

            setUserDivisions(allUserDivisions);
        } else {
            setUserDivisions([]);
        }
    };

    const hasFeature = (featureName: string) => {
        return _get(settings, featureName, false);
    };

    useEffect(() => {
        fetchData();
        fetchDivisions();
    }, [userHasTeam]);

    useEffect(computeDivisions, [user, teamList, teamDivisions]);

    const store = {
        settings,
        refresh: () => {
            fetchData();
            fetchDivisions();
        },
        hasFeature,
        userDivisions,
        teamDivisions,
    };

    return <TeamContext.Provider value={store}>{children}</TeamContext.Provider>;
};

export const useTeamContext = (): TeamContext => useContext(TeamContext);
