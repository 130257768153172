import React, { ReactElement } from 'react';

export const AnswerExport = ({ strokeColor = 'currentColor' }: { strokeColor?: string }): ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.75 0.25H19C19.1989 0.25 19.3897 0.329018 19.5303 0.46967C19.671 0.610322 19.75 0.801088 19.75 1V6.25C19.75 6.66421 19.4142 7 19 7C18.5858 7 18.25 6.66421 18.25 6.25V2.81066L12.8535 8.79287C12.5607 9.08577 12.0858 9.08577 11.7929 8.79287C11.5 8.49997 11.5 8.02517 11.7929 7.73227L17.1893 1.75H13.75C13.3358 1.75 13 1.41421 13 1C13 0.585787 13.3358 0.25 13.75 0.25ZM3.25 4.75C2.42157 4.75 1.75 5.42157 1.75 6.25V16.75C1.75 17.5784 2.42157 18.25 3.25 18.25H13.75C14.5784 18.25 15.25 17.5784 15.25 16.75V8.5C15.25 8.08579 15.5858 7.75 16 7.75C16.4142 7.75 16.75 8.08579 16.75 8.5V16.75C16.75 18.4069 15.4069 19.75 13.75 19.75H3.25C1.59315 19.75 0.25 18.4069 0.25 16.75V6.25C0.25 4.59315 1.59315 3.25 3.25 3.25H11.5C11.9142 3.25 12.25 3.58579 12.25 4C12.25 4.41421 11.9142 4.75 11.5 4.75H3.25Z"
                fill={strokeColor}
            />
            <path
                d="M6.64542 15H4.99769L7.50835 7.72727H9.48988L11.997 15H10.3493L8.52752 9.3892H8.4707L6.64542 15ZM6.54244 12.1413H10.4345V13.3416H6.54244V12.1413Z"
                fill={strokeColor}
            />
        </svg>
    );
};
