import { Add } from 'assets/add.svg';
import { Button } from 'components/duex/Button';
import { IconButton } from 'components/duex/IconButton';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import { GRAY, GREEN, Pill, RED, YELLOW } from 'components/pill.component';
import { complianceLabel } from 'data/compliance-status-options';
import _filter from 'lodash/filter';
import _isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { v4 as uuidv4 } from 'uuid';

const textInputClasses = `py-4 px-8 my-4 rounded-md w-full`;

const AddButton = ({ complianceType, addAlias }: { complianceType: string; addAlias: (aliasType: string) => void }) => {
    return (
        <IconButton
            title="Add another compliance option"
            Icon={Add}
            onClick={() => {
                addAlias(complianceType);
            }}
        />
    );
};

const Aliases = ({
    complianceType,
    aliases,
    updateAlias,
}: {
    complianceType: string;
    aliases: string[];
    updateAlias: (aliasId: string, newValue: string) => void;
}) => {
    const relevantAliases = _filter(aliases, {
        complianceType,
    });

    if (relevantAliases.length === 0) {
        return null;
    }

    return (
        <>
            {_map(relevantAliases, (alias) => {
                return (
                    <input
                        key={alias.id}
                        data-cy="Entire_Project_Custom_Compliance"
                        type="text"
                        placeholder={complianceLabel(alias.complianceType)}
                        value={alias.label}
                        onChange={(ev) => {
                            updateAlias(alias.id, ev.target.value);
                        }}
                        className={textInputClasses}
                    />
                );
            })}
        </>
    );
};

export const ComplianceModal = ({
    closeModal,
    projectId,
}: {
    closeModal: () => void;
    projectId: string;
}): ReactElement => {
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [fetchingData, setFetchingData] = useState(false);
    const [fetchingConfiguration, setFetchingConfiguration] = useState(false);
    const [savingData, setSavingData] = useState(false);
    const [sources, setSources] = useState([]);
    const [currentConfiguration, setCurrentConfiguration] = useState({
        aliases: [],
    });
    const [configuration, setConfiguration] = useState({
        aliases: [],
    });

    const getConfiguration = async () => {
        try {
            setFetchingConfiguration(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.COMPLIANCE_OVERRIDE, {
                projectId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                setCurrentConfiguration(results.data);
                setConfiguration(results.data);
            }
            setFetchingConfiguration(false);
        } catch (err) {
            showError('There was an error fetching your current configuration', err);
            setFetchingConfiguration(false);
        }
    };

    const fetchData = async () => {
        try {
            setFetchingData(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.GET_SOURCE_DOCS, {
                projectId,
            });

            const results = await API.get(url);
            if (results && results.data) {
                const tempSources = [];

                results.data.forEach((file) => {
                    if (file.fileName) {
                        tempSources.push({
                            identifier: file.projectSourceId,
                            label: file.fileName,
                            type: 'FILE',
                            parent: projectId,
                        });
                    }

                    if (file.sheets) {
                        file.sheets.forEach((sheet) => {
                            tempSources.push({
                                identifier: sheet.sheetId,
                                label: sheet.sheetName,
                                type: 'SHEET',
                                parent: file.projectSourceId,
                            });
                        });
                    }
                });
                setSources(tempSources);
            }
            setFetchingData(false);
        } catch (err) {
            showError('There was an error fetching the list of source documents', err);
            setFetchingData(false);
        }
    };

    const saveChanges = async () => {
        try {
            setSavingData(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.COMPLIANCE_OVERRIDE, {
                projectId,
            });

            const results = await API.post(url, prepareConfiguration());

            if (results && results.data) {
                setCurrentConfiguration(results.data);
                setConfiguration(results.data);
            }

            setSavingData(false);
        } catch (err) {
            showError('There was an error fetching your current configuration', err);
            setSavingData(false);
        }
    };

    const prepareConfiguration = () => {
        return {
            ...configuration,
            aliases: _filter(configuration.aliases, (alias) => {
                return alias.label.trim();
            }),
        };
    };

    const undoChanges = () => {
        setConfiguration(currentConfiguration);
    };

    const reset = () => {
        setConfiguration({
            aliases: [],
        });
    };

    const compareChanges = () => {
        setUnsavedChanges(!_isEqual(currentConfiguration, configuration));
    };

    const update = (identifier: string, propertyName: string, newValue: string) => {
        setConfiguration({
            ...configuration,
            [identifier]: {
                ...(configuration[identifier] || {}),
                [propertyName]: newValue,
            },
        });
    };

    const addAlias = (complianceType: string) => {
        const existingAliases = configuration.aliases || [];

        setConfiguration({
            ...configuration,
            aliases: [
                ...existingAliases,
                {
                    complianceType,
                    id: uuidv4(),
                    label: '',
                },
            ],
        });
    };

    const updateAlias = (identifier: string, newLabel: string) => {
        setConfiguration({
            ...configuration,
            aliases: _map(configuration.aliases, (alias) => {
                if (alias.id === identifier) {
                    return {
                        ...alias,
                        label: newLabel,
                    };
                }
                return alias;
            }),
        });
    };

    useEffect(() => {
        compareChanges();
    }, [currentConfiguration, configuration]);

    useEffect(() => {
        fetchData();
        getConfiguration();
    }, [projectId]);

    const headerClasses = `p-16 text-left text-12 sticky top-0 z-1 bg-white border-b border-gray-100 text-gray-500`;
    const cellClasses = `p-8 whitespace-pre-line break-words overflow-hidden font-body`;
    const rowClasses = `rounded-lg text-12 overflow-hidden hover:bg-gray-50`;

    if (fetchingData || fetchingConfiguration) {
        return;
    }

    const isLoading = fetchingData || fetchingConfiguration;

    return (
        <Modal
            width="w-95-percent"
            closeSafely={() => {
                return !unsavedChanges;
            }}
            closeModal={closeModal}
            title="Manage Compliance"
        >
            {isLoading && <LoadingSpinner />}
            {!isLoading && (
                <div className="h-full overflow-y-auto">
                    <div className="grid grid-cols-2">
                        <div className="">
                            <p className="text-14 text-gray-500">
                                When exporting, Pearler can use your compliance status&apos; set here.
                            </p>
                            <ol className="ml-12 list-decimal text-12 text-gray-500">
                                <li>Sheet Configuration (if available)</li>
                                <li>File Configuration (if available)</li>
                                <li>Project Configuration (if available)</li>
                                <li>Pearler Default</li>
                            </ol>
                        </div>
                        <div>
                            <div className="flex flex-row-reverse gap-16">
                                <Button label="Reset" buttonType="SECONDARY" onClick={reset} disabled={savingData} />
                                <Button
                                    label="Save Changes"
                                    onClick={saveChanges}
                                    disabled={!unsavedChanges || savingData}
                                    loading={savingData}
                                />
                                {unsavedChanges && (
                                    <Button
                                        label="Undo Changes"
                                        buttonType="TERTIARY"
                                        disabled={!unsavedChanges || savingData}
                                        onClick={undoChanges}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <table className="mb-32 block w-full">
                        <thead>
                            <tr key="header-row" className="text-black">
                                <th className={`${headerClasses} w-1/5`}>Apply to</th>
                                <th className={`${headerClasses} w-1/5`}>
                                    <div className="flex flex-row">
                                        <Pill title="Compliant" alt="Compliant" colour={GREEN} />
                                        <AddButton complianceType="COMPLIANT" addAlias={addAlias} />
                                    </div>
                                </th>
                                <th className={`${headerClasses} w-1/5`}>
                                    <div className="flex flex-row">
                                        <Pill title="Partially Compliant" alt="Partially Compliant" colour={YELLOW} />
                                        <AddButton complianceType="PARTIALLY-COMPLIANT" addAlias={addAlias} />
                                    </div>
                                </th>
                                <th className={`${headerClasses} w-1/5`}>
                                    <div className="flex flex-row">
                                        <Pill title="Non Compliant" alt="Non Compliant" colour={RED} />
                                        <AddButton complianceType="NON-COMPLIANT" addAlias={addAlias} />
                                    </div>
                                </th>
                                <th className={`${headerClasses} w-1/5`}>
                                    <div className="flex flex-row">
                                        <Pill title="Not Applicable" alt="Not Applicable" colour={GRAY} />
                                        <AddButton complianceType="NOT-APPLICABLE" addAlias={addAlias} />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="">
                            <tr key={projectId} className={rowClasses}>
                                <td className={cellClasses}>Entire Project</td>
                                <td className={cellClasses}>
                                    <input
                                        data-cy="Entire_Project_Compliant"
                                        type="text"
                                        placeholder={configuration[projectId]?.compliant || 'Compliant'}
                                        value={configuration[projectId]?.compliant || ''}
                                        onChange={(ev) => {
                                            update(projectId, 'compliant', ev.target.value || '');
                                        }}
                                        className={textInputClasses}
                                    />
                                    <Aliases
                                        aliases={configuration.aliases}
                                        complianceType="COMPLIANT"
                                        updateAlias={updateAlias}
                                    />
                                </td>
                                <td className={cellClasses}>
                                    <input
                                        data-cy="Entire_Project_Partially_Compliant"
                                        type="text"
                                        placeholder={
                                            configuration[projectId]?.partiallyCompliant || 'Partially Compliant'
                                        }
                                        value={configuration[projectId]?.partiallyCompliant || ''}
                                        onChange={(ev) => {
                                            update(projectId, 'partiallyCompliant', ev.target.value || '');
                                        }}
                                        className={textInputClasses}
                                    />
                                    <Aliases
                                        aliases={configuration.aliases}
                                        complianceType="PARTIALLY-COMPLIANT"
                                        updateAlias={updateAlias}
                                    />
                                </td>
                                <td className={cellClasses}>
                                    <input
                                        data-cy="Entire_Project_Non_Compliant"
                                        type="text"
                                        placeholder={configuration[projectId]?.nonCompliant || 'Non Compliant'}
                                        value={configuration[projectId]?.nonCompliant || ''}
                                        onChange={(ev) => {
                                            update(projectId, 'nonCompliant', ev.target.value || '');
                                        }}
                                        className={textInputClasses}
                                    />
                                    <Aliases
                                        aliases={configuration.aliases}
                                        complianceType="NON-COMPLIANT"
                                        updateAlias={updateAlias}
                                    />
                                </td>
                                <td className={cellClasses}>
                                    <input
                                        data-cy="Entire_Project_Not_Applicable"
                                        type="text"
                                        placeholder={configuration[projectId]?.notApplicable || 'Not Applicable'}
                                        value={configuration[projectId]?.notApplicable || ''}
                                        onChange={(ev) => {
                                            update(projectId, 'notApplicable', ev.target.value || '');
                                        }}
                                        className={textInputClasses}
                                    />
                                    <Aliases
                                        aliases={configuration.aliases}
                                        complianceType="NOT-APPLICABLE"
                                        updateAlias={updateAlias}
                                    />
                                </td>
                            </tr>
                            {(!configuration.aliases || configuration.aliases.length === 0) &&
                                sources.map((source) => {
                                    return (
                                        <tr key={source.identifier} className={rowClasses}>
                                            <td className={`${cellClasses} ${source.type === 'SHEET' ? 'pl-24' : ''}`}>
                                                <span className="text-gray-400">
                                                    {source.type === 'SHEET' ? 'Sheet' : 'File'}:
                                                </span>{' '}
                                                {source.label}
                                            </td>
                                            <td className={cellClasses}>
                                                <input
                                                    data-cy={source.type + '_Compliant'}
                                                    type="text"
                                                    placeholder={
                                                        configuration[source.identifier]?.compliant ||
                                                        configuration[source.parent]?.compliant ||
                                                        configuration[projectId]?.compliant ||
                                                        'Compliant'
                                                    }
                                                    value={configuration[source.identifier]?.compliant || ''}
                                                    onChange={(ev) => {
                                                        update(source.identifier, 'compliant', ev.target.value || '');
                                                    }}
                                                    className={textInputClasses}
                                                />
                                            </td>
                                            <td className={cellClasses}>
                                                <input
                                                    data-cy={source.type + '_Partially_Compliant'}
                                                    type="text"
                                                    placeholder={
                                                        configuration[source.identifier]?.partiallyCompliant ||
                                                        configuration[source.parent]?.partiallyCompliant ||
                                                        configuration[projectId]?.partiallyCompliant ||
                                                        'Partially Compliant'
                                                    }
                                                    value={configuration[source.identifier]?.partiallyCompliant || ''}
                                                    onChange={(ev) => {
                                                        update(
                                                            source.identifier,
                                                            'partiallyCompliant',
                                                            ev.target.value || '',
                                                        );
                                                    }}
                                                    className={textInputClasses}
                                                />
                                            </td>
                                            <td className={cellClasses}>
                                                <input
                                                    data-cy={source.type + '_Non_Compliant'}
                                                    type="text"
                                                    placeholder={
                                                        configuration[source.identifier]?.nonCompliant ||
                                                        configuration[source.parent]?.nonCompliant ||
                                                        configuration[projectId]?.nonCompliant ||
                                                        'Non Compliant'
                                                    }
                                                    value={configuration[source.identifier]?.nonCompliant || ''}
                                                    onChange={(ev) => {
                                                        update(
                                                            source.identifier,
                                                            'nonCompliant',
                                                            ev.target.value || '',
                                                        );
                                                    }}
                                                    className={textInputClasses}
                                                />
                                            </td>
                                            <td className={cellClasses}>
                                                <input
                                                    data-cy={source.type + '_Not_Applicable'}
                                                    type="text"
                                                    placeholder={
                                                        configuration[source.identifier]?.notApplicable ||
                                                        configuration[source.parent]?.notApplicable ||
                                                        configuration[projectId]?.notApplicable ||
                                                        'Not Applicable'
                                                    }
                                                    value={configuration[source.identifier]?.notApplicable || ''}
                                                    onChange={(ev) => {
                                                        update(
                                                            source.identifier,
                                                            'notApplicable',
                                                            ev.target.value || '',
                                                        );
                                                    }}
                                                    className={textInputClasses}
                                                />
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            )}
        </Modal>
    );
};
