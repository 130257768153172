const Queue = {
    timing: 50,
    tasks: [],
    callback: null,
    interval: null,
    setCallback: (newCallback: (data: unknown) => void) => {
        Queue.callback = newCallback;
    },
    add: (data: unknown) => {
        Queue.tasks.push(data);
        Queue.start();
    },
    start: () => {
        if (!Queue.interval) {
            Queue.interval = setInterval(Queue.process, Queue.timing);
        }
    },
    process: () => {
        if (Queue.tasks.length > 0) {
            const task = Queue.tasks.shift();
            Queue.callback(task);
        } else {
            clearInterval(Queue.interval);
            Queue.interval = null;
        }
    },
};

export default Queue;
