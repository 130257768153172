import { Tabs } from 'components/duex/Tabs';
import _find from 'lodash/find';
import _map from 'lodash/map';
import React, { ReactElement, useEffect, useState } from 'react';
import { CONFIG } from 'utility/Config';
import { ProjectDashboardComponents } from '../project-dashboard.view';
import { BuyerCards } from './cards.component';
import { Invitations } from './invitations';
import { Requirements } from './requirements';
import { Submissions } from './submissions';

const initialTabs = [
    {
        label: 'Requirements',
        key: 'REQUIREMENTS',
        isActive: true,
        component: Requirements,
    },
    {
        label: 'Submissions',
        key: 'SUBMISSIONS',
        isActive: false,
        component: Submissions,
    },
    {
        label: 'Invitations',
        key: 'INVITATIONS',
        isActive: false,
        component: Invitations,
    },
];

export const BuyerOversight = (props: ProjectDashboardComponents): ReactElement => {
    const [screensize, changeScreenSize] = useState(window.innerWidth);
    const [tabs, setTabs] = useState(initialTabs);

    useEffect(() => {
        window.removeEventListener('resize', () => changeScreenSize(window.innerWidth));
        window.addEventListener('resize', () => changeScreenSize(window.innerWidth));
        return window.removeEventListener('resize', () => changeScreenSize(window.innerWidth));
    }, []);

    const cards = [
        {
            title: 'Unaccepted Invitations',
            currentValue: 1,
            totalValue: 2,
        },
        {
            title: 'Completed Assessments',
            percentage: 50,
        },
    ];

    const selectedTab = _find(tabs, {
        isActive: true,
    });

    return (
        <div className={`${screensize <= CONFIG.BREAKPOINT_XL ? 'px-24' : 'pl-24'} py-24 flex flex-col gap-32`}>
            <BuyerCards cards={cards} />
            <Tabs
                tabItems={tabs}
                onChange={(selectedTab) => {
                    setTabs(
                        _map(tabs, (tab) => {
                            return {
                                ...tab,
                                isActive: selectedTab.label === tab.label,
                            };
                        }),
                    );
                }}
            />
            {<selectedTab.component {...props} />}
        </div>
    );
};
