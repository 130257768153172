import { ChevronRight } from 'assets/chevron-right.svg';
import { NotificationSymbol } from 'assets/notifications-status-symbol.svg';
import { Avatar } from 'components/duex/Avatar';
import { EmptyState } from 'components/duex/EmptyState';
import { showError } from 'components/error-toast.component';
import { Col, Row } from 'components/table';
import { useUserContext } from 'hooks/useUserContext';
import moment from 'moment';
import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'utility/Api';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { DashboardWidget } from './dashboard-widget';

export function DashboardNotifications(): ReactElement {
    const [isLoading, setIsLoading] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const navigate = useNavigate();

    const { getUser } = useUserContext();

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const url = ENDPOINTS.getUrl(CONSTANTS.FETCH_NOTIFICATIONS);
            const response = await API.get(url);

            if (response && response.data) {
                setNotifications(response.data);
            }

            setIsLoading(false);
        } catch (err) {
            showError('There was a problem retrieving your notifications', err);
            setIsLoading(false);
        }
    };

    const showNotification = async ({ notificationId, linkReferencePath }) => {
        const url = ENDPOINTS.getUrl(CONSTANTS.READ_NOTIFICATIONS, {
            notificationId,
        });
        await API.post(url, {});

        // Mark notification as read and navigate user to the source
        navigate(linkReferencePath);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DashboardWidget
            isLoading={isLoading}
            title="Notifications"
            showMoreTitle="See More"
            showMoreLink={ROUTES.NOTIFICATIONS}
            drawBorder={true}
        >
            {notifications.length === 0 && (
                <div className="flex h-full flex-col items-center justify-center">
                    <EmptyState
                        width="w-360"
                        title="No Notifications"
                        subtitle="Notifications are sent to you when team mates mention you, or assign Questions and Projects to you."
                    />
                </div>
            )}

            {notifications.map((notification, index) => {
                const user = getUser(notification.causedByUserId);
                const lastItem = notifications.length === index + 1;

                return (
                    <Row
                        dataCy="Notification_Row"
                        spacing="py-16"
                        key={notification.notificationId}
                        className={`cursor-pointer border-gray-100 hover:bg-blue-50 ${!lastItem ? 'border-b' : ''}`}
                        onClickHandler={() => {
                            showNotification(notification);
                        }}
                    >
                        <Col className="w-1-of-12 pl-12">
                            {notification.readAt !== null ? (
                                <NotificationSymbol {...{ color: '#EEF3FB' }} />
                            ) : (
                                <NotificationSymbol {...{ color: '#FF9100' }} />
                            )}
                        </Col>
                        <Col className="w-1-of-12">
                            <Avatar size={32} userId={notification.causedByUserId} />
                        </Col>
                        <Col className="w-3-of-12">
                            <p className="body-2-reg tracking-0.2 truncate text-black-100">{user.fullName}</p>
                            <p className="small1-medium tracking-0.2 mt-4 text-light-gray">
                                {moment(notification.createdAt).fromNow()}
                            </p>
                        </Col>
                        <Col className="body-2-reg tracking-0.2 w-6-of-12 truncate px-8 text-black-100">
                            <p>{notification.description}</p>
                        </Col>
                        <Col className="flex w-1-of-12 flex-row-reverse pr-16">
                            <ChevronRight />
                        </Col>
                    </Row>
                );
            })}
        </DashboardWidget>
    );
}
