import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { Modal } from 'components/duex/Modal';
import { showError } from 'components/error-toast.component';
import React, { ReactElement, useEffect, useState } from 'react';
import { logEvent } from 'utility/Analytics';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const GenerateModal = ({
    question,
    information,
    closeModal,
    setAnswer,
}: {
    question: string;
    information: string;
    closeModal: () => void;
    setAnswer: (newAnswer: string) => void;
}): ReactElement => {
    const [isGenerating, setIsGenerating] = useState(false);
    const [suggestion, setSuggestion] = useState<{ answerRichText: string; answerText: string }>(null);

    const generateAnswer = async () => {
        try {
            setIsGenerating(true);

            const started = new Date();

            const url = ENDPOINTS.getUrl(CONSTANTS.GENERATE_NEW_ANSWER);

            const results = await API.post(url, {
                questionText: [question, information].join('/n'),
            });

            logEvent('PROJECT_ANSWERING_GENERATE_ANSWER', started);

            if (results && results.data) {
                setIsGenerating(false);
                setSuggestion(results.data);
            }
        } catch (err) {
            showError('There was an error generating a new answer', err);
            setIsGenerating(false);
            setSuggestion(null);
        }
    };

    useEffect(() => {
        generateAnswer();
    }, []);

    return (
        <Modal width="w-720" closeModal={closeModal} title="Generate Answer">
            {isGenerating && <LoadingSpinner />}
            {suggestion && (
                <>
                    <div className="mt-16 rounded-md bg-blue-50 p-16">
                        <div className="mb-8 text-14 uppercase text-gray-500">AI Suggestion</div>
                        <div className="whitespace-pre-line break-words">{suggestion.answerText}</div>
                    </div>
                    <ButtonBar className="mt-16">
                        <Button
                            label="Use Suggested Answer"
                            onClick={() => {
                                setAnswer(suggestion.answerRichText);
                                closeModal();
                            }}
                            disabled={isGenerating}
                            loading={isGenerating}
                        />
                    </ButtonBar>
                </>
            )}
        </Modal>
    );
};
