import { DropDownWithSearch } from 'components/dropdown-with-search.component';
import _find from 'lodash/find';
import React, { ReactElement, useEffect, useState } from 'react';
import { ProjectSharedProps } from '../project.view';

export const QuestionAssignee = ({
    questionId,
    question,
    projectTeam,
    updateQuestionAssignee,
    updatingAssignee,
}: ProjectSharedProps): ReactElement => {
    const refineUser = (user) => {
        const ticketAssignedTo = {
            id: '',
            name: 'Unassigned',
            photo: '',
        };
        if (user) {
            ticketAssignedTo.id = user.member.userId;
            ticketAssignedTo.name = user.member.fullName;
            ticketAssignedTo.photo = user.member.photo;
        }
        return { ...ticketAssignedTo, ...user };
    };

    const initialSelectedMember = refineUser(_find(projectTeam, ['member.userId', question.assignedToUserId]));
    const [selectedMember, updateSelectedMember] = useState(initialSelectedMember);
    useEffect(() => {
        updateSelectedMember(refineUser(_find(projectTeam, ['member.userId', question.assignedToUserId])));
    }, [questionId, question.assignedToUserId]);

    return (
        <DropDownWithSearch
            teamMembers={projectTeam}
            selectedMember={selectedMember}
            showArrow={true}
            classForInput="flex items-center"
            showThumbNail={true}
            stylingOfValue="w-100 ml-12 inline font-normal text-14 leading-20 tracking-0.1 text-light-black select-none"
            showListItemCheck={true}
            callBackFunction={updateQuestionAssignee}
            defaultItem={{
                name: 'Unassign',
                id: '',
                userId: '',
                photo: '',
                emailAddress: '',
                skipSorting: true,
                member: {
                    givenName: 'Unassign',
                    lastName: '',
                    fullName: 'Unassign',
                },
            }}
            showLoading={updatingAssignee}
            alwaysShowDefault={false}
            dataCy="Question_Assignee_Dropdown"
        />
    );
};
