import React, { ReactElement } from 'react';

export default function FilterResultCount({
    totalQuestionCount,
    filteredTotalQuestionCount,
    isFilterApplied,
    isSearchApplied,
    onClickHandler,
}: {
    totalQuestionCount: number;
    filteredTotalQuestionCount: number;
    isFilterApplied: boolean;
    isSearchApplied: boolean;
    onClickHandler?: () => void;
}): ReactElement {
    let message = '';

    if (isFilterApplied || isSearchApplied) {
        message = `Showing ${filteredTotalQuestionCount} of ${totalQuestionCount}`;
    } else {
        message = `${totalQuestionCount} questions`;
    }

    return (
        <div
            data-cy="Filter_Result_Count"
            className={`flex ${
                isFilterApplied || isSearchApplied
                    ? 'justify-between bg-heading-blue text-white'
                    : 'justify-center bg-faded-blue text-app-blue'
            } px-16 py-4 text-12 font-500 leading-16`}
        >
            {isFilterApplied || isSearchApplied ? <div className="invisible select-none">Reset</div> : null}
            <div className={`select-none text-center`}>{message}</div>
            {isFilterApplied || isSearchApplied ? (
                <div
                    data-cy="ResetFilter"
                    className={`
                     ' cursor-pointer select-none text-right underline`}
                    onClick={onClickHandler}
                >
                    Reset
                </div>
            ) : null}
        </div>
    );
}
