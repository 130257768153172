import { Avatar } from 'components/duex/Avatar';
import { useUserContext } from 'hooks/useUserContext';
import { SettingsItem, SettingsSection } from 'modules/settings/settings-components';
import moment from 'moment';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Question } from 'types';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';

const Label = ({ title }: { title: string }) => {
    return <p className="select-none text-14 font-medium text-gray-600">{title}</p>;
};
const Value = ({ title, preventWrapping = false }: { title: string; preventWrapping?: boolean }) => {
    return (
        <p
            className={`text-black-90 h-40 text-14 leading-40 ${
                preventWrapping ? 'overflow-hidden text-ellipsis whitespace-nowrap' : ''
            }`}
        >
            {title}
        </p>
    );
};

export const CombinedComponent = ({ question }: { question: Question }): ReactElement => {
    const { getUser } = useUserContext();
    const author = getUser(question.answeredByUserId);
    return (
        <div className="">
            <div className="mx-16 grid grid-cols-3">
                <div>
                    <Label title="Project Name" />
                    <Link
                        to={createUrl(ROUTES.PROJECT_QUESTION_ANSWERING, {
                            questionId: question.questionId,
                            projectId: question.projectId,
                        })}
                    >
                        <span className="h-40 text-14 leading-40 text-primary-blue-100 hover:underline">
                            {question.project.name}
                        </span>
                    </Link>
                </div>
                <div>
                    <Label title="Author" />

                    <div
                        className="grid items-center gap-8"
                        style={{
                            gridTemplateColumns: '32px auto',
                        }}
                    >
                        <Avatar userId={question.answeredByUserId} size={32} />
                        <Value title={author.fullName} preventWrapping={true} />
                    </div>
                </div>
                <div>
                    <Label title="Written" />
                    <Value title={moment(question.updatedAt).format('Do MMM YYYY')} />
                </div>
                <div>
                    {question.information && (
                        <>
                            <Label title="Information" />
                            <Value title={question.information} />
                        </>
                    )}
                </div>
            </div>

            <SettingsSection title="Question" />
            <SettingsItem>
                <div>
                    <p className="select-text whitespace-pre-line break-words">{question.question}</p>
                </div>
            </SettingsItem>
            <SettingsSection title="Answer" />
            <SettingsItem>
                <div
                    className="select-text render-html-normally"
                    dangerouslySetInnerHTML={{
                        __html: question.richTextAnswer,
                    }}
                />
            </SettingsItem>
        </div>
    );
};
