import { Avatar } from 'components/duex/Avatar';
import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormLabel } from 'components/duex/FormLabel';
import { FormTextField } from 'components/duex/FormTextField';
import { LoadingSpinner } from 'components/duex/LoadingSpinner';
import { showError } from 'components/error-toast.component';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import React, { useEffect, useState } from 'react';
import { AssessmentConfiguration } from 'types/project.type';
import { API } from 'utility/Api';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';
import { ProjectDashboardComponents } from '../project-dashboard.view';

const validKeys = ['instructions', 'requiredAssessments', 'description', 'contactName', 'contactEmail', 'contactPhone'];

export const Requirements = ({ project, updateProjectInfo, loading }: ProjectDashboardComponents) => {
    const getOriginalConfig = () => {
        return _pick(project.assessmentConfiguration, validKeys);
    };

    const [assessmentList, setAssessmentList] = useState([]);
    const [fetchingData, setFetchingData] = useState(false);
    const [configuration, setConfiguration] = useState<AssessmentConfiguration>(getOriginalConfig());

    const fetchData = async () => {
        try {
            setFetchingData(true);
            const url = ENDPOINTS.getUrl(CONSTANTS.ASSESSMENT_GET_TEMPLATES);
            const result = await API.get(url);

            setFetchingData(false);

            if (result) {
                setAssessmentList(result.data);
            }
        } catch (err) {
            showError('There was an error retrieving your Assessment Templates', err);
            setFetchingData(false);
        }
    };

    const getCurrentConfig = () => {
        return _pick(configuration, validKeys);
    };

    const saveChanges = () => {
        updateProjectInfo('assessmentConfiguration', getCurrentConfig());
    };

    const undoChanges = () => {
        setConfiguration(getOriginalConfig());
    };

    const hasChanges = !_isEqual(getOriginalConfig(), getCurrentConfig());

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="grid gap-32">
            <div className="">
                <FormLabel label="Assessments to complete" />
                {fetchingData && <LoadingSpinner />}
                {!fetchingData &&
                    assessmentList.map((assessment) => {
                        let setting = false;

                        if (
                            configuration.requiredAssessments &&
                            configuration.requiredAssessments[assessment.assessmentId]
                        ) {
                            setting = configuration.requiredAssessments[assessment.assessmentId];
                        }

                        const updateSelection = () => {
                            setConfiguration({
                                ...configuration,
                                requiredAssessments: {
                                    ...configuration.requiredAssessments,
                                    [assessment.assessmentId]: !setting,
                                },
                            });
                        };

                        return (
                            <div
                                className={`flex flex-row gap-16 rounded-lg p-8 hover:bg-blue-50 hover:opacity-100 items-center cursor-pointer ${
                                    setting ? '' : 'opacity-70'
                                }`}
                                style={{
                                    gridTemplateColumns: '32px 32px auto',
                                }}
                                key={assessment.assessmentId}
                                onClick={updateSelection}
                            >
                                <input
                                    type="checkbox"
                                    className="h-16 w-16 border-gray-200"
                                    checked={setting}
                                    value={'checked'}
                                    onChange={updateSelection}
                                />
                                <Avatar size={32} userId={assessment.createdByUserId} />
                                <div className="select-none">
                                    <div className={`truncate text-black`}>{assessment.assessmentName}</div>
                                    <div className={`truncate text-gray-500 text-14`}>
                                        {assessment.assessmentDescription}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            {/* <FormTextField
                label="Instructions"
                inputType="resizing-textarea"
                defaultValue={configuration.instructions || ''}
                valueChanged={(newValue: string) => {
                    setConfiguration({
                        ...configuration,
                        instructions: newValue,
                    });
                }}
            /> */}
            <FormTextField
                label="Project Description"
                inputType="resizing-textarea"
                defaultValue={configuration.description || ''}
                valueChanged={(newValue: string) => {
                    setConfiguration({
                        ...configuration,
                        description: newValue,
                    });
                }}
            />
            <FormTextField
                label="Contact Name"
                defaultValue={configuration.contactName || ''}
                valueChanged={(newValue: string) => {
                    setConfiguration({
                        ...configuration,
                        contactName: newValue,
                    });
                }}
            />
            <FormTextField
                label="Contact Email"
                defaultValue={configuration.contactEmail || ''}
                valueChanged={(newValue: string) => {
                    setConfiguration({
                        ...configuration,
                        contactEmail: newValue,
                    });
                }}
            />
            <FormTextField
                label="Contact Number"
                defaultValue={configuration.contactPhone || ''}
                valueChanged={(newValue: string) => {
                    setConfiguration({
                        ...configuration,
                        contactPhone: newValue,
                    });
                }}
            />
            <ButtonBar className="mx-16 pb-32">
                <Button
                    label="Save Changes"
                    disabled={!hasChanges || loading}
                    loading={loading}
                    onClick={saveChanges}
                />
                {hasChanges && (
                    <Button
                        label="Undo Changes"
                        disabled={!hasChanges || loading}
                        buttonType="SECONDARY"
                        onClick={undoChanges}
                    />
                )}
            </ButtonBar>
        </div>
    );
};
