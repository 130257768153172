import React, { ReactElement } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

interface ErrorComponentProps {
    autoCloseDuration?: number;
    hideProgressBar?: boolean;
}

export const Error = ({ autoCloseDuration, hideProgressBar }: ErrorComponentProps): ReactElement => {
    return (
        <ToastContainer
            position={'top-right'}
            autoClose={autoCloseDuration || 3000}
            hideProgressBar={hideProgressBar || true}
            newestOnTop={false}
            transition={Slide}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    );
};
