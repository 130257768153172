import { Button } from 'components/duex/Button';
import { ButtonBar } from 'components/duex/ButtonBar';
import { FormTextField } from 'components/duex/FormTextField';
import { Modal } from 'components/duex/Modal';
import { InformationNotice } from 'components/duex/Notice';
import { showError } from 'components/error-toast.component';
import _isEqual from 'lodash/isEqual';
import _pick from 'lodash/pick';
import React, { ReactElement, useState } from 'react';
import { Question } from 'types';
import { logEvent } from 'utility/Analytics';

const changeableProperties = ['referenceNumber', 'information', 'question', 'sectionDescription'];

export const EditQuestionModal = ({
    question,
    closeModal,
    saveQuestion,
}: {
    question: Question;
    closeModal: () => void;
    saveQuestion: (updates: Question) => Promise<boolean>;
}): ReactElement => {
    const [saving, setSaving] = useState(false);
    const [updatedQuestion, setUpdatedQuestion] = useState(question);

    const save = async () => {
        try {
            const started = new Date();
            setSaving(true);

            const result = await saveQuestion(_pick(updatedQuestion, changeableProperties));

            if (result) {
                logEvent(`EDIT_QUESTION`, started);
                setSaving(false);

                closeModal();
            }
        } catch (err) {
            setSaving(false);
            showError('There was an error updating your question', err);
        }
    };

    const updateField = (propertyName, newValue) => {
        setUpdatedQuestion({
            ...updatedQuestion,
            [propertyName]: newValue,
        });
    };

    const saveEnabled = !_isEqual(_pick(question, changeableProperties), _pick(updatedQuestion, changeableProperties));

    return (
        <Modal width="w-800" closeModal={closeModal} title="Update Question">
            <form className="grid gap-16">
                <InformationNotice
                    title="Imported information"
                    description="This form allows you to update fields on this question. If you re-run your import from the original document, you might overwrite these values."
                />
                <FormTextField
                    required={false}
                    defaultValue={updatedQuestion.referenceNumber || ''}
                    label="Reference Number"
                    valueChanged={(updateValue: string) => {
                        updateField('referenceNumber', updateValue);
                    }}
                    placeholder="ABC-123"
                    maxLength={256}
                />
                <FormTextField
                    required={false}
                    defaultValue={updatedQuestion.sectionDescription || ''}
                    label="Section"
                    valueChanged={(updateValue: string) => {
                        updateField('sectionDescription', updateValue);
                    }}
                    placeholder="Add section description here"
                    maxLength={256}
                />
                <FormTextField
                    inputType="textarea"
                    required={false}
                    defaultValue={updatedQuestion.information || ''}
                    label="Information"
                    valueChanged={(updateValue: string) => {
                        updateField('information', updateValue);
                    }}
                    placeholder="Add information here"
                    maxLength={2048}
                    rows={5}
                />
                <FormTextField
                    inputType="textarea"
                    required={false}
                    defaultValue={updatedQuestion.question}
                    label="Question"
                    valueChanged={(updateValue: string) => {
                        updateField('question', updateValue);
                    }}
                    placeholder="Edit Question here"
                    maxLength={10000}
                    rows={5}
                    disabled={updatedQuestion.creationType !== 'MANUAL_CREATE'}
                />
            </form>

            <ButtonBar className="mt-32">
                <Button buttonType="SECONDARY" label="Cancel" onClick={closeModal} />
                <Button label="Save" disabled={!saveEnabled || saving} onClick={save} loading={saving} />
            </ButtonBar>
        </Modal>
    );
};
