import { Authenticator } from '@aws-amplify/ui-react';
import { useUserContext } from 'hooks/useUserContext';
import React, { ReactElement } from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import { ROUTES } from 'utility/ApplicationRoutes';
import { ApplicationLoading } from './ApplicationLoader';

export const PrivateRoute = ({ children }: RouteProps): ReactElement => {
    const auth = useUserContext();

    if (auth.checkingAuth) {
        return <ApplicationLoading />;
    }

    if (auth.authToken) {
        return <Authenticator>{children}</Authenticator>;
    }

    return <Navigate to={ROUTES.LOGIN} />;
};
