import { signInWithRedirect } from 'aws-amplify/auth';
import { Loader } from 'components/loader.component';
import React, { ReactElement, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from 'utility/Api';
import { ROUTES } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { ENDPOINTS } from 'utility/Endpoints';

export const SSOSignIn = (): ReactElement => {
    const { customerName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const url = ENDPOINTS.getUrl(CONSTANTS.GET_SSO_BY_ALIAS);
        const request = API.postWithoutAuth(url, {
            alias: customerName,
        });

        request.then(({ data }) => {
            if (data && data.providerName) {
                signInWithRedirect({ provider: { custom: data.providerName } });
            } else {
                navigate(ROUTES.LOGIN);
            }
        });
    }, [customerName]);

    return <Loader />;
};
