import React, { ReactElement } from 'react';

export const AccountSetupImage = (): ReactElement => {
    return (
        <svg width="432" height="298" viewBox="0 0 432 298" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M407.488 78.6162C386.306 78.6162 364.863 75.4408 343.563 69.137C322.877 63.0145 302.278 53.9442 282.341 42.1779C250.944 23.649 231.019 4.11914 228.043 1.12364C216.798 18.9565 199.718 33.0273 177.27 42.9481C159.179 50.9436 137.579 56.2771 113.071 58.8004C71.3644 63.0955 35.1885 57.677 34.828 57.6216C34.7148 57.6042 34.6132 57.5427 34.5455 57.4504C34.4778 57.3582 34.4494 57.2428 34.4668 57.1296C34.4841 57.0165 34.5456 56.9148 34.6379 56.8471C34.7301 56.7793 34.8455 56.751 34.9586 56.7683C35.3181 56.8238 71.4012 62.226 113.001 57.94C151.341 53.9899 202.345 40.7667 227.601 0.203174C227.636 0.147405 227.683 0.100306 227.739 0.0654664C227.794 0.0306269 227.857 0.00896831 227.923 0.00213928C227.988 -0.00468976 228.054 0.00349094 228.116 0.0260602C228.177 0.0486295 228.233 0.0849918 228.279 0.132373C228.481 0.343523 248.951 21.4749 282.802 41.4482C314.028 59.8726 363.354 81.0527 421.014 77.3167C421.128 77.3094 421.24 77.3477 421.326 77.4232C421.412 77.4988 421.464 77.6053 421.472 77.7195C421.479 77.8337 421.441 77.9461 421.365 78.0321C421.29 78.1181 421.184 78.1706 421.069 78.1781C416.557 78.4704 412.03 78.6164 407.488 78.6162Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M83.0096 79.8482C82.7895 79.8361 82.5788 79.7555 82.4069 79.6176C82.2349 79.4798 82.1103 79.2916 82.0505 79.0795L76.5052 60.4479C76.4583 60.2905 76.448 60.1244 76.475 59.9624C76.5021 59.8004 76.5657 59.6467 76.6612 59.513C76.7566 59.3793 76.8813 59.2692 77.0257 59.191C77.1701 59.1129 77.3305 59.0687 77.4946 59.062H77.4947L88.7912 58.597C88.9631 58.59 89.1341 58.6242 89.2901 58.6967C89.4461 58.7692 89.5824 58.878 89.6878 59.0139C89.7932 59.1499 89.8646 59.3091 89.896 59.4782C89.9274 59.6474 89.9179 59.8216 89.8684 59.9863L84.1171 79.0828C84.053 79.3068 83.917 79.5034 83.73 79.6422C83.5429 79.781 83.3154 79.8542 83.0826 79.8506C83.0581 79.8505 83.0336 79.8497 83.0096 79.8482Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M137.386 75.757C137.167 75.7805 136.946 75.7348 136.754 75.6265C136.562 75.5182 136.409 75.3525 136.316 75.1528L127.844 57.6575C127.772 57.5097 127.735 57.3474 127.736 57.1832C127.737 57.0189 127.775 56.857 127.847 56.7097C127.92 56.5624 128.025 56.4336 128.155 56.3332C128.285 56.2328 128.436 56.1634 128.597 56.1303H128.597L139.672 53.8519C139.84 53.8173 140.014 53.8235 140.18 53.8699C140.346 53.9164 140.498 54.0018 140.624 54.119C140.75 54.2362 140.846 54.3818 140.904 54.5437C140.962 54.7055 140.981 54.879 140.958 55.0496L138.356 74.8232C138.329 75.0546 138.226 75.2705 138.064 75.4376C137.902 75.6047 137.689 75.7137 137.459 75.7476C137.435 75.7514 137.41 75.7546 137.386 75.757Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M197.306 55.2687C197.103 55.3542 196.878 55.3741 196.663 55.3256C196.448 55.2771 196.254 55.1625 196.107 54.998L182.961 40.6806C182.85 40.5597 182.768 40.4149 182.721 40.2574C182.674 40.0999 182.664 39.9339 182.691 39.7719C182.719 39.6099 182.783 39.4563 182.878 39.3227C182.974 39.1891 183.098 39.0791 183.243 39.0012L183.243 39.0011L193.194 33.6321C193.345 33.5504 193.514 33.5062 193.686 33.503C193.858 33.4999 194.028 33.5379 194.182 33.6139C194.337 33.6899 194.471 33.8017 194.573 33.94C194.675 34.0783 194.743 34.239 194.771 34.4088L197.966 54.0953C198.006 54.3247 197.97 54.561 197.863 54.7677C197.756 54.9745 197.583 55.14 197.372 55.2388C197.35 55.2495 197.328 55.2595 197.306 55.2687Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M388.556 97.5201C388.338 97.4835 388.138 97.3798 387.982 97.2236C387.827 97.0674 387.724 96.8665 387.689 96.6489L384.263 77.5136C384.234 77.352 384.242 77.1858 384.287 77.0278C384.332 76.8698 384.412 76.7242 384.522 76.6021C384.632 76.4799 384.768 76.3844 384.92 76.3229C385.073 76.2614 385.237 76.2355 385.401 76.2471H385.401L396.678 77.0496C396.85 77.0619 397.016 77.115 397.163 77.2045C397.31 77.2941 397.433 77.4174 397.523 77.5643C397.612 77.7112 397.665 77.8774 397.678 78.049C397.69 78.2206 397.661 78.3926 397.593 78.5508L389.742 96.8835C389.653 97.0989 389.496 97.279 389.294 97.396C389.093 97.513 388.859 97.5603 388.628 97.5306C388.604 97.5278 388.579 97.5244 388.556 97.5201Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M328.362 84.9871C328.158 84.9032 327.985 84.7576 327.868 84.5708C327.752 84.3839 327.696 84.1652 327.709 83.9452L328.613 64.5265C328.621 64.3624 328.666 64.2023 328.745 64.0582C328.824 63.9141 328.934 63.79 329.069 63.6953C329.203 63.6005 329.357 63.5377 329.519 63.5115C329.681 63.4853 329.847 63.4965 330.004 63.5442L330.004 63.5442L340.823 66.8297C340.987 66.8798 341.137 66.9684 341.261 67.0883C341.384 67.2082 341.477 67.3558 341.532 67.5189C341.586 67.6821 341.601 67.8559 341.575 68.0259C341.549 68.196 341.483 68.3573 341.382 68.4965L329.659 84.6297C329.525 84.82 329.332 84.9608 329.11 85.0302C328.887 85.0995 328.648 85.0937 328.43 85.0135C328.407 85.0053 328.384 84.9965 328.362 84.9871Z"
                    fill="#E6E6E6"
                />
                <path
                    d="M292.142 95.7493H285.677C285.385 95.7493 285.097 95.6836 284.835 95.5573C284.572 95.4309 284.341 95.247 284.16 95.0193C283.978 94.7915 283.849 94.5258 283.784 94.2417C283.719 93.9576 283.719 93.6624 283.783 93.3781L284.98 88.0929C269.617 85.5945 258.06 68.8077 258.06 48.9238C258.06 27.1496 271.899 9.43497 288.909 9.43497C305.919 9.43497 319.758 27.1496 319.758 48.9238C319.758 68.8076 308.201 85.5945 292.838 88.0929L294.035 93.3781C294.099 93.6624 294.099 93.9576 294.034 94.2417C293.969 94.5258 293.84 94.7915 293.658 95.0193C293.477 95.247 293.246 95.4309 292.983 95.5573C292.721 95.6836 292.433 95.7493 292.142 95.7493Z"
                    fill="#FFAB00"
                />
                <path
                    d="M294.734 89.7073H284.379C283.978 89.7073 283.594 89.5481 283.311 89.2649C283.028 88.9816 282.869 88.5974 282.869 88.1968C282.869 87.7962 283.028 87.412 283.311 87.1287C283.594 86.8454 283.978 86.6863 284.379 86.6863H294.734C295.134 86.6863 295.518 86.8454 295.801 87.1287C296.085 87.412 296.244 87.7962 296.244 88.1968C296.244 88.5974 296.085 88.9816 295.801 89.2649C295.518 89.5481 295.134 89.7073 294.734 89.7073Z"
                    fill="#3F3D56"
                />
                <path
                    d="M283.94 87.8243C282.356 87.4284 280.702 87.4009 279.105 87.744C277.508 88.0871 276.011 88.7916 274.729 89.8035C273.467 90.8399 272.465 92.1565 271.801 93.6485C271.137 95.1405 270.83 96.7669 270.904 98.3983C271.22 105.45 278.317 109.24 283.264 113.032C289.11 117.513 294.598 122.684 298.559 128.944C302.482 135.144 304.942 142.49 304.372 149.889C303.879 156.289 300.98 162.633 296.007 166.775C295.381 167.293 294.723 167.773 294.037 168.21C293.339 168.659 293.987 169.78 294.691 169.328C300.139 165.825 303.742 159.986 305.107 153.713C306.626 146.733 305.273 139.467 302.288 133.06C299.188 126.404 294.311 120.745 288.83 115.928C286.112 113.538 283.224 111.369 280.3 109.241C277.729 107.37 275.033 105.355 273.435 102.537C272.646 101.156 272.223 99.5956 272.206 98.0046C272.189 96.4137 272.578 94.8448 273.337 93.4468C274.13 92.0404 275.28 90.8686 276.671 90.0503C278.062 89.232 279.645 88.7962 281.259 88.7872C282.047 88.7797 282.833 88.8757 283.596 89.0727C284.404 89.2791 284.748 88.0308 283.94 87.8243H283.94Z"
                    fill="#3F3D56"
                />
                <path
                    d="M269.278 59.9288C268.541 59.928 267.833 59.637 267.309 59.1189C266.784 58.6007 266.484 57.8969 266.474 57.1594C264.165 50.4229 264.165 43.1089 266.474 36.3724C266.483 35.6313 266.785 34.9239 267.313 34.4048C267.842 33.8856 268.555 33.5969 269.296 33.6016C270.037 33.6064 270.746 33.9042 271.268 34.43C271.79 34.9559 272.083 35.6671 272.082 36.4082L272.069 36.4826C269.617 43.1671 269.618 50.0876 272.07 57.052L272.082 57.1236C272.082 57.8673 271.786 58.5804 271.26 59.1063C270.734 59.6322 270.021 59.928 269.278 59.9288Z"
                    fill="white"
                />
                <path
                    d="M49.3334 162.496C49.0679 162.376 48.8332 162.196 48.6468 161.972C48.4604 161.748 48.3271 161.485 48.2571 161.201L46.9417 155.944C32.1042 160.648 14.2187 150.883 5.25015 133.137C-4.57101 113.705 -0.211043 91.6508 14.9694 83.9742C30.1498 76.2975 50.49 85.8613 60.3112 105.293C69.2797 123.038 66.5376 143.235 53.9539 152.398L57.4063 156.575C57.592 156.799 57.7248 157.063 57.7947 157.346C57.8647 157.629 57.8701 157.924 57.8105 158.209C57.7509 158.495 57.6279 158.763 57.4505 158.994C57.2732 159.225 57.0461 159.414 56.786 159.545L51.0164 162.463C50.7565 162.595 50.4701 162.667 50.1787 162.673C49.8872 162.678 49.5982 162.618 49.3334 162.496Z"
                    fill="#CCCCCC"
                />
                <path
                    d="M43.9489 157.574C43.7687 157.217 43.7377 156.802 43.8626 156.422C43.9875 156.041 44.2582 155.726 44.6153 155.545L53.8563 150.872C54.2136 150.691 54.6279 150.66 55.008 150.786C55.3881 150.911 55.703 151.182 55.8836 151.539C56.0641 151.896 56.0955 152.31 55.9709 152.691C55.8463 153.071 55.5758 153.387 55.2189 153.568L45.9779 158.241C45.6204 158.421 45.206 158.452 44.8256 158.327C44.4453 158.202 44.13 157.931 43.9489 157.574Z"
                    fill="#3F3D56"
                />
                <path
                    d="M54.7609 151.689C55.9964 150.621 57.4602 149.85 59.0399 149.435C60.6197 149.021 62.2734 148.974 63.874 149.299C65.4676 149.654 66.9563 150.376 68.2218 151.408C69.4873 152.44 70.4947 153.753 71.164 155.243C74.0626 161.678 69.4385 168.263 66.7341 173.88C63.5383 180.518 60.9732 187.609 60.2618 194.984C59.5572 202.287 60.6752 209.953 64.5212 216.299C67.8476 221.788 73.2968 226.141 79.6021 227.593C80.3952 227.773 81.1985 227.904 82.0077 227.985C82.8336 228.071 82.7607 229.364 81.9289 229.278C75.4871 228.61 69.6372 225.026 65.5895 220.043C61.0861 214.5 59.0163 207.405 58.79 200.34C58.555 193.001 60.3552 185.749 63.073 178.977C64.4211 175.617 66.0195 172.378 67.6695 169.159C69.1203 166.329 70.6169 163.314 70.7723 160.078C70.8535 158.49 70.5274 156.906 69.8251 155.479C69.1228 154.051 68.0676 152.827 66.7594 151.922C65.4178 151.024 63.8629 150.498 62.2522 150.395C60.6415 150.293 59.0324 150.618 57.588 151.339C56.8812 151.688 56.2233 152.128 55.6311 152.648C55.0036 153.197 54.1332 152.238 54.7609 151.689L54.7609 151.689Z"
                    fill="#3F3D56"
                />
                <path
                    d="M51.4959 118.939L51.4744 118.87C50.522 111.548 47.4008 105.372 42.1975 100.513L42.1522 100.452C41.8176 99.7909 41.7581 99.0241 41.9868 98.3191C42.2156 97.6142 42.7139 97.0284 43.373 96.6898C44.0322 96.3512 44.7984 96.2872 45.5045 96.5119C46.2106 96.7366 46.7991 97.2317 47.1414 97.889C52.2406 102.859 55.5395 109.386 56.5174 116.44C56.8437 117.106 56.8935 117.873 56.6558 118.575C56.4181 119.277 55.9122 119.857 55.2488 120.187C54.5854 120.517 53.8183 120.571 53.1151 120.338C52.4119 120.104 51.8297 119.601 51.4959 118.939V118.939Z"
                    fill="white"
                />
                <path
                    d="M331.725 198.671L284.552 174.342C283.84 173.974 283.304 173.339 283.06 172.577C282.816 171.814 282.885 170.985 283.251 170.273L286.169 164.611C286.537 163.9 287.172 163.363 287.934 163.12C288.697 162.876 289.525 162.944 290.238 163.311L337.411 187.639C338.122 188.007 338.659 188.642 338.903 189.405C339.146 190.168 339.078 190.996 338.712 191.708L335.793 197.37C335.612 197.723 335.363 198.037 335.06 198.293C334.757 198.549 334.406 198.742 334.028 198.862C333.729 198.958 333.417 199.007 333.103 199.007C332.624 199.007 332.151 198.891 331.725 198.671Z"
                    fill="#2F64BB"
                />
                <path
                    d="M317.343 175.912C317.715 175.767 318.113 175.698 318.513 175.708C318.912 175.719 319.306 175.809 319.67 175.973L335.13 184.523C336.821 185.458 338.09 187.007 338.672 188.851C339.254 190.694 339.107 192.691 338.259 194.429L338.065 194.334L338.259 194.429C337.45 196.089 336.059 197.395 334.351 198.098C332.643 198.802 330.737 198.854 328.993 198.245L312.227 192.383C311.864 192.223 311.537 191.993 311.263 191.705C310.989 191.418 310.774 191.08 310.631 190.71C310.487 190.339 310.419 189.945 310.428 189.548C310.438 189.151 310.525 188.76 310.686 188.397L315.671 177.508C315.757 177.311 315.865 177.124 315.991 176.949C316.332 176.48 316.802 176.12 317.343 175.912Z"
                    fill="#2F2E41"
                />
                <path
                    d="M352.913 173.3C365.666 173.3 376.004 162.959 376.004 150.203C376.004 137.447 365.666 127.106 352.913 127.106C340.16 127.106 329.822 137.447 329.822 150.203C329.822 162.959 340.16 173.3 352.913 173.3Z"
                    fill="#2F64BB"
                />
                <path
                    d="M350.08 293.85V240.76C350.08 239.96 350.399 239.192 350.965 238.625C351.531 238.059 352.299 237.74 353.1 237.739H359.468C360.268 237.74 361.036 238.059 361.602 238.625C362.168 239.192 362.487 239.96 362.488 240.76V293.85C362.487 294.651 362.168 295.418 361.602 295.985C361.036 296.551 360.268 296.87 359.468 296.871H353.1C352.299 296.87 351.531 296.551 350.965 295.985C350.399 295.418 350.08 294.651 350.08 293.85Z"
                    fill="#2F64BB"
                />
                <path
                    d="M336.273 293.85V240.76C336.274 239.96 336.593 239.192 337.159 238.625C337.725 238.059 338.493 237.74 339.293 237.739H345.661C346.462 237.74 347.23 238.059 347.796 238.625C348.362 239.192 348.68 239.96 348.681 240.76V293.85C348.68 294.651 348.362 295.418 347.796 295.985C347.23 296.551 346.462 296.87 345.661 296.871H339.293C338.493 296.87 337.725 296.551 337.159 295.985C336.593 295.418 336.274 294.651 336.273 293.85Z"
                    fill="#2F64BB"
                />
                <path
                    d="M353.398 158.764L353.422 157.9C351.817 157.856 350.402 157.755 349.338 156.978C349.037 156.745 348.789 156.45 348.612 156.113C348.434 155.775 348.332 155.404 348.311 155.024C348.292 154.791 348.327 154.557 348.413 154.341C348.5 154.124 348.635 153.93 348.808 153.774C349.514 153.178 350.65 153.371 351.478 153.751L352.192 154.078L350.823 144.07L349.968 144.187L351.132 152.7C350.007 152.37 348.965 152.512 348.251 153.115C347.977 153.355 347.762 153.654 347.623 153.99C347.483 154.327 347.424 154.691 347.448 155.054C347.474 155.565 347.61 156.064 347.849 156.517C348.087 156.97 348.422 157.365 348.829 157.675C350.196 158.674 351.971 158.723 353.398 158.764Z"
                    fill="#2F2E41"
                />
                <path d="M345.74 144.921H341.093V145.784H345.74V144.921Z" fill="#2F2E41" />
                <path d="M360.409 144.921H355.762V145.784H360.409V144.921Z" fill="#2F2E41" />
                <path
                    d="M375.009 249.545L367.023 197.06C366.903 196.268 367.103 195.461 367.577 194.816C368.052 194.171 368.763 193.741 369.554 193.619L375.85 192.661C376.642 192.541 377.448 192.741 378.093 193.215C378.738 193.69 379.169 194.401 379.29 195.193L387.276 247.678C387.396 248.47 387.197 249.277 386.722 249.922C386.247 250.567 385.536 250.997 384.745 251.119L378.45 252.077C378.058 252.137 377.657 252.119 377.272 252.025C376.887 251.93 376.524 251.76 376.205 251.525C375.885 251.29 375.615 250.994 375.41 250.654C375.205 250.314 375.069 249.938 375.009 249.545Z"
                    fill="#2F64BB"
                />
                <path
                    d="M368.837 215.858C368.501 215.642 368.211 215.361 367.985 215.031C367.759 214.701 367.6 214.33 367.519 213.938L365.354 196.402C365.116 194.483 365.624 192.546 366.773 190.992C367.922 189.437 369.625 188.384 371.528 188.049L371.566 188.261L371.528 188.049C373.347 187.728 375.22 188.087 376.793 189.056C378.365 190.025 379.527 191.538 380.059 193.307L385.168 210.322C385.251 210.71 385.257 211.11 385.186 211.501C385.115 211.891 384.967 212.264 384.752 212.597C384.536 212.931 384.257 213.218 383.931 213.444C383.604 213.669 383.236 213.828 382.848 213.912L371.111 216.274C370.9 216.32 370.686 216.343 370.47 216.343C369.891 216.342 369.323 216.174 368.837 215.858Z"
                    fill="#2F2E41"
                />
                <path
                    d="M369.457 253.794H307.75C307.124 253.794 306.509 253.634 305.962 253.328C305.416 253.023 304.956 252.583 304.628 252.05C304.3 251.518 304.113 250.91 304.086 250.284C304.059 249.659 304.192 249.037 304.473 248.477L334.308 189.131C336.381 185.008 339.784 181.704 343.966 179.755C348.149 177.806 352.867 177.326 357.356 178.391C361.846 179.457 365.845 182.006 368.706 185.627C371.568 189.248 373.124 193.728 373.124 198.344V250.126C373.123 251.098 372.737 252.031 372.049 252.718C371.362 253.406 370.429 253.793 369.457 253.794Z"
                    fill="#2F2E41"
                />
                <path
                    d="M353.731 131.147C349.004 132.203 345.017 135.284 340.264 136.243C338.099 136.679 335.714 136.661 333.724 135.595C332.251 134.795 331.125 133.48 330.56 131.901C330.001 130.286 329.857 128.557 330.141 126.873C330.425 125.188 331.128 123.601 332.185 122.259C334.853 118.992 339.107 117.487 343.188 117.063C351.356 116.214 359.745 118.906 366.393 123.596C373.257 128.438 378.523 135.709 380.289 143.984C381.233 148.407 381.14 153.112 379.528 157.372C377.977 161.448 374.925 164.775 370.998 166.67C370.061 167.123 369.084 167.487 368.079 167.757C367.919 167.808 367.785 167.918 367.704 168.066C367.624 168.213 367.604 168.386 367.649 168.548C367.693 168.71 367.799 168.848 367.944 168.934C368.088 169.019 368.26 169.045 368.423 169.006C372.839 167.8 376.648 164.994 379.109 161.135C381.638 157.089 382.486 152.135 382.097 147.428C381.343 138.322 376.338 129.953 369.339 124.223C362.616 118.719 353.92 115.37 345.175 115.636C340.79 115.769 336.237 116.883 332.827 119.774C331.358 121.017 330.22 122.606 329.517 124.398C328.813 126.189 328.566 128.128 328.797 130.039C328.993 131.827 329.702 133.52 330.84 134.913C332.231 136.485 334.159 137.481 336.245 137.706C341.416 138.379 345.899 135.447 350.468 133.563C351.635 133.071 352.842 132.681 354.075 132.396C354.888 132.214 354.544 130.966 353.731 131.147V131.147Z"
                    fill="#2F2E41"
                />
                <path
                    d="M366.718 121.997C364.862 115.045 369.707 107.811 376.29 105.64C380.067 104.463 384.119 104.508 387.87 105.769C391.526 107.006 394.809 109.15 397.413 112.001C403.183 118.142 405.739 126.429 406.935 134.601C408.17 143.038 407.473 152.278 411.789 159.945C413.579 163.123 416.235 165.907 419.666 167.275C422.868 168.552 426.703 168.558 429.77 166.899C430.509 166.494 431.191 165.996 431.801 165.415C432.408 164.842 431.491 163.928 430.885 164.5C428.317 166.924 424.489 167.381 421.188 166.43C417.587 165.392 414.816 162.624 412.99 159.437C410.878 155.751 409.998 151.538 409.519 147.358C408.995 142.794 408.793 138.207 408.094 133.662C406.833 125.465 404.108 117.237 398.328 111.086C395.684 108.213 392.388 106.019 388.717 104.689C384.939 103.336 380.844 103.129 376.948 104.095C373.408 105.012 370.286 107.112 368.101 110.046C365.977 112.831 364.898 116.273 365.049 119.772C365.105 120.64 365.246 121.501 365.47 122.341C365.521 122.501 365.631 122.636 365.778 122.716C365.926 122.797 366.099 122.817 366.261 122.772C366.422 122.727 366.561 122.622 366.646 122.477C366.731 122.332 366.757 122.16 366.718 121.997L366.718 121.997Z"
                    fill="#2F2E41"
                />
                <path
                    d="M175.835 201.659L122.178 198.144C121.332 198.089 120.505 197.868 119.744 197.493C118.984 197.118 118.304 196.597 117.745 195.96C117.186 195.322 116.757 194.581 116.485 193.778C116.212 192.975 116.1 192.126 116.156 191.28C116.211 190.433 116.433 189.606 116.808 188.845C117.183 188.085 117.704 187.405 118.342 186.846C118.979 186.287 119.721 185.859 120.524 185.587C121.327 185.315 122.176 185.203 123.022 185.259L176.678 188.774C177.478 188.827 178.225 189.195 178.753 189.798C179.282 190.4 179.549 191.189 179.497 191.989L179.048 198.839C178.998 199.605 178.659 200.322 178.1 200.847C177.541 201.372 176.803 201.664 176.037 201.665C175.97 201.665 175.902 201.663 175.835 201.659Z"
                    fill="#2F64BB"
                />
                <path
                    d="M165.342 202.546L121.219 200.897C120.822 200.882 120.432 200.789 120.072 200.624C119.711 200.458 119.386 200.223 119.116 199.932C118.846 199.641 118.636 199.3 118.498 198.927C118.36 198.555 118.296 198.159 118.311 197.763L118.694 187.53C118.724 186.729 119.07 185.973 119.658 185.427C120.246 184.882 121.026 184.592 121.827 184.622L165.95 186.272C166.347 186.286 166.737 186.379 167.098 186.545C167.458 186.71 167.783 186.945 168.053 187.236C168.323 187.527 168.533 187.869 168.671 188.241C168.809 188.613 168.873 189.009 168.858 189.406L168.476 199.638C168.445 200.419 168.114 201.158 167.552 201.701C166.989 202.244 166.238 202.547 165.456 202.549C165.419 202.549 165.38 202.548 165.342 202.546Z"
                    fill="#2F2E41"
                />
                <path
                    d="M110.728 293.847V236.628C110.729 235.826 111.048 235.058 111.615 234.491C112.181 233.924 112.95 233.606 113.751 233.605H120.614C121.416 233.606 122.184 233.924 122.751 234.491C123.317 235.058 123.636 235.826 123.637 236.628V293.847C123.636 294.649 123.317 295.417 122.751 295.984C122.184 296.551 121.416 296.87 120.614 296.871H113.751C112.95 296.87 112.181 296.551 111.615 295.984C111.048 295.417 110.729 294.649 110.728 293.847Z"
                    fill="#2F2E41"
                />
                <path
                    d="M140.064 265.607L110.412 216.675C109.998 215.989 109.872 215.166 110.063 214.388C110.254 213.609 110.746 212.938 111.43 212.522L117.3 208.963C117.986 208.549 118.808 208.423 119.586 208.614C120.364 208.805 121.035 209.297 121.451 209.982L151.103 258.914C151.517 259.6 151.643 260.422 151.452 261.201C151.261 261.979 150.769 262.65 150.084 263.066L144.215 266.625C143.529 267.04 142.707 267.165 141.929 266.975C141.151 266.784 140.48 266.292 140.064 265.607Z"
                    fill="#2F2E41"
                />
                <path
                    d="M124.175 173.89C137.273 173.89 147.891 163.269 147.891 150.168C147.891 137.066 137.273 126.446 124.175 126.446C111.077 126.446 100.459 137.066 100.459 150.168C100.459 163.269 111.077 173.89 124.175 173.89Z"
                    fill="#2F64BB"
                />
                <path
                    d="M137.012 158.267C137.775 158.017 138.475 157.606 139.066 157.061C139.458 156.673 139.759 156.204 139.95 155.686C140.141 155.169 140.217 154.616 140.172 154.066C140.147 153.675 140.032 153.294 139.836 152.954C139.639 152.615 139.367 152.325 139.041 152.108C138.193 151.564 137.058 151.562 135.903 152.071L135.947 142.81L135.017 142.806L134.966 153.693L135.682 153.242C136.513 152.72 137.7 152.353 138.538 152.891C138.746 153.033 138.917 153.221 139.04 153.44C139.163 153.66 139.233 153.904 139.246 154.155C139.277 154.565 139.22 154.976 139.078 155.362C138.936 155.747 138.713 156.097 138.424 156.389C137.397 157.37 135.899 157.677 134.19 157.951L134.337 158.869C135.245 158.749 136.14 158.548 137.012 158.267Z"
                    fill="#2F2E41"
                />
                <path
                    d="M145.534 142.342L140.568 142.998L140.69 143.92L145.656 143.264L145.534 142.342Z"
                    fill="#2F2E41"
                />
                <path
                    d="M129.86 144.412L124.894 145.068L125.016 145.991L129.981 145.335L129.86 144.412Z"
                    fill="#2F2E41"
                />
                <path
                    d="M100.42 248.026V201.27C100.426 195.6 102.681 190.164 106.689 186.154C110.698 182.145 116.132 179.889 121.801 179.883H135.993C137.037 179.882 138.038 180.294 138.778 181.03C139.518 181.765 139.937 182.763 139.944 183.807L148.316 248.026C148.315 249.075 147.898 250.079 147.157 250.821C146.416 251.562 145.412 251.979 144.364 251.98H104.373C103.325 251.979 102.32 251.562 101.579 250.821C100.838 250.08 100.421 249.075 100.42 248.026Z"
                    fill="#2F2E41"
                />
                <path
                    d="M70.727 225.669L111.416 190.506C112.057 189.951 112.802 189.528 113.606 189.261C114.411 188.994 115.261 188.888 116.106 188.95C116.952 189.011 117.777 189.239 118.535 189.62C119.293 190 119.968 190.526 120.522 191.168C121.077 191.81 121.499 192.555 121.766 193.36C122.032 194.165 122.137 195.015 122.075 195.861C122.013 196.707 121.785 197.532 121.404 198.29C121.023 199.047 120.497 199.723 119.855 200.277L79.166 235.44C78.8656 235.7 78.5171 235.898 78.1403 236.022C77.7635 236.147 77.3659 236.197 76.97 236.168C76.5741 236.139 76.1878 236.032 75.8331 235.854C75.4784 235.676 75.1623 235.429 74.9028 235.129L70.4157 229.934C69.8917 229.327 69.6301 228.537 69.6884 227.737C69.7468 226.938 70.1204 226.194 70.727 225.669Z"
                    fill="#2F64BB"
                />
                <path
                    d="M79.7075 214.065L113.119 185.191C113.42 184.932 113.768 184.734 114.145 184.609C114.522 184.484 114.919 184.435 115.315 184.463C115.711 184.492 116.097 184.599 116.452 184.777C116.807 184.955 117.123 185.202 117.382 185.502L125.046 194.376C125.306 194.676 125.504 195.025 125.628 195.401C125.753 195.778 125.803 196.176 125.774 196.572C125.745 196.968 125.638 197.355 125.46 197.709C125.282 198.064 125.035 198.38 124.735 198.64L91.3236 227.514C90.7167 228.038 89.927 228.299 89.1277 228.24C88.3285 228.182 87.5851 227.809 87.0605 227.203L79.3963 218.33C78.8723 217.723 78.6107 216.933 78.669 216.133C78.7274 215.333 79.1009 214.59 79.7075 214.065Z"
                    fill="#2F2E41"
                />
                <path
                    d="M123.732 136.098C126.902 133.474 131.14 135.956 134.725 135.604C138.155 135.267 140.916 132.247 141.668 128.998C142.546 125.207 140.643 121.357 137.777 118.928C134.639 116.269 130.424 115.442 126.408 115.847C121.806 116.311 117.565 118.372 113.747 120.891C110.063 123.244 106.664 126.016 103.618 129.151C100.888 132.054 98.5555 135.532 97.7565 139.493C97.0305 143.093 97.4565 147.105 99.5332 150.206C100.635 151.796 102.129 153.075 103.869 153.92C105.683 154.854 107.623 155.534 109.377 156.584C112.03 158.172 114.576 161.409 113.673 164.71C113.479 165.431 113.111 166.093 112.601 166.639C111.988 167.294 110.922 166.393 111.537 165.736C112.616 164.583 112.604 163.017 112.067 161.605C111.427 160.014 110.266 158.687 108.774 157.842C106.938 156.745 104.901 156.051 103.017 155.048C101.212 154.13 99.6561 152.789 98.481 151.14C96.2638 147.931 95.6608 143.799 96.2497 139.997C96.887 135.883 99.0766 132.176 101.792 129.086C104.747 125.723 108.352 122.897 112.028 120.367C115.972 117.652 120.317 115.376 125.09 114.613C129.228 113.952 133.69 114.493 137.253 116.809C140.58 118.971 143.092 122.63 143.272 126.668C143.33 128.505 142.897 130.324 142.018 131.938C141.139 133.552 139.846 134.903 138.272 135.851C136.658 136.776 134.794 137.171 132.944 136.98C130.965 136.815 128.993 136.17 126.996 136.301C126.093 136.339 125.226 136.67 124.528 137.244C123.835 137.818 123.045 136.667 123.732 136.098L123.732 136.098Z"
                    fill="#2F2E41"
                />
                <path
                    d="M151.174 261.86L133.382 289.452C132.947 290.125 132.262 290.598 131.479 290.767C130.696 290.936 129.877 290.788 129.203 290.354L123.436 286.633C122.762 286.198 122.289 285.513 122.12 284.73C121.951 283.946 122.1 283.127 122.534 282.453L140.326 254.861C140.761 254.188 141.445 253.715 142.229 253.546C143.012 253.377 143.83 253.525 144.504 253.959L150.272 257.68C150.606 257.895 150.895 258.173 151.121 258.5C151.347 258.826 151.506 259.195 151.588 259.583C151.6 259.636 151.61 259.688 151.618 259.74C151.739 260.478 151.58 261.233 151.174 261.86Z"
                    fill="#2F2E41"
                />
                <path
                    d="M420.61 297.077H35.3248C35.2103 297.077 35.1006 297.032 35.0197 296.951C34.9388 296.87 34.8933 296.76 34.8933 296.646C34.8933 296.531 34.9388 296.422 35.0197 296.341C35.1006 296.26 35.2103 296.214 35.3248 296.214H420.61C420.725 296.214 420.834 296.26 420.915 296.341C420.996 296.422 421.042 296.531 421.042 296.646C421.042 296.76 420.996 296.87 420.915 296.951C420.834 297.032 420.725 297.077 420.61 297.077Z"
                    fill="#3F3D56"
                />
                <path d="M174.359 127.47H171.77V135.67H174.359V127.47Z" fill="#F2F2F2" />
                <path d="M216.21 104.165H213.621V112.365H216.21V104.165Z" fill="#F2F2F2" />
                <path
                    d="M205.609 129.282L203.584 130.895L208.689 137.31L210.714 135.697L205.609 129.282Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M193.408 154.129L189.309 161.23L191.551 162.525L195.65 155.424L193.408 154.129Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M181.759 74.2884L177.66 81.3897L179.902 82.6844L184.001 75.5831L181.759 74.2884Z"
                    fill="#F2F2F2"
                />
                <path d="M248.568 214.216H245.98V222.416H248.568V214.216Z" fill="#F2F2F2" />
                <path d="M290.419 190.911H287.83V199.111H290.419V190.911Z" fill="#F2F2F2" />
                <path
                    d="M279.819 216.028L277.793 217.64L282.898 224.056L284.924 222.443L279.819 216.028Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M267.618 240.875L263.519 247.976L265.761 249.271L269.86 242.17L267.618 240.875Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M255.969 161.034L251.87 168.136L254.112 169.43L258.21 162.329L255.969 161.034Z"
                    fill="#F2F2F2"
                />
                <path
                    d="M126.278 126.457L122.072 123.957L118.734 121.969L113.892 119.09L110.549 117.105L106.005 114.399C105.931 114.356 105.847 114.332 105.761 114.331C105.676 114.33 105.591 114.352 105.517 114.394C105.442 114.436 105.38 114.496 105.336 114.57C105.292 114.644 105.268 114.727 105.266 114.813L105.22 118.66L105.18 121.633L105.124 125.942L105.091 128.916L105.035 133.225L104.999 136.196L104.971 138.404C104.967 138.49 104.988 138.575 105.031 138.649C105.073 138.723 105.132 138.786 105.205 138.831C105.278 138.875 105.362 138.898 105.448 138.899C105.534 138.9 105.618 138.878 105.693 138.836L126.27 127.298C126.335 127.263 126.39 127.214 126.432 127.154C126.474 127.093 126.501 127.024 126.51 126.951C126.516 126.927 126.519 126.903 126.52 126.879C126.519 126.794 126.497 126.71 126.456 126.636C126.415 126.56 126.353 126.498 126.278 126.457Z"
                    fill="#CCCCCC"
                />
                <path d="M113.891 119.09L105.18 121.633L105.22 118.66L110.549 117.105L113.891 119.09Z" fill="#3F3D56" />
                <path
                    d="M122.072 123.957L105.091 128.916L105.124 125.942L118.733 121.969L122.072 123.957Z"
                    fill="#3F3D56"
                />
                <path
                    d="M126.51 126.951C126.501 127.024 126.474 127.093 126.432 127.154C126.39 127.214 126.334 127.263 126.27 127.298L116.265 132.907L104.999 136.196L105.035 133.225L126.51 126.951Z"
                    fill="#3F3D56"
                />
                <path
                    d="M104.565 116.588C106.714 116.588 108.456 114.846 108.456 112.696C108.456 110.547 106.714 108.804 104.565 108.804C102.416 108.804 100.674 110.547 100.674 112.696C100.674 114.846 102.416 116.588 104.565 116.588Z"
                    fill="#CCCCCC"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="432" height="297.077" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
