import { BRIGHTBLUE, LIGHTGRAY, Pill } from 'components/pill.component';
import _filter from 'lodash/filter';
import React from 'react';

interface Item {
    itemId: string;
    itemLabel: string;
}

export const MultiSelectPill = ({
    items,
    selections,
    updateSelection,
}: {
    items: Item[];
    selections: string[];
    updateSelection: (updatedSelections: string[]) => void;
}) => {
    return (
        <div className="flex flex-row flex-wrap rounded-md border border-gray-300 p-8">
            {items.map((item) => {
                const { itemLabel, itemId } = item;
                const isSelected = selections.indexOf(itemId) !== -1;

                return (
                    <div
                        key={itemId}
                        className="mb-8 cursor-pointer select-none"
                        onClick={() => {
                            if (isSelected) {
                                updateSelection(
                                    _filter(selections, (existingItemId: string) => {
                                        return existingItemId !== itemId;
                                    }),
                                );
                            } else {
                                updateSelection([...selections, itemId]);
                            }
                        }}
                    >
                        <Pill key={itemId} colour={isSelected ? BRIGHTBLUE : LIGHTGRAY} title={itemLabel} />
                    </div>
                );
            })}
        </div>
    );
};
