import { ReactComponent as OpenProjects } from 'assets/openProjects.svg';
import { EmptyState } from 'components/empty-states.component';
import { ColHeader, Row, StyledTable } from 'components/table';
import { useUserContext } from 'hooks/useUserContext';
import _find from 'lodash/find';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Project, ProjectDashboard } from 'types';
import { ROUTES, createUrl } from 'utility/ApplicationRoutes';
import { CONSTANTS } from 'utility/Constants';
import { PERMISSION } from 'utility/Permission';
import { AssigneeListElement } from './assignee-list-element.view';

export const ProjectAssigneeTable = ({
    dashboardData,
    project,
}: {
    dashboardData: ProjectDashboard;
    project: Project;
}): ReactElement => {
    const unassignedData = _find(dashboardData.questionAssignments, { userId: null });
    const unassignedCount = unassignedData ? unassignedData.assignedQuestionsCount : 0;
    const navigate = useNavigate();
    const { hasPermission } = useUserContext();

    const callBack = (): void => {
        navigate(
            `${createUrl(ROUTES.PROJECT_QUESTION_ANSWERING_START, {
                projectId: project.projectId,
            })}/?${CONSTANTS.UNASSIGNED}`,
        );
    };

    const questionAnswerPermission = hasPermission(PERMISSION.ANSWER_QUESTION);

    return (
        <div className="mb-32">
            <StyledTable>
                <>
                    <Row className="border-b border-gray-light-1 bg-white">
                        <>
                            <ColHeader text="Assignee" className={`w-6-of-24 pl-16`} />
                            <ColHeader text="Questions Assigned" className={`w-3-of-24`} />
                            <ColHeader text="Questions Complete" className={`w-4-of-24 `} />
                            <ColHeader text="Status" className={`w-4-of-24`} />
                            <ColHeader text="Last Activity" className={`w-6-of-24`} />
                            <ColHeader text="" className={`w-1-of-24`} />
                        </>
                    </Row>
                    {(dashboardData.questionAssignments.length === 1 && unassignedData) ||
                    !dashboardData.questionAssignments.length ? (
                        <li className="mt-80">
                            <EmptyState
                                placeholderImage={<OpenProjects />}
                                title={
                                    dashboardData.questionAssignments.length
                                        ? 'All the questions are currently unassigned.'
                                        : 'Currently, there are no questions in the project'
                                }
                            />
                        </li>
                    ) : (
                        dashboardData.questionAssignments.map((item, index) => (
                            <AssigneeListElement key={index} item={item} projectId={project.projectId} />
                        ))
                    )}
                </>
            </StyledTable>

            {questionAnswerPermission && unassignedCount > 0 && (
                <div
                    data-cy="View_All_Unassigned_Questions"
                    className="button-med-14 mt-16 cursor-pointer text-primary-blue-100 hover:underline"
                    onClick={callBack}
                >
                    View {unassignedCount} Unassigned Questions
                </div>
            )}
        </div>
    );
};
