import React from 'react';

export const Key = (): React.ReactElement => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.9169 6.25008L15.8336 3.33341M17.5002 1.66675L15.8336 3.33341L17.5002 1.66675ZM9.4919 9.67508C9.92218 10.0996 10.2642 10.6051 10.4984 11.1624C10.7325 11.7197 10.8541 12.3178 10.8561 12.9223C10.8581 13.5267 10.7405 14.1257 10.5102 14.6845C10.2798 15.2433 9.94111 15.7511 9.51368 16.1785C9.08625 16.606 8.5785 16.9446 8.01965 17.175C7.4608 17.4054 6.86189 17.523 6.25742 17.5209C5.65295 17.5189 5.05485 17.3973 4.49755 17.1632C3.94026 16.9291 3.43478 16.587 3.01023 16.1567C2.17534 15.2923 1.71336 14.1346 1.72381 12.9328C1.73425 11.7311 2.21627 10.5815 3.06606 9.73175C3.91585 8.88196 5.0654 8.39994 6.26714 8.38949C7.46887 8.37905 8.62663 8.84102 9.49106 9.67592L9.4919 9.67508ZM9.4919 9.67508L12.9169 6.25008L9.4919 9.67508ZM12.9169 6.25008L15.4169 8.75008L18.3336 5.83341L15.8336 3.33341L12.9169 6.25008Z"
                stroke="#CACCCF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
